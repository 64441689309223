import React from "react";
import { dropdownMenuData } from "../NavBars/dropownMenuData";
import DrawerRoot from "./MoreDrawerRoot";
import MoreDrawerItem from "./MoreDrawerItem";
import { useHistory, useLocation } from "react-router";
import { getFolderId } from "../../../helpers/helpers";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";

function AddFileDrawer({ currentMembership, openDrawer, handleMenuClose }) {
  const history = useHistory();
  const { onShowUpload } = useAssetsContext();
  const { modal, onNotificationData } = useNotificationsContext();

  const location = useLocation();
  const folderId = getFolderId(location.pathname);

  if (!currentMembership) return null;

  return (
    <DrawerRoot
      header={currentMembership?.dataRoom?.state?.title}
      openDrawer={openDrawer}
      handleMenuClose={handleMenuClose}
    >
      {dropdownMenuData["plusMenu"](
        history,
        null,
        onShowUpload,
        handleMenuClose,
        folderId,
        modal.onConfirmModalFormType,
        modal.onModalVisibility,
        onNotificationData,
        currentMembership
      ).map((menuItem, index) => (
        <MoreDrawerItem key={index} menuItem={menuItem} />
      ))}
    </DrawerRoot>
  );
}

export default AddFileDrawer;
