export const stacksByDataRoomId =
  /* GraphQL */
  `
    query StacksByDataRoomId(
      $dataRoomId: ID!
      $filter: ModelStackFilterInput
      $limit: Int
      $nextToken: String
    ) {
      stacksByDataRoomId(
        dataRoomId: $dataRoomId
        filter: $filter
        nextToken: $nextToken
        limit: $limit
        sortDirection: DESC
      ) {
        items {
          id
          hash
          prevHash
          refHash
          publicSigningKey
          postedAt
          contextVersion
          dataRoomId
          folderId
          status
          state {
            status
            title
            description
            resourceVersion
            size
            files {
              title
              resourceUrl
              resourceTx
              thumbnailUrl
              postedAt
              fileType
              size
              numberOfChunks
              chunkSize
              hash
            }
          }
          storageTransactions(sortDirection: DESC) {
            items {
              id
              refId
              refHash
              publicSigningKey
              dataRoomId
              stackId
              type
              status
              timestamp
              amount
              createdAt
              updatedAt
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;

export const notesByDataRoomId =
  /* GraphQL */
  `
    query NotesByDataRoomId(
      $dataRoomId: ID!
      $filter: ModelNoteFilterInput
      $limit: Int
      $nextToken: String
    ) {
      notesByDataRoomId(
        dataRoomId: $dataRoomId
        filter: $filter
        nextToken: $nextToken
        limit: $limit
        sortDirection: DESC
      ) {
        items {
          id
          hash
          prevHash
          refHash
          publicSigningKey
          postedAt
          contextVersion
          dataRoomId
          folderId
          status
          state {
            status
            title
            resourceVersion
            size
            content
            revisions {
              title
              postedAt
              size
              hash
              content
            }
          }
          storageTransactions(sortDirection: DESC) {
            items {
              id
              refId
              refHash
              publicSigningKey
              dataRoomId
              stackId
              type
              status
              timestamp
              amount
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
    }
  `;

export const foldersByDataRoomId =
  /* GraphQL */
  `
    query FoldersByDataRoomId(
      $dataRoomId: ID!
      $filter: ModelFolderFilterInput
      $limit: Int
      $nextToken: String
    ) {
      foldersByDataRoomId(
        dataRoomId: $dataRoomId
        filter: $filter
        nextToken: $nextToken
        limit: $limit
        sortDirection: DESC
      ) {
        items {
          id
          hash
          prevHash
          refHash
          publicSigningKey
          postedAt
          contextVersion
          dataRoomId
          folderId
          status
          state {
            status
            title
          }
          createdAt
          updatedAt
          folders {
            nextToken
          }
          stacks {
            nextToken
          }
        }
        nextToken
      }
    }
  `;
