import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import { backUpAdvice } from "../../helpers/akordTexts";
import {
  ShieldIcon,
  ClipboardIcon,
  DownloadIcon,
  TickIcon,
  AccountBackupIcon
} from "@akord/addon-icons";
import { useSignupContext } from "../../contexts/SignupContext";
import { downloadTxtFile, copyToClipboard } from "../../helpers/helpers";
import BackupPhraseBox from "../../components/common/BackupPhraseBox";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  rootWithIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  containerText: {
    lineHeight: "32px"
  },
  rootIcon: {
    marginRight: theme.spacing(3)
  }
}));

function BackUpPhrase({ history }) {
  const { formData, isDesktop, wallet } = useSignupContext();

  const backupPhraseArray = wallet?.backupPhrase?.split(" ");

  const classes = useStyles();

  const [copyClick, setCopyClick] = useState(false);
  const handleCopyClick = value => setCopyClick(value);

  if (!backupPhraseArray) history.push("/signup");

  return (
    <Box className={classes.root} style={{ flexGrow: !isDesktop && 1 }}>
      <Box mb={3} className={classes.rootWithIcon}>
        <Typography variant="h1">Backup phrase</Typography>
        <AccountBackupIcon fontSize="large" color="primary" />
      </Box>

      <Box className={classes.root} style={{ flexGrow: !isDesktop && 1 }}>
        <Box>
          <Box mb={5}>
            <Typography variant="body2">
              Below is your unique 12-word phrase.
            </Typography>
          </Box>
          <Box mb={5}>
            <BackupPhraseBox backupPhraseArray={backupPhraseArray} />
          </Box>
          <Box mb={7} className={classes.containerText}>
            <DownloadIcon
              fontSize="small"
              color="action"
              className={classes.rootIcon}
            />
            <Typography
              component="a"
              variant="body2"
              color="text.primary"
              onClick={() => {
                downloadTxtFile(backupPhraseArray, formData);
              }}
            >
              Download
            </Typography>
            <br />
            <ClipboardIcon
              fontSize="small"
              color="action"
              className={classes.rootIcon}
            />
            <Typography
              component="a"
              variant="body2"
              color="text.primary"
              onClick={() =>
                copyToClipboard(backupPhraseArray.join(" "), handleCopyClick)
              }
            >
              {copyClick ? "Copied!" : "Copy to clipboard"}
            </Typography>
          </Box>
        </Box>

        <Box>
          <List>
            <ListItem disableGutters dense>
              <ListItemIcon>
                <ShieldIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="h3">Security best practices</Typography>
                }
              />
            </ListItem>
            {backUpAdvice.map((item, index) => (
              <ListItem
                alignItems="flex-start"
                dense
                key={index}
                disableGutters
                style={{ paddingTop: "8px" }}
              >
                <ListItemIcon>
                  <TickIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography variant="body2">{item}</Typography>}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
}

export default withRouter(BackUpPhrase);
