import { MenuDotsHorizontalIcon } from "@akord/addon-icons";
import { Card, CardMedia, ClickAwayListener, IconButton } from "@mui/material";
import { makeStyles } from '@mui/styles';
import React from "react";
import { useHistory } from "react-router-dom";
import NoThumbnail from "../../../components/common/NoThumbnail";
import OnImageMenu from "../../../components/common/OnImageMenu";
import OperationTitleWithIcon from "../../../components/common/OperationTitle/OperationTitleWithIcon";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import { getDataRoomId, getDataRoomStatus, getFolderId } from "../../../helpers/helpers";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "440px",
    height: "136px",
    "&:hover": {
      "& $showAction": {
        visibility: "visible",
        opacity: "1"
      }
    }
  },
  showAction: {
    textAlign: "end",
    width: "calc(100% - 48px)",
    visibility: ({ buttonVisible }) => (buttonVisible ? "visible" : "hidden"),
    opacity: ({ buttonVisible }) => (buttonVisible ? 1 : 0),
    transition: "visibility 0.3s, opacity 0.1s linear"
  },
  media: {
    height: "136px",
    backgroundPosition: "top",
    cursor: "pointer"
  },
  iconButtonThumb: {
    position: "absolute",
    color: ({ buttonVisible }) => buttonVisible && theme.palette.primary.main,
    backgroundColor: ({ buttonVisible }) => !buttonVisible && "#7C798620",
    padding: "6px",
    margin: "6px",
    "&:hover": {
      backgroundColor: "#7C798620",
      color: "#312F37"
    }
  },
  popOver: {
    background: "#FFF"
  }
}));

function StackCreated({ positionedBeforeDate, operation, color }) {
  const history = useHistory();
  const roomStatus = getDataRoomStatus(history.location.pathname);
  const roomId = getDataRoomId(history.location.pathname);
  const folderId = getFolderId(history.location.pathname);
  const { decryptedStacks } = useAssetsContext();
  const [buttonVisible, setButtonVisible] = React.useState(false);
  const handleButtonVisible = value => setButtonVisible(value);
  const classes = useStyles({ buttonVisible: buttonVisible });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!operation || Object.keys(operation).length === 0) return null;

  //create stacks status and stack hash dictionary
  const statusDictionary = {};
  const hashesDictionary = {};
  const stackArray = decryptedStacks.filter(stack =>
    operation.group.map(file =>
      file.files.some(item => item.title === stack.title)
    )
  );
  //HACK: if a file was renamed and we want to hide it in the timeline because
  //we revoked/deleted it we will be looking in the files array
  //and pick the first one to match the names, since the stack titles won't match
  stackArray.forEach(
    stack => (statusDictionary[stack.files[0].title] = stack.status)
  );
  stackArray.forEach(
    stack => (hashesDictionary[stack.files[0].title] = stack.hash)
  );

  return (
    <>
      {operation?.group?.length > 1 ? (
        <>
          <OperationTitleWithIcon
            actionRef={operation.actionRef}
            color={color}
            title={`${operation.group.length} files uploaded`}
          />
          {operation?.group?.map((stackItem, index) => (
            <div key={index}>
              <NoThumbnail
                positionedBeforeDate={positionedBeforeDate}
                stackItem={stackItem}
                handleMenu={handleMenu}
                lastItem={operation.group.length - 1 === index}
                stackStatus={statusDictionary[stackItem.files[0].title]}
                stackHash={hashesDictionary[stackItem.files[0].title]}
              />
            </div>
          ))}
        </>
      ) : (
        operation?.group?.map((stackItem, index) => (
          <div key={index}>
            <OperationTitleWithIcon
              actionRef={operation.actionRef}
              color={color}
              title={
                stackItem.thumbnailUrl ? stackItem.title : "1 file uploaded"
              }
            />
            {stackItem.thumbnailUrl &&
              statusDictionary[stackItem.title] !== "REVOKED" &&
              statusDictionary[stackItem.title] !== "DELETED" ? (
              <Card
                square
                className={classes.card}
                style={{
                  marginBottom: positionedBeforeDate ? "74px" : "16px"
                }}
              >
                <div className={classes.showAction}>
                  <ClickAwayListener
                    onClickAway={() => handleButtonVisible(false)}
                  >
                    <IconButton
                      aria-label="settings"
                      edge="end"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={event => {
                        handleMenu(event);
                        handleButtonVisible(true);
                      }}
                      className={classes.iconButtonThumb}
                    >
                      <MenuDotsHorizontalIcon />
                    </IconButton>
                  </ClickAwayListener>
                </div>
                <CardMedia
                  className={classes.media}
                  image={stackItem.thumbnailUrl}
                  title={stackItem.title}
                  onClick={() => {
                    let galleryUrl = `/vaults/${roomStatus}/${roomId}/gallery`
                    if (folderId) {
                      galleryUrl += `/folders/${folderId}`
                    }
                    galleryUrl += `#${stackItem.hash}`
                    history.push(galleryUrl);
                  }}
                />
              </Card>
            ) : (
              <NoThumbnail
                positionedBeforeDate={positionedBeforeDate}
                stackItem={stackItem}
                handleMenu={handleMenu}
                lastItem={operation.group.length - 1 === index}
                stackStatus={statusDictionary[stackItem.title]}
                stackHash={hashesDictionary[stackItem.title]}
              />
            )}
          </div>
        ))
      )}
      <OnImageMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        operation={operation?.group[0]}
      />
    </>
  );
}

export default StackCreated;
