import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { IconButton, Box, Typography, Tooltip } from "@mui/material";
import { Skeleton } from "@mui/material";
import {
  ChevronLeftIcon,
  ArchiveIcon,
  DoubleChevronLeftIcon,
  PadlockIcon,
  GlobeIcon
} from "@akord/addon-icons";
import { useHistory } from "react-router";
import Label from "../Label";

const useStyles = makeStyles(theme => ({
  roomName: {
    marginTop: "3px"
  },
  textLink: {
    borderBottom: "none",
    "&:hover": {
      color: theme.palette.text.primary
    }
  }
}));

function TopBarTitleMobile({
  currentMembership,
  currentFolder,
  parentFolders,
  folderId,
  isRoomArchived,
  dataRoomStatus,
  dataRoomId,
  isVaultPublic
}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box
      display="flex"
      maxWidth="calc(100% - 56px)"
      minWidth="calc(100% - 130px)"
    >
      {!currentMembership || Object.keys(currentMembership).length === 0 ? (
        <Skeleton variant="text" width="90%" />
      ) : (
        <Box maxWidth="95%" minWidth="90%" display="flex">
          <IconButton
            disableRipple
            size="small"
            edge="end"
            aria-label="back"
            aria-controls="menu-appbar"
            onClick={() =>
              history.push(
                parentFolders.length
                  ? `/vaults/${dataRoomStatus}/${dataRoomId}/assets`
                  : `/vaults/${dataRoomStatus}`
              )
            }
          >
            {parentFolders.length ? (
              <DoubleChevronLeftIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
          {isRoomArchived && (
            <ArchiveIcon color="disabled" style={{ marginRight: 8 }} />
          )}
          <Box
            className={classes.roomName}
            display="flex"
            minWidth="40%"
            maxWidth="95%"
          >
            {!parentFolders.length ? (
              folderId ? (
                <Box maxWidth="50%" display="flex">
                  <Typography
                    variant="h2"
                    noWrap
                    className={`${classes.textLink} ${"regular"}`}
                    color="text.secondary"
                    component="a"
                    onClick={() =>
                      history.push(
                        `/vaults/${dataRoomStatus}/${dataRoomId}/assets`
                      )
                    }
                  >
                    {currentMembership.dataRoom.state.title}
                  </Typography>
                </Box>
              ) : (
                <Box display="flex" width="100%">
                  <Typography variant="h2" noWrap color="text.primary">
                    {currentMembership.dataRoom.state.title}
                  </Typography>
                  {!currentMembership?.dataRoom?.state?.permanentStorage && (
                    <Label secondary text="Cloud" />
                  )}
                  {isVaultPublic ? (
                    <Tooltip title="Public vault" arrow>
                      <span>
                        <GlobeIcon
                          fontSize="small"
                          color="disabled"
                          style={{ marginLeft: "8px", marginTop: "1px" }}
                        />
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Encrypted vault" arrow>
                      <span>
                        <PadlockIcon
                          fontSize="small"
                          color="disabled"
                          style={{ marginLeft: "8px", marginTop: "1px" }}
                        />
                      </span>
                    </Tooltip>
                  )}
                </Box>
              )
            ) : null}
            {parentFolders.map((parentFolder, index) =>
              index === parentFolders.length - 1 ? (
                <Typography
                  key={index}
                  variant="h2"
                  noWrap
                  className={`${classes.textLink} ${"regular"}`}
                  color="text.secondary"
                  component="a"
                  onClick={() =>
                    history.push(
                      `/vaults/${dataRoomStatus}/${dataRoomId}/assets/folders/${parentFolder.id}`
                    )
                  }
                >
                  ...
                </Typography>
              ) : null
            )}
            {folderId && (
              <>
                <Typography
                  variant="h2"
                  className="regular"
                  color="text.secondary"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {" / "}
                </Typography>
                <Typography variant="h2" noWrap color="text.primary">
                  {currentFolder?.title}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default TopBarTitleMobile;
