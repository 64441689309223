import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import { withRouter } from "react-router";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";

function SearchResultsFilter() {
  const { isMobile } = useGlobalContext();

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell width={isMobile ? "100%" : "50%"} align="left">
            <Typography
              variant="caption"
              className="small"
              color="text.secondary"
            >
              Name
            </Typography>
          </TableCell>
          <TableCell width={isMobile ? "100%" : "50%"} align="left"></TableCell>
        </TableRow>
      </TableHead>
    </>
  );
}

export default withRouter(SearchResultsFilter);
