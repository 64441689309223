import { Box, IconButton, LinearProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";
import { useProgressContext } from "../../contexts/ProgressContext";
import { CloseInCircleIcon, GlobeIcon, PadlockIcon } from "@akord/addon-icons";
import { useVaultContext } from "../../contexts/VaultContextProvider";

const useStyles = makeStyles(theme => ({
  titleBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  progressBox: {
    background: theme.palette.background.card,
    borderRadius: "4px"
  },
  root: {
    width: "100%"
  }
}));

function ProgressBar(props) {
  const { progress } = useProgressContext();
  const { drawerActionType, filesNumber, uploadHook, showLoader } =
    useAssetsContext();
  const { isVaultPublic = false } = useVaultContext();

  const { darkMode } = props;
  const classes = useStyles({ darkMode: darkMode });

  const diff = Math.random() * 10;

  const uploadText =
    filesNumber > 1
      ? isVaultPublic
        ? `${filesNumber} files are uploading...`
        : `${filesNumber} files are encrypting and uploading...`
      : isVaultPublic
      ? `1 file is uploading...`
      : `1 file is encrypting and uploading...`;

  const downloadText = isVaultPublic
    ? "Your file is downloading..."
    : "Your file is decrypting and downloading...";

  if (!showLoader) return null;

  return (
    <Box mt={4} mb={5} mx={5} className={classes.progressBox}>
      <Box p={2}>
        <Box className={classes.titleBox}>
          <Typography variant="caption">
            {isVaultPublic ? (
              <GlobeIcon
                sx={{ marginRight: "8px", marginTop: "-2px", fontSize: "16px" }}
              />
            ) : (
              <PadlockIcon
                sx={{ marginRight: "8px", marginTop: "-2px", fontSize: "16px" }}
              />
            )}
            {drawerActionType && drawerActionType.includes("upload")
              ? uploadText
              : downloadText}
          </Typography>
          <IconButton onClick={() => uploadHook.cancel()} size="large">
            <CloseInCircleIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box display="flex" alignItems="baseline">
          <Box width="100%" mr={1}>
            <LinearProgress
              color="primary"
              variant="buffer"
              value={progress}
              valueBuffer={progress + diff}
            />
          </Box>
          <Box>
            <Typography
              variant="caption"
              className="small"
              color="text.secondary"
              align="right"
            >
              {progress}%
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ProgressBar;
