import React from "react";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";
import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";
import { useProgressContext } from "../../contexts/ProgressContext";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";

const UploadForm = ({
  actionFunc,
  currentStack,
  handleMenuClose,
  onDrawerActionType,
  multiple = true
}) => {
  const { onUploadFiles } = useNotificationsContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const { currentMembership, ledgerWrapper } = useVaultContext();
  const { onUploadStart, onAxiosError, onShowUpload, onShowLoader } =
    useAssetsContext();
  const { onProgress } = useProgressContext();
  // // Get the user-agent string
  // const userAgentString = navigator.userAgent
  // // Detect Chrome
  // const chromeAgent = userAgentString.indexOf('Chrome') > -1
  // // Detect Safari
  // let safariAgent = userAgentString.indexOf('Safari') > -1
  // // Discard Safari since it also matches Chrome
  // if (chromeAgent && safariAgent) safariAgent = false
  // console.log('Here', actionFunc)

  const isLegacyVault =
    currentMembership?.dataRoom?.state?.permanentStorage === null;

  return (
    <input
      id="upload-file"
      name="upload-file"
      multiple={multiple}
      type="file"
      onChange={event => {
        handleMenuClose(false);
        if (isLegacyVault) {
          onDrawerActionType("upload");
          if (onShowLoader && onShowUpload) {
            onShowLoader(true);
            onShowUpload(false);
          }
          const action = actionFunc(
            ledgerWrapper,
            currentStack,
            event.target.files,
            onShowLoader,
            onProgress,
            onAxiosError,
            onSnackbarToShow,
            isLegacyVault
          );
          onUploadStart(action);
        } else {
          onDrawerActionType("upload_revision");
          onUploadFiles(event.target.files);
        }
      }}
      style={{ display: "none" }}
    />
  );
};

export default UploadForm;
