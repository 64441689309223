import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MinusIcon, PlusIcon } from "@akord/addon-icons";

function QuatitySelector({ onQuantityChange }) {
  const [counter, setCounter] = useState(1);
  const [allowDecrement, setAllowDecrement] = useState(false);

  const handleIncrement = () => {
    setCounter(counter + 1);
  };

  const handleDecrement = () => {
    setCounter(counter - 1);
  };

  useEffect(() => {
    setAllowDecrement(counter > 1);
    onQuantityChange(counter);
  }, [counter]);

  return (
    <Box
      mt={3}
      display="flex"
      justifyContent="space-between"
      width="100%"
      alignItems="center"
    >
      <IconButton
        onClick={() => handleDecrement()}
        disabled={!allowDecrement}
        aria-label="close"
        size="large"
      >
        <MinusIcon fontSize="medium" />
      </IconButton>
      <Typography
        variant="body2"
        align="center"
        component="span"
        className="small"
        color="text.secondary"
      >
        Change amount
      </Typography>
      <IconButton
        onClick={() => handleIncrement()}
        aria-label="close"
        size="large"
      >
        <PlusIcon fontSize="default" />
      </IconButton>
    </Box>
  );
}

export default QuatitySelector;
