import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useRef
} from "react";
import { withRouter } from "react-router";
import { itemStatusEnum } from "../helpers/akord-enums.js";
import {
  decryptStacks,
  decryptObjects,
  mimicDecryptStacks,
  mimicDecryptObjects
} from "../helpers/decrypt-helper";
import {
  getComparator,
  getDataRoomId,
  getFolderId,
  stableSort
} from "../helpers/helpers";
import { paginatedAssetsQuery } from "../graphql/queries/utils";
import { filterDecryptedStacks } from "../helpers/stack-helpers";
import { useGlobalContext } from "./GlobalDataProvider";
import { useVaultContext } from "./VaultContextProvider";
const Context = createContext();
const assetsLimit = 40;

function AssetsContextProvider({ children, location }) {
  // State for stack/folder select
  const [selectedItemsMap, setSelectedItemsMap] = useState(new Map());
  const [lastSelectedItem, setLastSelectedItem] = useState(null);
  const handleLastSelectedItem = item => setLastSelectedItem(item);

  const [assetsEncrypter, setAssetsEncrypter] = useState();
  const handleAssetsEncrypter = value => setAssetsEncrypter(value);

  const [assetsDecrypted, setAssetsDecrypted] = useState(false);
  const handleAssetsDecrypted = value => setAssetsDecrypted(value);

  const [decryptedStacks, setDecryptedStacks] = useState([]);
  const handleDecryptedStacks = membership => setDecryptedStacks(membership);

  const [decryptedFolders, setDecryptedFolders] = useState([]);
  const handleDecryptedFolders = folder => setDecryptedFolders(folder);

  const [decryptedNotes, setDecryptedNotes] = useState([]);
  const handleDecryptedNotes = note => setDecryptedNotes(note);

  const [currentPage, setCurrentPage] = React.useState(1);
  const handleCurrentPage = page => setCurrentPage(page);

  const uploader = useRef();
  const onUploadStart = uploaderFunc => (uploader.current = uploaderFunc);

  const [uploadHook, setUploadHook] = useState(null);
  const handleUploadHook = hook => setUploadHook(hook);

  const [showLoader, setShowLoader] = useState(false);
  const handleShowLoader = value => setShowLoader(value);

  const [axiosError, setAxiosError] = useState();
  const handleAxiosError = (err = null) => setAxiosError(err);

  const [filesNumber, setFilesNumber] = useState(0);
  const handleFilesNumber = num => setFilesNumber(num);

  const [drawerActionType, setDrawerActionType] = useState();
  const handleDrawerActionType = action => setDrawerActionType(action);

  const [showUpload, setShowUpload] = useState(false);
  const handleShowUpload = value => setShowUpload(value);

  const [showFilter, setShowFilter] = React.useState(false);
  const handleShowFilter = value => setShowFilter(value);

  const [actionFilters, setActionFilters] = useState([]);
  const handleActionFilters = filters => setActionFilters(filters);

  const [periodFilter, setPeriodFilter] = useState(null);
  const handlePeriodFilter = filters => setPeriodFilter(filters);

  const [order, setOrder] = useState("asc");
  const handleOrder = order => setOrder(order);

  const [orderBy, setOrderBy] = useState("title");
  const handleOrderBy = orderBy => setOrderBy(orderBy);

  const [isShiftDown, setIsShiftDown] = useState(false);

  const {
    decryptedMemberships,
    onTxSpinner,
    // assetsUpdatedHeight,
    // onAssetsUpdatedHeight,
    onEncrypterFromMembership
  } = useGlobalContext();
  const { newAssetsRef } = useVaultContext();

  const dataRoomId = getDataRoomId(location.pathname);
  const folderId = getFolderId(location.pathname);
  const isAssetsTab = location.pathname.includes("/assets");
  const isRevokedAssetsTab = location.pathname.includes("/revoked-files");
  const isGallery = location.pathname.includes("/gallery");

  const currentMembership = decryptedMemberships?.filter(
    membership => membership.dataRoomId === dataRoomId
  )[0];
  const isVaultPublic = currentMembership?.dataRoom.state.isPublic;

  useEffect(() => {
    if (
      (isAssetsTab || isRevokedAssetsTab || isGallery) &&
      decryptedMemberships
    ) {
      fetchAndDecryptAssets();
    }
  }, [decryptedMemberships, isAssetsTab, isRevokedAssetsTab, dataRoomId]);

  // Clear assets on page change
  React.useEffect(() => {
    return () => {
      if (!newAssetsRef.current) {
        handleAssetsDecrypted(false);
        handleDecryptedStacks([]);
        handleDecryptedNotes([]);
        handleDecryptedFolders([]);
        newAssetsRef.current = true;
      }
    };
  }, [dataRoomId]);

  // useEffect(() => {
  //   if (currentMembership && Object.keys(currentMembership).length === 0)
  //     return;

  //   if (assetsUpdatedHeight) {
  //     onAssetsUpdatedHeight(null);
  //     fetchAndDecryptAssets(true);
  //   }
  // }, [assetsUpdatedHeight]);

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []);

  const fetchAndDecryptAssets = async (isSub = false) => {
    if (
      !currentMembership ||
      Object.keys(currentMembership).length === 0 ||
      (!newAssetsRef.current && !isSub)
    )
      return;

    const { stacks, folders, notes } = await paginatedAssetsQuery(dataRoomId);
    const encrypter = onEncrypterFromMembership(currentMembership);
    handleAssetsEncrypter(encrypter);

    if (stacks.length > 0) {
      let decryptedStacksArray;
      if (!isVaultPublic) {
        decryptedStacksArray = await decryptStacks(encrypter, stacks, {
          dataRoomId: currentMembership.dataRoom?.id,
          dataRoomName: currentMembership.dataRoom?.state.title,
          dataRoomStatus: currentMembership.dataRoom?.state.status
        });
      } else {
        const decryptedStacksArrayDecrypt = await mimicDecryptStacks(stacks, {
          dataRoomId: currentMembership.dataRoom.id,
          dataRoomName: currentMembership.dataRoom.state.title,
          dataRoomStatus: currentMembership.dataRoom.state.status
        });
        decryptedStacksArray = decryptedStacksArrayDecrypt;
      }

      // Overwrite "null" folderId to null to correctly filter the view
      decryptedStacksArray.map(stack => {
        if (stack.folderId === "null") return (stack.folderId = null);
      });
      handleDecryptedStacks(decryptedStacksArray);
    }
    if (folders.length > 0) {
      let decryptedFoldersArray;
      if (!isVaultPublic) {
        decryptedFoldersArray = await decryptStacks(encrypter, folders, {
          dataRoomId: currentMembership.dataRoom.id,
          dataRoomName: currentMembership.dataRoom.state.title,
          dataRoomStatus: currentMembership.dataRoom.state.status
        });
      } else
        decryptedFoldersArray = mimicDecryptStacks(folders, {
          dataRoomId: currentMembership.dataRoom.id,
          dataRoomName: currentMembership.dataRoom.state.title,
          dataRoomStatus: currentMembership.dataRoom.state.status
        });
      // Add type here to filter later and overwrite "null"
      // folderId to null to correctly filter the view
      decryptedFoldersArray.map(folder => {
        if (folder.folderId === "null")
          return (folder.folderId = null), (folder.type = "folder");
        else return (folder.type = "folder");
      });
      handleDecryptedFolders(decryptedFoldersArray);
    } else handleDecryptedFolders([]);

    if (notes.length > 0) {
      let decryptedNotesArray;
      if (!isVaultPublic) {
        decryptedNotesArray = await decryptObjects(encrypter, notes, [
          "title",
          "content"
        ]);
      } else {
        decryptedNotesArray = mimicDecryptObjects(notes);
      }
      // Add type here to filter later and overwrite "null"
      // folderId to null to correctly filter the view
      decryptedNotesArray.map(note => {
        if (note.folderId === "null" || !note.folderId)
          return (note.folderId = null), (note.type = "note");
        else return (note.type = "note");
      });

      handleDecryptedNotes(decryptedNotesArray);
    } else handleDecryptedNotes([]);

    newAssetsRef.current = false;
    handleAssetsDecrypted(true);
    onTxSpinner(false); //hide tx spinner
  };

  const allDecryptedAssets = [
    ...decryptedStacks,
    ...decryptedFolders,
    ...decryptedNotes
  ];

  const revokedDecryptedAssets = allDecryptedAssets.filter(
    asset => asset.status === itemStatusEnum.REVOKED
  );

  const handleSelectedItems = (hash, checked, revoked) => {
    if (hash === "allItemsOnPage") {
      if (checked) {
        const assetsIds = revoked
          ? revokedDecryptedAssets.reduce((acc, item) => {
              // if (
              //   // item.folderId === folderId &&
              //   item.status === itemStatusEnum.REVOKED
              // )
              return acc.set(item.hash, getAssetType(item));
              // else return acc;
            }, new Map())
          : getPaginatedData().reduce((acc, item) => {
              if (item.folderId === folderId)
                return acc.set(item.hash, getAssetType(item));
              else return acc;
            }, new Map());

        setSelectedItemsMap(assetsIds);
      } else {
        setSelectedItemsMap(new Map(selectedItemsMap.clear()));
      }
    } else if (hash === "allItems") {
      if (revoked) {
        if (selectedItemsMap.size < revokedDecryptedAssets.length) {
          const assetsIds = revokedDecryptedAssets.reduce((acc, item) => {
            // if (
            //   // item.folderId === folderId &&
            //   item.status === itemStatusEnum.REVOKED
            // )
            return acc.set(item.hash, getAssetType(item));
            // else return acc;
          }, new Map());

          setSelectedItemsMap(assetsIds);
        } else {
          setSelectedItemsMap(new Map(selectedItemsMap.clear()));
        }
      } else {
        if (selectedItemsMap.size < filteredSortedActiveAssets.length) {
          const assetsIds = filteredSortedActiveAssets.reduce((acc, item) => {
            if (item.folderId === folderId)
              return acc.set(item.hash, getAssetType(item));
            else return acc;
          }, new Map());
          setSelectedItemsMap(assetsIds);
        } else {
          setSelectedItemsMap(new Map(selectedItemsMap.clear()));
        }
      }
    } else if (hash === "reset") {
      setSelectedItemsMap(new Map(selectedItemsMap.clear()));
    } else {
      const hasBeenSelected = selectedItemsMap.has(hash);

      if (isShiftDown) {
        // Multiple items select
        const newSelectedItems = getNewSelectedItems(hash);
        let selections = new Map([...selectedItemsMap, ...newSelectedItems]);
        // Unselect
        if (hasBeenSelected) {
          selections = new Map(
            Array.from(selections.entries()).filter(
              ([key]) => !newSelectedItems.has(key)
            )
          );
        }
        setSelectedItemsMap(selections);
      } else {
        // Single item select
        const selectedItemsMapCopy = new Map(selectedItemsMap);
        const item = revoked
          ? revokedDecryptedAssets.filter(item => item?.hash === hash)[0]
          : getPaginatedData().filter(item => item.hash === hash)[0];

        selectedItemsMapCopy.has(hash)
          ? selectedItemsMapCopy.delete(hash)
          : selectedItemsMapCopy.set(item?.hash, getAssetType(item));

        setSelectedItemsMap(selectedItemsMapCopy);
      }
    }
  };

  const getAssetType = item => {
    if (!item) return;
    return item.files ? "stack" : item.type;
  };

  const getNewSelectedItems = hash => {
    const visibleItems = isRevokedAssetsTab
      ? filteredSortedRevokedAssets
      : getPaginatedData();

    const currentSelectedIndex = visibleItems.findIndex(
      item => item.hash === hash
    );
    const lastSelectedIndex = visibleItems.findIndex(
      item => item.hash === lastSelectedItem
    );
    return visibleItems
      .slice(
        Math.min(lastSelectedIndex, currentSelectedIndex),
        Math.max(lastSelectedIndex, currentSelectedIndex) + 1
      )
      .reduce((acc, item) => acc.set(item.hash, getAssetType(item)), new Map());
  };

  const activeDecryptedStacks = decryptedStacks.filter(
    stack =>
      stack.status !== itemStatusEnum.REVOKED &&
      stack.status !== itemStatusEnum.DELETED
  );

  const activeDecryptedFolders = decryptedFolders.filter(
    folder =>
      folder.status !== itemStatusEnum.REVOKED &&
      folder.status !== itemStatusEnum.DELETED
  );

  const activeDecryptedNotes = decryptedNotes.filter(
    note =>
      note.status !== itemStatusEnum.REVOKED &&
      note.status !== itemStatusEnum.DELETED
  );

  const activeDecryptedAssets = [
    ...activeDecryptedStacks,
    ...activeDecryptedFolders,
    ...activeDecryptedNotes
  ].filter(item => {
    if (item.folderId === undefined && !folderId) return item;
    else return item.folderId === folderId;
  });

  const filteredSortedActiveAssets = stableSort(
    filterDecryptedStacks(activeDecryptedAssets, periodFilter, actionFilters),
    getComparator(order, orderBy)
  );

  const filteredSortedRevokedAssets = stableSort(
    filterDecryptedStacks(revokedDecryptedAssets, periodFilter, actionFilters),
    getComparator(order, orderBy)
  );

  const pages =
    Math.ceil(filteredSortedActiveAssets.length / assetsLimit) || null;

  // Empty batch selected items on route change
  // Reset Assets pagination
  useEffect(() => {
    handleSelectedItems("reset");
    handleCurrentPage(1);
  }, [location.pathname, decryptedMemberships]);

  const goToNextPage = () => {
    handleCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    handleCurrentPage(currentPage - 1);
  };

  const changePage = event => {
    const selectValue = event.target?.value ? event.target.value : event;
    handleCurrentPage(Number(selectValue));
  };

  const getPaginatedData = () => {
    const startIndex = currentPage * assetsLimit - assetsLimit;
    const endIndex = startIndex + assetsLimit;
    return filteredSortedActiveAssets.slice(startIndex, endIndex);
  };

  // For Batch selection
  const downHandler = ({ key }) => {
    if (key === "Shift") {
      setIsShiftDown(true);
    }
  };

  const upHandler = ({ key }) => {
    if (key === "Shift") {
      setIsShiftDown(false);
    }
  };

  return (
    <Context.Provider
      value={{
        onDecryptedStacks: handleDecryptedStacks,
        decryptedStacks: decryptedStacks,
        decryptedFolders: decryptedFolders,
        decryptedNotes: decryptedNotes,
        activeDecryptedNotes: activeDecryptedNotes,
        activeDecryptedFolders: activeDecryptedFolders,
        activeDecryptedStacks: activeDecryptedStacks,
        decryptedAssets: activeDecryptedAssets,
        filteredSortedActiveAssets: filteredSortedActiveAssets,
        assetsDecrypted: assetsDecrypted,
        assetsEncrypter: assetsEncrypter,
        onSelectedItems: handleSelectedItems,
        selectedItemsMap: selectedItemsMap,
        isShiftDown: isShiftDown,
        onLastSelectedItem: handleLastSelectedItem,
        onGoToNextPage: goToNextPage,
        onGoToPreviousPage: goToPreviousPage,
        onChangePage: changePage,
        pages: pages,
        getPaginatedData: getPaginatedData,
        currentPage: currentPage,
        showLoader: showLoader,
        onShowLoader: handleShowLoader,
        axiosError: axiosError,
        onAxiosError: handleAxiosError,
        filesNumber: filesNumber,
        onFilesNumber: handleFilesNumber,
        drawerActionType: drawerActionType,
        onDrawerActionType: handleDrawerActionType,
        showUpload: showUpload,
        onShowUpload: handleShowUpload,
        uploader: uploader,
        onUploadStart: onUploadStart,
        uploadHook: uploadHook,
        onUploadHook: handleUploadHook,
        showFilter: showFilter,
        onShowFilter: handleShowFilter,
        actionFilters: actionFilters,
        onActionFilters: handleActionFilters,
        periodFilter: periodFilter,
        onPeriodFilter: handlePeriodFilter,
        order: order,
        onOrder: handleOrder,
        orderBy: orderBy,
        onOrderBy: handleOrderBy,
        assetsLimit: assetsLimit
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default withRouter(AssetsContextProvider);

export const withAssetsContext = Component => props =>
  (
    <Context.Consumer>
      {assetsContext => <Component {...props} {...assetsContext} />}
    </Context.Consumer>
  );

export const useAssetsContext = () => useContext(Context);
