/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:2cb4571c-1a70-4b78-b0db-6a0130af18c3",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_glTrP1Kin",
    "aws_user_pools_web_client_id": "7u2a1pf5i6shfo7enci6bagk7u",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://ib4g6n5wejax7oj646hhbbhfky.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-r4imvz76mnd5dhkyucbo7dvhkm",
    "aws_cloud_logic_custom": [
        {
            "name": "permapost",
            "endpoint": "https://xnpmgutvij.execute-api.eu-central-1.amazonaws.com",
            "region": "eu-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "akordampdev140050-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
