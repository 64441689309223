import React from "react";
import {
  getFolderId,
  getDataRoomId,
  getDataRoomStatus
} from "../../../helpers/helpers";
import {
  noteMenuActiveOptions,
  noteMenuRevokedOptions
} from "./note-menu-options";
// import { useGlobalContext } from '../../../contexts/GlobalDataProvider'
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import MoreMenuRoot from "./MoreMenuRoot";
import MoreMenuItem from "./MoreMenuItem";
import { useLocation, useHistory } from "react-router";
import { isArchived } from "../../../helpers/helpers";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";

const MenuNote = ({ anchorEl, handleMenuClose, openMenu, revoked, note }) => {
  // const { decryptedProfileDetails, onUserAttributes } = useGlobalContext()
  const { userRole, currentMembership, isVaultPublic } = useVaultContext();
  const { decryptedFolders } = useAssetsContext();
  const { modal, onNotificationData } = useNotificationsContext();
  const history = useHistory();
  // const userRole = getUserRole(
  //   currentMembership ? currentMembership : [],
  //   decryptedProfileDetails
  // )
  const isRoomArchived = isArchived(currentMembership);

  const location = useLocation();
  const folderId = getFolderId(location.pathname);
  const roomId = getDataRoomId(location.pathname);
  const roomStatus = getDataRoomStatus(location.pathname);

  const noteMenuOptions = revoked
    ? noteMenuRevokedOptions
    : noteMenuActiveOptions;

  if (!openMenu) return null;

  return (
    <MoreMenuRoot
      anchorEl={anchorEl}
      handleMenuClose={handleMenuClose}
      openMenu={openMenu}
    >
      <div>
        {noteMenuOptions(
          history,
          handleMenuClose,
          folderId,
          roomId,
          roomStatus,
          note,
          modal.onModalVisibility,
          modal.onConfirmModalType,
          onNotificationData,
          decryptedFolders,
          userRole,
          isRoomArchived,
          isVaultPublic,
          modal.onConfirmModalFormType
        ).map(
          (menuItem, index) =>
            menuItem.show && <MoreMenuItem key={index} menuItem={menuItem} />
        )}
      </div>
    </MoreMenuRoot>
  );
};

export default MenuNote;
