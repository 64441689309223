import React from "react";
import { Auth } from "aws-amplify";
import { Box, Typography, IconButton } from "@mui/material";
import { CloseIcon } from "@akord/addon-icons";
import { akordLinks } from "../../helpers/akordTexts";
import { grey } from "../../theme/colors";

function AnnouncementBanner({ darkMode, isMobile, onUserAttributes }) {
  const handleCloseAnnouncement = async () => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      "custom:showV2Announcment": "false"
    });
    onUserAttributes({ showV2Announcment: false });
  };

  return (
    <Box
      backgroundColor="background.announcement"
      justifyContent="center"
      alignItems="center"
      display="flex"
      position="fixed"
      height={isMobile ? "48px" : "40px"}
      width="100%"
      zIndex="100"
      pl={4}
      pr={isMobile ? 8 : 4}
      py={2}
    >
      <Typography
        variant="caption"
        color="text.announcement"
        component="span"
        position="relative"
      >
        Our v2 has been released in public beta. Check it out{" "}
        <Typography
          variant="caption"
          color="text.announcement"
          component="a"
          onClick={() => window.open(akordLinks["v2"])}
        >
          here
        </Typography>{" "}
        and read our blog post{" "}
        <Typography
          variant="caption"
          color="text.announcement"
          component="a"
          onClick={() => window.open(akordLinks["v2Blog"])}
        >
          here.
        </Typography>
      </Typography>
      <IconButton
        onClick={handleCloseAnnouncement}
        size="large"
        sx={{
          position: "absolute",
          right: "20px",
          "&:hover": {
            color: darkMode ? grey[600] : grey[200]
          }
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
}

export default AnnouncementBanner;
