import React from 'react'
import MoreMenuRoot from './MoreMenuRoot'
import MoreMenuItem from './MoreMenuItem'
import { notificationsMenuOptions } from './notification-menu-options'

const MenuNotifications = ({ anchorEl, openMenu, handleMenuClose }) => {
  return (
    <MoreMenuRoot
      anchorEl={anchorEl}
      openMenu={openMenu}
      handleMenuClose={handleMenuClose}
    >
      <div>
        {notificationsMenuOptions(handleMenuClose).map((menuItem, index) => {
          return (
            menuItem.show && (
              <MoreMenuItem
                key={index}
                menuItem={menuItem}
                handleMenuClose={handleMenuClose}
              />
            )
          )
        })}
      </div>
    </MoreMenuRoot>
  )
}

export default MenuNotifications
