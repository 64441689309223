import React from "react";
import { ChevronUpIcon, ChevronDownIcon } from "@akord/addon-icons";
import makeStyles from "@mui/styles/makeStyles";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Chip
} from "@mui/material";
import { useFileViewerContext } from "../../../contexts/FileViewerContextProvider";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { getCorrectExtForVersion } from "./file-viewer-helper";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles(theme => ({
  fileList: {
    position: "absolute",
    top: 0,
    left: ({ isMobile }) => (isMobile ? "none" : "50%"),
    transform: ({ isMobile }) => (isMobile ? "none" : "translate(-50%, 0)"),
    borderRadius: 4,
    backgroundColor: ({ darkMode }) =>
      darkMode ? theme.palette.background.dark : theme.palette.background.card,
    marginLeft: 0,
    width: ({ isMobile }) => (isMobile ? "calc(100% - 32px)" : "400px"),
    zIndex: 1
  },
  stackIcon: {
    color: theme.palette.info.secondary
  },
  listItem: {
    alignItems: "center",
    padding: theme.spacing(3),
    paddingRight: theme.spacing(2),
    height: "44px",
    "&:first-child": {
      paddingTop: theme.spacing(3)
    }
  }
}));

function FileViewerSelect({ FileIcon, handleSelectFile, onlyDarkMode }) {
  const { activeStack, onOpenFileList, openFileList } = useFileViewerContext();
  const { isMobile } = useGlobalContext();
  const classes = useStyles({ isMobile: isMobile, darkMode: onlyDarkMode });

  const handleFileListClick = () => {
    if (activeStack?.items.length > 1) onOpenFileList(!openFileList);
  };

  return (
    <div className={classes.fileList}>
      <List component="nav" disablePadding aria-labelledby="file-list">
        <ListItem
          disableGutters
          divider={openFileList}
          button
          onClick={handleFileListClick}
          className={classes.listItem}
        >
          <ListItemIcon style={{ marginRight: 8 }}>
            <FileIcon className={classes.stackIcon} />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              variant: "body2",
              className: "small",
              color: "text.primary",
              noWrap: true
            }}
            primary={
              //show main title if no revisions - otherwise original title
              // activeStack && activeStack.items && activeStack.items.length === 1
              //   ? activeStack.title
              //   : fileViewerData && fileViewerData.title
              Object.keys(activeStack).length !== 0 ? (
                getCorrectExtForVersion(0, activeStack)
              ) : (
                <Skeleton
                  variant="text"
                  style={{ backgroundColor: "#53515C" }}
                  width="90%"
                />
              )
            }
          />
          {((activeStack.items && activeStack.items.length > 1) ||
            activeStack.resourceVersion > 1) && (
            <Chip
              size="small"
              variant={
                (activeStack &&
                  activeStack.items &&
                  activeStack.items.length > 0 &&
                  activeStack.items[0].originalIndex !==
                    activeStack.items.length - 1) ||
                activeStack.items.length == 1
                  ? "outlined"
                  : "default"
              }
              label={
                activeStack.items &&
                activeStack.items[0] &&
                activeStack.items.length > 1
                  ? activeStack.items[0].originalIndex ===
                    activeStack.items.length - 1
                    ? "Latest"
                    : "V" + (activeStack.items[0].originalIndex + 1)
                  : activeStack.resourceVersion > 1
                  ? "V" + activeStack.resourceVersion
                  : null
              }
              color="primary"
              style={{ marginRight: "6px", marginLeft: "5px" }}
            />
          )}
          {activeStack.items && activeStack.items.length > 1 ? (
            openFileList ? (
              <ChevronUpIcon />
            ) : (
              <ChevronDownIcon />
            )
          ) : null}
        </ListItem>
        <Collapse in={openFileList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {activeStack &&
              activeStack.items &&
              activeStack.items.length > 0 &&
              activeStack.items.map((file, index) =>
                index === 0 ? null : (
                  <div key={index}>
                    <ListItem
                      divider={activeStack.items.length - 1 !== index}
                      disableGutters
                      button
                      className={classes.listItem}
                      onClick={() => handleSelectFile(index)}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          variant: "body2",
                          className: "small",
                          color: "text.primary",
                          noWrap: true
                        }}
                        primary={
                          // activeStack && activeStack.items[index].originalTitle
                          getCorrectExtForVersion(index, activeStack)
                        }
                      />
                      <Chip
                        size="small"
                        color="primary"
                        variant={
                          file.originalIndex !== activeStack.items.length - 1
                            ? "outlined"
                            : "default"
                        }
                        label={
                          file.originalIndex !== activeStack.items.length - 1
                            ? "V" + (file.originalIndex + 1)
                            : "Latest"
                        }
                        style={{ marginRight: "30px", marginLeft: "5px" }}
                      />
                    </ListItem>
                  </div>
                )
              )}
          </List>
        </Collapse>
      </List>
    </div>
  );
}

export default FileViewerSelect;
