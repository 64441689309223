import { getDataRoomStatus } from "../../../helpers/helpers";
import {
  AddMemberIcon,
  ArchiveIcon,
  KeyIcon,
  MinusInCircleIcon,
  PenIcon,
  PlayIcon,
  RestoreIcon,
  RevokedFilesIcon,
  TeamIcon,
  TimelineIcon,
  VaultLeaveIcon
} from "@akord/addon-icons";
import { Auth } from "aws-amplify";

export const roomMenuOptions = (
  currentMembership,
  userRole,
  isRoomArchived,
  setVisibility,
  setData,
  setConfirmType,
  history,
  handleMenuClose,
  setConfirmFormType,
  folderId,
  onUserAttributes
) => [
  {
    text: "Invite",
    icon: AddMemberIcon,
    action: () =>
      history.push({
        pathname: `/vaults/${getDataRoomStatus(history.location.pathname)}/${
          currentMembership.dataRoomId
        }/invite-to-vault`,
        state: currentMembership
      }),
    disabled: userRole !== "OWNER",
    show: !isRoomArchived
  },
  {
    text:
      userRole !== "OWNER" || isRoomArchived ? "View team" : "Manage access",
    icon: userRole !== "OWNER" || isRoomArchived ? TeamIcon : KeyIcon,
    action: () =>
      history.push({
        pathname: `/vaults/${getDataRoomStatus(history.location.pathname)}/${
          currentMembership.dataRoomId
        }/manage-access`,
        state: currentMembership
      }),
    show: true
  },
  {
    text: "Rename vault",
    icon: PenIcon,
    action: () => {
      setConfirmFormType("dataRoomRename");
      setVisibility(null, "dataRoomRename");
      setData({
        title: currentMembership.dataRoom.state.title,
        hash: currentMembership.dataRoom.hash
      });
      handleMenuClose();
    },
    disabled: userRole !== "OWNER",
    show: !isRoomArchived
  },
  {
    text: "View revoked files",
    icon: RevokedFilesIcon,
    action: () =>
      history.push({
        pathname: `/vaults/${getDataRoomStatus(history.location.pathname)}/${
          currentMembership.dataRoomId
        }/revoked-files`,
        state: currentMembership
      }),
    show: true
  },
  {
    text: "Timeline",
    icon: TimelineIcon,
    action: () =>
      history.push({
        pathname: `/vaults/${getDataRoomStatus(history.location.pathname)}/${
          currentMembership.dataRoomId
        }/timeline`,
        state: currentMembership
      }),
    show: true
  },
  {
    text: "Onboarding tour",
    icon: PlayIcon,
    action: async () => {
      handleMenuClose();
      onUserAttributes({ userOnboarding: true });
      history.push({
        pathname: `/vaults/${getDataRoomStatus(history.location.pathname)}/${
          currentMembership.dataRoomId
        }/assets`
      });
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        "custom:onboarding": "true"
      });
    },
    show: true
  },
  {
    text: isRoomArchived ? "Restore vault" : "Archive vault",
    icon: isRoomArchived ? RestoreIcon : ArchiveIcon,
    action: () => {
      setConfirmType(isRoomArchived ? "restore" : "archive");
      setVisibility(null, isRoomArchived ? "restore" : "archive");
      setData({
        title: currentMembership.dataRoom.state.title,
        hash: currentMembership.dataRoom.hash
      });
      handleMenuClose();
    },
    disabled: userRole !== "OWNER",
    show: userRole === "OWNER"
  },
  {
    text: "Remove vault",
    icon: MinusInCircleIcon,
    action: () => {
      setConfirmType("delete");
      setVisibility(null, "delete");
      setData({
        title: currentMembership.dataRoom.state.title,
        hash: currentMembership.dataRoom.hash
      });
      handleMenuClose();
    },
    disabled: userRole !== "OWNER",
    show: isRoomArchived && userRole === "OWNER"
  },
  {
    text: "Leave vault",
    icon: VaultLeaveIcon,
    action: () => {
      setConfirmType("leave");
      setVisibility(null, "leave");
      setData({
        title: currentMembership.dataRoom.state.title,
        hash: currentMembership.hash
      });
      handleMenuClose();
    },
    show: userRole !== "OWNER"
  }
];
