import React from "react";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";
import { snackbarOptions } from "./snackbar-options";
import { SnackbarNotification } from "@akord/ui";

function SnackBarConfirm() {
  const { snackbarToShow, onSnackbarClose } = useSnackbarContext();

  return (
    <SnackbarNotification
      snackbarViewProps={snackbarOptions[snackbarToShow.type]}
      onSnackbarClose={onSnackbarClose}
      snackbarData={snackbarToShow}
      snackbarShow={snackbarToShow.open}
    />
  );
}

export default SnackBarConfirm;
