import { Box } from '@mui/material'
import React from 'react'
import SearchBox from '../../components/search/SearchBox'

function ShowSearch() {

  return (
    <Box margin="10px">
      <SearchBox forceActive="true"></SearchBox>
    </Box>
  )
}

export default ShowSearch
