export const storageTransactionsByPublicSigningKey = /* GraphQL */ `
  query StorageTransactionsByPublicSigningKey(
    $publicSigningKey: String
    $sortDirection: ModelSortDirection
    $filter: ModelStorageTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    storageTransactionsByPublicSigningKey(
      publicSigningKey: $publicSigningKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        refId
        refHash
        publicSigningKey
        dataRoomId
        stackId
        type
        status
        timestamp
        amount
        createdAt
        updatedAt
        memberships(
          filter: { memberPublicSigningKey: { eq: $publicSigningKey } }
        ) {
          items {
            state {
              keys {
                encPrivateKey
                publicKey
              }
              encryptionType
              encAccessKey
            }
            dataRoom {
              id
              state {
                title
                status
                isPublic
                publicKeys
              }
            }
          }
        }
        note {
          id
          state {
            status
            title
            resourceVersion
            size
            content
            revisions {
              hash
              title
            }
          }
        }
        stack {
          id
          state {
            title
            description
            files {
              title
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getStorage = /* GraphQL */ `
  query GetStorage($publicSigningKey: String!) {
    getStorage(publicSigningKey: $publicSigningKey) {
      publicSigningKey
      total_storage
      storage_available
      storage_used
      documents
      photos
      notes
      audio
      video
      other
      createdAt
      updatedAt
    }
  }
`;
