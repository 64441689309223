import { grey } from "../../theme/colors";
import akordTheme from "../../theme/mui";

const rte = darkMode => {
  return {
    DraftEditor: {
      styleOverrides: {
        editorContainer: {
          color: darkMode ? grey[300] : grey[600]
        }
      }
    },
    MUIRichTextEditor: {
      styleOverrides: {
        container: {
          margin: 0
        },
        editorContainer: {
          border: "1px solid ",
          borderColor: darkMode ? grey[600] : grey[300],
          borderRadius: "4px",
          padding: "20px",
          fontSize: "1rem",
          lineHeight: 1.5,
          color: darkMode ? grey[300] : grey[600],
          height: "128px",
          minHeight: "128px",
          "@media(max-width:668px)": { minHeight: "208px" },
          maxHeight: "304px",
          overflowY: "auto",
          resize: "vertical",
          backgroundImage: "url(./img/resize_handle.svg)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "99.5% 99%",
          backgroundSize: "4%",
          "& li": {
            marginBottom: 8
          },
          "& ul": {
            paddingInlineStart: "24px"
          }
        },
        toolbar: {
          width: "352px",
          "@media(max-width:668px)": { width: "100%" },
          marginBottom: "8px",
          height: "35px",
          border: "1px solid",
          borderColor: darkMode ? grey[600] : grey[300],
          borderRadius: "4px",
          padding: "0 10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        },
        placeHolder: {
          position: "inherit",
          color: darkMode ? grey[400] : grey[500]
        }
      }
    }
  };
};

export const rteAkordTheme = darkMode => {
  return {
    ...akordTheme(darkMode),
    components: {
      ...akordTheme(darkMode).components,
      ...rte(darkMode)
    }
  };
};

export const rteStyleMap = {
  BOLD: {
    fontFamily: [
      "Larsseit-Bold",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen-Sans",
      "Ubuntu",
      "Cantarell",
      "Helvetica Neue",
      "sans-serif"
    ].join(",")
  },
  ITALIC: {
    fontFamily: [
      "Larsseit-Italic",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen-Sans",
      "Ubuntu",
      "Cantarell",
      "Helvetica Neue",
      "sans-serif"
    ].join(",")
  },
  TITLE: {
    fontFamily: [
      "Larsseit-Bold",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen-Sans",
      "Ubuntu",
      "Cantarell",
      "Helvetica Neue",
      "sans-serif"
    ].join(","),
    margin: 0,
    fontSize: "24px"
  }
};
