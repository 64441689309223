import React from "react";
import { withRouter } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import {
  Typography,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import {
  VaultIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  BellIcon,
  SettingsIcon,
  StorageIcon
} from "@akord/addon-icons";
import {
  stableSort,
  getComparator,
  getDataRoomId
} from "../../../helpers/helpers";
import { memberStatusEnum } from "../../../helpers/akord-enums";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { TopBarMain } from "..";
import { useInAppNotificationsContext } from "../../../contexts/InAppNotificationsContext";

const drawerWidth = 250;

const useStyles = makeStyles(theme =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      borderRadius: 0,
      width: drawerWidth,
      top: "inherit"
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    itemIcon: {
      margin: "0 5px"
    },
    anchorOriginTopRightCircle: {
      top: "28%",
      right: "-3%",
      transform: "scale(1) translate(100%, -60%)",
      borderColor: ({ darkMode }) => (darkMode ? "transparent" : "inherit")
    },
    itemList: {
      alignItems: "center"
      // '&:hover': {
      //   '& $itemText': {
      //     // color: '#333333',
      //   },
      // },
    },
    itemText: {
      transition: ".3s ease",
      "&:hover": {
        color: ({ darkMode }) => (darkMode ? "#A3A09F" : "#333333")
      }
    },
    logo: {
      color: ({ darkMode }) =>
        darkMode ? "white" : theme.palette.background.secondary,
      display: "block"
    },
    iconHover: {
      "&:hover": {
        color: theme.palette.text.primary
      }
    },
    badgeDot: {
      marginTop: "1px"
    }
  })
);

function MenuDrawerMd({ history, location }) {
  const { roomsMenu, decryptedMemberships, darkMode } = useGlobalContext();
  const { notificationsLog } = useInAppNotificationsContext();
  const classes = useStyles({ darkMode: darkMode });

  const isSelected = roomState => {
    return getDataRoomId(location.pathname) === roomState.dataRoomId;
  };

  //reformat dataRoom.state to handle sorting
  const roomsDetails =
    decryptedMemberships?.length > 0 &&
    decryptedMemberships
      .filter(membership => membership.status === memberStatusEnum.ACCEPTED)
      .map(membership => {
        return {
          ...membership.dataRoom.state,
          dataRoomId: membership.dataRoomId
        };
      });

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <TopBarMain />
      <List>
        <ListItem button>
          <ListItemIcon>
            <VaultIcon
              fontSize="small"
              color={
                location.pathname === "/" || location.pathname.match("/vaults")
                  ? "action"
                  : "disabled"
              }
            />
          </ListItemIcon>
          <ListItemText
            disableTypography
            onClick={() => {
              history.push("/vaults/active");
            }}
          >
            <Typography
              component="span"
              variant="body2"
              className="small"
              color={
                location.pathname === "/" || location.pathname.match("/vaults")
                  ? "text.primary"
                  : "inherit"
              }
            >
              Vaults
            </Typography>
          </ListItemText>
          <ListItemIcon style={{ marginRight: 0 }}>
            {roomsMenu.roomsMenuOpen ? (
              <ChevronUpIcon
                className={classes.iconHover}
                color="disabled"
                onClick={roomsMenu.onDataRoomsExpand}
              />
            ) : (
              <ChevronDownIcon
                className={classes.iconHover}
                color="disabled"
                onClick={roomsMenu.onDataRoomsExpand}
              />
            )}
          </ListItemIcon>
        </ListItem>
        <Collapse in={roomsMenu.roomsMenuOpen} timeout="auto" unmountOnExit>
          <List component="div">
            {roomsDetails &&
              stableSort(roomsDetails, getComparator("asc", "title"))
                .filter(room => room.status === "ACTIVE")
                .map((room, index) => (
                  <ListItem
                    dense
                    button
                    disableTouchRipple
                    key={index}
                    selected={isSelected(room)}
                  >
                    <ListItemText
                      disableTypography
                      inset
                      onClick={() =>
                        history.push(`/vaults/active/${room.dataRoomId}/assets`)
                      }
                    >
                      <Badge
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left"
                        }}
                        overlap="circular"
                        color="warning"
                        variant="dot"
                        classes={{ badge: classes.badgeDot }}
                        invisible={
                          !notificationsLog ||
                          notificationsLog?.filter(
                            log =>
                              log.status === "UNREAD" &&
                              room.dataRoomId === log.dataRoomId
                          ).length === 0
                        }
                      >
                        <Typography
                          component="span"
                          variant="body2"
                          className="small"
                          color="inherit"
                        >
                          {room.title}
                        </Typography>
                      </Badge>
                    </ListItemText>
                  </ListItem>
                ))}
          </List>
        </Collapse>
        <ListItem
          button
          onClick={() => {
            history.push("/storage");
          }}
        >
          <ListItemIcon>
            <StorageIcon
              fontSize="small"
              color={
                location.pathname.match("/storage") ? "action" : "disabled"
              }
            />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography
              component="span"
              className={["small", "step6"].join(" ")}
              variant="body2"
              color={
                location.pathname === "/storage" ? "text.primary" : "inherit"
              }
            >
              Storage
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push("/notifications");
          }}
        >
          <ListItemIcon>
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              color="warning"
              overlap="circular"
              variant="dot"
              invisible={
                !notificationsLog ||
                notificationsLog?.filter(log => log.status === "UNREAD")
                  .length === 0
              }
            >
              <BellIcon
                fontSize="small"
                color={
                  location.pathname === "/notifications" ? "action" : "disabled"
                }
              />
            </Badge>
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography
              component="span"
              className={["small", "step7"].join(" ")}
              variant="body2"
              color={
                location.pathname === "/notifications"
                  ? "text.primary"
                  : "inherit"
              }
            >
              Notifications
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push("/settings");
          }}
        >
          <ListItemIcon>
            <SettingsIcon
              fontSize="small"
              color={
                location.pathname.match("/settings") ? "action" : "disabled"
              }
            />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography
              component="span"
              className={["small", "step8"].join(" ")}
              variant="body2"
              color={
                location.pathname === "/settings" ? "text.primary" : "inherit"
              }
            >
              Settings
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
    </Drawer>
  );
}

export default withRouter(MenuDrawerMd);
