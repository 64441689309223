import React from "react";
import {
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { dropdownMenuData } from "./dropownMenuData";
import { useHistory, useLocation } from "react-router-dom";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import { getFolderId } from "../../../helpers/helpers";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { useVaultContext } from "../../../contexts/VaultContextProvider";

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.secondary,
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(2)
  },
  menuItem: {
    // We need more padding before the divider for the last element in the menu
    "&:last-of-type": {
      paddingTop: ({ isMobile }) =>
        isMobile ? theme.spacing(4) : theme.spacing(3)
    },
    "&:hover": {
      "& $icon": {
        color: theme.palette.text.primary
      }
    }
  },
  menuItemDisabled: {
    // We need more padding before the divider for the last element in the menu
    "&:last-of-type": {
      paddingTop: ({ isMobile }) =>
        isMobile ? theme.spacing(4) : theme.spacing(3)
    },
    cursor: "auto"
  },
  icon: {
    transition: ".3s ease",
    marginBottom: "2px"
  }
}));

function DropdownMenu({ anchorEl, onClose, openMenu, type, position }) {
  const history = useHistory();
  const { darkMode, onIsAuthenticated, isMobile, userAttributes } =
    useGlobalContext();
  const { onShowUpload } = useAssetsContext();
  const { modal, onNotificationData } = useNotificationsContext();
  const { currentMembership } = useVaultContext();
  const classes = useStyles({ darkMode: darkMode, isMobile: isMobile });
  const location = useLocation();
  const folderId = getFolderId(location.pathname);
  const menuData = dropdownMenuData[type];
  const referralsCount = userAttributes.referrals;

  return (
    <Menu
      elevation={8}
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: position
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: position
      }}
      open={openMenu}
      onClose={onClose}
      classes={{ paper: classes.paper }}
    >
      {menuData(
        history,
        onIsAuthenticated,
        onShowUpload,
        onClose,
        folderId,
        modal.onConfirmModalFormType,
        modal.onModalVisibility,
        onNotificationData,
        currentMembership,
        referralsCount
      ).map((item, index) => [
        <MenuItem
          key={index}
          disableGutters
          className={
            item.disabled ? classes.menuItemDisabled : classes.menuItem
          }
          onClick={item.disabled ? null : item.action}
        >
          {item.upload ? (
            <label
              htmlFor="contained-button-file"
              style={{ display: "flex", cursor: "pointer" }}
            >
              <ListItemIcon>
                <item.icon
                  fontSize="small"
                  color="disabled"
                  className={classes.icon}
                />
              </ListItemIcon>
              <Typography variant="body2" color="text.primary">
                {item.text}
              </Typography>
            </label>
          ) : item.showTooltip ? (
            <Tooltip title={item.tooltipText} arrow>
              <span style={{ display: "flex" }}>
                <ListItemIcon>
                  <item.icon
                    fontSize="small"
                    color="disabled"
                    className={classes.icon}
                  />
                </ListItemIcon>
                <Typography
                  variant="body2"
                  color={item.disabled ? "text.secondary" : "text.primary"}
                >
                  {item.text}
                </Typography>
              </span>
            </Tooltip>
          ) : (
            <>
              <ListItemIcon>
                <item.icon
                  fontSize="small"
                  color="disabled"
                  className={classes.icon}
                />
              </ListItemIcon>
              <Typography variant="body2" color="text.primary">
                {item.text}
              </Typography>
            </>
          )}
        </MenuItem>,
        item.divider && <Divider light />
      ])}
    </Menu>
  );
}
export default DropdownMenu;
