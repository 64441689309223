import React from "react";
import { withRouter } from "react-router";
import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  ChevronLeftIcon,
  FilterIcon,
  FiberManualRecordIcon
} from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";
import { getDataRoomId } from "../../helpers/helpers";
import { TransactionSpinner, FilterDrawer } from "../../components/common";
import TimelineWrapper from "./TimelineWrapper";

const useStyles = makeStyles(theme => ({
  header: {
    borderBottom: "1px solid rgb(49, 47, 55)"
  },
  filterActive: {
    flexDirection: "column"
  },
  paddingForToolTip: {
    paddingRight: theme.spacing(2),
    paddingLeft: ({ isMobile }) => (isMobile ? 0 : theme.spacing(3))
  },
  filterMark: {
    fontSize: "8px",
    position: "absolute",
    bottom: "-8px"
  }
}));

function Timeline({ history }) {
  const { isMobile, darkMode, decrptSpinner } = useGlobalContext();
  const { currentMembership } = useVaultContext();
  const { showFilter, onShowFilter, actionFilters, periodFilter } =
    useAssetsContext();

  const classes = useStyles();

  const dataRoomId = getDataRoomId(history.location.pathname);

  const membership = history.location?.state
    ? history.location?.state
    : currentMembership;

  const filterActive = actionFilters.length || periodFilter;

  return (
    <Box
      display={isMobile ? "flex" : "block"}
      flexDirection="column"
      height="100%"
      width={isMobile ? "100%" : "calc(100% - 250px)"}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        className={classes.header}
        width={isMobile ? "100%" : "calc(100% - 250px)"}
        position="fixed"
      >
        <Box mx={5} mt={4} mb={1} width="100%">
          <Box display="flex" justifyContent="space-between">
            <Box mb={1} display="flex" alignItems="flex-start">
              <IconButton
                style={{ padding: 0 }}
                onClick={() =>
                  history.push(`/vaults/active/${dataRoomId}/assets`)
                }
                size="large"
              >
                <ChevronLeftIcon />
              </IconButton>
              <Typography variant="h2" noWrap color="text.primary">
                {membership.dataRoom?.state?.title}
              </Typography>
            </Box>
            <Box display="flex">
              <Box>
                {decrptSpinner && (
                  <TransactionSpinner
                    isMobile={isMobile}
                    darkMode={darkMode}
                    text="Decryption"
                    mobileText=""
                  />
                )}
              </Box>
              <Tooltip title="Filter the timeline" arrow>
                <IconButton
                  disableRipple
                  aria-label="filter"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  classes={{ label: classes.filterActive }}
                  className={classes.paddingForToolTip}
                  onClick={() => onShowFilter(!showFilter)}
                >
                  <FilterIcon />
                  {filterActive && (
                    <FiberManualRecordIcon
                      color="action"
                      className={classes.filterMark}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          <Typography variant="body2" color="text.secondary">
            Timeline
          </Typography>
        </Box>
      </Box>
      <Box
        flex="1"
        height={isMobile ? "calc(100% - 133px)" : "calc(100% - 75px)"}
        position="absolute"
        top={isMobile ? "133px" : "75px"}
        width={isMobile ? "100%" : "calc(100% - 250px)"}
      >
        <TimelineWrapper isFiltered={filterActive} />
      </Box>
      <FilterDrawer topHeight={-11} />
      {/* <FilterDrawer topHeight={isWarning ? bannerHeight : 0} /> */}
    </Box>
  );
}

export default withRouter(Timeline);
