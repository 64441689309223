import React from "react";
import { Helmet } from "react-helmet";
import { Box, CssBaseline } from "@mui/material";
import { TopBarMain, BottomBarSm, MenuDrawerMd } from "..";
import { withRouter } from "react-router";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { getDataRoomStatus, getDataRoomId } from "../../../helpers/helpers";
import Onboarding from "../Onboarding/Onboarding";
import ThemeWrapper from "./ThemeWrapper";
import AnnouncementBanner from "../AnnouncementBanner";

const { host, protocol } = window.location;
const baseUrl = `${protocol}//${host}`;

const SafeWrapper = ({ children, location, history }) => {
  const {
    darkMode,
    isMobile,
    decryptedMemberships,
    userAttributes,
    onUserAttributes
  } = useGlobalContext();

  const getDataRoomName = () => {
    return (
      decryptedMemberships?.filter(
        membership => membership.dataRoomId === getDataRoomId(location.pathname)
      )[0]?.dataRoom?.state?.title || ""
    );
  };

  const getPageTitle = () => {
    switch (true) {
      case location.pathname ===
        `/vaults/${getDataRoomStatus(location.pathname)}/${getDataRoomId(
          location.pathname
        )}/manage-access`:
        return "Manage Access";
      case location.pathname ===
        `/vaults/${getDataRoomStatus(location.pathname)}/${getDataRoomId(
          location.pathname
        )}/invite-to-vault`:
        return "Invite to vault";
      case location.pathname.includes(
        `/vaults/${getDataRoomStatus(location.pathname)}/${getDataRoomId(
          location.pathname
        )}`
      ):
        return getDataRoomName();
      case location.pathname ===
        `/vaults/${getDataRoomStatus(location.pathname)}`:
        return "Vaults";
      case location.pathname === "/notifications":
        return "Notifications";
      case location.pathname === "/settings":
        return "Settings";
      case location.pathname === "/settings/notifications":
        return "Settings | Notifications";
      case location.pathname === "/settings/display":
        return "Settings | Display";
      case location.pathname === "/settings/security":
      case location.pathname === "/settings/security/backup-phrase":
      case location.pathname === "/settings/security/change-password":
        return "Settings | Security";
      case location.pathname === "/storage":
        return "Storage";

      default:
        return "Home";
    }
  };

  const showHeader = () => {
    switch (true) {
      case location.pathname === "/settings":
        return false;
      case location.pathname === "/notifications":
        return false;
      case location.pathname ===
        `/vaults/${getDataRoomStatus(location.pathname)}`:
        return true;

      default:
        return false;
    }
  };

  return (
    <ThemeWrapper darkMode={darkMode}>
      <CssBaseline />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${getPageTitle()} | Akord`}</title>
        <link rel="canonical" href={`${baseUrl}${location.pathname}`} />
      </Helmet>
      {userAttributes.userOnboarding &&
        location.pathname.includes("/assets") && <Onboarding />}
      {userAttributes.showV2Announcment && (
        <AnnouncementBanner
          darkMode={darkMode}
          isMobile={isMobile}
          onUserAttributes={onUserAttributes}
        />
      )}
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        width="100%"
        overflow="auto"
        position="fixed"
        top={
          userAttributes.showV2Announcment ? (isMobile ? "48px" : "40px") : 0
        }
        // overflow="hidden" //no sure - allows to scroll create vault: make sure works on other screens
      >
        {isMobile && showHeader() && <TopBarMain />}
        <Box display="flex" flexDirection="row" height="100%" width="100%">
          {!isMobile && <MenuDrawerMd />}
          <Box
            display="flex"
            flexDirection="column"
            flex="1 0"
            height="100%"
            width="100%"
            // overflow="hidden" //no sure - allows to scroll create vault: make sure works on other screens
          >
            {children}
            {isMobile &&
            !location.pathname.match(
              `/vaults/${getDataRoomStatus(location.pathname)}/${getDataRoomId(
                location.pathname
              )}`
            ) &&
            !location.pathname.match("/create-vault") ? (
              <BottomBarSm history={history} />
            ) : null}
          </Box>
        </Box>
      </Box>
    </ThemeWrapper>
  );
};

export default withRouter(SafeWrapper);
