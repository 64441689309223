import { documentAction, fromStatus } from "./document-action";
import { documentStatus, fromStateStatus } from "./document-status";
import { searchResultType } from "./search-result-type";

export const fromMemberships = memberships => {
  if (Array.isArray(memberships) && memberships.length > 0) {
    return memberships
      .map(membership => membership.dataRoom)
      .map(dataRoom =>
        new Document()
          .id(dataRoom.id)
          .type(searchResultType.DATA_ROOM)
          .name(dataRoom.state.title)
          .active(isActive(dataRoom))
          .url(`${roomUrl(dataRoom.status, dataRoom.id)}/assets`)
          .sourceId(dataRoom.id)
          .sourceName(null)
          .sourceUrl(`${roomsUrl(dataRoom.status)}`)
          .action(fromStatus(dataRoom.state.status))
          .build()
      );
  }
};

export const fromStacks = stacks => {
  if (Array.isArray(stacks) && stacks.length > 0) {
    return stacks.map(stack =>
      new Document()
        .id(stack.id)
        .type(stackType(stack))
        .name(stack.title)
        .active(isActive(stack))
        .url(
          `${roomUrl(stack.dataRoomStatus, stack.dataRoomId)}/assets/${
            stack.id
          }`
        )
        .action(fromStatus(stack.status))
        .sourceId(stack.dataRoomId)
        .sourceName(stack.dataRoomName)
        .sourceUrl(
          `${roomUrl(stack.dataRoomStatus, stack.dataRoomId)}/${leafLocation(
            stack
          )}${inFolderLocation(stack)}`
        )
        .build()
    );
  }
};

export const fromFolders = folders => {
  if (Array.isArray(folders) && folders.length > 0) {
    return folders.map(folder =>
      new Document()
        .id(folder.id)
        .type(searchResultType.FOLDER)
        .name(folder.title)
        .active(isActive(folder))
        .url(
          `${roomUrl(
            folder.dataRoomStatus,
            folder.dataRoomId
          )}/assets/folders/${folder.id}`
        )
        .action(fromStatus(folder.status))
        .sourceId(folder.dataRoomId)
        .sourceName(folder.dataRoomName)
        .sourceUrl(
          `${roomUrl(folder.dataRoomStatus, folder.dataRoomId)}/${leafLocation(
            folder
          )}${inFolderLocation(folder)}`
        )
        .build()
    );
  }
};

export const fromTimeline = timelineItems => {
  if (Array.isArray(timelineItems) && timelineItems.length > 0) {
    return timelineItems
      .map(timelineItem => {
        if (timelineItem.actionRef === "MEMO_WRITE") {
          return new Document()
            .id(timelineItem.modelId)
            .type(searchResultType.MEMO)
            .name(timelineItem.message)
            .url(
              `${roomUrl(
                timelineItem.dataRoomStatus,
                timelineItem.dataRoomId
              )}/timeline`
            )
            .active(documentStatus.ACTIVE)
            .action(documentAction.PUT)
            .sourceId(timelineItem.dataRoomId)
            .sourceName(timelineItem.dataRoomName)
            .sourceUrl(
              `${roomUrl(
                timelineItem.dataRoomStatus,
                timelineItem.dataRoomId
              )}/timeline`
            )
            .build();
        }
        return null;
      })
      .filter(item => item);
  }
};

export const fromMemos = memos => {
  if (Array.isArray(memos) && memos.length > 0) {
    return memos
      .map(memo => {
        memo.dataRoomStatus = "active"; //needs a dataRoomSatus
        return new Document()
          .id(memo.id)
          .type(searchResultType.MEMO)
          .name(memo.message)
          .url(`${roomUrl(memo.dataRoomStatus, memo.dataRoomId)}/chat`)
          .active(documentStatus.ACTIVE)
          .action(documentAction.PUT)
          .sourceId(memo.dataRoomId) //needs a dataRoomName
          .sourceName(memo.dataRoomName)
          .sourceUrl(`${roomUrl(memo.dataRoomStatus, memo.dataRoomId)}/chat`)
          .build();
      })
      .filter(item => item);
  }
};

export class Document {
  id(id) {
    this.id = id;
    return this;
  }

  type(type) {
    this.type = type;
    return this;
  }

  name(name) {
    this.name = name;
    return this;
  }

  url(url) {
    this.url = url;
    return this;
  }

  active(isActive) {
    this.isActive = isActive;
    return this;
  }

  action(action) {
    this.action = action;
    return this;
  }

  sourceId(sourceId) {
    this.sourceId = sourceId;
    return this;
  }

  sourceName(sourceName) {
    if (sourceName) {
      if (!this.isActive) {
        this.sourceName = `in ${sourceName} -> Revoked files`;
      } else {
        this.sourceName = `in ${sourceName}`;
      }
    } else {
      this.sourceName = null;
    }
    return this;
  }

  sourceUrl(sourceUrl) {
    this.sourceUrl = sourceUrl;
    return this;
  }

  build() {
    if (this.id) {
      return this;
    }
    return null;
  }
}

const stackType = stack => {
  const fileType =
    stack?.files && stack.files[0] ? stack.files[0].fileType : "";
  return stack?.files?.length > 1
    ? searchResultType.STACK
    : fileType && fileType.match("image")
    ? searchResultType.STACK_IMAGE
    : searchResultType.STACK_DOCUMENT;
};

const roomUrl = (dataRoomStatus, dataRoomId) => {
  return `/vaults/${fromStateStatus(dataRoomStatus)}/${dataRoomId}`;
};

const roomsUrl = dataRoomStatus => {
  return `/vaults/${fromStateStatus(dataRoomStatus)}`;
};

const isActive = model => {
  return fromStateStatus(model.status) === documentStatus.ACTIVE;
};

const leafLocation = model => {
  if (!isActive(model)) {
    return "revoked-files";
  }
  return "assets";
};

const inFolderLocation = model => {
  if (model.folderId) {
    return `/folders/${model.folderId}`;
  }
  return "";
};
