import { pdfjs } from 'react-pdf';
import loadImage from 'blueimp-load-image'

export const THUMBNAIL_WIDTH = 438
// export const THUMBNAIL_HEIGHT = 136

const makeImageThumbnail = async fileUrl => {
  return new Promise((resolve, reject) => {
    const image = document.createElement('img')
    image.setAttribute('src', fileUrl)

    image.addEventListener('load', () => {
      const canvas = loadImage.scale(image, {
        canvas: true,
        cover: true,
        maxWidth: THUMBNAIL_WIDTH
      })
      canvas.toBlob(
        blob => {
          resolve(blob)
        },
        'image/jpeg',
        1
      )
    })

    image.addEventListener('error', error => {
      console.log('Make image thumbnail error', error)
      reject(error)
    })
  })
}

const makeVideoThumbnail = async fileUrl => {
  const canvasUrl = await new Promise((resolve, reject) => {
    const video = document.createElement('video')
    video.setAttribute('src', fileUrl)
    video.load()

    video.addEventListener('error', error => {
      console.log('Make video thumbnail error', error)
      reject('Make video thumbnail error', error)
    })

    video.addEventListener('loadedmetadata', () => {
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        video.currentTime = 0.0
      }, 200)

      video.addEventListener('seeked', () => {
        const canvas = document.createElement('canvas')

        canvas.width = video.videoWidth
        canvas.height = video.videoHeight

        canvas
          .getContext('2d')
          .drawImage(video, 0, 0, canvas.width, canvas.height)
        resolve(canvas.toDataURL())
      })
    })
  })
  return makeImageThumbnail(canvasUrl)
}

export const makePdfThumbnail = async fileUrl => {
  const defaultWorker = () => {
    if (!pdfjs.GlobalWorkerOptions.workerPort) {
      pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
      pdfjs.GlobalWorkerOptions.workerPort = new pdfjs.PDFWorker('default-worker');
    }
    return pdfjs.GlobalWorkerOptions.workerPort
  }
  const pdf = await pdfjs.getDocument({url: fileUrl, worker: defaultWorker()}).promise
  const firstPage = await pdf.getPage(1)
  const canvasUrl = await new Promise(resolve => {
    const viewport = firstPage.getViewport({ scale: 1 })
    const canvas = document.createElement('canvas')

    canvas.width = viewport.width
    canvas.height = viewport.height

    firstPage
      .render({
        canvasContext: canvas.getContext('2d'),
        viewport: viewport
      })
      .promise.then(function () {
        resolve(canvas.toDataURL())
      })
  })
  return makeImageThumbnail(canvasUrl)
}

export const createThumbnail = async file => {
  const fileUrl = URL.createObjectURL(file)
  let thumbnail = null
  switch (file.type) {
    case 'application/pdf':
      try {
        thumbnail = await makePdfThumbnail(fileUrl)
      } catch (error) {
        console.log('PDF thumbnail error: ' + error)
      }
      break
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/png':
    case 'image/gif':
      try {
        thumbnail = await makeImageThumbnail(fileUrl)
      } catch (error) {
        console.log('Image thumbnail error: ' + error)
      }
      break
    case 'video/mp4':
    case 'video/quicktime':
      try {
        thumbnail = await makeVideoThumbnail(fileUrl)
      } catch (error) {
        console.log('Video thumbnail error: ' + error)
      }
      break
    default:
      console.log('Thumbnail not supported for this file type: ' + file.type)
      break
  }
  URL.revokeObjectURL(fileUrl)
  return thumbnail
}
