import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";
import { confirmationModalOptions } from "./confirmation-modal-options";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import TwoButtonConfirm from "./TwoButtonConfirm";
import ThreeButtonConfirm from "./ThreeButtonConfirm";
import MoveModalContent from "./MoveModalContent";
import UploadModalContent from "./UploadModalContent";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { FILE_SIZE_LIMIT_UPLOAD } from "../../../helpers/akord-enums";

const useStyles = makeStyles(theme => ({
  dialogActions: {
    justifyContent: ({ alignRight }) => (alignRight ? "flex-end" : "flex-end"),
    flexDirection: ({ isDesktop }) => (isDesktop ? "row" : "column-reverse")
  },
  dialogContent: {
    marginTop: 0
  },
  dialogTitle: {
    display: "flex",
    marginBottom: theme.spacing(4)
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  paperFullWidth: {
    width: "calc(100% - 32px)"
  },
  paper: {
    margin: ({ isDesktop }) =>
      isDesktop ? "128px 16px 16px" : "64px 16px 16px"
  },
  buttonGroup: {
    minWidth: "auto",
    marginLeft: ({ isDesktop }) => isDesktop && "24px!important",
    "&:last-child": {
      marginBottom: ({ isDesktop }) => (isDesktop ? 0 : theme.spacing(4))
    }
  },
  buttonProgress: {
    position: "absolute"
  }
}));

function ConfirmationModal() {
  const [upoadFileIndex, setUpoadFileIndex] = useState(0);
  const handleUpoadFileIndex = num => setUpoadFileIndex(num);

  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const handleSelectedFolderId = event => {
    const folderId = !event ? null : event.target.value;
    setSelectedFolderId(folderId);
  };

  const [uploadedAndConfirmedFiles, setUploadedAndConfirmedFiles] = useState();
  const handleUploadedAndConfirmedFiles = files =>
    setUploadedAndConfirmedFiles(files);

  let filesWithAction = React.useRef([]);
  const { width } = useGlobalContext();
  const { modal, notificationData, duplicateFiles } = useNotificationsContext();
  const { isVaultPublic } = useVaultContext();

  const isDesktop = width !== "xs";
  const classes = useStyles({
    isDesktop: isDesktop,
    alignRight: confirmationModalOptions[modal.confirmModalType].showCancel
  });

  const IconForTitle = confirmationModalOptions[modal.confirmModalType].icon;

  if (isVaultPublic) Object.assign(notificationData, { isVaultPublic: true });
  else Object.assign(notificationData, { isVaultPublic: false });

  const threeGbFileLimitCheck = uploadedAndConfirmedFiles
    ? Array.from(uploadedAndConfirmedFiles)?.some(
        file => file.size >= FILE_SIZE_LIMIT_UPLOAD
      )
    : false;

  return (
    <Dialog
      open={modal.isModalVisible[modal.confirmModalType]}
      onClose={() => modal.onModalVisibility(null, modal.confirmModalType)}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      classes={{
        paperFullWidth: classes.paperFullWidth,
        paper: classes.paper
      }}
    >
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        <IconForTitle color="disabled" className={classes.icon} />
        <Typography className="strong" color="text.primary">
          {confirmationModalOptions[modal.confirmModalType].title(
            notificationData
          )}
        </Typography>
      </DialogTitle>

      {confirmationModalOptions[modal.confirmModalType].modalType === "move" ? (
        <MoveModalContent
          onSelectedFolderId={handleSelectedFolderId}
          selectedFolderId={selectedFolderId}
        />
      ) : confirmationModalOptions[modal.confirmModalType].modalType ===
        "upload" ? (
        <UploadModalContent
          handleUploadedAndConfirmedFiles={handleUploadedAndConfirmedFiles}
          uploadedAndConfirmedFiles={uploadedAndConfirmedFiles}
          isSizeExceedLimit={threeGbFileLimitCheck}
        />
      ) : (
        <DialogContent className={classes.dialogContent}>
          <Typography variant="body2" style={{ whiteSpace: "pre-line" }} noWrap>
            {duplicateFiles.length > 0
              ? confirmationModalOptions[modal.confirmModalType].createText(
                  duplicateFiles,
                  upoadFileIndex
                )
              : notificationData &&
                confirmationModalOptions[modal.confirmModalType].createText(
                  notificationData
                )}
          </Typography>
        </DialogContent>
      )}

      {duplicateFiles.length > 0 &&
      confirmationModalOptions[modal.confirmModalType].modalType !==
        "upload" ? (
        <ThreeButtonConfirm
          filesWithAction={filesWithAction}
          upoadFileIndex={upoadFileIndex}
          onUpoadFileIndex={handleUpoadFileIndex}
        />
      ) : (
        <TwoButtonConfirm
          filesWithAction={filesWithAction}
          handleUploadedAndConfirmedFiles={handleUploadedAndConfirmedFiles}
          uploadedAndConfirmedFiles={uploadedAndConfirmedFiles}
          upoadFileIndex={upoadFileIndex}
          onUpoadFileIndex={handleUpoadFileIndex}
          selectedFolderId={selectedFolderId}
          confirmationType={
            confirmationModalOptions[modal.confirmModalType].modalType
          }
          isSizeExceedLimit={threeGbFileLimitCheck}
        />
      )}
    </Dialog>
  );
}

export default ConfirmationModal;
