import React from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { registerHandlers } from '../handlers'

// Given that we are implementing App Shell Architecture and, therefore,
// injecting (via reactDOM.render) the Header and Main components into
// different HTML elements, we need a way to share the router 'history' among
// all two mentioned components.
// As a default, for every invocation of 'BrowserRouter', there will be new
// 'history' instance created. Then, changes in the 'history' object in one
// component won't be available in the other components. To prevent this, we are
// relying on 'Router' component instead of 'BrowserRouter' and defining our
// custom 'history' object by means of 'createBrowserHistory' function. Said
// 'history' object is then passed to every invocation of 'Router' and therefore
// the same 'history' object will be shared among all three mentioned components.
const history = createBrowserHistory()

const App = ({ component: Component }) => (
  <Router history={history}>
    <Component />
  </Router>
)

registerHandlers()

export default App
