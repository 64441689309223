import React from 'react'

function FormSubmit({ children, onSubmit }) {
  //to enable Enter submit
  return (
    <form
      action="/"
      method="POST"
      onSubmit={e => {
        e.preventDefault()
        onSubmit()
      }}
    >
      {children}
    </form>
  )
}

export default FormSubmit
