import React from "react";
import { DialogActions, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { confirmationModalOptions } from "./confirmation-modal-options";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { useProgressContext } from "../../../contexts/ProgressContext";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";
import { handleUpload } from "../../../helpers/duplicate-file-upload";
import { getFolderId } from "../../../helpers/helpers";
import { useHistory } from "react-router-dom";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";

const useStyles = makeStyles(theme => ({
  dialogActions: {
    justifyContent: "space-between",
    flexDirection: ({ isDesktop }) => (isDesktop ? "row" : "column-reverse")
  },
  button: {
    minWidth: "auto"
  },
  buttonGroup: {
    minWidth: "auto",
    "&:last-child": {
      marginBottom: ({ isDesktop }) => (isDesktop ? 0 : theme.spacing(4))
    },
    "&:first-child": {
      marginBottom: ({ isDesktop }) => (isDesktop ? 0 : theme.spacing(4))
    }
  },
  btnGroupTwo: {
    display: "flex",
    flexDirection: ({ isDesktop }) => (isDesktop ? "row" : "column-reverse"),
    width: ({ isDesktop }) => (isDesktop ? "auto" : "100%")
  },
  dialogSpacing: {
    "&>:not(:first-child)": {
      marginLeft: 0
    }
  }
}));

function ThreeButtonConfirm({
  upoadFileIndex,
  onUpoadFileIndex,
  filesWithAction
}) {
  const { onSnackbarToShow } = useSnackbarContext();
  const { onProgress } = useProgressContext();
  const { currentMembership, ledgerWrapper } = useVaultContext();
  const {
    onShowLoader,
    decryptedStacks,
    onDrawerActionType,
    onAxiosError,
    onUploadStart,
    onUploadHook,
    onFilesNumber
  } = useAssetsContext();
  const {
    modal,
    duplicateFiles,
    uploadedFiles,
    onUploadFiles,
    uploadEventRef,
    clearUploadEventRef
  } = useNotificationsContext();
  const { width, onTxSpinner } = useGlobalContext();

  const history = useHistory();
  const folderId = getFolderId(history.location.pathname);

  const isDesktop = width !== "xs";
  const classes = useStyles({
    isDesktop: isDesktop,
    alignRight: confirmationModalOptions[modal.confirmModalType].showCancel
  });

  const isLegacyVault =
    currentMembership?.dataRoom?.state?.permanentStorage === null;

  const onButtonClick = action => {
    if (action === "CANCEL") {
      modal.onModalVisibility(null, modal.confirmModalType);
      if (uploadEventRef.current) clearUploadEventRef();
      return;
    }

    filesWithAction.current = [
      ...filesWithAction.current,
      { ...duplicateFiles[upoadFileIndex], action: action }
    ];
    if (duplicateFiles[upoadFileIndex + 1])
      onUpoadFileIndex(prevState => prevState + 1);
    else {
      if (isLegacyVault) {
        handleUpload(
          filesWithAction.current,
          uploadedFiles,
          decryptedStacks,
          onDrawerActionType,
          onShowLoader,
          currentMembership,
          ledgerWrapper,
          onProgress,
          onAxiosError,
          onSnackbarToShow,
          onUploadStart,
          onFilesNumber,
          onUploadFiles,
          uploadEventRef,
          clearUploadEventRef,
          folderId,
          isLegacyVault,
          onUploadHook
        );
        modal.onModalVisibility(null, modal.confirmModalType);
        filesWithAction.current = [];
        onUpoadFileIndex(0);
      } else {
        onTxSpinner(true);
        modal.onConfirmModalType("confirmUpload");
        modal.onModalVisibility(null, "confirmUpload");
      }
    }
  };

  return (
    <DialogActions
      className={classes.dialogActions}
      classes={{ spacing: classes.dialogSpacing }}
    >
      <div className={classes.btnGroupOne}>
        <Button
          onClick={() => onButtonClick("CANCEL")}
          size={isDesktop ? "small" : "large"}
          color="primary"
          label="cancel"
          className={classes.button}
        >
          Cancel
        </Button>
      </div>
      <div className={classes.btnGroupTwo}>
        <Button
          size={isDesktop ? "small" : "large"}
          onClick={() =>
            onButtonClick(
              confirmationModalOptions[modal.confirmModalType].buttonSecondary
            )
          }
          color="primary"
          variant="outlined"
          fullWidth={!isDesktop}
          type="submit"
          label="submit"
          disableElevation
          className={classes.buttonGroup}
        >
          {confirmationModalOptions[modal.confirmModalType].buttonSecondary}
        </Button>
        <Button
          onClick={() =>
            onButtonClick(
              confirmationModalOptions[modal.confirmModalType].buttonTextPrimary
            )
          }
          size={isDesktop ? "small" : "large"}
          color="primary"
          variant="contained"
          fullWidth={!isDesktop}
          type="submit"
          label="submit"
          disableElevation
          className={classes.buttonGroup}
          style={{ marginLeft: isDesktop && 16 }}
        >
          {confirmationModalOptions[modal.confirmModalType].buttonTextPrimary}
        </Button>
      </div>
    </DialogActions>
  );
}

export default ThreeButtonConfirm;
