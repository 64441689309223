import React, { useState, useRef, useEffect } from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import API, { graphqlOperation } from "@aws-amplify/api";
import * as mutations from "../../graphql/mutations";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Fab, Tabs, Tab } from "@mui/material";
import {
  HideOnScroll,
  UploadButton,
  TopBarSecondary,
  FilterDrawer,
  LegacyBanner,
  BatchActionsBar,
  AddFileDrawer
} from "../../components/common";
import { PlusIcon } from "@akord/addon-icons";
import {
  isArchived,
  getDataRoomStatus,
  getDataRoomId,
  getFolderId
} from "../../helpers/helpers";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import TabDocuments from "./TabDocuments";
import { grey } from "../../theme/colors";
import { useInAppNotificationsContext } from "../../contexts/InAppNotificationsContext";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";
import TabChat from "./TabChat";

const useStyles = makeStyles(theme => ({
  fab: {
    position: "fixed",
    right: "16px",
    bottom: "16px",
    "&.Mui-disabled": {
      backgroundColor: ({ darkMode }) =>
        darkMode ? `${grey[700]}!important` : `${grey[200]}!important`,
      color: ({ darkMode }) =>
        darkMode ? `${grey[500]}!important` : `${grey[400]}!important`
    }
  },
  fabIcon: {
    fontSize: "30px"
  },
  indicator: {
    bottom: 3,
    backgroundColor: ({ isVaultPublic }) =>
      isVaultPublic && theme.palette.info.main
  },
  tabsFlexContainer: {
    borderBottomColor: ({ isVaultPublic }) =>
      isVaultPublic && theme.palette.info.main
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function ShowStack({ history }) {
  const { currentMembership, userRole, isVaultPublic } = useVaultContext();
  const {
    activeDecryptedStacks,
    activeDecryptedNotes,
    showUpload,
    onShowFilter,
    actionFilters,
    onActionFilters,
    periodFilter,
    onPeriodFilter,
    selectedItemsMap
  } = useAssetsContext();

  const { isMobile, darkMode, userAttributes } = useGlobalContext();
  const { transactionLogs } = useInAppNotificationsContext();

  const [bannerHeight, setBannerHeight] = useState();
  const handleBannerHeight = value => setBannerHeight(value);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMobileMenu = Boolean(anchorEl);

  const docsRef = useRef(null);

  const isRoomArchived = isArchived(currentMembership);
  const dataRoomStatus = getDataRoomStatus(location.pathname);
  const dataRoomId = getDataRoomId(location.pathname);
  const folderId = getFolderId(location.pathname);

  const classes = useStyles({
    darkMode: darkMode,
    isDisabled: userRole === "VIEWER" || isRoomArchived,
    isVaultPublic: isVaultPublic
  });

  const handleMobileMenu = event => setAnchorEl(event.currentTarget);

  const handleMobileMenuClose = () => setAnchorEl(null);

  // Clear the filter on Tabs switch
  useEffect(() => {
    if (periodFilter || actionFilters.length) {
      onActionFilters([]);
      onPeriodFilter(null);
    }
    onShowFilter(false);
    // setPermaWarning(true)
  }, [location.pathname]);

  //Effect that sets Notification status to READ when clicked into a Room
  //runs on every render - IMPROVE
  useEffect(() => {
    const changeLogStatus = async ids => {
      await API.graphql(
        graphqlOperation(mutations.updateNotificationStatus, {
          operation: "MARK_AS_READ",
          ids: ids
        })
      );
    };

    const dataRoomIdUnreadLogs = transactionLogs?.filter(
      log => log.dataRoomId === dataRoomId && log.status === "UNREAD"
    );
    if (dataRoomIdUnreadLogs?.length) {
      const ids = dataRoomIdUnreadLogs.map(log => log.id);
      changeLogStatus(ids);
    }
  });

  const isWarning =
    currentMembership?.dataRoom?.state?.permanentStorage === null &&
    !userAttributes?.legacyVaultsNotified?.includes(dataRoomId);

  const topHeight = location.pathname.includes("/assets")
    ? isMobile && selectedItemsMap?.size > 0
      ? 176
      : 133
    : 86;

  return (
    <>
      <Box
        position="fixed"
        width={isMobile ? "100%" : "calc(100% - 250px)"}
        height="86px"
        zIndex="1"
      >
        {Object.keys(currentMembership).length > 0 && isWarning && (
          <LegacyBanner
            onBannerHeight={handleBannerHeight}
            // handlePermanentWarning={setPermaWarning}
            dataRoomId={dataRoomId}
          />
        )}
        <TopBarSecondary
          filterActive={actionFilters.length || periodFilter}
          history={history}
        />
        <Box zIndex="100">
          <Tabs
            color="text.secondary"
            value={location.pathname}
            aria-label="rooms tabs"
            classes={{
              indicator: classes.indicator,
              flexContainer: classes.tabsFlexContainer
            }}
          >
            <Tab
              component={Link}
              disableRipple
              label={<span className="step1">Files</span>}
              to={`/vaults/${dataRoomStatus}/${dataRoomId}/assets`}
              value={
                location.pathname.includes("/folders")
                  ? `/vaults/${dataRoomStatus}/${dataRoomId}/assets/folders/${folderId}`
                  : `/vaults/${dataRoomStatus}/${dataRoomId}/assets`
              }
              {...a11yProps(1)}
            />
            {!isVaultPublic && (
              <Tab
                component={Link}
                disableRipple
                label={<span className="step2">Chat</span>}
                to={`/vaults/${dataRoomStatus}/${dataRoomId}/chat`}
                value={`/vaults/${dataRoomStatus}/${dataRoomId}/chat`}
                {...a11yProps(0)}
              />
            )}
          </Tabs>
        </Box>
        {location.pathname.includes("/assets") && (
          <BatchActionsBar
            currentMembershipTitle={currentMembership.dataRoom?.state?.title}
            folderId={folderId}
          />
        )}
      </Box>
      {/* //ContentStart */}
      <Box
        flex="1"
        height={
          isWarning
            ? `calc(100% - ${bannerHeight + topHeight}px)`
            : `calc(100% - ${topHeight}px)`
        }
        position="absolute"
        top={isWarning ? `${bannerHeight + topHeight}px` : `${topHeight}px`}
        width={isMobile ? "100%" : "calc(100% - 250px)"}
      >
        <Switch>
          <Route
            exact
            path={`/vaults/${dataRoomStatus}/${dataRoomId}/chat`}
            render={() => (
              <TabChat
                periodFilter={periodFilter}
                actionFilters={actionFilters}
                bannerHeight={isWarning ? bannerHeight : 0}
              />
            )}
          />
          <Route
            exact
            path={`/vaults/${dataRoomStatus}/${dataRoomId}/assets`}
            render={() => (
              <TabDocuments
                docsRef={docsRef}
                periodFilter={periodFilter}
                actionFilters={actionFilters}
              />
            )}
          />
          <Route
            exact
            path={`/vaults/${dataRoomStatus}/${dataRoomId}/assets/folders/${folderId}`}
            render={() => (
              <TabDocuments
                docsRef={docsRef}
                periodFilter={periodFilter}
                actionFilters={actionFilters}
              />
            )}
          />
          {/* Redirect unknown paths */}
          <Redirect to={`/vaults/${dataRoomStatus}/${dataRoomId}/assets`} />
        </Switch>
        {showUpload && <UploadButton fab={true} />}
        {(activeDecryptedStacks?.length > 0 ||
          activeDecryptedNotes?.length > 0) &&
        isMobile &&
        location.pathname.match("/assets") ? (
          <HideOnScroll window={docsRef.current} fab={true}>
            <Fab
              disabled={userRole === "VIEWER" || isRoomArchived}
              size="medium"
              color="primary"
              className={classes.fab}
              onClick={handleMobileMenu}
            >
              <PlusIcon className={classes.fabIcon} />
            </Fab>
          </HideOnScroll>
        ) : null}
      </Box>
      {/* //ContentEnd */}
      <FilterDrawer topHeight={isWarning ? bannerHeight : 0} />

      {isMobile && (
        <AddFileDrawer
          currentMembership={currentMembership}
          openDrawer={openMobileMenu}
          handleMenuClose={handleMobileMenuClose}
        />
      )}
    </>
  );
}
export default ShowStack;
