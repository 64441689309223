import React from "react";
import { Auth } from "aws-amplify";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, IconButton } from "@mui/material";
import { CloseIcon } from "@akord/addon-icons";
import { blue } from "../../theme/colors/index";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";

const useStyles = makeStyles(theme => ({
  bannerDiv: {
    backgroundColor: ({ darkMode }) => (darkMode ? blue[700] : blue[600])
  },
  bannerText: {
    paddingTop: 2
  },
  closeIcon: {
    color: theme.palette.primary.contrastText
  }
}));

export default function LegacyBanner({ dataRoomId, onBannerHeight }) {
  const { darkMode, userAttributes, onUserAttributes } = useGlobalContext();
  const classes = useStyles({
    darkMode: darkMode
  });
  const bannerRef = React.useRef(null);

  const calcBannerHeight = () => {
    const bannerHeight = bannerRef.current.offsetHeight;
    onBannerHeight(bannerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", calcBannerHeight());
  });

  const handleCloseWarning = async () => {
    const legacyVaultsNotifiedCopy = [...userAttributes.legacyVaultsNotified];
    legacyVaultsNotifiedCopy.push(dataRoomId);
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      "custom:legacyVaultsNotified": legacyVaultsNotifiedCopy.join(",")
    });
    onUserAttributes({ legacyVaultsNotified: legacyVaultsNotifiedCopy });
  };

  return (
    <Box
      px={4}
      py={2}
      className={classes.bannerDiv}
      justifyContent="space-between"
      display="flex"
      alignContent="center"
      ref={bannerRef}
    >
      <Typography
        variant="caption"
        className={classes.bannerText}
        color="primary.contrastText"
      >
        This is a legacy vault and is not storing data permanently. Any further
        uploads within this vault will remain in cloud storage.
      </Typography>
      <IconButton onClick={handleCloseWarning} size="large">
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
    </Box>
  );
}
