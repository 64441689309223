import { Auth, Hub } from "aws-amplify";
import { AkordWallet } from "@akord/crypto";
import LedgerWrapper from "../../crypto/ledger-wrapper";
import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "../../graphql/queries";

export const stepsData = {
  recover: {
    isButtonDisabled: ({ formData }) => {
      const emailRegex =
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;

      return !(formData.userEmail.match(emailRegex) !== null);
    },
    handleNext: async ({ onSnackbarToShow, formData, handleError }) => {
      try {
        const { host, protocol } = window.location;
        const verifyUrl = `${protocol}//${host}/recover/backup-phrase`;
        const userEmail = formData.userEmail.toLowerCase().trim();
        await Auth.forgotPassword(userEmail, { resetPasswordUrl: verifyUrl });
        onSnackbarToShow("linkToVerify");
      } catch (err) {
        console.log(err);
        handleError(err);
      }
    },
    showBottomNav: true,
    spaceBetween: null,
    darkMode: true,
    nextText: "Send verification",
    hideBackButton: false,
    fullWidthNextButton: false,
    hideIcon: true,
    disabledRed: false
  },
  "backup-phrase": {
    isButtonDisabled: ({ checkBackupPhrase, error }) => {
      return !checkBackupPhrase() || !!error;
    },
    handleNext: ({ steps, currentStep, props, path }) => {
      const nextStepIndex = steps.indexOf(currentStep) + 1;
      const routeName = steps[nextStepIndex];
      props.history.push(`${path}/${routeName}`);
    },
    showBottomNav: true,
    spaceBetween: null,
    darkMode: true,
    nextText: "Next",
    hideBackButton: true,
    fullWidthNextButton: true,
    hideIcon: true,
    disabledRed: false
  },
  "reset-password": {
    isButtonDisabled: ({ formData, loading, passEvaluation, error }) => {
      return (
        !(formData.userPassword.length > 8 && passEvaluation.score >= 4) ||
        loading ||
        !!error
      );
    },
    handleNext: async ({
      formData,
      backupPhrase,
      props,
      setLoading,
      handleError
    }) => {
      try {
        setLoading(true);
        const newWallet = await AkordWallet.recover(
          formData.userPassword,
          Object.values(backupPhrase).join(" ").toLowerCase()
        );

        await Auth.forgotPasswordSubmit(
          formData.userName.toLowerCase().trim(),
          formData.code,
          formData.userPassword
        );
        Hub.dispatch("akord:auth", { event: "preSignIn", data: formData });
        const user = await Auth.signIn(
          formData.userName.toLowerCase().trim(),
          formData.userPassword
        );

        await Auth.updateUserAttributes(user, {
          "custom:encBackupPhrase": newWallet.encBackupPhrase
        });
        const results = await API.graphql(
          graphqlOperation(queries.profilesByPublicSigningKeyLight, {
            publicSigningKey: await newWallet.signingPublicKey()
          })
        );

        // fetch profiles to get hash
        const ledgerWrapper = new LedgerWrapper(newWallet);
        await ledgerWrapper.dispatch("PROFILE_UPDATE_PASSWORD", {
          prevHash: results.data.profilesByPublicSigningKey.items[0].hash
        });
        setLoading(false);
        props.history.push({
          pathname: "/vaults/active",
          state: { changePassword: true }
        });
      } catch (err) {
        console.log(err);
        setLoading(false);
        handleError("Invalid backup phrase.");
      }
    },
    showBottomNav: true,
    spaceBetween: null,
    darkMode: true,
    nextText: "Reset Password",
    hideBackButton: true,
    fullWidthNextButton: true,
    hideIcon: true,
    disabledRed: false
  }
};
