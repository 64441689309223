import React, { useState, createContext, useContext, useEffect } from "react";
import API, { graphqlOperation } from "@aws-amplify/api";
import { useGlobalContext } from "./GlobalDataProvider";
import { mapMember, decryptObjects } from "../helpers/decrypt-helper";
import { paginatedMemoQuery } from "../graphql/queries/utils";
import { getDataRoomId } from "../helpers/helpers";
import * as subscriptions from "../graphql/subscriptions";

const Context = createContext();

const memoToLoadNumber = 25;

function ChatContextProvider({ children }) {
  const [decryptedMemos, setDecryptedMemos] = useState([]);
  const handleDecryptedMemos = memos => setDecryptedMemos(memos);
  const [isMemoLoaded, setIsMemoLoaded] = useState(false);
  const handleIsMemoLoaded = isLoaded => setIsMemoLoaded(isLoaded);
  const [nextMemoToken, setNextMemoToken] = useState(null);
  const handleNextMemoToken = nextToken => setNextMemoToken(nextToken);
  const [chatItemsNumber, setChatItemsNumber] = useState(memoToLoadNumber);
  const handleChatItemsNumber = number => setChatItemsNumber(number);

  const { onDecrptSpinner, onEncrypterFromMembership, decryptedMemberships } =
    useGlobalContext();

  const countRef = React.useRef([]);
  const newChatRef = React.useRef(true);
  const decryptedMemosRef = React.useRef([]);

  const dataRoomId = getDataRoomId(location.pathname);
  const isChatTab = location.pathname.includes("/chat");

  const currentMembership = decryptedMemberships?.filter(
    membership => membership?.dataRoomId === dataRoomId
  )[0];

  useEffect(() => {
    const getMemos = async () => {
      const response = await paginatedMemoQuery(dataRoomId, memoToLoadNumber);
      const { items, nextToken } = response;
      handleNextMemoToken(nextToken);
      await decryptMemos(items);
    };
    if (
      isChatTab &&
      decryptedMemberships &&
      Object.keys(decryptedMemberships).length > 0
    ) {
      // Don't reload memos on tab switch
      if (newChatRef.current) getMemos();
    }
  }, [decryptedMemberships, isChatTab, dataRoomId]);

  // Clear timeline on page change
  React.useEffect(() => {
    return () => {
      if (!newChatRef.current) {
        handleDecryptedMemos([]);
        handleIsMemoLoaded(false);
        newChatRef.current = true;
        countRef.current = [];
        handleChatItemsNumber(memoToLoadNumber);
      }
    };
  }, [dataRoomId]);

  const decryptMemos = async (
    memos,
    isCreateSub = false,
    isUpdateSub = false,
    loadMoreMemos = false
  ) => {
    if (
      (decryptedMemberships &&
        Object.keys(decryptedMemberships).length === 0) ||
      (!isCreateSub && !isUpdateSub && !loadMoreMemos && !newChatRef.current) ||
      !currentMembership
    )
      return;
    onDecrptSpinner(true);

    const encrypter = onEncrypterFromMembership(currentMembership);

    const decryptedAvatarsMap = new Map();
    const decryptedMembers = await Promise.all(
      currentMembership.dataRoom.members.items.map(
        async member => await mapMember(member, encrypter, decryptedAvatarsMap)
      )
    );

    const membersMap =
      currentMembership?.dataRoom?.members &&
      new Map(
        decryptedMembers.map(member => {
          return [member.memberPublicSigningKey, member.state.memberDetails];
        })
      );

    memos.map(memo => {
      const ownerInfo = membersMap.get(memo.publicSigningKey);
      memo.ownerInfo = ownerInfo;
    });

    const decryptedMemosArray = await decryptObjects(encrypter, memos, [
      "message"
    ]);

    await Promise.all(
      decryptedMemosArray.map(async decryptedMemo => {
        const matchMemo = memos.find(memo => memo.hash === decryptedMemo.hash);
        matchMemo.message = decryptedMemo.message;
        matchMemo.reactions = null;

        if (decryptedMemo.reactions) {
          const decryptedReactions = await Promise.all(
            decryptedMemo.reactions.map(
              async reaction =>
                await encrypter.decryptObject(reaction, ["reaction", "name"])
            )
          );
          matchMemo.reactions = decryptedReactions;
        }

        matchMemo.reactions = decryptedMemo.reactions;
        matchMemo.groupRef = null;
      })
    );

    newChatRef.current = false;
    handleIsMemoLoaded(true);
    onDecrptSpinner(false);
    if (isCreateSub) {
      const updatedMemos = [...decryptedMemosRef.current, ...memos];
      decryptedMemosRef.current = updatedMemos;
      handleDecryptedMemos(updatedMemos);
    } else if (isUpdateSub) {
      const decryptedMemosRefCopy = [...decryptedMemosRef.current];
      const memoToUpdateIndex = decryptedMemosRefCopy.findIndex(
        memo => memo.id === memos[0].id
      );
      decryptedMemosRefCopy[memoToUpdateIndex] = memos[0];
      decryptedMemosRef.current = decryptedMemosRefCopy;
      handleDecryptedMemos(decryptedMemosRefCopy);
    } else if (loadMoreMemos) {
      const updatedMemos = [...decryptedMemosRef.current, ...memos];
      decryptedMemosRef.current = updatedMemos;
      handleDecryptedMemos(updatedMemos);
    } else {
      decryptedMemosRef.current = memos;
      handleDecryptedMemos(memos);
    }
  };

  const loadMoreMemos = async () => {
    if (!nextMemoToken) return;
    const response = await paginatedMemoQuery(
      dataRoomId,
      memoToLoadNumber,
      nextMemoToken
    );
    const { items, nextToken } = response;
    await decryptMemos(items, false, false, true);
    handleNextMemoToken(nextToken);
  };

  //Subscription
  useEffect(() => {
    let subscriptionOnCreate;
    let subscriptiononUpdate;
    const setupSubscription = async () => {
      try {
        if (!dataRoomId) return;

        subscriptionOnCreate = await API.graphql(
          graphqlOperation(subscriptions.onCreateMemoByDataRoomId, {
            dataRoomId: dataRoomId
          })
        ).subscribe({
          next: async ({ value }) => {
            const memo = value.data.onCreateMemoByDataRoomId;
            await decryptMemos([memo], true, false);
          },
          error: () => {
            console.warn("err");
          }
        });
        subscriptiononUpdate = await API.graphql(
          graphqlOperation(subscriptions.onUpdateMemoByDataRoomId, {
            dataRoomId: dataRoomId
          })
        ).subscribe({
          next: async ({ value }) => {
            const updatedMemo = value.data.onUpdateMemoByDataRoomId;
            await decryptMemos([updatedMemo], false, true);
          },
          error: () => {
            console.warn("Memo Subscription error.");
          }
        });
      } catch (err) {
        console.log("Memo Subscription error: ", err);
      }
    };
    if (window.navigator.onLine) setupSubscription();
    return () => {
      if (subscriptionOnCreate) subscriptionOnCreate.unsubscribe();
      if (subscriptiononUpdate) subscriptiononUpdate.unsubscribe();
    };
  }, [decryptedMemberships, dataRoomId]);

  return (
    <Context.Provider
      value={{
        decryptedMemos: decryptedMemos,
        onDecryptedMemos: handleDecryptedMemos,
        isMemoLoaded: isMemoLoaded,
        chatCurrentMembership: currentMembership,
        nextMemoToken: nextMemoToken,
        onLoadMoreMemos: loadMoreMemos,
        dataRoomId: dataRoomId,
        countRef: countRef,
        onChatItemsNumber: handleChatItemsNumber,
        chatItemsNumber: chatItemsNumber,
        chatItemsLimit: memoToLoadNumber,
        newChatRef: newChatRef
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default ChatContextProvider;

export const withChatContext = Component => props =>
  (
    <Context.Consumer>
      {chatContext => <Component {...props} {...chatContext} />}
    </Context.Consumer>
  );

export const useChatContext = () => useContext(Context);
