import React, { useState } from 'react'
import { Table, TableBody, TableContainer } from '@mui/material'
import { DataRoomFilter, EmptySpace } from '../../components/common/'
import RoomItem from './RoomItem'
import * as helpers from '../../helpers/helpers'
import { useGlobalContext } from '../../contexts/GlobalDataProvider'

function TabArchivedRooms({ archiveRef }) {
  const { decryptedMemberships } = useGlobalContext()

  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('title')

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <div
      ref={archiveRef}
      style={{
        height: '100%',
        overflowY: 'auto',
        paddingTop: '24px'
      }}
    >
      {helpers
        .roomsDetailsForSorting(decryptedMemberships)
        ?.filter(room => room.status === 'ARCHIVED').length > 0 ? (
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <DataRoomFilter
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              data={decryptedMemberships}
            />
            <TableBody>
              {helpers
                .stableSort(
                  helpers.roomsDetailsForSorting(decryptedMemberships),
                  helpers.getComparator(order, orderBy)
                )
                .filter(room => room.status === 'ARCHIVED')
                .map((roomDetails, index) => (
                  <RoomItem
                    isArchived={true}
                    key={index}
                    membership={
                      decryptedMemberships.filter(
                        membership =>
                          membership.dataRoomId === roomDetails.dataRoomId
                      )[0]
                    }
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <EmptySpace dataText="archive" />
      )}
    </div>
  )
}

export default TabArchivedRooms
