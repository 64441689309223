import React, { useState, useContext, useCallback } from 'react'
import zxcvbn from 'zxcvbn'
import { withRouter } from 'react-router'
const RecoverAccountContext = React.createContext()

function RecoverAccountContextProvider({ children }) {
  const [error, setError] = useState()
  const handleError = error => setError(error)

  const [passEvaluation, setPassEvaluation] = useState({})
  const handlePassEvaluation = evaluation => setPassEvaluation(evaluation)

  const [formData, setFormData] = useState({
    userEmail: '',
    userPassword: '',
    code: '',
    userName: ''
  })

  const handleFormChange = prop => event => {
    const { target } = event
    const value = target.value
    if (prop === 'userPassword') {
      const evaluation = zxcvbn(value)
      handlePassEvaluation(evaluation)
    }
    setFormData({ ...formData, [prop]: value })
  }

  const [backupPhrase, setBackupPhrase] = useState({})
  const handlebackupPhrase = (event, index) => {
    setBackupPhrase({ ...backupPhrase, [index]: event.target.value.trim() })
  }

  const checkBackupPhrase = useCallback(() => {
    const checkWordLength = !Object.values(backupPhrase).includes('')
    return Object.keys(backupPhrase).length === 12 && checkWordLength
  }, [backupPhrase])

  React.useEffect(() => {
    handleError()
  }, [backupPhrase])

  return (
    <RecoverAccountContext.Provider
      value={{
        formData: formData,
        handleFormChange: handleFormChange,
        setFormData: setFormData,
        passEvaluation: passEvaluation,
        handlePassEvaluation: handlePassEvaluation,
        handlebackupPhrase: handlebackupPhrase,
        backupPhrase,
        checkBackupPhrase,
        error: error,
        handleError: handleError
      }}
    >
      {children}
    </RecoverAccountContext.Provider>
  )
}
export default withRouter(RecoverAccountContextProvider)

export const useRecoverAccountContext = () => useContext(RecoverAccountContext)
