import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import queryString from "query-string";
import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "../../graphql/queries";
import { Box, Typography } from "@mui/material";
import { ButtonGroup, StandAloneScreenWrapper } from "../../components/common/";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import {
  revokeInvite,
  membershipConfirm
} from "../../helpers/manage-access-actions";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import LedgerWrapper from "../../crypto/ledger-wrapper";
import { fromMembership } from "@akord/crypto";
import { getDataRoomStatusFromMembership } from "../../helpers/helpers";

function ConfirmAccess({ location, history }) {
  const { onSnackbarToShow } = useSnackbarContext();
  const { wallet, darkMode, decryptedMemberships, isMembershipsLoaded } =
    useGlobalContext();

  const [membership, setMemebership] = useState();
  const [loading, setLoading] = useState(false);
  const [backLoading, setBackLoading] = useState(false);
  const [error, setError] = useState();

  const membershipId = queryString.parse(location.search).membershipId;

  useEffect(() => {
    if (!membershipId) history.push("/");

    const getMembership = async () => {
      try {
        const resultMembership = await API.graphql(
          graphqlOperation(queries.getMembership, {
            id: membershipId
          })
        );
        const membership = resultMembership.data.getMembership;
        const roomUrl = `/vaults/${getDataRoomStatusFromMembership(
          membership
        )}/${membership.dataRoomId}/assets`;
        //handle link differently depending on the status
        switch (membership.status) {
          case "INVITED":
            setMemebership(membership);
            break;
          case "PENDING":
            onSnackbarToShow("dataRoomAccessPending", membership.email);
            history.push(roomUrl);
            break;
          case "REVOKED":
            onSnackbarToShow("dataRoomAccessRevoked", membership.email);
            history.push(roomUrl);
            break;
          case "ACCEPTED":
            onSnackbarToShow("dataRoomAccessAccepted", membership.email);
            history.push(roomUrl);
            break;
          case "REJECTED":
            onSnackbarToShow("dataRoomAccessReject", membership.email);
            history.push(roomUrl);
            break;
          default:
            history.push(roomUrl);
        }
      } catch (err) {
        setError(err);
        console.log("Error fetching Membership: ", err);
      }
    };
    getMembership();
  }, [location.search]);

  const confirmAccess = async () => {
    try {
      const currentMembership = decryptedMemberships.filter(
        decryptedMembership =>
          decryptedMembership.dataRoomId === membership.dataRoomId
      )[0];
      setLoading(true);
      const encryptionKeys = fromMembership(currentMembership);
      const ledgerWrapper = new LedgerWrapper(wallet, encryptionKeys);
      await membershipConfirm(ledgerWrapper, membership, currentMembership);
      setLoading(false);
      onSnackbarToShow("dataRoomAccessConfirm", membership.email);
      history.push(
        `/vaults/${getDataRoomStatusFromMembership(currentMembership)}/${
          currentMembership.dataRoomId
        }/assets`
      );
    } catch (err) {
      console.log("Confirm access error: ", err);
      setLoading(false);
    }
  };

  const revokeAccess = async () => {
    try {
      const currentMembership = decryptedMemberships.filter(
        decryptedMembership =>
          decryptedMembership.dataRoomId === membership.dataRoomId
      )[0];
      setBackLoading(true);
      const encryptionKeys = fromMembership(currentMembership);
      const ledgerWrapper = new LedgerWrapper(wallet, encryptionKeys);
      await revokeInvite(ledgerWrapper, membership.hash, currentMembership);
      setBackLoading(false);
      onSnackbarToShow("dataRoomAccessRevoked", membership.email);
      history.push(
        `/vaults/${getDataRoomStatusFromMembership(currentMembership)}/${
          currentMembership.dataRoomId
        }/assets`
      );
    } catch (err) {
      setBackLoading(false);
      setError(err);
      console.log(err);
    }
  };

  if (!membership || !isMembershipsLoaded) return null;

  return (
    <StandAloneScreenWrapper title="Confirm access" route="/">
      <Box
        height="90%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box height="88%">
          <Box>
            <Typography variant="body2">
              {`You can now confirm access for ${membership.email} to your vault, as
               they have created their account.`}
            </Typography>
          </Box>
        </Box>
      </Box>
      <ButtonGroup
        errorText={error}
        darkMode={darkMode}
        nextDiasabled={loading}
        handleNext={() => confirmAccess()}
        handleBack={() => revokeAccess()}
        disableBackButton={backLoading}
        nextText="Confirm access"
        backText="Revoke"
        backButtonOutlined={true}
        hideBackButton={false}
        fullWidthNextButton={false}
        hideIcon={true}
        loading={loading}
        backLoading={backLoading}
        noMinWidth={true}
      />
    </StandAloneScreenWrapper>
  );
}

export default withRouter(ConfirmAccess);
