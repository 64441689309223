import React from "react";
import OperationTitleWithIcon from "../../../components/common/OperationTitle/OperationTitleWithIcon";
import { Typography } from "@mui/material";
import { memberRoleEnum } from "../../../helpers/akord-enums.js";

function MembershipChangeAccess(props) {
  const { operation, positionedBeforeDate, color } = props;

  const getRoleText = role => {
    switch (role) {
      case memberRoleEnum.VIEWER:
        return "view only";
      case memberRoleEnum.CONTRIBUTOR:
        return "contributor";
      default:
        return "unknown";
    }
  };

  return (
    <div style={{ marginBottom: positionedBeforeDate ? "74px" : "16px" }}>
      <OperationTitleWithIcon
        actionRef={operation.actionRef}
        color={color}
        title={`Role changed to ${getRoleText(operation.group[0].role)}`}
      />
      {operation.group.map((action, index) => (
        <div key={index}>
          <Typography
            noWrap
            paragraph={operation.group.length - 1 === index}
            variant="body2"
            color="text.secondary"
            className="small"
          >
            {action.membership.memberDetails.email}
          </Typography>
        </div>
      ))}
    </div>
  );
}

export default MembershipChangeAccess;
