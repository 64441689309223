import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.background.card,
    borderRadius: "4px",
    padding: theme.spacing(4),
    border: "1px solid",
    borderColor: theme.palette.background.tertiary,
    minHeight: ({ noMinHeight }) => !noMinHeight && "154px"
  },

  phraseWords: {
    lineHeight: 2.5
    // wordSpacing: theme.spacing(3), //not working in Safari
  }
}));

function BackupPhraseBox({ backupPhraseArray, noMinHeight }) {
  const classes = useStyles({ noMinHeight: noMinHeight });
  return (
    <Box className={classes.container}>
      <Typography
        variant="body1"
        align="center"
        className={classes.phraseWords}
        color="text.primary"
      >
        {backupPhraseArray &&
          backupPhraseArray.map((word, i) => (
            <span key={i}>
              <sup style={{ marginRight: "4px" }}>{i + 1}</sup>
              <Typography
                component="span"
                variant="body1"
                className="strong"
                color="text.primary"
                style={{ marginRight: "12px" }}
              >
                {word}{" "}
              </Typography>
            </span>
          ))}
      </Typography>
    </Box>
  );
}

export default BackupPhraseBox;
