import { fileDownload } from "./akord-actions";
import { filterActionRefs } from "./akord-enums";

const dateMap = {
  TWENTY_FOUR_HOURS: 60 * 60 * 24 * 1000,
  FORTY_EIGHT_HOURS: 60 * 60 * 24 * 1000 * 2,
  SEVEN_DAYS: 60 * 60 * 24 * 1000 * 7,
  THIRTY_DAYS: 60 * 60 * 24 * 1000 * 30
};

export const filterMemos = (decryptedMemos, periodFilter, actionFilters) => {
  let filteredDecryptedMemos = decryptedMemos
    .filter(item => {
      if (!periodFilter) return true;
      else return new Date() - new Date(item.postedAt) <= dateMap[periodFilter];
    })
    .filter(item => {
      if (!actionFilters.length) return true;
      else
        return (
          actionFilters.indexOf(item.ownerInfo.fullName) !== -1 ||
          actionFilters.indexOf(item.ownerInfo.email) !== -1
        );
    });

  return filteredDecryptedMemos;
};

export const filterTimeline = (
  decryptedTimeline,
  periodFilter,
  actionFilters
) => {
  let filteredTimeline = decryptedTimeline
    .filter(item => {
      if (!periodFilter) return true;
      else return new Date() - new Date(item.postedAt) <= dateMap[periodFilter];
    })
    .filter(item => {
      if (!actionFilters.length) return true;
      else return actionFilters.indexOf(item.actionRef) !== -1;
    });

  return filteredTimeline;
};

export const filterDecryptedStacks = (
  decryptedStacks,
  periodFilter,
  actionFilters
) => {
  let filteredTimeline = decryptedStacks
    .filter(item => {
      if (!periodFilter) return true;
      else
        return (
          new Date() - new Date(item.postedAt || item.updatedAt) <=
          dateMap[periodFilter]
        );
    })
    .filter(item => {
      if (!actionFilters || !actionFilters.length) return true;
      else
        return (
          actionFilters.indexOf(
            item.files ? item.files[0].fileType : item.type
          ) !== -1
        );
    });

  return filteredTimeline;
};

export const createMembersMap = members => {
  return members
    .filter(member => member.status !== "INVITED")
    .sort((a, b) => {
      if (a.createdAt > b.createdAt) return 1;
      if (a.createdAt < b.createdAt) return -1;
      return 0;
    })
    .reduce((acc, item, index) => {
      return { ...acc, [item.state.memberDetails.email]: index };
    }, {});
};

export const filtersToShow = (
  isChat,
  isTimeline,
  decryptedTimeline,
  filtersListMap,
  decryptedAssets,
  decryptedMemos
) =>
  isTimeline
    ? [
        ...new Set(
          decryptedTimeline
            .map(operation => operation.actionRef)
            .filter(
              item =>
                !filterActionRefs.includes(item) &&
                item !== "STACK_UPLOAD_REVISION" &&
                item !== "USER_VERIFIED"
            )
            .sort((a, b) => {
              if (filtersListMap[a] < filtersListMap[b]) {
                return -1;
              }
              if (filtersListMap[a] > filtersListMap[b]) {
                return 1;
              }
              return 0;
            })
        )
      ]
    : isChat
    ? [
        ...new Set(
          decryptedMemos.map(memo =>
            memo.ownerInfo.fullName
              ? memo.ownerInfo.fullName
              : memo.ownerInfo.email
          )
        )
      ]
    : [
        ...new Set(
          decryptedAssets.map(stack =>
            stack.files ? stack.files[0].fileType : stack.type
          )
        )
      ];

export const downloadSelectedFile = (
  currentStack,
  fileViewerData,
  encrypter,
  onUploadStart,
  onShowLoader,
  onProgress,
  onSnackbar,
  onUploadHook,
  isVaultPublic = false
) => {
  const download = fileDownload(
    currentStack,
    encrypter,
    onShowLoader,
    onProgress,
    null,
    fileViewerData.originalIndex,
    onSnackbar,
    onUploadHook,
    isVaultPublic
  );
  onUploadStart(download);
};
