import { Box, IconButton, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withRouter } from "react-router";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import SearchTrigger from "../../search/SearchTrigger";
import LogoIcon from "../logo/logoOrange";
import { AvatarIcon } from "@akord/addon-icons";
import DropdownMenu from "./DropdownMenu";
import { grey } from "../../../theme/colors";

const useStyles = makeStyles({
  iconBig: {
    fontSize: "32px"
  },
  iconHover: {
    "&:hover": {
      color: "inherit"
    },
    padding: "0"
  },
  avatar: {
    width: "32px",
    height: "32px",
    borderRadius: "50%"
  },
  logo: {
    fill: "currentColor",
    color: ({ darkMode }) => (darkMode ? "white" : grey[800])
  }
});

function TopBarMain() {
  const { decryptedProfileDetails, darkMode, isMobile } = useGlobalContext();
  const classes = useStyles({ darkMode: darkMode });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      px={5}
      pt={isMobile ? 3 : 4}
      mb={isMobile ? 0 : 7}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      style={{ position: isMobile && "fixed" }} // on mobile we need the top menu stay fixed
      width="100%"
      zIndex="1"
    >
      <LogoIcon width="74" height="28" className={classes.logo} />
      <Box display="flex" alignItems="center">
        {isMobile && (
          <Box marginRight="15px">
            <SearchTrigger></SearchTrigger>
          </Box>
        )}
        <Tooltip title="Profile" arrow>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            className={classes.iconHover}
            size="large"
          >
            {decryptedProfileDetails && decryptedProfileDetails.avatarUrl ? (
              <img
                src={decryptedProfileDetails.avatarUrl}
                className={classes.avatar}
                alt="Avatar"
              />
            ) : (
              <AvatarIcon className={classes.iconBig} color="disabled" />
            )}
          </IconButton>
        </Tooltip>
        <DropdownMenu
          type="avatarMenu"
          anchorEl={anchorEl}
          onAnchorEl={setAnchorEl}
          onClose={handleClose}
          openMenu={openMenu}
          position="left"
        />
      </Box>
    </Box>
  );
}

export default withRouter(TopBarMain);
