import React from "react";
import { Auth } from "aws-amplify";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@mui/material";
import {
  DarkModeIcon,
  DarkModeFilledIcon,
  LightModeFilledIcon,
  LightModeIcon,
  AutoModeFilledIcon,
  AutoModeIcon
} from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { SupportScreenWrapper } from "../../components/common/";

const useStyles = makeStyles({
  formControl: {
    marginBottom: ({ isMobile }) => (isMobile ? "12px" : 0)
  }
});

const displayOptions = [
  {
    value: "dark",
    name: "Dark mode",
    Icon: DarkModeIcon,
    IconFilled: DarkModeFilledIcon
  },
  {
    value: "light",
    name: "Light mode",
    Icon: LightModeIcon,
    IconFilled: LightModeFilledIcon
  },
  {
    value: "auto",
    name: "Auto",
    Icon: AutoModeIcon,
    IconFilled: AutoModeFilledIcon
  }
];

function Display() {
  const { isMobile, onUserColorMode, onIsDarkMode, userColorMode } =
    useGlobalContext();
  const classes = useStyles({ isMobile: isMobile });
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const handleChange = async event => {
    onUserColorMode(event.target.value);
    onIsDarkMode(
      event.target.value === "dark"
        ? true
        : event.target.value === "light"
        ? false
        : prefersDarkMode
    );
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      "custom:mode": event.target.value
    });
  };

  return (
    <SupportScreenWrapper title="Display" route="/settings">
      <Box mt={6}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="color-mode"
            name="mode1"
            value={userColorMode}
            onChange={handleChange}
          >
            {displayOptions.map((option, index) => (
              <FormControlLabel
                key={index}
                className={classes.formControl}
                value={option.value}
                control={
                  <Radio
                    disableRipple
                    icon={<option.Icon />}
                    checkedIcon={<option.IconFilled />}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    className="strong"
                    color="text.primary"
                  >
                    {option.name}
                  </Typography>
                }
              />
            ))}
          </RadioGroup>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{
              margin: isMobile ? "-14px 0 0 31px" : "-6px 0 0 31px"
            }}
          >
            Adjusts depending on your operating system.
          </Typography>
        </FormControl>
      </Box>
    </SupportScreenWrapper>
  );
}

export default Display;
