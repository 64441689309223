import React from "react";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { getNoteId } from "../../../helpers/helpers";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Editor from "@akord/rich-markdown-editor";
import { light, dark } from "./myTheme";
import DialogFileViewerWrapper from "./DialogFileViewerWrapper";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";

const defaultRTValue = "";
const regexHeadings = /(?:__|[*#])|\[(.*?)\]\(.*?\)/gm;

const useStyles = makeStyles({
  noteContainer: {
    marginLeft: ({ isMobile }) => (isMobile ? "16px" : "auto"),
    marginRight: ({ isMobile }) => (isMobile ? "16px" : "auto"),
    marginTop: ({ isMobile }) => (isMobile ? "20px" : "69px"),
    width: ({ isMobile }) => (isMobile ? "auto" : "640px")
  },
  bodyEditor: {
    fontSize: "18px"
  }
});

function DialogNoteViewer() {
  const { decryptedMemberships, darkMode, isMobile } = useGlobalContext();
  const { decryptedNotes } = useAssetsContext();

  const { modal, onNotificationData } = useNotificationsContext();
  const classes = useStyles({ darkMode: darkMode, isMobile: isMobile });
  const originalNote = React.useRef();

  const history = useHistory();
  const noteId = getNoteId(history.location.pathname);

  const [noteText, setNoteText] = React.useState(defaultRTValue);

  const currentNote =
    decryptedNotes?.filter(note => note.id === noteId)[0] || {};

  const handleOnChange = state => {
    setNoteText(state());
  };

  const handleNoteSave = async () => {
    //Create a name for the note from the first line of the note
    const firstLine = noteText.split("\n")[0];
    const noteTitle = firstLine.replace(regexHeadings, "$1").trim();
    modal.onConfirmModalType("saveNote");
    modal.onModalVisibility(null, "saveNote");
    onNotificationData({
      hash: currentNote?.hash || null,
      noteText: noteText,
      folderId: history.location.state?.folderId || null,
      fileType: "note",
      title: noteTitle + ".note" || "untitled.note"
    });
  };

  const getNoteViewerData = note => {
    const content = JSON.parse(note.content);

    if (content && typeof content === "string") {
      setNoteText(content);
      originalNote.current = content;
    } else {
      setNoteText("ERROR: Unsupported format. Not a mark down note!");
      originalNote.current = "ERROR: Unsupported format. Not a mark down note!";
    }
  };

  const isNoteChanged = () => {
    if (JSON.stringify(originalNote.current) === JSON.stringify(noteText))
      return true;
    return false;
  };

  if (!decryptedMemberships) return null;

  return (
    <DialogFileViewerWrapper
      isNote
      overflowYAuto
      showDropDown={!!noteId}
      onSaveClick={handleNoteSave}
      onViewerData={getNoteViewerData}
      onlyDarkMode={darkMode}
      buttonDisable={
        !Object.values(noteText).some(text => text) || isNoteChanged()
      }
    >
      <Box className={classes.noteContainer}>
        <Editor
          className={classes.bodyEditor}
          placeholder="What do you want to never forget?"
          theme={darkMode ? dark : light}
          hideDropDownToolbar
          autoFocus
          disableExtensions={[
            "code_inline",
            "highlight",
            "em",
            "link",
            "placeholder",
            "strikethrough",
            "underline",
            "blockquote",
            "checkbox_item",
            "checkbox_list",
            "code_block",
            "code_fence",
            "embed",
            "hr",
            "image",
            "container_notice",
            "table",
            "td",
            "th",
            "tr",
            "emoji"
          ]}
          defaultValue={noteText}
          value={originalNote.current}
          dictionary={{ newLineEmpty: "" }}
          onChange={value => handleOnChange(value)}
        />
      </Box>
    </DialogFileViewerWrapper>
  );
}

export default DialogNoteViewer;
