import React from "react";
import { Switch } from "react-router-dom";
import LoggedInRoute from "./LoggedInRoute";
import RoutesHome from "./RoutesHome";
import GlobalDataProvider from "../../contexts/GlobalDataProvider";
import Profile from "../../pages/room-options/Profile";
import AcceptInvite from "../../pages/room-options/AcceptInvite";
import ConfirmAccess from "../../pages/room-options/ConfirmAccess";
import RejectInvite from "../../pages/room-options/RejectInvite";
import SnackBarWrapper from "../common/Wrappers/SnackBarWrapper";
import Referral from "../../pages/room-options/Referral";

function SecureRoutes(props) {
  return (
    <GlobalDataProvider>
      <SnackBarWrapper>
        <Switch>
          <LoggedInRoute
            path="/reject-invite"
            exact
            component={RejectInvite}
            {...props}
          />
          <LoggedInRoute path="/profile" exact component={Profile} />
          <LoggedInRoute path="/referral" exact component={Referral} />
          <LoggedInRoute
            path="/invite"
            exact
            component={AcceptInvite}
            {...props}
          />
          <LoggedInRoute path="/confirm" exact component={ConfirmAccess} />
          <LoggedInRoute path="/" component={RoutesHome} {...props} />
        </Switch>
      </SnackBarWrapper>
    </GlobalDataProvider>
  );
}

export default SecureRoutes;
