import React from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { withWallet, useGlobalContext } from '../../contexts/GlobalDataProvider'
import queryString from 'query-string'

function LoggedInRoute({ component: Component, ...rest }) {
  const { isAuthenticated, isAuthLoaded } = useGlobalContext()

  if (!isAuthLoaded) return null

  return (
    <Route
      {...rest}
      render={props => {
        const redirectPath = props.location.pathname
        const queryToken = queryString.parse(props.location.search)
        // If user is NOT logged in, resolve
        if ((queryToken.membershipId || redirectPath) && !isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: '/sign-in',
                state: { from: props.location }
              }}
            />
          )
        }
        if (!isAuthenticated) {
          return <Redirect to="/sign-in" />
        }
        return <Component {...props} {...rest} />
      }}
    />
  )
}

export default withRouter(withWallet(LoggedInRoute))
