import React from "react";
import Drawer from "@mui/material/Drawer";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, IconButton, Divider } from "@mui/material";
import { ChevronRightIcon } from "@akord/addon-icons";
import FilterActionsDesktop from "./FilterActionsDesktop";
import FilterActionsMobile from "./FilterActionsMobile";
import FilterPeriodMobile from "./FilterPeriodMobile";
import FilterPeriodDesktop from "./FilterPeriodDesktop";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { filtersToShow } from "../../../helpers/stack-helpers";
import { filtersListMap } from "./filterMap";
import { useLocation } from "react-router";
import { getFolderId } from "../../../helpers/helpers";
import { useTimelineContext } from "../../../contexts/TimelineContextProvider";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import { useChatContext } from "../../../contexts/ChatContextProvider";

const useStyles = makeStyles({
  paperAnchorRight: {
    width: ({ isMobile }) => (isMobile ? "85%" : "336px")
  },
  backDrop: {
    top: ({ isMobile, topHeight }) => (isMobile ? 0 : `${topHeight + 86}px`),
    left: ({ isMobile }) => (isMobile ? 0 : "250px")
  },
  paper: {
    top: ({ isMobile, topHeight }) => (isMobile ? 0 : `${topHeight + 86}px`)
  },
  resetText: {
    color: ({ hasTrueKeys }) => hasTrueKeys && "#F85B15"
  },
  divider: {
    marginLeft: ({ isMobile }) => isMobile && -20,
    marginRight: ({ isMobile }) => isMobile && -20
  }
});

function FilterDrawer({ topHeight }) {
  const { isMobile, darkMode } = useGlobalContext();

  const {
    onPeriodFilter,
    onActionFilters,
    actionFilters,
    periodFilter,
    showFilter,
    onShowFilter,
    activeDecryptedStacks,
    activeDecryptedNotes,
    decryptedFolders
  } = useAssetsContext();

  const { decryptedTimeline } = useTimelineContext() || [];
  const { decryptedMemos } = useChatContext() || [];
  // const hasTrueKeys = Object.keys(filters).some(k => filters[k])
  const location = useLocation();
  const isFiltering = actionFilters.length > 0 || periodFilter;
  const classes = useStyles({
    isMobile: isMobile,
    hasTrueKeys: isFiltering,
    darkMode: darkMode,
    topHeight: topHeight
  });

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    onShowFilter(open);
  };

  const resetFilter = () => {
    onActionFilters([]);
    onPeriodFilter(null);
  };

  const isTimeline = location.pathname.match("/timeline");
  const isChat = location.pathname.match("/chat");

  let decryptedAssets = [
    ...activeDecryptedStacks,
    ...decryptedFolders,
    ...activeDecryptedNotes
  ];

  const folderId = getFolderId(location.pathname);
  decryptedAssets = decryptedAssets.filter(item => {
    if (item.folderId === undefined && !folderId) return item;
    else return item.folderId === folderId;
  });

  const filtersList = filtersToShow(
    isChat,
    isTimeline,
    decryptedTimeline,
    filtersListMap,
    decryptedAssets,
    decryptedMemos
  );

  return (
    <Drawer
      variant={isMobile ? "temporary" : "persistent"}
      anchor="right"
      open={showFilter}
      onClose={toggleDrawer(false)}
      BackdropProps={{ className: classes.backDrop }}
      PaperProps={{ className: classes.paper }}
      classes={{
        paperAnchorRight: classes.paperAnchorRight
      }}
    >
      <Box ml={5} mr={5} mt={3} height="calc(100% - 88px)">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Typography
            variant="body2"
            className="small"
            classes={{ root: classes.resetText }}
            color="text.secondary"
            onClick={resetFilter}
            style={{
              cursor: isFiltering && "pointer"
            }}
          >
            Reset
          </Typography>
          <Typography variant="h3" color="text.primary">
            Filters
          </Typography>
          <IconButton
            style={{ padding: 0 }}
            onClick={toggleDrawer(false)}
            size="large"
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
        <Divider className={classes.divider} />
        <Box
          pt={5}
          height="calc(100% - 47px)"
          style={{ overflowY: !isMobile ? "auto" : "none" }}
        >
          {isMobile ? (
            <>
              <FilterActionsMobile
                keepOpen={actionFilters.length > 0}
                hasTrueKeys={isFiltering}
                filtersList={filtersList}
              />
              <FilterPeriodMobile
                keepOpen={!!periodFilter}
                hasTrueKeys={isFiltering}
              />
            </>
          ) : (
            <>
              <FilterActionsDesktop
                hasTrueKeys={isFiltering}
                filtersList={filtersList}
              />
              <FilterPeriodDesktop hasTrueKeys={isFiltering} />
            </>
          )}
        </Box>
      </Box>
    </Drawer>
  );
}

export default FilterDrawer;
