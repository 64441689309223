import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { blue } from "../../theme/colors";

const useStyles = makeStyles(theme => ({
  transactionBox: {
    border: `1px solid`,
    borderColor: ({ darkMode }) => (darkMode ? blue[500] : blue[400]),
    borderRadius: "4px",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  transactionText: {
    color: ({ darkMode }) => (darkMode ? blue[400] : blue[500])
  },
  spinner: {
    position: "inherit"
  }
}));

function TransactionSpinner({
  isMobile,
  darkMode,
  text = "Transaction",
  mobileText = "TX",
  showText = true
}) {
  const classes = useStyles({ darkMode: darkMode });

  return (
    <Box display="flex" alignItems="center" className={classes.transactionBox}>
      {showText && (
        <Box mr={1}>
          <Typography
            variant="body2"
            className={["small", classes.transactionText].join(" ")}
          >
            {isMobile ? mobileText : text}
          </Typography>
        </Box>
      )}
      <CircularProgress
        size="1rem"
        color="secondary"
        className={classes.spinner}
      />
    </Box>
  );
}

export default TransactionSpinner;
