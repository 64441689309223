import React from "react";
import { Typography } from "@mui/material";
import OperationTitleWithIcon from "../../../components/common/OperationTitle/OperationTitleWithIcon";

function MemberInvite(props) {
  const { operation, positionedBeforeDate, color } = props;

  return (
    <div style={{ marginBottom: positionedBeforeDate ? "74px" : "16px" }}>
      <OperationTitleWithIcon
        actionRef={operation.actionRef}
        color={color}
        title={operation.group.length === 1 ? "New invite sent" : null}
      />
      {operation.group.map((invite, index) => (
        <div key={index}>
          <Typography
            noWrap
            variant="body2"
            color="text.secondary"
            className="small"
            paragraph={operation.group.length - 1 === index}
          >
            {invite.membership.memberDetails?.email}
          </Typography>
        </div>
      ))}
    </div>
  );
}

export default MemberInvite;
