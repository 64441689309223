import axios from "axios";
import { cloneDeep } from "lodash";
import { Storage } from "aws-amplify";
import {
  EncrypterFactory,
  fromProfileState,
  fromMembership,
  base64ToJson
} from "@akord/crypto";

export const decryptObjects = async (encrypter, itemsArray, fieldsArray) => {
  if (!itemsArray || !itemsArray.length) {
    return [];
  }

  const itemsArrayCopy = cloneDeep(itemsArray);
  const decryptedObjects = itemsArrayCopy.map(async item => {
    const decryptedObject = await encrypter.decryptObject(
      item?.state,
      fieldsArray
    );
    // let resultRevisions = item.state.revisions

    if (item?.state?.revisions?.length > 0) {
      const revisionsPromises = item.state.revisions.map(
        async revision =>
          await encrypter.decryptObject(revision, ["content", "title"])
      );
      await Promise.all(revisionsPromises).then(res => res);
    }
    // Only STATUS is being decrypted
    // we need to add other properties from the root level
    // TODO: return original Stack obj with overwritten STATE
    decryptedObject.updatedAt = item.postedAt;
    decryptedObject.hash = item.hash;
    decryptedObject.id = item.id;
    decryptedObject.folderId = item.folderId;
    decryptedObject.status = item.status;
    decryptedObject.storageTransactions = item.storageTransactions;
    // decryptedObject.revisions = resultRevisions

    return decryptedObject;
  });

  const result = await Promise.all(decryptedObjects).then(res => res);

  return result;
};

export const mimicDecryptObjects = itemsArray => {
  if (!itemsArray || !itemsArray.length) {
    return [];
  }

  const itemsArrayCopy = cloneDeep(itemsArray);

  return itemsArrayCopy.map(item => {
    const transformedObject = item?.state;
    // Only STATUS is being decrypted
    // we need to add other properties from the root level
    // TODO: return original Stack obj with overwritten STATE
    transformedObject.updatedAt = item.postedAt;
    transformedObject.hash = item.hash;
    transformedObject.id = item.id;
    transformedObject.folderId = item.folderId;
    transformedObject.status = item.status;
    transformedObject.storageTransactions = item.storageTransactions;

    return transformedObject;
  });
};

export const decryptStacks = async (encrypter, stacksArray, roomData) => {
  const stacksCopy = cloneDeep(stacksArray);

  const decryptedStacks = stacksCopy.map(async stack => {
    let decryptedStack;
    try {
      decryptedStack = await encrypter.decryptStack(stack.state);
    } catch (e) {
      console.log("Error decrypting stack, passing as is: ", e);
      decryptedStack = stack.state;
    }
    // Only STATUS is being decrypted
    // we need to add other properties from the root level
    // TODO: return original Stack obj with overwritten STATE
    decryptedStack.updatedAt = stack.updatedAt;
    decryptedStack.hash = stack.hash;
    decryptedStack.id = stack.id;
    decryptedStack.folderId = stack.folderId;
    decryptedStack.status = stack.status;
    decryptedStack.storageTransactions = stack.storageTransactions;

    Object.assign(decryptedStack, roomData);
    return decryptedStack;
  });

  const result = await Promise.all(decryptedStacks).then(res => res);
  return result;
};

// Mimic the structure of the decrypted object
export const mimicDecryptStacks = (stacksArray, roomData) => {
  const stacksCopy = cloneDeep(stacksArray);

  return stacksCopy.map(stack => {
    let decryptedStack = stack.state;
    decryptedStack.updatedAt = stack.updatedAt;
    decryptedStack.hash = stack.hash;
    decryptedStack.id = stack.id;
    decryptedStack.folderId = stack.folderId;
    decryptedStack.status = stack.status;
    decryptedStack.storageTransactions = stack.storageTransactions;

    Object.assign(decryptedStack, roomData);
    return decryptedStack;
  });
};

const downloadThumbnail = async decodedItem => {
  try {
    const url = await Storage.get(decodedItem.files[0].thumbnailUrl);
    const downloadRes = await axios.get(url);
    return downloadRes.data;
  } catch (err) {
    console.log(err);
  }
};

export const decryptTimeline = (encrypter, timeline, membersMap, roomData) => {
  try {
    const timelineCopy = cloneDeep(timeline);
    const groupRefSet = [];
    const decryptedTimeline = timelineCopy.map(async item => {
      const decodedBody = base64ToJson(item.encodedBody);
      const decodedItem = { ...item, ...decodedBody };

      if (
        decodedItem.files &&
        decodedItem.files[0].thumbnailUrl &&
        window.navigator.onLine &&
        (!groupRefSet.includes(item.groupRef) || !item.groupRef)
      ) {
        decodedItem.thumbnailUrl = downloadThumbnail(decodedItem);
        groupRefSet.push(item.groupRef);
      }

      decodedItem.ownerInfo = membersMap.get(item.publicSigningKey);
      decodedItem.thumbnailUrl = await Promise.resolve(
        decodedItem.thumbnailUrl
      );

      const decryptedTimelineItem = encrypter?.publicKey
        ? await encrypter.decryptOperation(decodedItem)
        : decodedItem;
      Object.assign(decryptedTimelineItem, roomData);
      return decryptedTimelineItem;
    });

    const result = Promise.all(decryptedTimeline).then(res => res);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const decryptSelf = async (wallet, fullProfile) => {
  if (
    wallet === undefined ||
    Object.entries(wallet)?.length === 0 ||
    !fullProfile
  ) {
    return null;
  }

  const encryptionKeys = fromProfileState(fullProfile.state);
  const akordEncrypter = new EncrypterFactory(
    wallet,
    encryptionKeys
  ).encrypterInstance();
  const profileDetails = fullProfile.state.profileDetails;
  try {
    if (profileDetails.avatarUrl && window.navigator.onLine) {
      const url = await Storage.get(profileDetails.avatarUrl);
      const downloadRes = await axios.get(url);
      profileDetails.avatarUrl = downloadRes.data;
    }
    const decryptedSelf = await akordEncrypter.decryptSelf(profileDetails);
    return decryptedSelf;
  } catch (err) {
    console.log("Self decryption err: ", err);
  }
};

export const decryptMemberships = async (
  wallet,
  memberships,
  decryptedAvatarsMap,
  decryptAvatars = true
) => {
  if (wallet === undefined || Object.entries(wallet).length === 0) return null;
  try {
    const decryptedMemberships = await Promise.all(
      memberships.map(async membership => {
        if (membership.dataRoom.state.isPublic) {
          return Promise.resolve(membership);
        }
        return decryptMembership(
          membership,
          wallet,
          decryptedAvatarsMap,
          decryptAvatars
        );
      })
    );
    return decryptedMemberships;
  } catch (err) {
    console.log(err);
  }
};

export const decryptMembership = async (membership, wallet) => {
  const encryptionKeys = fromMembership(membership);
  const encrypter = new EncrypterFactory(
    wallet,
    encryptionKeys
  ).encrypterInstance();

  const decryptedDataRoomState = await decryptDataRoomState(
    membership,
    encrypter
  );
  if (!decryptedDataRoomState) {
    return null;
  }

  const decryptedMembership = cloneDeep(membership);
  decryptedMembership.dataRoom.state = decryptedDataRoomState;
  return decryptedMembership;
};

const decryptDataRoomState = async (membership, encrypter) => {
  try {
    return await encrypter.decryptDataroom(membership.dataRoom.state);
  } catch (err) {
    console.log(err);
  }
  return null;
};

export const decryptDataRoomMembers = async (
  membership,
  encrypter,
  decryptedAvatarsMap
) => {
  if (
    membership?.dataRoom?.members?.items &&
    Array.isArray(membership.dataRoom.members.items)
  ) {
    try {
      const decryptedMembers = await Promise.all(
        membership.dataRoom.members.items.map(
          async member =>
            await mapMember(member, encrypter, decryptedAvatarsMap)
        )
      );
      return decryptedMembers;
    } catch (err) {
      console.log(err);
    }
  }
  return null;
};

export const mapMember = async (membership, encrypter, decryptedAvatarsMap) => {
  if (membership?.state?.memberDetails) {
    const mappedMember = cloneDeep(membership);
    mappedMember.state.memberDetails = await mapMemberDetails(
      membership,
      encrypter,
      decryptedAvatarsMap
    );
    return mappedMember;
  }
  return membership;
};

export const mapMemberDetails = async (
  member,
  encrypter,
  decryptedAvatarsMap
) => {
  if (member?.state?.memberDetails) {
    const memberDetails = cloneDeep(member.state.memberDetails);
    if (decryptedAvatarsMap.has(memberDetails.email)) {
      memberDetails.avatarUrl = decryptedAvatarsMap.get(memberDetails.email);
    } else {
      memberDetails.avatarUrl = await getAvatarUrl(memberDetails);
      decryptedAvatarsMap.set(memberDetails.email, memberDetails.avatarUrl);
    }
    return await mapDetails(memberDetails, encrypter);
  }
  return null;
};

const getAvatarUrl = async memberDetails => {
  if (memberDetails.avatarUrl && window.navigator.onLine) {
    try {
      const url = await Storage.get(memberDetails.avatarUrl);
      const downloadRes = await axios.get(url);
      return downloadRes.data;
    } catch (err) {
      console.log(err);
    }
  }
  return null;
};

const mapDetails = async (memberDetails, encrypter) => {
  try {
    return await encrypter.decryptMember(memberDetails);
  } catch (err) {
    console.log(err);
  }
  return null;
};
