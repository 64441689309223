import React from "react";
import {
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box
} from "@mui/material";
import { UploadForm } from "..";
import { fileRevision } from "../../../helpers/akord-actions";

function MoreDrawerItem({
  menuItem,
  currentStack,
  handleMenuClose,
  onDrawerActionType
}) {
  return (
    <Box my={6}>
      <ListItem
        disabled={menuItem.disabled}
        disableGutters
        button
        onClick={menuItem.action}
        component={menuItem.upload ? "label" : "li"}
        htmlFor={menuItem.upload ? "upload-file" : null}
      >
        {menuItem.upload && onDrawerActionType && (
          <UploadForm
            actionFunc={fileRevision}
            currentStack={currentStack}
            handleMenuClose={handleMenuClose}
            onDrawerActionType={onDrawerActionType}
            multiple={false}
          />
        )}
        {menuItem.upload && !onDrawerActionType ? (
          <label
            htmlFor="contained-button-file"
            style={{ display: "flex", cursor: "pointer" }}
          >
            <ListItemIcon>
              <menuItem.icon fontSize="small" color="disabled" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2" color="text.primary">
                {menuItem.text}
              </Typography>
            </ListItemText>
          </label>
        ) : (
          <>
            <ListItemIcon>
              <menuItem.icon fontSize="small" color="disabled" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2" color="text.primary">
                {menuItem.text}
              </Typography>
            </ListItemText>
          </>
        )}
      </ListItem>
    </Box>
  );
}

export default MoreDrawerItem;
