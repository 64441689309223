import React from 'react'
import { withRouter } from 'react-router-dom'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery'
import { PageWrapper } from '../../components/common/'
import LoginPage from './LoginPage'
import muiTheme from '../../theme/mui'
import { useLoginContext } from '../../contexts/LoginContext'
import SnackBarConfirm from '../../components/common/SnackBar/SnackBarConfirm'
import AnnouncementV2 from '../../components/common/Announcement/Announcement';

const useStyles = makeStyles({
  main: {
    justifyContent: ({ isDesktop }) => isDesktop && 'center'
  },
  snackbar: {
    left: '16px',
    right: '16px',
    bottom: '16px',
    '@media (min-width: 700px)': {
      width: '400px',
      left: '50%'
    }
  }
})

function LoginPageContainer() {
  const { error } = useLoginContext()
  const theme = muiTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true
  })
  const classes = useStyles({
    isDesktop: isDesktop
  })

  return (
    <PageWrapper
      error={error}
      darkMode={true}
      showBottomNav={false}
      mainClassName={classes.main}
      pageTitle="Login"
    >
      <AnnouncementV2/>
      <Box>
        <LoginPage />
      </Box>
      <SnackBarConfirm />
    </PageWrapper>
  )
}

export default withRouter(LoginPageContainer)
