import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { downloadTxtFile, copyToClipboard } from "../../helpers/helpers";
import { DownloadIcon, ClipboardIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import BackupPhraseBox from "../../components/common/BackupPhraseBox";

const useStyles = makeStyles(theme => ({
  containerText: {
    lineHeight: "32px"
  },
  rootIcon: {
    marginRight: theme.spacing(3),
    color: theme.palette.primary.main
  }
}));

function SecurityPhrase({ backupPhrase, userEmail }) {
  const { darkMode } = useGlobalContext();
  const classes = useStyles({ darkMode: darkMode });
  const [copy, setCopy] = React.useState(false);

  return (
    <Box mb={5}>
      <Typography variant="body2">
        Below is your unique 12-word phrase.
      </Typography>
      <Box my={5}>
        <BackupPhraseBox backupPhraseArray={backupPhrase} noMinHeight />
      </Box>

      <Box mb={7} className={classes.containerText}>
        <DownloadIcon
          fontSize="small"
          color="action"
          className={classes.rootIcon}
        />
        <Typography
          component="a"
          variant="body2"
          color="text.primary"
          onClick={() => {
            downloadTxtFile(backupPhrase, userEmail);
          }}
        >
          Download
        </Typography>
        <br />
        <ClipboardIcon
          fontSize="small"
          color="action"
          className={classes.rootIcon}
        />
        <Typography
          component="a"
          variant="body2"
          color="text.primary"
          onClick={() => copyToClipboard(backupPhrase.join(" "), setCopy)}
        >
          {copy ? "Copied!" : "Copy to clipboard"}
        </Typography>
      </Box>
    </Box>
  );
}

export default SecurityPhrase;
