import React from "react";
import { Box, TableContainer, Table, TableBody } from "@mui/material";
import {
  DataRoomFilter,
  ProgressBar,
  ErrorAlert,
  DropZone,
  EmptySpace,
  AssetsPagination
} from "../../components/common";
import StackItem from "./StackItem";
import FolderItem from "./FolderItem";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import NoteItem from "./NoteItem";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";

function TabDocuments({ docsRef }) {
  const { currentMembership, userRole, isVaultPublic } = useVaultContext();

  const {
    showLoader,
    axiosError,
    onUploadStart,
    activeDecryptedNotes,
    activeDecryptedStacks,
    decryptedAssets,
    assetsDecrypted,
    order,
    onOrder,
    orderBy,
    onOrderBy,
    getPaginatedData
  } = useAssetsContext();

  const { width, darkMode } = useGlobalContext();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    onOrder(isAsc ? "desc" : "asc");
    onOrderBy(property);
  };

  return (
    <Box>
      <div
        ref={docsRef}
        style={{
          width: "100%",
          height: "100%",
          paddingTop: 0,
          position: "absolute"
        }}
      >
        <DropZone userRole={userRole}>
          <ProgressBar darkMode={darkMode} />
          {axiosError && (
            <Box my={4} mx={5}>
              <ErrorAlert getErrorText={axiosError} darkMode={true} />
            </Box>
          )}
          {Object.keys(currentMembership).length === 0 ||
          (decryptedAssets.length > 0 &&
            Object.keys(currentMembership).length > 0) ||
          showLoader ||
          !assetsDecrypted ? (
            <TableContainer style={{ marginTop: "24px" }}>
              <Table
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
              >
                <DataRoomFilter
                  order={order}
                  orderBy={orderBy}
                  data={[...activeDecryptedStacks, ...activeDecryptedNotes]}
                  onRequestSort={handleRequestSort}
                  width={width}
                />
                <TableBody>
                  {decryptedAssets && assetsDecrypted
                    ? getPaginatedData().map((item, index) =>
                        item.files ? (
                          <StackItem key={index} stack={item} />
                        ) : item.type === "folder" ? (
                          <FolderItem key={index} folder={item} />
                        ) : (
                          <NoteItem key={index} note={item} />
                        )
                      )
                    : Array.from(new Array(8)).map((item, index) => (
                        <StackItem key={index} space={null} />
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <EmptySpace
              dataText={isVaultPublic ? "newPublicFile" : "newFile"}
              spaceData={currentMembership}
              onUploadStart={onUploadStart}
            />
          )}
          <Box display="flex" justifyContent="center" my={6}>
            <AssetsPagination bottomPosition />
          </Box>
        </DropZone>
      </div>
    </Box>
  );
}

export default TabDocuments;
