export const colorMap = [
  '#3592FA',
  '#A167FF',
  '#209582',
  '#BA3333',
  '#B16E13',
  '#254DF8',
  '#8B7872',
  '#B94181',
  '#3F9FB4',
  '#DE6F00',
]
