import React, { useState, useEffect, createContext, useContext } from "react";
import { useGlobalContext } from "./GlobalDataProvider";
import API, { graphqlOperation } from "@aws-amplify/api";
import { EncrypterFactory } from "@akord/crypto";
import { fromMembership } from "@akord/crypto";
import * as subscriptions from "../graphql/subscriptions";
import { notificationsPaginatedQuery } from "../graphql/queries/utils";
import { cloneDeep } from "lodash";
import {
  memoizedDecryptMembership,
  memoizedMapMemberDetails
} from "../helpers/notification-helper";

const Context = createContext();

function InAppNotificationsContextProvider({ children }) {
  const { wallet, transactionLog } = useGlobalContext();

  const [inAppNotifications, setInAppNotifications] = useState();
  const handleInAppNotifications = data => setInAppNotifications(data);

  // Original notifications
  const notificationsRef = React.useRef();
  // Proccessed notifications
  const inAppNotificationsRef = React.useRef();

  const loadTransactionLogs = async () => {
    try {
      const publicSigningKey = await wallet.signingPublicKey();
      const notifications = await notificationsPaginatedQuery(publicSigningKey);
      notificationsRef.current = notifications || [];
      await processNotifications();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (wallet) loadTransactionLogs();
  }, [wallet]);

  useEffect(() => {
    const addNewTransactionLog = async () => {
      // To keep orginal notifications in sync - and process all from scratch
      notificationsRef.current?.unshift(transactionLog);
      await processNotifications();
    };
    if (
      transactionLog &&
      transactionLog.status !== "DELETED" &&
      notificationsRef
    )
      addNewTransactionLog();
  }, [transactionLog]);

  const cacheEncrypter = new Map();
  const decryptedAvatarsMap = new Map();

  const processNotifications = async () => {
    if (!wallet) return;
    const publicSigningKey = await wallet.signingPublicKey();

    const notificationsCopy = cloneDeep(notificationsRef.current);
    if (!notificationsCopy) return;

    const updatedNotifications = await Promise.all(
      notificationsCopy?.map(async notification => {
        if (notification.admin && !notification.dataRoom) {
          return notification;
        }

        const currentMembership = notification.dataRoom.memberships.items.find(
          membership => membership.memberPublicSigningKey === publicSigningKey
        );
        const transactionMembership =
          notification.dataRoom.memberships.items.find(
            member =>
              member.memberPublicSigningKey ===
              notification.transactions.items[0].publicSigningKey
          );
        const decryptedMembership = await memoizedDecryptMembership(
          currentMembership,
          wallet
        );

        if (!decryptedMembership) return null;

        let encrypter;
        if (!cacheEncrypter.has(currentMembership.dataRoom.state.publicKeys)) {
          const encryptionKeys = fromMembership(decryptedMembership);
          encrypter = new EncrypterFactory(
            wallet,
            encryptionKeys
          ).encrypterInstance();
          cacheEncrypter.set(
            currentMembership.dataRoom.state.publicKeys,
            encrypter
          );
        } else {
          encrypter = cacheEncrypter.get(
            currentMembership.dataRoom.state.publicKeys
          );
        }

        const decryptedMember = await memoizedMapMemberDetails(
          transactionMembership,
          encrypter,
          decryptedAvatarsMap
        );

        notification.transactions.items[0].ownerInfo = decryptedMember;
        notification.transactions.items[0].membership = currentMembership.state;
        notification.transactions.items[0].membership.termsOfAccess =
          decryptedMembership?.dataRoom?.state?.termsOfAccess;

        // Reassign values to match previous model and to decrypt the data (field based)
        // TODO Ideally remove it
        notification.groupRef = notification.transactions.items[0].groupRef;

        notification.transactions.items[0].dataRoomName =
          decryptedMembership?.dataRoom?.state?.title || null;

        if (notification.transactions.items[0].stack)
          notification.transactions.items[0].title =
            notification.transactions.items[0].stack.title;

        if (notification.transactions.items[0].folder)
          notification.transactions.items[0].title =
            notification.transactions.items[0].folder.title;

        if (!currentMembership.dataRoom.state.isPublic) { 
          await encrypter.decryptOperation(notification.transactions.items[0]);
        }
        return notification;
      })
    );
    const filtered = updatedNotifications.filter(notification => notification);
    const combinedFilteredInAppNotifications = [];

    filtered?.forEach(notification => {
      const existing = combinedFilteredInAppNotifications.filter(v => {
        return (
          v.groupRef &&
          v.groupRef !== null &&
          v.groupRef !== "null" &&
          notification.groupRef !== null &&
          notification.groupRef !== "null" &&
          v.groupRef === notification.groupRef
        );
      });

      if (existing.length) {
        const existingIndex = combinedFilteredInAppNotifications.indexOf(
          existing[0]
        );

        combinedFilteredInAppNotifications[existingIndex].transactions.items =
          combinedFilteredInAppNotifications[
            existingIndex
          ].transactions.items.concat(notification.transactions.items);
      } else {
        combinedFilteredInAppNotifications.push(notification);
      }
    });

    inAppNotificationsRef.current = combinedFilteredInAppNotifications;
    handleInAppNotifications(combinedFilteredInAppNotifications);
  };

  const handleNotificationUpdate = updatedTransactionLog => {
    // Need to updated original and proccessed notifications
    const notificationsCopy = cloneDeep(notificationsRef.current);
    const inAppNotificationsCopy = cloneDeep(inAppNotificationsRef.current);

    if (!inAppNotificationsCopy) return;

    const index = inAppNotificationsCopy.findIndex(
      notification => notification.hash === updatedTransactionLog.hash
    );

    // To keep original notification in sync on update
    const originalNotificationIndex = notificationsCopy.findIndex(
      notification => notification.hash === updatedTransactionLog.hash
    );
    if (originalNotificationIndex !== -1) {
      notificationsCopy[originalNotificationIndex].status =
        updatedTransactionLog.status;
      notificationsRef.current = notificationsCopy;
    }

    if (index >= 0) {
      if (updatedTransactionLog.status === "DELETED")
        inAppNotificationsCopy.splice(index, 1);
      else inAppNotificationsCopy[index].status = updatedTransactionLog.status;
      inAppNotificationsRef.current = inAppNotificationsCopy;

      handleInAppNotifications(inAppNotificationsCopy);
    }
  };

  //Subscription for notifications updates
  useEffect(() => {
    let subscription;
    const setupSubscription = async () => {
      try {
        if (wallet === undefined || Object.entries(wallet).length === 0) return;
        subscription = await API.graphql(
          graphqlOperation(
            subscriptions.onUpdateTransactionLogByPublicSigningKey,
            {
              publicSigningKey: await wallet.signingPublicKey()
            }
          )
        ).subscribe({
          next: async ({ value }) => {
            const updatedTransactionLog =
              value.data.onUpdateTransactionLogByPublicSigningKey;
            handleNotificationUpdate(updatedTransactionLog);
          },
          error: () => {
            console.warn("err");
          }
        });
      } catch (err) {
        console.log("Subscription error: ", err);
      }
    };
    if (window.navigator.onLine && wallet) setupSubscription();
    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [wallet]);

  return (
    <Context.Provider
      value={{
        notificationsLog: inAppNotifications
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default InAppNotificationsContextProvider;

export const withInAppNotificationsContext = Component => props =>
  (
    <Context.Consumer>
      {inAppNotificationsContext => (
        <Component {...props} {...inAppNotificationsContext} />
      )}
    </Context.Consumer>
  );

export const useInAppNotificationsContext = () => useContext(Context);
