import React from "react";
import { Typography } from "@mui/material";
import OperationTitleWithIcon from "../../../components/common/OperationTitle/OperationTitleWithIcon";

function FolderCreate(props) {
  const { operation, positionedBeforeDate, color } = props;

  return (
    <div style={{ marginBottom: positionedBeforeDate ? "74px" : "16px" }}>
      <OperationTitleWithIcon actionRef={operation.actionRef} color={color} />
      <Typography
        noWrap
        variant="body2"
        color="text.secondary"
        className="small"
      >
        {operation.title}
      </Typography>
    </div>
  );
}

export default FolderCreate;
