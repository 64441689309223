import React from 'react'
import { getFolderId, getUserRole, isArchived } from '../../../helpers/helpers'
import { roomMenuOptions } from '../MoreMenu/room-menu-options'
import { useGlobalContext } from '../../../contexts/GlobalDataProvider'
import { useNotificationsContext } from '../../../contexts/NotificationsContextProvider'
import DrawerRoot from './MoreDrawerRoot'
import MoreDrawerItem from './MoreDrawerItem'
import { useLocation } from 'react-router'

function RoomDrawer({
  currentMembership,
  openDrawer,
  handleMenuClose,
  history
}) {
  const { decryptedProfileDetails } = useGlobalContext()
  const { modal, onNotificationData } = useNotificationsContext()

  const userRole = getUserRole(
    currentMembership ? currentMembership : [],
    decryptedProfileDetails
  )

  const isRoomArchived = isArchived(currentMembership)

  const location = useLocation()
  const folderId = getFolderId(location.pathname)

  if (!currentMembership) return null

  return (
    <DrawerRoot
      header={currentMembership?.dataRoom?.state?.title}
      openDrawer={openDrawer}
      handleMenuClose={handleMenuClose}
      isRoomArchived={isRoomArchived}
      currentMembership={currentMembership}
    >
      {roomMenuOptions(
        currentMembership,
        userRole,
        isRoomArchived,
        modal.onModalVisibility,
        onNotificationData,
        modal.onConfirmModalType,
        history,
        handleMenuClose,
        modal.onConfirmModalFormType,
        folderId
      ).map((menuItem, index) => {
        return (
          menuItem.show && <MoreDrawerItem key={index} menuItem={menuItem} />
        )
      })}
    </DrawerRoot>
  )
}

export default RoomDrawer
