import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  FormLabel,
  FormControl,
  Checkbox,
  FormControlLabel,
  Typography,
  FormGroup
} from "@mui/material";
import { periodListMap } from "./filterMap";
import { CheckboxTickIcon, CheckboxEmptyIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";

const useStyles = makeStyles({
  formControlLabelRoot: {
    marginBottom: "12px"
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
  radioChecked: {
    "&:hover": {
      backgroundColor: "transparent!important"
    }
  },
  label: {
    alignSelf: "flex-end"
  }
});

function FilterPeriodDesktop({ hasTrueKeys }) {
  const { darkMode } = useGlobalContext();
  const { periodFilter, onPeriodFilter } = useAssetsContext();

  const classes = useStyles({
    hasTrueKeys: hasTrueKeys,
    darkMode: darkMode
  });

  const handleChange = event => {
    if (event.target.name === periodFilter) onPeriodFilter(null);
    else onPeriodFilter(event.target.name);
  };

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend" style={{ marginBottom: "8px" }}>
        <Typography variant="body2" className="strong" color="text.primary">
          Period
        </Typography>
      </FormLabel>
      <FormGroup>
        {Object.keys(periodListMap).map((period, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                disableRipple
                icon={<CheckboxEmptyIcon fontSize="small" />}
                checkedIcon={<CheckboxTickIcon fontSize="small" />}
                size="small"
                onClick={handleChange}
                checked={period === periodFilter}
                classes={{
                  checked: classes.radioChecked
                }}
                className={classes.checkbox}
              />
            }
            label={
              <Typography
                variant="body2"
                className="small"
                style={{ lineHeight: "1rem", paddingTop: "2px" }}
              >
                {periodListMap[period]}
              </Typography>
            }
            name={period}
            classes={{ label: classes.label }}
            className={classes.formControlLabelRoot}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

export default FilterPeriodDesktop;
