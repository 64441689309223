import React from 'react'
import { useParams } from 'react-router-dom'
import AccountBackup from './AccountBackup'
import BackUpPhrase from './BackUpPhrase'
import Verify from './Verify'
import AccountCreated from './AccountCreated'
import BackupInfo from './BackupInfo'

function SignupSteps(props) {
  const { setActivateButton } = props
  let { step } = useParams()
  const renderStep = () => {
    switch (step) {
      case 'account-backup':
        return <AccountBackup />
      case 'backup-phrase':
        return <BackUpPhrase />
      case 'verification':
        return <Verify setActivateButton={setActivateButton} />
      case 'account-created':
        return <AccountCreated />
      case 'backup-info':
        return <BackupInfo />
      default:
        return
    }
  }

  return renderStep()
}

export default SignupSteps
