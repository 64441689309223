import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Typography, Box, Tooltip } from '@mui/material'
import { withWallet } from '../contexts/GlobalDataProvider'
import { grey } from '../theme/colors'
import { bytesToGb } from '../pages/storage/storage-helper'

class HorizontalBar extends Component {
  constructor(props) {
    super(props)
    //
    this.state = {
      listBars: [],
      data: this.props.data
    }
    this.onClick = this.onClick.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props, prevProps)) {
      this.setState(state => ({
        ...state,
        listBars: this.getListBarWithOtherParameters(),
        data: this.props.data
      }))
    }
  }

  componentDidMount() {
    this.setState(state => ({
      ...state,
      listBars: this.getListBarWithOtherParameters(),
      data: this.props.data
    }))
  }

  /**
   * Make the calculus of total width
   */
  calcWidthTotal() {
    let widthTotal = 0
    this.props.data.forEach(bar => {
      if (bar.value) widthTotal = widthTotal + bar.value
    })
    return widthTotal
  }

  /**
   * Returns the same list of bars with position and barWidth
   */
  getListBarWithOtherParameters() {
    const widthTotal = this.calcWidthTotal()
    let position = (this.props.outlineWidth * 2) / 100
    let barWidth = 0
    const listBars = this.props.data.map(bar => {
      if (bar.value) {
        position = position + barWidth
        barWidth =
          (bar.value * 100) / widthTotal -
          (this.props.outlineWidth * 100) / bar.value / 100
        bar = Object.assign(
          { position: position, barWidth: barWidth, widthTotal: widthTotal },
          bar
        )
        return bar
      }
    })
    return listBars.filter(bar => bar)
  }

  /**
   * OnClick Event
   */
  onClick(evt, bar) {
    Object.assign(evt, { bar: bar })
    if (this.props.onClick) {
      this.props.onClick(evt)
    }
  }

  /**
   * Returns a new calculated rgb color
   */
  randomColor() {
    const r = Math.floor(Math.random() * 255)
    const g = Math.floor(Math.random() * 255)
    const b = Math.floor(Math.random() * 255)

    return `rgb(${r}, ${g}, ${b})`
  }

  renderBars() {
    const listBars = []

    listBars.push(
      this.state.listBars.map((bar, index) => {
        return (
          <g key={index} onClick={e => this.onClick(e, bar)}>
            <Tooltip
              title={
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography
                    variant="body2"
                    className="xs strong"
                    style={{ color: '#FFF' }}
                  >
                    {bar.description}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    className="xs"
                    style={{ color: '#FFF' }}
                  >
                    {' '}
                    {bytesToGb(bar.value).size}
                    {bytesToGb(bar.value).sizeType.toUpperCase()}
                  </Typography>
                </Box>
              }
              arrow
            >
              <rect
                width={`${bar.barWidth + 0.1}%`}
                height={this.props.height}
                style={{
                  fill: bar.color || this.randomColor(),
                  strokeWidth: `${
                    (this.props.outlineWidth * 100) /
                    (bar.widthTotal / this.props.data.length)
                  }%`,
                  stroke: this.props.outlineColor
                }}
                x={`${bar.position}%`}
              />
            </Tooltip>
          </g>
        )
      })
    )
    return listBars
  }

  render() {
    const { darkMode } = this.props

    return (
      <React.Fragment>
        <svg
          id={this.props.id}
          width="100%"
          height={this.props.height}
          style={{
            borderRadius: '8px',
            background: darkMode
              ? 'linear-gradient(90deg, #222126 0%, #312F37 100%)'
              : 'linear-gradient(90deg, #FFFFFF 0%, #F7F6FA 100%)',
            border: '1px solid',
            borderColor: darkMode ? grey[700] : grey[200],
            boxSizing: 'border-box'
          }}
        >
          {this.renderBars()}
        </svg>
      </React.Fragment>
    )
  }
}
export default withWallet(HorizontalBar)

HorizontalBar.propTypes = {
  data: PropTypes.array.isRequired,
  id: PropTypes.string,
  height: PropTypes.number,
  showTextIn: PropTypes.bool,
  showTextUp: PropTypes.bool,
  showTextDown: PropTypes.bool,
  showTextWithValue: PropTypes.bool,
  showValueIn: PropTypes.bool,
  showValueUp: PropTypes.bool,
  showValueDown: PropTypes.bool,
  fontColor: PropTypes.string,
  onClick: PropTypes.func,
  outlineWidth: PropTypes.number,
  outlineColor: PropTypes.string
}

HorizontalBar.defaultProps = {
  height: 30,
  showTextIn: false,
  showTextUp: false,
  showTextDown: false,
  showTextWithValue: true,
  showValueIn: false,
  showValueUp: false,
  showValueDown: false,
  outlineWidth: 0,
  outlineColor: 'black',
  fontColor: 'white',
  id: 'hsbar'
}
