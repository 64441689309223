import React from "react";
import { Route, Switch } from "react-router-dom";
import PublicFileViewerContextProvider from "../../contexts/PublicFileViewerContextProvider";
import ThemeWrapper from "../common/Wrappers/ThemeWrapper";
import PublicGallery from "../gallery/PublicGallery";

function PublicRoutes() {
  return (
    <ThemeWrapper darkMode={true}>
      <Switch>
        <Route
          path="/public/:assetId/:assetHash"
          exact
          render={() => (
            <PublicFileViewerContextProvider>
              <PublicGallery />
            </PublicFileViewerContextProvider>
          )}
        />
      </Switch>
    </ThemeWrapper>
  );
}

export default PublicRoutes;
