import {
  ArchiveIcon,
  VaultLeaveIcon,
  TrashIcon,
  DoubleFileIcon,
  FileRestoreIcon,
  FolderRestoreIcon,
  FolderRevokeIcon,
  MinusInCircleIcon,
  MoveIcon,
  RestoreIcon,
  RevokedFileIcon,
  RevokeStackIcon,
  UploadIcon,
  CloseInCircleIcon
} from "@akord/addon-icons";
import {
  batchRevoke,
  batchMove,
  batchRemove,
  batchRestore,
  dataRoomArchive,
  dataRoomRestore,
  dataRoomDelete,
  folderDelete,
  folderMove,
  folderRestore,
  folderRevoke,
  stackDelete,
  stackMove,
  stackRemove,
  stackRestore,
  stackRevoke,
  noteRevoke,
  noteRestore,
  noteUpload,
  noteMove,
  noteRemove
} from "../../../helpers/akord-actions";
import {
  revokeInvite,
  membershipLeave
} from "../../../helpers/manage-access-actions";

export const confirmationModalOptions = {
  revoke: {
    title: () => "Revoke access",
    createText: notificationData => {
      return `You are about to revoke access to the vault for ${
        notificationData.memberToRevoke.memberDetails.fullName
          ? notificationData.memberToRevoke.memberDetails.fullName
          : notificationData.memberToRevoke.memberDetails.email
      }. Are you sure?`;
    },
    buttonText: "Revoke access",
    icon: CloseInCircleIcon,
    showCancel: true
  },
  archive: {
    title: () => "Archive vault",
    createText: memberToRevoke => {
      return `All members of the vault will be notified the vault has been archived and that no more actions can be taken. Are you sure you want to archive the "${memberToRevoke.title}" vault?`;
    },
    buttonText: "Archive vault",
    icon: ArchiveIcon,
    showCancel: true
  },
  restore: {
    title: () => "Restore vault",
    createText: memberToRevoke => {
      return `All members of the vault will be notified the vault has been restored. Are you sure you want to restore the "${memberToRevoke.title}" vault?`;
    },
    buttonText: "Restore vault",
    icon: RestoreIcon,
    showCancel: true
  },
  delete: {
    title: () => "Remove vault",
    createText: memberToRevoke => {
      return `Removing a vault will mean it is no longer accessible through the Akord app. The data in the vault can only be found on Arweave if someone has the transaction IDs.\n\nAre you sure you want to remove the "${memberToRevoke.title}" vault?`;
    },
    buttonText: "Remove vault",
    icon: MinusInCircleIcon,
    showCancel: true
  },
  revokeFile: {
    title: () => "Revoke file",
    createText: fileToRevoke => {
      return `You are about to revoke "${[
        fileToRevoke.title,
        fileToRevoke.extension
      ].join(
        "."
      )}". It will no longer be available to view or download. Are you sure?`;
    },
    buttonText: "Revoke",
    icon: RevokedFileIcon,
    showCancel: true
  },
  batchRevoke: {
    title: notificationData => {
      const valuesArray = Array.from(notificationData.batchItems.values());
      return valuesArray.includes("folder") && !valuesArray.includes("stack")
        ? "Revoke folders"
        : !valuesArray.includes("folder") && valuesArray.includes("stack")
        ? "Revoke files"
        : "Revoke items";
    },
    createText: notificationData => {
      return `You are about to revoke ${notificationData.batchItems.size} items. They will no longer be available to view or download. Are you sure?`;
    },
    buttonText: "Revoke",
    icon: RevokedFileIcon,
    showCancel: true
  },
  revokeStack: {
    title: () => "Revoke file stack",
    createText: fileToRevoke => {
      return `You are about to revoke "${[
        fileToRevoke.title,
        fileToRevoke.extension
      ].join(
        "."
      )}". It will no longer be available to view or download. Are you sure?`;
    },
    buttonText: "Revoke",
    icon: RevokeStackIcon,
    showCancel: true
  },
  restoreFile: {
    title: () => "Restore file",
    createText: fileToRevoke => {
      return `Are you sure you want to restore "${[
        fileToRevoke.title,
        fileToRevoke.extension
      ].join(".")}"?`;
    },
    buttonText: "Restore",
    icon: FileRestoreIcon,
    showCancel: true
  },
  batchRestore: {
    title: () => "Restore files",
    createText: notificationData => {
      return `Are you sure you want to restore ${notificationData.batchItems.size} files?`;
    },
    buttonText: "Restore",
    icon: FileRestoreIcon,
    showCancel: true
  },
  restoreStack: {
    title: () => "Restore file stack",
    createText: fileToRevoke => {
      return `Are you sure you want to restore "${[
        fileToRevoke.title,
        fileToRevoke.extension
      ].join(".")}"?`;
    },
    buttonText: "Restore",
    icon: RestoreIcon,
    showCancel: true
  },
  deleteFile: {
    title: () => "Delete file",
    createText: fileToRevoke => {
      return `Are you sure you want to permanently delete "${[
        fileToRevoke.title,
        fileToRevoke.extension
      ].join(".")}"?`;
    },
    buttonText: "Delete",
    icon: TrashIcon,
    showCancel: true
  },
  removeFile: {
    title: () => "Remove file",
    createText: fileToRemove => {
      return `Removing a file will mean it is no longer accessible through the Akord app. They can only be found on Arweave if someone has the transaction ID.\n\nAre you sure you want to remove "${[
        fileToRemove.title,
        fileToRemove.extension
      ].join(".")}"?`;
    },
    buttonText: "Remove",
    icon: MinusInCircleIcon,
    showCancel: true
  },
  batchRemove: {
    title: () => "Remove files",
    createText: notificationData => {
      return `Removing a file will mean it is no longer accessible through the Akord app. They can only be found on Arweave if someone has the transaction ID.\n\nAre you sure you want to remove ${notificationData.batchItems.length} files?`;
    },
    buttonText: "Remove",
    icon: MinusInCircleIcon,
    showCancel: true
  },
  restoreFileFromTimeline: {
    title: () => "Revoked file",
    createText: () =>
      "This file was revoked. To view or download the file, you have to restore the file",
    buttonText: "Restore file",
    icon: RevokedFileIcon,
    showCancel: true
  },
  deletedFileFromTimeline: {
    title: () => "Deleted file",
    createText: () =>
      "This file has been deleted. We keep a log of the original upload in the timeline, but it is no longer available to view or download.",
    buttonText: "Done",
    icon: TrashIcon,
    showCancel: false
  },
  removedFileFromTimeline: {
    title: () => "Removed file",
    createText: () =>
      "This file has been  removed. We keep a log of the original upload in the timeline, and it is still on the Arweave blockchain, but it is no longer available to view or download within Akord.",
    buttonText: "Done",
    icon: MinusInCircleIcon,
    showCancel: false
  },
  duplicateFile: {
    title: () => "File already exists",
    createText: (duplicateFiles, index) =>
      `A file named "${
        duplicateFiles[index] && duplicateFiles[index].name
      }" already exists in this vault. Do you want to append this file as a new version?`,
    buttonTextPrimary: "New version",
    buttonSecondary: "Keep both",
    icon: DoubleFileIcon,
    showCancel: false
  },
  folderRevoke: {
    title: () => "Revoke folder",
    createText: folderToRevoke => {
      return `Are you sure you want to revoke the folder "${folderToRevoke.title}" and all its contents from your vault?`;
    },
    buttonText: "Revoke",
    icon: FolderRevokeIcon,
    showCancel: true
  },
  folderMove: {
    title: () => "Move folder",
    createText: () => "Select destination:",
    buttonText: "Move",
    icon: MoveIcon,
    showCancel: true,
    modalType: "move"
  },
  fileMove: {
    title: () => "Move item",
    createText: () => "Select destination:",
    buttonText: "Move",
    icon: MoveIcon,
    showCancel: true,
    modalType: "move"
  },
  batchMove: {
    title: () => "Move items",
    createText: () => "Select destination:",
    buttonText: "Move",
    icon: MoveIcon,
    showCancel: true,
    modalType: "move"
  },
  noteMove: {
    title: () => "Move items",
    createText: () => "Select destination:",
    buttonText: "Move",
    icon: MoveIcon,
    showCancel: true,
    modalType: "move"
  },
  restoreFolderWithMove: {
    title: () => "Restore folder",
    createText: () => "Select destination:",
    buttonText: "Restore",
    icon: MoveIcon,
    showCancel: true,
    modalType: "move"
  },
  restoreFileWithMove: {
    title: () => "Restore file",
    createText: () => "Select destination:",
    buttonText: "Restore",
    icon: MoveIcon,
    showCancel: true,
    modalType: "move"
  },
  folderRestore: {
    title: () => "Restore folder",
    createText: folderToRestore => {
      return `Are you sure you want to restore "${folderToRestore.title}"?`;
    },
    buttonText: "Restore",
    icon: FolderRestoreIcon,
    showCancel: true
  },
  folderDelete: {
    title: () => "Delete folder",
    createText: folder => {
      return `Are you sure you want to permanently delete "${folder.title}"?`;
    },
    buttonText: "Delete",
    icon: TrashIcon,
    showCancel: true
  },
  confirmUpload: {
    title: notificationData =>
      notificationData.isVaultPublic
        ? "Upload public files"
        : "Upload encrypted files",
    createText: isVaultPublic =>
      isVaultPublic
        ? "You are about to upload public files to Arweave. These could be accessed by anyone. If you have any concerns, do not upload."
        : "Please check you have the correct files before uploading to the vault’s permanent storage. ",
    buttonText: "Upload",
    icon: UploadIcon,
    showCancel: true,
    modalType: "upload"
  },
  leave: {
    title: () => "Leave vault",
    createText: data => {
      return `You are about to leave the "${data.title}" vault. You will no longer be able to access the vault and its contents. Are you sure?`;
    },
    buttonText: "Leave",
    icon: VaultLeaveIcon,
    showCancel: true
  },
  revokeNote: {
    title: () => "Revoke note",
    createText: fileToRevoke => {
      return `You are about to revoke "${fileToRevoke.title}". It will no longer be available to view. Are you sure?`;
    },
    buttonText: "Revoke",
    icon: RevokedFileIcon,
    showCancel: true
  },
  restoreNote: {
    title: () => "Restore note",
    createText: fileToRevoke => {
      return `Are you sure you want to restore "${fileToRevoke.title}"?`;
    },
    buttonText: "Restore",
    icon: FileRestoreIcon,
    showCancel: true
  },
  saveNote: {
    title: () => "Save note",
    createText: () => {
      return "When you save this note it will be stored on the blockchain, using some of your available storage.";
    },
    buttonText: "Save",
    icon: UploadIcon,
    showCancel: true,
    modalType: "upload"
  },
  removeNote: {
    title: () => "Remove note",
    createText: noteToRemove => {
      return `Removing a note will mean it is no longer accessible through the Akord app. They can only be found on Arweave if someone has the transaction ID.\n\nAre you sure you want to remove "${noteToRemove.title}"?`;
    },
    buttonText: "Remove",
    icon: MinusInCircleIcon,
    showCancel: true
  }
};

export const handleModalConfirm = async (
  confirmType,
  ledgerWrapper,
  currentMembership,
  notificationData,
  onSnackbarToShow,
  onNotificationData,
  history
) => {
  switch (confirmType) {
    case "archive":
      await dataRoomArchive(ledgerWrapper, notificationData.hash);
      onSnackbarToShow("dataRoomArchive");
      break;
    case "restore":
      await dataRoomRestore(ledgerWrapper, notificationData.hash);
      onSnackbarToShow("dataRoomRestore");
      break;
    case "delete":
      await dataRoomDelete(ledgerWrapper, notificationData.hash);
      onSnackbarToShow("dataRoomDelete");
      break;
    case "leave":
      await membershipLeave(ledgerWrapper, notificationData);
      onSnackbarToShow("membershipLeave");
      break;
    case "revokeFile":
    case "revokeStack":
      await stackRevoke(ledgerWrapper, notificationData.hash);
      onSnackbarToShow("fileRevoked");
      break;
    case "batchRevoke":
      await batchRevoke(ledgerWrapper, notificationData.batchItems);
      onSnackbarToShow("batchRevoked");
      break;
    case "batchRemove":
      await batchRemove(ledgerWrapper, notificationData);
      onSnackbarToShow("batchRemove");
      break;
    case "removeFile":
      await stackRemove(ledgerWrapper, notificationData.hash);
      onSnackbarToShow("fileRemove");
      break;
    case "restoreFileFromTimeline":
      await stackRestore(ledgerWrapper, notificationData.hash);
      onSnackbarToShow("fileRestore");
      break;
    case "deleteFile":
      await stackDelete(ledgerWrapper, notificationData.hash);
      onSnackbarToShow("fileDelete");
      break;
    case "restoreFile":
    case "restoreStack":
      await stackRestore(ledgerWrapper, notificationData);
      onSnackbarToShow("fileRestore");
      break;
    case "batchRestore":
      await batchRestore(ledgerWrapper, notificationData);
      onSnackbarToShow("batchRestore");
      break;
    case "revoke":
      await revokeInvite(
        ledgerWrapper,
        notificationData.hash,
        currentMembership
      );
      onSnackbarToShow("memberRevoke");
      break;
    case "folderRevoke":
      await folderRevoke(ledgerWrapper, notificationData.hash);
      onSnackbarToShow("folderRevoke");
      break;
    case "folderRestore":
      await folderRestore(ledgerWrapper, notificationData);
      onSnackbarToShow("folderRestore");
      break;
    case "folderDelete":
      await folderDelete(ledgerWrapper, notificationData.hash);
      onSnackbarToShow("folderDelete");
      break;
    case "fileMove":
      await stackMove(ledgerWrapper, notificationData);
      onSnackbarToShow("fileMove");
      break;
    case "folderMove":
      await folderMove(ledgerWrapper, notificationData);
      onSnackbarToShow("folderMove");
      break;
    case "batchMove":
      await batchMove(ledgerWrapper, notificationData);
      onSnackbarToShow("batchMove");
      break;
    case "noteMove":
      await noteMove(ledgerWrapper, notificationData);
      onSnackbarToShow("noteMove");
      break;
    case "removeNote":
      await noteRemove(ledgerWrapper, notificationData.hash);
      onSnackbarToShow("noteRemove");
      break;
    case "restoreFolderWithMove":
      await folderRestore(ledgerWrapper, notificationData);
      onSnackbarToShow("restoreFolderWithMove");
      break;
    case "restoreFileWithMove":
      await stackRestore(ledgerWrapper, notificationData);
      onSnackbarToShow("restoreFileWithMove");
      break;
    case "duplicateFile":
      // Handle it differently needs to be revised
      // in duplicate-file-upload.js
      break;
    case "revokeNote":
      await noteRevoke(ledgerWrapper, notificationData.hash);
      onSnackbarToShow("revokeNote");
      break;
    case "restoreNote":
      await noteRestore(ledgerWrapper, notificationData.hash);
      onSnackbarToShow("restoreNote");
      break;
    case "saveNote":
      await noteUpload(
        currentMembership,
        ledgerWrapper,
        notificationData.noteText,
        notificationData.folderId,
        notificationData.hash,
        notificationData.title
      );
      onNotificationData(null);
      onSnackbarToShow(notificationData.hash ? "reviseNote" : "saveNote");
      history.push(`/vaults/active/${currentMembership.dataRoomId}/assets`);
      break;
    default:
      break;
  }
};
