import { Auth } from 'aws-amplify'

export const downloadTxtFile = (backupPhraseArray, formData) => {
  const element = document.createElement('a')
  const file = new Blob(generateText(backupPhraseArray, formData), {
    type: 'text/plain'
  })
  element.href = URL.createObjectURL(file)
  element.download = 'Akord-backup-phrase.txt'
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

const generateText = (backupPhraseArray, formData) => {
  const phrase = backupPhraseArray
    .map((word, index) => {
      const tabOrBrk = (index + 1) % 3 === 0 ? `\n` : `\t\t`
      const space = index + 1 <= 9 ? ' ' : ''
      return `${space}${index + 1}. ${word}${tabOrBrk}`
    })
    .join('')
  const text = `AKORD\n\nSAVE YOUR BACKUP PHRASE\nKeep these backup phrase somewhere safe but accessible.\n\n${phrase}\n\n(${
    formData.userEmail
  })\n\n*Phrase was generated on ${new Date()}`
  return [text]
}

export const handleResendEmail = async (formData, handleResendClick) => {
  const username = formData.userEmail
  if (!username) {
    console.log('NO EMAIL!')
    return
  }
  try {
    handleResendClick(true)
    setTimeout(() => {
      handleResendClick(false)
    }, 1000)
    const { host, protocol } = window.location
    const verifyUrl = `${protocol}//${host}/sign-in`
    await Auth.resendSignUp(username, { verifyUrl })
  } catch (err) {
    console.log('ERROR: ', err)
  }
}
