import React from "react";
import { isArchived } from "../../../helpers/helpers";
import {
  stackActiveMenuOptions,
  stackRevokedMenuOptions
} from "./stack-menu-options";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { useProgressContext } from "../../../contexts/ProgressContext";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import MoreMenuRoot from "./MoreMenuRoot";
import MoreMenuItem from "./MoreMenuItem";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";

function MenuStack({ anchorEl, handleMenuClose, openMenu, revokedStack }) {
  const { modal, onNotificationData } = useNotificationsContext();
  const { onProgress } = useProgressContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const {
    currentMembership,
    currentStack,
    userRole,
    encrypter,
    isVaultPublic
  } = useVaultContext();
  const {
    onShowLoader,
    onAxiosError,
    onShowUpload,
    onUploadStart,
    onDrawerActionType,
    decryptedFolders,
    onUploadHook
  } = useAssetsContext();

  const isRoomArchived = isArchived(currentMembership);

  const stackMenuOptions = revokedStack
    ? stackRevokedMenuOptions
    : stackActiveMenuOptions;

  if (!openMenu) return null;

  return (
    <MoreMenuRoot
      anchorEl={anchorEl}
      handleMenuClose={handleMenuClose}
      openMenu={openMenu}
    >
      <div>
        {stackMenuOptions(
          currentStack,
          encrypter,
          userRole,
          isRoomArchived,
          onShowLoader,
          onProgress,
          onSnackbarToShow,
          onAxiosError,
          onUploadStart,
          onDrawerActionType,
          onShowUpload,
          onNotificationData,
          modal.onModalVisibility,
          modal.onConfirmModalType,
          handleMenuClose,
          modal.onConfirmModalFormType,
          decryptedFolders,
          onUploadHook,
          isVaultPublic
        ).map((menuItem, index) => {
          return (
            menuItem.show && (
              <MoreMenuItem
                currentStack={currentStack}
                key={index}
                menuItem={menuItem}
                handleMenuClose={handleMenuClose}
                onDrawerActionType={onDrawerActionType}
              />
            )
          );
        })}
      </div>
    </MoreMenuRoot>
  );
}

export default MenuStack;
