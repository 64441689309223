import {
  FileRestoreIcon,
  MoveIcon,
  MinusInCircleIcon,
  NoteEditIcon,
  RevokedFileIcon,
  ViewTransactionIcon,
  LinkIcon
} from "@akord/addon-icons";
import { akordLinks } from "../../../helpers/akordTexts";

const { host, protocol } = window.location;
const baseUrl = `${protocol}//${host}`;

export const noteMenuActiveOptions = (
  history,
  handleMenuClose,
  folderId,
  roomId,
  roomStatus,
  note,
  setVisibility,
  setConfirmType,
  handleData,
  decryptedFolders,
  userRole,
  isRoomArchived,
  isVaultPublic,
  setConfirmFormType
) => [
  // {
  //   text: 'Download',
  //   altText: 'Download latest version',
  //   icon: DownloadIcon,
  //   action: () => {
  //     handleMenuClose()
  //   },
  //   disabled: false,
  //   show: true,
  //   upload: false
  // },
  {
    text: "View transaction",
    icon: ViewTransactionIcon,
    action: () => {
      const refId = note.storageTransactions?.items?.find(
        tr => tr.refHash === note.hash
      )?.refId;
      window.open(`${akordLinks.transactionBaseUrl}/${refId}`, "_blank");
      handleMenuClose();
    },
    disabled: userRole === "VIEWER" || isRoomArchived,
    show: true,
    upload: false
  },
  {
    text: "Edit note",
    icon: NoteEditIcon,
    action: () => {
      history.push(`/vaults/${roomStatus}/${roomId}/assets/note/${note.id}`);
      handleMenuClose();
    },
    disabled: userRole === "VIEWER" || isRoomArchived,
    show: true,
    upload: true
  },
  {
    text: "Revoke",
    icon: RevokedFileIcon,
    action: () => {
      setConfirmType("revokeNote");
      setVisibility(null, "revokeNote");
      handleData({
        title: note.title,
        hash: note.hash
      });
      handleMenuClose();
    },
    disabled: userRole === "VIEWER" || isRoomArchived,
    show: true,
    upload: false
  },
  {
    text: "Move",
    icon: MoveIcon,
    action: () => {
      setConfirmType("noteMove");
      setVisibility(null, "noteMove");
      handleData({
        hash: note.hash
      });
      handleMenuClose();
    },
    disabled: userRole === "VIEWER" || isRoomArchived,
    show: decryptedFolders?.length > 0,
    upload: false
  },
  {
    text: "Share",
    icon: LinkIcon,
    action: () => {
      setConfirmFormType("shareFile");
      setVisibility(null, "shareFile");
      handleData({
        title: `${baseUrl}/public/${note?.id}/${note?.hash}`
      });
      handleMenuClose();
    },
    disabled: isRoomArchived,
    show: isVaultPublic,
    upload: false
  }
];

export const noteMenuRevokedOptions = (
  history,
  handleMenuClose,
  folderId,
  roomId,
  roomStatus,
  note,
  setVisibility,
  setConfirmType,
  handleData
) => [
  {
    text: "Restore",
    icon: FileRestoreIcon,
    action: () => {
      setConfirmType("restoreNote");
      setVisibility(null, "restoreNote");
      handleData({
        title: note.title,
        hash: note.hash
      });
      handleMenuClose();
    },
    // disabled: userRole === 'VIEWER' || isRoomArchived,
    show: true,
    upload: false
  },
  {
    text: "Remove",
    icon: MinusInCircleIcon,
    action: () => {
      setConfirmType("removeNote");
      setVisibility(null, "removeNote");
      handleData({
        title: note.title,
        hash: note.hash
      });
      handleMenuClose();
    },
    // disabled: userRole === "VIEWER" || isRoomArchived,
    show: true,
    upload: false
  }
];
