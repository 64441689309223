export const transactionLogsByPublicSigningKey =
  /* GraphQL */
  `
    query TransactionLogsByPublicSigningKey(
      $publicSigningKey: String
      $height: ModelIntKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelTransactionLogFilterInput
      $limit: Int
      $nextToken: String
    ) {
      transactionLogsByPublicSigningKey(
        publicSigningKey: $publicSigningKey
        height: $height
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          height
          hash
          publicSigningKey
          modelId
          modelType
          dataRoomId
          status
          admin
          content
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;

export const usersByEmail = /* GraphQL */ `
  query UsersByEmail($emails: [String]) {
    usersByEmail(emails: $emails) {
      email
      publicSigningKey
      publicKey
    }
  }
`;
export const preInviteCheck = /* GraphQL */ `
  query PreInviteCheck($emails: [String], $dataRoomId: ID) {
    preInviteCheck(emails: $emails, dataRoomId: $dataRoomId) {
      email
      publicSigningKey
      publicKey
      membership {
        id
        hash
        prevHash
        refHash
        publicSigningKey
        postedAt
        contextVersion
        dataRoomId
        memberPublicSigningKey
        email
        status
        state {
          status
          role
          expiresOn
          memberDetails {
            publicSigningKey
            email
            fullName
            phone
            avatarUrl
          }
          termsOfAccess
          agreementHash
          message
          encryptionType
          keys {
            publicKey
            encPrivateKey
          }
          encAccessKey
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const profileByHeight = /* GraphQL */ `
  query ProfileByHeight($height: Int) {
    profileByHeight(height: $height) {
      profile {
        id
        hash
        prevHash
        refHash
        publicSigningKey
        postedAt
        contextVersion
        status
        state {
          profileDetails {
            publicSigningKey
            email
            fullName
            phone
            avatarUrl
          }
          status
          wallets {
            publicSigningKey
            encBackupPhrase
            publicKey
            revokedOn
          }
          encryptionType
          keys {
            publicKey
            encPrivateKey
          }
          encAccessKey
        }
        storage {
          publicSigningKey
          total_storage
          storage_available
          storage_used
          documents
          photos
          notes
          audio
          video
          other
          transactions {
            items {
              id
              refId
              refHash
              publicSigningKey
              dataRoomId
              stackId
              type
              status
              timestamp
              amount
            }
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      memberships {
        id
        hash
        prevHash
        refHash
        publicSigningKey
        postedAt
        contextVersion
        dataRoomId
        memberPublicSigningKey
        email
        status
        state {
          status
          role
          expiresOn
          memberDetails {
            publicSigningKey
            email
            fullName
            phone
            avatarUrl
          }
          termsOfAccess
          agreementHash
          message
          encryptionType
          keys {
            publicKey
            encPrivateKey
          }
          encAccessKey
        }
        createdAt
        updatedAt
        dataRoom {
          id
          hash
          prevHash
          refHash
          publicSigningKey
          postedAt
          contextVersion
          status
          state {
            status
            isPublic
            title
            description
            termsOfAccess
            permanentStorage
            publicKeys
          }
          createdAt
          updatedAt
          members {
            items {
              id
              hash
              prevHash
              refHash
              publicSigningKey
              postedAt
              contextVersion
              dataRoomId
              memberPublicSigningKey
              email
              status
              state {
                status
                role
                expiresOn
                memberDetails {
                  publicSigningKey
                  email
                  fullName
                  phone
                  avatarUrl
                }
                termsOfAccess
                agreementHash
                message
                encryptionType
                keys {
                  publicKey
                  encPrivateKey
                }
                encAccessKey
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          folders {
            items {
              id
              hash
              prevHash
              refHash
              publicSigningKey
              postedAt
              contextVersion
              dataRoomId
              folderId
              status
              state {
                status
                title
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          stacks {
            items {
              id
              hash
              prevHash
              refHash
              publicSigningKey
              postedAt
              contextVersion
              dataRoomId
              folderId
              status
              state {
                status
                title
                description
                resourceVersion
                size
                files {
                  title
                  resourceUrl
                  thumbnailUrl
                  postedAt
                  fileType
                  size
                  numberOfChunks
                  chunkSize
                  hash
                }
              }
              storageTransactions(sortDirection: DESC) {
                items {
                  id
                  refId
                  refHash
                  publicSigningKey
                  dataRoomId
                  stackId
                  type
                  status
                  timestamp
                  amount
                  createdAt
                  updatedAt
                }
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          memos {
            items {
              id
              hash
              prevHash
              refHash
              publicSigningKey
              postedAt
              contextVersion
              dataRoomId
              state {
                message
                reactions {
                  publicSigningKey
                  name
                  reaction
                  status
                  postedAt
                  refHash
                }
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          notes {
            items {
              id
              hash
              prevHash
              refHash
              publicSigningKey
              postedAt
              contextVersion
              dataRoomId
              folderId
              state {
                status
                title
                resourceVersion
                size
                content
                revisions {
                  title
                  postedAt
                  size
                  hash
                  content
                }
              }
              storageTransactions(sortDirection: DESC) {
                items {
                  id
                  refId
                  refHash
                  publicSigningKey
                  dataRoomId
                  stackId
                  type
                  status
                  timestamp
                  amount
                  createdAt
                  updatedAt
                }
              }
            }
            nextToken
          }
          timeline {
            items {
              id
              hash
              schemaUri
              postedAt
              publicSigningKey
              contextVersion
              prevHash
              refHash
              dataRoomId
              modelId
              memberDetails {
                publicSigningKey
                email
                fullName
                phone
                avatarUrl
              }
              actionRef
              groupRef
              encodedPrevState
              membership {
                status
                role
                expiresOn
                memberDetails {
                  publicSigningKey
                  email
                  fullName
                  phone
                  avatarUrl
                }
                termsOfAccess
                agreementHash
                message
                encryptionType
                keys {
                  publicKey
                  encPrivateKey
                }
                encAccessKey
              }
              dataroom {
                status
                title
                description
                termsOfAccess
                publicKeys
              }
              stack {
                status
                title
                description
                resourceVersion
                size
                files {
                  title
                  resourceUrl
                  thumbnailUrl
                  postedAt
                  fileType
                  size
                  numberOfChunks
                  chunkSize
                  hash
                }
              }
              folder {
                status
                title
              }
              memo {
                message
                reactions {
                  publicSigningKey
                  name
                  reaction
                  status
                  postedAt
                  refHash
                }
              }
              note {
                status
                title
                resourceVersion
                size
                content
                revisions {
                  title
                  postedAt
                  size
                  hash
                  content
                }
              }
              profile {
                profileDetails {
                  publicSigningKey
                  email
                  fullName
                  phone
                  avatarUrl
                }
                status
                wallets {
                  publicSigningKey
                  encBackupPhrase
                  publicKey
                  revokedOn
                }
                encryptionType
                keys {
                  publicKey
                  encPrivateKey
                }
                encAccessKey
              }
              status
              encodedHeader
              encodedBody
              publicKey
              signature
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      height
    }
  }
`;
export const getMembership = /* GraphQL */ `
  query GetMembership($id: ID!) {
    getMembership(id: $id) {
      id
      hash
      prevHash
      refHash
      publicSigningKey
      postedAt
      contextVersion
      dataRoomId
      memberPublicSigningKey
      email
      status
      state {
        status
        role
        expiresOn
        memberDetails {
          publicSigningKey
          email
          fullName
          phone
          avatarUrl
        }
        termsOfAccess
        agreementHash
        message
        encryptionType
        keys {
          publicKey
          encPrivateKey
        }
        encAccessKey
      }
      dataRoom {
        id
        hash
        prevHash
        refHash
        publicSigningKey
        postedAt
        contextVersion
        status
        state {
          status
          title
          description
          termsOfAccess
          permanentStorage
          publicKeys
          isPublic
        }
        members {
          items {
            id
            hash
            prevHash
            refHash
            publicSigningKey
            postedAt
            contextVersion
            dataRoomId
            memberPublicSigningKey
            email
            status
            state {
              status
              role
              expiresOn
              memberDetails {
                publicSigningKey
                email
                fullName
                phone
                avatarUrl
              }
              termsOfAccess
              agreementHash
              message
              encryptionType
              keys {
                publicKey
                encPrivateKey
              }
              encAccessKey
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        folders {
          items {
            id
            hash
            prevHash
            refHash
            publicSigningKey
            postedAt
            contextVersion
            dataRoomId
            folderId
            status
            state {
              status
              title
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        stacks {
          items {
            id
            hash
            prevHash
            refHash
            publicSigningKey
            postedAt
            contextVersion
            dataRoomId
            folderId
            status
            state {
              status
              title
              description
              resourceVersion
              size
              files {
                title
                resourceUrl
                thumbnailUrl
                postedAt
                fileType
                size
                numberOfChunks
                chunkSize
                hash
              }
            }
            storageTransactions(sortDirection: DESC) {
              items {
                id
                refId
                refHash
                publicSigningKey
                dataRoomId
                stackId
                type
                status
                timestamp
                amount
                createdAt
                updatedAt
              }
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        memos {
          items {
            id
            hash
            prevHash
            refHash
            publicSigningKey
            postedAt
            contextVersion
            dataRoomId
            state {
              message
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        notes {
          items {
            id
            hash
            prevHash
            refHash
            publicSigningKey
            postedAt
            contextVersion
            dataRoomId
            folderId
            state {
              status
              title
              resourceVersion
              size
              content
              revisions {
                title
                postedAt
                size
                hash
                content
              }
            }
            storageTransactions(sortDirection: DESC) {
              items {
                id
                refId
                refHash
                publicSigningKey
                dataRoomId
                stackId
                type
                status
                timestamp
                amount
                createdAt
                updatedAt
              }
            }
          }
          nextToken
        }
        timeline {
          items {
            id
            hash
            schemaUri
            postedAt
            publicSigningKey
            contextVersion
            prevHash
            refHash
            dataRoomId
            modelId
            memberDetails {
              publicSigningKey
              email
              fullName
              phone
              avatarUrl
            }
            actionRef
            groupRef
            encodedPrevState
            membership {
              status
              role
              expiresOn
              memberDetails {
                publicSigningKey
                email
                fullName
                phone
                avatarUrl
              }
              termsOfAccess
              agreementHash
              message
              encryptionType
              keys {
                publicKey
                encPrivateKey
              }
              encAccessKey
            }
            dataroom {
              status
              title
              description
              termsOfAccess
              publicKeys
            }
            stack {
              status
              title
              description
              resourceVersion
              size
              files {
                title
                resourceUrl
                thumbnailUrl
                postedAt
                fileType
                size
                numberOfChunks
                chunkSize
                hash
              }
            }
            folder {
              status
              title
            }
            memo {
              message
            }
            note {
              status
              title
              resourceVersion
              size
              content
              revisions {
                title
                postedAt
                size
                hash
                content
              }
            }
            profile {
              profileDetails {
                publicSigningKey
                email
                fullName
                phone
                avatarUrl
              }
              status
              wallets {
                publicSigningKey
                encBackupPhrase
                publicKey
                revokedOn
              }
              encryptionType
              keys {
                publicKey
                encPrivateKey
              }
              encAccessKey
            }
            status
            encodedHeader
            encodedBody
            publicKey
            signature
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const profilesByPublicSigningKey = /* GraphQL */ `
  query ProfilesByPublicSigningKey(
    $publicSigningKey: String
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profilesByPublicSigningKey(
      publicSigningKey: $publicSigningKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hash
        prevHash
        refHash
        publicSigningKey
        postedAt
        contextVersion
        status
        state {
          profileDetails {
            publicSigningKey
            email
            fullName
            phone
            avatarUrl
          }
          status
          wallets {
            publicSigningKey
            encBackupPhrase
            publicKey
            revokedOn
          }
        }
        storage {
          publicSigningKey
          total_storage
          storage_available
          storage_used
          documents
          photos
          notes
          audio
          video
          other
          transactions {
            items {
              id
              refId
              refHash
              publicSigningKey
              dataRoomId
              stackId
              type
              status
              timestamp
              amount
            }
          }
          createdAt
          updatedAt
        }
        memberships(filter: { status: { eq: ACCEPTED } }) {
          items {
            id
            hash
            prevHash
            refHash
            publicSigningKey
            postedAt
            contextVersion
            dataRoomId
            memberPublicSigningKey
            email
            status
            state {
              status
              role
              expiresOn
              memberDetails {
                publicSigningKey
                email
                fullName
                phone
                avatarUrl
              }
              termsOfAccess
              agreementHash
              message
              encryptionType
              keys {
                publicKey
                encPrivateKey
              }
              encAccessKey
            }
            dataRoom {
              id
              hash
              prevHash
              refHash
              publicSigningKey
              postedAt
              contextVersion
              status
              state {
                status
                isPublic
                title
                description
                termsOfAccess
                permanentStorage
                publicKeys
              }
              members {
                items {
                  id
                  hash
                  prevHash
                  refHash
                  publicSigningKey
                  postedAt
                  contextVersion
                  dataRoomId
                  memberPublicSigningKey
                  email
                  status
                  state {
                    status
                    role
                    expiresOn
                    memberDetails {
                      publicSigningKey
                      email
                      fullName
                      phone
                      avatarUrl
                    }
                    termsOfAccess
                    agreementHash
                    message
                    encryptionType
                    keys {
                      publicKey
                      encPrivateKey
                    }
                    encAccessKey
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              memos {
                items {
                  id
                  hash
                  prevHash
                  refHash
                  publicSigningKey
                  postedAt
                  contextVersion
                  dataRoomId
                  state {
                    message
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                items {
                  id
                  hash
                  schemaUri
                  postedAt
                  publicSigningKey
                  contextVersion
                  prevHash
                  refHash
                  dataRoomId
                  modelId
                  actionRef
                  groupRef
                  encodedPrevState
                  status
                  encodedHeader
                  encodedBody
                  publicKey
                  signature
                  createdAt
                  updatedAt
                }
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const profilesByPublicSigningKeyLight =
  /* GraphQL */
  `
    query ProfilesByPublicSigningKey(
      $publicSigningKey: String
      $sortDirection: ModelSortDirection
      $filter: ModelProfileFilterInput
      $limit: Int
      $nextToken: String
    ) {
      profilesByPublicSigningKey(
        publicSigningKey: $publicSigningKey
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          hash
          prevHash
          refHash
          publicSigningKey
          postedAt
          contextVersion
          status
          state {
            profileDetails {
              publicSigningKey
              email
              fullName
              phone
              avatarUrl
            }
            status
            wallets {
              publicSigningKey
              encBackupPhrase
              publicKey
              revokedOn
            }
            encryptionType
            keys {
              publicKey
              encPrivateKey
            }
            encAccessKey
          }
          storage {
            publicSigningKey
            total_storage
            storage_available
            storage_used
            documents
            photos
            notes
            audio
            video
            other
            transactions {
              items {
                id
                refId
                refHash
                publicSigningKey
                dataRoomId
                stackId
                type
                status
                timestamp
                amount
              }
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;

export const profilesByPublicSigningKeySuperLight =
  /* GraphQL */
  `
    query ProfilesByPublicSigningKey(
      $publicSigningKey: String
      $sortDirection: ModelSortDirection
      $filter: ModelProfileFilterInput
      $limit: Int
      $nextToken: String
    ) {
      profilesByPublicSigningKey(
        publicSigningKey: $publicSigningKey
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          hash
          prevHash
          refHash
          publicSigningKey
          postedAt
          contextVersion
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;

export const membershipsByMemberPublicSigningKey =
  /* GraphQL */
  `
    query MembershipsByMemberPublicSigningKey(
      $memberPublicSigningKey: String
      $sortDirection: ModelSortDirection
      $filter: ModelMembershipFilterInput
      $limit: Int
      $nextToken: String
    ) {
      membershipsByMemberPublicSigningKey(
        memberPublicSigningKey: $memberPublicSigningKey
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          hash
          prevHash
          refHash
          publicSigningKey
          postedAt
          contextVersion
          dataRoomId
          memberPublicSigningKey
          email
          status
          state {
            status
            role
            expiresOn
            memberDetails {
              publicSigningKey
              email
              fullName
              phone
              avatarUrl
            }
            termsOfAccess
            agreementHash
            message
            encryptionType
            keys {
              publicKey
              encPrivateKey
            }
            encAccessKey
          }
          dataRoom {
            id
            hash
            prevHash
            refHash
            publicSigningKey
            postedAt
            contextVersion
            status
            createdAt
            updatedAt
            state {
              status
              isPublic
              title
              description
              termsOfAccess
              permanentStorage
              publicKeys
            }
            members {
              items {
                id
                hash
                prevHash
                refHash
                publicSigningKey
                postedAt
                contextVersion
                dataRoomId
                memberPublicSigningKey
                email
                status
                state {
                  status
                  role
                  expiresOn
                  memberDetails {
                    publicSigningKey
                    email
                    fullName
                    phone
                    avatarUrl
                  }
                  termsOfAccess
                  agreementHash
                  message
                  encryptionType
                  keys {
                    publicKey
                    encPrivateKey
                  }
                  encAccessKey
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            stacks {
              items {
                id
                hash
                prevHash
                refHash
                publicSigningKey
                postedAt
                contextVersion
                dataRoomId
                folderId
                status
                state {
                  title
                  description
                  size
                  files {
                    title
                  }
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            folders {
              items {
                id
                hash
                prevHash
                refHash
                publicSigningKey
                postedAt
                contextVersion
                dataRoomId
                folderId
                status
                state {
                  status
                  title
                }
                createdAt
                updatedAt
                folders {
                  nextToken
                }
                stacks {
                  nextToken
                }
              }
              nextToken
            }
            memos {
              items {
                id
                hash
                prevHash
                refHash
                publicSigningKey
                postedAt
                contextVersion
                dataRoomId
                state {
                  message
                  reactions {
                    publicSigningKey
                    name
                    reaction
                    status
                    postedAt
                    refHash
                  }
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            notes {
              items {
                id
                hash
                prevHash
                refHash
                publicSigningKey
                postedAt
                contextVersion
                dataRoomId
                folderId
                state {
                  title
                  content
                  size
                  revisions {
                    title
                    content
                  }
                }
              }
              nextToken
            }
            timeline {
              items {
                id
                hash
                schemaUri
                postedAt
                publicSigningKey
                contextVersion
                prevHash
                refHash
                dataRoomId
                modelId
                actionRef
                groupRef
                encodedPrevState
                status
                encodedHeader
                encodedBody
                publicKey
                signature
                createdAt
                updatedAt
                note {
                  status
                  title
                  resourceVersion
                  size
                  content
                  revisions {
                    title
                    postedAt
                    size
                    hash
                    content
                  }
                }
                stack {
                  status
                  title
                  description
                  resourceVersion
                  size
                  files {
                    title
                    resourceUrl
                    thumbnailUrl
                    postedAt
                    fileType
                    size
                    numberOfChunks
                    chunkSize
                    hash
                  }
                }
                membership {
                  status
                  role
                  expiresOn
                  memberDetails {
                    publicSigningKey
                    email
                    fullName
                    phone
                    avatarUrl
                  }
                  termsOfAccess
                  agreementHash
                  message
                  encryptionType
                  keys {
                    publicKey
                    encPrivateKey
                  }
                  encAccessKey
                }
              }
              nextToken
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;

export const membershipsByEmail =
  /* GraphQL */
  `
    query MembershipsByEmail(
      $email: String
      $sortDirection: ModelSortDirection
      $filter: ModelMembershipFilterInput
      $limit: Int
      $nextToken: String
    ) {
      membershipsByEmail(
        email: $email
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          hash
          prevHash
          refHash
          publicSigningKey
          postedAt
          contextVersion
          dataRoomId
          memberPublicSigningKey
          email
          status
          state {
            status
            role
            expiresOn
            memberDetails {
              publicSigningKey
              email
              fullName
              phone
              avatarUrl
              avatarTx
            }
            termsOfAccess
            agreementHash
            message
            encryptionType
            keys {
              publicKey
              encPrivateKey
            }
            encAccessKey
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;
