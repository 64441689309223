import React from "react";
import { Typography } from "@mui/material";
import SelectMenu from "../../../components/common/SelectMenu";
import SelectMenuForAccess from "./SelectMenuForAccess";
import {
  memberRoleEnum,
  memberStatusEnum
} from "../../../helpers/akord-enums.js";

export const manageAccessStatusCase = (
  member,
  memberRole,
  userRole,
  index,
  handleFormChange,
  isRoomArchived
) => {
  const memberFromState = memberRole.filter(
    memberState =>
      memberState?.state?.memberDetails?.email === member?.memberDetails?.email
  )[0];

  if (!isRoomArchived)
    switch (true) {
      case member.status === memberStatusEnum.PENDING:
        return (
          <SelectMenuForAccess
            member={memberFromState && memberFromState.state}
            memberHash={memberFromState && memberFromState.hash}
            menuType="Pending"
          />
        );
      case member.status === memberStatusEnum.INVITED:
        return (
          <SelectMenuForAccess
            member={memberFromState && memberFromState.state}
            memberHash={memberFromState && memberFromState.hash}
            menuType="Invited"
          />
        );
      case member.status === memberStatusEnum.REVOKED:
        return (
          <SelectMenuForAccess
            member={memberFromState && memberFromState.state}
            memberHash={memberFromState && memberFromState.hash}
            menuType="Revoked"
          />
        );
      case member.status === "expired":
        return (
          <Typography variant="caption" color="text.secondary">
            Expired
          </Typography>
        );
      case member.status === memberStatusEnum.REJECTED:
        return (
          <SelectMenuForAccess
            member={memberFromState && memberFromState.state}
            memberHash={memberFromState && memberFromState.hash}
            menuType="Rejected"
          />
        );
      //don't show select menu if a user is not a vault owner
      case userRole !== memberRoleEnum.OWNER &&
        member.role !== memberRoleEnum.OWNER:
        switch (true) {
          case member.role === memberRoleEnum.CONTRIBUTOR:
            return (
              <Typography variant="caption" color="text.secondary">
                Contributor
              </Typography>
            );
          case member.role === memberRoleEnum.VIEWER:
            return (
              <Typography variant="caption" color="text.secondary">
                View only
              </Typography>
            );
          default:
            return null;
        }

      default:
        return (
          memberFromState && (
            <SelectMenu
              member={memberFromState.state}
              outlined={false}
              handleFormChange={handleFormChange}
              index={index}
            />
          )
        );
    }
  else
    switch (true) {
      case member.role === memberRoleEnum.CONTRIBUTOR &&
        member.status === memberStatusEnum.ACCEPTED:
        return (
          <Typography variant="caption" color="text.secondary">
            Contributor
          </Typography>
        );
      case member.role === memberRoleEnum.VIEWER &&
        member.status === memberStatusEnum.ACCEPTED:
        return (
          <Typography variant="caption" color="text.secondary">
            View only
          </Typography>
        );
      case member.status === memberStatusEnum.PENDING:
        return (
          <Typography variant="caption" color="text.secondary">
            Pending
          </Typography>
        );
      case member.status === memberStatusEnum.INVITED:
        return (
          <Typography variant="caption" color="text.secondary">
            Invited
          </Typography>
        );

      default:
        return null;
    }
};
