export const publicAssetById =
  /* GraphQL */
  `
    query GetPublicAssetkById($id: String) {
      assetById(id: $id) {
        status
        refHash
        publicSigningKey
        prevHash
        postedAt
        id
        hash
        folderId
        dataRoomId
        contextVersion
        type
        state {
          description
          revisions {
            chunkSize
            chunks
            content
            fileType
            hash
            numberOfChunks
            postedAt
            resourceTx
            resourceUrl
            size
            thumbnailTx
            thumbnailUrl
            title
          }
          resourceVersion
          size
          status
          title
        }
        storageTransactions {
          refHash
          refId
        }
      }
    }
  `;
