import React from "react";
import Button from "@mui/material/Button";
import { withVaultContext } from "../../contexts/VaultContextProvider";
import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";

const UploadButton = props => {
  const { children, fab = false, disabled = false, noMinWidth } = props;
  const { uploadEventRef, onUploadFiles } = useNotificationsContext();
  const { isMobile } = useGlobalContext();
  // const [uploadedFiles, setUploadedFiles] = React.useState([])
  //hack to let upload same file after cancel https://stackoverflow.com/questions/4109276/how-to-detect-input-type-file-change-for-the-same-file
  // let inputEvent = React.useRef()

  const handleUpload = e => {
    const files = e.target.files;
    onUploadFiles(files);
    uploadEventRef.current = e;
  };

  // const clearValue = () => {
  //   inputEvent.current.target.value = null
  // }
  // const onUploadFiles = value => setUploadedFiles(value)

  return (
    <>
      <input
        name="contained-button-file"
        id="contained-button-file"
        // capture="environment"
        multiple
        type="file"
        onInput={handleUpload}
        onClick={event => {
          event.target.value = null;
        }}
        style={{ display: "none" }}
      />
      {!fab && (
        <label
          htmlFor={!disabled ? "contained-button-file" : "non-clickable"}
          style={{ width: isMobile ? "100%" : "auto" }}
        >
          <Button
            style={{ minWidth: noMinWidth ? "auto" : "228" }}
            disabled={disabled}
            variant="contained"
            color="primary"
            component="span"
            fullWidth={isMobile ? true : false}
            disableElevation
          >
            {children}
          </Button>
        </label>
      )}
    </>
  );
};

export default withVaultContext(UploadButton);
