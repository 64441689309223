import React from "react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import akordTheme from "../../../theme/mui";

function ThemeWrapper({ children, darkMode }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={akordTheme(darkMode)}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}

export default ThemeWrapper;
