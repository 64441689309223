import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import AssetsContextProvider from "../../contexts/AssetsContextProvider";
import InAppNotificationsContextProvider from "../../contexts/InAppNotificationsContext";
import NotificationsContextProvider from "../../contexts/NotificationsContextProvider";
import ProgressContextProvider from "../../contexts/ProgressContext";
import SearchContextProvider from "../../contexts/SearchContextProvider";
import VaultContextProvider from "../../contexts/VaultContextProvider";
import StorageContextProvider from "../../contexts/StorageContextProvider";
import Notifications from "../../pages/notifications";
import CreateVault from "../../pages/room-options/CreateVault";
import ShowSearch from "../../pages/search/ShowSearch";
import ShowSearchResults from "../../pages/search/ShowSearchResults";
import Settings from "../../pages/settings";
import SettingsDisplay from "../../pages/settings/Display.jsx";
import SettingsNotifications from "../../pages/settings/Notifications.jsx";
import SettingsSecurity from "../../pages/settings/Security.jsx";
import SecurityBackup from "../../pages/settings/SecurityBackup.jsx";
import SecurityChangePassword from "../../pages/settings/SecurityChangePassword.jsx";
import Storage from "../../pages/storage";
import TopUp from "../../pages/topup";
import { SafeWrapper } from "../common/";
import NotificationsWrapper from "../common/Wrappers/NotificationsWrapper";
import RoutesRooms from "./RoutesRooms";
import ChatContextProvider from "../../contexts/ChatContextProvider";
import CreateVaultForm from "../../pages/room-options/CreateVaultForm";

function Home(props) {
  return (
    <NotificationsContextProvider>
      <VaultContextProvider>
        <ChatContextProvider>
          <AssetsContextProvider>
            <SearchContextProvider>
              <ProgressContextProvider>
                <InAppNotificationsContextProvider>
                  <StorageContextProvider>
                    <SafeWrapper>
                      <NotificationsWrapper>
                        <Switch>
                          <Route
                            path="/vaults"
                            render={() => <RoutesRooms />}
                          />
                          <Route path="/search" render={() => <ShowSearch />} />
                          <Route
                            path="/search-results"
                            render={() => <ShowSearchResults />}
                          />
                          <Route
                            path="/create-vault"
                            exact
                            render={() => <CreateVault />}
                          />
                          <Route
                            path="/create-vault/encrypted"
                            exact
                            render={() => <CreateVaultForm />}
                          />
                          <Route
                            path="/create-vault/public"
                            exact
                            render={() => <CreateVaultForm isPublic />}
                          />
                          <Route
                            path="/storage"
                            exact
                            render={() => <Storage />}
                          />
                          <Route
                            path="/top-up"
                            exact
                            render={() => <TopUp />}
                          />
                          <Route
                            path="/notifications"
                            exact
                            render={() => <Notifications />}
                          />
                          <Route
                            path="/settings"
                            exact
                            render={() => <Settings />}
                          />
                          <Route
                            path="/settings/notifications"
                            exact
                            render={() => <SettingsNotifications {...props} />}
                          />
                          <Route
                            path="/settings/display"
                            exact
                            render={() => <SettingsDisplay {...props} />}
                          />
                          <Route
                            path="/settings/security"
                            exact
                            render={() => <SettingsSecurity {...props} />}
                          />
                          <Route
                            path="/settings/security/backup-phrase"
                            exact
                            render={() => <SecurityBackup {...props} />}
                          />
                          <Route
                            path="/settings/security/change-password"
                            exact
                            render={() => <SecurityChangePassword {...props} />}
                          />
                          {/* If no route found redirect */}
                          <Route
                            path="/"
                            exact
                            render={() => <Redirect to="/vaults/active" />}
                          />
                          <Redirect to="/404" />
                        </Switch>
                      </NotificationsWrapper>
                    </SafeWrapper>
                  </StorageContextProvider>
                </InAppNotificationsContextProvider>
              </ProgressContextProvider>
            </SearchContextProvider>
          </AssetsContextProvider>
        </ChatContextProvider>
      </VaultContextProvider>
    </NotificationsContextProvider>
  );
}

export default withRouter(Home);
