import { Box, Button, Typography, Link } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import HSBar from "../../storage-bar/index";
import TransactionsTable from "./TransactionsTable";
import { bytesToGb, prepareDataForBar } from "./storage-helper";
import { useStorageContext } from "../../contexts/StorageContextProvider";
import { storageTransactionsByPublicSigningKey } from "../../graphql/queries/storage";
import { nextTokenQuery } from "../../graphql/queries/utils";
import Label from "../../components/common/Label";

function Storage() {
  const { isMobile, wallet } = useGlobalContext();
  const {
    globalStorage,
    transactionNextToken,
    transactions,
    onTransactions,
    onTransactionNextToken
  } = useStorageContext();

  const history = useHistory();

  if (!globalStorage) return null;

  const handleLoadMore = async () => {
    const results = await nextTokenQuery(
      "storageTransactionsByPublicSigningKey",
      storageTransactionsByPublicSigningKey,
      {
        publicSigningKey: await wallet.signingPublicKey(),
        limit: 20,
        sortDirection: "DESC",
        nextToken: transactionNextToken
      }
    );

    const transactionsCopy = [...transactions];
    const updatedTransactions = transactionsCopy?.concat(results.items);
    onTransactions(updatedTransactions);
    onTransactionNextToken(results.nextToken);
  };

  return (
    <Box m={4}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h2" component="span">
            Storage:{" "}
          </Typography>
          <Typography variant="h2" component="span" className="regular">
            {bytesToGb(globalStorage?.total_storage).size}{" "}
          </Typography>
          <Typography
            variant="h2"
            component="span"
            className="regular"
            style={{ fontVariant: "small-caps" }}
          >
            {bytesToGb(globalStorage?.total_storage).sizeType}
          </Typography>
        </Box>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => history.push("/top-up")}
        >
          Top up
        </Button>
      </Box>
      <Box mt={3} display="flex" flexDirection={isMobile ? "column" : "row"}>
        <Box display="flex" alignItems="center" mr={5}>
          <Typography variant="h1" className="xl">
            {bytesToGb(globalStorage?.storage_available).size}{" "}
          </Typography>
          <Typography variant="h1" className="xl regular">
            {bytesToGb(globalStorage?.storage_available).sizeType}
          </Typography>
          <Label text="available" color="text.primary" />
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="h1" className="xl" color="text.secondary">
            {
              bytesToGb(
                globalStorage?.storage_used > 0
                  ? globalStorage?.storage_used
                  : 0
              ).size
            }{" "}
          </Typography>
          <Typography
            variant="h1"
            className="xl regular"
            color="text.secondary"
          >
            {
              bytesToGb(
                globalStorage?.storage_used > 0
                  ? globalStorage?.storage_used
                  : 0
              ).sizeType
            }
          </Typography>
          <Label text="used" secondary />
        </Box>
      </Box>
      <Box mt={7}>
        <Typography variant="h3">Media</Typography>
        <Box display="flex" mt={3}>
          {prepareDataForBar(globalStorage)?.map(
            (item, index) =>
              index < prepareDataForBar(globalStorage)?.length - 1 && (
                <Box key={index} display="flex" alignItems="baseline" mr={4}>
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                      backgroundColor: item.color,
                      marginRight: 4
                    }}
                  />
                  <Typography
                    variant="caption"
                    className="small"
                    color="text.secondary"
                  >
                    {item.description}
                  </Typography>
                </Box>
              )
          )}
        </Box>
      </Box>
      <Box mt={3}>
        <HSBar
          id="storageBar"
          height={48}
          data={prepareDataForBar(globalStorage)}
        />
      </Box>
      <Box mt={7}>
        <TransactionsTable />
      </Box>
      <Box pt={5} pb={isMobile ? 10 : 5} display="flex" justifyContent="center">
        {transactionNextToken && (
          <Typography variant="body2" className="small">
            <Link
              onClick={() => handleLoadMore()}
              underline="always"
              color="text.secondary"
              // color="initial"
            >
              Load more transactions...
            </Link>
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default Storage;
