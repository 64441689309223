import React from "react";
import { Box, Checkbox, IconButton, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  CheckboxEmptyIcon,
  CheckboxTickIcon,
  DownloadIcon,
  MoveIcon,
  CloseInCircleIcon,
  MinusInCircleIcon,
  FileRestoreIcon
} from "@akord/addon-icons";
import { useNotificationsContext } from "../../contexts/NotificationsContextProvider";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { batchDownload } from "../../helpers/akord-actions";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { grey } from "../../theme/colors";
import SelectAll from "./SelectAll";
import AssetsPagination from "./Pagination/AssetsPagination";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";

const useStyles = makeStyles(theme => ({
  batchContainer: {
    borderBottom: `1px solid`,
    borderTop: ({ revoked }) => revoked && `1px solid`,
    borderBottomColor: ({ darkMode }) => (darkMode ? grey[800] : grey[200]),
    borderTopColor: ({ darkMode }) => (darkMode ? grey[800] : grey[200])
  },
  batchContainerBackground: {
    backgroundColor: ({ darkMode }) =>
      darkMode ? [grey[800], "30"].join("") : [grey[100], "30"].join("")
  },
  iconCheck: {
    marginRight: theme.spacing(7),
    padding: 0
  },
  icons: {
    marginRight: theme.spacing(6)
  },
  disabledButton: {
    "&.MuiButtonBase-root": {
      "&.Mui-disabled": {
        cursor: "not-allowed",
        pointerEvents: "auto"
      }
    }
  }
}));

const activeToolbar = (
  classes,
  selectedItemsMap,
  folderScopeStacks,
  folderId,
  handleClick
) => {
  return (
    <>
      <Box className={classes.icons}>
        <Tooltip title="Download" arrow>
          <span>
            <IconButton
              onClick={() => handleClick("download")}
              disabled={selectedItemsMap.size === 0}
              className={classes.disabledButton}
            >
              <DownloadIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <Box className={classes.icons}>
        <Tooltip title="Move" arrow>
          <span>
            <IconButton
              onClick={() => handleClick("move")}
              disabled={
                selectedItemsMap.size === 0 ||
                (selectedItemsMap.size === folderScopeStacks.length &&
                  !folderId)
                // disable if we are in the root and all items are selected - nowhere to move
              }
              className={classes.disabledButton}
            >
              <MoveIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip title="Revoke" arrow>
          <span>
            <IconButton
              onClick={() => handleClick("revoke")}
              disabled={selectedItemsMap.size === 0}
              className={classes.disabledButton}
            >
              <CloseInCircleIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </>
  );
};

const revokedToolbar = (classes, itemsChecked, handleClick) => {
  return (
    <>
      <Box className={classes.icons}>
        <Tooltip title="Restore" arrow>
          <span>
            <IconButton
              onClick={() => handleClick("restore")}
              disabled={itemsChecked.size === 0}
            >
              <FileRestoreIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip title="Remove" arrow>
          <span>
            <IconButton
              onClick={() => handleClick("remove")}
              disabled={itemsChecked.size === 0}
            >
              <MinusInCircleIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </>
  );
};

function BatchActionsBar({
  currentMembershipTitle,
  folderId,
  revoked = false
}) {
  const { darkMode, onTxSpinner, isMobile } = useGlobalContext();
  const { modal, onNotificationData } = useNotificationsContext();
  const { encrypter } = useVaultContext();

  const {
    onSelectedItems,
    selectedItemsMap,
    filteredSortedActiveAssets,
    decryptedFolders,
    decryptedStacks,
    decryptedNotes,
    getPaginatedData
  } = useAssetsContext();

  const classes = useStyles({ darkMode: darkMode, revoked: revoked });

  const handleChange = event => {
    onSelectedItems(event.target.name, event.target.checked, revoked);
  };

  const handleClick = async name => {
    switch (name) {
      case "move":
        modal.onConfirmModalType("batchMove");
        modal.onModalVisibility(null, "batchMove");
        onNotificationData({ batchItems: new Map(selectedItemsMap) });
        // onSelectedItems('allItems', false) // need to clear the state
        break;
      case "revoke":
        modal.onConfirmModalType("batchRevoke");
        modal.onModalVisibility(null, "batchRevoke");
        onNotificationData({ batchItems: new Map(selectedItemsMap) });
        // onSelectedItems('allItems', false) // need to clear the state
        break;
      case "download":
        onTxSpinner(true);
        await batchDownload(
          new Map(selectedItemsMap),
          decryptedStacks,
          decryptedFolders,
          currentMembershipTitle,
          encrypter
        );
        onSelectedItems("reset");
        onTxSpinner(false);
        // onSelectedItems('allItems', false) // need to clear the state
        break;
      case "restore":
        modal.onConfirmModalType("batchRestore");
        modal.onModalVisibility(null, "batchRestore");
        onNotificationData({
          batchItems: new Map(selectedItemsMap),
          folderId: folderId
        });
        // onSelectedItems('allItems', false) // need to clear the state
        break;
      case "remove":
        modal.onConfirmModalType("batchRemove");
        modal.onModalVisibility(null, "batchRemove");
        onNotificationData({
          batchItems: new Map(selectedItemsMap)
        });
        // onSelectedItems('allItems', false) // need to clear the state
        break;

      default:
        break;
    }
  };

  // Need to select only files in a current folder
  const folderScopeStacks = filteredSortedActiveAssets.filter(
    asset => asset.folderId === folderId
  );

  const revokedScopeAssets = [
    ...decryptedStacks,
    ...decryptedFolders,
    ...decryptedNotes
  ].filter(item => item.status === "REVOKED");

  const includesAllItemsOnPage = () => {
    return getPaginatedData().every(item => selectedItemsMap.has(item.hash));
  };

  const allItemsInVault = revoked
    ? revokedScopeAssets
    : filteredSortedActiveAssets;

  return (
    <Box
      height={isMobile && selectedItemsMap.size > 0 ? "88px" : "48px"}
      className={classes.batchContainer}
      mb={revoked ? 3 : 0}
    >
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        className={classes.batchContainerBackground}
      >
        <Box
          display="flex"
          height="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center" ml={6}>
            <Tooltip title="Select all" arrow>
              <Checkbox
                onChange={handleChange}
                name="allItemsOnPage"
                icon={<CheckboxEmptyIcon />}
                checkedIcon={<CheckboxTickIcon />}
                disableRipple
                className={classes.iconCheck}
                checked={
                  revoked
                    ? selectedItemsMap.size === revokedScopeAssets.length &&
                      selectedItemsMap.size > 0
                    : includesAllItemsOnPage() && selectedItemsMap.size > 0
                }
              />
            </Tooltip>
            {revoked
              ? revokedToolbar(classes, selectedItemsMap, handleClick)
              : activeToolbar(
                  classes,
                  selectedItemsMap,
                  folderScopeStacks,
                  folderId,
                  handleClick
                )}
          </Box>
          {!isMobile && selectedItemsMap.size > 0 && (
            <SelectAll revoked={revoked} allItemsInVault={allItemsInVault} />
          )}
          {revoked ? <span /> : <AssetsPagination />}
        </Box>
        {isMobile && selectedItemsMap.size > 0 && (
          <Box
            height="100%"
            alignItems="center"
            display="flex"
            alignSelf="center"
          >
            <SelectAll revoked={revoked} allItemsInVault={allItemsInVault} />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default BatchActionsBar;
