import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow
} from "@mui/material";
import { DataRoomFilter, EmptySpace } from "../../components/common/";
import RoomItem from "./RoomItem";
import * as helpers from "../../helpers/helpers";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";

function TabActiveRooms({ roomRef }) {
  const {
    decryptedMemberships,
    invitedMemberships,
    isMobile,
    isLoaded,
    isRoomsArchived
  } = useGlobalContext();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("title");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const roomsDetails = helpers.roomsDetailsForSorting(decryptedMemberships);

  return (
    <div
      ref={roomRef}
      style={{
        width: "100%",
        height: isMobile ? "calc(100% - 59px)" : "100%",
        overflowY: "auto",
        position: "absolute",
        paddingTop: "24px"
      }}
    >
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          {!isRoomsArchived && (
            <DataRoomFilter
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              data={decryptedMemberships}
            />
          )}
          <TableBody>
            {invitedMemberships?.length &&
              invitedMemberships.map((membership, index) => (
                <RoomItem key={index} membership={membership} />
              ))}
            {isLoaded && roomsDetails?.length && !isRoomsArchived ? (
              helpers
                .stableSort(roomsDetails, helpers.getComparator(order, orderBy))
                .filter(room => room.status === "ACTIVE")
                .map((roomDetails, index) => (
                  <RoomItem
                    key={index}
                    membership={
                      decryptedMemberships.filter(
                        membership =>
                          membership.dataRoomId === roomDetails.dataRoomId
                      )[0]
                    }
                  />
                ))
            ) : roomsDetails && isRoomsArchived ? (
              <TableRow>
                <TableCell style={{ border: "none" }}>
                  <EmptySpace dataText="archivedRooms" />
                </TableCell>
              </TableRow>
            ) : !invitedMemberships?.length ? (
              Array.from(new Array(8)).map((item, index) => (
                <RoomItem key={index} />
              ))
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
      {invitedMemberships?.length && !decryptedMemberships?.length && (
        <EmptySpace dataText="pendingVault" />
      )}
    </div>
  );
}

export default TabActiveRooms;
