import API, { graphqlOperation } from "@aws-amplify/api";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Auth, Hub } from "aws-amplify";
import { useLiveQuery } from "dexie-react-hooks";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { AkordWallet } from "@akord/crypto";
import { EncrypterFactory } from "@akord/crypto";
import { fromMembership } from "@akord/crypto";
import LedgerWrapper from "../crypto/ledger-wrapper";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import * as subscriptions from "../graphql/subscriptions";
import { checkDB, db, updateDB } from "../helpers/db";
import { decryptMemberships, decryptSelf } from "../helpers/decrypt-helper";
import { paginatedQuery } from "../graphql/queries/utils";
import { getDataRoomId } from "../helpers/helpers";

const Context = createContext();

const GlobalDataProvider = ({ children }) => {
  const location = useLocation();
  const history = useHistory();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const handleIsAuthenticated = value => setIsAuthenticated(value);

  const [isAuthLoaded, setIsAuthLoaded] = useState(false);
  const handleIsAuthLoaded = value => setIsAuthLoaded(value);

  const [isDarkMode, setIsDarkMode] = useState(false);
  const handleIsDarkMode = value => setIsDarkMode(value);

  const [isMembershipsLoaded, setIsMembershipsLoaded] = useState(false);
  const handleIsMembershipsLoaded = value => setIsMembershipsLoaded(value);

  const [isProfileLoaded, setIsProfileLoaded] = useState(false);
  const handleIsProfileLoaded = value => setIsProfileLoaded(value);

  const [isLoaded, setIsLoaded] = useState(false);
  const handleIsLoaded = value => setIsLoaded(value);

  const [decryptedProfileDetails, setDecryptedProfileDetails] = useState();
  const handleDecryptedProfileDetails = value =>
    setDecryptedProfileDetails(value);

  const [transactionLog, setTransactionLog] = useState();
  const handleTransactionLog = value => setTransactionLog(value);

  const [userColorMode, setUserColorMode] = useState("auto");
  const handleUserColorMode = mode => setUserColorMode(mode);

  const [userAttributes, setUserAttributes] = useState({});
  const handleUserAttributes = attribute => {
    setUserAttributes({ ...userAttributes, ...attribute });
  };

  const [error, setError] = useState();
  const handleError = error => {
    setError(error);
  };

  const [roomsMenuOpen, setRoomsMenuOpen] = useState(
    location && location.pathname && !!location.pathname.match("/vaults")
  );

  const [wallet, setWallet] = useState();
  const handleWallet = wallet => setWallet(wallet);

  const [invitedMemberships, setInvitedMemberships] = useState();
  const handleInvitedMemberships = memberships =>
    setInvitedMemberships(memberships);

  const [assetsUpdatedHeight, setAssetsUpdatedHeight] = useState(null);
  const handleAssetsUpdatedHeight = height => setAssetsUpdatedHeight(height);

  const [screenWidth, setScreenWidth] = useState();
  const [height, setHeight] = useState(
    parseInt(localStorage.getItem("height"))
  );

  const [txSpinner, setTxSpinner] = useState(false);
  const handleTxSpinner = mode => setTxSpinner(mode);

  const [decrptSpinner, setDecrptSpinner] = useState(false);
  const handleDecrptSpinner = mode => setDecrptSpinner(mode);

  const dataRoomId = getDataRoomId(location.pathname);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const xs = useMediaQuery("(max-width:668px)");
  const sm = useMediaQuery("(max-width:960px)");
  const md = useMediaQuery("(max-width:1280px)");
  const lg = useMediaQuery("(max-width:1920px)");
  const xl = useMediaQuery("(min-width:1920px)");

  const decryptedMemberships = useLiveQuery(async () => {
    try {
      //Check if indexDB is supported
      if (!wallet || !height) return;

      const isDBavailable = await checkDB();
      if (!isDBavailable) {
        history.push("/418");
        return;
      }
      const dbStatus = await db.status.toArray();
      const isDbLoaded = dbStatus[0]?.isLoaded; // If indexDB was updated proceed to dycryption
      if (!wallet || height === 0 || !isDbLoaded) return null;

      const memberships = await db.memberships.toArray();

      const decryptedMembershipsFromDB = await decryptMemberships(
        wallet,
        memberships
      );

      handleIsMembershipsLoaded(true);

      // If a user is in a vault view StackContext is not firing, we need to reset here
      if (!dataRoomId && txSpinner) handleTxSpinner(false);

      return decryptedMembershipsFromDB;
    } catch (err) {
      console.log("Error useLiveQuery", err);
    }
  }, [wallet, height]);

  const onMembershipByDataRoomId = roomId => {
    if (isMembershipsLoaded && Array.isArray(decryptedMemberships)) {
      return decryptedMemberships.find(
        membership => membership.dataRoomId === roomId
      );
    }
    return null;
  };

  const onLedgerWrapperFromMembership = membership => {
    if (wallet === undefined || Object.entries(wallet).length === 0)
      return null;

    const encryptionKeys = fromMembership(membership);
    return new LedgerWrapper(wallet, encryptionKeys);
  };

  const onEncrypterFromMembership = membership => {
    if (wallet === undefined || Object.entries(wallet).length === 0)
      return null;

    const encryptionKeys = fromMembership(membership);
    return new EncrypterFactory(wallet, encryptionKeys).encrypterInstance();
  };

  const decryptedFullProfile = useLiveQuery(async () => {
    if (!wallet || height === 0) return null;
    const fullProfile = await db.profile.toArray(array => array[0] || null);
    try {
      const decryptedSelf = await decryptSelf(wallet, fullProfile);
      handleDecryptedProfileDetails(decryptedSelf);
      if (fullProfile) fullProfile.state.profileDetails = decryptedSelf;
    } catch (err) {
      console.log(err);
    }
    return fullProfile;
  }, [wallet, height]);

  useEffect(() => {
    if (decryptedProfileDetails) {
      handleIsProfileLoaded(true);
    }
  }, [decryptedProfileDetails]);

  const fetchProfileByHeight = async (incomingHeight = 0) => {
    const localHeight = localStorage.getItem("height");
    const user = await Auth.currentAuthenticatedUser();

    let newHeight, userData;
    if (!localHeight) {
      // get the complete user data graph
      newHeight = Math.round(new Date().valueOf() / 1000);
      // fetch user's profile
      const profiles = await paginatedQuery(
        "profilesByPublicSigningKey",
        queries.profilesByPublicSigningKeyLight,
        { publicSigningKey: user.attributes["custom:publicSigningKey"] }
      );

      //fetch memberships where a user was invited before creating an account
      const invitedMemberships = await paginatedQuery(
        "membershipsByEmail",
        queries.membershipsByEmail,
        { email: user.attributes["email"] },
        { status: { eq: "INVITED" } }
      );

      if (invitedMemberships.length > 0)
        handleInvitedMemberships(invitedMemberships);

      // fetch user's memberships
      const memberships = await paginatedQuery(
        "membershipsByMemberPublicSigningKey",
        queries.membershipsByMemberPublicSigningKey,
        { memberPublicSigningKey: user.attributes["custom:publicSigningKey"] },
        {
          or: [{ status: { eq: "ACCEPTED" } }, { status: { eq: "PENDING" } }]
        }
      );

      userData = {
        profile: profiles[0],
        memberships: memberships,
        height: newHeight
      };
    } else {
      //fetch memberships to which a user was invited before creating an account
      const invitedMemberships = await paginatedQuery(
        "membershipsByEmail",
        queries.membershipsByEmail,
        { email: user.attributes["email"] },
        { status: { eq: "INVITED" } }
      );

      if (invitedMemberships.length > 0)
        handleInvitedMemberships(invitedMemberships);
      // fetch only the latest changes, if any
      if (incomingHeight < parseInt(localHeight)) {
        return;
      }
      const result = await API.graphql(
        graphqlOperation(queries.profileByHeight, {
          height: parseInt(localHeight)
        })
      );
      newHeight = result.data.profileByHeight.height;
      userData = result.data.profileByHeight;
    }
    localStorage.setItem("height", newHeight);
    setHeight(newHeight);
    await updateDB(userData);
  };

  /**
   * Tries to authenticate the user.
   * Authentication retry is used to support multi tab light session.
   * Light session -> data stored in SessionStorage.
   * Information about light app session opened in other tab is auxiliary (local storage events) thus cannot be synchronized
   */
  useEffect(() => {
    let retry = true;
    const authenticate = async () => {
      Hub.dispatch("akord:auth", { event: "preAuthenticate" });
      authenticateUser();
    };

    const authenticateUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        await AkordWallet.importFromKeystore(
          user.attributes["custom:encBackupPhrase"]
        );
        handleIsAuthenticated(true);
        handleIsAuthLoaded(true);
      } catch (err) {
        if (retry) {
          setTimeout(() => {
            authenticate();
            retry = false;
          }, 500);
        } else {
          Hub.dispatch("akord:auth", { event: "unauthorized" });
          handleIsAuthenticated(false);
          handleIsAuthLoaded(true);
        }
      }
    };
    authenticate();
  }, []);

  useEffect(() => {
    if (isMembershipsLoaded && isProfileLoaded) {
      handleIsLoaded(isMembershipsLoaded && isProfileLoaded);
    }
  }, [isMembershipsLoaded, isProfileLoaded]);

  useEffect(() => {
    const loadProfilesByHeight = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const userColorMode = user.attributes["custom:mode"];
        const userLegacyVaultsNotified =
          user.attributes["custom:legacyVaultsNotified"];
        const userReferralId = user.attributes["custom:referralId"];
        const userReferrerId = user.attributes["custom:referrerId"];
        const userOnboarding = /true/i.test(
          user.attributes["custom:onboarding"]
        );
        const userUploadNotification = /true/i.test(
          user.attributes["custom:notifyOnUpload"]
        );
        const referrals = user.attributes["custom:referrals"] || "0";
        const showV2Announcment = /true/i.test(
          user.attributes["custom:showV2Announcment"] || "true"
        );
        const userWallet = await AkordWallet.importFromKeystore(
          user.attributes["custom:encBackupPhrase"]
        );
        handleWallet(userWallet);
        handleUserColorMode(userColorMode);
        handleUserAttributes({
          notifyOnUpload: userUploadNotification,
          mode: userColorMode,
          legacyVaultsNotified: userLegacyVaultsNotified?.split(",") || [],
          userOnboarding: userOnboarding,
          userReferralId: userReferralId,
          userReferrerId: userReferrerId,
          referrals: parseInt(referrals),
          showV2Announcment: showV2Announcment
        });

        if (window.navigator.onLine) {
          await fetchProfileByHeight(height);
        }
      } catch (err) {
        console.log("profileByHeight error:", err);
      }
    };
    db.open().catch(err => {
      console.error(err.stack || err);
    });
    if (isAuthenticated) loadProfilesByHeight();
    return () => {
      handleWallet();
      handleUserColorMode("auto");
    };
  }, [isAuthenticated]);

  useEffect(() => {
    // Initialize a new user
    const loadProfiles = async () => {
      try {
        if (!isAuthenticated || !wallet) return;
        const user = await Auth.currentAuthenticatedUser();
        const results = await API.graphql(
          graphqlOperation(queries.profilesByPublicSigningKeySuperLight, {
            publicSigningKey: await wallet.signingPublicKey()
          })
        );

        if (results.data.profilesByPublicSigningKey.items.length === 0) {
          const ledgerWrapper = new LedgerWrapper(wallet);
          const { encodedTransaction } = await ledgerWrapper.dispatch(
            "PROFILE_CREATE",
            {},
            {}
          );
          await API.graphql(
            graphqlOperation(mutations.postLedgerTransaction, {
              transactions: [encodedTransaction]
            })
          );
          // update user public keys to keep them consistent with the backend
          const walletPublicKey = await wallet.publicKey();
          const walletsigningPublicKey = await wallet.signingPublicKey();

          await Auth.updateUserAttributes(user, {
            "custom:publicKey": walletPublicKey,
            "custom:publicSigningKey": walletsigningPublicKey,
            "custom:mode": "dark",
            "custom:notifications": "true",
            "custom:notifyOnUpload": "true",
            "custom:onboarding": "true",
            "custom:showV2Announcment": "true"
          });
          const userReferralId = user.attributes["custom:referralId"];
          const userReferrerId = user.attributes["custom:referrerId"];
          handleUserAttributes({
            notifyOnUpload: true,
            mode: "dark",
            userOnboarding: true,
            userReferralId: userReferralId,
            userReferrerId: userReferrerId
          });
        }
      } catch (err) {
        console.log("GDP: ", err);
      }
    };
    loadProfiles();
  }, [isAuthenticated, wallet]);

  //Subscription
  useEffect(() => {
    let subscription;
    const setupSubscription = async () => {
      try {
        if (
          !isAuthenticated ||
          wallet === undefined ||
          Object.entries(wallet).length === 0
        )
          return;

        subscription = await API.graphql(
          graphqlOperation(
            subscriptions.onCreateTransactionLogByPublicSigningKey,
            {
              publicSigningKey: await wallet.signingPublicKey()
            }
          )
        ).subscribe({
          next: async ({ value }) => {
            handleTransactionLog(
              value.data.onCreateTransactionLogByPublicSigningKey
            );
            await fetchProfileByHeight(
              value.data.onCreateTransactionLogByPublicSigningKey.height
            );
          },
          error: () => {
            console.warn("err");
          }
        });
      } catch (err) {
        console.log("Subscription error: ", err);
      }
    };
    if (window.navigator.onLine) setupSubscription();
    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [isAuthenticated, wallet]);

  useEffect(() => {
    const loadColorMode = async () => {
      //get display mode
      try {
        const user = await Auth.currentAuthenticatedUser();
        const mode = user.attributes["custom:mode"];
        if (mode === "dark") {
          handleIsDarkMode(true);
        } else if (mode === "light") {
          handleIsDarkMode(false);
        } else {
          handleIsDarkMode(prefersDarkMode);
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (isAuthenticated) {
      loadColorMode();
    }
  }, [isAuthenticated, prefersDarkMode]);

  useEffect(() => {
    if (isAuthenticated) {
      validateAuthSession();
    }
  }, [isAuthenticated, isProfileLoaded]);

  /**
   *
   * Force logout when
   * - loaded profile does not match currently authenticated user
   * - cognito uid does not match currently authenticated user
   */
  const validateAuthSession = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.has("uid")) {
      const userId = urlParams.get("uid");
      if (user.username !== userId) {
        handleIsAuthenticated(false);
        await Auth.signOut();
        history.push("/sign-in?uid=" + userId);
      }
    }
    if (
      isProfileLoaded &&
      user.attributes["custom:publicSigningKey"] !==
        decryptedProfileDetails.publicSigningKey
    ) {
      Hub.dispatch("akord:auth", { event: "resetSession" });
      handleDecryptedProfileDetails(null);
      handleIsProfileLoaded(false);
      handleIsMembershipsLoaded(false);
      await fetchProfileByHeight();
    }
  };

  const screenWidthCalc = () => {
    if (xs) return "xs";
    if (sm) return "sm";
    if (md) return "md";
    if (lg) return "lg";
    if (xl) return "xl";
  };

  useEffect(() => {
    setScreenWidth(screenWidthCalc());
  });

  // DESKTOP LEFT SIDEBAR ROOMS VIEW
  const onDataRoomsExpand = () => {
    setRoomsMenuOpen(!roomsMenuOpen);
  };

  return (
    <Context.Provider
      value={{
        isAuthenticated: isAuthenticated,
        onIsAuthenticated: handleIsAuthenticated,
        isAuthLoaded: isAuthLoaded,
        isLoaded: isLoaded,
        error: error,
        onError: handleError,
        fullProfile: decryptedFullProfile,
        isProfileLoaded: isProfileLoaded,
        decryptedMemberships: decryptedMemberships,
        isMembershipsLoaded: isMembershipsLoaded,
        onMembershipByDataRoomId: onMembershipByDataRoomId,
        onLedgerWrapperFromMembership: onLedgerWrapperFromMembership,
        onEncrypterFromMembership: onEncrypterFromMembership,
        decryptedProfileDetails: decryptedProfileDetails,
        invitedMemberships: invitedMemberships,
        wallet: wallet,
        handleWallet: handleWallet,
        width: screenWidth,
        isMobile: screenWidth === "xs",
        onIsDarkMode: handleIsDarkMode,
        darkMode: isDarkMode,
        onUserColorMode: handleUserColorMode,
        userColorMode: userColorMode,
        userAttributes: userAttributes,
        onUserAttributes: handleUserAttributes,
        roomsMenu: {
          roomsMenuOpen: roomsMenuOpen,
          onDataRoomsExpand: onDataRoomsExpand
        },
        onTxSpinner: handleTxSpinner,
        txSpinner: txSpinner,
        onDecrptSpinner: handleDecrptSpinner,
        decrptSpinner: decrptSpinner,
        assetsUpdatedHeight: assetsUpdatedHeight,
        onAssetsUpdatedHeight: handleAssetsUpdatedHeight,
        transactionLog: transactionLog
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default GlobalDataProvider;

export const withWallet = Component => props =>
  (
    <Context.Consumer>
      {wallet => <Component {...props} {...wallet} />}
    </Context.Consumer>
  );

export const useGlobalContext = () => useContext(Context);
