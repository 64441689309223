import {
  AddMemberIcon,
  ArchiveIcon,
  AvatarIcon,
  VaultIcon,
  VaultLeaveIcon,
  TrashIcon,
  DownloadIcon,
  FileRestoreIcon,
  FolderOutlineIcon,
  FolderRestoreIcon,
  FolderRevokeIcon,
  InfoIcon,
  InvitationAcceptIcon,
  KeyIcon,
  LetterIcon,
  MinusInCircleIcon,
  MoveIcon,
  NoteIcon,
  PaperPlaneIcon,
  PenIcon,
  RestoreIcon,
  RevokedFileIcon,
  StackIcon,
  ThumbsUpIcon,
  TicketIcon,
  TickIcon,
  TickinCircleIcon,
  UploadIcon,
  CloseInCircleIcon,
  LinkIcon
} from "@akord/addon-icons";

export const snackbarOptions = {
  dataRoomCreate: {
    text: "Your vault was successfully created.",
    Icon: VaultIcon
  },
  dataRoomArchive: {
    text: "Your vault was successfully archived.",
    Icon: ArchiveIcon
  },
  dataRoomRename: {
    text: "Your vault was successfully renamed.",
    Icon: PenIcon
  },
  dataRoomInvite: {
    text: "Your invitation was successfully sent.",
    altText: "new invitations were successfully sent.",
    Icon: AddMemberIcon
  },
  dataRoomRestore: {
    text: "Your vault was successfully restored.",
    Icon: RestoreIcon
  },
  dataRoomDelete: {
    text: "Your vault was successfully removed.",
    Icon: MinusInCircleIcon
  },
  dataRoomConfirm: {
    text: "Access to your vault was successfully confirmed.",
    Icon: KeyIcon
  },
  dataRoomAccessUpdate: {
    text: "Access to your vault was successfully updated.",
    Icon: KeyIcon
  },
  dataRoomAccessPending: {
    text: "",
    altText: "has already had their access confirmed.",
    Icon: TickinCircleIcon
  },
  dataRoomAccessConfirm: {
    text: "",
    altText: "has had their access confirmed.",
    Icon: TickinCircleIcon
  },
  dataRoomAccessAccepted: {
    text: "",
    altText: "has already had their access confirmed.",
    Icon: InvitationAcceptIcon
  },
  dataRoomAccessRevoked: {
    text: "",
    altText: "has had their access revoked.",
    Icon: CloseInCircleIcon
  },
  dataRoomAccessReject: {
    text: "",
    altText: "has rejected the invite.",
    Icon: CloseInCircleIcon
  },
  memberRevoke: {
    text: "A member of your vault was successfully revoked.",
    Icon: KeyIcon
  },
  uploadPending: {
    text: "Your uploads are pending.",
    altText:
      "It may take a while to commit files to the blockchain depending on their size. You can still view, rename and move files while they’re pending.",
    checkBoxText: "Don’t show me this message again.",
    Icon: InfoIcon
  },
  uploadFailed: {
    text: "A file failed to upload to the blockchain.",
    linkText: "View transactions",
    Icon: InfoIcon,
    error: true
  },
  fileUpload: {
    text: "A new file was successfully uploaded.",
    altText: "new files were successfully uploaded.",
    Icon: UploadIcon
  },
  fileDownload: {
    text: "Preparing your file for download. Large files may take a while.",
    altText: "file is being prepared for download",
    Icon: DownloadIcon
  },
  versionUpload: {
    text: "A new version was successfully uploaded.",
    Icon: StackIcon
  },
  fileRename: {
    text: "Your file was successfully renamed.",
    Icon: PenIcon
  },
  fileRevoked: {
    text: "Your file was successfully revoked.",
    Icon: RevokedFileIcon
  },
  batchRevoked: {
    text: "Your items were successfully revoked.",
    Icon: MinusInCircleIcon
  },
  fileRestore: {
    text: "Your file was successfully restored.",
    Icon: FileRestoreIcon
  },
  batchRestore: {
    text: "Your items were successfully restored.",
    Icon: FileRestoreIcon
  },
  fileRemove: {
    text: "Your file was successfully removed.",
    Icon: MinusInCircleIcon
  },
  batchRemove: {
    text: "Your items were successfully removed.",
    Icon: MinusInCircleIcon
  },
  restoreFileWithMove: {
    text: "Your file was successfully restored.",
    Icon: FileRestoreIcon
  },
  fileDelete: {
    text: "Your file was successfully deleted.",
    Icon: TrashIcon
  },
  passwordChange: {
    text: "Your password was successfully changed.",
    Icon: TickIcon
  },
  profileUpdate: {
    text: "Your profile was successfully updated.",
    Icon: AvatarIcon
  },
  userVerified: {
    text: "Your email was successfully verified.\nPlease enter your email and password to log in.",
    Icon: ThumbsUpIcon
  },
  linkToVerify: {
    text: "Check your email for a verification link.",
    Icon: LetterIcon
  },
  folderCreate: {
    text: "Your folder was successfuly created.",
    Icon: FolderOutlineIcon
  },
  folderRename: {
    text: "Your folder was successfully renamed.",
    Icon: PenIcon
  },
  folderRevoke: {
    text: "Your folder was successfully revoked.",
    Icon: FolderRevokeIcon
  },
  folderRestore: {
    text: "Your folder was successfully restored.",
    Icon: FolderRestoreIcon
  },
  restoreFolderWithMove: {
    text: "Your folder was successfully restored.",
    Icon: FolderRestoreIcon
  },
  folderDelete: {
    text: "Your folder was successfully deleted.",
    Icon: TrashIcon
  },
  fileMove: {
    text: "Your file was successfully moved.",
    Icon: MoveIcon
  },
  folderMove: {
    text: "Your folder was successfully moved.",
    Icon: MoveIcon
  },
  batchMove: {
    text: "Your items were successfully moved.",
    Icon: MoveIcon
  },
  noteMove: {
    text: "Your note was successfully moved.",
    Icon: MoveIcon
  },
  topUpSuccess: {
    text: "Your top up was successfully processed.",
    Icon: TickIcon
  },
  topUpFailure: {
    altText: "",
    Icon: CloseInCircleIcon
  },
  referralInvite: {
    text: "Your referral invite was successfully sent.",
    Icon: TicketIcon
  },
  referralInvites: {
    text: "Your referral invites were successfully sent.",
    Icon: TicketIcon
  },
  membershipLeave: {
    text: "You have successfully left the vault.",
    Icon: VaultLeaveIcon
  },
  resendInvite: {
    text: "Your invite was successfully resent.",
    Icon: PaperPlaneIcon
  },
  revokeNote: {
    text: "Your note was successfully revoked.",
    Icon: RevokedFileIcon
  },
  restoreNote: {
    text: "Your note was successfully restored.",
    Icon: FileRestoreIcon
  },
  saveNote: {
    text: "Your note was successfully saved.",
    Icon: NoteIcon
  },
  reviseNote: {
    text: "A new version of your note was successfully saved.",
    Icon: NoteIcon
  },
  noteRemove: {
    text: "Your note was successfully removed.",
    Icon: MinusInCircleIcon
  },
  actionFailure: {
    text: "Something went wrong.",
    Icon: CloseInCircleIcon
  },
  shareFile: {
    text: "The link to this image was copied to your clipboard",
    Icon: LinkIcon
  }
};
