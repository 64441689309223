import { Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { base64ToArray } from "@akord/crypto";
// Storage.configure({
//   level: "public",
//   customPrefix: {
//     public: "public/"
//   }
// });

export default class EncryptedChunkProcessor {
  constructor(encrypter, progressHook, cancelHook, snackbarHook) {
    this.encrypter = encrypter;
    this.progressHook = progressHook;
    this.snackbarHook = snackbarHook;
    this.cancelHook = cancelHook;
  }

  encodingFactor = 2.4;
  encryptionFactor = 100; // in bytes - safety buffer; encryption should take 16 bytes
  chunkSize = 10485760;
  uploadInProgress = false;
  downloadInProgress = false;
  downloadCancelled = false;
  uploadedChunks = 0;
  resourceUrl = null;

  async encryptedChunkedUpload(file) {
    this.uploadInProgress = true;
    let offset = 0;
    let loadedProgress = 0;
    let totalProgress = 0;

    while (offset < file.size && !this.uploadCancelled) {
      const chunk = file.slice(offset, this.chunkSize + offset);
      const { encryptedData, chunkNumber } = await this._encryptChunk(
        chunk,
        offset
      );
      totalProgress = this._getEncryptedFileSize(file, encryptedData);
      if (offset == 0) {
        this.resourceUrl = encryptedData.resourceKey;
      }
      loadedProgress = await this._uploadChunk(
        encryptedData,
        chunkNumber,
        this.resourceUrl,
        loadedProgress,
        totalProgress
      );
      offset += this.chunkSize;
      this.uploadedChunks += 1;
    }

    this.uploadInProgress = false;
    return {
      uploaded: !this.uploadCancelled,
      resourceUrl: this.resourceUrl,
      numberOfChunks: this.uploadedChunks,
      chunkSize: this.chunkSize
    };
  }

  cancelUpload() {
    this.uploadCancelled = true;
    this.progressHook(0);
    let removedChunks = 0;
    while (removedChunks <= this.uploadedChunks) {
      Storage.remove(`${this.resourceUrl}_${removedChunks}`);
      removedChunks += 1;
    }
  }

  async decryptedChunkedDownload(
    originalFileSize,
    resourceUrl,
    numberOfChunks,
    fileType
  ) {
    this.downloadInProgress = true;
    let fileData = new Uint8Array();
    let uploadedFileSize = originalFileSize;
    let currentChunk = 0;
    let decryptedSize = 0;
    const chunksCount = numberOfChunks || 1;
    const progressHook = this.progressHook;
    if (chunksCount > 1 && this.snackbarHook) {
      this.snackbarHook("fileDownload");
    }
    try {
      if (
        await this._isEncoded(resourceUrl, numberOfChunks, 0, originalFileSize)
      ) {
        uploadedFileSize = originalFileSize * this.encodingFactor;
      }
      while (currentChunk < chunksCount && !this.downloadCancelled) {
        const chunkUrl = this._getChunkUrl(
          resourceUrl,
          numberOfChunks,
          currentChunk
        );
        if (!chunkUrl) {
          throw new Error(
            "Failed to download. The file might be corrupted. Please upload the file again and/or contact Akord support."
          );
        }
        const bucketObject = await Storage.get(chunkUrl, {
          download: true,
          progressCallback(progress) {
            let percentCompleted = Math.round(
              ((decryptedSize + progress.loaded) * 100) / uploadedFileSize
            );
            if (progressHook) {
              progressHook(percentCompleted);
            }
          }
        });
        const { encryptedFile, isEncoded } = await this._getEncryptedFile(
          bucketObject
        );
        const decryptedChunk = await this.encrypter.decryptRaw(
          encryptedFile,
          isEncoded
        );
        fileData = this._appendBuffer(fileData, decryptedChunk);
        currentChunk += 1;
        decryptedSize = fileData.byteLength;
      }
    } catch (e) {
      console.log(e);
      throw new Error(
        "Failed to download. Please check your network connection." +
          " Please upload the file again if problem persists and/or contact Akord support."
      );
    } finally {
      this.downloadInProgress = false;
    }
    if (this.downloadCancelled) {
      throw new Error("Download cancelled");
    }
    return new Blob([fileData], { type: fileType });
  }

  cancelDownload() {
    this.downloadCancelled = true;
  }

  cancel() {
    if (this.downloadInProgress) {
      this.downloadInProgress = false;
      this.cancelDownload();
    } else if (this.uploadInProgress) {
      this.uploadInProgress = false;
      this.cancelUpload();
    }
  }

  async _getEncryptedFile(bucketObject) {
    if (this._bucketHasBinaryFile(bucketObject)) {
      return {
        encryptedFile: {
          encryptedKey: bucketObject.Metadata.encryptedkey,
          publicKey: bucketObject.Metadata.publickey,
          encryptedData: {
            iv: base64ToArray(bucketObject.Metadata.iv),
            ciphertext: await bucketObject.Body.arrayBuffer()
          }
        },
        isEncoded: false
      };
    }

    const encodedContent = await bucketObject.Body.text();
    return {
      encryptedFile: this._parseEncryptedFile(encodedContent),
      isEncoded: true
    };
  }

  _parseEncryptedFile(file) {
    const repEncoded = file.replace(/(')/g, "");
    try {
      const encoded = JSON.parse(repEncoded);
      return encoded.encryptedFile || encoded;
    } catch (e) {
      return file;
    }
  }

  _bucketHasMetadata(bucket) {
    return (
      bucket &&
      bucket.Metadata &&
      bucket.Metadata.iv &&
      bucket.Metadata.publickey &&
      bucket.Metadata.encryptedkey
    );
  }

  _bucketHasBinaryFile(bucket) {
    return this._bucketHasMetadata(bucket);
  }

  async _isEncoded(resourceUrl, numberOfChunks, currentChunk, fileSize) {
    const url = this._getChunkUrl(resourceUrl, numberOfChunks, currentChunk);
    const bucketMeta = await Storage.list(url);
    if (bucketMeta && bucketMeta.length) {
      return (
        bucketMeta[0].size > this.chunkSize + this.encryptionFactor ||
        bucketMeta[0].size > fileSize + this.encryptionFactor
      );
    }
    return false;
  }

  _getChunkUrl(resourceUrl, numberOfChunks, chunkNumber) {
    if (numberOfChunks) {
      if (chunkNumber >= numberOfChunks) {
        return null;
      }
      return `${resourceUrl}_${chunkNumber}`;
    }
    return resourceUrl;
  }

  async _encryptChunk(chunk, offset) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = async evt => {
        if (evt.target.error == null) {
          const chunkNumber = offset / this.chunkSize;
          const byteArray = new Uint8Array(evt.target.result);
          const encryptedData = await this._encryptByteArray(byteArray);
          resolve({ encryptedData, chunkNumber });
        }
      };
      reader.readAsArrayBuffer(chunk);
    });
  }

  async _encryptByteArray(byteArray) {
    const encryptedByteArray = await this.encrypter.encryptRaw(
      byteArray,
      false
    );
    const resourceKey = uuidv4();
    const uploadPayload = {
      encryptedFile: encryptedByteArray,
      resourceKey: resourceKey
    };
    return uploadPayload;
  }

  async _uploadChunk(
    chunk,
    chunkNumber,
    resourceUrl,
    loadedProgress,
    totalProgress
  ) {
    const processor = this;
    await Storage.put(
      `${resourceUrl}_${chunkNumber}`,
      this._getEncryptedData(chunk),
      {
        metadata: this._getMetadata(chunk),
        progressCallback(progress) {
          if (processor.uploadCancelled) {
            processor.progressHook(0);
          } else {
            let percentCompleted = Math.round(
              (loadedProgress + progress.loaded * 100) / totalProgress
            );
            if (processor.progressHook) {
              processor.progressHook(percentCompleted);
            }
            if (progress.loaded == progress.total) {
              loadedProgress += progress.loaded * 100;
            }
          }
        }
      }
    );
    return loadedProgress;
  }

  _getEncryptedData(chunk) {
    if (typeof chunk.encryptedFile === "string") {
      return chunk.encryptedFile;
    }
    return chunk.encryptedFile.encryptedData.ciphertext;
  }

  _getMetadata(chunk) {
    if (typeof chunk.encryptedFile === "string") {
      return {};
    }
    return {
      encryptedkey: chunk.encryptedFile.encryptedKey,
      publickey: chunk.encryptedFile.publicKey,
      iv: chunk.encryptedFile.encryptedData.iv
    };
  }

  _getEncryptedFileSize(file, chunk) {
    if (typeof chunk.encryptedFile === "string") {
      return file.size * this.encodingFactor;
    }
    return file.size;
  }

  _appendBuffer(buffer1, buffer2) {
    var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
    tmp.set(new Uint8Array(buffer1), 0);
    tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
    return tmp.buffer;
  }
}
