import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import {
  Tooltip,
  TableRow,
  TableCell,
  Box,
  Checkbox,
  Typography
} from "@mui/material";
import { FolderIcon, TickIcon } from "@akord/addon-icons";
import Skeleton from "@mui/material/Skeleton";
import {
  formatDate,
  getDataRoomStatus,
  getDataRoomId
} from "../../helpers/helpers";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { FolderDrawer } from "../../components/common";
import MoreActionButton from "../../components/common/MoreActionButton";
import MenuFolder from "../../components/common/MoreMenu/MenuFolder";
import { withRouter } from "react-router";
import { bytesToGb } from "../storage/storage-helper";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";

const useStyles = makeStyles(theme => ({
  iconBackground: {
    boxSizing: "content-box",
    marginRight: "15px",
    borderRadius: "2px",
    background: ({ itemChecked, iconHovered, isVaultPublic }) =>
      itemChecked || iconHovered
        ? itemChecked
          ? isVaultPublic
            ? theme.palette.background.stack
            : theme.palette.primary.veryDark
          : theme.palette.background.folder
        : "transparent",
    color: ({ archived, itemChecked, iconHovered, isVaultPublic }) =>
      archived
        ? itemChecked
          ? "#FFF"
          : theme.palette.secondary.disabled
        : itemChecked || iconHovered
        ? itemChecked
          ? "#FFF"
          : isVaultPublic
          ? theme.palette.info.secondary
          : theme.palette.primary.main
        : isVaultPublic
        ? theme.palette.info.secondary
        : theme.palette.primary.main
  },
  toolLine: {
    display: "flex",
    justifyContent: "space-between",
    height: "56px",
    alignItems: "center"
  },
  checkBox: {
    padding: 0
  }
}));

const columnsToShow = (folder, width, folderSizeToShow) => {
  switch (true) {
    case width === "xs":
      return null;
    case !!folder:
      switch (true) {
        case width === "sm":
          return (
            <TableCell align="left">
              <Typography variant="body2" color="text.primary" noWrap>
                {formatDate(folder.updatedAt)}
              </Typography>
            </TableCell>
          );
        default:
          return (
            <>
              <TableCell align="left">
                <Typography variant="body2" color="text.primary" noWrap>
                  {formatDate(folder.updatedAt)}
                </Typography>
              </TableCell>
              <TableCell align="left">
                {folderSizeToShow?.size
                  ? folderSizeToShow.size +
                    folderSizeToShow.sizeType.toUpperCase()
                  : "-"}
              </TableCell>
              <TableCell align="left"></TableCell>
            </>
          );
      }
    default:
      return (
        <>
          <TableCell>
            <Skeleton style={{ height: "30px" }} />
          </TableCell>
          {width === "sm" ? (
            <TableCell />
          ) : (
            <TableCell>
              <Skeleton style={{ height: "30px" }} />
            </TableCell>
          )}
        </>
      );
  }
};

function FolderItem({ folder, revoked, history }) {
  const { width, darkMode } = useGlobalContext();
  const { userRole, onCurrentStack, isVaultPublic } = useVaultContext();
  const {
    decryptedFolders,
    decryptedNotes,
    decryptedStacks,
    onSelectedItems,
    onLastSelectedItem,
    selectedItemsMap,
    isRoomArchived
  } = useAssetsContext();

  const location = useLocation();
  const theme = useTheme();
  const itemRef = useRef(null);
  const itemId = location.state?.itemId;

  const itemChecked = selectedItemsMap?.has(folder?.hash);

  const [menuActive, setMenuActive] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenuFolder = Boolean(anchorEl);

  const [iconHovered, setIconHovered] = React.useState(false);
  const handleIconHovered = action => setIconHovered(action);

  useEffect(() => {
    if (itemId && folder && itemId == folder.id && itemRef?.current) {
      itemRef.current.scrollIntoView({ behavior: "smooth" });
      highlightItem();
    }
  }, [folder]);

  const highlightItem = () => {
    const initialBackground = "inherit";
    itemRef.current.style.transition = "background-color 1s ease-in";
    itemRef.current.style.background = theme.palette.background.stack;
    setTimeout(() => {
      itemRef.current.style.background = initialBackground;
    }, 1000);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
    setMenuActive(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuActive(false);
  };

  const classes = useStyles({
    darkMode: darkMode,
    archived: isRoomArchived || revoked || userRole === "VIEWER",
    menuActive: menuActive,
    itemChecked: itemChecked,
    iconHovered: iconHovered,
    isVaultPublic: isVaultPublic
  });

  const toggleIcon = () => handleIconHovered(!iconHovered);

  const handleSelectFolder = event => {
    const { name, checked } = event.target;
    onSelectedItems(name, checked, revoked);
    onLastSelectedItem(name);
  };
  const calculateStackSize = stackArray =>
    stackArray.reduce((acc, stack) => (acc += stack?.size), 0);

  const getEnclosedAssets = (stackArray, folderId) =>
    stackArray.filter(stack => stack.folderId == folderId);

  let allEnclosedStacks = [];
  let allEnclosedNotes = [];

  const recursiveStacks = folderId => {
    allEnclosedStacks.push(getEnclosedAssets(decryptedStacks, folderId));
    allEnclosedNotes.push(getEnclosedAssets(decryptedNotes, folderId));
    const enclosedFolders = decryptedFolders.filter(
      item => item.folderId === folderId
    );

    if (enclosedFolders.length > 0) {
      enclosedFolders.map(folder => recursiveStacks(folder.id));
    }

    return [...allEnclosedStacks, ...allEnclosedNotes].flat(4);
  };

  const folderSizeToShow = bytesToGb(
    calculateStackSize(recursiveStacks(folder.id))
  );

  if (!decryptedStacks || !decryptedNotes) return null;

  return (
    <>
      <TableRow hover={!!folder} ref={itemRef}>
        {folder ? (
          <TableCell size="medium" style={{ cursor: "pointer" }} scope="row">
            <Tooltip title={folder.title}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {iconHovered || itemChecked ? (
                  <Checkbox
                    onMouseLeave={toggleIcon}
                    onMouseEnter={toggleIcon}
                    onChange={handleSelectFolder}
                    checked={itemChecked}
                    name={folder.hash}
                    className={classes.checkBox}
                    icon={
                      <TickIcon
                        fontSize="medium"
                        className={classes.iconBackground}
                        style={{ padding: "3px" }}
                      />
                    }
                    checkedIcon={
                      <TickIcon
                        fontSize="medium"
                        className={classes.iconBackground}
                        style={{ padding: "3px" }}
                      />
                    }
                  />
                ) : (
                  <FolderIcon
                    fontSize="large"
                    className={classes.iconBackground}
                    onMouseEnter={toggleIcon}
                  />
                )}
                <div
                  style={{ display: "grid" }}
                  onClick={() =>
                    revoked
                      ? history.push(
                          `/vaults/${getDataRoomStatus(
                            history.location.pathname
                          )}/${getDataRoomId(
                            history.location.pathname
                          )}/revoked-files/folders/${folder.id}`
                        )
                      : history.push(
                          `/vaults/${getDataRoomStatus(
                            history.location.pathname
                          )}/${getDataRoomId(
                            history.location.pathname
                          )}/assets/folders/${folder.id}`
                        )
                  }
                >
                  <Typography variant="body2" color="text.primary" noWrap>
                    {folder.title}
                  </Typography>
                </div>
              </div>
            </Tooltip>
          </TableCell>
        ) : (
          <TableCell scope="row" size="medium">
            <Box display="flex">
              <Skeleton
                variant="rectangular"
                width={30}
                height={30}
                style={{ marginRight: "15px" }}
              />
              <Skeleton style={{ flex: "1" }} />
            </Box>
          </TableCell>
        )}

        {columnsToShow(folder, width, folderSizeToShow)}

        {folder ? (
          <TableCell align="right" size="medium">
            <MoreActionButton
              handleClick={e => {
                handleMenu(e);
                onCurrentStack(folder);
              }}
              menuActive={menuActive}
            />
          </TableCell>
        ) : width === "xs" || width === "sm" ? null : (
          <TableCell />
        )}
      </TableRow>
      {width === "xs" ? (
        <FolderDrawer
          openDrawer={openMenuFolder}
          handleMenuClose={handleMenuClose}
          revokedFolder={revoked}
        />
      ) : (
        <MenuFolder
          anchorEl={anchorEl}
          openMenu={openMenuFolder}
          handleMenuClose={handleMenuClose}
          revokedFolder={revoked}
        />
      )}
    </>
  );
}

export default withRouter(FolderItem);
