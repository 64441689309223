import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { TableRow, TableCell, TableHead, Typography } from "@mui/material";
import TableSortLabel from "@mui/material/TableSortLabel";
import { withRouter } from "react-router";
import { ArrowUpIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { getDataRoomStatus, getDataRoomId } from "../../helpers/helpers";

const useStyles = makeStyles({
  tableText: {
    fontSize: "13px"
  }
});

function DataRoomFilter({
  archivedStack,
  order,
  orderBy,
  onRequestSort,
  data,
  location,
  showAllFilters
}) {
  const { width, isMobile } = useGlobalContext();
  const classes = useStyles();

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const columnsToShow = () => {
    switch (true) {
      case isMobile ||
        (width === "sm" &&
          !location.pathname.match(
            `/vaults/${getDataRoomStatus(location.pathname)}/${getDataRoomId(
              location.pathname
            )}`
          )):
        switch (true) {
          case data && data.length > 0:
            return <TableCell width="5%" />;
          default:
            return <TableCell width="5%" />;
        }
      case (width !== "xs" &&
        !!location.pathname.match(
          `/vaults/${getDataRoomStatus(location.pathname)}/${getDataRoomId(
            location.pathname
          )}`
        )) ||
        showAllFilters:
        return (
          <>
            <TableCell
              width="25%"
              className={classes.tableText}
              align="left"
              sortDirection={orderBy === "updatedAt" ? order : false}
            >
              <TableSortLabel
                active={orderBy === "updatedAt"}
                direction={orderBy === "updatedAt" ? order : "asc"}
                onClick={createSortHandler("updatedAt")}
                IconComponent={ArrowUpIcon}
              >
                <Typography
                  variant="caption"
                  className="small"
                  color="text.secondary"
                >
                  {archivedStack ? "Revoked on" : "Modified on"}
                </Typography>
              </TableSortLabel>
            </TableCell>
            {width !== "sm" && (
              <>
                <TableCell
                  width="20%"
                  className={classes.tableText}
                  align="left"
                  sortDirection={orderBy === "size" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "size"}
                    direction={orderBy === "size" ? order : "asc"}
                    onClick={createSortHandler("size")}
                    IconComponent={ArrowUpIcon}
                  >
                    <Typography
                      variant="caption"
                      className="small"
                      color="text.secondary"
                    >
                      Size
                    </Typography>
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  width="10%"
                  className={classes.tableText}
                  align="left"
                  sortDirection={orderBy === "files" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "files"}
                    direction={orderBy === "files" ? order : "asc"}
                    onClick={createSortHandler("files")}
                    IconComponent={ArrowUpIcon}
                  >
                    <Typography
                      variant="caption"
                      className="small"
                      color="text.secondary"
                    >
                      Versions
                    </Typography>
                  </TableSortLabel>
                </TableCell>
              </>
            )}
            <TableCell width="5%" />
          </>
        );
      default:
        return (
          <>
            <TableCell
              width="30%"
              align="left"
              sortDirection={orderBy === "updatedAt" ? order : false}
            >
              <TableSortLabel
                active={orderBy === "updatedAt"}
                direction={orderBy === "updatedAt" ? order : "asc"}
                onClick={createSortHandler("updatedAt")}
                IconComponent={ArrowUpIcon}
              >
                <Typography
                  variant="caption"
                  className="small"
                  color="text.secondary"
                >
                  Modified on
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell
              width="10%"
              className={classes.tableText}
              align="left"
              sortDirection={orderBy === "type" ? order : false}
            >
              <TableSortLabel
                active={orderBy === "type"}
                direction={orderBy === "type" ? order : "asc"}
                onClick={createSortHandler("type")}
                IconComponent={ArrowUpIcon}
              >
                <Typography
                  variant="caption"
                  className="small"
                  color="text.secondary"
                >
                  Size
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell
              width="10%"
              className={classes.tableText}
              align="left"
              sortDirection={orderBy === "size" ? order : false}
            >
              <TableSortLabel
                active={orderBy === "size"}
                direction={orderBy === "size" ? order : "asc"}
                onClick={createSortHandler("size")}
                IconComponent={ArrowUpIcon}
              >
                <Typography
                  variant="caption"
                  className="small"
                  color="text.secondary"
                >
                  Type
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell width="5%" />
          </>
        );
    }
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          width={isMobile ? "100%" : "50%"}
          align="left"
          sortDirection={orderBy === "title" ? order : false}
        >
          <TableSortLabel
            active={orderBy === "title"}
            direction={orderBy === "title" ? order : "asc"}
            onClick={createSortHandler("title")}
            IconComponent={ArrowUpIcon}
          >
            <Typography
              variant="caption"
              className="small"
              color="text.secondary"
            >
              Name
            </Typography>
          </TableSortLabel>
        </TableCell>
        {columnsToShow()}
      </TableRow>
    </TableHead>
  );
}

export default withRouter(DataRoomFilter);
