import { matchPath } from "react-router";
import { StackIcon, VideoIcon, AudioIcon, NoteIcon } from "@akord/addon-icons";
import { ImageIcon, DocumentIcon } from "@akord/addon-icons";
import { filterActionRefs, filterActions } from "./akord-enums";

export const formatDate = (inputDate, justTime = false, logTime = false) => {
  const todayDate = new Date();
  const fullDate = new Date(inputDate);
  const year = fullDate.getFullYear();
  const month = fullDate.getMonth() + 1; //January is 0
  const date = fullDate.getDate();
  const hours = fullDate.getHours();
  const minutes = fullDate.getMinutes();
  const formatedDate = `${month}/${date}/${year} at ${
    hours < 10 ? "0" + hours : hours
  }:${minutes < 10 ? "0" + minutes : minutes}`;
  const formatedHours = `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }`;
  // const formatedDate = `${month}/${date}/${year} at ${
  //   hours > 12 ? hours - 12 : hours
  // }:${minutes < 10 ? '0' + minutes : minutes} ${hours > 12 ? 'PM' : 'AM'}`
  if (logTime) {
    const diffTime = todayDate - fullDate;
    const diffDay = Math.floor(diffTime / 86400000); // days
    const diffHrs = Math.floor((diffTime % 86400000) / 3600000); // hours
    const diffMins = Math.round(((diffTime % 86400000) % 3600000) / 60000); // minutes
    if (diffDay >= 1) return `${date} ${months[month - 1]}`;
    if (diffHrs === 0 && diffMins < 5) return "Just now";
    else return formatedHours;
  }
  if (justTime) return formatedHours;
  else return formatedDate;
  // return fullDate.toLocaleString([], { hour: '2-digit', minute: '2-digit' })
};

export const formatDateAtTime = date => {
  const d = new Date(date);
  const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
  const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
  const ho = new Intl.DateTimeFormat("en", {
    hourCycle: "h23",
    hour: "numeric"
  }).format(d);
  const mi = new Intl.DateTimeFormat("en", {
    hourCycle: "h23",
    minute: "2-digit"
  }).format(d);
  return `${da}-${mo}-${ye} @ ${ho}:${mi}`;
};

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  if (!array) return;
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
export const sortedItemsByDate = timeline => {
  const sorted = new Map();
  const todayFullDate = new Date();
  const todayMonth = todayFullDate.getMonth();
  const todayDate = todayFullDate.getDate();

  // Commented out cause we sort it before grouping in StackTimeline.jsx
  timeline.sort((a, b) => {
    return new Date(a.postedAt) - new Date(b.postedAt);
  });

  const arrayToGroupedObjects = timeline.reduce((acc, value) => {
    if (
      acc.length === 0 ||
      acc[acc.length - 1][0].groupRef !== value.groupRef ||
      value.groupRef === null ||
      value.groupRef === "null"
    ) {
      return [...acc, [value]];
    } else {
      acc[acc.length - 1].push(value);
      return acc;
    }
  }, []);

  const groupedTimeline = arrayToGroupedObjects.map(arr => {
    if (
      arr.length === 1 &&
      (arr[0].groupRef === null || arr[0].groupRef === "null")
    ) {
      return arr[0];
    } else {
      return {
        actionRef: arr[0].actionRef,
        groupRef: arr[0].groupRef,
        ownerInfo: arr[0].ownerInfo,
        postedAt: arr[0].postedAt,
        contextVersion: arr[0].contextVersion,
        resourceVersion: arr[0].resourceVersion,
        group: arr
      };
    }
  });

  groupedTimeline.forEach(item => {
    // if (filterActionRefs.includes(item.actionRef)) return
    const fullDate = new Date(item.postedAt);
    const month = fullDate.getMonth();
    const date = fullDate.getDate();
    const day = fullDate.getDay();

    const dateToShow =
      todayMonth === month && todayDate === date
        ? "Today"
        : todayMonth === month && todayDate - date === 1
        ? "Yesterday"
        : `${days[day]}, ${months[month]} ${date}`;

    if (sorted.has(dateToShow)) {
      const data = sorted.get(dateToShow);
      data.push(item);
      sorted.set(dateToShow, data);
    } else sorted.set(dateToShow, [item]);
  });

  return sorted;
};

export const getUserRole = (membership, decryptedProfileDetails) => {
  const members = membership?.dataRoom?.members?.items;
  const publicSigningKey = decryptedProfileDetails?.publicSigningKey;
  if (Array.isArray(members) && members.length && publicSigningKey) {
    const currentUser = members.find(
      member => publicSigningKey === member.memberPublicSigningKey
    );
    if (currentUser) {
      return currentUser.state?.role || null;
    }
  }
  return null;
};

export const isArchived = membership => {
  if (!membership?.dataRoom) return null;
  return membership.dataRoom.state.status === "ARCHIVED";
};

export const downloadTxtFile = (backupPhrase, userEmail) => {
  const element = document.createElement("a");
  const file = new Blob(generateText(backupPhrase, userEmail), {
    type: "text/plain"
  });
  element.href = URL.createObjectURL(file);
  element.download = "Akord-backup-phrase.txt";
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

const generateText = (backupPhrase, userEmail) => {
  const phrase = backupPhrase
    .map((word, index) => {
      const tabOrBrk = (index + 1) % 3 === 0 ? `\n` : `\t\t`;
      const space = index + 1 <= 9 ? " " : "";
      return `${space}${index + 1}. ${word}${tabOrBrk}`;
    })
    .join("");
  const text = `AKORD\n\nSAVE YOUR BACKUP PHRASE\nKeep these backup phrase somewhere safe but accessible.\n\n${phrase}\n\n(${userEmail})\n\n*Phrase was generated on ${new Date()}`;
  return [text];
};

export const copyToClipboard = (dataToCopy, setCopy) => {
  const el = document.createElement("textarea");
  el.value = dataToCopy;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  setCopy(true);
  setTimeout(() => {
    setCopy(false);
  }, 1000);
};

//reformat dataRoom.state to handle sorting
export const roomsDetailsForSorting = decryptedMemberships =>
  decryptedMemberships?.map(membership => {
    return {
      ...membership.dataRoom.state,
      dataRoomId: membership.dataRoomId,
      updatedAt: membership.updatedAt
    };
  });

export const isRoomsArchived = decryptedMemberships => {
  let active = 0;
  let archived = 0;
  decryptedMemberships?.map(membership => {
    if (membership.dataRoom.status === "ARCHIVED") archived += 1;
    else active += 1;
  });
  if (active > 0) return false;
  else if (archived > 0 && active === 0) return true;
  else return false;
};

export const getDataRoomStatusFromMembership = membership => {
  switch (membership?.dataRoom?.status) {
    case "ACTIVE":
      return "active";
    case "ARCHIVED":
      return "archived";
    case "DELETED":
      return "deleted";
    default:
      return null;
  }
};

export const getDataRoomStatus = pathname => {
  const matchProfile = matchPath(pathname, {
    path: `/vaults/:status?`
  });
  return matchProfile?.params?.status || null;
};

export const getDataRoomId = pathname => {
  const matchProfile = matchPath(pathname, {
    path: `/vaults/:status?/:dataRoomId?`
  });
  return matchProfile?.params?.dataRoomId || null;
};

export const getFolderId = pathname => {
  const matchProfile = matchPath(pathname, {
    path: `/vaults/:status/:dataRoomId/:page/folders/:folderId`
  });
  return matchProfile?.params?.folderId || null;
};

export const getStackId = pathname => {
  const matchProfile = matchPath(pathname, {
    path: `/vaults/:status/:dataRoomId/:roomView/stack/:stackId`
  });
  return matchProfile?.params?.stackId || null;
};

export const getNoteId = pathname => {
  const matchProfile = matchPath(pathname, {
    path: `/vaults/:status/:dataRoomId/:roomView/note/:noteId`
  });
  return matchProfile?.params?.noteId || null;
};

export const getRevokedFolderId = pathname => {
  const matchProfile = matchPath(pathname, {
    path: `/vaults/:status/:dataRoomId/revoked-files/folders/:folderId`
  });
  return matchProfile?.params?.folderId || null;
};

// Collect all parent folders for folder's titles breadcrumbs navigation
export const getAllFoldersRecursively = (decryptedFolders, currentFolder) => {
  let allFolders = [];

  const parentFoldersRecursive = childFolder => {
    const parentFolder = decryptedFolders.filter(
      folder => folder.id === childFolder.folderId
    )[0];
    if (parentFolder) {
      allFolders.push(parentFolder);
      parentFoldersRecursive(parentFolder);
    } else return;
  };
  parentFoldersRecursive(currentFolder);
  return allFolders.reverse();
};

export const getFileIcon = item => {
  const fileType = item?.files ? item.files[0].fileType : "";

  if (item?.files?.length > 1) return StackIcon;
  if (fileType?.includes("image")) return ImageIcon;
  if (fileType?.includes("video")) return VideoIcon;
  if (fileType?.includes("audio")) return AudioIcon;
  else if (item?.type === "note") return NoteIcon;
  else return DocumentIcon;
};

export const getUploadFileIcon = file => {
  const fileType = file?.type;

  if (fileType?.includes("image")) return ImageIcon;
  if (fileType?.includes("video")) return VideoIcon;
  if (fileType?.includes("audio")) return AudioIcon;
  else return DocumentIcon;
};

export const getTotalUploadSize = fileList => {
  const fileArray = Array.from(fileList);
  return fileArray?.reduce((acc, file) => acc + file.size, 0);
};

export const isActionFiltered = action => {
  return (
    filterActionRefs.includes(action.actionRef) ||
    filterActions.find(
      item => action.actionRef === item.actionRef && item.filter(action)
    )
  );
};
