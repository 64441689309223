import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchBox from "../../components/search/SearchBox";
import SearchResults from "../../components/search/SearchResults";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useSearchContext } from "../../contexts/SearchContextProvider";

const useStyles = makeStyles({
  fab: {
    position: "fixed",
    right: "16px",
    bottom: "76px"
  },
  headerBox: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between"
  }
});

function ShowSearchResults() {
  const { isMobile } = useGlobalContext();
  const { searchResults, cachedSearchResults } = useSearchContext();
  const location = useLocation();
  const [numberOfResults, setNumberOfResults] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    if (Array.isArray(searchResults)) {
      setNumberOfResults(searchResults.length);
    } else {
      setNumberOfResults(getCachedResults().length);
    }
  }, [searchResults]);

  const getCachedResults = () => {
    const urlParams = new URLSearchParams(location.search);
    const term = urlParams.get("term");
    if (term) {
      return cachedSearchResults[term] || [];
    }
    return [];
  };

  return (
    <>
      <Box
        position="fixed"
        width={isMobile ? "100%" : "calc(100% - 250px)"}
        zIndex="1"
      >
        <Box mx={5} mt={4} mb={!isMobile ? 1 : 3} className={classes.headerBox}>
          {!isMobile && (
            <Box width="20%">
              <Typography variant="h2">
                {numberOfResults === 1
                  ? `${numberOfResults} result`
                  : `${numberOfResults} results`}
              </Typography>
            </Box>
          )}
          <Box flexGrow="1" display="flex" justifyContent="flex-end">
            <SearchBox
              showRecentSearchTerm={true}
              forceActive={isMobile}
            ></SearchBox>
          </Box>
          {!isMobile && (
            <Box marginLeft="15px">
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => history.push("/create-vault")}
              >
                New vault
              </Button>
            </Box>
          )}
        </Box>
        {isMobile && (
          <Box mx={5} mt={4} mb={3}>
            <Typography variant="h2">
              {numberOfResults === 1
                ? `${numberOfResults} result`
                : `${numberOfResults} results`}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        flex="1"
        height={isMobile ? "calc(100% - 126px)" : "calc(100% - 100px)"}
        position="absolute"
        top="100px"
        width={isMobile ? "100%" : "calc(100% - 250px)"}
      >
        <SearchResults></SearchResults>
      </Box>
    </>
  );
}

export default ShowSearchResults;
