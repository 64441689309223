import React from "react";
import { Box, Typography, Divider, IconButton } from "@mui/material";
import { ChevronLeftIcon, CloseIcon } from "@akord/addon-icons";
import { withRouter } from "react-router";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import MoreActionButton from "../MoreActionButton";
import MenuNotifications from "../MoreMenu/MenuNotifications";
import ReferralBanner from "../ReferralBanner";
import TransactionSpinner from "../TransactionSpinner";

function SupportScreenWrapper({ children, ...rest }) {
  const {
    title,
    subtitle,
    route,
    hideChevron,
    history,
    showMenu,
    hideElements = false,
    showCloseButton = false,
    showSaveButton = false,
    referral,
    spaceBetween = false
  } = rest;
  const { isMobile, darkMode, txSpinner } = useGlobalContext();

  const [menuActive, setMenuActive] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
    setMenuActive(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuActive(false);
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {showCloseButton && (
        <Box
          display="flex"
          right="0"
          alignSelf="flex-end"
          p={4}
          zIndex="2"
          mb="-56px"
        >
          <IconButton onClick={() => history.push(route)} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <Box
        px={isMobile ? 5 : 4}
        width="100%"
        maxWidth="576px"
        height={!isMobile ? "100%" : spaceBetween ? "100%" : "auto"}
        alignSelf="center"
      >
        {!hideElements && (
          <Box mb={7}>
            <Box mt={4} mb={3} width="100%">
              <Box
                mb={1}
                display="flex"
                alignItems={showSaveButton ? "flex-start" : "center"}
                justifyContent="space-between"
                height="31px"
              >
                <Box flexDirection="row" display="flex">
                  {!hideChevron && (
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={() =>
                        route ? history.push(route) : history.goBack()
                      }
                      size="large"
                    >
                      <ChevronLeftIcon />
                    </IconButton>
                  )}
                  <Typography
                    variant="h2"
                    color="text.primary"
                    style={{ whiteSpace: "pre", paddingTop: "3px" }}
                  >
                    {title}
                  </Typography>
                </Box>
                {showMenu && (
                  <Box style={{ marginLeft: "auto" }}>
                    <MoreActionButton
                      horizontalButton
                      handleClick={e => {
                        handleMenu(e);
                      }}
                      menuActive={menuActive}
                    />
                  </Box>
                )}
                {!isMobile && referral && <ReferralBanner />}
                {txSpinner && !referral && (
                  <TransactionSpinner isMobile={isMobile} darkMode={darkMode} />
                )}
              </Box>
              {subtitle && (
                <Typography variant="body2" color="text.secondary">
                  {subtitle}
                </Typography>
              )}
              {isMobile && referral && <ReferralBanner />}
            </Box>
            <Divider />
          </Box>
        )}
        {children}
      </Box>
      <MenuNotifications
        anchorEl={anchorEl}
        openMenu={openMenu}
        handleMenuClose={handleMenuClose}
      />
    </Box>
  );
}

export default withRouter(SupportScreenWrapper);
