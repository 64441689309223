import React from "react";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import Pagination from "./Pagination";

function AssetsPagination({ bottomPosition = false }) {
  const {
    pages,
    currentPage,
    onGoToNextPage,
    onGoToPreviousPage,
    onChangePage
  } = useAssetsContext();

  return (
    <Pagination
      bottomPosition={bottomPosition}
      pages={pages}
      currentPage={currentPage}
      onGoToNextPage={onGoToNextPage}
      onGoToPreviousPage={onGoToPreviousPage}
      onChangePage={onChangePage}
    ></Pagination>
  );
}

export default AssetsPagination;
