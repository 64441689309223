import {
  AddMemberIcon,
  ArchiveIcon,
  CloseInCircleIcon,
  FileRestoreIcon,
  FolderOutlineIcon,
  FolderRestoreIcon,
  FolderRevokeIcon,
  InvitationAcceptIcon,
  KeyIcon,
  MinusInCircleIcon,
  MoveIcon,
  NoteIcon,
  PaperPlaneIcon,
  PenIcon,
  RestoreIcon,
  RevokedFileIcon,
  StackIcon,
  TickInCircleIcon,
  TrashIcon,
  UploadIcon,
  VaultIcon,
  VaultLeaveIcon
} from "@akord/addon-icons";

export const operationDataWithTitleData = {
  DATAROOM_CREATE: {
    title: "Vault created",
    icon: VaultIcon
  },
  STACK_CREATE: {
    icon: UploadIcon
  },
  STACK_UPLOAD_REVISION: {
    icon: StackIcon
  },
  STACK_RENAME: {
    title: "File renamed",
    icon: PenIcon
  },
  DATAROOM_RENAME: {
    title: "Vault renamed",
    icon: PenIcon
  },
  MEMBERSHIP_INVITE_NEW_USER: {
    title: "New invites sent",
    icon: AddMemberIcon
  },
  MEMBERSHIP_INVITE: {
    title: "New invites sent",
    icon: AddMemberIcon
  },
  MEMBERSHIP_ACCEPT: {
    title: "Terms accepted",
    icon: InvitationAcceptIcon
  },
  MEMBERSHIP_REJECT: {
    title: "Invitation rejected",
    icon: CloseInCircleIcon
  },
  MEMBERSHIP_REVOKE: {
    title: "Invitation revoked",
    icon: CloseInCircleIcon
  },
  MEMBERSHIP_LEAVE: {
    title: "Left the vault",
    icon: VaultLeaveIcon
  },
  MEMBERSHIP_INVITE_NEW_USER_RESEND: {
    title: "Invitation resent",
    icon: PaperPlaneIcon
  },
  MEMBERSHIP_INVITE_RESEND: {
    title: "Invitation resent",
    icon: PaperPlaneIcon
  },
  MEMBERSHIP_CHANGE_ACCESS: {
    // title: 'Membership access changed',
    icon: KeyIcon
  },
  MEMBERSHIP_RESTORE_ACCESS: {
    title: "Access restored",
    icon: KeyIcon
  },
  USER_VERIFIED: {
    icon: InvitationAcceptIcon
  },
  MEMBERSHIP_CONFIRM: {
    title: "Access confirmed",
    icon: TickInCircleIcon
  },
  DATAROOM_ARCHIVE: {
    title: "Vault archived",
    icon: ArchiveIcon
  },
  DATAROOM_RESTORE: {
    title: "Vault restored",
    icon: RestoreIcon
  },
  STACK_REVOKE: {
    title: "File revoked",
    icon: RevokedFileIcon
  },
  STACK_RESTORE: {
    title: "File restored",
    icon: FileRestoreIcon
  },
  STACK_DELETE: {
    title: "File deleted",
    icon: TrashIcon
  },
  FOLDER_CREATE: {
    title: "Folder created",
    icon: FolderOutlineIcon
  },
  FOLDER_RENAME: {
    title: "Folder renamed",
    icon: PenIcon
  },
  STACK_MOVE: {
    title: "File was moved",
    icon: MoveIcon
  },
  FOLDER_MOVE: {
    title: "Folder was moved",
    icon: MoveIcon
  },
  FOLDER_RESTORE: {
    title: "Folder restored",
    icon: FolderRestoreIcon
  },
  FOLDER_REVOKE: {
    title: "Folder revoked",
    icon: FolderRevokeIcon
  },
  FOLDER_DELETE: {
    title: "Folder deleted",
    icon: TrashIcon
  },
  FOLDER_REMOVE: {
    title: "Folder deleted",
    icon: TrashIcon
  },
  STACK_REMOVE: {
    title: "File removed",
    icon: MinusInCircleIcon
  },
  NOTE_CREATE_REVISION: {
    title: "Note created",
    icon: NoteIcon
  },
  NOTE_CREATE: {
    title: "Note created",
    icon: NoteIcon
  },
  NOTE_RESTORE: {
    title: "Note restored",
    icon: FileRestoreIcon
  },
  NOTE_REVOKE: {
    title: "Note revoked",
    icon: RevokedFileIcon
  },
  NOTE_MOVE: {
    title: "Note was moved",
    icon: MoveIcon
  },
  NOTE_REMOVE: {
    title: "Note deleted",
    icon: MinusInCircleIcon
  }
};
