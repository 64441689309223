import API, { graphqlOperation } from "@aws-amplify/api";
import { Auth, Storage } from "aws-amplify";
import axios from "axios";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import saveAs from "save-as";
import { v4 as uuidv4 } from "uuid";
import LedgerWrapper from "../crypto/ledger-wrapper";
import * as mutations from "../graphql/mutations";
import EncryptedChunkProcessor from "./chunked-file-io";
import PublicChunkProcessor from "./chunked-file-io-public";
import { createThumbnail } from "./thumbnail";

const BATCH_TRANSACTION_LIMIT = 50;

let cancel;

const getExtension = filename => {
  const parts = filename.split(".");
  return parts[parts.length - 1];
};

const getNewName = (fileName, allExistingNames, startIndex) => {
  let updatedFileName;
  //first run add ` copy`
  if (startIndex === 1) updatedFileName = fileName.replace(/(\.[\w\d_-]+)$/i, " copy$1");
  //second run add ` 2` to `copy` -> `copy 2`
  else if (startIndex === 2) updatedFileName = fileName.replace(/(\.[\w\d_-]+)$/i, ` ${startIndex}$1`);
  //after changer `2` to next number -> `3` , `4` etc
  else updatedFileName = fileName.replace(/(\d+)(?!.*\d)/, `${startIndex}`);
  if (allExistingNames.indexOf(updatedFileName) !== -1) {
    return getNewName(updatedFileName, allExistingNames, startIndex + 1);
  } else {
    return updatedFileName;
  }
};

export const noteUpload = async (currentMembership, ledgerWrapper, noteText, folderId = null, prevHash = null, noteTitle) => {
  const body = prevHash
    ? {
        prevHash: prevHash
      }
    : {
        dataRoomId: currentMembership.dataRoomId,
        folderId: folderId
      };
  const stringifiedContent = JSON.stringify(noteText);
  const { encodedTransaction } = await ledgerWrapper.dispatch(prevHash ? "NOTE_CREATE_REVISION" : "NOTE_CREATE", body, {
    revision: {
      title: noteTitle,
      content: stringifiedContent,
      size: new Blob([stringifiedContent]).size
    }
  });

  // post ledger transaction mutation
  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const noteRevoke = async (ledgerWrapper, prevHash) => {
  const { encodedTransaction } = await ledgerWrapper.dispatch("NOTE_REVOKE", {
    prevHash: prevHash
  });

  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const noteRestore = async (ledgerWrapper, prevHash) => {
  const { encodedTransaction } = await ledgerWrapper.dispatch("NOTE_RESTORE", {
    prevHash: prevHash
  });

  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const fileUpload = (
  currentMembership,
  ledgerWrapper,
  files,
  allExistingNames,
  filesWithAction,
  onShowLoader,
  onProgress,
  onAxiosError,
  onFilesNumber,
  onSnackbarToShow,
  folderId = null,
  onUploadHook,
  onTxSpinner
) => {
  const returnUpload = { cancel: () => null };
  let fileList = [];
  for (let i = 0; i < files.length; i++) {
    fileList.push(files[i]);
  }
  if (onFilesNumber) onFilesNumber(files.length);

  const groupRef = uuidv4();
  const multipleUpload = fileList.map(async file => {
    //some files do not have file type, so we get extension from the title
    const fileType = file.type ? file.type : getExtension(file.name);

    //array of existing files to compare with in order to append `copy`
    const needsRename = filesWithAction.find(fileWithAction => fileWithAction.name === file.name);

    let fileName;
    if (!needsRename) fileName = file.name;
    else {
      const startIndex = 1;
      fileName = getNewName(file.name, allExistingNames, startIndex);
    }

    const thumbnail = await createThumbnail(file);

    const { uploadPayload, encodedTransaction } = await ledgerWrapper.dispatch(
      "STACK_CREATE",
      {
        dataRoomId: currentMembership.dataRoomId,
        groupRef: groupRef,
        folderId: folderId
      },
      {
        title: fileName,
        file: {
          title: fileName,
          fileType: fileType,
          file: file,
          thumbnail: thumbnail,
          size: file.size
        }
      },
      {
        onProgress: onProgress,
        onUploader: onUploadHook
      }
    );
    // TODO: upload thumbnail here if not null
    if (uploadPayload.thumbnail) await Storage.put(uploadPayload.thumbnail.resourceKey, uploadPayload.thumbnail.encryptedFile);

    // post ledger transaction mutation
    await API.graphql(
      graphqlOperation(mutations.postLedgerTransaction, {
        transactions: [encodedTransaction]
      })
    );
    if (onProgress && onFilesNumber) {
      onFilesNumber(length => length - 1);
      setTimeout(() => {
        onProgress(0);
      }, 500);
    }
  });

  Promise.all(multipleUpload)
    .then(() => {
      //hack for uploading first file in the stack
      if (onShowLoader && onFilesNumber)
        setTimeout(() => {
          onShowLoader(false);
          onFilesNumber(0);
        }, 100);
      if (!currentMembership?.dataRoom?.state?.permanentStorage) onSnackbarToShow("fileUpload", files.length > 1 ? files.length : null);
      else onSnackbarToShow("uploadPending");
    })
    .catch(err => {
      console.log("Upload Error:", err);
      onAxiosError("Uploading canceled.");
      setTimeout(() => {
        onAxiosError();
      }, 3000);
      onTxSpinner(false);
      onShowLoader(false);
      onFilesNumber(0);
      onProgress(0);
    });

  return returnUpload;
};

export const fileDownload = (
  stackData,
  encrypter,
  onShowLoader,
  onProgress,
  onAxiosError,
  index = 0, //these two fields for downloading from a viewer
  onSnackbar,
  onUploadHook,
  isVaultPublic = false
) => {
  onShowLoader && onShowLoader(true);
  const returnDownload = { cancel: () => null };
  // three options: downloading from fileViewer - we have original Index if stack
  // from drawer then we dont have originalIndex
  // single file - always [0]

  const downloadFile = async () => {
    let correctFileToDownload;
    if (stackData.files.length > 1) {
      correctFileToDownload = stackData.files.filter(file => file.originalIndex === index)[0];
      if (!correctFileToDownload) correctFileToDownload = stackData.files[stackData.files.length - 1];
    } else correctFileToDownload = stackData.files[0];
    //add a version number to file from a stack
    const createVersionFileNameForStack = () => {
      const splitOriginalTitle = stackData.title.split(".");
      splitOriginalTitle.pop();
      const versionedTitle = index === 0 ? splitOriginalTitle.join(".") : splitOriginalTitle.join(".") + ` (v${index + 1})`;
      const originalTitleArray = correctFileToDownload.title.split(".");
      const originalExtension = originalTitleArray.pop();
      return versionedTitle + "." + originalExtension;
    };

    const createVersionFileNameForFile = () => {
      const splitOriginalTitle = stackData.title.split(".");
      splitOriginalTitle.pop();
      const versionedTitle =
        stackData.resourceVersion === 1 || !stackData.resourceVersion
          ? splitOriginalTitle.join(".")
          : splitOriginalTitle.join(".") + `(v${stackData.resourceVersion})`;
      const originalTitleArray = correctFileToDownload.title.split(".");
      const originalExtension = originalTitleArray[originalTitleArray.length - 1];
      return versionedTitle + "." + originalExtension;
    };
    // if encrypter?.publicKey we assume the file is encrypted
    let fileToDownload;
    if (!isVaultPublic) {
      const encChunkProcessor = new EncryptedChunkProcessor(encrypter, onProgress, cancel, onSnackbar);
      if (onShowLoader) {
        onUploadHook(encChunkProcessor);
      }
      fileToDownload = await encChunkProcessor.decryptedChunkedDownload(
        correctFileToDownload.size,
        correctFileToDownload.resourceUrl,
        correctFileToDownload.numberOfChunks,
        correctFileToDownload.fileType,
        onShowLoader
      );
      console.log(encrypter);
    } else {
      const publicChunkProcessor = new PublicChunkProcessor(onProgress, cancel, onSnackbar);
      if (onShowLoader) {
        onUploadHook(publicChunkProcessor);
      }
      fileToDownload = await publicChunkProcessor.publicChunkedDownload(
        correctFileToDownload.size,
        correctFileToDownload.resourceUrl,
        correctFileToDownload.numberOfChunks,
        correctFileToDownload.fileType,
        onShowLoader
      );
    }

    let a = document.createElement("a");
    // a.download =
    //   stackData.resourceVersion === 1 ? stackData.title : createVerionFileName()
    a.download = stackData.files.length === 1 ? createVersionFileNameForFile() : createVersionFileNameForStack();
    // a.download = originalTitle ? originalTitle : stackData.title
    a.href = window.URL.createObjectURL(fileToDownload);
    a.click();
  };

  downloadFile()
    .then(() => {
      if (onShowLoader)
        setTimeout(() => {
          onShowLoader(false);
          onProgress(0);
        }, 500);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        console.log("Downloading canceled", err);
        onAxiosError("Downloading canceled.");
        setTimeout(() => {
          onAxiosError();
        }, 3000);
        onShowLoader(false);
        onProgress(0);
      } else {
        console.log("Error:", err);
        if (onShowLoader) {
          onAxiosError(err.message);
          setTimeout(() => {
            onAxiosError();
          }, 3000);
          onShowLoader(false);
          onProgress(0);
        }
        // throw new Error(err)
      }
    });
  return returnDownload;
};

export const fileBatchDownload = async (
  stackData,
  encrypter,
  index = 0 //these two fields for downloading from a viewer
) => {
  // three options: downloading from fileViewer - we have original Index if stack
  // from drawer then we dont have originalIndex
  // single file - always [0]

  let correctFileToDownload;
  if (stackData.files.length > 1) {
    correctFileToDownload = stackData.files.filter(file => file.originalIndex === index)[0];
    if (!correctFileToDownload) correctFileToDownload = stackData.files[stackData.files.length - 1];
  } else correctFileToDownload = stackData.files[0];

  let fileToDownload;
  if (encrypter?.publicKey || encrypter?.encAccessKey) {
    const encChunkProcessor = new EncryptedChunkProcessor(encrypter, null, cancel, null);

    fileToDownload = await encChunkProcessor.decryptedChunkedDownload(
      correctFileToDownload.size,
      correctFileToDownload.resourceUrl,
      correctFileToDownload.numberOfChunks,
      correctFileToDownload.fileType,
      null
    );
  } else {
    const publicChunkProcessor = new PublicChunkProcessor(null, cancel, null);

    fileToDownload = await publicChunkProcessor.publicChunkedDownload(
      correctFileToDownload.size,
      correctFileToDownload.resourceUrl,
      correctFileToDownload.numberOfChunks,
      correctFileToDownload.fileType,
      null
    );
  }
  return window.URL.createObjectURL(fileToDownload);
};

export const batchDownload = async (batchItems, decryptedStacks, decryptedFolders, currentMembershipTitle, encrypter) => {
  const decryptedStacksMap = decryptedStacks.reduce((acc, stack) => acc.set(stack.hash, stack), new Map());
  const decryptedFoldersMap = decryptedFolders.reduce((acc, stack) => acc.set(stack.hash, stack), new Map());

  const archiveName = currentMembershipTitle + " files.zip";
  let zip = new JSZip();

  const folderCreate = async (folder, archive) => {
    const folderInZip = archive.folder(folder.title);

    const filesInFolder = decryptedStacks.filter(stack => stack.folderId === folder.id);

    for (let i = 0; i < filesInFolder.length; i++) {
      const url = await fileBatchDownload(filesInFolder[i], encrypter);
      const file = await JSZipUtils.getBinaryContent(url);
      folderInZip.file(filesInFolder[i].title, file, { binary: true });
    }
    const foldersInFolder = decryptedFolders.filter(folderInFolder => folderInFolder.folderId === folder.id);
    if (foldersInFolder.length > 0) {
      for (let i = 0; i < foldersInFolder.length; i++) {
        folderCreate(foldersInFolder[i], folderInZip);
      }
    }
  };

  for (const [key, value] of batchItems) {
    if (value === "stack") {
      const stack = decryptedStacksMap.get(key);
      const url = await fileBatchDownload(stack, encrypter);
      const file = await JSZipUtils.getBinaryContent(url);
      zip.file(stack.title, file, { binary: true });
    } else if (value === "folder") {
      const folder = decryptedFoldersMap.get(key);
      await folderCreate(folder, zip);
    }
  }
  zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, archiveName);
  });
};

export const fileView = async (encrypter, file) => {
  try {
    const encChunkProcessor = new EncryptedChunkProcessor(encrypter, null);
    const fileToShow = encChunkProcessor.decryptedChunkedDownload(file.size, file.resourceUrl, file.numberOfChunks, file.fileType, false);

    return fileToShow;
    // const link = document.createElement('a')
    // link.href = window.URL.createObjectURL(fileToShow)
    // link.download = stackData.title
    // window.open(link)
  } catch (err) {
    throw new Error(err);
  }
};

export const publicFileView = async file => {
  try {
    let isAuthenticatedUser = false;
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) isAuthenticatedUser = true;
    } catch (err) {
      isAuthenticatedUser = false;
    }

    let fileToShow;
    const chunkProcessor = new PublicChunkProcessor(null);
    if (isAuthenticatedUser) {
      fileToShow = await chunkProcessor.publicChunkedDownload(file.size, file.resourceUrl, file.numberOfChunks, file.fileType);
    } else {
      fileToShow = await chunkProcessor.noAuthPublicChunkedDownload(file.chunks, file.numberOfChunks, file.fileType);
    }
    return fileToShow;
  } catch (err) {
    console.log(err);
    // throw new Error(err);
  }
};

export const fileRevision = (
  ledgerWrapper,
  stackData,
  files,
  onShowLoader,
  onProgress,
  onAxiosError,
  onSnackbarToShow,
  isLegacyVault,
  onUploadHook
) => {
  const returnRevision = { cancel: () => null };
  const file = files[0];

  //we need to make sure we have right file extension for the new version
  //of the file but keep the original name
  // const oldTitle = stackData.title.split('.').slice(0, -1).join('.')
  const newFileExtension = file.name.split(".").slice(-1)[0];
  // const oldTitleWithNewExtension = oldTitle.concat('.', newFileExtension)

  // const fileType = file.type ? file.type : getExtension(file.name)

  const uploadRevision = async () => {
    const thumbnail = await createThumbnail(file);

    const { encodedTransaction, uploadPayload } = await ledgerWrapper.dispatch(
      "STACK_UPLOAD_REVISION",
      { prevHash: stackData.hash },
      {
        file: {
          title: file.name,
          file: file,
          fileType: file.type || newFileExtension,
          thumbnail: thumbnail,
          size: file.size
        }
      },
      {
        onProgress: onProgress,
        onUploader: onUploadHook
      }
    );

    // upload thumbnail here if not null
    if (uploadPayload.thumbnail) await Storage.put(uploadPayload.thumbnail.resourceKey, uploadPayload.thumbnail.encryptedFile);

    // post ledger transaction mutation
    await API.graphql(
      graphqlOperation(mutations.postLedgerTransaction, {
        transactions: [encodedTransaction]
      })
    );
  };

  uploadRevision()
    .then(() => {
      onShowLoader(false);
      onProgress(0);
      onSnackbarToShow(isLegacyVault ? "versionUpload" : "uploadPending");
    })
    .catch(err => {
      console.log("Uploading Error:", err);
      onAxiosError("Uploading canceled.");
      setTimeout(() => {
        onAxiosError();
      }, 3000);
      onShowLoader(false);
      onProgress(0);
      return { error: err };
    });

  return returnRevision;
};

export const stackRename = async (ledgerWrapper, fileData) => {
  // append extension from the previous file
  const title = fileData.title.concat(".", fileData.extension);

  const { encodedTransaction } = await ledgerWrapper.dispatch("STACK_RENAME", { prevHash: fileData.hash }, { title: title });

  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const dataRoomRename = async (ledgerWrapper, roomData) => {
  const { encodedTransaction } = await ledgerWrapper.dispatch("DATAROOM_RENAME", { prevHash: roomData.hash }, { title: roomData.title });

  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const dataRoomArchive = async (ledgerWrapper, txHash) => {
  const { encodedTransaction } = await ledgerWrapper.dispatch("DATAROOM_ARCHIVE", { prevHash: txHash });

  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const dataRoomRestore = async (ledgerWrapper, txHash) => {
  const { encodedTransaction } = await ledgerWrapper.dispatch("DATAROOM_RESTORE", { prevHash: txHash });

  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const dataRoomDelete = async (ledgerWrapper, txHash) => {
  const { encodedTransaction } = await ledgerWrapper.dispatch("DATAROOM_DELETE", { prevHash: txHash });

  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const stackRevoke = async (ledgerWrapper, txHash) => {
  const groupRef = uuidv4();
  const { encodedTransaction } = await ledgerWrapper.dispatch("STACK_REVOKE", {
    prevHash: txHash,
    groupRef: groupRef
  });

  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const stackRestore = async (ledgerWrapper, data) => {
  const groupRef = uuidv4();
  const { encodedTransaction } = await ledgerWrapper.dispatch("STACK_RESTORE", {
    prevHash: data.hash,
    folderId: data.folderId,
    groupRef: groupRef
  });
  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const stackDelete = async (ledgerWrapper, txHash) => {
  const { encodedTransaction } = await ledgerWrapper.dispatch("STACK_DELETE", {
    prevHash: txHash
  });
  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const stackRemove = async (ledgerWrapper, txHash) => {
  const { encodedTransaction } = await ledgerWrapper.dispatch("STACK_REMOVE", {
    prevHash: txHash
  });
  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const dataRoomCreate = async (wallet, formData, decryptedProfileDetails) => {
  const ledgerWrapper = new LedgerWrapper(wallet);
  const { encodedTransaction, additionalData } = await ledgerWrapper.dispatch(
    "DATAROOM_CREATE",
    {},
    {
      title: formData.roomTitle,
      isPublic: formData.isPublic,
      termsOfAccess: {
        termsOfAccess: formData.roomTerms,
        hasTerms: formData.hasTerms
      }
    }
  );

  const resultDataRoom = await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );

  const resultMembership = await ledgerWrapper.dispatch(
    "MEMBERSHIP_OWNER",
    { dataRoomId: resultDataRoom.data.postLedgerTransaction[0].dataRoomId },
    {
      memberDetails: {
        publicSigningKey: decryptedProfileDetails.publicSigningKey,
        fullName: decryptedProfileDetails.fullName,
        phone: decryptedProfileDetails.phone,
        avatarUrl: decryptedProfileDetails.avatarUrl
      },
      // We need keyRotate only if a vault is Encrypted
      keyRotate: formData.isPublic ? null : additionalData.newKeyPair
    }
  );

  if (resultMembership.uploadPayload) {
    await Storage.put(resultMembership.uploadPayload.resourceKey, resultMembership.uploadPayload.encryptedFile);
  }

  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [resultMembership.encodedTransaction]
    })
  );
  return resultDataRoom;
};

export const folderCreate = async (ledgerWrapper, data) => {
  const { encodedTransaction } = await ledgerWrapper.dispatch(
    "FOLDER_CREATE",
    {
      dataRoomId: data.dataRoomId,
      folderId: data.folderId
    },
    { title: data.title }
  );

  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const folderRename = async (ledgerWrapper, data) => {
  const { encodedTransaction } = await ledgerWrapper.dispatch("FOLDER_RENAME", { prevHash: data.hash }, { title: data.title });

  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const stackMove = async (ledgerWrapper, data) => {
  const groupRef = uuidv4();
  const { encodedTransaction } = await ledgerWrapper.dispatch("STACK_MOVE", {
    prevHash: data.hash,
    folderId: data.folderId,
    groupRef: groupRef
  });

  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const folderMove = async (ledgerWrapper, data) => {
  const groupRef = uuidv4();

  const { encodedTransaction } = await ledgerWrapper.dispatch("FOLDER_MOVE", {
    prevHash: data.hash,
    folderId: data.folderId,
    groupRef: groupRef
  });

  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const noteMove = async (ledgerWrapper, data) => {
  const groupRef = uuidv4();

  const { encodedTransaction } = await ledgerWrapper.dispatch("NOTE_MOVE", {
    prevHash: data.hash,
    folderId: data.folderId,
    groupRef: groupRef
  });

  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const noteRemove = async (ledgerWrapper, txHash) => {
  const { encodedTransaction } = await ledgerWrapper.dispatch("NOTE_REMOVE", {
    prevHash: txHash
  });
  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const folderRevoke = async (ledgerWrapper, hash) => {
  const { encodedTransaction } = await ledgerWrapper.dispatch("FOLDER_REVOKE", {
    prevHash: hash
  });

  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const folderRestore = async (ledgerWrapper, data) => {
  const { encodedTransaction } = await ledgerWrapper.dispatch("FOLDER_RESTORE", {
    prevHash: data.hash,
    folderId: data.folderId
  });

  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const folderDelete = async (ledgerWrapper, hash) => {
  const { encodedTransaction } = await ledgerWrapper.dispatch("FOLDER_DELETE", {
    prevHash: hash
  });

  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
};

export const createMemo = async (ledgerWrapper, dataRoomId, clientHash, message) => {
  const { encodedTransaction } = await ledgerWrapper.dispatch(
    "MEMO_WRITE",
    {
      dataRoomId: dataRoomId,
      clientHash: clientHash
    },
    { message: message }
  );

  await API.graphql(
    graphqlOperation(mutations.postLedgerLazyTransaction, {
      transactions: [encodedTransaction]
    })
  );
  return encodedTransaction;
};

export const addMemoReaction = async (ledgerWrapper, hash, profile, dataRoomId, reaction) => {
  const { encodedTransaction } = await ledgerWrapper.dispatch(
    "MEMO_ADD_REACTION",
    {
      dataRoomId: dataRoomId,
      prevHash: hash
    },
    {
      reaction: {
        publicSigningKey: profile.publicSigningKey,
        name: profile.fullName || profile.email,
        reaction: reaction,
        postedAt: new Date(),
        status: "ACTIVE"
      }
    }
  );

  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
  return encodedTransaction;
};

export const removeMemoReaction = async (ledgerWrapper, hash, dataRoomId, reactionData) => {
  const reaction = {
    ...reactionData,
    status: "DELETED"
  };
  const { encodedTransaction } = await ledgerWrapper.dispatch(
    "MEMO_REMOVE_REACTION",
    {
      dataRoomId: dataRoomId,
      prevHash: hash
    },
    {
      reaction: reaction
    }
  );
  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  );
  return encodedTransaction;
};

export const batchRevoke = async (ledgerWrapper, batchItems) => {
  const isMoreThanOne = batchItems.size > 1;
  const groupRef = isMoreThanOne ? uuidv4() : "null";
  let transactionsPromises = [];

  for (const [key, value] of batchItems) {
    transactionsPromises.push(transaction(key, getAssetActionRef(value, "revoke"), ledgerWrapper, groupRef));
  }

  await postLedgerBatchTransaction(transactionsPromises);
};

export const batchRestore = async (ledgerWrapper, data) => {
  const isMoreThanOne = data.batchItems.size > 1;
  const groupRef = isMoreThanOne ? uuidv4() : "null";
  let transactionsPromises = [];

  for (const [key, value] of data.batchItems) {
    transactionsPromises.push(transaction(key, getAssetActionRef(value, "restore"), ledgerWrapper, groupRef));
  }

  await postLedgerBatchTransaction(transactionsPromises);
};

export const batchRemove = async (ledgerWrapper, data) => {
  const isMoreThanOne = data.batchItems.size > 1;
  const groupRef = isMoreThanOne ? uuidv4() : "null";
  let transactionsPromises = [];

  for (const [key, value] of data.batchItems) {
    transactionsPromises.push(transaction(key, getAssetActionRef(value, "remove"), ledgerWrapper, groupRef));
  }

  await postLedgerBatchTransaction(transactionsPromises);
};

export const batchMove = async (ledgerWrapper, data) => {
  const isMoreThanOne = data.batchItems.size > 1;
  const groupRef = isMoreThanOne ? uuidv4() : "null";

  const transaction = async (hash, action) => {
    const { encodedTransaction } = await ledgerWrapper.dispatch(action, {
      prevHash: hash,
      folderId: data.folderId,
      groupRef: groupRef
    });
    return encodedTransaction;
  };

  let transactionsPromises = [];
  for (const [key, value] of data.batchItems) {
    transactionsPromises.push(transaction(key, getAssetActionRef(value, "move")));
  }

  const encodedTransactions = await Promise.all(transactionsPromises);
  await postLedgerBatchTransaction(encodedTransactions);
};

const getAssetActionRef = (value, action) => {
  return value === "stack"
    ? `STACK_${action.toUpperCase()}`
    : value === "folder"
    ? `FOLDER_${action.toUpperCase()}`
    : `NOTE_${action.toUpperCase()}`;
};

// Batch Transaction helper
const transaction = async (hash, action, ledgerWrapper, groupRef) => {
  const { encodedTransaction } = await ledgerWrapper.dispatch(action, {
    prevHash: hash,
    groupRef: groupRef
  });
  return encodedTransaction;
};

const chunkBatchTransactions = transactions => {
  return transactions.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / BATCH_TRANSACTION_LIMIT);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
};

const postLedgerBatchTransaction = async transactions => {
  const encodedTransactions = await Promise.all(transactions);
  const chunkedTransactions = chunkBatchTransactions(encodedTransactions);

  await Promise.all(
    chunkedTransactions.map(
      async encodedTransactionsChunk =>
        await API.graphql(
          graphqlOperation(mutations.postLedgerTransaction, {
            transactions: encodedTransactionsChunk
          })
        )
    )
  );
};
