import React from "react";
import { withRouter } from "react-router";
import makeStyles from "@mui/styles/makeStyles";
import {
  Typography,
  Grid,
  TextField,
  Box,
  InputAdornment
} from "@mui/material";
import { AccountBackupIcon } from "@akord/addon-icons";
import { useRecoverAccountContext } from "../../contexts/RecoverAccountContext";

const useStyles = makeStyles({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  rootWithIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  buttonGrid: {
    margin: 0
  },
  inputLabel: {
    fontFamily: [
      "Larsseit-Bold",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen-Sans",
      "Ubuntu",
      "Cantarell",
      "Helvetica Neue",
      "sans-serif"
    ].join(","),
    textAlign: "center",
    padding: "12px 14px",
    marginLeft: "-20px"
  },
  xsGrid: {
    flexBasis: "33%",
    maxWidth: "33%"
  },
  inputAdornment: {
    fontSize: "10px",
    alignItems: "flex-end"
  },
  inputAdornedStart: {
    paddingLeft: 8
  }
});

function RecoverPhrase({ location }) {
  const classes = useStyles();
  const { handlebackupPhrase, backupPhrase, formData, setFormData } =
    useRecoverAccountContext();
  // get code from a magic link
  React.useEffect(() => {
    async function verifyEmail() {
      const decoded = JSON.parse(atob(encoded));
      const { userName } = decoded;
      setFormData({ ...formData, code: code, userName: userName });
    }

    const urlParams = new URLSearchParams(location.search);
    const encoded = urlParams.get("data");
    const code = urlParams.get("code");
    if (encoded && code) {
      verifyEmail();
    }
  }, [location.search]);

  //to keep backup phrase in the form if BACK clicked
  const backupPhraseToShow =
    Object.keys(backupPhrase).length === 12
      ? Object.values(backupPhrase)
      : ["", "", "", "", "", "", "", "", "", "", "", ""];

  return (
    <>
      <Box mb="12px" className={classes.rootWithIcon}>
        <Typography variant="h1">Recover account</Typography>
        <AccountBackupIcon fontSize="large" color="primary" />
      </Box>

      <Box className={classes.root}>
        <Box mb="20px">
          <Typography variant="body2">
            Please enter your 12-word backup phrase to recover your account.
          </Typography>
        </Box>
        <Box>
          <Grid container justifyContent="space-between" spacing={2}>
            {backupPhraseToShow.map((word, index) => {
              return (
                <Grid item xs={3} key={index} className={classes.xsGrid}>
                  <TextField
                    id={"outlined-basic-" + index}
                    variant="outlined"
                    className={classes.buttonGrid}
                    defaultValue={word}
                    onChange={e => handlebackupPhrase(e, index)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          {index + 1}
                        </InputAdornment>
                      ),
                      classes: {
                        input: classes.inputLabel,
                        adornedStart: classes.inputAdornedStart
                      }
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default withRouter(RecoverPhrase);
