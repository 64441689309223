import React from 'react'
import { useGlobalContext } from '../../../contexts/GlobalDataProvider'
import { getFolderId, getUserRole, isArchived } from '../../../helpers/helpers'
import { roomMenuOptions } from './room-menu-options'
import { useNotificationsContext } from '../../../contexts/NotificationsContextProvider'
import MoreMenuRoot from './MoreMenuRoot'
import MoreMenuItem from './MoreMenuItem'
import { useLocation } from 'react-router'

const MenuRoom = ({
  anchorEl,
  handleMenuClose,
  openMenu,
  currentMembership,
  history
}) => {
  const { decryptedProfileDetails, onUserAttributes } = useGlobalContext()
  const { modal, onNotificationData } = useNotificationsContext()

  const userRole = getUserRole(
    currentMembership ? currentMembership : [],
    decryptedProfileDetails
  )
  const isRoomArchived = isArchived(currentMembership)

  const location = useLocation()
  const folderId = getFolderId(location.pathname)

  if (!openMenu) return null

  return (
    <MoreMenuRoot
      anchorEl={anchorEl}
      handleMenuClose={handleMenuClose}
      openMenu={openMenu}
    >
      <div>
        {roomMenuOptions(
          currentMembership,
          userRole,
          isRoomArchived,
          modal.onModalVisibility,
          onNotificationData,
          modal.onConfirmModalType,
          history,
          handleMenuClose,
          modal.onConfirmModalFormType,
          folderId,
          onUserAttributes
        ).map(
          (menuItem, index) =>
            menuItem.show && <MoreMenuItem key={index} menuItem={menuItem} />
        )}
      </div>
    </MoreMenuRoot>
  )
}

export default MenuRoom
