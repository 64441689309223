import React from 'react'
import { Box } from '@mui/material'
import Alert from '@mui/material/Alert'

function ErrorAlert(props) {
  const { getErrorText, darkMode } = props

  return (
    // for signup alert if phrease is wrong
    <Box mt={2} mb={darkMode && 2}>
      <Alert variant={darkMode ? 'filled' : 'standard'} severity="error">
        {getErrorText}
      </Alert>
    </Box>
  )
}

export default ErrorAlert
