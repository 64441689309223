import {
  Box,
  Table,
  TableBody,
  TableContainer,
  Typography
} from "@mui/material";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useSearchContext } from "../../contexts/SearchContextProvider";
import { Pagination } from "../common";
import SearchResult from "./SearchResult";
import SearchResultsFilter from "./SearchResultsFilter";

function SearchResults() {
  const { isMobile } = useGlobalContext();
  const { searchResults, clearSearch, cachedSearchResults } =
    useSearchContext();
  const location = useLocation();
  const [results, setResults] = useState([]);
  const [paginatedResults, setPaginatedResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(1);
  const resultsPerPage = 20;

  useEffect(() => {
    if (Array.isArray(searchResults)) {
      setResults(cloneDeep(searchResults));
      cacheResults();
      clearSearch();
    } else {
      setResults(getCachedResults());
    }
  }, [searchResults]);

  useEffect(() => {
    const pages = Math.ceil(results.length / resultsPerPage) || null;
    setPages(pages);
    setPaginatedResults(getPaginatedResults());
  }, [results, currentPage]);

  const cacheResults = () => {
    const urlParams = new URLSearchParams(location.search);
    const term = urlParams.get("term");
    if (term) {
      cachedSearchResults[term] = searchResults;
    }
  };

  const getCachedResults = () => {
    const urlParams = new URLSearchParams(location.search);
    const term = urlParams.get("term");
    if (term) {
      return cachedSearchResults[term] || [];
    }
    return [];
  };

  const getPaginatedResults = () => {
    const startIndex = currentPage * resultsPerPage - resultsPerPage;
    const endIndex = startIndex + resultsPerPage;
    return results.slice(startIndex, endIndex);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const changePage = event => {
    const selectValue = event.target?.value ? event.target.value : event;
    setCurrentPage(Number(selectValue));
  };

  return (
    <div
      style={{
        width: "100%",
        height: isMobile ? "calc(100% - 59px)" : "100%",
        overflowY: "auto",
        position: "absolute"
      }}
    >
      {paginatedResults && paginatedResults.length ? (
        <Box>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <SearchResultsFilter />
              <TableBody>
                {paginatedResults.map((result, i) => (
                  <SearchResult key={i} document={result}></SearchResult>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="center" my={6}>
            <Pagination
              bottomPosition
              pages={pages}
              currentPage={currentPage}
              onGoToNextPage={goToNextPage}
              onGoToPreviousPage={goToPreviousPage}
              onChangePage={changePage}
            ></Pagination>
          </Box>
        </Box>
      ) : (
        <Box mx={5} mt={4} mb={!isMobile ? 1 : 3}>
          <Typography variant="body2" color="text.secondary">
            Sorry, there were no search results. Search can currently only
            display data you have recently viewed and therefore decrypted.
          </Typography>
        </Box>
      )}
    </div>
  );
}

export default SearchResults;
