import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Typography,
  Card,
  CardMedia,
  IconButton,
  ClickAwayListener
} from "@mui/material";
import OperationTitleWithIcon from "../../../components/common/OperationTitle/OperationTitleWithIcon";
import OnImageMenu from "../../../components/common/OnImageMenu";
import NoThumbnail from "../../../components/common/NoThumbnail";
import { MenuDotsHorizontalIcon } from "@akord/addon-icons";
import { useHistory } from "react-router-dom";
import { getDataRoomId, getDataRoomStatus } from "../../../helpers/helpers";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "440px",
    height: "136px",
    marginBottom: "6px",
    "&:hover": {
      "& $showAction": {
        visibility: "visible",
        opacity: "1"
      }
    }
  },
  showAction: {
    textAlign: "end",
    width: "calc(100% - 48px)",
    visibility: ({ buttonVisible }) => (buttonVisible ? "visible" : "hidden"),
    opacity: ({ buttonVisible }) => (buttonVisible ? 1 : 0),
    transition: "visibility 0.3s, opacity 0.1s linear"
  },
  iconButtonThumb: {
    position: "absolute",
    margin: "6px",
    color: ({ buttonVisible }) => buttonVisible && theme.palette.primary.main,
    backgroundColor: ({ buttonVisible }) => !buttonVisible && "#7C798620",
    padding: "6px",
    "&:hover": {
      backgroundColor: "rgba(120, 117, 116, 0.1)",
      color: "#333"
    }
  },
  media: {
    height: "136px",
    backgroundPosition: "top",
    cursor: "pointer"
  }
}));

function StackRevision(props) {
  const history = useHistory();
  const roomStatus = getDataRoomStatus(history.location.pathname);
  const roomId = getDataRoomId(history.location.pathname);
  const [buttonVisible, setButtonVisible] = React.useState(false);
  const handleButtonVisible = value => setButtonVisible(value);
  const classes = useStyles({ buttonVisible: buttonVisible });
  const { positionedBeforeDate, operation, color, decryptedStacks } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCorrectExtensioForVersion = () => {
    const stackTitle = operation.stack.title.split(".")[0];
    const originalNameArray = operation.files[0].title.split(".");
    return stackTitle + "." + originalNameArray[originalNameArray.length - 1];
  };

  //create stacks status and stack hash dictionary
  const statusDictionary = {};
  const hashesDictionary = {};

  const stackArray = decryptedStacks?.filter(stack =>
    stack.files.some(item => operation.stack.title === item.title)
  );

  //HACK: if a file was renamed and we want to hide it in the timeline because
  //we revoked/deleted it we will be looking in the files array
  //and pick the first one to match the names, since the stack titles won't match
  stackArray?.forEach(
    stack => (statusDictionary[operation.files[0].title] = stack.status)
  );
  stackArray?.forEach(
    stack => (hashesDictionary[operation.files[0].title] = stack.hash)
  );

  return (
    <>
      <OperationTitleWithIcon
        actionRef={operation.actionRef}
        color={color}
        title={
          operation.thumbnailUrl
            ? getCorrectExtensioForVersion()
            : `New version added  ·  version ${operation.stack.resourceVersion}`
        }
      />
      {operation.thumbnailUrl ? (
        <>
          <Typography
            variant="body2"
            paragraph
            color="text.secondary"
            className="small"
            style={{
              marginBottom: "4px",
              whiteSpace: "pre"
            }}
          >
            New version added{"  "}·{"  "}version{" "}
            {operation.stack.resourceVersion}
          </Typography>
          <Card
            className={classes.card}
            square
            style={{
              marginBottom: positionedBeforeDate ? "74px" : "16px"
            }}
          >
            <div className={classes.showAction}>
              <ClickAwayListener onClickAway={() => handleButtonVisible(false)}>
                <IconButton
                  aria-label="settings"
                  edge="end"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={event => {
                    handleMenu(event);
                    handleButtonVisible(true);
                  }}
                  className={classes.iconButtonThumb}
                  size="large"
                >
                  <MenuDotsHorizontalIcon />
                </IconButton>
              </ClickAwayListener>
            </div>
            <CardMedia
              className={classes.media}
              image={operation.thumbnailUrl}
              title={operation.stack.title}
              onClick={() => {
                history.push(
                  `/vaults/${roomStatus}/${roomId}/assets/stack/${operation.modelId}/?resourceVersion=${operation.stack.resourceVersion}`
                );
              }}
            />
          </Card>
        </>
      ) : (
        <NoThumbnail
          stackItem={operation}
          handleMenu={handleMenu}
          lastItem={true}
          stackStatus={statusDictionary[operation.files[0].title]}
          stackHash={hashesDictionary[operation.files[0].title]}
          {...props}
        />
      )}
      <OnImageMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        operation={operation}
      />
    </>
  );
}

export default StackRevision;
