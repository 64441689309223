import { createTheme } from "@mui/material/styles";
import { grey, orange, blue } from "./colors";

const createpaletteOptions = (darkMode = false) => ({
  mode: darkMode ? "dark" : "light",
  background: {
    default: darkMode ? grey[900] : "#FFF",
    secondary: darkMode ? grey[800] : "#FFF",
    tertiary: darkMode ? grey[600] : grey[200],
    switch: grey[500],
    dark: grey[800],
    archived: darkMode ? grey[800] : grey[200],
    active: darkMode ? "rgb(155, 50, 3, 20%)" : orange[100],
    card: darkMode ? grey[800] : grey[100],
    cardBorder: darkMode ? grey[700] : grey[200],
    stack: darkMode ? blue[900] : blue[100],
    folder: darkMode ? "#373235" : "#E8E4E3",
    announcement: darkMode ? "white" : grey[900]
  },
  text: {
    primary: darkMode ? grey[100] : grey[800],
    secondary: darkMode ? grey[400] : grey[500],
    tertiary: darkMode ? grey[300] : grey[600],
    disabled: darkMode ? grey[500] : grey[400],
    announcement: darkMode ? grey[800] : grey[100]
  },
  primary: {
    main: orange[500],
    light: orange[400],
    dark: orange[600],
    darkest: orange[800],
    veryDark: orange[900],
    iconInvert: darkMode ? grey[900] : grey[100],
    contrastText: "#FFF"
  },
  secondary: {
    main: orange[500],
    light: grey[300],
    disabled: darkMode ? grey[500] : grey[400]
  },
  success: {
    main: "#36A342",
    light: "#46C354",
    dark: "#288032"
  },
  warning: {
    main: orange[400]
  },
  error: {
    main: "#E43C33"
  },
  info: {
    main: blue[500],
    secondary: blue[500]
  }
});

const theme = (darkMode = false) => {
  const paletteOptions = createpaletteOptions(darkMode);

  return createTheme({
    palette: paletteOptions,
    spacing: [0, 4, 8, 12, 16, 20, 24, 32, 48, 64, 72],
    breakpoints: {
      values: {
        xs: 0,
        sm: 668,
        md: 960,
        lg: 1280,
        xl: 1920
      }
    },

    typography: {
      fontFamily: [
        "Larsseit-Regular",
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Roboto",
        "Oxygen-Sans",
        "Ubuntu",
        "Cantarell",
        "Helvetica Neue",
        "sans-serif"
      ].join(","),
      fontSize: 16
    },
    components: {
      MuiContainer: {
        styleOverrides: {
          root: {
            paddingLeft: "20px",
            paddingRight: "20px",
            "@media (min-width:668px)": {
              paddingLeft: "20px",
              paddingRight: "20px"
            },
            "&.MuiContainer-disableGutters": {
              paddingLeft: 0,
              paddingRight: 0
            }
          },
          maxWidthSm: {
            maxWidth: "544px!important"
          }
        }
      },
      // TYPOGRAPHY
      MuiTypography: {
        styleOverrides: {
          gutterBottom: {
            marginBottom: 24
          },
          paragraph: {
            "&.MuiTypography-body2": {
              marginBottom: "0.75rem",
              "&:last-child": {
                marginBottom: 0
              }
            }
          },
          h1: {
            fontFamily: [
              "Larsseit-Bold",
              "-apple-system",
              "BlinkMacSystemFont",
              "Segoe UI",
              "Roboto",
              "Oxygen-Sans",
              "Ubuntu",
              "Cantarell",
              "Helvetica Neue",
              "sans-serif"
            ].join(","),
            color: paletteOptions.text.primary,
            fontSize: "1.5rem",
            lineHeight: 1.214,
            "&.xl": {
              fontSize: "1.875rem",
              whiteSpace: "pre"
            },
            "&.xxl": {
              fontSize: "2.375rem"
            },
            "&.regular": {
              fontFamily: [
                "Larsseit-Regular",
                "-apple-system",
                "BlinkMacSystemFont",
                "Segoe UI",
                "Roboto",
                "Oxygen-Sans",
                "Ubuntu",
                "Cantarell",
                "Helvetica Neue",
                "sans-serif"
              ].join(","),
              fontVariant: "small-caps"
            }
          },
          h2: {
            fontFamily: [
              "Larsseit-Bold",
              "-apple-system",
              "BlinkMacSystemFont",
              "Segoe UI",
              "Roboto",
              "Oxygen-Sans",
              "Ubuntu",
              "Cantarell",
              "Helvetica Neue",
              "sans-serif"
            ].join(","),
            color: paletteOptions.text.primary,
            fontSize: "1.25rem",
            lineHeight: 1.3,
            "&.regular": {
              fontFamily: [
                "Larsseit-Regular",
                "-apple-system",
                "BlinkMacSystemFont",
                "Segoe UI",
                "Roboto",
                "Oxygen-Sans",
                "Ubuntu",
                "Cantarell",
                "Helvetica Neue",
                "sans-serif"
              ].join(",")
            }
          },
          h3: {
            fontFamily: [
              "Larsseit-Bold",
              "-apple-system",
              "BlinkMacSystemFont",
              "Segoe UI",
              "Roboto",
              "Oxygen-Sans",
              "Ubuntu",
              "Cantarell",
              "Helvetica Neue",
              "sans-serif"
            ].join(","),
            color: paletteOptions.text.primary,
            fontSize: "1.125rem",
            lineHeight: 1.4
          },
          body1: {
            color: paletteOptions.text.tertiary,
            fontSize: "1.125rem",
            lineHeight: 1.5,
            "&.strong": {
              fontFamily: [
                "Larsseit-Bold",
                "-apple-system",
                "BlinkMacSystemFont",
                "Segoe UI",
                "Roboto",
                "Oxygen-Sans",
                "Ubuntu",
                "Cantarell",
                "Helvetica Neue",
                "sans-serif"
              ].join(",")
            }
          },
          body2: {
            color: paletteOptions.text.tertiary,
            fontSize: "1rem",
            lineHeight: 1.5,
            "&.small": {
              transition: ".3s ease",
              fontSize: "0.938rem",
              lineHeight: "1.4"
            },
            "&.strong": {
              fontFamily: [
                "Larsseit-Bold",
                "-apple-system",
                "BlinkMacSystemFont",
                "Segoe UI",
                "Roboto",
                "Oxygen-Sans",
                "Ubuntu",
                "Cantarell",
                "Helvetica Neue",
                "sans-serif"
              ].join(",")
            },
            "&.xs": {
              fontSize: "0.75rem",
              lineHeight: 1.6
            }
          },
          caption: {
            fontFamily: [
              "Larsseit-Regular",
              "-apple-system",
              "BlinkMacSystemFont",
              "Segoe UI",
              "Roboto",
              "Oxygen-Sans",
              "Ubuntu",
              "Cantarell",
              "Helvetica Neue",
              "sans-serif"
            ].join(","),
            color: darkMode ? grey[300] : grey[600],
            fontSize: "0.875rem",
            lineHeight: 1.3,
            "&.small": {
              fontSize: "0.813rem",
              marginBottom: "0"
            },
            "&.xs": {
              fontSize: "0.75rem",
              lineHeight: 1.6,
              letterSpacing: 1.6
            },
            "&.strong": {
              fontFamily: [
                "Larsseit-Bold",
                "-apple-system",
                "BlinkMacSystemFont",
                "Segoe UI",
                "Roboto",
                "Oxygen-Sans",
                "Ubuntu",
                "Cantarell",
                "Helvetica Neue",
                "sans-serif"
              ].join(",")
            }
          }
        }
      },
      // NAVIGATION
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundImage: "none"
          },
          colorSecondary: {
            backgroundColor: paletteOptions.background.default,
            color: paletteOptions.secondary.main
          }
        }
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            "& .MuiButton-label": {
              fontFamily: [
                "Larsseit-Bold",
                "-apple-system",
                "BlinkMacSystemFont",
                "Segoe UI",
                "Roboto",
                "Oxygen-Sans",
                "Ubuntu",
                "Cantarell",
                "Helvetica Neue",
                "sans-serif"
              ].join(",")
            }
          },
          regular: {
            justifyContent: "space-evenly",
            "@media (min-width: 700px)": {
              minHeight: 54,
              justifyContent: "space-around"
            },
            "@media (min-width: 0px) and (orientation: landscape)": {
              minHeight: 54,
              justifyContent: "space-around"
            }
          },
          dense: {
            minHeight: 50
          },
          gutters: {
            "@media (min-width: 768px)": {
              padding: "0 16px"
            }
          }
        }
      },
      // AVATAR
      MuiAvatar: {
        styleOverrides: {
          root: {
            fontSize: "inherit",
            width: 35,
            height: 35,
            paddingTop: 4
          },
          colorDefault: {
            backgroundColor: paletteOptions.secondary.main,
            color: "#FFF"
          }
        }
      },
      // BUTTONS
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            height: "44px",
            padding: "0 1rem",
            lineHeight: "inherit",
            fontSize: "1.125rem",
            textTransform: "none"
          },
          contained: {
            border: "1px solid",
            "&.Mui-disabled": {
              backgroundColor: paletteOptions.text.disabled,
              color: darkMode ? grey[400] : grey[200],
              borderColor: paletteOptions.text.disabled,
              "&:hover": {
                backgroundColor: paletteOptions.text.disabled,
                borderColor: paletteOptions.text.disabled,
                "&$disabled": {
                  backgroundColor: paletteOptions.text.disabled,
                  // Reset on touch devices, it doesn't add specificity
                  "@media (hover: none)": {
                    backgroundColor: paletteOptions.text.disabled
                  }
                }
              }
            }
          },
          outlinedSizeSmall: {
            padding: "0 12px",
            height: "40px",
            minWidth: "auto"
          },
          containedSizeSmall: {
            padding: "0 12px",
            height: "40px",
            minWidth: "auto"
          },
          outlined: {
            border: "1px solid ",
            fontFamily: [
              "Larsseit-Bold",
              "-apple-system",
              "BlinkMacSystemFont",
              "Segoe UI",
              "Roboto",
              "Oxygen-Sans",
              "Ubuntu",
              "Cantarell",
              "Helvetica Neue",
              "sans-serif"
            ].join(",")
          },
          outlinedSecondary: {
            borderColor: orange[500],
            color: "#FFF",
            "&:hover": {
              backgroundColor: orange[900]
            }
          },
          outlinedPrimary: {
            borderColor: orange[500],
            "&:hover": {
              borderColor: paletteOptions.primary.dark,
              color: paletteOptions.primary.dark,
              backgroundColor: "transparent"
            }
          },
          containedPrimary: {
            borderColor: darkMode
              ? paletteOptions.primary.light
              : paletteOptions.primary.dark,
            boxShadow: "none",
            fontFamily: [
              "Larsseit-Bold",
              "-apple-system",
              "BlinkMacSystemFont",
              "Segoe UI",
              "Roboto",
              "Oxygen-Sans",
              "Ubuntu",
              "Cantarell",
              "Helvetica Neue",
              "sans-serif"
            ].join(","),
            "&:hover": {
              backgroundColor: paletteOptions.primary.dark,
              boxShadow: "none",
              borderColor: darkMode ? orange[500] : orange[700],
              "@media (hover: none)": {
                backgroundColor: paletteOptions.primary.main
              },
              "&$disabled": {
                backgroundColor: paletteOptions.primary.dark
              }
            },
            minWidth: "224px", // check prev designs if we need it
            padding: "0 16px"
          },
          textPrimary: {
            fontFamily: [
              "Larsseit-Bold",
              "-apple-system",
              "BlinkMacSystemFont",
              "Segoe UI",
              "Roboto",
              "Oxygen-Sans",
              "Ubuntu",
              "Cantarell",
              "Helvetica Neue",
              "sans-serif"
            ].join(","),
            color: paletteOptions.text.secondary,
            "&:hover": {
              backgroundColor: darkMode ? grey[800] : grey[200]
            }
          },
          text: {
            padding: "0 16px",
            color: paletteOptions.text.secondary,
            "&:hover": {
              backgroundColor: darkMode ? grey[800] : grey[200]
            }
          },
          sizeSmall: {
            fontSize: "1rem",
            "& .MuiButton-label": {},
            height: 40
          },
          textSizeSmall: {
            padding: "0 12px"
          }
        }
      },
      MuiCheckbox: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: {
            color: darkMode ? grey[500] : grey[400],
            "&:hover": {
              color: paletteOptions.primary.dark,
              backgroundColor: "transparent"
            }
          },
          colorSecondary: {
            "&.Mui-checked": {
              "&:hover": {
                backgroundColor: "transparent!important" // hover over Radio button
              }
            }
          }
        }
      },
      // TABS
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "none",
            transition: ".3s ease",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 0,
            paddingBottom: 0,
            minHeight: "28px",
            "@media (min-width: 768px)": {
              minWidth: "auto"
            },
            "&.Mui-selected": {
              color: paletteOptions.text.primary
            },
            "&:hover": {
              color: darkMode ? "#FFF" : grey[800]
            }
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "28px"
          },
          flexContainer: {
            borderBottom: "1px solid ",
            borderBottomColor: orange[500],
            position: "absolute",
            top: "-3px",
            width: "100%"
          }
        }
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: {
            color: darkMode ? grey[500] : grey[400],
            "&:hover": {
              color: darkMode ? grey[200] : grey[600],
              backgroundColor: "transparent"
            },
            "&.Mui-disabled": {
              color: paletteOptions.text.disabled
            },
            padding: 0,
            fontSize: "1rem"
          },
          sizeSmall: {
            fontSize: "1.3rem"
          },
          colorSecondary: {
            "&:hover": {
              backgroundColor: "transparent"
            }
          }
        }
      },
      MuiFab: {
        styleOverrides: {
          root: {
            boxShadow: darkMode
              ? "0 5px 10px hsla(255,7%,11%,.3), 0 2px 4px hsla(255,7%,11%,.27)"
              : "0 5px 10px hsla(255,7%,11%,.15), 0 2px 4px hsla(255,7%,11%,.12)",
            width: 69,
            height: 69,
            "&:active": {
              boxShadow: darkMode
                ? "0 5px 10px hsla(255,7%,11%,.3), 0 2px 4px hsla(255,7%,11%,.27)"
                : "0 5px 10px hsla(255,7%,11%,.15), 0 2px 4px hsla(255,7%,11%,.12)"
            },
            "&.Mui-disabled": {
              backgroundColor: paletteOptions.text.disabled,
              color: darkMode ? grey[400] : grey[200]
            }
          },
          primary: {
            "&:hover": {
              backgroundColor: paletteOptions.primary.dark
            }
          },
          secondary: {
            border: "1px solid",
            borderColor: darkMode ? grey[500] : grey[400],
            color: paletteOptions.text.secondary,
            backgroundColor: paletteOptions.background.default,
            "&:hover": {
              backgroundColor: paletteOptions.background.default,
              "@media (hover: none)": {
                border: "1px solid",
                borderColor: darkMode ? grey[500] : grey[400],
                color: paletteOptions.text.secondary,
                backgroundColor: paletteOptions.background.default
              }
            }
          },
          sizeMedium: {
            width: "48px",
            height: "48px",
            minHeight: "48px",
            minWidth: "48px",
            boxShadow: "none"
          },
          sizeSmall: {
            width: "32px",
            height: "32px",
            minHeight: "32px",
            minWidth: "32px",
            boxShadow: "none"
          },
          extended: {
            textTransform: "none",
            height: "33px!important",
            padding: "0 16px 0 12px!important",
            fontSize: "0.938rem",
            width: "auto!important"
          }
        }
      },
      // LINKS
      MuiLink: {
        styleOverrides: {
          underlineHover: {
            "&:hover": {
              textDecoration: "none"
            }
          },
          underlineNone: {
            borderBottom: "1px solid",
            borderBottomColor: paletteOptions.text.secondary,
            "&:hover": {
              color: paletteOptions.text.primary
            }
          },
          underlineAlways: {
            textDecoration: "none",
            borderBottom: "1px solid",
            borderBottomColor: paletteOptions.text.secondary,
            "&:hover": {
              color: paletteOptions.text.primary
            }
          }
        }
      },
      // LISTS
      MuiList: {
        styleOverrides: {
          root: {
            margin: 0,
            listStyleType: "disc"
          },
          padding: {
            paddingTop: 0,
            paddingBottom: 0
          }
        }
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            paddingTop: 10,
            paddingBottom: 10,
            "&.Mui-selected": {
              backgroundColor: blue[500],
              color: grey[100],
              "&:hover": {
                backgroundColor: blue[500],
                color: grey[100]
              }
            },
            "& a": {
              textDecoration: "none"
            },
            listStyleType: "disc",
            "&:first-of-type": {
              paddingTop: 0
            },
            "@media(max-width:668px)": {
              paddingTop: 0,
              paddingBottom: 0
            }
          },
          gutters: {
            paddingLeft: "20px",
            paddingRight: "20px",
            margin: 0
          },
          dense: {
            paddingTop: 0,
            paddingBottom: 0
          },
          button: {
            color: paletteOptions.text.secondary,
            "&:hover": {
              backgroundColor: "transparent",
              color: paletteOptions.text.primary
            }
          }
        }
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            marginTop: 0,
            marginBottom: "1px",
            paddingTop: "3px"
          },
          inset: {
            paddingLeft: "32px"
          },
          multiline: {
            marginTop: 0,
            marginBottom: 0
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            transition: ".3s ease",
            minWidth: "auto!important",
            marginRight: "12px",
            "&:last-child": {
              marginRight: "6px"
            }
          },
          alignItemsFlexStart: {
            marginTop: 0
          }
        }
      },
      // Disable Ripple Effect on everything
      MuiTouchRipple: {
        styleOverrides: {
          root: {
            display: "none"
          }
        }
      },
      // Forms
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: "1.125rem",
            lineHeight: 0.875,
            color: darkMode ? grey[400] : grey[500],
            "&.Mui-focused": {
              color: grey[400]
            },
            "&.Mui-error": {
              color: paletteOptions.error.main
            },
            "&.shrink": {
              fontSize: "14px",
              transform: "translate(12px, 10px)",
              color: grey[400]
            }
          }
        }
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            minWidth: "auto"
          },
          fullWidth: {
            width: "100%"
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: "1rem",
            color: paletteOptions.secondary.light
          },
          root: {
            marginRight: 0
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            lineHeight: "1.1876em"
          },
          input: {
            "&:-webkit-autofill": {
              transitionDelay: "9999s",
              transitionProperty: "background-color, color"
            },
            "&.Mui-disabled": {
              background: darkMode ? grey[800] : "#F7F6FA"
            },
            "&::-webkit-input-placeholder": {
              opacity: 1
            },
            color: paletteOptions.text.primary,
            "&::placeholder": {
              color: paletteOptions.text.secondary
            }
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            transform: "translate(14px, 20px) scale(1)",
            overflow: "inherit"
          },
          filled: {
            transform: "translate(12px, 26px) scale(1)"
          },
          outlined: {
            "&.MuiInputLabel-shrink": {
              transform: "translate(14px, -6px) scale(0.777)"
            }
          }
        }
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: grey[200],
            "&:hover, &.Mui-focused": {
              backgroundColor: grey[400]
            }
          },
          input: {
            padding: "32px 12px 15px"
          },
          underline: {
            "&:before": {
              borderBottom: "2px solid #D1CFD8"
            },
            "&:hover:before": {
              borderBottom: "2px solid #9D9BA6"
            },
            "&:after": {
              borderBottom: "2px solid #D1CFD8"
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            height: "1.1876em",
            padding: "16px 12px",
            "&:-webkit-autofill": {
              transitionDelay: "9999s",
              transitionProperty: "background-color, color",
              WebkitBoxShadow: "none"
            }
          },
          root: {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid",
              borderColor: darkMode ? grey[600] : grey[300]
            },
            "&.MuiOutlinedInput-root.Mui-disabled": {
              borderColor: "transparent"
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "1px solid",
              borderColor: paletteOptions.secondary.main
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "1px solid",
              borderColor: paletteOptions.secondary.main
            },
            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
              borderColor: paletteOptions.error.main
            }
          },
          multiline: {
            padding: "12px 14px 18px"
          },
          adornedEnd: {
            paddingRight: 12
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            marginBottom: 24
          }
        }
      },
      MuiRadio: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: {
            color: darkMode ? grey[500] : grey[400],
            position: "relative",
            top: -2,
            "&:hover": {
              "&:not(&.Mui-checked)": {
                backgroundColor: "transparent!important", // hover over Radio button
                color: darkMode ? grey[200] : grey[600]
              }
            },
            "&.Mui-checked": {
              "&:hover": {
                backgroundColor: "transparent!important" // hover over Radio button
              }
            }
          },
          colorSecondary: {
            "&.Mui-checked": {
              "&:hover": {
                backgroundColor: "transparent!important" // hover over Radio button
              }
            }
          }
        }
      },
      // Snackbar
      MuiSnackbar: {
        styleOverrides: {
          root: {
            left: "20px",
            right: "20px"
          },
          anchorOriginBottomCenter: {
            bottom: "24px"
          }
        }
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            fontSize: 16
          }
        }
      },
      // MISC
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: 27
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderColor: darkMode
              ? `${grey[800]}!important`
              : `${grey[200]}!important`,
            background: "transparent",
            border: "1px solid",
            boxShadow: "none"
          }
        }
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: darkMode ? grey[800] : grey[200]
          },
          light: { borderColor: darkMode ? grey[700] : grey[200] }
        }
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            marginTop: 14,
            marginBottom: 0,
            borderRadius: 2
          }
          // colorPrimary: {
          //   backgroundColor: darkMode ? orange[700] : orange[200]
          // }
        }
      },
      MuiMobileStepper: {
        styleOverrides: {
          dot: {
            backgroundColor: grey[600]
          },
          dotActive: {
            backgroundColor: "#FFF"
          }
        }
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: "24px",
            transition: ".3s ease",
            lineHeight: 1.43
          },
          colorPrimary: {
            color: darkMode ? "#FFF" : "#403F46"
          },
          colorSecondary: {
            color: darkMode ? grey[500] : grey[400]
          },
          colorAction: {
            color: paletteOptions.primary.main
          },
          colorWarning: {
            color: orange[400]
          },
          colorInfo: {
            color: paletteOptions.text.tertiary
          },
          colorDisabled: {
            color: darkMode ? grey[500] : grey[400]
          },
          colorError: {
            color: paletteOptions.error.main
          },
          fontSizeSmall: {
            fontSize: "20px"
          },
          fontSizeLarge: {
            fontSize: "30px"
          }
        }
      },
      MuiMenuItem: {
        defaultProps: {
          disableRipple: true
        },
        styleOverrides: {
          root: {
            minHeight: "auto",
            paddingRight: 24,
            paddingLeft: 16,
            paddingTop: 8,
            paddingBottom: 8,
            "&:hover": {
              backgroundColor: "transparent"
            },
            "&:last-of-type": {
              paddingBottom: 16
            },
            "&:first-of-type": {
              paddingTop: 16
            },
            "@media(max-width:668px)": {
              paddingRight: 24,
              paddingLeft: 16,
              paddingTop: 10,
              paddingBottom: 10,
              "&:last-of-type": {
                paddingBottom: 20
              },
              "&:first-of-type": {
                paddingTop: 20
              }
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "&:focus": {
              backgroundColor: "transparent!important"
            }
          }
        }
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px"
          },
          paperAnchorRight: {
            width: "350px",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
          },
          paperAnchorDockedRight: {
            borderLeft: "none",
            boxShadow: darkMode
              ? "-10px 10px 20px hsla(255,7%,11%,.25), -1px 3px 6px hsla(255,7%,11%,.23)"
              : "-10px 10px 20px hsla(255,7%,11%,.10), -1px 3px 6px hsla(255,7%,11%,.08)"
          },
          paperAnchorDockedLeft: {
            borderRight: darkMode ? "1px solid #403F46" : "1px solid #D1CFD8"
          }
        }
      },
      MuiBadge: {
        styleOverrides: {
          badge: {
            backgroundColor: blue[500],
            border: "2px solid ",
            borderColor: darkMode ? grey[900] : "#FFF",
            height: "18px",
            fontSize: "11px",
            fontFamily: [
              "Larsseit-Bold",
              "-apple-system",
              "BlinkMacSystemFont",
              "Segoe UI",
              "Roboto",
              "Oxygen-Sans",
              "Ubuntu",
              "Cantarell",
              "Helvetica Neue",
              "sans-serif"
            ].join(","),
            padding: "0 4px",
            minWidth: "18px",
            lineHeight: "11px",
            right: "auto"
          },
          dot: {
            height: "9px",
            minWidth: "9px",
            borderRadius: "8px",
            padding: 0
          },
          anchorOriginTopRightRectangular: {
            top: "-43px",
            right: "auto",
            transform: "none"
          },
          anchorOriginTopLeft: {
            transform: "scale(1) translate(300%, -50%)"
          },
          anchorOriginTopRightCircular: {
            transform: "scale(1) translate(50%, -50%)",
            top: "20%",
            right: "20%"
          },
          anchorOriginTopLeftCircular: {
            transform: "scale(1) translate(-230%, 40%)",
            top: "auto",
            left: "auto"
          },
          colorSecondary: {
            color: blue[400],
            backgroundColor: blue[900]
          },
          colorWarning: {
            backgroundColor: orange[400]
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: "none",
            backgroundColor: paletteOptions.background.default,
            borderRadius: "4px" // need to add square props
          },
          elevation4: {
            boxShadow: darkMode
              ? "0 3px 6px hsla(255,7%,11%,.30), 0 2px 4px hsla(255,7%,11%,.27)"
              : "0 3px 6px hsla(255,7%,11%,.15), 0 2px 4px hsla(255,7%,11%,.12)"
          },
          elevation8: {
            boxShadow: darkMode
              ? "0 10px 20px hsla(255,7%,11%,.3), 0 3px 6px hsla(255,7%,11%,.23)"
              : "0 10px 20px hsla(255,7%,11%,.10), 0 3px 6px hsla(255,7%,11%,.08)"
          },
          elevation16: {
            boxShadow: darkMode
              ? "0 -10px 20px hsla(255,7%,11%,.25), 0 -3px 6px hsla(255,7%,11%,.23)"
              : "0 -10px 20px hsla(255,7%,11%,.10), 0 -3px 6px hsla(255,7%,11%,.08)"
          },
          elevation24: {
            boxShadow: darkMode
              ? "0 15px 30px hsla(255,7%,11%,.30  ), 0 5px 10px hsla(255,7%,11%,.25)"
              : "0 15px 30px hsla(255,7%,11%,.15  ), 0 5px 10px hsla(255,7%,11%,.10)"
          },
          rounded: {
            borderRadius: "8px"
          }
        }
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: darkMode
              ? "rgba(49, 47, 55, .8)"
              : "rgba(34, 33, 38, .5)"
          },
          invisible: {
            backgroundColor: "transparent"
          }
        }
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            padding: 12,
            paddingRight: 16,
            borderRadius: 4
          },
          standardError: {
            padding: 0,
            backgroundColor: "transparent",
            color: paletteOptions.error.main
          },
          filledSuccess: {
            backgroundColor: paletteOptions.success.dark
          },
          filledInfo: {
            backgroundColor: paletteOptions.background.archived
          },
          icon: {
            fontSize: 24,
            padding: 0,
            marginRight: 8
          },
          message: {
            padding: 0,
            marginTop: 2,
            whiteSpace: "pre-line"
          }
        }
      },
      MuiSkeleton: {
        styleOverrides: {
          root: {
            backgroundColor: darkMode ? grey[800] : grey[200]
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "13px 20px",
            borderBottom: "1px solid",
            borderBottomColor: darkMode ? grey[800] : grey[200],
            WebkitTapHighlightColor: "transparent"
          },
          head: {
            color: paletteOptions.text.disabled
          },
          sizeSmall: {
            padding: "0 8px 0 20px"
          },
          paddingNone: {
            padding: "8px 8px"
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&.MuiTableRow-hover:hover": {
              backgroundColor: darkMode
                ? "rgba(49, 47, 55, .3)!important"
                : "rgba(247, 246, 250, .3)!important"
            }
          }
        }
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            color: paletteOptions.text.secondary,
            "&.Mui-active": {
              "& .MuiTableSortLabel-icon": {
                opacity: 1,
                color: paletteOptions.primary.main
              }
            }
          },
          icon: {
            marginBottom: "2px",
            marginLeft: 0,
            marginRight: 0,
            fontSize: "20px"
          }
        }
      },
      MuiTimelineConnector: {
        styleOverrides: {
          root: {
            width: "1px",
            backgroundColor: darkMode ? grey[800] : grey[200]
          }
        }
      },
      MuiTimelineDot: {
        styleOverrides: {
          filledGrey: {
            color: darkMode ? grey[500] : grey[400],
            backgroundColor: "transparent"
          }
        }
      },
      MuiTimeline: {
        styleOverrides: {
          root: {
            paddingTop: "16px"
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          paperWidthMd: {
            width: "576px",
            maxHeight: "calc(100% - 48px)",
            padding: "24px"
          },
          paperWidthXs: {
            maxWidth: "400px",
            width: "100%",
            padding: "24px"
          },
          scrollPaper: {
            alignItems: "flex-start"
          }
        }
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            maxHeight: "237px",
            padding: 0,
            marginTop: 20,
            marginBottom: 24
          },
          dividers: {
            margin: "0 24px 16px",
            padding: "24px 0",
            borderBottom: "none"
          }
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: 0,
            marginBottom: 12
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: 0
          },
          spacing: {
            "& > :not(:first-of-type)": {
              marginLeft: 0
            }
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          outlinedPrimary: {
            color: darkMode ? "#FFF" : grey[600],
            border: "1px solid #3592FA"
          },
          colorPrimary: {
            backgroundColor: paletteOptions.info.main
          },
          sizeSmall: {
            lineHeight: 1.3,
            fontSize: "0.813rem",
            marginBottom: "0",
            height: "20px",
            fontFamily: [
              "Larsseit-Bold",
              "-apple-system",
              "BlinkMacSystemFont",
              "Segoe UI",
              "Roboto",
              "Oxygen-Sans",
              "Ubuntu",
              "Cantarell",
              "Helvetica Neue",
              "sans-serif"
            ].join(",")
          },
          label: {
            marginTop: "2px"
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: grey[600]
          },
          arrow: {
            "&::before": {
              backgroundColor: grey[600]
            }
          }
        }
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: paletteOptions.primary.main,
            position: "absolute"
          },
          colorSecondary: {
            color: darkMode ? blue[500] : blue[400]
          }
        }
      }
    }
  });
};

export default theme;
