import React from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { countries } from "../../helpers/akordTexts";

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        ?.toUpperCase()
        .replace(/./g, char =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const useStyles = makeStyles({
  option: {
    fontSize: "15px",
    "& > span": {
      marginRight: "10px",
      fontSize: "18px"
    }
  },
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      padding: "6.5px 4px"
    }
  }
});

export default function CountrySelect({ onCountrySelect }) {
  const classes = useStyles();

  return (
    <Autocomplete
      id="country-select-demo"
      options={countries}
      classes={{
        option: classes.option,
        inputRoot: classes.inputRoot
      }}
      autoHighlight
      getOptionLabel={option => option.label}
      onChange={onCountrySelect}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <span>{countryToFlag(option.code)}</span>
          {option.label} ({option.code})
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label="Country"
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password" // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
