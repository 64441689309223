import { Menu, MenuItem, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useProgressContext } from "../../contexts/ProgressContext";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { downloadSelectedFile } from "../../helpers/stack-helpers";
import { arweaveURL } from "../../pages/storage/storage-helper.js";
import { DownloadIcon, OpenIcon } from "@akord/addon-icons";
const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.spacing(2)
  },
  extendedIcon: {
    marginRight: theme.spacing(2)
  }
}));

function OnImageMenu({ anchorEl, handleClose, operation }) {
  const { darkMode } = useGlobalContext();
  const { onProgress } = useProgressContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const { encrypter, currentMembership, isVaultPublic } = useVaultContext();
  const { onUploadStart, onShowLoader, onUploadHook } = useAssetsContext();
  const classes = useStyles({ darkMode: darkMode });
  const open = Boolean(anchorEl);

  const stackItem = currentMembership.dataRoom.stacks.items.filter(
    stack => stack.id === operation.modelId
  )[0];

  const noteItem = currentMembership?.dataRoom?.notes?.items.filter(
    note => note.id === operation.modelId
  )[0];

  const itemToShow = stackItem ? stackItem : noteItem;

  const fileTransaction = itemToShow?.storageTransactions?.items.filter(
    transaction => {
      return transaction.refHash === operation.hash;
    }
  )[0];

  return (
    <Menu
      elevation={8}
      variant="selectedMenu"
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
      // MenuListProps={{
      //   style: { background: '#FFF', color: '#333' }
      // }}
    >
      {stackItem && (
        <MenuItem
          style={{ fontSize: "0.9375rem" }}
          onClick={() => {
            handleClose();
            downloadSelectedFile(
              {
                files: [
                  {
                    fileType: operation.files[0].fileType,
                    postedAt: operation.files[0].postedAt,
                    resourceUrl: operation.files[0].resourceUrl,
                    title: operation.files[0].title,
                    size: operation.files[0].size,
                    numberOfChunks: operation.files[0].numberOfChunks
                  }
                ],
                resourceVersion: operation.stack.resourceVersion,
                postedAt: operation.postedAt,
                title: operation.stack.title
              },
              {
                originalIndex: 0
              },
              encrypter,
              onUploadStart,
              onShowLoader,
              onProgress,
              onSnackbarToShow,
              onUploadHook,
              isVaultPublic
            );
          }}
        >
          <DownloadIcon className={classes.extendedIcon} />
          <Typography variant="body2" color="text.primary">
            Download
          </Typography>
        </MenuItem>
      )}
      {fileTransaction && (
        <MenuItem
          onClick={() => window.open(`${arweaveURL}${fileTransaction.refId}`)}
        >
          <OpenIcon className={classes.extendedIcon} />
          <Typography variant="body2" color="text.primary">
            View transaction
          </Typography>
        </MenuItem>
      )}
    </Menu>
  );
}

export default OnImageMenu;
