export const updateNotificationStatus = /* GraphQL */ `
  mutation UpdateNotificationStatus($operation: String, $ids: [String]) {
    updateNotificationStatus(operation: $operation, ids: $ids)
  }
`

export const postLedgerTransaction = /* GraphQL */ `
  mutation PostLedgerTransaction($transactions: [TransactionInput]) {
    postLedgerTransaction(transactions: $transactions) {
      id
      hash
      schemaUri
      postedAt
      publicSigningKey
      contextVersion
      prevHash
      refHash
      dataRoomId
      modelId
      memberDetails {
        publicSigningKey
        email
        fullName
        phone
        avatarUrl
      }
      actionRef
      groupRef
      encodedPrevState
      membership {
        status
        role
        expiresOn
        memberDetails {
          publicSigningKey
          email
          fullName
          phone
          avatarUrl
        }
        termsOfAccess
        agreementHash
        message
        encryptionType
        keys {
          publicKey
          encPrivateKey
        }
        encAccessKey
      }
      dataroom {
        status
        title
        description
        termsOfAccess
        publicKeys
      }
      stack {
        status
        title
        description
        resourceVersion
        files {
          title
          resourceUrl
          thumbnailUrl
          postedAt
          fileType
          size
        }
      }
      folder {
        status
        title
      }
      memo {
        message
      }
      note {
        content
      }
      profile {
        profileDetails {
          publicSigningKey
          email
          fullName
          phone
          avatarUrl
        }
        status
        wallets {
          publicSigningKey
          encBackupPhrase
          publicKey
          revokedOn
        }
        encryptionType
        keys {
          publicKey
          encPrivateKey
        }
        encAccessKey
      }
      status
      encodedHeader
      encodedBody
      publicKey
      signature
    }
  }
`

export const postLedgerLazyTransaction = /* GraphQL */ `
  mutation PostLedgerLazyTransaction($transactions: [TransactionInput]) {
    postLedgerLazyTransaction(transactions: $transactions)
  }
`

export const cancelLazyTransaction = /* GraphQL */ `
  mutation CancelLazyTransaction($id: String) {
    cancelLazyTransaction(id: $id)
  }
`
export const createPaymentIntent = /* GraphQL */ `
  mutation CreatePaymentIntent($topUp: TopUpPaymentIntentInput) {
    createPaymentIntent(topUp: $topUp) {
      clientSecret
      paymentIntentId
      error
    }
  }
`
export const confirmPayment = /* GraphQL */ `
  mutation ConfirmPayment($topUp: TopUpPaymentConfirmInput) {
    confirmPayment(topUp: $topUp)
  }
`

export const rejectPayment = /* GraphQL */ `
  mutation RejectPayment($topUp: TopUpPaymentRejectInput) {
    rejectPayment(topUp: $topUp)
  }
`

export const sendReferralEmail = /* GraphQL */ `
  mutation SendReferralEmail($emails: [String]) {
    sendReferralEmail(emails: $emails)
  }
`
