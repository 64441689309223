import React from 'react'
import zxcvbn from 'zxcvbn'
import { Auth } from 'aws-amplify'
import { AkordWallet } from '@akord/crypto'
import LedgerWrapper from '../../crypto/ledger-wrapper'
import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import {
  ButtonGroup,
  PasswordMeter,
  FormSubmit,
  ErrorAlert,
  RevealToggler,
  SupportScreenWrapper
} from '../../components/common/'
import { useGlobalContext } from '../../contexts/GlobalDataProvider'
import { useSnackbarContext } from '../../contexts/SnackbarContextProvider'

const useStyles = makeStyles(theme => ({
  formCustom: {
    marginBottom: theme.spacing(7)
  },
  notchedOutline: {
    borderColor: '#4CAF57!important'
  },
  notchedOutlineWarning: {
    borderColor: '#DB443C!important'
  },
  label: {
    color: '#4CAF57!important'
  },
  labelWarning: {
    color: '#DB443C!important'
  }
}))

function SecurityChangePassword() {
  const { fullProfile, handleWallet, isMobile } = useGlobalContext()
  const { onSnackbarToShow } = useSnackbarContext()
  const classes = useStyles()
  const [values, setValues] = React.useState({
    showPassword: true
  })
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')

  const [formData, setFormData] = React.useState({
    currentPassword: '',
    userPassword: ''
  })

  const [correctPassword, setCorrectPassword] = React.useState(false)
  const [passEvaluation, setPassEvaluation] = React.useState({})

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleFormChange = prop => async event => {
    const { target } = event
    const value = target.value

    if (prop === 'userPassword') {
      const evaluation = zxcvbn(value)
      setPassEvaluation(evaluation)
    }
    setFormData({
      ...formData,
      [prop]: value
    })
  }

  React.useEffect(() => {
    const checkCurrentPassword = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        const encBackupPhrase = user.attributes['custom:encBackupPhrase']
        await AkordWallet.importFromEncBackupPhrase(
          formData.currentPassword,
          encBackupPhrase
        )
        setCorrectPassword(true)
      } catch (err) {
        setCorrectPassword(false)
      }
    }
    checkCurrentPassword()
    setError()
  }, [formData])

  const isDisabled = () => {
    if (
      correctPassword &&
      formData.userPassword.length >= 8 &&
      formData.currentPassword !== formData.userPassword &&
      !loading
    )
      return false
    else return true
  }

  const changePassword = async () => {
    try {
      setLoading(true)
      const user = await Auth.currentAuthenticatedUser()
      const encBackupPhrase = user.attributes['custom:encBackupPhrase']
      const newWallet = await AkordWallet.changePassword(
        formData.currentPassword,
        formData.userPassword,
        encBackupPhrase
      )
      await Auth.updateUserAttributes(user, {
        'custom:encBackupPhrase': newWallet.encBackupPhrase
      })
      await Auth.changePassword(
        user,
        formData.currentPassword,
        formData.userPassword
      )
      const ledgerWrapper = new LedgerWrapper(newWallet)
      await ledgerWrapper.dispatch('PROFILE_UPDATE_PASSWORD', {
        prevHash: fullProfile.hash
      })
      handleWallet(newWallet)
      setFormData({ currentPassword: '', userPassword: '' })
      setPassEvaluation({})
      setLoading(false)
      onSnackbarToShow('passwordChange')
    } catch (err) {
      setLoading(false)
      setError(err.message)
      console.log(err)
    }
  }

  return (
    <SupportScreenWrapper title="Change password" route="/settings/security">
      <Box mt={5} mb={6}>
        <Typography variant="body2">
          Enter your current password first.
        </Typography>
      </Box>
      <FormSubmit onSubmit={changePassword}>
        <Box width={isMobile ? '100%' : '400px'}>
          <FormControl fullWidth>
            <FormControl variant="outlined" className={classes.formCustom}>
              <InputLabel
                classes={{
                  root: correctPassword
                    ? classes.label
                    : !correctPassword && formData.currentPassword.length >= 6
                    ? classes.labelWarning
                    : null
                }}
              >
                Current password
              </InputLabel>
              <OutlinedInput
                label="Current password"
                name="currentPassword"
                value={formData.currentPassword}
                type={values.showPassword ? 'text' : 'password'}
                onChange={handleFormChange('currentPassword')}
                classes={{
                  notchedOutline: correctPassword
                    ? classes.notchedOutline
                    : !correctPassword && formData.currentPassword.length >= 6
                    ? classes.notchedOutlineWarning
                    : null
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle current password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      size="large">
                      <RevealToggler showPassword={values.showPassword} />
                    </IconButton>
                  </InputAdornment>
                }
              />
              {!correctPassword && formData.currentPassword.length >= 6 && (
                <ErrorAlert getErrorText="This is not your current password." />
              )}
            </FormControl>
            <PasswordMeter
              padding={false}
              values={values}
              formData={formData}
              handleClickShowPassword={handleClickShowPassword}
              handleMouseDownPassword={handleMouseDownPassword}
              handleFormChange={handleFormChange}
              passEvaluation={passEvaluation}
              newPasswordText
            />
          </FormControl>
        </Box>
        <ButtonGroup
          loading={loading}
          type="submit"
          noMinWidth={true}
          nextText="Change password"
          nextDiasabled={isDisabled()}
          hideBackButton={true}
          fullWidthNextButton={isMobile ? true : false}
          hideIcon={true}
          errorText={error}
          disabledRed={false}
        />
      </FormSubmit>
    </SupportScreenWrapper>
  );
}

export default SecurityChangePassword
