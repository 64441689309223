import API, { graphqlOperation } from "@aws-amplify/api";
import {
  stacksByDataRoomId,
  foldersByDataRoomId,
  notesByDataRoomId
} from "./assets";
import { memosByDataRoomId } from "./memos";
import { publicAssetById } from "./publicAssetById";
import { transactionsByDataRoomId } from "./timeline";
import {
  notificationsByPublicSigningKey,
  getTransactionLog
} from "./notifications";

export const paginatedQuery = async (queryName, query, args, filter) => {
  const variables = {};
  const results = [];
  let nextToken = null;
  for (let index in args) {
    variables[index] = args[index];
  }
  if (filter) {
    variables.filter = filter;
  }

  do {
    variables.nextToken = nextToken;
    const queryResult = await API.graphql(graphqlOperation(query, variables));
    results.push(...queryResult.data[queryName].items);
    if (variables.limit && results.length === variables.limit) {
      break;
    }
    nextToken = queryResult.data[queryName].nextToken;
  } while (nextToken);
  return results;
};

export const nextTokenQuery = async (queryName, query, args) => {
  let queryResult = await API.graphql(graphqlOperation(query, args));
  let nextToken = queryResult.data[queryName].nextToken;
  let results = queryResult.data[queryName].items;

  return { nextToken: nextToken, items: results };
};

export const paginatedAssetsQuery = async (dataRoomId, limit) => {
  const queryParams = {};
  queryParams.dataRoomId = dataRoomId;
  if (limit) {
    queryParams.limit = limit;
  }
  const [stacks, folders, notes] = await Promise.all([
    paginatedQuery("stacksByDataRoomId", stacksByDataRoomId, queryParams),
    paginatedQuery("foldersByDataRoomId", foldersByDataRoomId, queryParams),
    paginatedQuery("notesByDataRoomId", notesByDataRoomId, queryParams)
  ]);
  return { stacks, folders, notes };
};

export const paginatedTimelineQuery = async (dataRoomId, limit) => {
  const queryParams = {};
  queryParams.dataRoomId = dataRoomId;
  if (limit) {
    queryParams.limit = limit;
  }
  const timelineItems = await paginatedQuery(
    "transactionsByDataRoomId",
    transactionsByDataRoomId,
    queryParams
  );
  return timelineItems;
};

export const paginatedMemoQuery = async (
  dataRoomId,
  limit,
  nextToken = null
) => {
  const queryParams = {};
  queryParams.dataRoomId = dataRoomId;
  if (limit) {
    queryParams.limit = limit;
  }
  if (nextToken) {
    queryParams.nextToken = nextToken;
  }
  const timelineItems = await nextTokenQuery(
    "memosByDataRoomId",
    memosByDataRoomId,
    queryParams
  );
  return timelineItems;
};

export const notificationsPaginatedQuery = async (publicSigningKey, limit) => {
  const queryParams = {};
  queryParams.publicSigningKey = publicSigningKey;
  if (limit) {
    queryParams.limit = limit;
  }
  const items = await paginatedQuery(
    "notificationsByPublicSigningKey",
    notificationsByPublicSigningKey,
    queryParams
  );

  if (items && items.length) {
    const notifications = items
      .filter(
        item =>
          item.admin ||
          (item.transactions &&
            item.transactions.items &&
            item.transactions.items.length)
      )
      .sort((logA, logB) => logB.height - logA.height);
    return notifications;
  }
};

export const getNotificationQuery = async id => {
  const notificationData = await API.graphql(
    graphqlOperation(getTransactionLog, { id: id })
  );
  if (
    notificationData &&
    notificationData.data &&
    notificationData.data.getTransactionLog
  ) {
    return notificationData.data.getTransactionLog;
  }
  return null;
};

export const getPublicAssetByIdQuery = async id => {
  const publicAssetData = await API.graphql({
    query: publicAssetById,
    variables: { id: id },
    authMode: "API_KEY"
  });
  if (publicAssetData.data.assetById) {
    return publicAssetData.data.assetById;
  }
  return null;
};
