import React from "react";
import { base64ToJson } from "@akord/crypto";
import { Box, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AkordAvatarIcon, AvatarIcon } from "@akord/addon-icons";
import { useHistory } from "react-router-dom";
import {
  getDataForAction,
  getRefIconForAction,
  matchIcon
} from "./notifications-helper";
import { notificationStatus } from "../../helpers/akord-enums";
import { formatDate } from "../../helpers/helpers";
import { blue, grey } from "../../theme/colors/";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";

const useStyles = makeStyles({
  card: {
    cursor: "pointer",
    transition: "all 0.3s",
    backgroundColor: ({ status, darkMode }) =>
      status === notificationStatus.UNREAD
        ? darkMode
          ? blue[900]
          : blue[700]
        : "transparent",
    "&:hover": {
      backgroundColor: ({ status, darkMode }) =>
        status === notificationStatus.UNREAD
          ? darkMode
            ? "#013F84"
            : blue[600]
          : darkMode
          ? "rgb(49, 47, 55, 0.3)"
          : "rgb(247, 246, 250, .3)"
    }
  },
  avatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%"
  },
  unreadBlueColor: {
    color: ({ status, darkMode }) =>
      status === notificationStatus.UNREAD
        ? darkMode
          ? blue[400]
          : blue[200]
        : null
  }
});

function LogNotification({ transaction, lastToShow }) {
  const { darkMode } = useGlobalContext();
  const classes = useStyles({ status: transaction.status, darkMode: darkMode });
  const history = useHistory();

  let RefIconForNotification = getRefIconForAction(
    transaction?.transactions?.items[0]?.actionRef
  );

  let IconForNotification = transaction?.content
    ? AkordAvatarIcon
    : getDataForAction(transaction).icon
    ? getDataForAction(transaction).icon
    : null;

  const dataForAction = transaction.content
    ? base64ToJson(transaction.content)
    : getDataForAction(transaction);

  // In case all the data including icons was sent from th BE
  // we need to use them
  if (dataForAction.icon && dataForAction.refIcon) {
    IconForNotification = matchIcon(dataForAction.icon);
    RefIconForNotification = matchIcon(dataForAction.refIcon);
  }

  const createLinkByModelId = transaction => {
    switch (transaction.modelType) {
      case "stack":
        if (transaction.transactions.items[0].actionRef === "STACK_REVOKE")
          history.push(
            `vaults/active/${transaction?.dataRoomId}/revoked-files`
          );
        else history.push(`vaults/active/${transaction?.dataRoomId}/assets`);
        break;
      case "memo":
        history.push({
          pathname: `vaults/active/${transaction?.dataRoomId}/chat`,
          state: { itemId: transaction.modelId }
        });
        break;
      case "note":
        history.push(`vaults/active/${transaction?.dataRoomId}/assets`);
        break;
      case "folder":
        history.push(
          `vaults/active/${transaction?.dataRoomId}/assets/folders/${transaction?.modelId}`
        );
        break;
      case "dataroom":
        history.push(`vaults/active/${transaction?.dataRoomId}/assets`);
        break;
      case "membership":
        if (transaction.transactions.items[0].actionRef === "USER_VERIFIED") {
          history.push(`confirm/?membershipId=${transaction?.modelId}`);
        } else {
          history.push({
            pathname: `vaults/active/${transaction?.dataRoomId}/manage-access`,
            state: { itemId: transaction.modelId }
          });
        }
        break;
      default:
        history.push({
          pathname: `vaults/active/${transaction?.dataRoomId}/assets`,
          state: { itemId: transaction.modelId }
        });
        break;
    }
  };

  const createNotificationLink = () => {
    return dataForAction.link
      ? window.open(dataForAction.link, "_blank", "noopener,noreferrer")
      : dataForAction.path
      ? history.push(dataForAction.path)
      : createLinkByModelId(transaction);
  };

  return (
    <>
      <Box
        px={4}
        py={4}
        className={classes.card}
        onClick={() => createNotificationLink()}
      >
        <Box display="flex">
          <Box mr={4}>
            {IconForNotification ? (
              <IconForNotification style={{ fontSize: "40px" }} />
            ) : transaction.transactions.items[0]?.ownerInfo?.avatarUrl ? (
              <img
                src={transaction.transactions.items[0]?.ownerInfo?.avatarUrl}
                alt="Avatar"
                className={classes.avatar}
              />
            ) : (
              <AvatarIcon style={{ fontSize: "40px" }} color="secondary" />
            )}
          </Box>
          <Box width="100%">
            <Box>
              <Typography
                component="span"
                variant="body2"
                className="small"
                style={{
                  color:
                    transaction.status === notificationStatus.UNREAD &&
                    grey[100]
                }}
              >
                {dataForAction.preText
                  ? dataForAction.preText
                  : transaction.transactions.items[0]?.ownerInfo?.fullName ||
                    transaction.transactions.items[0]?.ownerInfo?.email ||
                    "Unknown"}
              </Typography>
              <Typography
                component="span"
                variant="body2"
                className="small strong"
                style={{
                  color:
                    transaction.status === notificationStatus.UNREAD &&
                    grey[100]
                }}
              >
                {dataForAction.action}
              </Typography>
              <Typography
                component="span"
                variant="body2"
                className="small"
                style={{
                  color:
                    transaction.status === notificationStatus.UNREAD &&
                    grey[100]
                }}
              >
                {dataForAction.postText
                  ? dataForAction.postText
                  : `${dataForAction.details} the 
                ${transaction.transactions.items[0]?.dataRoomName} vault.`}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <RefIconForNotification
                  fontSize="small"
                  color="secondary"
                  className={classes.unreadBlueColor}
                />
              </Box>
              <Typography
                variant="caption"
                className={[classes.unreadBlueColor, "small"].join(" ")}
                color="text.secondary"
                style={{
                  paddingTop: "3px"
                }}
              >
                {formatDate(transaction.createdAt, false, true)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {!lastToShow && <Divider />}
    </>
  );
}

export default LogNotification;
