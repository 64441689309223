export const onCreateTransactionLogByPublicSigningKey = /* GraphQL */ `
  subscription OnCreateTransactionLogByPublicSigningKey(
    $publicSigningKey: String
  ) {
    onCreateTransactionLogByPublicSigningKey(
      publicSigningKey: $publicSigningKey
    ) {
      id
      height
      hash
      publicSigningKey
      modelId
      modelType
      dataRoomId
      status
      admin
      content
      createdAt
      updatedAt
      dataRoom {
        memberships: members {
          items {
            dataRoom {
              state {
                publicKeys
                title
                termsOfAccess
              }
            }
            memberPublicSigningKey
            state {
              memberDetails {
                publicSigningKey
                email
                fullName
                avatarUrl
              }
              encryptionType
              keys {
                encPrivateKey
                publicKey
              }
            }
          }
        }
      }
      transactions {
        items {
          publicSigningKey
          actionRef
          createdAt
          dataRoomId
          modelId
          groupRef
          hash
          encodedPrevState
          stack {
            title
          }
          folder {
            title
          }
          note {
            title
          }
          memo {
            message
            reactions {
              publicSigningKey
              name
              reaction
              status
              postedAt
              refHash
            }
          }
        }
      }
    }
  }
`;

export const onUpdateTransactionLogByPublicSigningKey = /* GraphQL */ `
  subscription OnUpdateTransactionLogByPublicSigningKey(
    $publicSigningKey: String
  ) {
    onUpdateTransactionLogByPublicSigningKey(
      publicSigningKey: $publicSigningKey
    ) {
      id
      height
      hash
      publicSigningKey
      modelId
      modelType
      dataRoomId
      status
      admin
      content
      createdAt
      updatedAt
    }
  }
`;

export const onCreateStorageByPublicSigningKey = /* GraphQL */ `
  subscription OnCreateStorageByPublicSigningKey($publicSigningKey: String) {
    onCreateStorageByPublicSigningKey(publicSigningKey: $publicSigningKey) {
      publicSigningKey
      total_storage
      storage_available
      storage_used
      documents
      photos
      notes
      audio
      video
      other
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateStorageByPublicSigningKey = /* GraphQL */ `
  subscription OnUpdateStorageByPublicSigningKey($publicSigningKey: String) {
    onUpdateStorageByPublicSigningKey(publicSigningKey: $publicSigningKey) {
      publicSigningKey
      total_storage
      storage_available
      storage_used
      documents
      photos
      notes
      audio
      video
      other
      createdAt
      updatedAt
    }
  }
`;
export const onCreateStorageTransactionByPublicSigningKey = /* GraphQL */ `
  subscription OnCreateStorageTransactionByPublicSigningKey(
    $publicSigningKey: String
  ) {
    onCreateStorageTransactionByPublicSigningKey(
      publicSigningKey: $publicSigningKey
    ) {
      id
      refId
      refHash
      publicSigningKey
      dataRoomId
      stackId
      type
      status
      timestamp
      amount
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateStorageTransactionByPublicSigningKey = /* GraphQL */ `
  subscription OnUpdateStorageTransactionByPublicSigningKey(
    $publicSigningKey: String
  ) {
    onUpdateStorageTransactionByPublicSigningKey(
      publicSigningKey: $publicSigningKey
    ) {
      id
      refId
      refHash
      publicSigningKey
      dataRoomId
      stackId
      type
      status
      timestamp
      amount
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateStackByDataRoomId = /* GraphQL */ `
  subscription OnUpdateStackByDataRoomId($dataRoomId: String) {
    onUpdateStackByDataRoomId(dataRoomId: $dataRoomId) {
      id
      hash
      prevHash
      refHash
      publicSigningKey
      postedAt
      contextVersion
      dataRoomId
      folderId
      status
      state {
        status
        title
        description
        resourceVersion
        size
        permaStatus
        files {
          title
          resourceUrl
          thumbnailUrl
          postedAt
          fileType
          size
          hash
          permaStatus
          resourceTx
          thumbnailTx
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const onCreateMemoByDataRoomId = /* GraphQL */ `
  subscription OnCreateMemoByDataRoomId($dataRoomId: ID!) {
    onCreateMemoByDataRoomId(dataRoomId: $dataRoomId) {
      id
      hash
      prevHash
      refHash
      publicSigningKey
      postedAt
      contextVersion
      dataRoomId
      state {
        message
        reactions {
          publicSigningKey
          name
          reaction
          status
          postedAt
          refHash
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateMemoByDataRoomId = /* GraphQL */ `
  subscription OnUpdateMemoByDataRoomId($dataRoomId: ID!) {
    onUpdateMemoByDataRoomId(dataRoomId: $dataRoomId) {
      id
      hash
      prevHash
      refHash
      publicSigningKey
      postedAt
      contextVersion
      dataRoomId
      state {
        message
        reactions {
          publicSigningKey
          name
          reaction
          status
          postedAt
          refHash
        }
      }
      createdAt
      updatedAt
    }
  }
`;
