import React from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  IconButton
} from "@mui/material";
import StackItem from "../vault-pages/StackItem";
import { DataRoomFilter, EmptySpace } from "../../components/common";
import { ChevronLeftIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { withRouter } from "react-router";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import FolderItem from "../vault-pages/FolderItem";
import { filterDecryptedStacks } from "../../helpers/stack-helpers";
import {
  stableSort,
  getComparator,
  getDataRoomId,
  getDataRoomStatus,
  getRevokedFolderId,
  isArchived,
  getAllFoldersRecursively
} from "../../helpers/helpers";
import TopBarTitleDesktop from "../../components/common/NavBars/TopBarTitleDesktop";
import BatchActionsBar from "../../components/common/BatchActionsBar";
import NoteItem from "../vault-pages/NoteItem";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";
import TransactionSpinner from "../../components/common/TransactionSpinner";

function RevokedFiles({ history }) {
  const { isMobile, darkMode, txSpinner } = useGlobalContext();
  const { currentMembership } = useVaultContext();
  const {
    decryptedNotes,
    decryptedStacks,
    decryptedFolders,
    periodFilter,
    actionFilters,
    assetsDecrypted
  } = useAssetsContext();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("title");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const dataRoomId = getDataRoomId(history.location.pathname);
  const folderId = getRevokedFolderId(history.location.pathname);
  const isRoomArchived = isArchived(currentMembership);

  const revokedStacks = decryptedStacks.filter(
    stack => stack.status === "REVOKED"
  );
  const revokedFolders = decryptedFolders.filter(
    folder => folder.status === "REVOKED"
  );
  const revokedNotes = decryptedNotes.filter(note => note.status === "REVOKED");

  let revokedStacksFoldersNotesCombined = [
    ...revokedStacks,
    ...revokedFolders,
    ...revokedNotes
  ];

  const currentRevokedFolder =
    revokedFolders?.filter(folder => folder.id === folderId)[0] || {};

  const checkIfOrphan = folderId => {
    const parent = revokedFolders.filter(folder => folder.id === folderId)[0];
    return !parent;
  };

  revokedStacksFoldersNotesCombined = revokedStacksFoldersNotesCombined.filter(
    item => {
      if (item.folderId === undefined && !folderId) return item;
      // Add items which were revoked from within a folder and show them at the root level
      else if (item.folderId !== folderId && !folderId)
        return checkIfOrphan(item.folderId) && item;
      else return item.folderId === folderId;
    }
  );

  const membership = history.location?.state
    ? history.location?.state
    : currentMembership;

  const parentFolders = getAllFoldersRecursively(
    revokedFolders,
    currentRevokedFolder
  );

  return (
    <Box
      display={isMobile ? "flex" : "block"}
      flexDirection="column"
      height="100%"
    >
      <Box display="flex" justifyContent="space-between">
        <Box mx={5} mt={4} mb={1} width="100%">
          <Box display="flex" justifyContent="space-between">
            <Box mb={1} display="flex" alignItems="flex-start">
              <IconButton
                style={{ padding: 0 }}
                onClick={() =>
                  history.push(`/vaults/active/${dataRoomId}/assets`)
                }
                size="large"
              >
                <ChevronLeftIcon />
              </IconButton>
              <TopBarTitleDesktop
                variant="h2"
                color="secondary"
                revoked
                currentMembership={membership}
                currentFolder={currentRevokedFolder}
                parentFolders={parentFolders}
                folderId={folderId}
                isRoomArchived={isRoomArchived}
                dataRoomStatus={getDataRoomStatus(history.location.pathname)}
                dataRoomId={getDataRoomId(history.location.pathname)}
              />
            </Box>
            <Box>
              {txSpinner && (
                <TransactionSpinner isMobile={isMobile} darkMode={darkMode} />
              )}
            </Box>
          </Box>
          <Typography variant="body2" color="text.secondary">
            Revoked files
          </Typography>
        </Box>
      </Box>
      <BatchActionsBar
        decryptedStacks={revokedStacks}
        currentMembershipTitle={currentMembership.dataRoom?.state?.title}
        folderId={folderId}
        revoked
      />
      <Box>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <DataRoomFilter
              archivedStack
              order={order}
              orderBy={orderBy}
              data={decryptedStacks}
              onRequestSort={handleRequestSort}
              showAllFilters
            />
            <TableBody>
              {!assetsDecrypted
                ? Array.from(new Array(8)).map((item, index) => (
                    <StackItem key={index} space={null} />
                  ))
                : revokedStacksFoldersNotesCombined?.length > 0 &&
                  stableSort(
                    filterDecryptedStacks(
                      revokedStacksFoldersNotesCombined,
                      periodFilter,
                      actionFilters
                    ),
                    getComparator(order, orderBy)
                  ).map((item, index) =>
                    item.files ? (
                      <StackItem key={index} stack={item} revoked />
                    ) : item.type === "note" ? (
                      <NoteItem key={index} note={item} revoked />
                    ) : (
                      <FolderItem key={index} folder={item} revoked />
                    )
                  )}
            </TableBody>
          </Table>
        </TableContainer>
        {revokedStacksFoldersNotesCombined?.length === 0 && assetsDecrypted && (
          <EmptySpace dataText="archiveStack" />
        )}
      </Box>
    </Box>
  );
}

export default withRouter(RevokedFiles);
