import React, { forwardRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Gallery, darkTheme, lightTheme } from "@akord/ui";
import { Dialog, Slide, ThemeProvider } from "@mui/material";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { fileView, publicFileView } from "../../helpers/akord-actions";
import {
  getAllFoldersRecursively,
  getComparator,
  getDataRoomId,
  getDataRoomStatus,
  getFolderId,
  stableSort
} from "../../helpers/helpers";
import { filterDecryptedStacks } from "../../helpers/stack-helpers";
import { AssetsGalleryNavigation } from "./AssetsGalleryNavigation";
import "@akord/ui/dist/esm/index.css";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const { host, protocol } = window.location;
const baseUrl = `${protocol}//${host}`;

function AssetsGallery() {
  const {
    activeDecryptedStacks,
    activeDecryptedNotes,
    decryptedFolders,
    actionFilters,
    periodFilter,
    assetsEncrypter
  } = useAssetsContext();
  const { currentMembership, isVaultPublic } = useVaultContext();
  const { darkMode } = useGlobalContext();

  const history = useHistory();

  const [files, setFiles] = useState([]);
  const [isDarkTheme, setIsDarkTheme] = useState(darkMode);
  const [theme, setTheme] = useState(darkTheme);
  const [key, setKey] = useState(0);

  const folderId = getFolderId(history.location.pathname);
  const dataRoomId = getDataRoomId(history.location.pathname);
  const dataRoomStatus = getDataRoomStatus(history.location.pathname);
  const closeUrl = history.location.pathname.replace("gallery", "assets");

  useEffect(async () => {
    if (
      (activeDecryptedStacks && activeDecryptedStacks.length) ||
      (activeDecryptedNotes && activeDecryptedNotes.length)
    ) {
      const activeDecryptedAssets = [
        ...activeDecryptedStacks,
        ...activeDecryptedNotes
      ];

      setFiles(
        mapToFiles(
          stableSort(
            filterDecryptedStacks(
              filterCurrentFolderAssets(activeDecryptedAssets),
              periodFilter,
              actionFilters
            ),
            getComparator("asc", "title")
          )
        )
      );
    }
  }, [activeDecryptedStacks]);

  useEffect(async () => {
    setTheme(isDarkTheme ? darkTheme : lightTheme);
  }, [isDarkTheme]);

  useEffect(async () => {
    setKey(key + 1);
  }, [files]);

  /**
   * Function to load the file: downloads and decrypts the file based on metadata.
   * Called by the gallery when file is in the viewport
   *
   * @param {*} file
   * @returns Resolved file - downloaded & decrypted
   */
  const getFile = async file => {
    const blob = isVaultPublic
      ? await publicFileView(file)
      : await fileView(assetsEncrypter, file);
    // const blob = await fileView(assetsEncrypter, file);
    const src = URL.createObjectURL(blob);
    const type = (file.title.split(".").pop() || "").toLowerCase();
    return { src: src, type: type, fileName: file.title };
  };

  const getNote = async note => {
    return Promise.resolve({
      src: JSON.parse(note.content),
      type: "note",
      fileName: note.title
    });
  };

  const getStorageRefId = (asset, revision) => {
    const refId = asset.storageTransactions?.items?.find(
      tr => tr.refHash === revision.hash
    )?.refId;
    return refId;
  };

  const mapToFiles = assets => {
    const viewawbleFiles = [];
    assets.map((asset, idx) => {
      const revisions = asset.files || asset.revisions;
      return revisions.forEach((revision, revisionIndex) => {
        if (revisionIndex === revisions.length - 1) {
          revision.hash = asset.hash;
        }
        revision.title = asset.title;
        viewawbleFiles.push({
          hash: revision.hash,
          id: asset.id,
          txId: getStorageRefId(asset, revision),
          group: idx.toString(),
          version: revisionIndex,
          toLoad: revision,
          onLoad: asset.type === "note" ? getNote : getFile
        });
      });
    });
    return viewawbleFiles;
  };

  const filterCurrentFolderAssets = files => {
    return files?.filter(stack => stack.folderId === folderId);
  };

  const navigation = () => {
    const currentFolder = decryptedFolders?.filter(
      folder => folder.id === folderId
    )[0];

    const parentFolders = currentFolder
      ? getAllFoldersRecursively(decryptedFolders, currentFolder)
      : [];

    const dataRoomTitle = currentMembership.dataRoom.state.title;

    return (
      <AssetsGalleryNavigation
        dataRoomId={dataRoomId}
        dataRoomStatus={dataRoomStatus}
        dataRoomTitle={dataRoomTitle}
        currentFolder={currentFolder}
        parentFolders={parentFolders}
        folderId={folderId}
      />
    );
  };

  if (!files || !files.length) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        fullScreen
        open
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            overflowY: "hidden",
            backgroundColor: theme.palette.background.default
          }
        }}
      >
        <Gallery
          key={key}
          navigationComponent={navigation()}
          onThemeSwitch={() => setIsDarkTheme(!isDarkTheme)}
          onClose={() => history.push(closeUrl)}
          width="auto"
          height="auto"
          mode="single"
          loaderText="Decryption"
          transactionBaseUrl="https://viewblock.io/arweave/tx"
          darkMode={darkMode}
          printEnabled
          loaderEnabled
          downloadEnabled
          modeSwitchEnabled
          themeSwitchEnabled
          groupFilesEnabled
          viewTransactionEnabled
          publicShareEnabled={isVaultPublic}
          publicShareBaseUrl={`${baseUrl}/public`}
          files={files}
        ></Gallery>
      </Dialog>
    </ThemeProvider>
  );
}

export default AssetsGallery;
