import React from "react";
import { Checkbox } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { CheckboxTickIcon, CheckboxEmptyIcon } from "@akord/addon-icons";

const AkordCheckbox = withStyles(({ palette }) => ({
  root: {
    alignItems: "normal",
    padding: "0 8px",
    marginTop: "-3px"
  },
  checked: {
    color: palette.primary.main
  }
}))(props => (
  <Checkbox
    icon={<CheckboxEmptyIcon />}
    checkedIcon={<CheckboxTickIcon />}
    color="default"
    disableRipple
    {...props}
  />
));

export default AkordCheckbox;
