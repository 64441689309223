import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import SecureRoutes from "./RoutesSecure";
import PublicRoutes from "./PublicRoutes";
import SignupPage from "../../pages/signup";
import LoginPage from "../../pages/login";
import RecoverPage from "../../pages/recover";
import { Amplify } from "aws-amplify";
import awsconfig from "../../aws-exports";
import LoginContextProvider from "../../contexts/LoginContext";
import SignupContext from "../../contexts/SignupContext";
import RecoverAccountContext from "../../contexts/RecoverAccountContext";
import SnackbarContextProvider from "../../contexts/SnackbarContextProvider";
import NotFoundPage from "../../pages/not-found-page";
import TeaPotPage from "../../pages/tea-pot";

Amplify.configure(awsconfig);

const Routes = props => {
  const [fontsReady, setFontsReady] = React.useState(false);
  const loader = document.querySelector(".preloader");
  const main = document.querySelector("#main");
  const addClass = () => {
    loader?.classList.add("loader-hide");
    main?.classList.add("app-show");
  };

  React.useEffect(() => addClass(), []);

  document.fonts.ready.then(() => setFontsReady(true));

  if (!fontsReady) return null;

  return (
    <SnackbarContextProvider>
      <Switch>
        <Route
          path="/sign-in"
          exact
          render={() => (
            <LoginContextProvider>
              <LoginPage {...props} />
            </LoginContextProvider>
          )}
        />
        <Route exact path="/404" component={NotFoundPage} />
        <Route exact path="/418" component={TeaPotPage} />
        <Route
          path="/login"
          render={props => (
            <Redirect
              to={{ pathname: "/sign-in/", search: props.location.search }}
            />
          )}
        ></Route>
        <Route
          path="/recover"
          render={() => (
            <RecoverAccountContext>
              <RecoverPage {...props} />
            </RecoverAccountContext>
          )}
        ></Route>
        <Route
          path="/signup"
          render={() => (
            <SignupContext>
              <SignupPage />
            </SignupContext>
          )}
        />
        <Route path="/public" render={() => <PublicRoutes />} />
        <Route path="/" render={() => <SecureRoutes {...props} />} />
      </Switch>
    </SnackbarContextProvider>
  );
};

export default Routes;
