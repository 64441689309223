import API, { graphqlOperation } from "@aws-amplify/api";
import {
  Box,
  FormControl,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { Skeleton, ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  CardElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CountrySelect, FormSubmit } from "../../components/common/";
import ButtonGroup from "../../components/common/ButtonGroup";
import { CreditCardIcon, StripeClimateIcon } from "@akord/addon-icons";
import QuatitySelector from "../../components/common/QuantitySelector";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import * as mutations from "../../graphql/mutations";
import { blue, grey } from "../../theme/colors/";
import StripeInput from "./stripe/StripeInput";
import TopupSummaryModal from "./TopupSummaryModal";

const StyledToggleButton = withStyles(theme => ({
  root: {
    width: "100%",
    border: "1px solid",
    borderColor: blue[400],
    color: blue[400],
    textTransform: "none",
    transition: ".3s ease",
    borderRadius: ({ index }) =>
      index === 0 ? theme.shape.borderRadius : "4px 4px 0 4px",
    "&:hover": {
      backgroundColor: blue[600],
      color: "#FFF",
      "& .priceTag": {
        color: "white"
      }
    },
    "&$selected": {
      background: blue[600],
      color: "white",
      "& .priceTag": {
        color: "white"
      },
      "&:hover": {
        backgroundColor: blue[600]
      }
    },
    "& .priceTag": {
      color: theme.palette.text.primary
    }
  },
  selected: {
    background: blue[600]
  }
}))(ToggleButton);

const StyledToggleButtonGroup = withStyles(theme => ({
  root: {
    display: "flex",
    "@media (min-width: 720px)": {
      flexDirection: "row"
    },
    flexDirection: "column"
  },
  grouped: {
    flex: "1",
    "@media (min-width: 720px)": {
      marginBottom: 0,
      "&:not(:last-child)": {
        paddingRight: theme.spacing(4)
      },
      "&:first-child": {
        paddingRight: theme.spacing(4)
      }
    },
    marginBottom: "24px"
  }
}))(ToggleButtonGroup);

const useStyles = makeStyles(theme => ({
  promoText: {
    backgroundColor: blue[400],
    color: grey[900],
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: "0 0 4px 0"
  },
  ccText: {
    fontSize: "16px",
    color: "white",
    "::placeholder": {
      color: grey[400]
    }
  },
  rowForm: {
    display: "flex",
    flexDirection: ({ isMobile }) => (isMobile ? "column" : "row")
  },
  rowField: {
    flex: "1",
    "&:not(:last-child)": {
      paddingRight: ({ isMobile }) => !isMobile && theme.spacing(4)
    },
    "&:first-child": {
      paddingRight: ({ isMobile }) => !isMobile && theme.spacing(4)
    }
  },
  rowSkeleton: {
    marginBottom: 32,
    borderRadius: 4,
    flexGrow: 1,
    "&:nth-child(2)": {
      marginLeft: 16,
      marginRight: 16
    }
  },
  rowFields: {
    "@media (min-width: 660px)": {
      flex: 1
    },
    "@media (min-width: 766px)": {
      flex: 2
    },
    "&:not(:last-child)": {
      paddingRight: ({ isMobile }) => !isMobile && theme.spacing(4)
    },
    "&:first-child": {
      paddingRight: ({ isMobile }) => !isMobile && theme.spacing(4)
    }
  }
}));

const CardForm = ({ products }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { wallet, isMobile, darkMode } = useGlobalContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const [selectedProduct, setSelectedProduct] = useState();
  const [confirmStripeModal, setConfirmStripeModal] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);
  const handleCardComplete = status => setCardComplete(status);
  const [formData, setFormData] = useState({
    name: "",
    companyName: "",
    companyVat: "",
    country: "",
    address: "",
    city: "",
    state: "",
    zip: ""
  });
  const [loading, setLoading] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const history = useHistory();
  const classes = useStyles({ isMobile: isMobile, darkMode: darkMode });

  const handleConfirmStripeModalClose = () => {
    setConfirmStripeModal(false);
  };

  const handleSelectedProduct = product => {
    if (product.promoText) {
      setQuantity(1);
    }
    setSelectedProduct(product);
  };

  useEffect(() => {
    if (products) handleSelectedProduct(products[0]);
  }, [products]);

  const handlePaymentDataChanged = () => event => {
    const { target } = event;
    setFormData({ ...formData, [target.name]: target.value });
  };

  const handleCountrySelected = () => (event, value) => {
    setFormData({ ...formData, country: value ? value.code : null });
  };

  const getSelectedStorage = () => {
    return selectedProduct.promoText
      ? selectedProduct.storage
      : selectedProduct.storage * quantity;
  };

  const renderStorage = item => {
    if (!item) return "-";
    const storage = item?.promoText ? item.storage : item.storage * quantity;
    return storage;
  };

  const getSelectedPrice = () => {
    return selectedProduct.promoText
      ? selectedProduct.amount
      : selectedProduct.amount * quantity;
  };

  // TODO: add currency symbol to product @stripe
  const renderPrice = item => {
    if (!item) return "-";
    const amount = item?.promoText ? item.amount : item.amount * quantity;
    return `$${amount / 100}`;
  };

  const isButtonDisabled = () => {
    return !(
      formData.name.length > 0 &&
      formData.country.length > 0 &&
      formData.city.length > 0 &&
      formData.address.length > 0 &&
      formData.zip.length > 0 &&
      formData.zip.length < 12 &&
      cardComplete &&
      !loading
    );
  };

  const createPaymentIntent = async () => {
    const paymentIntentResponse = await API.graphql(
      graphqlOperation(mutations.createPaymentIntent, {
        topUp: {
          operation: "PAYMENT_INTENT",
          publicSigningKey: await wallet.signingPublicKey(),
          productId: selectedProduct.id,
          amount: getSelectedPrice(),
          storage: getSelectedStorage(),
          quantity: quantity,
          currency: selectedProduct.currency,
          promo: selectedProduct.promoText
        }
      })
    );

    const error = paymentIntentResponse.data?.createPaymentIntent?.error;
    const clientSecret =
      paymentIntentResponse.data?.createPaymentIntent?.clientSecret;
    const paymentIntentId =
      paymentIntentResponse.data?.createPaymentIntent?.paymentIntentId;

    if (error) {
      setLoading(false);
      onSnackbarToShow("topUpFailure", error.message, "error");
      return;
    }
    return { clientSecret, paymentIntentId };
  };

  const handlePaymentPostProcessing = async (paymentIntentId, error) => {
    if (error) {
      setLoading(false);
      onSnackbarToShow("topUpFailure", error.message, "error");
      await API.graphql(
        graphqlOperation(mutations.rejectPayment, {
          topUp: {
            operation: "PAYMENT_REJECT",
            paymentIntentId: paymentIntentId
          }
        })
      );
      return;
    }

    await API.graphql(
      graphqlOperation(mutations.confirmPayment, {
        topUp: {
          operation: "PAYMENT_CONFIRM",
          paymentIntentId: paymentIntentId,
          publicSigningKey: await wallet.signingPublicKey(),
          currency: selectedProduct.currency,
          storage: getSelectedStorage(),
          amount: getSelectedPrice(),
          quantity: quantity,
          name: formData.name,
          country: formData.country,
          company: `${formData.companyName} ${formData.companyVat}`,
          address: formData.address,
          city: formData.city,
          postalCode: formData.zip
        }
      })
    );
    setLoading(false);
    onSnackbarToShow("topUpSuccess");
    history.push("/storage");
  };

  const handleMobilePayment = async e => {
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);

    const { clientSecret, paymentIntentId } = await createPaymentIntent();

    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: e.paymentMethod.id
      },
      { handleActions: false }
    );

    handlePaymentPostProcessing(paymentIntentId, error);

    if (error) {
      onSnackbarToShow("topUpFailure", error.message, "error");
      e.complete("fail");
    }
    e.complete("success");
    if (paymentIntent.status === "requires_action") {
      stripe.confirmCardPayment(clientSecret);
    }
  };

  const handleCardPayment = async () => {
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);

    const { clientSecret, paymentIntentId } = await createPaymentIntent();

    const { error } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: formData.name,
          address: {
            city: formData.city,
            state: formData.state,
            line1: formData.address,
            postal_code: formData.zip
          }
        }
      }
    });

    handlePaymentPostProcessing(paymentIntentId, error);
  };

  // useEffect(() => {
  //   if (!stripe || !elements) {
  //     return;
  //   }

  //   setPaymentRequest(null)
  //   const pr = stripe.paymentRequest({
  //     country: 'US',
  //     currency: 'usd',
  //     total: {
  //       label: 'Permanent Storage',
  //       amount: selectedProduct.amount * quantity,
  //     },
  //     requestPayerName: true,
  //     requestPayerEmail: true,
  //   });

  //   pr.canMakePayment().then(result => {
  //     console.log(result)
  //     if (result) {
  //       console.log(pr)
  //       setPaymentRequest(pr)
  //     }
  //   });
  // }, [selectedProduct, quantity, stripe, elements])

  useEffect(() => {
    if (!stripe || !elements || !paymentRequest) {
      return;
    }
    paymentRequest.on("paymentmethod", async e => {
      handleMobilePayment(e);
    });
    setPaymentRequest(null);
  }, [paymentRequest]);

  return (
    <>
      {confirmStripeModal && (
        <TopupSummaryModal
          formData={formData}
          selectedProduct={selectedProduct}
          quantity={quantity}
          handleCardPayment={handleCardPayment}
          openConfirmStripeModal={confirmStripeModal}
          onConfirmStripeModalClose={handleConfirmStripeModalClose}
          loading={loading}
        />
      )}
      <Box mt="6" pb={isMobile ? "56px" : 0}>
        <Box>
          <Box mb={2}>
            <Typography variant="h1" className="xxl">
              Pay once, store forever.
            </Typography>
          </Box>
          <Typography variant="body1" component="div">
            Forget about never-ending monthly payments, secure your data storage
            for generations with one payment.
          </Typography>
        </Box>
        {products ? (
          <>
            <Box mt={8}>
              <StyledToggleButtonGroup exclusive>
                {products.map((productItem, i) => (
                  <Box key={i}>
                    <StyledToggleButton
                      index={i}
                      value={productItem}
                      selected={productItem?.amount === selectedProduct?.amount}
                      onClick={() => handleSelectedProduct(productItem)}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        minWidth="100px"
                        width="100%"
                      >
                        <Box display="flex">
                          <Typography variant="h1" color="inherit">
                            {renderStorage(productItem)}
                          </Typography>
                          <Typography
                            variant="h1"
                            color="inherit"
                            style={{
                              fontVariant: "small-caps",
                              whiteSpace: "pre"
                            }}
                          >
                            {" "}
                            gb
                          </Typography>
                        </Box>
                        <Typography
                          variant="h1"
                          className="priceTag"
                          color="text.primary"
                        >
                          {renderPrice(productItem)}
                        </Typography>
                      </Box>
                    </StyledToggleButton>
                    <Box display="flex" justifyContent="flex-end">
                      {productItem.promoText ? (
                        <Typography
                          variant="body2"
                          className={[
                            classes.promoText,
                            "strong",
                            "small"
                          ].join(" ")}
                        >
                          {`${productItem.promoText} off`}
                        </Typography>
                      ) : (
                        <QuatitySelector
                          onQuantityChange={setQuantity}
                        ></QuatitySelector>
                      )}
                    </Box>
                  </Box>
                ))}
              </StyledToggleButtonGroup>
            </Box>
            {paymentRequest && (
              <Box marginTop="5%">
                <PaymentRequestButtonElement options={{ paymentRequest }} />
              </Box>
            )}
            <Box mt={6}>
              <FormSubmit onSubmit={() => handleCardPayment()}>
                <Box mb={4}>
                  <Typography variant="h3" component="div">
                    Payment details
                  </Typography>
                </Box>
                <Box mb={2}>
                  <FormControl fullWidth>
                    <TextField
                      margin="none"
                      // error={!!error}
                      label="Name"
                      variant="outlined"
                      name="name"
                      value={formData.name}
                      onChange={handlePaymentDataChanged()}
                    />
                  </FormControl>
                  <Box mb={2}>
                    <FormControl className={classes.rowForm}>
                      <TextField
                        // error={!!error}
                        label="Company (optional)"
                        variant="outlined"
                        name="companyName"
                        value={formData.companyName}
                        onChange={handlePaymentDataChanged()}
                        className={classes.rowFields}
                      />
                      <TextField
                        // error={!!error}
                        label="Tax ID (optional)"
                        variant="outlined"
                        name="companyVat"
                        value={formData.companyVat}
                        onChange={handlePaymentDataChanged()}
                        className={classes.rowField}
                      />
                    </FormControl>
                  </Box>
                </Box>
                <Box mb={2}>
                  <FormControl fullWidth>
                    <TextField
                      // error={!!error}
                      label="Address"
                      variant="outlined"
                      name="address"
                      value={formData.address}
                      onChange={handlePaymentDataChanged()}
                    />
                  </FormControl>
                </Box>
                <Box mb={2}>
                  <FormControl className={classes.rowForm}>
                    <TextField
                      // error={!!error}
                      label="City"
                      variant="outlined"
                      name="city"
                      value={formData.city}
                      onChange={handlePaymentDataChanged()}
                      className={classes.rowField}
                    />
                    <TextField
                      // error={!!error}
                      label="State"
                      variant="outlined"
                      name="state"
                      value={formData.state}
                      onChange={handlePaymentDataChanged()}
                      className={classes.rowField}
                    />
                    <TextField
                      // error={!!error}
                      label="Zip"
                      variant="outlined"
                      name="zip"
                      onChange={handlePaymentDataChanged()}
                      value={formData.zip}
                      className={classes.rowField}
                    />
                  </FormControl>
                </Box>
                <Box mb={2}>
                  <FormControl fullWidth>
                    <CountrySelect
                      onCountrySelect={handleCountrySelected()}
                    ></CountrySelect>
                  </FormControl>
                </Box>
                <TextField
                  // label="Card number"
                  name="ccard"
                  variant="outlined"
                  fullWidth
                  // InputLabelProps={{ shrink: true }}
                  onChange={e => handleCardComplete(e.complete)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{
                          position: isMobile ? "inherit" : "absolute"
                        }}
                      >
                        <CreditCardIcon color="disabled" />
                      </InputAdornment>
                    ),
                    inputComponent: StripeInput,
                    inputProps: {
                      component: CardElement,
                      options: {
                        hidePostalCode: true,
                        // hideIcon: true,
                        style: {
                          base: {
                            iconColor: "transparent",
                            fontFamily: [
                              "Larsseit-Regular",
                              "-apple-system",
                              "BlinkMacSystemFont",
                              "Segoe UI",
                              "Roboto",
                              "Oxygen-Sans",
                              "Ubuntu",
                              "Cantarell",
                              "Helvetica Neue",
                              "sans-serif"
                            ].join(","),
                            fontSize: "18px",
                            color: darkMode ? "white" : "inherit",
                            "::placeholder": {
                              color: grey[400]
                            }
                          }
                        }
                      }
                    }
                  }}
                />
                <Box pb={4}>
                  <ButtonGroup
                    nextText="Review and make payment"
                    nextDiasabled={isButtonDisabled()}
                    handleEnd={() => setConfirmStripeModal(true)}
                    hideBackButton={true}
                    fullWidthNextButton={true}
                    hideIcon={true}
                    //  errorText={getErrorText()}
                    disabledRed={false}
                    loading={loading}
                    noMarginTop={true}
                  />
                </Box>
              </FormSubmit>
              <Box display="flex" alignItems="center" pb={4}>
                <StripeClimateIcon
                  fontSize="small"
                  style={{ marginRight: "5px" }}
                />
                <Typography
                  variant="caption"
                  color="text.tertiary"
                  className="small"
                >
                  Akord will contribute 2% of your purchase to remove CO2 from
                  the atmosphere.
                </Typography>
              </Box>
            </Box>
          </>
        ) : (
          <Box mt={8}>
            {Array.from(new Array(9)).map((item, index) =>
              index === 0 ? (
                <Box key={index} display="flex" flexDirection="row">
                  {Array.from(new Array(3)).map((i, idx) => (
                    <Skeleton
                      key={idx}
                      variant="rectangular"
                      height={56}
                      width="30%"
                      className={classes.rowSkeleton}
                    />
                  ))}
                </Box>
              ) : (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  height={56}
                  style={{ marginBottom: 32, borderRadius: 4 }}
                />
              )
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default CardForm;
