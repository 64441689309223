import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useMiniSearch } from "react-minisearch-alpha";
import {
  fromFolders,
  fromMemberships,
  fromStacks,
  // fromTimeline,
  fromMemos
} from "../components/search/document";
import { documentAction } from "../components/search/document-action";
import { searchConfig } from "../components/search/search-config";
import { useGlobalContext } from "./GlobalDataProvider";
// import { useTimelineContext } from "./TimelineContextProvider";
import { useAssetsContext } from "./AssetsContextProvider";
import { useChatContext } from "./ChatContextProvider";

const Context = createContext();

function SearchContextProvider({ children }) {
  const { decryptedMemberships, isMembershipsLoaded } = useGlobalContext();
  const { decryptedStacks, decryptedFolders } = useAssetsContext();

  // const { decryptedTimeline } = useTimelineContext();
  const { decryptedMemos } = useChatContext();

  const [recentSearchTerm, setRecentSearchTerm] = useState();
  const handleRecentSearchTerm = value => setRecentSearchTerm(value);

  const [searchActive, setSearchActive] = useState();
  const handleSearchActive = isSearchActive => setSearchActive(isSearchActive);

  const [isAutosuggesting, setIsAutosuggesting] = useState();
  const handleIsAutosuggesting = isAutosuggesting =>
    setIsAutosuggesting(isAutosuggesting);

  const [cachedSearchResults, setCachedSearchResults] = useState({});
  const handleCachedSearchResults = results => setCachedSearchResults(results);

  const [isOnSearchResults, setIsOnSearchResults] = useState(false);

  const {
    search,
    rawResults,
    addAllAsync,
    isIndexing,
    miniSearch,
    clearSearch,
    removeAll,
    removeById
  } = useMiniSearch([], searchConfig);

  const location = useLocation();

  useEffect(() => {
    if (isMembershipsLoaded && decryptedMemberships) {
      const documents = fromMemberships(decryptedMemberships);
      index(documents);
    }
  }, [decryptedMemberships, isMembershipsLoaded]);

  useEffect(() => {
    if (decryptedStacks) {
      const documents = fromStacks(decryptedStacks);
      index(documents);
    }
  }, [decryptedStacks]);

  useEffect(() => {
    if (decryptedFolders) {
      const documents = fromFolders(decryptedFolders);
      index(documents);
    }
  }, [decryptedFolders]);

  // useEffect(() => {
  //   if (decryptedTimeline) {
  //     const documents = fromTimeline(decryptedTimeline);
  //     index(documents);
  //   }
  // }, [decryptedTimeline]);

  useEffect(() => {
    if (decryptedMemos) {
      const documents = fromMemos(decryptedMemos);
      index(documents);
    }
  }, [decryptedMemos]);

  useEffect(() => {
    setIsOnSearchResults(location.pathname.match("search-results"));
  }, [location]);

  const repeatSearchIfOnSearchResults = () => {
    if (isOnSearchResults) {
      search(recentSearchTerm);
    }
  };

  const index = async documents => {
    if (Array.isArray(documents) && documents.length > 0) {
      removeAll(documents, { ignoreIfMissing: true });
      await addAllAsync(
        documents.filter(
          document => document && document.action === documentAction.PUT
        )
      );
      repeatSearchIfOnSearchResults();
    }
  };

  const removeFromIndex = id => {
    try {
      removeById(id);
    } catch {
      console.warn("Index: could not remove document");
    }
  };

  return (
    <Context.Provider
      value={{
        searchResults: rawResults,
        cachedSearchResults: cachedSearchResults,
        onCachedSearchResults: handleCachedSearchResults,
        onSearch: search,
        isIndexing,
        clearSearch,
        index: miniSearch,
        removeFromIndex: removeFromIndex,
        recentSearchTerm: recentSearchTerm,
        onRecentSearchTerm: handleRecentSearchTerm,
        isSearchActive: searchActive,
        onSearchActive: handleSearchActive,
        isAutosuggesting: isAutosuggesting,
        onIsAutosuggesting: handleIsAutosuggesting
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default SearchContextProvider;

export const useSearchContext = () => useContext(Context);
