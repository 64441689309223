import {
  Box,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { getFolderId } from "../../../helpers/helpers";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  RadioEmptyIcon,
  RadioCheckedIcon
} from "@akord/addon-icons";

function MoveModalContent({ onSelectedFolderId, selectedFolderId }) {
  const { currentMembership } = useVaultContext();
  const { activeDecryptedFolders } = useAssetsContext();
  const { onNotificationData, notificationData } = useNotificationsContext();
  const location = useLocation();
  const folderId = getFolderId(location.pathname);
  const isRevoked = !!location.pathname.match("revoked-files");
  const [folderActiveId, setFolderActiveId] = useState(null);
  const handleFolderActiveId = id => setFolderActiveId(id);

  const [dataRoomLevel, setDataRoomLevel] = useState(false);
  const handleDataRoomLevel = value => setDataRoomLevel(value);

  useEffect(() => {
    handleFolderActiveId(folderId);
    onSelectedFolderId(null);
    if (folderId || isRevoked) handleDataRoomLevel(true);
  }, [folderId]);

  const checkForSubfolders = id => {
    return !!activeDecryptedFolders.filter(folder => folder.folderId === id)[0];
  };

  const foldersToShow = id =>
    activeDecryptedFolders
      .filter(
        folder =>
          folder.folderId === id &&
          folder.hash !== notificationData.hash &&
          !notificationData.batchItems?.has(folder.hash) // Exclude folder which will be moved from the shown list
      )
      .sort((a, b) => {
        if (a.title < b.title) return -1;
        else if (a.title > b.title) return 1;
        else return 0;
      });

  const handleFolderBack = id => {
    const filtered = activeDecryptedFolders.filter(folder => folder.id === id);
    if (!filtered[0]) handleDataRoomLevel(true);
    handleFolderActiveId(filtered[0] ? filtered[0].folderId : null);
  };

  const handleDestinationSelection = e => {
    onSelectedFolderId(e);
    onNotificationData({
      ...notificationData,
      folderId: e.target.value || null
    });
  };

  return (
    <>
      {dataRoomLevel ? (
        <Typography variant="body2" color="text.secondary">
          Select destination:
        </Typography>
      ) : (
        <IconButton
          disableRipple
          size="small"
          aria-label="back"
          aria-controls="menu-appbar"
          onClick={() => {
            handleFolderBack(folderActiveId);
          }}
          style={{ padding: 0, justifyContent: "start" }}
        >
          <ChevronLeftIcon />
          <Typography variant="body2" color="text.secondary" className="strong">
            Back
          </Typography>
        </IconButton>
      )}
      <DialogContent style={{ marginTop: 8 }}>
        <Divider />
        <TableContainer>
          <Table stickyHeader>
            <TableBody>
              {dataRoomLevel ? (
                <TableRow hover>
                  <TableCell style={{ padding: "8px" }}>
                    <Box display="flex" justifyContent="space-between">
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="folder-root"
                          name="folder-root"
                          value={selectedFolderId}
                          onChange={e => handleDestinationSelection(e)}
                        >
                          <FormControlLabel
                            value=""
                            control={
                              <Radio
                                icon={<RadioEmptyIcon />}
                                checkedIcon={<RadioCheckedIcon />}
                              />
                            }
                            label={
                              <Typography>
                                {currentMembership.dataRoom.state.title}
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                      <IconButton
                        disableRipple
                        size="small"
                        aria-label="nextfolder"
                        aria-controls="move-control"
                        onClick={() => {
                          handleFolderActiveId(null);
                          handleDataRoomLevel(false);
                        }}
                        style={{ marginRight: "9px" }}
                      >
                        <ChevronRightIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                foldersToShow(folderActiveId).map((folder, index) => (
                  <TableRow hover key={index}>
                    <TableCell style={{ padding: "8px" }}>
                      <Box display="flex" justifyContent="space-between">
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="folder"
                            name="folder1"
                            value={selectedFolderId}
                            onChange={e => handleDestinationSelection(e)}
                          >
                            <FormControlLabel
                              value={folder.id}
                              control={
                                <Radio
                                  icon={<RadioEmptyIcon />}
                                  checkedIcon={<RadioCheckedIcon />}
                                />
                              }
                              label={<Typography>{folder.title}</Typography>}
                            />
                          </RadioGroup>
                        </FormControl>
                        {checkForSubfolders(folder.id) && (
                          <IconButton
                            disableRipple
                            size="small"
                            aria-label="next"
                            aria-controls="move-control"
                            onClick={() => {
                              handleFolderActiveId(folder.id);
                            }}
                            style={{ marginRight: "9px" }}
                          >
                            <ChevronRightIcon />
                          </IconButton>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </>
  );
}

export default MoveModalContent;
