import React from "react";
import {
  PadlockIcon,
  BellIcon,
  ArchiveIcon,
  RevokedFileIcon,
  WavingHandIcon,
  RefferalIcon,
  VaultIcon,
  PlusIcon,
  TeamIcon,
  GlobeIcon
} from "@akord/addon-icons";
import {
  getDataRoomStatus,
  getDataRoomId,
  getFolderId
} from "../../../helpers/helpers";
import { Typography } from "@mui/material";

export const emptySpaceData = {
  newRoomWelcome: {
    title: "Welcome, Akordian.",
    getTextEl: () => (
      <Typography variant="body2" align="center" component="span">
        To realise all the possibilities of the social vault, don’t forget
        you’ll need to invite others.
      </Typography>
    ),
    getTextElSecond: () => (
      <Typography variant="body2" align="center" component="span">
        Invite someone new to Akord from within a vault, or send them a referral
        code from.
      </Typography>
    ),
    buttonText: () => "Create a new vault",
    icon: WavingHandIcon,
    iconSecond: RefferalIcon,
    handleClick: history => {
      history.push("/create-vault");
    },
    topMargin: "52px",
    topMarginXS: "40px",
    bottomMarginXS: "56px",
    welcomeScreen: true
  },
  newRoom: {
    title: "Time to secure your data – permanently.",
    getTextEl: () => (
      <Typography variant="body2" align="center" component="span">
        Create your first vault, invite collaborators, message and store assets
        permanently with end-to-end encryption.
      </Typography>
    ),
    buttonText: () => "Create a new vault",
    icon: PadlockIcon,
    handleClick: history => {
      history.push("/create-vault");
    },
    topMargin: "52px",
    topMarginXS: "40px"
  },
  newRoomOnBoard: {
    title: "Create your first vault",
    getTextEl: () => (
      <Typography variant="body2" align="center" component="span">
        Vaults in Akord are where you upload your digital assets, invite others
        to share and collaborate, as well as send end-to-end encrypted messages.
      </Typography>
    ),
    buttonText: () => "Create vault",
    icon: VaultIcon,
    handleClick: history => {
      history.push("/create-vault");
    },
    topMargin: "52px",
    topMarginXS: "40px"
  },
  newFile: {
    title: "Add an asset",
    getTextEl: () => (
      <Typography variant="body2" align="center" component="span">
        Upload a file or create a note using Akord’s end-to-end encryption.
      </Typography>
    ),
    buttonText: () => "Upload a file",
    icon: PlusIcon,
    handleClick: history => {
      history.push("/create-vault");
    },
    buttonTextSecond: "Create a note",
    handleClickSecond: history => {
      history.push({
        pathname: `/vaults/${getDataRoomStatus(
          history.location.pathname
        )}/${getDataRoomId(history.location.pathname)}/assets/create-note`,
        state: { folderId: getFolderId(history.location.pathname) }
      });
    },
    noMinWidth: true,
    topMargin: "52px"
  },
  newPublicFile: {
    title: "Upload a public file",
    getTextEl: () => (
      <Typography variant="body2" align="center" component="span">
        Files will be stored unencrypted on the Arweave blockchain.
      </Typography>
    ),
    buttonText: () => "Upload a file",
    icon: GlobeIcon,
    handleClick: history => {
      history.push("/create-vault");
    },
    buttonTextSecond: "Create a note",
    handleClickSecond: history => {
      history.push({
        pathname: `/vaults/${getDataRoomStatus(
          history.location.pathname
        )}/${getDataRoomId(history.location.pathname)}/assets/create-note`,
        state: { folderId: getFolderId(history.location.pathname) }
      });
    },
    noMinWidth: true,
    topMargin: "52px"
  },
  notifications: {
    title: "All clear",
    getTextEl: () => (
      <Typography variant="body2" align="center" component="span">
        You have no notifications.
      </Typography>
    ),
    icon: BellIcon,
    buttonText: () => null,
    topMargin: "52px"
  },
  archivedRooms: {
    title: "No active vaults",
    getTextEl: () => (
      <Typography variant="body2" align="center" component="span">
        All your vaults have been archived
      </Typography>
    ),
    buttonText: () => "Create vault",
    icon: PadlockIcon,
    handleClick: history => {
      history.push("/create-vault");
    },
    topMargin: "52px"
  },
  archive: {
    title: "All clear",
    getTextEl: () => (
      <Typography variant="body2" align="center" component="span">
        You have no archived vaults.
      </Typography>
    ),
    icon: ArchiveIcon,
    buttonText: () => null,
    topMargin: "52px"
  },
  archiveStack: {
    title: "All clear",
    getTextEl: () => (
      <Typography variant="body2" align="center" component="span">
        You have no revoked files.
      </Typography>
    ),
    icon: RevokedFileIcon,
    buttonText: () => null,
    topMargin: "52px"
  },
  pendingVault: {
    title: "Pending vault",
    getTextEl: () => (
      <Typography variant="body2" align="center" component="span">
        You’ve been invited to this vault, but your access is pending a final
        access confirmation.
      </Typography>
    ),
    icon: VaultIcon,
    topMargin: "52px",
    buttonText: () => null
    // handleClick: history => {
    //   history.push('/create-vault')
    // },
  },
  emptyMemos: {
    title: "A social vault",
    getTextEl: (_, userRole) => (
      <Typography variant="body2" align="center" component="span">
        {userRole === "OWNER"
          ? `Invite someone to your vault, if you haven’t already. Your messages will be
        stored onchain and protected with end-to-end encryption.`
          : `Akord vault’s are not just for storing files – chat with the other members of this vault using the message bar below.`}
      </Typography>
    ),
    icon: TeamIcon,
    topMargin: "52px",
    buttonText: userRole => (userRole === "OWNER" ? "Send an invite" : null),
    handleClick: (history, currentMembership) => {
      history.push({
        pathname: `/vaults/${getDataRoomStatus(
          history.location.pathname
        )}/${getDataRoomId(history.location.pathname)}/invite-to-vault`,
        state: currentMembership
      });
    }
  }
};
