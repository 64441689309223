import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { v4 as uuidv4 } from "uuid";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { colorMap } from "../../timeline/timeline-actions/colorMap";
import { isArchived } from "../../../helpers/helpers";
import { mapMemberDetails } from "../../../helpers/decrypt-helper";
import { cloneDeep } from "lodash";
import { manageAccessStatusCase } from "./ManageAccessStatusCase";
import { AvatarIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { SupportScreenWrapper } from "../../../components/common";
import { withRouter } from "react-router";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { changeAccess } from "../../../helpers/manage-access-actions";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { memberStatusEnum } from "../../../helpers/akord-enums";
import { EncrypterFactory } from "@akord/crypto";
import { fromMembership } from "@akord/crypto";

const useStyles = makeStyles(theme => ({
  containedPrimary: {
    marginTop: theme.spacing(7),
    minWidth: "auto"
  },
  iconExtraLarge: {
    fontSize: "38px"
  },
  avatar: {
    width: "38px",
    height: "38px",
    borderRadius: "50%"
  },
  buttonProgress: {
    position: "absolute"
  }
}));

function ManageAccess({ match }) {
  const {
    decryptedMemberships,
    isMobile,
    darkMode,
    onTxSpinner,
    wallet,
    decryptedProfileDetails
  } = useGlobalContext();
  const { ledgerWrapper, userRole } = useVaultContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const { modal, onNotificationData } = useNotificationsContext();

  const [memberRole, setMemberRole] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [fullyDecriptedMembership, setFullyDecriptedMembership] = useState();
  // const membership = props.location.state
  const dataRoomId = match.params.dataRoomId;
  const currentMembership = decryptedMemberships?.filter(
    decryptedMembership => decryptedMembership?.dataRoomId === dataRoomId
  )[0];

  const isRoomArchived = isArchived(currentMembership);
  const classes = useStyles({ darkMode: darkMode });

  React.useEffect(() => {
    const decryptAndSet = async () => {
      const activeMembers = currentMembership.dataRoom.members.items.filter(
        member =>
          member.state.role !== "OWNER" &&
          (member.state.status === memberStatusEnum.ACCEPTED ||
            member.state.status === memberStatusEnum.REVOKED ||
            member.state.status === memberStatusEnum.PENDING ||
            member.state.status === memberStatusEnum.REJECTED ||
            member.state.status === memberStatusEnum.INVITED)
      );
      activeMembers.map(
        member => (member.state.originalRole = member.state.role)
      );

      const decryptedAvatarsMap = new Map();
      const encryptionKeys = fromMembership(currentMembership);
      const encrypter = new EncrypterFactory(
        wallet,
        encryptionKeys
      ).encrypterInstance();

      const currentMembershipCopy = cloneDeep(currentMembership);
      const decryptedMembers = await Promise.all(
        currentMembershipCopy?.dataRoom?.members?.items?.map(async member => {
          if (member?.email === decryptedProfileDetails?.email)
            member.state.memberDetails = decryptedProfileDetails;
          else
            member.state.memberDetails = await mapMemberDetails(
              member,
              encrypter,
              decryptedAvatarsMap
            );
          return member;
        })
      );
      currentMembershipCopy.dataRoom.members.items = decryptedMembers;
      setFullyDecriptedMembership(currentMembershipCopy);
      setMemberRole(activeMembers);
    };

    if (currentMembership) decryptAndSet();
  }, [currentMembership]);

  const isButtonDisabled = () => {
    return (
      !memberRole.some(
        member => member.state.role !== member.state.originalRole
      ) || buttonLoading
    );
  };

  const handleFormChange = (e, elIndex, member) => {
    setMemberRole(
      memberRole.map(memberState =>
        memberState.state.memberDetails.email === member.memberDetails.email
          ? {
              ...memberState,
              state: { ...memberState.state, [e.target.name]: e.target.value }
            }
          : memberState
      )
    );
  };

  const updateMembersRole = async () => {
    const changedRoleToSave = memberRole.filter(
      member => member.state.role !== member.state.originalRole
    );
    const groupRef = uuidv4();

    changedRoleToSave.map(async member => {
      if (member.state.role === "REVOKED") {
        // Revoke access through Modal Confirm
        onNotificationData({
          memberToRevoke: member.state,
          hash: currentMembership.dataRoom.members.items.filter(
            memberState =>
              memberState.state.memberDetails.email ===
              member.state.memberDetails.email
          )[0].hash
        });
        modal.onConfirmModalType("revoke");
        modal.onModalVisibility(null, "revoke");
        return;
      }
      // Change Access
      onTxSpinner(true);
      setButtonLoading(true);
      await changeAccess(ledgerWrapper, member, groupRef);
      setButtonLoading(false);
      onTxSpinner(false);
      onSnackbarToShow("dataRoomAccessUpdate");
    });
  };

  if (!decryptedMemberships) return null;

  const filteredSortedMembers =
    fullyDecriptedMembership?.dataRoom?.members?.items
      .sort((a, b) => {
        if (a.createdAt > b.createdAt) return 1;
        if (a.createdAt < b.createdAt) return -1;
        return 0;
      })
      .filter(item => item.status !== "REVOKED" || item.status !== "REJECTED");

  return (
    <SupportScreenWrapper
      title="Manage access"
      subtitle={currentMembership.dataRoom.state.title}
    >
      {filteredSortedMembers?.length > 0 && (
        <Box>
          {filteredSortedMembers.map((member, index) => {
            if (userRole === "VIEWER" && member.state.status === "PENDING")
              //don't show pending for viewer
              return null;
            else
              return (
                <Box
                  width="100%"
                  mb={6}
                  key={index}
                  display="flex"
                  alignItems={
                    member?.state?.memberDetails?.fullName ? "start" : "center"
                  }
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center" width="75%">
                    {/* on small screens truncate long emails with width */}
                    {member?.state?.memberDetails?.avatarUrl ? (
                      <img
                        src={member.state.memberDetails.avatarUrl}
                        alt="Avatar"
                        className={classes.avatar}
                        style={{
                          opacity:
                            member.state.status === "REVOKED" ? "40%" : "100%"
                        }}
                      />
                    ) : (
                      <AvatarIcon
                        fontSize="large"
                        classes={{
                          fontSizeLarge: classes.iconExtraLarge
                        }}
                        style={{
                          color:
                            member.state.status !== "PENDING"
                              ? colorMap[index]
                              : "#A3A09F",
                          opacity:
                            member.state.status === "REVOKED" ? "40%" : "100%"
                        }}
                      />
                    )}
                    <Box ml={3} width="75%">
                      {/* on small screens truncate long emails with width */}
                      <Typography
                        component="p"
                        variant="caption"
                        color={
                          member.state.status === "REVOKED"
                            ? "textSecondary"
                            : "textPrimary"
                        }
                      >
                        {member?.state?.memberDetails?.fullName ||
                          member?.state?.memberDetails?.email}
                      </Typography>
                      {member?.state?.memberDetails?.fullName && (
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          component="p"
                          noWrap
                        >
                          {member.state.memberDetails.email}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box>
                    {member.state.role !== "OWNER" ? (
                      manageAccessStatusCase(
                        member.state,
                        memberRole,
                        userRole,
                        index,
                        handleFormChange,
                        isRoomArchived
                      )
                    ) : (
                      <Typography variant="caption" color="textSecondary">
                        Owner
                      </Typography>
                    )}
                  </Box>
                </Box>
              );
          })}
        </Box>
      )}

      {userRole === "OWNER" && (
        <Button
          variant="contained"
          color="primary"
          type="button"
          disableElevation
          classes={{
            containedPrimary: classes.containedPrimary
          }}
          // style={error && { background: '#DB443C' }}
          disabled={isButtonDisabled()}
          fullWidth={isMobile}
          onClick={updateMembersRole}
        >
          Save changes
          {buttonLoading && (
            <CircularProgress
              component="span"
              size={24}
              className={classes.buttonProgress}
            />
          )}
        </Button>
      )}
    </SupportScreenWrapper>
  );
}

export default withRouter(ManageAccess);
