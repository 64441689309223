import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  MenuItem,
  MenuList,
  ListItemText,
  ListItemIcon,
  Button,
  Paper,
  Popover
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  resendInvite,
  restoreAccess,
  resendInviteNewUser,
  revokeInvite
} from "../../../helpers/manage-access-actions";
import {
  KeyIcon,
  CloseInCircleIcon,
  PaperPlaneIcon,
  TriangleDownIcon,
  TriangleUpIcon
} from "@akord/addon-icons";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";

const useStyles = makeStyles(theme => ({
  menuItem: {
    fontSize: "1rem",
    minHeight: "34px",
    alignItems: "start",
    paddingTop: theme.spacing(5),
    paddingBottom: 0,
    "&:first-child": {
      paddingTop: theme.spacing(5),
      paddingBottom: 0
    },
    "&:last-child": {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5)
    }
  },
  selected: {
    background: "transparent!important"
  },
  menuList: {
    backgroundColor: theme.palette.background.secondary,
    maxWidth: "293px",
    minWidth: "180px"
  },
  primaryTypo: {
    fontSize: "1rem"
  },
  secondaryTypo: {
    fontSize: "1rem",
    color: theme.palette.text.secondary
  },
  multiline: {
    marginTop: 0,
    marginBottom: 0
  },
  // buttonLabel: {
  //   // fontSize: "0.875rem",
  //   color: ({ roleMenuOpen }) =>
  //     roleMenuOpen ? theme.palette.text.primary : "inherit"
  // },
  buttonRoot: {
    height: "auto",
    "&:hover": {
      backgroundColor: "inherit"
    }
  },
  buttonText: {
    padding: 0,
    fontSize: "0.875rem",
    fontFamily: [
      "Larsseit-Regular",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen-Sans",
      "Ubuntu",
      "Cantarell",
      "Helvetica Neue",
      "sans-serif"
    ].join(",")
    // color: ({ roleMenuOpen }) =>
    //   roleMenuOpen ? theme.palette.text.primary : "inherit"
  },
  endIcon: {
    marginLeft: 0
  },
  iconSizeLarge: {
    "&>*:first-child": {
      fontSize: "24px",
      marginLeft: theme.spacing(1)
    }
  }
}));

function SelectMenuForAccess({ member, menuType, memberHash, roomData }) {
  const { darkMode, onTxSpinner } = useGlobalContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const { currentMembership, ledgerWrapper } = useVaultContext();
  const [roleMenuOpen, setRoleMenuOpen] = React.useState(false);
  const classes = useStyles({
    darkMode: darkMode,
    roleMenuOpen: roleMenuOpen
  });

  const handleToggle = () => {
    setRoleMenuOpen(prevOpen => !prevOpen);
  };

  const handleClose = () => {
    setRoleMenuOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setRoleMenuOpen(false);
    }
  }

  // return focus to the button when we transitioned from !roleMenuOpen -> roleMenuOpen
  const prevOpen = React.useRef(roleMenuOpen);
  React.useEffect(() => {
    if (prevOpen.current === true && roleMenuOpen === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = roleMenuOpen;
  }, [roleMenuOpen]);

  const anchorRef = React.useRef(null);

  const handleMenuItems = async type => {
    try {
      switch (type) {
        case "resendPendingInvite":
          handleClose();
          onTxSpinner(true);
          await resendInvite(ledgerWrapper, memberHash);
          onSnackbarToShow("resendInvite");
          break;
        case "revokePendingInvite":
          handleClose();
          onTxSpinner(true);
          await revokeInvite(ledgerWrapper, memberHash, currentMembership);
          onSnackbarToShow("memberRevoke");
          break;
        case "resendNewUserInvite":
          handleClose();
          onTxSpinner(true);
          await resendInviteNewUser(ledgerWrapper, memberHash);
          onSnackbarToShow("resendInvite");
          break;
        case "revokeNewUserInvite":
          handleClose();
          onTxSpinner(true);
          await revokeInvite(ledgerWrapper, memberHash, currentMembership);
          onSnackbarToShow("memberRevoke");
          break;
        case "restoreRevokedAccess":
          handleClose();
          onTxSpinner(true);
          await restoreAccess(
            ledgerWrapper,
            memberHash,
            roomData.state.termsOfAccess
          );
          break;
        case "restoreRejectedAccess":
          handleClose();
          onTxSpinner(true);
          await restoreAccess(
            ledgerWrapper,
            memberHash,
            roomData.state.termsOfAccess
          );
          break;

        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getMenus = menuType => {
    switch (menuType) {
      case "Revoked":
        return (
          <MenuItem
            className={classes.menuItem}
            classes={{ selected: classes.selected }}
            onClick={() => handleMenuItems("restoreRevokedAccess")}
          >
            <ListItemIcon>
              <KeyIcon color="disabled" className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              classes={{ multiline: classes.multiline }}
              style={{ whiteSpace: "normal" }}
              primary="Restore Access"
              primaryTypographyProps={{
                classes: { body1: classes.primaryTypo }
              }}
              secondaryTypographyProps={{
                classes: { body2: classes.secondaryTypo }
              }}
              secondary="An invite will be sent to rejoin the vault."
            />
          </MenuItem>
        );
      case "Rejected":
        return (
          <MenuItem
            className={classes.menuItem}
            classes={{ selected: classes.selected }}
            onClick={() => handleMenuItems("restoreRejectedAccess")}
          >
            <ListItemIcon>
              <KeyIcon color="disabled" className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              classes={{ multiline: classes.multiline }}
              style={{ whiteSpace: "normal" }}
              primary="Restore Access"
              primaryTypographyProps={{
                classes: { body1: classes.primaryTypo }
              }}
              secondaryTypographyProps={{
                classes: { body2: classes.secondaryTypo }
              }}
              secondary="An invite will be sent to rejoin the vault."
            />
          </MenuItem>
        );
      case "Pending":
        return (
          <MenuList onKeyDown={handleListKeyDown}>
            <MenuItem
              className={classes.menuItem}
              classes={{ selected: classes.selected }}
              onClick={() => handleMenuItems("resendPendingInvite")}
            >
              <ListItemIcon>
                <PaperPlaneIcon color="disabled" className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                classes={{ multiline: classes.multiline }}
                style={{ whiteSpace: "normal" }}
                primary="Resend"
                primaryTypographyProps={{
                  classes: { body1: classes.primaryTypo }
                }}
              />
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              classes={{ selected: classes.selected }}
              onClick={() => handleMenuItems("revokePendingInvite")}
            >
              <ListItemIcon>
                <CloseInCircleIcon color="disabled" className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                classes={{ multiline: classes.multiline }}
                style={{ whiteSpace: "normal" }}
                primary="Revoke invite"
                primaryTypographyProps={{
                  classes: { body1: classes.primaryTypo }
                }}
              />
            </MenuItem>
          </MenuList>
        );
      case "Invited":
        return (
          <MenuList onKeyDown={handleListKeyDown}>
            <MenuItem
              className={classes.menuItem}
              classes={{ selected: classes.selected }}
              onClick={() => handleMenuItems("resendNewUserInvite")}
            >
              <ListItemIcon>
                <PaperPlaneIcon color="disabled" className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                classes={{ multiline: classes.multiline }}
                style={{ whiteSpace: "normal" }}
                primary="Resend"
                primaryTypographyProps={{
                  classes: { body1: classes.primaryTypo }
                }}
              />
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              classes={{ selected: classes.selected }}
              onClick={() => handleMenuItems("revokeNewUserInvite")}
            >
              <ListItemIcon>
                <CloseInCircleIcon color="disabled" className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                classes={{ multiline: classes.multiline }}
                style={{ whiteSpace: "normal" }}
                primary="Revoke invite"
                primaryTypographyProps={{
                  classes: { body1: classes.primaryTypo }
                }}
              />
            </MenuItem>
          </MenuList>
        );

      default:
        break;
    }
  };

  if (!member) return null;

  return (
    <div className={classes.root}>
      <div>
        <Button
          disableRipple
          size="large"
          ref={anchorRef}
          aria-controls={roleMenuOpen ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          endIcon={
            roleMenuOpen ? (
              <TriangleUpIcon color="action" />
            ) : (
              <TriangleDownIcon color="disabled" />
            )
          }
          onClick={handleToggle}
          classes={{
            // label: classes.buttonLabel,
            root: classes.buttonRoot,
            text: classes.buttonText,
            endIcon: classes.endIcon,
            iconSizeLarge: classes.iconSizeLarge
          }}
        >
          {menuType}
        </Button>
        <Popover
          anchorEl={anchorRef.current}
          open={roleMenuOpen}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <Paper className={classes.menuList}>
            <ClickAwayListener onClickAway={handleClose}>
              {getMenus(menuType)}
            </ClickAwayListener>
          </Paper>
        </Popover>
      </div>
    </div>
  );
}

export default SelectMenuForAccess;
