import React, { useState } from "react";
import AvatarEditor from "react-avatar-editor";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  InputLabel,
  Slider
} from "@mui/material";
import { PenIcon, AvatarIcon } from "@akord/addon-icons";
import { StandAloneScreenWrapper } from "../../components/common/";
import { ButtonGroup } from "../../components/common";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { withRouter } from "react-router";
import { updateProfile } from "../../helpers/manage-access-actions";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";

const useStyles = makeStyles({
  icon: {
    cursor: "pointer",
    fontSize: "64px",
    fill: "#3592FA"
  },
  avatar: {
    cursor: "pointer",
    width: "64px",
    height: "64px",
    borderRadius: "50%"
  },
  inputDisabled: {
    background: "none!important"
  }
});

function Profile({ history }) {
  const { darkMode, onTxSpinner } = useGlobalContext();
  const {
    decryptedProfileDetails,
    wallet,
    decryptedMemberships,
    fullProfile,
    isMobile
  } = useGlobalContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const classes = useStyles();
  const [profileForm, setProfileForm] = useState({
    name: ""
  });

  const [avatar, setAvatar] = React.useState({
    photoFile: null,
    photoUrl: null,
    croppedFile: null,
    originalFile: null
  });

  React.useEffect(() => {
    const getAvatarFile = async () => {
      setAvatar(prevState => ({
        ...prevState,
        croppedFile: decryptedProfileDetails.avatarUrl,
        originalFile: decryptedProfileDetails.avatarUrl
      }));
    };
    setProfileForm({
      name: (decryptedProfileDetails && decryptedProfileDetails.fullName) || ""
    });
    if (decryptedProfileDetails && decryptedProfileDetails.avatarUrl) {
      getAvatarFile();
    }
  }, [decryptedProfileDetails]);

  const handleFormChange = e => {
    setProfileForm({ ...profileForm, [e.target.name]: e.target.value });
  };

  const isButtonDisabled = () => {
    if (
      ((profileForm.name !== "" &&
        profileForm.name !== decryptedProfileDetails.fullName) ||
        avatar.originalFile !== avatar.croppedFile) &&
      !loading
    )
      return false;
    else return true;
  };

  const [cropperOpen, setCropperOpen] = React.useState(false);
  const [zoom, setZoom] = React.useState(1);

  // button loading state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const handleAvatar = e => {
    // setAvatar({ photoFile: e.target.files[0] })
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      setAvatar({ ...avatar, photoFile: file, photoUrl: fileReader.result });
      setCropperOpen(true);
    };
    if (file) {
      fileReader.readAsDataURL(file);
    }
  };

  let myEditor;
  const setEditorRef = editor => (myEditor = editor);

  const handleZoomSlider = (event, value) => {
    setZoom(value);
  };
  const handleCancelCrop = () => {
    setCropperOpen(false);
  };

  const handleSaveCrop = async () => {
    if (myEditor) {
      const canvasScaled = myEditor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      setAvatar({ ...avatar, croppedFile: croppedImg });
      setCropperOpen(false);
    }
  };

  const saveProfile = async () => {
    try {
      setLoading(true);
      onTxSpinner(true);
      await updateProfile(
        wallet,
        fullProfile,
        profileForm,
        avatar,
        decryptedMemberships
      );
      setLoading(false);
      history.goBack();
      onSnackbarToShow("profileUpdate");
    } catch (err) {
      setLoading(false);
      setError(err.errors[0].message);
      console.log("Error saving profile: ", err);
    }
  };

  return (
    <StandAloneScreenWrapper
      title="Profile"
      subtitle={decryptedProfileDetails && decryptedProfileDetails.email}
    >
      <Box display="flex" flexDirection="column" width="100%" maxWidth="400px">
        <Box mb={7} display="flex" alignItems="baseline">
          <label htmlFor="profile-avatar" style={{ display: "contents" }}>
            {avatar.croppedFile ? (
              <img
                alt="preview"
                src={avatar.croppedFile}
                className={classes.avatar}
              />
            ) : (
              <AvatarIcon className={classes.icon} />
            )}
            <Box alignSelf="flex-end">
              <Typography
                variant="body2"
                className="small"
                color="text.secondary"
                style={{
                  display: "flex",
                  cursor: "pointer"
                }}
              >
                <PenIcon
                  color="disabled"
                  fontSize="small"
                  style={{ marginRight: "4px" }}
                />
                Edit
              </Typography>
            </Box>
            <input
              name="profile-avatar"
              id="profile-avatar"
              accept="image/*"
              type="file"
              onChange={event => {
                handleAvatar(event);
              }}
              style={{ display: "none" }}
            />
          </label>
        </Box>

        <Box mb={7}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="input-first-name">Name</InputLabel>
            <OutlinedInput
              fullWidth
              id={`enter-name`}
              name="name"
              type="text"
              label="Name"
              value={profileForm.name}
              onChange={handleFormChange}
            />
          </FormControl>
        </Box>

        <FormControl variant="outlined">
          <InputLabel htmlFor="input-first-name">Email address</InputLabel>
          <OutlinedInput
            disabled
            fullWidth
            id={`enter-email`}
            name="email"
            type="email"
            label="Email address"
            value={decryptedProfileDetails ? decryptedProfileDetails.email : ""}
            classes={{
              disabled: classes.inputDisabled
            }}
          />
        </FormControl>
      </Box>
      <ButtonGroup
        loading={loading}
        type="submit"
        noMinWidth
        nextText="Save changes"
        nextDiasabled={isButtonDisabled()}
        hideBackButton={true}
        fullWidthNextButton={isMobile ? true : false}
        hideIcon
        errorText={error}
        handleEnd={() => saveProfile()}
        disabledRed={false}
      />
      {cropperOpen && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          width="100%"
          height="100%"
          zIndex="2"
          style={{
            top: 0,
            left: 0,
            background: darkMode
              ? "rgba(49, 47,55, 0.8)"
              : "rgba(34, 33, 38, 0.2)"
          }}
        >
          <AvatarEditor
            style={isMobile && { width: "100%", height: "auto" }}
            ref={setEditorRef}
            image={avatar.photoUrl}
            width={300}
            height={300}
            border={50}
            borderRadius={200}
            color={[29, 29, 29, 0.7]}
            scale={zoom}
            rotate={0}
          />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              margin: "10px 0"
            }}
          >
            <Typography variant="caption" style={{ margin: "0 20px 0 0" }}>
              Zoom
            </Typography>
            <Slider
              min={1}
              max={10}
              step={0.1}
              value={zoom}
              onChange={handleZoomSlider}
              style={{ width: 200 }}
            />
          </Box>
          <Box maxWidth="400px" width={"calc(100% - 32px)"}>
            <ButtonGroup
              type="submit"
              noMinWidth
              hideIcon
              nextText="Save profile picture"
              backText="Cancel"
              handleEnd={() => handleSaveCrop()}
              handleBack={() => handleCancelCrop()}
            />
          </Box>
        </Box>
      )}
    </StandAloneScreenWrapper>
  );
}

export default withRouter(Profile);
