import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, IconButton, Typography, Tooltip } from "@mui/material";
import {
  ArchiveIcon,
  DoubleChevronLeftIcon,
  GlobeIcon,
  PadlockIcon
} from "@akord/addon-icons";
import { useHistory } from "react-router";
import { Skeleton } from "@mui/material";
import Label from "../Label";

const useStyles = makeStyles(theme => ({
  roomName: {
    marginTop: 0
  },
  textLink: {
    borderBottom: "none",
    "&:hover": {
      color: theme.palette.text.primary
    }
  }
}));

function TopBarTitleDesktop({
  currentMembership,
  currentFolder,
  parentFolders,
  folderId,
  isRoomArchived,
  isVaultPublic,
  dataRoomStatus,
  dataRoomId,
  revoked,
  variant,
  color
}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box display="flex" width={revoked ? "100%" : "calc(100% - 140px)"}>
      {!currentMembership || Object.keys(currentMembership).length === 0 ? (
        <Skeleton variant="text" width="70%" />
      ) : (
        <>
          {isRoomArchived && (
            <ArchiveIcon color="disabled" style={{ marginRight: 8 }} />
          )}
          <Box display="flex" className={classes.roomName} width="100%">
            {folderId ? (
              parentFolders.length < 2 ? (
                <Typography
                  variant={variant}
                  noWrap
                  className={`${classes.textLink} ${"regular"}`}
                  color={color}
                  component="a"
                  onClick={() =>
                    revoked
                      ? history.push(
                          `/vaults/${dataRoomStatus}/${dataRoomId}/revoked-files`
                        )
                      : history.push(
                          `/vaults/${dataRoomStatus}/${dataRoomId}/assets`
                        )
                  }
                >
                  {currentMembership.dataRoom?.state?.title}
                </Typography>
              ) : (
                <IconButton
                  disableRipple
                  aria-label="back"
                  aria-controls="menu-appbar"
                  onClick={() =>
                    revoked
                      ? history.push(
                          `/vaults/${dataRoomStatus}/${dataRoomId}/revoked-files`
                        )
                      : history.push(
                          `/vaults/${dataRoomStatus}/${dataRoomId}/assets`
                        )
                  }
                  style={{ alignItems: "flex-start", padding: 0 }}
                  size="large"
                >
                  <DoubleChevronLeftIcon
                    fontSize={revoked ? "small" : "default"}
                  />
                </IconButton>
              )
            ) : (
              <>
                <Typography variant={variant} noWrap color="text.primary">
                  {currentMembership.dataRoom?.state?.title}
                </Typography>
                {!currentMembership?.dataRoom?.state?.permanentStorage && (
                  <Label secondary text="Cloud storage" />
                )}
                {isVaultPublic ? (
                  <Tooltip title="Public vault" arrow>
                    <span
                      style={{
                        height: "20px",
                        marginLeft: "8px",
                        marginTop: "1px"
                      }}
                    >
                      <GlobeIcon fontSize="small" color="disabled" />
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Encrypted vault" arrow>
                    <span
                      style={{
                        height: "20px",
                        marginLeft: "8px",
                        marginTop: "1px"
                      }}
                    >
                      <PadlockIcon fontSize="small" color="disabled" />
                    </span>
                  </Tooltip>
                )}
              </>
            )}
            {parentFolders.map((parentFolder, index) =>
              index >= parentFolders.length - 2 ? (
                <Box key={index} display="flex">
                  {index !== parentFolders.length - 2 && (
                    <Typography
                      variant={variant}
                      className="regular"
                      color={color}
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {" / "}
                    </Typography>
                  )}
                  <Typography
                    variant={variant}
                    noWrap
                    className={`${classes.textLink} ${"regular"}`}
                    color={color}
                    component="a"
                    onClick={() =>
                      revoked
                        ? history.push(
                            `/vaults/${dataRoomStatus}/${dataRoomId}/revoked-files/folders/${parentFolder.id}`
                          )
                        : history.push(
                            `/vaults/${dataRoomStatus}/${dataRoomId}/assets/folders/${parentFolder.id}`
                          )
                    }
                  >
                    {parentFolder.title}
                  </Typography>
                </Box>
              ) : null
            )}
            {folderId && (
              <>
                <Typography
                  variant={variant}
                  className="regular"
                  color={color}
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {" / "}
                </Typography>
                <Typography variant={variant} noWrap color="text.primary">
                  {currentFolder?.title}
                </Typography>
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

export default TopBarTitleDesktop;
