import React from "react";
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
  InputLabel,
  OutlinedInput,
  IconButton,
  InputAdornment
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { withRouter } from "react-router-dom";
import Logo from "../../components/common/logo/logoOrange";
import {
  AkordCheckbox,
  ButtonGroup,
  FormSubmit
} from "../../components/common/";
import { useLoginContext } from "../../contexts/LoginContext";
import RevealToggler from "../../components/common/RevealToggler";
import { checkDB } from "../../helpers/db";

const useStyles = makeStyles({
  logo: {
    fill: "currentColor",
    color: "white",
    display: "block",
    margin: "0 0 24px 0",
    cursor: "pointer"
  }
  // inputAdornment: {
  //   paddingRight: '5px'
  // }
});

const LoginPage = ({ history }) => {
  const classes = useStyles();
  const {
    onLoginFormChange,
    formData,
    error,
    getErrorText,
    onSignIn,
    loading,
    showPassword,
    handleClickShowPassword
  } = useLoginContext();

  React.useEffect(() => {
    const checkIfDbAvailable = async () => {
      const isDBavailable = await checkDB();
      if (!isDBavailable) history.push("/418");
    };
    checkIfDbAvailable();
  }, []);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const isButtonDisabled = () => {
    return !(
      formData.userEmail.length > 0 &&
      formData.userPassword.length >= 8 &&
      !loading
    );
  };

  const switchRouteForgotPassword = () => {
    history.push("/recover");
  };

  return (
    <Box style={{ maxWidth: "400px", margin: "0 auto" }}>
      <FormSubmit onSubmit={() => onSignIn()}>
        <Box mb={6}>
          <Logo
            width="90"
            height="35"
            className={classes.logo}
            onClick={() => window.open("https://akord.com", "_self")}
          />
          <Box mb={6}>
            <Typography variant="h1">Sign in</Typography>
          </Box>
          <Box mb={6}>
            <FormControl fullWidth>
              <TextField
                error={!!error}
                label="Email address"
                variant="outlined"
                name="userEmail"
                id="email-input"
                value={formData.userEmail}
                onChange={onLoginFormChange()}
                style={{ marginBottom: "32px" }}
              />
              <FormControl error={!!error} variant="outlined" fullWidth>
                <InputLabel htmlFor="password-input">Password</InputLabel>
                <OutlinedInput
                  spellCheck="false"
                  id="password-input"
                  aria-describedby="password-input"
                  value={formData.userPassword}
                  name="userPassword"
                  label="Password"
                  onChange={onLoginFormChange()}
                  type={showPassword ? "text" : "password"}
                  classes={{ adornedEnd: classes.inputAdornment }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        size="large"
                      >
                        <RevealToggler showPassword={showPassword} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </FormControl>
          </Box>
        </Box>
        <Box mb={7}>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                style={{ marginBottom: 0 }}
                control={
                  <AkordCheckbox
                    inputProps={{ "aria-label": "keepSignedIn" }}
                    name="keepSignedIn"
                    onChange={onLoginFormChange()}
                  />
                }
                label="Keep me signed in"
              />
            </FormGroup>
          </FormControl>
        </Box>
        <ButtonGroup
          type="submit"
          nextText="Sign in"
          nextDiasabled={isButtonDisabled()}
          hideBackButton={true}
          fullWidthNextButton={true}
          hideIcon={true}
          errorText={getErrorText()}
          disabledRed={false}
          loading={loading}
          noMarginTop={true}
        />
        <Box mt={6}>
          <Box>
            <Typography component="span" variant="body2">
              Forgot your password?
            </Typography>{" "}
            <Typography
              component="a"
              variant="body2"
              onClick={switchRouteForgotPassword}
            >
              Recover your account
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography component="span" variant="body2">
              Don&apos;t have an account?
            </Typography>{" "}
            <Typography
              component="a"
              variant="body2"
              onClick={() => history.push("/signup")}
            >
              Create an account
            </Typography>
          </Box>
        </Box>
      </FormSubmit>
    </Box>
  );
};

export default withRouter(LoginPage);
