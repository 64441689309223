import React from "react";
import { DialogActions, Button, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getFolderId, getTotalUploadSize } from "../../../helpers/helpers";
import {
  handleModalConfirm,
  confirmationModalOptions
} from "./confirmation-modal-options";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { useProgressContext } from "../../../contexts/ProgressContext";
import { handleUpload } from "../../../helpers/duplicate-file-upload";
import { useHistory } from "react-router-dom";
import { useStorageContext } from "../../../contexts/StorageContextProvider";
import { fileRevision } from "../../../helpers/akord-actions";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";

const useStyles = makeStyles(theme => ({
  dialogActions: {
    justifyContent: ({ alignRight }) => (alignRight ? "flex-end" : "flex-end"),
    flexDirection: ({ isDesktop }) => (isDesktop ? "row" : "column-reverse")
  },
  buttonGroup: {
    minWidth: "auto",
    marginLeft: ({ isDesktop }) => isDesktop && "24px!important",
    "&:last-child": {
      marginBottom: ({ isDesktop }) => (isDesktop ? 0 : theme.spacing(4))
    }
  },
  buttonProgress: {
    position: "absolute"
  },
  topUpButton: {
    marginRight: "auto",
    marginTop: ({ isDesktop }) => (isDesktop ? 0 : theme.spacing(4))
  }
}));

function TwoButtonConfirm({
  selectedFolderId,
  confirmationType,
  onUpoadFileIndex,
  filesWithAction,
  uploadedAndConfirmedFiles,
  handleUploadedAndConfirmedFiles,
  isSizeExceedLimit
}) {
  const { globalStorage } = useStorageContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const { width, onTxSpinner } = useGlobalContext();

  const {
    modal,
    notificationData,
    onNotificationData,
    onUploadFiles,
    uploadEventRef,
    clearUploadEventRef
  } = useNotificationsContext();

  const { onProgress } = useProgressContext();

  const { currentMembership, ledgerWrapper, currentStack } = useVaultContext();
  const {
    onShowLoader,
    decryptedStacks,
    onDrawerActionType,
    onAxiosError,
    onUploadStart,
    onUploadHook,
    onFilesNumber,
    drawerActionType,
    onShowUpload
  } = useAssetsContext();

  const isDesktop = width !== "xs";
  const history = useHistory();
  const folderId = getFolderId(history.location.pathname);
  const classes = useStyles({
    isDesktop: isDesktop,
    alignRight: confirmationModalOptions[modal.confirmModalType].showCancel
  });
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  // close confirmation modal if all files were excluded
  React.useEffect(() => {
    if (!uploadedAndConfirmedFiles) return;
    if (!uploadedAndConfirmedFiles.length) {
      modal.onModalVisibility(null, modal.confirmModalType);
      handleUploadedAndConfirmedFiles();
      filesWithAction.current = [];
      onUpoadFileIndex(0);
    }
  }, [uploadedAndConfirmedFiles]);

  const handleConfirm = async () => {
    try {
      if (
        modal.confirmModalType === "confirmUpload" &&
        drawerActionType !== "upload_revision"
      ) {
        handleUpload(
          filesWithAction.current,
          uploadedAndConfirmedFiles,
          decryptedStacks,
          onDrawerActionType,
          onShowLoader,
          currentMembership,
          ledgerWrapper,
          onProgress,
          onAxiosError,
          onSnackbarToShow,
          onUploadStart,
          onFilesNumber,
          onUploadFiles,
          uploadEventRef,
          clearUploadEventRef,
          folderId,
          false,
          onUploadHook,
          onTxSpinner
        );
        // modal.onModalVisibility(null, modal.confirmModalType)
        filesWithAction.current = [];
        onUpoadFileIndex(0);
      } else if (
        modal.confirmModalType === "confirmUpload" &&
        drawerActionType === "upload_revision"
      ) {
        if (onShowLoader && onShowUpload) {
          onShowLoader(true);
          onShowUpload(false);
        }
        const action = fileRevision(
          ledgerWrapper,
          currentStack,
          uploadedAndConfirmedFiles,
          onShowLoader,
          onProgress,
          onAxiosError,
          onSnackbarToShow,
          false,
          onUploadHook
        );
        onUploadStart(action);
        // onDrawerActionType()
      } else
        await handleModalConfirm(
          modal.confirmModalType,
          ledgerWrapper,
          currentMembership,
          notificationData,
          onSnackbarToShow,
          onNotificationData,
          history
        );
      onTxSpinner(true); //show tx spinner
      modal.onModalVisibility(null, modal.confirmModalType);
      modal.onConfirmModalType(null);
    } catch (err) {
      onTxSpinner(false);
      console.log(`Error in ${modal.confirmModalType} modal: `, err);
    }
  };

  return (
    <>
      <DialogActions className={classes.dialogActions}>
        {confirmationType === "upload" &&
          uploadedAndConfirmedFiles &&
          globalStorage.storage_available <
            getTotalUploadSize(uploadedAndConfirmedFiles) && (
            <Button
              onClick={() => {
                clearUploadEventRef();
                modal.onModalVisibility(null, modal.confirmModalType);
                modal.onConfirmModalType(null);
                onDrawerActionType();
                history.push("/top-up");
              }}
              variant="outlined"
              color="primary"
              fullWidth={isDesktop ? false : true}
              className={classes.topUpButton}
            >
              Top up
            </Button>
          )}
        {confirmationModalOptions[modal.confirmModalType].showCancel && (
          <Button
            onClick={() => {
              clearUploadEventRef();
              modal.onModalVisibility(null, modal.confirmModalType);
              modal.onConfirmModalType(null);
              onDrawerActionType();
              onNotificationData(null);
            }}
            color="primary"
            fullWidth={isDesktop ? false : true}
            className={classes.buttonGroup}
          >
            Cancel
          </Button>
        )}
        <Button
          disabled={
            loading ||
            (selectedFolderId === null && confirmationType === "move") ||
            (confirmationType === "upload" &&
              uploadedAndConfirmedFiles &&
              (globalStorage.storage_available <
                getTotalUploadSize(uploadedAndConfirmedFiles) ||
                isSizeExceedLimit))
          }
          color="primary"
          variant="contained"
          fullWidth={isDesktop ? false : true}
          type="button"
          disableElevation
          className={classes.buttonGroup}
          onClick={async () => {
            setLoading(true);
            await handleConfirm();
            setLoading(false);
          }}
        >
          {confirmationModalOptions[modal.confirmModalType].buttonText}
          {loading && (
            <CircularProgress
              component="span"
              size={24}
              className={classes.buttonProgress}
            />
          )}
        </Button>
      </DialogActions>
    </>
  );
}

export default TwoButtonConfirm;
