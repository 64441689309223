import React from "react";
import { Menu } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.spacing(2),
    minWidth: "130px"
    // left: 'calc(100% - 250px)!important'
  }
}));

const MoreMenuRoot = ({ children, anchorEl, openMenu, handleMenuClose }) => {
  const classes = useStyles();

  return (
    <Menu
      variant="selectedMenu"
      elevation={8}
      id="room-menu"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={openMenu}
      onClose={handleMenuClose}
      classes={{ paper: classes.paper }}
    >
      {children}
    </Menu>
  );
};

export default MoreMenuRoot;
