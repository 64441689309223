import React from "react";
import { Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  labelBox: {
    height: "24px",
    border: "1px solid",
    borderRadius: "100px",
    marginLeft: "8px",
    padding: "0 8px"
  }
});

function Label({ text, secondary = false }) {
  const classes = useStyles();

  return (
    <Box
      className={classes.labelBox}
      style={{
        borderColor: secondary && "#9D9BA6"
      }}
    >
      <Typography
        variant="caption"
        className="xs"
        color={secondary ? "text.secondary" : "text.primary"}
        noWrap
      >
        {text.toUpperCase()}
      </Typography>
    </Box>
  );
}

export default Label;
