import React, { useState, useEffect, createContext, useContext } from "react";
import API, { graphqlOperation } from "@aws-amplify/api";
import * as subscriptions from "../graphql/subscriptions";
import { useLocation } from "react-router";
import {
  storageTransactionsByPublicSigningKey,
  getStorage
} from "../graphql/queries/storage";
import { getInvoice } from "../graphql/queries/topup";
import { useGlobalContext } from "./GlobalDataProvider";
import { useSnackbarContext } from "./SnackbarContextProvider";

const Context = createContext();

function StorageContextProvider({ children }) {
  const location = useLocation();
  const { wallet } = useGlobalContext();
  const { onSnackbarToShow } = useSnackbarContext();

  const [transactions, setTransactions] = useState();
  const handleTransactions = transactions => setTransactions(transactions);

  const transactionLogsRef = React.useRef();
  transactionLogsRef.current = transactions;

  const [transactionNextToken, setTransactionNextToken] = useState();
  const handleTransactionNextToken = token => setTransactionNextToken(token);

  const [globalStorage, setGlobalStorage] = useState();
  const handleGlobalStorage = storage => setGlobalStorage(storage);

  const storageTransactions = async limit => {
    const results = await API.graphql(
      graphqlOperation(storageTransactionsByPublicSigningKey, {
        publicSigningKey: await wallet.signingPublicKey(),
        limit: limit,
        sortDirection: "DESC"
      })
    );
    const transactions =
      results.data.storageTransactionsByPublicSigningKey.items;
    const transactionNextToken =
      results.data.storageTransactionsByPublicSigningKey.nextToken;
    handleTransactionNextToken(transactionNextToken);
    handleTransactions(transactions);
  };

  React.useEffect(() => {
    if (wallet && location.pathname === "/storage") {
      // Fetch 5 items on initial call
      storageTransactions(5);
    }
    return () => {
      // Clear all transactions on unmount
      handleTransactions();
    };
  }, [wallet, location.pathname]);

  React.useEffect(() => {
    const getGlobalStorage = async () => {
      const results = await API.graphql(
        graphqlOperation(getStorage, {
          publicSigningKey: await wallet.signingPublicKey()
        })
      );
      const storage = results.data.getStorage;
      handleGlobalStorage(storage);
    };

    if (wallet) getGlobalStorage();
  }, [wallet]);

  //Subscription Transactions
  useEffect(() => {
    let createTransactionSub;
    let updateTransactionSub;
    const setupSubscription = async () => {
      try {
        if (wallet === undefined || Object.entries(wallet).length === 0) return;
        createTransactionSub = await API.graphql(
          graphqlOperation(
            subscriptions.onCreateStorageTransactionByPublicSigningKey,
            {
              publicSigningKey: await wallet.signingPublicKey()
            }
          )
        ).subscribe({
          next: async () => {
            // const newTransaction =
            //   value.data.onCreateStorageTransactionByPublicSigningKey;

            // const updatedTransactions = [...transactionLogsRef.current];
            // updatedTransactions.unshift(newTransaction);
            // updatedTransactions.pop();

            // handleTransactions(updatedTransactions);

            // Fetch new transactions if we are on the storage page
            if (location.pathname === "/storage")
              await storageTransactions(transactionLogsRef.current?.length);
          },
          error: () => {
            console.warn("err");
          }
        });

        updateTransactionSub = await API.graphql(
          graphqlOperation(
            subscriptions.onUpdateStorageTransactionByPublicSigningKey,
            {
              publicSigningKey: await wallet.signingPublicKey()
            }
          )
        ).subscribe({
          next: async ({ value }) => {
            const updatedTransaction =
              value.data.onUpdateStorageTransactionByPublicSigningKey;
            if (updatedTransaction.status === "REJECTED") {
              onSnackbarToShow("uploadFailed", updatedTransaction.refId);
            }

            // Update a transaction if we have any fetched (are on the storage page)
            // Add memberships, notes and stacks from originally fetched (not getting it from the subscription)
            if (transactionLogsRef?.current?.length > 0) {
              const updatedObjectIndex = transactionLogsRef.current?.findIndex(
                transaction => transaction.id === updatedTransaction.id
              );
              const copyTransactions = [...transactionLogsRef.current];
              updatedTransaction.memberships =
                copyTransactions[updatedObjectIndex]?.memberships;
              updatedTransaction.note =
                copyTransactions[updatedObjectIndex]?.note;
              updatedTransaction.stack =
                copyTransactions[updatedObjectIndex]?.stack;
              copyTransactions[updatedObjectIndex] = updatedTransaction;
              handleTransactions(copyTransactions);
            }
          },
          error: () => {
            console.warn("err");
          }
        });
      } catch (err) {
        console.log("Subscription error: ", err);
      }
    };
    if (window.navigator.onLine) setupSubscription();
    return () => {
      if (createTransactionSub) createTransactionSub.unsubscribe();
      if (updateTransactionSub) updateTransactionSub.unsubscribe();
    };
  }, [wallet]);

  //Subscription Storage
  useEffect(() => {
    let createStorageSub;
    let updateStorageSub;
    const setupSubscription = async () => {
      try {
        if (wallet === undefined || Object.entries(wallet).length === 0) return;
        createStorageSub = await API.graphql(
          graphqlOperation(subscriptions.onCreateStorageByPublicSigningKey, {
            publicSigningKey: await wallet.signingPublicKey()
          })
        ).subscribe({
          next: async ({ value }) => {
            const createdGlobalStorage =
              value.data.onCreateStorageByPublicSigningKey;
            handleGlobalStorage(createdGlobalStorage);
          },
          error: () => {
            console.warn("err");
          }
        });
        updateStorageSub = await API.graphql(
          graphqlOperation(subscriptions.onUpdateStorageByPublicSigningKey, {
            publicSigningKey: await wallet.signingPublicKey()
          })
        ).subscribe({
          next: async ({ value }) => {
            const updatedGlobalStorage =
              value.data.onUpdateStorageByPublicSigningKey;
            handleGlobalStorage(updatedGlobalStorage);
          },
          error: () => {
            console.warn("err");
          }
        });
      } catch (err) {
        console.log("Storage Subscription error: ", err);
      }
    };
    if (window.navigator.onLine) setupSubscription();
    return () => {
      if (createStorageSub) createStorageSub.unsubscribe();
      if (updateStorageSub) updateStorageSub.unsubscribe();
    };
  }, [wallet]);

  const downloadReceipt = async paymentId => {
    const downloadBase64AsPdf = (pdf, filename) => {
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `${filename}.pdf`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    };
    const invoiceResponse = await API.graphql(
      graphqlOperation(getInvoice, {
        topUp: {
          operation: "INVOICE",
          paymentIntentId: paymentId
        }
      })
    );
    const base64Invoice = invoiceResponse.data.invoice.base64Buffer;
    downloadBase64AsPdf(base64Invoice, `Akord_Invoice_${paymentId}`);
  };

  return (
    <Context.Provider
      value={{
        transactions: transactions,
        transactionNextToken: transactionNextToken,
        globalStorage: globalStorage,
        onTransactions: handleTransactions,
        onTransactionNextToken: handleTransactionNextToken,
        onDownloadReceipt: downloadReceipt
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default StorageContextProvider;

export const withStorageContext = Component => props =>
  (
    <Context.Consumer>
      {storageContext => <Component {...props} {...storageContext} />}
    </Context.Consumer>
  );

export const useStorageContext = () => useContext(Context);
