import React from "react";
import {
  AssetDeleted,
  AssetMove,
  AssetRemoved,
  AssetRestore,
  AssetRevoked,
  DataSpaceRename,
  ErrorOperation,
  FolderCreate,
  FolderDeleted,
  FolderMove,
  FolderRename,
  FolderRestore,
  FolderRevoked,
  MemberInvite,
  MembershipAccept,
  MembershipChangeAccess,
  MembershipConfirm,
  MembershipInviteResend,
  MembershipLeave,
  MembershipReject,
  MembershipRestoreAccess,
  MembershipRevoke,
  MemoWrite,
  NoteCreated,
  RoomArchive,
  RoomCreated,
  RoomRestore,
  StackCreated,
  StackRename,
  StackRevision,
  UserVerified
} from "./timeline-actions/";

export const getTimelineAction = (
  operation,
  positionedBeforeDate,
  color,
  decryptedStacks
) => {
  switch (operation.actionRef) {
    case "DATAROOM_CREATE":
      return (
        <RoomCreated
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "DATAROOM_RENAME":
      return (
        <DataSpaceRename
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "DATAROOM_ARCHIVE":
      return (
        <RoomArchive
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "DATAROOM_RESTORE":
      return (
        <RoomRestore
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "MEMO_WRITE":
      return (
        <MemoWrite
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color === "#A167FF" ? "#8e5ae4" : color}
        />
      );
    case "MEMBERSHIP_INVITE":
    case "MEMBERSHIP_INVITE_NEW_USER":
      return (
        <MemberInvite
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "MEMBERSHIP_ACCEPT":
      return (
        <MembershipAccept
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "MEMBERSHIP_REJECT":
      return (
        <MembershipReject
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "MEMBERSHIP_REVOKE":
      return (
        <MembershipRevoke
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "MEMBERSHIP_LEAVE":
      return (
        <MembershipLeave
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "MEMBERSHIP_INVITE_RESEND":
    case "MEMBERSHIP_INVITE_NEW_USER_RESEND":
      return (
        <MembershipInviteResend
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "MEMBERSHIP_CHANGE_ACCESS":
      return (
        <MembershipChangeAccess
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "MEMBERSHIP_RESTORE_ACCESS":
      return (
        <MembershipRestoreAccess
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "MEMBERSHIP_CONFIRM":
      return (
        <MembershipConfirm
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "STACK_CREATE":
      return (
        <StackCreated
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
          decryptedStacks={decryptedStacks}
        />
      );
    case "STACK_UPLOAD_REVISION":
      return (
        <StackRevision
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
          decryptedStacks={decryptedStacks}
        />
      );
    case "STACK_RENAME":
      return (
        <StackRename
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "STACK_REVOKE":
    case "NOTE_REVOKE":
      return (
        <AssetRevoked
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "STACK_RESTORE":
    case "NOTE_RESTORE":
      return (
        <AssetRestore
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "STACK_DELETE":
    case "NOTE_DELETE":
      return (
        <AssetDeleted
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "STACK_MOVE":
    case "NOTE_MOVE":
      return (
        <AssetMove
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "STACK_REMOVE":
    case "NOTE_REMOVE":
      return (
        <AssetRemoved
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "FOLDER_CREATE":
      return (
        <FolderCreate
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "FOLDER_RENAME":
      return (
        <FolderRename
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "FOLDER_RESTORE":
      return (
        <FolderRestore
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "FOLDER_REVOKE":
      return (
        <FolderRevoked
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "FOLDER_DELETE":
    case "FOLDER_REMOVE":
      return (
        <FolderDeleted
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "FOLDER_MOVE":
      return (
        <FolderMove
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "NOTE_CREATE":
    case "NOTE_CREATE_REVISION":
      return (
        <NoteCreated
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    case "USER_VERIFIED":
      return (
        <UserVerified
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
          color={color}
        />
      );
    default:
      return (
        <ErrorOperation
          operation={operation}
          positionedBeforeDate={positionedBeforeDate}
        />
      );
  }
};
