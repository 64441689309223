import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { withRouter } from "react-router";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import {
  Button,
  TableCell,
  TableRow,
  Box,
  Typography,
  Badge,
  Tooltip
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import {
  VaultIcon,
  InfoIcon,
  GlobeIcon,
  PadlockIcon
} from "@akord/addon-icons";
import { formatDate, getDataRoomStatus } from "../../helpers/helpers";
import { memberStatusEnum } from "../../helpers/akord-enums";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import MenuRoom from "../../components/common/MoreMenu/MenuRoom";
import { RoomDrawer } from "../../components/common";
import MoreActionButton from "../../components/common/MoreActionButton";
import { useInAppNotificationsContext } from "../../contexts/InAppNotificationsContext";
import { bytesToGb } from "../storage/storage-helper";

const useStyles = makeStyles(theme => ({
  iconBackGround: {
    background: ({ isArchived, isPending, isPublic }) =>
      isArchived
        ? theme.palette.background.archived
        : isPublic
        ? theme.palette.background.stack
        : isPending
        ? theme.palette.primary.main
        : theme.palette.background.active,
    boxSizing: "content-box",
    padding: "3px",
    marginRight: theme.spacing(4),
    borderRadius: "3px"
  },
  toolLine: {
    display: "flex",
    justifyContent: "space-between",
    height: "56px",
    alignItems: "center"
  }
}));

const ToolTipText = () => {
  return (
    <Box m={2}>
      <Typography
        variant="body2"
        className="xs"
        style={{ marginBottom: 8 }}
        color="text.primary"
      >
        You’ve been invited to this vault, but your access is pending a final
        access confirmation.
      </Typography>
      <Typography
        variant="body2"
        className="xs"
        style={{ marginBottom: 8 }}
        color="text.primary"
      >
        To speed up the process, you can ask the person who invited you to
        confirm your access. They should have email and in-app notifications
        with links to confirm.
      </Typography>
      <Typography
        variant="body2"
        className="xs"
        style={{ marginBottom: 8 }}
        color="text.primary"
      >
        Once they confirm you’ll be asked to view the invite and accept.
      </Typography>
      <Typography variant="body2" className="xs" color="text.primary">
        This flow enables us to complete the necessary end-to-end encryption to
        secure your vault.
      </Typography>
    </Box>
  );
};

function RoomItem({ membership, isArchived = false, history }) {
  const location = useLocation();
  const theme = useTheme();
  const { darkMode, width, isMobile } = useGlobalContext();
  const { transactionLogs } = useInAppNotificationsContext();
  const { onCurrentMembership } = useVaultContext();

  const [menuActive, setMenuActive] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const itemId = location.state?.itemId;
  const itemRef = useRef(null);

  const isMembershipPending =
    membership?.state?.status === memberStatusEnum.PENDING;

  const isMembershipInvited =
    membership?.state?.status === memberStatusEnum.INVITED;

  const isPublic = membership?.dataRoom?.state?.isPublic;

  const classes = useStyles({
    darkMode: darkMode,
    isArchived: isArchived,
    isPending: isMembershipPending || isMembershipInvited,
    isPublic: isPublic,
    menuActive: menuActive
  });

  useEffect(() => {
    if (
      itemId &&
      membership?.dataRoomId &&
      itemId == membership.dataRoomId &&
      itemRef.current
    ) {
      itemRef.current.scrollIntoView({ behavior: "smooth" });
      highlightItem();
    }
  }, [membership]);

  const highlightItem = () => {
    const initialBackground = "inherit";
    itemRef.current.style.transition = "background-color 1s ease-in";
    itemRef.current.style.background = theme.palette.background.active;
    setTimeout(() => {
      itemRef.current.style.background = initialBackground;
    }, 1000);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
    setMenuActive(true);
    onCurrentMembership(membership);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuActive(false);
  };

  const dataRoomStatus = getDataRoomStatus(location.pathname);

  const roomSizeToShow = bytesToGb(membership?.dataRoom?.storage?.storage_used);

  const hasTerms = membership?.dataRoom?.state?.termsOfAccess?.hasTerms;

  return (
    <>
      <TableRow hover={!!membership} ref={itemRef}>
        {membership ? (
          <TableCell
            size="medium"
            style={{
              cursor: !isMembershipInvited && "pointer",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis"
              // pointerEvents: isMembershipPending && 'none'
            }}
            scope="row"
            onClick={() =>
              isMembershipPending
                ? history.push(`/invite/?membershipId=${membership.id}`)
                : isMembershipInvited
                ? null
                : history.push(
                    `/vaults/${dataRoomStatus}/${membership.dataRoomId}/assets`
                  )
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
                overlap="rectangular"
                variant="dot"
                invisible={
                  !transactionLogs ||
                  transactionLogs?.filter(
                    log =>
                      log.status === "UNREAD" &&
                      log.dataRoomId === membership.dataRoomId
                  ).length === 0
                }
              >
                <VaultIcon
                  fontSize="medium"
                  style={{
                    color: isArchived
                      ? theme.palette.text.disabled
                      : isPublic
                      ? theme.palette.info.main
                      : isMembershipPending || isMembershipInvited
                      ? theme.palette.primary.iconInvert
                      : theme.palette.primary.main
                  }}
                  className={classes.iconBackGround}
                />
              </Badge>
              {isMembershipInvited ? (
                <div style={{ display: "flex" }}>
                  <Typography variant="body2" color="text.secondary" noWrap>
                    {isMobile ? "Pending..." : "Pending access confirmation..."}
                    <Tooltip placement="right-start" title={<ToolTipText />}>
                      <span>
                        <InfoIcon
                          fontSize="small"
                          color="secondary"
                          style={{ marginLeft: 4, marginTop: -6 }}
                        />
                      </span>
                    </Tooltip>
                  </Typography>
                </div>
              ) : (
                <div style={{ display: "grid" }}>
                  <Typography variant="body2" color="text.primary" noWrap>
                    {membership?.dataRoom?.state?.title}
                  </Typography>
                </div>
              )}
            </div>
          </TableCell>
        ) : (
          <TableCell scope="row" size="medium">
            <Box display="flex">
              <Skeleton
                variant="rectangular"
                width={30}
                height={30}
                style={{ marginRight: "15px" }}
              />
              <Skeleton style={{ flex: "1" }} />
            </Box>
          </TableCell>
        )}
        {isMobile || width === "sm" ? null : membership?.dataRoom ? (
          <>
            <TableCell align="left" size="medium">
              <Typography variant="body2" color="text.primary" noWrap>
                {formatDate(membership?.dataRoom?.updatedAt)}
              </Typography>
            </TableCell>
            <TableCell align="left">
              {roomSizeToShow.size
                ? roomSizeToShow.size + roomSizeToShow.sizeType.toUpperCase()
                : "-"}
            </TableCell>
            <TableCell align="left" size="medium">
              {isPublic ? (
                <Tooltip title="Public" arrow>
                  <span>
                    <GlobeIcon fontSize="small" color="disabled" />
                  </span>
                </Tooltip>
              ) : (
                <Tooltip title="Encrypted" arrow>
                  <span>
                    <PadlockIcon fontSize="small" color="disabled" />
                  </span>
                </Tooltip>
              )}
            </TableCell>
          </>
        ) : isMembershipInvited ? (
          <>
            <TableCell align="left" size="medium">
              <Typography variant="body2" color="text.secondary" noWrap>
                -
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body2" color="text.secondary" noWrap>
                -
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body2" color="text.secondary" noWrap>
                -
              </Typography>
            </TableCell>
          </>
        ) : (
          <>
            <TableCell>
              <Skeleton style={{ height: "30px" }} />
            </TableCell>
            <TableCell>
              <Skeleton style={{ height: "30px" }} />
            </TableCell>
            <TableCell>
              <Skeleton style={{ height: "30px" }} />
            </TableCell>
          </>
        )}
        {membership ? (
          <TableCell align="right" size="medium" style={{ minWidth: "148px" }}>
            {isMembershipPending && hasTerms ? (
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() =>
                    history.push(`/invite/?membershipId=${membership.id}`)
                  }
                >
                  View terms
                </Button>
              </Box>
            ) : (
              <MoreActionButton
                handleClick={handleMenu}
                menuActive={menuActive}
                disabled={isMembershipInvited}
              />
            )}
          </TableCell>
        ) : isMobile || width === "sm" ? null : (
          <TableCell style={{ minWidth: "68px" }} />
        )}
      </TableRow>
      {isMobile ? (
        <RoomDrawer
          currentMembership={membership}
          openDrawer={openMenu}
          handleMenuClose={handleMenuClose}
          history={history}
        />
      ) : (
        <MenuRoom
          anchorEl={anchorEl}
          openMenu={openMenu}
          handleMenuClose={handleMenuClose}
          currentMembership={membership}
          history={history}
        />
      )}
    </>
  );
}

export default withRouter(RoomItem);
