import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Radio,
  Divider
} from "@mui/material";
import {
  RadioEmptyIcon,
  RadioCheckedIcon,
  TriangleDownIcon
} from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";

const useStyles = makeStyles(theme => ({
  selectMenu: {
    height: "1.1876em!important",
    minHeight: "1.1876em!important",
    fontSize: ({ outlined }) => (outlined ? "16px" : "14px"),
    color: theme.palette.text.secondary,
    minWidth: "78px",
    border: "none",
    padding: ({ outlined }) => !outlined && "2px 0 0"
  },
  select: {
    paddingTop: "16px",
    paddingBottom: "16px"
    // '&:focus': {
    //   background: ({ darkMode }) => (darkMode ? '#333' : '#faf7f6')
    // }
  },
  selectRoot: {
    // background: ({ emailFilled, darkMode }) =>
    //   emailFilled ? (darkMode ? '#333' : '#faf7f6') : 'inherit',
    color: ({ roleMenuOpen }) => roleMenuOpen && theme.palette.text.primary,
    paddingRight: ({ outlined }) => !outlined && "12px!important",
    "&:focus": {
      backgroundColor: "transparent"
    }
  },
  iconOpen: {
    color: `${theme.palette.primary.main}!important`
  },
  arrowIcon: {
    color: theme.palette.text.secondary,
    marginRight: ({ outlined }) => !outlined && "-6px",
    marginTop: ({ outlined }) => !outlined && "2px"
  },
  menuItem: {
    // fontSize: '15px',
    // minHeight: '34px',
    alignItems: "start",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(4),
    "&:first-child": {
      paddingTop: theme.spacing(5),
      paddingBottom: 0
    },
    "&:last-child": {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(5)
    }
  },
  selected: {
    background: "transparent!important"
  },
  menuList: {
    backgroundColor: theme.palette.background.secondary,
    maxWidth: "293px"
  },
  adornedEnd: {
    paddingRight: 0
  },
  primaryTypo: {
    fontSize: "1rem",
    color: theme.palette.text.primary
  },
  secondaryTypo: {
    fontSize: "1rem",
    color: theme.palette.text.secondary
  },
  radio: {
    marginTop: theme.spacing(1),
    padding: "0 8px 0 0",
    "&:hover": {
      backgroundColor: "transparent"
    },
    "&.Mui-checked:hover": {
      backgroundColor: ({ outlined }) => (outlined ? "inherit" : "transparent")
    }
  },
  paper: {
    marginTop: theme.spacing(2)
  }
}));

function SelectMenu(props) {
  const { darkMode } = useGlobalContext();
  const {
    member,
    outlined,
    handleFormChange,
    index,
    fromInvite = false
  } = props;
  const [roleMenuOpen, setRoleMenuOpen] = React.useState(false);
  const classes = useStyles({
    darkMode: darkMode,
    roleMenuOpen: roleMenuOpen,
    outlined: outlined
  });

  return (
    <FormControl style={{ maxWidth: "136px" }}>
      <Select
        variant={outlined ? "outlined" : "standard"}
        {...(!outlined && { disableUnderline: true })}
        name="role"
        labelId="permissions-select-outlined-label"
        id="permissions-select-outlined"
        value={member.role}
        IconComponent={TriangleDownIcon}
        renderValue={() =>
          member.role === "CONTRIBUTOR"
            ? "Contributor"
            : member.role === "REVOKED"
            ? "Revoked"
            : "View Only"
        }
        classes={{
          select: classes.selectMenu,
          outlined: classes.select,
          iconOpen: classes.iconOpen
        }}
        MenuProps={{
          elevation: 4,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right"
          },
          classes: {
            list: classes.menuList,
            paper: classes.paper
          }
        }}
        inputProps={{
          classes: {
            root: classes.selectRoot,
            icon: classes.arrowIcon
          }
        }}
        onOpen={() => setRoleMenuOpen(true)}
        onClose={() => setRoleMenuOpen(false)}
        onChange={e => handleFormChange(e, index, member)}
      >
        <MenuItem
          classes={{ selected: classes.selected }}
          className={classes.menuItem}
          value="CONTRIBUTOR"
        >
          <Radio
            icon={<RadioEmptyIcon />}
            checkedIcon={<RadioCheckedIcon />}
            className={classes.radio}
            disableRipple
            disableFocusRipple
            checked={member.role === "CONTRIBUTOR"}
          />
          <ListItemText
            style={{ whiteSpace: "normal" }}
            primary="Contributor"
            primaryTypographyProps={{
              classes: { body1: classes.primaryTypo }
            }}
            secondaryTypographyProps={{
              classes: { body2: classes.secondaryTypo }
            }}
            secondary="People can upload files, request data and delete files."
          />
        </MenuItem>
        <MenuItem
          classes={{ selected: classes.selected }}
          className={classes.menuItem}
          value="VIEWER"
        >
          <Radio
            icon={<RadioEmptyIcon />}
            checkedIcon={<RadioCheckedIcon />}
            className={classes.radio}
            disableRipple
            disableFocusRipple
            checked={member.role === "VIEWER"}
          />
          <ListItemText
            primaryTypographyProps={{
              classes: { body1: classes.primaryTypo }
            }}
            secondaryTypographyProps={{
              classes: { body2: classes.secondaryTypo }
            }}
            style={{ whiteSpace: "normal" }}
            primary="View only"
            secondary="People can view and download."
          />
        </MenuItem>

        {!fromInvite && <Divider light />}

        {!fromInvite && (
          <MenuItem
            classes={{ selected: classes.selected }}
            className={classes.menuItem}
            value="REVOKED"
          >
            <Radio
              icon={<RadioEmptyIcon />}
              checkedIcon={<RadioCheckedIcon />}
              className={classes.radio}
              disableRipple
              disableFocusRipple
              checked={member.role === "REVOKED"}
            />
            <ListItemText
              primaryTypographyProps={{
                classes: { body1: classes.primaryTypo }
              }}
              secondaryTypographyProps={{
                classes: { body2: classes.secondaryTypo }
              }}
              style={{ whiteSpace: "normal" }}
              primary="Revoke access"
            />
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

export default SelectMenu;
