import { TickIcon, CloseIcon, CloseInCircleIcon } from "@akord/addon-icons";
import API, { graphqlOperation } from "@aws-amplify/api";
import * as mutations from "../../../graphql/mutations";

export const notificationsMenuOptions = handleMenuClose => [
  {
    text: "Mark all as read",
    icon: TickIcon,
    action: async () => {
      handleMenuClose();
      await API.graphql(
        graphqlOperation(mutations.updateNotificationStatus, {
          operation: "MARK_ALL_AS_READ",
          ids: []
        })
      );
      console.log("Mark all as read");
    },
    disabled: false,
    show: true,
    upload: false
  },
  {
    text: "Clear all read notifications",
    icon: CloseInCircleIcon,
    action: async () => {
      handleMenuClose();
      await API.graphql(
        graphqlOperation(mutations.updateNotificationStatus, {
          operation: "CLEAR_ALL_READ",
          ids: []
        })
      );
      console.log("Clear all read notifications");
    },
    disabled: false,
    show: true,
    upload: false
  },
  {
    text: "Clear all notifications",
    icon: CloseIcon,
    action: async () => {
      handleMenuClose();
      await API.graphql(
        graphqlOperation(mutations.updateNotificationStatus, {
          operation: "CLEAR_ALL",
          ids: []
        })
      );
      console.log("Clear all notifications");
    },
    disabled: false,
    show: true,
    upload: false
  }
];
