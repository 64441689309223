import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Box,
  Divider
} from "@mui/material";
import { VaultIcon } from "@akord/addon-icons";

const useStyles = makeStyles(theme => ({
  iconBackGround: {
    background: ({ isRoomArchived, isStack }) =>
      isRoomArchived
        ? theme.palette.background.archived
        : isStack
        ? theme.palette.background.stack
        : theme.palette.background.active,
    boxSizing: "content-box",
    padding: "5px",
    color: ({ isStack }) => isStack && theme.palette.info.secondary
  },
  iconFolder: {
    boxSizing: "content-box",
    // padding: '5px',
    color: "#8B7872"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

function MoreDrawerRoot({
  children,
  openDrawer,
  handleMenuClose,
  isRoomArchived,
  isStack,
  isFolder,
  header,
  icon
}) {
  const classes = useStyles({
    isRoomArchived: isRoomArchived,
    isStack: isStack
  });

  const HeaderIcon = icon ? icon : VaultIcon;

  return (
    <Drawer
      elevation={16}
      anchor="bottom"
      open={openDrawer}
      onClose={handleMenuClose}
    >
      <Box mx={5}>
        <List
          component="nav"
          aria-label="active files"
          style={{ listStyleType: "none" }}
        >
          <Box my={3}>
            <ListItem disableGutters className={classes.header}>
              <ListItemIcon>
                <HeaderIcon
                  fontSize="small"
                  color={isRoomArchived ? "disabled" : "action"}
                  className={
                    isFolder ? classes.iconFolder : classes.iconBackGround
                  }
                />
              </ListItemIcon>
              <ListItemText>
                <Typography noWrap variant="body2" color="text.primary">
                  {header}
                </Typography>
              </ListItemText>
            </ListItem>
          </Box>
          <Divider />
          {children}
        </List>
      </Box>
    </Drawer>
  );
}

export default MoreDrawerRoot;
