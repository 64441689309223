import React from "react";
import { Box, Typography, Switch, Grid } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { Auth } from "aws-amplify";
import { SupportScreenWrapper } from "../../components/common/";

const AntSwitch = withStyles(theme => ({
  root: {
    width: 33,
    height: 16,
    padding: 0,
    display: "flex"
  },
  switchBase: {
    padding: 0,
    left: 3,
    top: 2,
    color: theme.palette.background.default,
    "&$checked": {
      transform: "translateX(15px)",
      color: theme.palette.background.default,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      },
      "&:hover": {
        color: theme.palette.background.default
      }
    },
    "&:hover": {
      color: theme.palette.background.default
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none"
  },
  track: {
    // border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 26 / 2,
    opacity: 1,
    backgroundColor: theme.palette.background.switch
  },
  checked: {}
}))(Switch);

function Notifications() {
  const [checked, setChecked] = React.useState(true);
  const handleChange = async event => {
    setChecked(event.target.checked);
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      "custom:notifications": event.target.checked.toString()
    });
  };

  React.useEffect(() => {
    const getNotifications = async () => {
      const user = await Auth.currentAuthenticatedUser();
      const notifications = user.attributes["custom:notifications"];
      setChecked(notifications === "true");
    };
    getNotifications();
  }, []);

  return (
    <SupportScreenWrapper title="Notifications" route="/settings">
      <Box mt={5}>
        <Grid
          component="label"
          container
          alignItems="flex-start"
          spacing={2}
          wrap="nowrap"
        >
          <Grid item>
            <Grid direction="column" container>
              <Grid item>
                <Typography
                  component="div"
                  variant="body2"
                  color="text.primary"
                >
                  Email
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="text.secondary">
                  Receive an email notification any time there is activity in
                  your vaults.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <AntSwitch
              checked={checked}
              onChange={handleChange}
              name="checked"
            />
          </Grid>
        </Grid>
      </Box>
    </SupportScreenWrapper>
  );
}

export default Notifications;
