import { Box, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleChevronLeftIcon,
  DoubleChevronRightIcon
} from "@akord/addon-icons";
import PaginationRow from "./PaginationRow";
import PaginationSelect from "./PaginationSelect";

const useStyles = makeStyles(theme => ({
  disabled: {
    "&.Mui-disabled": {
      color: theme.palette.background.tertiary
    }
  }
}));

function Pagination({
  bottomPosition = false,
  pages,
  currentPage,
  onGoToNextPage,
  onGoToPreviousPage,
  onChangePage
}) {
  const { isMobile } = useGlobalContext();
  const classes = useStyles();

  if (pages <= 1) return <Box width="100px">{""}</Box>;

  return (
    <Box
      width={isMobile ? "auto" : "100px"}
      mr={bottomPosition ? 0 : 6}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
    >
      {bottomPosition && (
        <IconButton
          onClick={() => onChangePage(1)}
          disabled={currentPage === 1}
          className={classes.disabled}
          style={{ marginRight: isMobile && "12px" }}
          size="large"
        >
          <DoubleChevronLeftIcon fontSize={isMobile ? "large" : "default"} />
        </IconButton>
      )}
      {(currentPage > 1 || bottomPosition) && (
        <IconButton
          onClick={onGoToPreviousPage}
          disabled={currentPage === 1}
          className={classes.disabled}
          style={{ marginRight: isMobile && "12px" }}
          size="large"
        >
          <ChevronLeftIcon
            fontSize={
              bottomPosition ? (isMobile ? "large" : "medium") : "small"
            }
          />
        </IconButton>
      )}
      {bottomPosition ? (
        <PaginationRow
          pages={pages}
          currentPage={currentPage}
          onChangePage={onChangePage}
        />
      ) : (
        <PaginationSelect
          pages={pages}
          currentPage={currentPage}
          onChangePage={onChangePage}
        />
      )}
      <IconButton
        onClick={onGoToNextPage}
        disabled={currentPage === pages}
        className={classes.disabled}
        style={{ marginLeft: isMobile && "12px" }}
        size="large"
      >
        <ChevronRightIcon
          fontSize={bottomPosition ? (isMobile ? "large" : "medium") : "small"}
        />
      </IconButton>
      {bottomPosition && (
        <IconButton
          onClick={() => onChangePage(pages)}
          disabled={currentPage === pages}
          className={classes.disabled}
          style={{ marginLeft: isMobile && "12px" }}
          size="large"
        >
          <DoubleChevronRightIcon fontSize={isMobile ? "large" : "default"} />
        </IconButton>
      )}
    </Box>
  );
}

export default Pagination;
