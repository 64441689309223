import { Auth, Hub } from 'aws-amplify'
import { AkordWallet } from '@akord/crypto'
import { clearDB } from '../../helpers/db'
import { KEEP_SIGNED_IN } from './constants'

export const registerAkordAuthHandler = () => {
  const listener = async data => {
    switch (data.payload.event) {
      case 'resetSession':
        localStorage.removeItem('height')
        await clearDB()
        break
      case 'unauthorized':
        await AkordWallet.clear()
        await clearDB()
        localStorage.clear()
        sessionStorage.clear()
        break
      case 'preAuthenticate':
        if (sessionStorage.getItem(KEEP_SIGNED_IN) == 'false') {
          Auth.configure({ storage: sessionStorage })
        }
        break
      case 'preSignIn':
        Auth.configure({
          storage: data.payload.data.keepSignedIn
            ? localStorage
            : sessionStorage
        })
        sessionStorage.setItem(KEEP_SIGNED_IN, data.payload.data.keepSignedIn)
        break
    }
  }

  Hub.listen('akord:auth', listener)
}
