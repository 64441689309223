import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";
import { CloseIcon, SearchIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useSearchContext } from "../../contexts/SearchContextProvider";
import { grey } from "../../theme/colors";

const useStyles = makeStyles(theme => ({
  // input: {
  //   paddingBottom: "8px"
  // },
  inputActive: {
    transition: "width 0.5s linear 0s"
  },
  textFieldWithButton: {
    transition: "width 0.3s linear 0s",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid",
    borderRadius: "4px",
    background: ({ darkMode }) => `linear-gradient(
      to left,
      ${darkMode ? grey[800] : grey[100]} 0px,
      ${darkMode ? grey[800] : grey[100]} 37px,
      transparent 37px,
      transparent 100%
    )`
  },
  textFieldWithButtonInactive: {
    width: "10%",
    minWidth: "115px",
    borderColor: ({ darkMode }) => (darkMode ? grey[700] : grey[300]),
    "&:hover": {
      borderColor: theme.palette.primary.main
    }
    // background: ({ darkMode }) => `linear-gradient(
    //   to left,
    //   ${darkMode ? grey[800] : grey[100]} 0px,
    //   ${darkMode ? grey[800] : grey[100]} 37px,
    //   transparent 37px,
    //   transparent 100%
    // )`
  },
  textFieldWithButtonActive: {
    width: ({ forceActive }) => (forceActive ? "100%" : "75%"),
    minWidth: "115px",
    borderColor: theme.palette.primary.main,
    padding: "0px"
    // background: ({ darkMode }) => `linear-gradient(
    //   to left,
    //   ${darkMode ? grey[800] : grey[100]} 0px,
    //   ${darkMode ? grey[800] : grey[100]} 37px,
    //   transparent 37px,
    //   transparent 100%
    // )`
  },
  textFieldWithButtonReady: {
    width: ({ forceActive }) => (forceActive ? "100%" : "75%"),
    borderColor: theme.palette.primary.main,
    padding: "0px",
    background: `linear-gradient(
      to left,
      ${theme.palette.primary.main} 0px,
      ${theme.palette.primary.main} 37px,
      transparent 37px,
      transparent 100%
    )`
  },
  textField: {
    width: "100%",
    height: "40px",
    minWidth: "70px",
    display: "flex",
    justifyContent: "flex-end",
    transition: "width 0.5s linear 0s",
    border: "none",
    marginBottom: 0,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none"
      },
      "&.Mui-focused fieldset": {
        border: "none"
      },
      "&:hover fieldset": {
        border: "none"
      }
    }
  },
  textFieldActive: {
    width: "100%"
  },
  textFieldRoot: {
    fontSize: "15px",
    height: "100%",
    border: "none"
  },
  container: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    maxHeight: "40px"
  },

  searchButton: {
    border: "none",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    padding: "0 8px",
    height: "38px"
  },

  searchButtonInactive: {
    // background: ({ darkMode }) => (darkMode ? grey[800] : grey[100])
    "&.MuiButton-outlined": {
      border: "none"
    },
    "& .MuiButton-label": {
      color: ({ darkMode }) => (darkMode ? grey[500] : grey[400])
    }
  },
  searchButtonActive: {
    // background: ({ darkMode }) => (darkMode ? grey[800] : grey[100]),
    "&.MuiButton-outlinedPrimary": {
      border: "none"
    },
    "& .MuiButton-label": {
      color: ({ darkMode }) => (darkMode ? grey[500] : grey[400])
    }
  }
}));

function SearchBox({ showRecentSearchTerm = false, forceActive = false }) {
  const { darkMode } = useGlobalContext();
  const classes = useStyles({ forceActive: forceActive, darkMode: darkMode });
  const history = useHistory();
  const {
    isIndexing,
    onSearch,
    searchResults,
    recentSearchTerm,
    onRecentSearchTerm,
    isSearchActive,
    onSearchActive,
    onIsAutosuggesting
  } = useSearchContext();
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchReady, setIsSearchReady] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (!searchTerm) {
      onSearchActive(false);
      onIsAutosuggesting(false);
    }
  }, []);

  useEffect(() => {
    if (isSearching) {
      setIsSearching(false);
      history.push({
        pathname: "/search-results",
        search: `?term=${searchTerm}`
      });
    }
  }, [searchResults]);

  useEffect(() => {
    setSearchState();
  }, [searchTerm]);

  useEffect(() => {
    setSearchState();
  }, [isSearchActive]);

  useEffect(() => {
    if (showRecentSearchTerm) {
      setSearchTerm(recentSearchTerm);
      onSearchActive(true);
    }
  }, [showRecentSearchTerm]);

  useEffect(() => {
    if (!showRecentSearchTerm) {
      setSearchState();
    }
  }, [isSearchReady]);

  const setSearchState = () => {
    const isReady =
      isSearchActive && searchTerm && searchTerm.length > 2 && !isIndexing;
    setIsSearchReady(isReady);
  };

  const searchHandler = () => () => {
    handleSearch();
  };

  const handleSearch = () => {
    if (!isIndexing && isSearchActive) {
      setIsSearching(true);
      onRecentSearchTerm(searchTerm);
      onSearch(searchTerm);
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    onRecentSearchTerm("");
    history.push({
      pathname: "/"
    });
  };

  const handleSearchTermChange = () => event => {
    const term = event.target.value;
    const isAutosuggesting = term && term.length > 0;
    onIsAutosuggesting(isAutosuggesting);
    const isReady = term && term.length > 2 && !isIndexing;
    setIsSearchReady(isReady);
    setSearchTerm(term);
  };

  const onKeyPressed = e => {
    if (e.key == "Enter") {
      handleSearch();
    }
  };

  const isSearchBoxActive = () => {
    return isSearchActive || forceActive;
  };

  return (
    <div className={classes.container} onKeyDown={onKeyPressed} tabIndex="0">
      <Box
        className={`${classes.textFieldWithButton} ${
          isSearchReady
            ? classes.textFieldWithButtonReady
            : isSearchBoxActive()
            ? classes.textFieldWithButtonActive
            : classes.textFieldWithButtonInactive
        }`}
      >
        <TextField
          placeholder="Search"
          variant="outlined"
          name="search"
          id="search-input"
          size="small"
          className={`${classes.textField} ${
            isSearchBoxActive() ? classes.textFieldActive : ""
          }`}
          inputProps={{
            margin: "dense",
            className: `${classes.input} ${
              isSearchBoxActive() ? classes.inputActive : ""
            }`
          }}
          InputProps={{
            classes: {
              root: classes.textFieldRoot
            },
            endAdornment: isSearchReady ? (
              <IconButton
                aria-label="close"
                onClick={() => handleClearSearch()}
                size="large"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            ) : (
              ""
            )
          }}
          onFocus={() => onSearchActive(true)}
          onBlur={() =>
            setTimeout(() => {
              onSearchActive(false);
            }, 100)
          }
          value={searchTerm}
          onChange={handleSearchTermChange()}
        />
        <Button
          variant={isSearchReady ? "contained" : "outlined"}
          size="small"
          color="primary"
          className={`${classes.searchButton} ${
            !isSearchReady
              ? isSearchBoxActive()
                ? classes.searchButtonActive
                : classes.searchButtonInactive
              : ""
          }`}
          disabled={!isSearchReady}
          onClick={searchHandler()}
        >
          <SearchIcon fontSize="small" />
        </Button>
      </Box>
    </div>
  );
}

export default SearchBox;
