import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel
} from "@mui/material";
import { backUpText, backUpTextLabel } from "../../helpers/akordTexts";
import { AkordCheckbox } from "../../components/common/";
import { AccountBackupIcon } from "@akord/addon-icons";
import { useSignupContext } from "../../contexts/SignupContext";
import { withRouter } from "react-router";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  rootWithIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }
});

function BackUpAbout({ history }) {
  const classes = useStyles();
  const { handleFormChange, formData, isDesktop } = useSignupContext();

  const switchRouteAbout = () => {
    history.push("/signup/backup-info");
  };

  return (
    <Box className={classes.root} style={{ flexGrow: !isDesktop && 1 }}>
      <Box mb={8}>
        <Box mb={3} className={classes.rootWithIcon}>
          <Typography variant="h1">Account backup</Typography>
          <AccountBackupIcon fontSize="large" color="primary" />
        </Box>
        <Box className={classes.root}>
          <Box variant="body1">
            {backUpText.map((text, i) => (
              <Typography key={i} variant="body2" paragraph>
                {text}
              </Typography>
            ))}
            <Typography
              variant="body2"
              component="a"
              onClick={switchRouteAbout}
            >
              Learn more about your account backup.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              style={{ alignItems: "normal" }}
              control={
                <AkordCheckbox
                  inputProps={{ "aria-label": "acceptPhrase" }}
                  checked={formData.acceptPhrase}
                  onChange={handleFormChange("acceptPhrase")}
                  name="acceptPhrase"
                />
              }
              label={backUpTextLabel}
            />
          </FormGroup>
        </FormControl>
      </Box>
    </Box>
  );
}

export default withRouter(BackUpAbout);
