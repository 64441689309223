import React, { useState } from 'react'
import { Switch, Route, useRouteMatch, withRouter } from 'react-router-dom'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery'
import { PageWrapper } from '../../components/common/'
import RecoverEmail from './RecoverEmail'
import RecoverSteps from './RecoverSteps'
import { stepsData } from './stepsData'
import { useRecoverAccountContext } from '../../contexts/RecoverAccountContext'
import { useSnackbarContext } from '../../contexts/SnackbarContextProvider'
import SnackBarConfirm from '../../components/common/SnackBar/SnackBarConfirm'

const useStyles = makeStyles({
  main: {
    justifyContent: ({ spaceBetween, isDesktop }) => isDesktop && spaceBetween
  }
})

const steps = ['recover', 'backup-phrase', 'reset-password']

function RecoverPageContainer(props) {
  const {
    formData,
    passEvaluation,
    checkBackupPhrase,
    backupPhrase,
    handleError,
    error
  } = useRecoverAccountContext()
  const { onSnackbarToShow } = useSnackbarContext()

  const { path } = useRouteMatch()
  const match = useRouteMatch(`${path}/:step`)
  const currentStep = !match ? 'recover' : match.params.step

  const [loading, setLoading] = useState(false)

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true
  })

  const classes = useStyles({
    spaceBetween: stepsData[currentStep].spaceBetween,
    isDesktop: isDesktop
  })

  const handleBack = () => {
    props.history.goBack()
  }

  const handleNextProps = {
    onSnackbarToShow,
    handleError,
    formData,
    props,
    steps,
    currentStep,
    path,
    backupPhrase,
    setLoading,
    error
  }

  const errorMessage = error => {
    if (!error) return
    switch (error.code) {
      case 'UserNotFoundException':
        return 'An account with this email address does not exist.'

      default:
        return error.message
    }
  }

  return (
    <PageWrapper
      error={error}
      errorText={errorMessage(error)}
      darkMode={stepsData[currentStep].darkMode}
      showBottomNav={stepsData[currentStep].showBottomNav}
      mainClassName={classes.main}
      nextText={stepsData[currentStep].nextText}
      nextDiasabled={stepsData[currentStep].isButtonDisabled({
        formData,
        checkBackupPhrase,
        loading,
        passEvaluation,
        error
      })}
      handleBack={handleBack}
      handleNext={() => stepsData[currentStep].handleNext(handleNextProps)}
      hideBackButton={stepsData[currentStep].hideBackButton}
      fullWidthNextButton={stepsData[currentStep].fullWidthNextButton}
      hideIcon={stepsData[currentStep].hideIcon}
      disabledRed={stepsData[currentStep].disabledRed}
      loading={loading}
      pageTitle="Recover account"
      {...props}
    >
      <Box>
        <Switch>
          <Route path="/recover/:step">
            <RecoverSteps />
          </Route>
          <Route path="/recover">
            <RecoverEmail />
          </Route>
        </Switch>
      </Box>
      <SnackBarConfirm />
    </PageWrapper>
  )
}

export default withRouter(RecoverPageContainer)
