import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  IconButton,
  Box,
  Typography,
  Fab,
  Tooltip,
  CircularProgress
} from "@mui/material";
import {
  getDataRoomStatus,
  getDataRoomId,
  getFolderId,
  isArchived,
  getAllFoldersRecursively
} from "../../../helpers/helpers";
import {
  FilterIcon,
  PlusIcon,
  FiberManualRecordIcon
} from "@akord/addon-icons";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { useProgressContext } from "../../../contexts/ProgressContext";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import MenuRoom from "../MoreMenu/MenuRoom";
import { RoomDrawer } from "..";
import { grey } from "../../../theme/colors/";
import MoreActionButton from "../MoreActionButton";
import TopBarTitleMobile from "./TopBarTitleMobile";
import TopBarTitleDesktop from "./TopBarTitleDesktop";
import TransactionSpinner from "../TransactionSpinner";
import DropdownMenu from "./DropdownMenu";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";

const useStyles = makeStyles(theme => ({
  toolBarTop: {
    marginTop: ({ isMobile }) => (isMobile ? 0 : "20px"),
    justifyContent: "space-between",
    minHeight: "34px"
  },
  grow: {
    flexGrow: 1
  },
  iconHeader: {
    marginRight: "8px"
  },
  fabIcon: {
    cursor: "pointer"
  },
  fab: {
    "&.Mui-disabled": {
      color: ({ darkMode }) =>
        darkMode ? `${grey[500]}!important` : `${grey[400]}!important`,
      backgroundColor: ({ darkMode }) =>
        darkMode ? `${grey[700]}!important` : `${grey[200]}!important`
    }
  },
  paddingForToolTip: {
    paddingRight: theme.spacing(2),
    paddingLeft: ({ isMobile }) => (isMobile ? 0 : theme.spacing(3))
  },
  filterActive: {
    flexDirection: "column"
  },
  dotMark: {
    fontSize: "8px",
    position: "absolute",
    bottom: "-8px"
  },
  fabProgress: {
    color: "white",
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1
  }
}));

const CircularProgressWithLabel = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    position="relative"
    display="inline-flex"
    style={{
      width: "34px",
      height: "34px",
      background: "#F85B15",
      borderRadius: "50%",
      verticalAlign: "middle",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "6px"
    }}
  >
    <CircularProgress
      size={28}
      variant="determinate"
      style={{ color: "white" }}
      value={props.value}
    />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        variant="caption"
        style={{ fontSize: "11px", color: "#FFF" }}
      >{`${Math.round(props.value)}`}</Typography>
    </Box>
  </Box>
));

function TopBarSecondary(props) {
  const [menuActive, setMenuActive] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElDropDown, setAnchorElDropDown] = React.useState(null);

  const openDropDown = Boolean(anchorElDropDown);
  const openMenu = Boolean(anchorEl);

  const { filterActive, history } = props;
  const { progress } = useProgressContext();
  const { isMobile, darkMode, txSpinner, decrptSpinner } = useGlobalContext();
  const { currentMembership, userRole, isVaultPublic } = useVaultContext();
  const { showFilter, onShowFilter, decryptedFolders } = useAssetsContext();

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
    setMenuActive(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuActive(false);
  };

  const handleDropDown = event => {
    setAnchorElDropDown(event.currentTarget);
  };

  const handleCloseDropDown = () => {
    setAnchorElDropDown(null);
  };

  const isRoomArchived = isArchived(currentMembership);

  const classes = useStyles({
    darkMode: darkMode,
    isMobile: isMobile,
    menuActive: menuActive
  });

  const folderId = getFolderId(history.location.pathname);
  const currentFolder = decryptedFolders?.filter(
    folder => folder.id === folderId
  )[0];

  const parentFolders = currentFolder
    ? getAllFoldersRecursively(decryptedFolders, currentFolder)
    : [];

  return (
    <Box mt={4} mx={5} mb={3} display="flex" justifyContent="space-between">
      {isMobile ? (
        <TopBarTitleMobile
          currentMembership={currentMembership}
          currentFolder={currentFolder}
          parentFolders={parentFolders}
          folderId={folderId}
          isRoomArchived={isRoomArchived}
          dataRoomStatus={getDataRoomStatus(history.location.pathname)}
          dataRoomId={getDataRoomId(history.location.pathname)}
          isVaultPublic={isVaultPublic}
        />
      ) : (
        <TopBarTitleDesktop
          variant="h2"
          color="text.secondary"
          currentMembership={currentMembership}
          currentFolder={currentFolder}
          parentFolders={parentFolders}
          folderId={folderId}
          isRoomArchived={isRoomArchived}
          dataRoomStatus={getDataRoomStatus(history.location.pathname)}
          dataRoomId={getDataRoomId(history.location.pathname)}
          isVaultPublic={isVaultPublic}
        />
      )}

      <Box display="flex">
        {txSpinner && (
          <TransactionSpinner isMobile={isMobile} darkMode={darkMode} />
        )}
        {decrptSpinner && !txSpinner && (
          <TransactionSpinner
            isMobile={isMobile}
            darkMode={darkMode}
            text="Decryption"
            mobileText=""
          />
        )}
        <Box display="flex" ml={4}>
          {!isMobile && (
            <>
              <Tooltip title="Add an asset" arrow>
                <div
                  style={{
                    width: "32px",
                    height: "32px",
                    cursor:
                      userRole === "VIEWER" || isRoomArchived
                        ? "not-allowed"
                        : "inherit",
                    display: "inline-block"
                  }}
                >
                  {progress > 0 && history.location.pathname.match("/chat") ? (
                    <CircularProgressWithLabel value={progress} />
                  ) : (
                    <Fab
                      component="button"
                      disabled={userRole === "VIEWER" || isRoomArchived}
                      className={classes.fab}
                      size="small"
                      color="primary"
                      onClick={handleDropDown}
                    >
                      <PlusIcon
                        className={[classes.fabIcon, "step3"].join(" ")}
                      />
                    </Fab>
                  )}
                </div>
              </Tooltip>
              <DropdownMenu
                type="plusMenu"
                anchorEl={anchorElDropDown}
                onAnchorEl={setAnchorElDropDown}
                onClose={handleCloseDropDown}
                openMenu={openDropDown}
                position="right"
              />
            </>
          )}
          <Tooltip title="Filter the timeline" arrow>
            <IconButton
              disableRipple
              aria-label="filter"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              classes={{ label: classes.filterActive }}
              className={classes.paddingForToolTip}
              onClick={() => onShowFilter(!showFilter)}
            >
              <FilterIcon className="step4" />
              {filterActive && (
                <FiberManualRecordIcon
                  color="action"
                  className={classes.dotMark}
                />
              )}
            </IconButton>
          </Tooltip>
          <MoreActionButton
            menuActive={menuActive}
            handleClick={handleMenu}
            onboarding
          />
        </Box>
        {!isMobile ? (
          <MenuRoom
            anchorEl={anchorEl}
            openMenu={openMenu}
            handleMenuClose={handleMenuClose}
            currentMembership={currentMembership}
            history={history}
          />
        ) : (
          <RoomDrawer
            currentMembership={currentMembership}
            openDrawer={openMenu}
            handleMenuClose={handleMenuClose}
            history={history}
          />
        )}
      </Box>
    </Box>
  );
}

export default TopBarSecondary;
