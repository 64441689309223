import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from "@mui/material/";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {
  ArrowUpIcon,
  DoubleTickIcon,
  OpenIcon,
  InfoIcon,
  ReceiptIcon,
  TickIcon,
  CloseInCircleIcon
} from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useStorageContext } from "../../contexts/StorageContextProvider";
import {
  decryptStacks,
  decryptObjects,
  mimicDecryptObjects,
  mimicDecryptStacks
} from "../../helpers/decrypt-helper";
import { formatDate, getComparator, stableSort } from "../../helpers/helpers";
import { grey } from "../../theme/colors/index";
import {
  arweaveURL,
  bytesToGb,
  createDataForTable,
  formatRefId,
  getTypeIconAndTitle,
  rowNames
} from "./storage-helper.js";

const useStyles = makeStyles(theme => ({
  paper: {
    width: "200px",
    minWidth: "100%",
    backgroundColor: ({ darkMode }) => (darkMode ? grey[800] : grey[100]),
    boxShadow: "none",
    border: ({ darkMode }) => (darkMode ? "none" : `1px solid ${grey[200]}`)
  },
  cell: {
    whiteSpace: "nowrap",
    borderBottomColor: ({ darkMode }) => (darkMode ? grey[700] : grey[200])
  },
  cellHead: {
    padding: "0 20px"
  },
  cellBody: {
    padding: "8px 20px 4px"
  },
  pending: {
    color: theme.palette.primary.light
  },
  success: {
    color: theme.palette.success.main
  },
  rowHover: {
    "&.MuiTableRow-hover": {
      "&:hover": {
        backgroundColor: ({ darkMode }) =>
          darkMode ? "#403F4630!important" : "#E8E6EF30!important"
      }
    },
    "&:last-child td": {
      borderBottom: 0
    }
  }
}));

function ToolTipText() {
  return (
    <Box m={2}>
      <Typography
        variant="body2"
        className="xs"
        style={{ marginBottom: 8 }}
        color="text.primary"
      >
        Listed below are all your storage transactions.
      </Typography>
      <Typography
        variant="body2"
        className="xs"
        style={{ marginBottom: 8 }}
        color="text.primary"
      >
        For any upload, clicking the icon in the far right will take you to
        viewblock.io, where you can view the transaction info on the Arweave
        blockchain.
      </Typography>
      <Typography variant="body2" className="xs" color="text.primary">
        We also list all your top up payments, and clicking the far right icon
        will show you your receipt.
      </Typography>
    </Box>
  );
}

function TransactionIconButton({ transaction }) {
  const { onDownloadReceipt } = useStorageContext();
  const [loading, setLoading] = React.useState(false);

  const handleReceiptDownload = async () => {
    setLoading(true);
    await onDownloadReceipt(transaction.refId);
    setLoading(false);
  };

  if (loading) {
    return (
      <CircularProgress
        component="span"
        size={14}
        style={{ position: "absoulute" }}
      />
    );
  }
  return (
    <>
      {transaction.type === "TOPUP" ? (
        <IconButton onClick={() => handleReceiptDownload()} size="large">
          <ReceiptIcon fontSize="small" />
        </IconButton>
      ) : (
        <IconButton
          aria-label="show transaction"
          onClick={() => window.open(`${arweaveURL}${transaction.refId}`)}
          size="large"
        >
          <OpenIcon fontSize="small" />
        </IconButton>
      )}
    </>
  );
}

export default function TransactionsTable() {
  const { darkMode, onEncrypterFromMembership } = useGlobalContext();
  const { transactions } = useStorageContext();

  const [stackDictionary, setStackDictionary] = React.useState();
  const handleStackDictionary = dictionary => setStackDictionary(dictionary);

  const [fileDictionary, setFileDictionary] = React.useState();
  const handleFileDictionary = dictionary => setFileDictionary(dictionary);

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("timestamp");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  const classes = useStyles({ darkMode: darkMode });

  const prepareDataForTable = transactions
    ?.map(transaction => createDataForTable(transaction))
    .sort((a, b) => {
      return new Date(b.timestamp) - new Date(a.timestamp);
    });

  const decryptedItemsPromise = transactions?.map(async transaction => {
    if (!transaction?.memberships?.items[0]) return;

    const isVaultPublic =
      transaction?.memberships?.items[0].dataRoom.state.isPublic;

    const encrypter = !isVaultPublic
      ? onEncrypterFromMembership(transaction?.memberships?.items[0])
      : null;

    let decryptedStacksArray = [];
    if (transaction.stack) {
      if (!isVaultPublic)
        decryptedStacksArray = await decryptStacks(
          encrypter,
          [transaction.stack],
          {
            dataRoomId: transaction.memberships.items[0].dataRoom.id,
            dataRoomName: transaction.memberships.items[0].dataRoom.state.title,
            dataRoomStatus:
              transaction.memberships.items[0].dataRoom.state.status
          }
        );
      else
        decryptedStacksArray = mimicDecryptStacks([transaction.stack], {
          dataRoomId: transaction.memberships.items[0].dataRoom.id,
          dataRoomName: transaction.memberships.items[0].dataRoom.state.title,
          dataRoomStatus: transaction.memberships.items[0].dataRoom.state.status
        });
    }

    let decryptedNotesArray = [];
    if (transaction.note) {
      if (!isVaultPublic)
        decryptedNotesArray = await decryptObjects(
          encrypter,
          [transaction.note],
          ["title", "content"]
        );
      else decryptedNotesArray = mimicDecryptObjects([transaction.note]);
    }
    return [...decryptedStacksArray, ...decryptedNotesArray];
  });

  React.useEffect(() => {
    const decryptItems = async () => {
      const stacksDictionary = {};
      const filesDictionary = {};
      const decryptedItems = decryptedItemsPromise
        ? await Promise.all(decryptedItemsPromise)
        : [];

      decryptedItems.flat().map(item => {
        if (item?.files) {
          item.files.map(file => {
            if (file.hash) filesDictionary[file.hash] = file;
          });
        } else if (item?.revisions) {
          item.revisions.map(revision => {
            if (revision.hash) filesDictionary[revision.hash] = revision;
          });
        }
        stacksDictionary[item?.id] = item;
      });
      handleStackDictionary(stacksDictionary);
      handleFileDictionary(filesDictionary);
    };
    if (transactions) decryptItems();
  }, [transactions]);

  const getStatusIcon = status => {
    switch (status) {
      case "COMMITTED":
        return (
          <Tooltip title="Stored on Arweave" arrow>
            <span>
              <DoubleTickIcon fontSize="small" className={classes.success} />
            </span>
          </Tooltip>
        );
      case "PENDING":
      case "SCHEDULED":
        return (
          <Tooltip title="Pending upload to Arweave" arrow>
            <span>
              <TickIcon fontSize="small" className={classes.pending} />
            </span>
          </Tooltip>
        );
      case "REJECTED":
        return (
          <Tooltip title="Upload failed to Arweave" arrow>
            <span>
              <CloseInCircleIcon fontSize="small" color="error" />
            </span>
          </Tooltip>
        );

      default:
        return (
          <Tooltip title="Upload failed to Arweave" arrow>
            <span>
              <CloseInCircleIcon fontSize="small" color="error" />
            </span>
          </Tooltip>
        );
    }
  };

  if (!transactions?.length) return null;

  return (
    <Paper className={classes.paper}>
      <TableContainer>
        <Box mt={4} mb={3} ml={5} display="flex">
          <Typography
            className={classes.title}
            variant="h3"
            id="tableTitle"
            component="div"
          >
            Transactions
          </Typography>
          <Tooltip placement="right-start" title={<ToolTipText />}>
            <span>
              <InfoIcon
                fontSize="small"
                color="secondary"
                style={{ marginLeft: 4 }}
              />
            </span>
          </Tooltip>
        </Box>
        <Table className={classes.table} aria-label="caption table">
          <TableHead>
            <TableRow>
              {rowNames.map((row, index) => (
                <TableCell
                  key={index}
                  className={[classes.cell, classes.cellHead].join(" ")}
                >
                  <TableSortLabel
                    active={orderBy === row.key}
                    direction={orderBy === row.key ? order : "asc"}
                    onClick={createSortHandler(row.key)}
                    hideSortIcon={row.key === ""}
                    IconComponent={ArrowUpIcon}
                  >
                    <Typography
                      variant="caption"
                      className="small"
                      color="text.secondary"
                    >
                      {row.label}
                    </Typography>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(
              prepareDataForTable,
              getComparator(order, orderBy)
            )?.map((transaction, index) => (
              <TableRow
                key={transaction.refId + "_" + index}
                // onClick={event => console.log(event)}
                // className={classes.rowHover}
                classes={{ root: classes.rowHover }}
                hover
              >
                <TableCell
                  className={[classes.cell, classes.cellBody].join(" ")}
                  style={{ minWidth: 100, maxWidth: 200 }}
                >
                  <Typography
                    variant="body2"
                    className="small"
                    noWrap
                    color="text.primary"
                  >
                    {transaction.type === "TOPUP"
                      ? `#${formatRefId(transaction.refId)}`
                      : formatRefId(transaction.refId)}
                  </Typography>

                  <Typography
                    variant="caption"
                    color="text.secondary"
                    noWrap
                    component="p"
                  >
                    {fileDictionary && fileDictionary[transaction.refHash]
                      ? fileDictionary[transaction.refHash].title
                      : stackDictionary && stackDictionary[transaction.stackId]
                      ? stackDictionary[transaction.stackId].title
                      : null}
                    {/* {stackDictionary &&
                      stackDictionary[transaction.stackId]?.title} */}
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <Typography
                    variant="body2"
                    className="small"
                    color="text.primary"
                  >
                    {getTypeIconAndTitle(transaction?.type)}
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <Typography
                    variant="body2"
                    className="small"
                    color="text.primary"
                  >
                    {transaction.amount && bytesToGb(transaction.amount).size}{" "}
                    {transaction.amount &&
                      bytesToGb(transaction.amount).sizeType.toUpperCase()}
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <Typography
                    variant="body2"
                    className="small"
                    color="text.primary"
                  >
                    {formatDate(transaction?.timestamp, false, true)}
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  {/* <Typography variant="body2" className="small"> */}
                  {getStatusIcon(transaction?.status)}
                  {/* </Typography> */}
                </TableCell>
                <TableCell className={classes.cell}>
                  <TransactionIconButton
                    transaction={transaction}
                  ></TransactionIconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
