import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { getDataRoomId, getUserRole, isArchived } from "../helpers/helpers";
import { createMembersMap } from "../helpers/stack-helpers";
import { useGlobalContext } from "./GlobalDataProvider";

const Context = React.createContext();

function VaultContextProvider({ children, history, location }) {
  const currentMembershipRef = React.useRef({});
  const [currentMembership, setCurrentMembership] = useState({});
  const handleCurrentMembership = membership =>
    setCurrentMembership(membership);

  const [members, setMembers] = useState({});
  const handleMembers = members => setMembers(members);

  const [userRole, setUserRole] = useState(null);
  const handleUserRole = userRole => setUserRole(userRole);

  const [encrypter, setEncrypter] = useState({});
  const handleEncrypter = encrypter => setEncrypter(encrypter);

  const [ledgerWrapper, setLedgerWrapper] = useState({});
  const handleLedgerWrapper = ledgerWrapper => setLedgerWrapper(ledgerWrapper);

  // Saving folder for File viewer to know
  // which files to show on the next/previous click
  // and a way back on close
  const [fileFolderId, setFileFolderId] = useState(null);
  const handleFileFolderId = folderId => setFileFolderId(folderId);

  const [currentStack, setCurrentStack] = useState({});
  const handleCurrentStack = stack => setCurrentStack(stack);

  const [cancelledTimelineItemsCount, setCancelledTimelineItemsCount] =
    useState(0);
  const handleCancelledTimelineItemsCount = cancelledTimelineItemsCount =>
    setCancelledTimelineItemsCount(cancelledTimelineItemsCount);

  const dataRoomId = getDataRoomId(location.pathname);

  const newTimelineRef = React.useRef(true);
  const newAssetsRef = React.useRef(true);

  const {
    decryptedMemberships,
    decryptedProfileDetails,
    onLedgerWrapperFromMembership,
    onEncrypterFromMembership
  } = useGlobalContext();

  useEffect(() => {
    const getCurrentDataSpace = async () => {
      if (!decryptedMemberships) return null;
      const currentMembership = decryptedMemberships.filter(
        membership => membership.dataRoomId === dataRoomId
      )[0];

      //if vault was revoked return to home screen
      if (!currentMembership) {
        history.push("/vaults/active");
        return;
      }
      handleCurrentMembership(currentMembership);
      currentMembershipRef.current = currentMembership;
      handleUserRole(getUserRole(currentMembership, decryptedProfileDetails));
      handleMembers(createMembersMap(currentMembership.dataRoom.members.items));

      const encrypter = onEncrypterFromMembership(currentMembership);
      handleEncrypter(encrypter);

      const ledgerWrapper = onLedgerWrapperFromMembership(currentMembership);
      handleLedgerWrapper(ledgerWrapper);
    };

    if (dataRoomId) getCurrentDataSpace();
    return () => {
      newTimelineRef.current = true;
      newAssetsRef.current = true;
      currentMembershipRef.current = {};
      // handleEncrypter({});
      // handleCurrentMembership({});
    };
  }, [decryptedMemberships, dataRoomId, decryptedProfileDetails]);

  useEffect(() => {
    if (currentMembership && currentMembership.dataRoom) {
      handleMembershipData(currentMembership);
    }
  }, [currentMembership]);

  const handleMembershipData = membership => {
    handleUserRole(getUserRole(membership, decryptedProfileDetails));
    handleMembers(createMembersMap(membership.dataRoom.members.items));

    const encrypter = onEncrypterFromMembership(membership);
    handleEncrypter(encrypter);
    const ledgerWrapper = onLedgerWrapperFromMembership(membership);
    handleLedgerWrapper(ledgerWrapper);
  };

  const modelIdByHash = hash => {
    const item = currentMembership?.dataRoom?.timeline?.items?.find(
      item => item.hash == hash
    );
    if (item) {
      return item.modelId;
    }
    return null;
  };

  const isRoomArchived = isArchived(currentMembership);
  const isVaultPublic = currentMembership.dataRoom?.state?.isPublic;
  // if (Object.keys(currentMembership).length === 0) return null;

  return (
    <Context.Provider
      value={{
        onCurrentMembership: handleCurrentMembership,
        currentMembership: currentMembership,
        currentMembershipRef: currentMembershipRef.current,
        onMembers: handleMembers,
        members: members,
        onUserRole: handleUserRole,
        userRole: userRole,
        onEncrypter: handleEncrypter,
        encrypter: encrypter,
        onLedgerWrapper: handleLedgerWrapper,
        ledgerWrapper: ledgerWrapper,
        modelIdByHash: modelIdByHash,
        currentStack: currentStack,
        onCurrentStack: handleCurrentStack,
        onFileFolderId: handleFileFolderId,
        fileFolderId: fileFolderId,
        onCancelledTimelineItemsCount: handleCancelledTimelineItemsCount,
        cancelledTimelineItemsCount: cancelledTimelineItemsCount,
        isRoomArchived: isRoomArchived,
        isVaultPublic: isVaultPublic,
        newTimelineRef: newTimelineRef,
        newAssetsRef: newAssetsRef
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default withRouter(VaultContextProvider);

export const withVaultContext = Component => props =>
  (
    <Context.Consumer>
      {stackContext => <Component {...props} {...stackContext} />}
    </Context.Consumer>
  );

export const useVaultContext = () => useContext(Context);
