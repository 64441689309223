import React, { useState } from 'react'
import { Switch, Route, useRouteMatch, withRouter } from 'react-router-dom'
import SignupSteps from './SignupSteps'
import CreateAccount from './CreateAccount'
import { PageWrapper } from '../../components/common/'
import { stepsData } from './stepsData'
import { useSignupContext } from '../../contexts/SignupContext'

const steps = [
  'signup',
  'account-backup',
  'backup-phrase',
  'verification',
  'account-created',
  'backup-info'
]

function SignUpPage(props) {
  const {
    formData,
    handleError,
    error,
    passEvaluation,
    wallet,
    handleWallet,
    missingWords
  } = useSignupContext()

  const { path } = useRouteMatch()
  const match = useRouteMatch(`${path}/:step`)

  const currentStep = !match ? 'signup' : match.params.step

  const [activateButton, setActivateButton] = useState(true)

  const handleNextProps = {
    steps,
    currentStep,
    path,
    formData,
    props,
    handleError,
    handleWallet,
    wallet,
    missingWords
  }

  return (
    <PageWrapper
      currentStep={currentStep}
      error={error}
      errorText={error?.message}
      darkMode={stepsData[currentStep].darkMode}
      showBottomNav={stepsData[currentStep].showBottomNav}
      nextText={stepsData[currentStep].nextText}
      inverted={stepsData[currentStep].inverted}
      nextDiasabled={
        stepsData[currentStep].isButtonDisabled(formData, passEvaluation) &&
        activateButton
      }
      handleBack={() => stepsData[currentStep].handleBack(props)}
      handleNext={() => stepsData[currentStep].handleNext(handleNextProps)}
      hideBackButton={stepsData[currentStep].hideBackButton}
      fullWidthNextButton={stepsData[currentStep].fullWidthNextButton}
      hideIcon={stepsData[currentStep].hideIcon}
      handleEnd={
        stepsData[currentStep].handleEnd
          ? () => stepsData[currentStep].handleEnd(props)
          : null
      }
      pageTitle="Create account"
      {...props}
    >
      <Switch>
        <Route path="/signup/:step">
          <SignupSteps setActivateButton={setActivateButton} />
        </Route>
        <Route path="/signup">
          <CreateAccount />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default withRouter(SignUpPage)
