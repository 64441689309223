import React from 'react'
import Button from '@mui/material/Button'

const ButtonLink = ({ children, ...rest }) => (
  <Button
    size={rest.size}
    variant={rest.variant ? rest.variant : 'contained'}
    color="primary"
    fullWidth={rest.width === 'xs' || !rest.width ? true : false}
    disableElevation
    type="button"
    style={{ minWidth: 'auto' }}
    disabled={rest.disabled}
    {...rest}
  >
    {children}
  </Button>
)

export default ButtonLink
