import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ChevronDownIcon } from "@akord/addon-icons";

const useStyles = makeStyles(theme => ({
  selectMenu: {
    height: "1.1876em",
    fontSize: "16px",
    color: theme.palette.text.secondary,
    minWidth: "8px"
  },
  menuPaper: {
    marginTop: theme.spacing(1),
    borderRadius: "6px"
  },
  menuList: {
    backgroundColor: theme.palette.background.secondary
  },
  inputRoot: {
    color: theme.palette.text.primary,
    "&:focus": {
      backgroundColor: "transparent"
    }
  },
  inputIcon: {
    color: theme.palette.text.disabled,
    fontSize: "20px",
    zIndex: 100
  },
  iconOpen: {
    color: `${theme.palette.text.primary}!important`
  },
  select: {
    "&:before": {
      borderBottom: "none"
    },
    "&:after": {
      borderBottom: "none"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none!important"
    },
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(3)
  },
  menuItem: {
    paddingRight: theme.spacing(4),
    "&:hover": {
      backgroundColor: theme.palette.background.cardBorder
    },
    "&.Mui-selected": {
      color: theme.palette.text.primary
    },
    "&.Mui-sdisabled": {
      opacity: 1
    },
    color: theme.palette.text.primary
  },
  selected: {
    background: "transparent!important"
  }
}));

function PaginationSelect({ pages, currentPage, onChangePage }) {
  const classes = useStyles();

  return (
    <FormControl>
      <Select
        labelId="page-select-label"
        id="page-select"
        value={currentPage}
        IconComponent={ChevronDownIcon}
        onChange={onChangePage}
        variant="standard"
        className={classes.select}
        classes={{
          selectMenu: classes.selectMenu,
          iconOpen: classes.iconOpen
        }}
        MenuProps={{
          elevation: 4,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right"
          },
          classes: {
            list: classes.menuList,
            paper: classes.menuPaper
          }
        }}
        inputProps={{
          classes: {
            root: classes.inputRoot,
            icon: classes.inputIcon
          }
        }}
      >
        {new Array(pages).fill("").map((_, index) => (
          <MenuItem
            key={index}
            value={index + 1}
            classes={{ selected: classes.selected }}
            className={classes.menuItem}
            disabled={index + 1 === currentPage}
          >
            {index + 1}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default PaginationSelect;
