import React from 'react'
import { Auth } from 'aws-amplify'
import { Box } from '@mui/material'
import { AkordWallet } from '@akord/crypto'
import SecurityPassword from './SecurityPassword'
import SecurityPhrase from './SecurityPhrase'
import { withRouter } from 'react-router'
import { useGlobalContext } from '../../contexts/GlobalDataProvider'
import {
  SupportScreenWrapper,
  ButtonGroup,
  FormSubmit
} from '../../components/common/'

function SecurityBackup({ history }) {
  const { isMobile } = useGlobalContext()
  const [screen, setScreen] = React.useState('password')
  const [backupPhrase, setBackupPhrase] = React.useState()
  const [userEmail, setUserEmail] = React.useState()
  const [formData, setFormData] = React.useState({
    userPassword: ''
  })
  const [error, setError] = React.useState()

  const isDisabled = () => !(formData.userPassword.length >= 8)

  const getErrorText = () => {
    if (error && error.message)
      return 'Your login details are not recognised. Please check your password.'
  }

  const getBackupPhrase = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const encBackupPhrase = user.attributes['custom:encBackupPhrase']
      const importWallet = await AkordWallet.importFromEncBackupPhrase(
        formData.userPassword,
        encBackupPhrase
      )
      setUserEmail(user.attributes['email'])
      setBackupPhrase(importWallet.backupPhrase)
      setScreen('reveal')
    } catch (err) {
      setError(err)
    }
  }

  const redirectToSecurity = () => {
    history.push('/settings/security')
  }

  return (
    <SupportScreenWrapper
      title="Reveal backup phrase"
      route="/settings/security"
    >
      <FormSubmit
        onSubmit={screen === 'password' ? getBackupPhrase : redirectToSecurity}
      >
        <Box mt={5}>
          {screen === 'password' ? (
            <SecurityPassword setFormData={setFormData} formData={formData} />
          ) : (
            <SecurityPhrase
              backupPhrase={backupPhrase.split(' ')}
              userEmail={userEmail}
            />
          )}
        </Box>
        {screen === 'password' && (
          <ButtonGroup
            type="submit"
            noMinWidth={true}
            nextText="Reveal backup phrase"
            nextDiasabled={isDisabled()}
            hideBackButton={true}
            fullWidthNextButton={isMobile ? true : false}
            hideIcon={true}
            errorText={getErrorText()}
            disabledRed={false}
          />
        )}
      </FormSubmit>
    </SupportScreenWrapper>
  )
}

export default withRouter(SecurityBackup)
