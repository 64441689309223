import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Divider, Typography, IconButton } from "@mui/material";
import { withRouter } from "react-router";
import { ChevronRightIcon } from "@akord/addon-icons";
import { SupportScreenWrapper } from "../../components/common/";

const useStyles = makeStyles(theme => ({
  hoverOver: {
    cursor: "pointer",
    "&:hover": {
      "& $icon": {
        color: theme.palette.text.primary
      }
    }
  },
  icon: {
    transition: ".3s ease"
  }
}));

function Security({ history }) {
  const classes = useStyles();
  const securityOptions = [
    {
      title: "Reveal backup phrase",
      onClick: () => history.push("/settings/security/backup-phrase")
    },
    {
      title: "Change password",
      onClick: () => history.push("/settings/security/change-password")
    }
  ];
  return (
    <SupportScreenWrapper title="Security" route="/settings">
      <Box mb={7}>
        {securityOptions.map((option, index) => (
          <div key={index}>
            <Box
              my={4}
              display="flex"
              justifyContent="space-between"
              onClick={option.onClick}
              className={classes.hoverOver}
            >
              <Typography variant="body2" color="text.primary">
                {option.title}
              </Typography>
              <IconButton
                style={{ padding: 0 }}
                onClick={option.onClick}
                size="large"
              >
                <ChevronRightIcon className={classes.icon} />
              </IconButton>
            </Box>
            <Divider />
          </div>
        ))}
      </Box>
    </SupportScreenWrapper>
  );
}

export default withRouter(Security);
