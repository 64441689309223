import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, FormControl, OutlinedInput, InputAdornment } from "@mui/material";
import SelectMenu from "../../components/common/SelectMenu";
import ErrorAlert from "../../components/common/ErrorAlert";
import { CloseInCircleIcon } from "@akord/addon-icons";

const useStyles = makeStyles(theme => ({
  formEmail: {
    marginRight: theme.spacing(3),
    flexGrow: 1
  },
  formMessage: {
    border: "none"
  },
  input: {
    fontSize: "1rem"
  },
  adornedEnd: {
    paddingRight: 0
  }
}));

function InviteEmailForm(props) {
  const {
    inviteForm,
    handleFormChange,
    handleEmailClear,
    index,
    invalidUsers,
    alreadyInvited,
    isMobile,
    noSelect
  } = props;
  const [emailFilled, setEmailFilled] = React.useState(false);
  const classes = useStyles({
    emailFilled: emailFilled
  });

  React.useEffect(() => {
    inviteForm[index].email ? setEmailFilled(true) : setEmailFilled(false);
  }, [inviteForm, index]);

  return (
    <Box mb={3} display="flex">
      <FormControl variant="outlined" className={classes.formEmail}>
        <OutlinedInput
          // required
          error={
            (invalidUsers?.length > 0 &&
              invalidUsers?.filter(user => user.id === inviteForm[index].id)
                .length > 0) ||
            (alreadyInvited?.length > 0 &&
              alreadyInvited?.filter(user => user.id === inviteForm[index].id)
                .length > 0)
          }
          id={`enter-email-${index}`}
          name="email"
          type="email"
          placeholder="Email"
          value={inviteForm[index].email}
          classes={{
            input: classes.input,
            adornedEnd: classes.adornedEnd,
            root: classes.root
          }}
          onChange={e => handleFormChange(e, index)}
          endAdornment={
            inviteForm[index].email && (
              <InputAdornment position="end" style={{ marginRight: 0 }}>
                <CloseInCircleIcon
                  color="disabled"
                  onClick={() => handleEmailClear(index, inviteForm[index])}
                  style={{
                    cursor: "pointer",
                    marginRight: isMobile ? "8px" : "12px"
                  }}
                />
              </InputAdornment>
            )
          }
        />
        {invalidUsers?.length > 0 &&
          invalidUsers?.filter(user => user.id === inviteForm[index].id)
            .length > 0 && (
            <ErrorAlert getErrorText="Only people with an Akord account can be invited." />
          )}
        {alreadyInvited?.length > 0 &&
          alreadyInvited?.filter(user => user.id === inviteForm[index].id)
            .length > 0 && (
            <ErrorAlert getErrorText="This person has already been invited." />
          )}
      </FormControl>
      {!noSelect && (
        <SelectMenu
          member={inviteForm[index]}
          handleFormChange={handleFormChange}
          index={index}
          outlined={true}
          emailFilled={emailFilled}
          fromInvite={true}
          {...props}
        />
      )}
    </Box>
  );
}

export default InviteEmailForm;
