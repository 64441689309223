import React, { useEffect } from 'react'
import { EmptySpace } from '../../components/common/'
import ShowRooms from './ShowRooms'
import { useGlobalContext } from '../../contexts/GlobalDataProvider'
import { withRouter } from 'react-router'
import { useSnackbarContext } from '../../contexts/SnackbarContextProvider'

function Rooms({ location }) {
  const { onSnackbarToShow } = useSnackbarContext()
  const { isLoaded, decryptedMemberships, invitedMemberships } =
    useGlobalContext()

  useEffect(() => {
    if (location.state?.changePassword) onSnackbarToShow('passwordChange')
  }, [location])

  return !isLoaded ||
    decryptedMemberships?.length > 0 ||
    invitedMemberships?.length > 0 ? (
    <ShowRooms />
  ) : (
    <EmptySpace dataText="newRoomOnBoard" />
  )
}

export default withRouter(Rooms)
