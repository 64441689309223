import {
  Box,
  DialogContent,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import {
  getUploadFileIcon,
  getTotalUploadSize
} from "../../../helpers/helpers";
import { InfoIcon, MinusInCircleIcon, NoteIcon } from "@akord/addon-icons";
import { bytesToGb } from "../../../pages/storage/storage-helper";
import { useStorageContext } from "../../../contexts/StorageContextProvider";
import { confirmationModalOptions } from "./confirmation-modal-options";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { FILE_SIZE_LIMIT_UPLOAD } from "../../../helpers/akord-enums";

function UploadModalContent({
  handleUploadedAndConfirmedFiles,
  uploadedAndConfirmedFiles,
  isSizeExceedLimit
}) {
  const { uploadedFiles, notificationData, modal } = useNotificationsContext();
  const { globalStorage } = useStorageContext();
  const { isVaultPublic } = useVaultContext();

  useEffect(() => {
    if (uploadedFiles.length > 0)
      handleUploadedAndConfirmedFiles(uploadedFiles);
  }, [uploadedFiles]);

  if (
    (!uploadedAndConfirmedFiles && notificationData.fileType !== "note") ||
    !globalStorage
  )
    return null;

  const filesArray =
    uploadedAndConfirmedFiles && Array.from(uploadedAndConfirmedFiles);

  const handleRemoveFile = index => {
    const uploadedFilesCopy = [...uploadedAndConfirmedFiles];
    uploadedFilesCopy.splice(index, 1);
    handleUploadedAndConfirmedFiles(uploadedFilesCopy);
  };

  const noteSize = new Blob([JSON.stringify(notificationData?.noteText)]).size;

  const totalUploadingSize =
    notificationData?.fileType === "note"
      ? bytesToGb(noteSize).size
      : getTotalUploadSize(uploadedAndConfirmedFiles);

  return (
    <>
      <Box mb={4}>
        <Typography variant="body2" className="small">
          {confirmationModalOptions[modal.confirmModalType].createText(
            isVaultPublic
          )}
        </Typography>
      </Box>
      <DialogContent style={{ marginTop: 8 }}>
        <Divider />
        <TableContainer>
          <Table stickyHeader style={{ tableLayout: "fixed" }}>
            <TableBody>
              {notificationData?.fileType === "note" ? (
                <TableRow hover>
                  <TableCell padding="none">
                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center"
                        }}
                      >
                        <NoteIcon color="secondary" />
                        <Box
                          style={{
                            width: "100%",
                            marginLeft: 4,
                            display: "flex",
                            justifyContent: "space-between"
                          }}
                        >
                          <Typography
                            variant="body2"
                            className="small"
                            noWrap
                            component="p"
                          >
                            {notificationData.title}
                          </Typography>
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            component="p"
                          >
                            {bytesToGb(noteSize).size}{" "}
                            {bytesToGb(noteSize).sizeType.toUpperCase()}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                filesArray?.map((file, index) => {
                  const FileIcon = getUploadFileIcon(file);
                  return (
                    <TableRow hover key={index}>
                      <TableCell padding="none">
                        <Box
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              width: "90%",
                              alignItems: "center"
                            }}
                          >
                            <FileIcon
                              color={
                                file.size > FILE_SIZE_LIMIT_UPLOAD
                                  ? "error"
                                  : "secondary"
                              }
                            />
                            <Box
                              style={{
                                width: "90%",
                                marginLeft: 4
                              }}
                            >
                              <Typography
                                variant="body2"
                                className="small"
                                noWrap
                                component="p"
                              >
                                {file.name}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="text.secondary"
                                component="p"
                              >
                                {bytesToGb(file.size).size}{" "}
                                {bytesToGb(file.size).sizeType.toUpperCase()}
                              </Typography>
                            </Box>
                          </Box>
                          <Box style={{ marginRight: 4 }}>
                            <IconButton
                              onClick={() => handleRemoveFile(index)}
                              size="large"
                            >
                              <MinusInCircleIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <Box mb={6} mt={4} textAlign="right">
        <Typography
          variant="body2"
          className="small strong"
          color="text.primary"
        >
          {filesArray?.length > 0 &&
            "Total upload: " +
              bytesToGb(getTotalUploadSize(filesArray)).size +
              " " +
              bytesToGb(getTotalUploadSize(filesArray)).sizeType.toUpperCase()}
        </Typography>
        {isSizeExceedLimit && (
          <Typography variant="body2" className="small" color="error">
            <InfoIcon fontSize="small" style={{ marginRight: 4 }} />
            We cannot accept file uploads larger than{" "}
            {bytesToGb(FILE_SIZE_LIMIT_UPLOAD).size}{" "}
            {bytesToGb(FILE_SIZE_LIMIT_UPLOAD).sizeType.toUpperCase()}
          </Typography>
        )}
        {globalStorage.storage_available < totalUploadingSize ? (
          <Typography variant="body2" className="small" color="error">
            <InfoIcon fontSize="small" style={{ marginRight: 4 }} />
            Not enough available space:{" "}
            {bytesToGb(globalStorage?.storage_available).size}{" "}
            {bytesToGb(globalStorage?.storage_available).sizeType.toUpperCase()}
          </Typography>
        ) : (
          <Typography variant="body2" className="small">
            Available: {bytesToGb(globalStorage?.storage_available).size}{" "}
            {bytesToGb(globalStorage?.storage_available).sizeType.toUpperCase()}
          </Typography>
        )}
      </Box>
    </>
  );
}

export default UploadModalContent;
