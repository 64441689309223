import React from "react";
import { Typography } from "@mui/material";
import OperationTitleWithIcon from "../../../components/common/OperationTitle/OperationTitleWithIcon";

function AssetDeleted(props) {
  const { positionedBeforeDate, operation, color } = props;

  return (
    <>
      <OperationTitleWithIcon actionRef={operation.actionRef} color={color} />
      <div
        style={{
          marginBottom: positionedBeforeDate ? "74px" : "16px",
          display: "flex"
        }}
      >
        <Typography
          variant="body2"
          color="textSecondary"
          className="small"
          noWrap
          style={{ whiteSpace: "pre" }}
        >
          {operation?.stack ? operation?.stack?.title : operation?.note?.title}
        </Typography>
      </div>
    </>
  );
}

export default AssetDeleted;
