import React, { useState, createContext, useContext } from "react";
const Context = createContext();

function SnackbarContextProvider({ children }) {
  const [snackbarToShow, setSnackbarToShow] = useState({
    open: false,
    type: null,
    dynamicData: null,
    severity: null
  });

  const handleSnackbarToShow = (
    type = null,
    dynamicData = null,
    severity = null
  ) =>
    setSnackbarToShow({
      open: true,
      type: type,
      dynamicData: dynamicData,
      severity: severity
    });

  const handleSnackbarClose = () =>
    setSnackbarToShow({ ...snackbarToShow, open: false });

  return (
    <Context.Provider
      value={{
        snackbarToShow: snackbarToShow,
        onSnackbarToShow: handleSnackbarToShow,
        onSnackbarClose: handleSnackbarClose
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default SnackbarContextProvider;

export const withSnackbarContext = Component => props =>
  (
    <Context.Consumer>
      {snackbarContext => <Component {...props} {...snackbarContext} />}
    </Context.Consumer>
  );

export const useSnackbarContext = () => useContext(Context);
