import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, FormControl, Box } from "@mui/material";
import { AccountBackupIcon } from "@akord/addon-icons";
import PasswordMeter from "../../components/common/PasswordMeter";
import { useRecoverAccountContext } from "../../contexts/RecoverAccountContext";

const useStyles = makeStyles({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    color: "#FFFFFF"
  },
  rootWithIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }
});

function RecoverNewPassword() {
  const classes = useStyles();
  const { handleFormChange, formData, passEvaluation } =
    useRecoverAccountContext();

  const [values, setValues] = useState({
    showPassword: true
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <>
      <Box mb={3} className={classes.rootWithIcon}>
        <Typography variant="h1">Reset password</Typography>
        <AccountBackupIcon fontSize="large" color="primary" />
      </Box>

      <Box>
        <Box mb={5}>
          <Typography variant="body2">
            Your account has been recovered and you can now reset your password.
          </Typography>
        </Box>
        <FormControl fullWidth>
          <PasswordMeter
            values={values}
            formData={formData}
            handleClickShowPassword={handleClickShowPassword}
            handleMouseDownPassword={handleMouseDownPassword}
            handleFormChange={handleFormChange}
            passEvaluation={passEvaluation}
            newPasswordText
            darkMode={true}
            padding={false}
          />
        </FormControl>
      </Box>
    </>
  );
}

export default RecoverNewPassword;
