import React from "react";
import { useHistory } from "react-router-dom";
import { IconButton } from "@mui/material";
import { SearchIcon } from "@akord/addon-icons";

function SearchTrigger() {
  const history = useHistory();

  const triggerSearchHandler = () => () => {
    history.push({
      pathname: "/search"
    });
  };

  return (
    <IconButton
      aria-label="search-trigger"
      onClick={triggerSearchHandler()}
      size="large"
    >
      <SearchIcon />
    </IconButton>
  );
}

export default SearchTrigger;
