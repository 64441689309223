import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Tooltip,
  CircularProgress
} from "@mui/material";
import FileViewerSelect from "./FileViewerSelect";
import {
  DownloadIcon,
  VaultIcon,
  PrinterIcon,
  CloseIcon,
  OpenIcon
} from "@akord/addon-icons";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { handlePrint, downloadSelectedFile } from "./file-viewer-helper";
import { useFileViewerContext } from "../../../contexts/FileViewerContextProvider";
import { useHistory } from "react-router-dom";
import {
  getDataRoomId,
  getDataRoomStatus,
  getFileIcon,
  getNoteId,
  getStackId
} from "../../../helpers/helpers";
import { Skeleton } from "@mui/material";
import { arweaveURL } from "../../../pages/storage/storage-helper";
import { ButtonLink } from "..";
import queryString from "query-string";
import ThemeWrapper from "../Wrappers/ThemeWrapper";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
    backgroundColor: "transparent",
    marginBottom: theme.spacing(2)
  },
  title: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  highlightedIconArrow: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.dark,
    opacity: "0.8",
    "&:hover": {
      backgroundColor: theme.palette.background.dark
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogFileViewerWrapper({
  children,
  isNote = false,
  showDropDown = true,
  buttonLoading,
  onSaveClick,
  buttonDisable,
  onViewerData,
  onlyDarkMode = true,
  overflowYAuto = false
}) {
  const history = useHistory();
  const roomStatus = getDataRoomStatus(history.location.pathname);
  const roomId = getDataRoomId(history.location.pathname);

  const { currentMembership, encrypter, fileFolderId } =
    useVaultContext() || {};

  const {
    onUploadStart,
    onDrawerActionType,
    decryptedNotes = [],
    decryptedStacks = []
  } = useAssetsContext() || {};

  const { fileViewerData, activeStack, onActiveStack, onOpenFileList } =
    useFileViewerContext() || {};

  const { isMobile } = useGlobalContext() || {};
  const classes = useStyles({ isMobile: isMobile });

  const FileIcon = getFileIcon(activeStack);

  const transactionId = activeStack?.storageTransactions?.items.filter(
    transaction => transaction.refHash === fileViewerData.hash
  )[0]?.refId;
  const searchParams = queryString.parse(history.location.search);
  const resourceVersion = searchParams.resourceVersion || null;
  const noteId = getNoteId(history.location.pathname);
  const stackId = getStackId(history.location.pathname);

  // To abstract out pick what are we showing
  const decryptedItems = isNote
    ? decryptedNotes
    : [...decryptedStacks, ...decryptedNotes] || [];
  const currentItemId = isNote ? noteId : stackId;
  const currentItemToView =
    decryptedItems?.filter(item => item.id === currentItemId)[0] || {};

  const getFileSetCurrent = currentItem => {
    const currentItemCopy = { ...currentItem };
    const itemCopy = isNote
      ? [...currentItemCopy.revisions]
      : currentItemCopy.files
      ? [...currentItemCopy.files]
      : [...currentItemCopy.revisions];

    const itemsUpdated = itemCopy.map((item, index) => {
      return {
        ...item,
        originalIndex: index
      };
    });
    // reverse the revision files order in a stack
    // so we can show the latest one as a first one in a viewer select

    const reversedItems = itemsUpdated.reverse();
    //consolidating notes and files by addina field `items` instead of `revisions` and `files`
    currentItemCopy.items = reversedItems;

    // need to check if needed (removed during public vault work)
    // isNote || currentItem.type === "note"
    //   ? delete currentItemCopy.revisions
    //   : delete currentItemCopy.files;

    onViewerData(
      resourceVersion
        ? currentItemCopy.items[currentItemCopy.items.length - resourceVersion]
        : currentItemCopy.items[0]
    );

    //add ability to pick the right version
    if (resourceVersion) {
      const selectedItemObj = currentItemCopy.items.splice(
        currentItemCopy.items.length - resourceVersion,
        1
      )[0];
      //sort files by original index
      const sortedFiles = currentItemCopy.items.sort((a, b) => {
        if (a.originalIndex > b.originalIndex) return -1;
        else return 1;
      });
      sortedFiles.splice(0, 0, selectedItemObj);
      onActiveStack(currentItemCopy);
    } else onActiveStack(currentItemCopy);
  };

  React.useEffect(() => {
    if (Object.keys(currentItemToView).length !== 0) {
      getFileSetCurrent(currentItemToView);
    }
    return () => {
      if (activeStack?.length) onActiveStack([]);
    };
  }, [currentItemToView]);

  const handleSelectFile = index => {
    onOpenFileList(prevState => (prevState ? false : prevState));
    onViewerData(activeStack.items[index]);
    const copyactiveStack = { ...activeStack };
    const selectedFileObj = copyactiveStack.items.splice(index, 1)[0];
    // //sort files by original index
    const sortedFiles = copyactiveStack.items.sort((a, b) => {
      if (a.originalIndex > b.originalIndex) return -1;
      else return 1;
    });
    sortedFiles.splice(0, 0, selectedFileObj);
    onActiveStack(copyactiveStack);
    //keep the url up-to-date with the selected file
    selectedFileObj.originalIndex !== activeStack.items.length - 1
      ? history.push(
          `/vaults/${roomStatus}/${roomId}/assets/${
            isNote ? "note" : "stack"
          }/${activeStack.id}/?resourceVersion=${
            selectedFileObj.originalIndex + 1
          }`
        )
      : history.push(
          `/vaults/${roomStatus}/${roomId}/assets/${
            isNote ? "note" : "stack"
          }/${activeStack.id}`
        );
  };
  const isVersoned = currentItemToView?.revisions
    ? currentItemToView?.revisions?.length > 1
    : currentItemToView?.files?.length > 1;

  return (
    <ThemeWrapper darkMode={onlyDarkMode}>
      <Dialog
        fullScreen
        open
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            overflowY: overflowYAuto ? "auto" : "hidden"
          }
        }}
        style={{ zIndex: 1200 }}
      >
        <AppBar className={classes.appBar} elevation={0} position="static">
          <Toolbar
            style={{
              justifyContent: "space-between",
              minHeight: "auto",
              margin: "16px 20px",
              padding: 0
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              marginRight="auto"
              maxWidth={isMobile ? "44%" : "100%"}
            >
              <VaultIcon color="disabled" />
              <Typography variant="h3" className={classes.title} noWrap>
                {!currentMembership?.dataRoom?.state?.title ? (
                  <Skeleton
                    width="120px"
                    variant="text"
                    style={{ backgroundColor: "#53515C" }}
                  />
                ) : (
                  currentMembership?.dataRoom?.state?.title
                )}
              </Typography>
            </Box>
            {!isMobile && showDropDown && isVersoned && (
              <Box position="absolute" display="contents">
                <FileViewerSelect
                  FileIcon={FileIcon}
                  handleSelectFile={handleSelectFile}
                  onlyDarkMode={onlyDarkMode}
                />
              </Box>
            )}
            <Box display="flex">
              {isNote ? (
                <Box mr={3}>
                  <ButtonLink
                    disabled={buttonDisable}
                    onClick={onSaveClick}
                    size="small"
                  >
                    {showDropDown ? "Save new version" : "Save note"}
                    {buttonLoading && <CircularProgress size={24} />}
                  </ButtonLink>
                </Box>
              ) : (
                <>
                  {transactionId && (
                    <Tooltip title="View Transaction" arrow>
                      <IconButton
                        onClick={() =>
                          window.open(`${arweaveURL}${transactionId}`)
                        }
                        aria-label="transaction link"
                        style={{ marginRight: "16px" }}
                        size="large"
                      >
                        <OpenIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Print" arrow>
                    <IconButton
                      onClick={() => handlePrint(fileViewerData)}
                      aria-label="print"
                      style={{ marginRight: "16px" }}
                      size="large"
                    >
                      <PrinterIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Download" arrow>
                    <IconButton
                      onClick={() =>
                        downloadSelectedFile(
                          activeStack,
                          encrypter,
                          fileViewerData,
                          onUploadStart,
                          onDrawerActionType
                        )
                      }
                      aria-label="download"
                      style={{ marginRight: "32px" }}
                      size="large"
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              <IconButton
                onClick={() =>
                  history.push(
                    fileFolderId
                      ? `/vaults/${roomStatus}/${roomId}/assets/folders/${fileFolderId}`
                      : `/vaults/${roomStatus}/${roomId}/assets`
                  )
                }
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Toolbar>
          {isMobile && showDropDown && isVersoned && (
            <Toolbar style={{ minHeight: "40px", marginBottom: "12px" }}>
              <FileViewerSelect
                FileIcon={FileIcon}
                handleSelectFile={handleSelectFile}
              />
            </Toolbar>
          )}
        </AppBar>
        {children}
      </Dialog>
    </ThemeWrapper>
  );
}

export default DialogFileViewerWrapper;
