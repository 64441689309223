import React, { useState, useContext, useEffect } from 'react'
import { Auth, Hub } from 'aws-amplify'
import { AkordWallet } from '@akord/crypto'
import { withRouter } from 'react-router'
import { useSnackbarContext } from './SnackbarContextProvider'
import { cookieStorage } from '../helpers/cookie-storage'
const LoginContext = React.createContext()

function LoginContextProvider({ location, history, children }) {
  const { onSnackbarToShow } = useSnackbarContext()
  const [formData, setFormData] = useState({
    userEmail: '',
    userPassword: '',
    keepSignedIn: false
  })
  const handleLoginFormChange = () => event => {
    const { target } = event
    const value = target.type === 'checkbox' ? target.checked : target.value
    setFormData({ ...formData, [target.name]: value })
  }

  // const [userVerified, setUserVerified] = useState(false)
  // const handleUserVerified = value => setUserVerified(value)

  const [error, setError] = useState()
  const handleError = error => setError(error)

  const [textClicked, setTextClicked] = useState(false)
  const handleTextClicked = value => setTextClicked(value)

  const [loading, setLoading] = useState(false)
  const handleLoading = value => setLoading(value)

  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  useEffect(() => {
    setUsernameFromCookie()
  }, [location.search])

  useEffect(() => {
    handleError()
  }, [formData])

  // verify email address
  useEffect(() => {
    const { host, protocol } = window.location
    const urlParams = new URLSearchParams(location.search)
    const encoded = urlParams.get('data')
    const code = urlParams.get('code')
    async function verifyEmail() {
      try {
        const decoded = JSON.parse(atob(encoded))
        const { userName } = decoded
        const baseUrl = `${protocol}//${host}`
        await Auth.confirmSignUp(userName, code, {
          clientMetadata: { baseUrl }
        })
        // handleUserVerified(true)
        onSnackbarToShow('userVerified')
      } catch (err) {
        console.log(err)
        if (err.message.endsWith('Current status is CONFIRMED')) {
          // if already confirmed, ignore error and show message again
          // handleUserVerified(true)
          onSnackbarToShow('userVerified')
        } else {
          handleError(err)
        }
      }
    }

    // if (userVerified) {
    //   return
    // }

    if (encoded && code) {
      verifyEmail()
    }
  }, [location.search])

  const getErrorText = () => {
    if (error?.code === 'UserNotConfirmedException')
      return (
        <div>
          Your email is not yet verified.{' '}
          <a onClick={resendEmail}>
            {textClicked ? 'Email sent!' : 'Resend email.'}
          </a>
        </div>
      )
    else if (error?.code === 'CodeMismatchException')
      return (
        <div>
          Your verification link is not the latest one sent.{' '}
          <a onClick={resendEmail}>
            {textClicked ? 'Email sent!' : 'Resend email.'}
          </a>
        </div>
      )
    else if (error?.message?.endsWith('Current status is CONFIRMED')) return
    else if (error?.code === 'LimitExceededException') return error.message
    else if (error?.message)
      return 'Your login details are not recognised. Please check your email and password.'
    else return
  }

  const resendEmail = async () => {
    const { host, protocol } = window.location
    const verifyUrl = `${protocol}//${host}/sign-in`
    const username = usernameFromUrl() || usernameFromForm()

    handleTextClicked(true)
    setTimeout(() => {
      handleTextClicked(false)
    }, 1500)

    await Auth.resendSignUp(username, { verifyUrl })
  }

  const usernameFromForm = () => {
    return formData.userEmail
  }

  const usernameFromUrl = () => {
    const urlParams = new URLSearchParams(location.search)
    if (urlParams.has('data')) {
      try {
        const encoded = urlParams.get('data')
        const decoded = JSON.parse(Buffer.from(encoded, 'base64'))
        const { userName } = decoded
        return userName
      } catch (err) {
        console.log('ERROR: ', err)
      }
    }
    return null
  }

  const setUsernameFromCookie = () => {
    const urlParams = new URLSearchParams(location.search)
    if (urlParams.has('uid')) {
      try {
        const userId = urlParams.get('uid')
        const email = cookieStorage.getSignInEmail(userId)
        if (email) {
          setFormData({ ...formData, userEmail: email })
        }
        
      } catch (err) {
        console.log('ERROR: ', err)
      }
    }
  }


  const handleSignIn = async () => {
    try {
      handleLoading(true)
      const userEmail = formData.userEmail.toLowerCase().trim()
      const password = formData.userPassword

      Hub.dispatch('akord:auth', { event: 'preSignIn', data: formData })
      const user = await Auth.signIn(userEmail, password)

      const encBackupPhrase = user.attributes['custom:encBackupPhrase']
      await AkordWallet.importFromEncBackupPhrase(password, encBackupPhrase)
      handleLoading(false)
      if (
        location &&
        location.state &&
        location.state.from &&
        location.state.from.pathname !== '/sign-in'
      ) {
        history.push(location.state.from.pathname + location.state.from.search)
      } else history.push('/vaults/active')
    } catch (err) {
      console.log(err)
      handleLoading(false)
      handleError(err)
    }
  }

  return (
    <LoginContext.Provider
      value={{
        formData: formData,
        onLoginFormChange: handleLoginFormChange,
        error: error,
        handleError: handleError,
        handleTextClicked: handleTextClicked,
        textClicked: textClicked,
        resendEmail: resendEmail,
        getErrorText: getErrorText,
        onSignIn: handleSignIn,
        loading: loading,
        handleClickShowPassword: handleClickShowPassword,
        showPassword: showPassword
        // userVerified: userVerified
      }}
    >
      {children}
    </LoginContext.Provider>
  )
}
export default withRouter(LoginContextProvider)

export const useLoginContext = () => useContext(LoginContext)
