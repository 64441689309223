import React from "react";
import { Typography } from "@mui/material";
import OperationTitleWithIcon from "../../../components/common/OperationTitle/OperationTitleWithIcon";

function FolderRevoked(props) {
  const { positionedBeforeDate, operation, color } = props;

  return (
    <>
      <OperationTitleWithIcon
        actionRef={operation.actionRef}
        color={color}
        title="Folder revoked"
      />
      <div
        style={{
          marginBottom: positionedBeforeDate ? "74px" : "16px",
          display: "flex"
        }}
      >
        <Typography
          variant="body2"
          className="small"
          color="text.secondary"
          noWrap
          style={{ whiteSpace: "pre" }}
        >
          {operation?.folder
            ? operation.folder.title
            : operation.group[0].folder.title}
        </Typography>
      </div>
    </>
  );
}

export default FolderRevoked;
