export class CookieStorage {

    constructor(defaultDaysToExpire) {
        this._defaultDaysToExpire = defaultDaysToExpire || 90
    }

    storeCognitoUser(userId, email) {
        document.cookie = userId + "=" + email + "; " + this._expires() + "; path=/";
    }

    getSignInEmail(username) {
        return this._getCookie(username);
    }

    _getCookie(cName) {
        const name = cName + "=";
        const cDecoded = decodeURIComponent(document.cookie)
        const cArr = cDecoded.split('; ')
        let res
        cArr.forEach(val => {
            if (val.indexOf(name) === 0) res = val.substring(name.length)
        })
        return res
    }

    _expires(daysToExpire) {
        const days = daysToExpire || this._defaultDaysToExpire
        const expirationDate = new Date()
        expirationDate.setTime(expirationDate.getTime() + days * 24 * 60 * 60 * 1000);
        return "expires=" + expirationDate.toUTCString();
    }
}

export const cookieStorage = new CookieStorage(null);
