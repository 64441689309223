import {
  AddMemberIcon,
  ArchiveIcon,
  BonusIcon,
  CloseInCircleIcon,
  TrashIcon,
  FolderOutlineIcon,
  InvitationAcceptIcon,
  KeyIcon,
  MoveIcon,
  NoteIcon,
  PadlockIcon,
  PaperPlaneIcon,
  PenIcon,
  RestoreIcon,
  RevokedFileIcon,
  StackIcon,
  StorageIcon,
  UploadIcon,
  VaultIcon,
  VaultLeaveIcon
} from "@akord/addon-icons";

export const getDataForAction = log => {
  switch (log.transactions?.items[0]?.actionRef) {
    case "STACK_CREATE":
      return {
        action: " uploaded ",
        details:
          log.transactions?.items?.length > 1
            ? `${log.transactions?.items?.length} files in `
            : `"${log.transactions.items[0].title}" in `
      };
    case "STACK_MOVE":
      return {
        action: " moved file ",
        details: `"${log.transactions.items[0].title}" in`
      };
    case "STACK_RENAME":
      return {
        action: " renamed ",
        details: `"${log.transactions.items[0].prevTitle}" to "${log.transactions.items[0].title}" in `
      };
    case "STACK_UPLOAD_REVISION":
      return {
        action: " added a new version ",
        details: `to "${log.transactions.items[0].title}" in `
      };
    case "STACK_REVOKE":
      return {
        action: " revoked ",
        details: `"${log.transactions.items[0].title}" from `
      };
    case "STACK_RESTORE":
      return {
        action: " restored ",
        details: `"${log.transactions.items[0].title}" in `
      };
    case "NOTE_CREATE":
      return {
        action: " created a new note ",
        details: `"${log.transactions.items[0].note.title}" in `
      };
    case "NOTE_CREATE_REVISION":
      return {
        action: " added a new version ",
        details: `to "${log.transactions.items[0].note.title}" in `
      };
    case "NOTE_REVOKE":
      return {
        action: " revoked ",
        details: `"${log.transactions.items[0].note.title}" from `
      };
    case "NOTE_REMOVE":
      return {
        action: " removed ",
        details: `"${log.transactions.items[0].note.title}" from `
      };
    case "NOTE_RESTORE":
      return {
        action: " restored ",
        details: `"${log.transactions.items[0].note.title}" from `
      };
    case "NOTE_MOVE":
      return {
        action:
          log.transactions?.items?.length > 1
            ? ` moved ${log.transactions?.items?.length} notes`
            : " moved a note ",
        details:
          log.transactions?.items?.length > 1
            ? " from "
            : `"${log.transactions.items[0].note.title}" from `
      };
    case "FOLDER_CREATE":
      return {
        action: " created folder ",
        details: ` "${log.transactions.items[0].title}" in `
      };
    case "FOLDER_RENAME":
      return {
        action: " renamed the folder ",
        details: `"${log.transactions.items[0].prevTitle}" to "${log.transactions.items[0].title}" in `
      };
    case "MEMBERSHIP_ACCEPT":
      if (!log.transactions.items[0]?.membership?.termsOfAccess?.hasTerms) {
        return {
          action: " joined ",
          details: ""
        };
      } else {
        return {
          action: " accepted the terms of access",
          details: " to "
        };
      }
    case "MEMBERSHIP_INVITE":
      return {
        action: " invited",
        details: ` ${log.transactions.items[0].membership?.memberDetails?.email} to `
      };
    case "MEMBERSHIP_INVITE_RESEND":
      return {
        action: " resent an invite",
        details: ` for ${log.transactions.items[0].membership?.memberDetails?.email} to `
      };
    case "MEMBERSHIP_CONFIRM":
      return {
        action: " confirmed access",
        details: ` to `
      };
    case "MEMBERSHIP_REVOKE":
      return {
        action: " revoked the invite ",
        details: `for ${log.transactions.items[0].membership?.memberDetails?.email} to `
      };
    case "MEMBERSHIP_CHANGE_ACCESS":
      return {
        action: " changed role ",
        details: `for ${log.transactions.items[0].membership?.memberDetails?.email} to `
      };
    case "MEMBERSHIP_INVITE_NEW_USER":
      return {
        action: " invited ",
        details: `${log.transactions.items[0].membership?.memberDetails?.email} to `
      };
    case "MEMBERSHIP_LEAVE":
      return {
        action: " left ",
        details: ""
      };
    case "USER_VERIFIED":
      return {
        action: " has created their account",
        details: `, you can confirm their access to `
      };
    case "MEMO_WRITE":
      return {
        action: " posted a message ",
        details: "in "
      };
    case "MEMO_ADD_REACTION":
      return {
        action: " added an emoji reaction ",
        details: "to a message "
      };
    case "MEMO_REMOVE_REACTION":
      return {
        action: " removed an emoji reaction ",
        details: "to a message "
      };
    case "DATAROOM_ARCHIVE":
      return {
        action: " archived ",
        details: ""
      };
    case "DATAROOM_DELETE":
      return {
        action: " removed ",
        details: "",
        path: "/vaults/active"
      };
    case "DATAROOM_RENAME":
      return {
        action: " renamed ",
        details: `"${log.transactions.items[0].prevTitle}" to "${log.transactions.items[0].dataRoomName}" in `
      };
    case "REFERRAL_REWARD":
      return {
        preText: "Congrats! Someone you referred just signed up – ",
        action: " 100 MB of free storage ",
        postText: "has been added to your account!",
        icon: BonusIcon,
        path: "/storage"
      };
    default:
      return {
        action: log.transactions.items[0]?.actionRef,
        details: " in "
      };
  }
};

export const getRefIconForAction = actionRef => {
  switch (actionRef) {
    case "STACK_CREATE":
      return UploadIcon;
    case "STACK_MOVE":
    case "NOTE_MOVE":
      return MoveIcon;
    case "STACK_REVOKE":
    case "NOTE_REVOKE":
      return RevokedFileIcon;
    case "STACK_DELETE":
      return TrashIcon;
    case "STACK_RENAME":
      return PenIcon;
    case "NOTE_CREATE":
      return NoteIcon;
    case "STACK_RESTORE":
    case "NOTE_RESTORE":
      return RestoreIcon;
    case "NOTE_CREATE_REVISION":
      return StackIcon;
    case "MEMBERSHIP_ACCEPT":
      return InvitationAcceptIcon;
    case "MEMBERSHIP_INVITE":
      return AddMemberIcon;
    case "MEMBERSHIP_REVOKE":
      return CloseInCircleIcon;
    case "MEMBERSHIP_CHANGE_ACCESS":
      return KeyIcon;
    case "MEMBERSHIP_INVITE_NEW_USER":
      return InvitationAcceptIcon;
    case "MEMBERSHIP_LEAVE":
      return VaultLeaveIcon;
    case "USER_VERIFIED":
      return AddMemberIcon;
    case "FOLDER_CREATE":
      return FolderOutlineIcon;
    case "DATAROOM_CREATE":
      return VaultIcon;
    case "DATAROOM_ARCHIVE":
      return ArchiveIcon;
    case "DATAROOM_DELETE":
      return TrashIcon;
    case "DATAROOM_RENAME":
      return PenIcon;
    case "MEMO_WRITE":
      return PaperPlaneIcon;
    case "MEMO_ADD_REACTION":
      return PaperPlaneIcon;
    case "MEMO_REMOVE_REACTION":
      return PaperPlaneIcon;
    case "REFERRAL_REWARD":
      return StorageIcon;

    default:
      return PadlockIcon;
  }
};

export const matchIcon = icon => {
  switch (icon) {
    case "BonusIcon":
      return BonusIcon;
    case "StorageIcon":
      return StorageIcon;

    default:
      return PadlockIcon;
  }
};
