import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, IconButton, Divider, Link } from "@mui/material";
import {
  ShieldIcon,
  DisplayIcon,
  BellIcon,
  ChevronRightIcon
} from "@akord/addon-icons";
import { withRouter } from "react-router";
import { akordLinks } from "../../helpers/akordTexts";
import { SupportScreenWrapper } from "../../components/common/";

const useStyles = makeStyles(theme => ({
  hoverOver: {
    cursor: "pointer",
    "&:hover": {
      "& $icon": {
        color: theme.palette.text.primary
      }
    }
  },
  icon: {
    transition: ".3s ease"
  }
}));

function Settings({ history }) {
  const classes = useStyles();
  const settingsOptions = [
    {
      title: "Security",
      icon: ShieldIcon,
      onClick: () => {
        history.push("/settings/security");
      }
    },
    {
      title: "Notifications",
      icon: BellIcon,
      onClick: () => {
        history.push("/settings/notifications");
      }
    },
    {
      title: "Display",
      icon: DisplayIcon,
      onClick: () => {
        history.push("/settings/display");
      }
    }
  ];

  return (
    <SupportScreenWrapper title="Settings" hideChevron>
      <Box mb={7}>
        {settingsOptions.map((option, index) => (
          <div key={index}>
            <Box
              my={4}
              display="flex"
              justifyContent="space-between"
              onClick={option.onClick}
              className={classes.hoverOver}
            >
              <Box display="flex">
                <option.icon
                  color="disabled"
                  style={{ marginRight: 12 }}
                  className={classes.icon}
                />
                <Typography variant="body2" color="text.primary">
                  {option.title}
                </Typography>
              </Box>
              <IconButton
                style={{ padding: 0 }}
                onClick={option.onClick}
                size="large"
              >
                <ChevronRightIcon className={classes.icon} />
              </IconButton>
            </Box>
            <Divider />
          </div>
        ))}
      </Box>
      <Typography variant="body2" className="small">
        <Link href={akordLinks.privacy} underline="none" color="text.secondary">
          Privacy policy
        </Link>{" "}
        and{" "}
        <Link href={akordLinks.terms} underline="none" color="text.secondary">
          Terms of Service
        </Link>
      </Typography>
    </SupportScreenWrapper>
  );
}

export default withRouter(Settings);
