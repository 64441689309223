export const transactionsByDataRoomId =
  /* GraphQL */
  `
    query TransactionsByDataRoomId(
      $dataRoomId: ID!
      $filter: ModelTransactionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      transactionsByDataRoomId(
        dataRoomId: $dataRoomId
        filter: $filter
        nextToken: $nextToken
        limit: $limit
        sortDirection: DESC
      ) {
        items {
          id
          hash
          schemaUri
          postedAt
          publicSigningKey
          contextVersion
          prevHash
          refHash
          dataRoomId
          modelId
          memberDetails {
            publicSigningKey
            email
            fullName
            phone
            avatarUrl
          }
          actionRef
          groupRef
          encodedPrevState
          membership {
            status
            role
            expiresOn
            memberDetails {
              publicSigningKey
              email
              fullName
              phone
              avatarUrl
            }
            termsOfAccess
            agreementHash
            message
            encryptionType
            keys {
              publicKey
              encPrivateKey
            }
            encAccessKey
          }
          dataroom {
            status
            title
            description
            termsOfAccess
            publicKeys
          }
          stack {
            status
            title
            description
            resourceVersion
            size
            files {
              title
              resourceUrl
              thumbnailUrl
              postedAt
              fileType
              size
              numberOfChunks
              chunkSize
              hash
            }
          }
          folder {
            status
            title
          }
          memo {
            message
            reactions {
              publicSigningKey
              name
              reaction
              status
              postedAt
              refHash
            }
          }
          note {
            status
            title
            resourceVersion
            size
            content
            revisions {
              title
              postedAt
              size
              hash
              content
            }
          }
          profile {
            profileDetails {
              publicSigningKey
              email
              fullName
              phone
              avatarUrl
            }
            status
            wallets {
              publicSigningKey
              encBackupPhrase
              publicKey
              revokedOn
            }
            encryptionType
            keys {
              publicKey
              encPrivateKey
            }
            encAccessKey
          }
          status
          encodedHeader
          encodedBody
          publicKey
          signature
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;
