export const getPaymentConfig = /* GraphQL */ `
query PaymentConfig($topUp: TopUpPaymentConfigInput) {
  paymentConfig(topUp: $topUp) {
    publishableKey
    products {
      id
      description
      storage
      amount
      currency
      promoText
    }
  }
}
`;

export const getInvoice = /* GraphQL */ `
query Invoice($topUp: TopUpInvoiceInput) {
  invoice(topUp: $topUp) {
    base64Buffer
  }
}
`;