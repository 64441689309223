import React from "react";
import { withRouter } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from "@mui/material/styles";
import { Typography, Box, Container } from "@mui/material";
import { ButtonLink, UploadButton } from "..";
import { emptySpaceData } from "./emptySpaceData";
import { getUserRole, isArchived } from "../../../helpers/helpers";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../../contexts/VaultContextProvider";

const useStyles = makeStyles({
  container: {
    marginTop: ({ isMobile }) => (isMobile ? 0 : "56px"),
    paddingTop: ({ topMargin }) => topMargin,
    marginBottom: ({ bottomMargin }) => bottomMargin,
    paddingBottom: "20px",
    textAlign: "center",
    overflow: ({ isMobile }) => (isMobile ? "scroll" : "auto")
  }
});

function EmptySpace(props) {
  const { dataText, spaceData, history, onUploadStart } = props;
  const { wallet, decryptedProfileDetails, width, isMobile } =
    useGlobalContext();
  const { currentMembership, isVaultPublic } = useVaultContext();
  const theme = useTheme();
  const classes = useStyles({
    topMargin: isMobile
      ? emptySpaceData[dataText].topMarginXS
        ? emptySpaceData[dataText].topMarginXS
        : emptySpaceData[dataText].topMargin
      : emptySpaceData[dataText].topMargin,
    bottomMargin: emptySpaceData[dataText].bottomMarginXS
      ? emptySpaceData[dataText].bottomMarginXS
      : 0,
    isMobile: isMobile
  });

  const Icon = emptySpaceData[dataText].icon;
  const IconSecond = emptySpaceData[dataText].iconSecond;
  const userRole = getUserRole(spaceData, decryptedProfileDetails);
  const isRoomArchived = isArchived(spaceData);
  const isWelcomeScreen = emptySpaceData[dataText].welcomeScreen;

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Box mb={4}>
        {isWelcomeScreen ? (
          <Icon style={{ margin: "auto", fontSize: "40px" }} />
        ) : (
          <div
            style={{
              margin: "auto",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              border: "1px solid ",
              borderColor: isVaultPublic
                ? theme.palette.info.main
                : theme.palette.primary.main,
              display: "flex"
            }}
          >
            <Icon
              style={{
                margin: "auto",
                color: isVaultPublic
                  ? theme.palette.info.main
                  : theme.palette.primary.main
              }}
            />
          </div>
        )}
      </Box>
      <Box mb={2}>
        <Typography
          variant="h1"
          color="text.primary"
          className={isWelcomeScreen ? "xxl" : ""}
        >
          {emptySpaceData[dataText].title}
        </Typography>
      </Box>
      <Box mb={5}>{emptySpaceData[dataText].getTextEl(history, userRole)}</Box>
      {isWelcomeScreen && (
        <>
          <Box my={7}>
            <IconSecond
              viewBox="0 0 266 74"
              style={{ fontSize: "265px", height: "auto" }}
            />
          </Box>
          <Box mb={5}>{emptySpaceData[dataText].getTextElSecond()}</Box>
        </>
      )}
      {dataText === "newFile" || dataText === "newPublicFile" ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection={isMobile ? "column" : "row"}
        >
          <UploadButton
            disabled={userRole === "VIEWER" || isRoomArchived}
            spaceData={spaceData}
            decryptedStacks={[]}
            wallet={wallet}
            onUploadStart={onUploadStart}
            noMinWidth={emptySpaceData[dataText].noMinWidth}
          >
            {emptySpaceData[dataText].buttonText(userRole)}
          </UploadButton>
          <Box mx={4} my={isMobile ? 4 : 0}>
            <Typography variant="body2">or</Typography>
          </Box>
          <ButtonLink
            disabled={userRole === "VIEWER" || isRoomArchived}
            width={width}
            onClick={() => emptySpaceData[dataText].handleClickSecond(history)}
          >
            {emptySpaceData[dataText].buttonTextSecond}
          </ButtonLink>
        </Box>
      ) : (
        emptySpaceData[dataText].buttonText(userRole) && (
          <ButtonLink
            onClick={() =>
              emptySpaceData[dataText].handleClick(history, currentMembership)
            }
            width={width}
          >
            {emptySpaceData[dataText].buttonText(userRole)}
          </ButtonLink>
        )
      )}
    </Container>
  );
}

export default withRouter(EmptySpace);
