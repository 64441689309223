import React from "react";
import {
  Box,
  Typography,
  Divider,
  IconButton,
  CssBaseline
} from "@mui/material";
import { CloseIcon } from "@akord/addon-icons";
import { withRouter } from "react-router";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import ThemeWrapper from "./ThemeWrapper";

function StandAloneScreenWrapper({
  children,
  title,
  subtitle,
  route,
  history
}) {
  const { darkMode } = useGlobalContext();

  return (
    <ThemeWrapper darkMode={darkMode}>
      <CssBaseline />
      <Box px={4} width="100%" maxWidth="576px" height="100%" mx="auto">
        <Box>
          <Box mb={6}>
            <Box pt={4} pb={3}>
              <Box
                mb={1}
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <Typography variant="h2" color="text.primary">
                  {title}
                </Typography>
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() =>
                    route ? history.push(route) : history.goBack()
                  }
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              {subtitle && (
                <Typography variant="body2" color="text.secondary">
                  {subtitle}
                </Typography>
              )}
            </Box>
            <Divider />
          </Box>
          {children}
        </Box>
      </Box>
    </ThemeWrapper>
  );
}

export default withRouter(StandAloneScreenWrapper);
