import React from "react";
import { Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import makeStyles from "@mui/styles/makeStyles";
import { akordLinks } from "../../../helpers/akordTexts";

const useStyles = makeStyles(theme => ({
  announcementBox: {
    background: theme.palette.background.card,
    borderRadius: "4px",
    padding: theme.spacing(4),
    border: "1px solid",
    borderColor: theme.palette.background.tertiary
  }
}));

const AnnouncementV2 = () => {
  const classes = useStyles();

  return (
    <Box mb={8} className={classes.announcementBox}>
      <Typography variant="body2" className={["small", "strong"].join(" ")}>
        Move to V2 Akord
      </Typography>
      <Box mt={1}>
        <Typography variant="body2" className="small" component="span">
          Since November 2022, we no longer actively develop this version of Akord. If you haven&apos;t migrated your data to
        </Typography>{" "}
        <Link
          variant="body2"
          className="small"
          onClick={() => window.open("https://v2.akord.com")}
          color="text.tertiary"
          sx={{ borderBottomColor: "primary.main" }}
        >
          V2 Akord
        </Link>
        {", "}
        <Typography component="span" variant="body2" className="small">
          then please
        </Typography>{" "}
        <Link
          variant="body2"
          className="small"
          onClick={() => window.open(akordLinks["support"])}
          color="text.tertiary"
          sx={{ borderBottomColor: "primary.main" }}
        >
          contact us
        </Link>{" "}
        <Typography component="span" variant="body2" className="small">
          to transfer your data.
        </Typography>
      </Box>
    </Box>
  );
};

export default AnnouncementV2;
