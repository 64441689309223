import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { v4 as uuidv4 } from "uuid";
import API, { graphqlOperation } from "@aws-amplify/api";
import {
  Box,
  FormControl,
  Divider,
  Button,
  TextareaAutosize
} from "@mui/material";
import InviteToRoomForm from "./InviteToRoomForm";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import CircularProgress from "@mui/material/CircularProgress";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { withRouter } from "react-router";
import { SupportScreenWrapper } from "../../components/common";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import LedgerWrapper from "../../crypto/ledger-wrapper";
import { fromMembership } from "@akord/crypto";

const useStyles = makeStyles(theme =>
  createStyles({
    containedPrimary: {
      minWidth: "auto"
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: "absolute"
    },
    textArea: {
      fontFamily: [
        "Larsseit-Regular",
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Roboto",
        "Oxygen-Sans",
        "Ubuntu",
        "Cantarell",
        "Helvetica Neue",
        "sans-serif"
      ].join(","),
      padding: 0,
      fontSize: "1rem",
      color: theme.palette.text.primary,
      border: "none",
      "&::placeholder": {
        color: theme.palette.text.secondary
      }
    }
  })
);

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
let count = 0;

function InviteToRoom({ location }) {
  const roomData = location.state || null;
  const { isMobile, darkMode, wallet, onTxSpinner } = useGlobalContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const { ledgerWrapper, currentMembership, isVaultPublic } = useVaultContext();
  const classes = useStyles({ darkMode: darkMode, isMobile: isMobile });
  const [invalidUsers, setInvalidUsers] = useState([]);
  const [alreadyInvited, setAlreadyInvited] = useState([]);
  const [inviteForm, setInviteForm] = useState([
    {
      id: 0,
      email: "",
      role: "CONTRIBUTOR"
    }
  ]);
  const [inviteMessage, setInviteMessage] = useState({
    message: ""
  });
  const [loading, setLoading] = useState(false);

  const handleFormChange = (e, elIndex) => {
    setInviteForm(
      inviteForm.map(el =>
        el.id === elIndex ? { ...el, [e.target.name]: e.target.value } : el
      )
    );
  };

  const handleMessageChange = e => {
    setInviteMessage({ message: e.target.value });
  };

  const isButtonDisabled = () => {
    const allInvitesRegex = inviteForm.every(
      (invite, index) =>
        (invite && invite.email.match(emailRegex) && invite.role) ||
        (index !== 0 && invite.email === "")
    );
    if (allInvitesRegex && !loading) return false;
    else return true;
  };

  React.useEffect(() => {
    //clearing warnings
    const invalidUsersActive = invalidUsers.filter(obj => {
      return inviteForm.some(obj2 => {
        if (obj2) {
          return obj.email === obj2.email;
        } else return false;
      });
    });
    if (invalidUsersActive) setInvalidUsers(invalidUsersActive);
    //clearing warnings
    const alreadyInvitedActive = alreadyInvited.filter(obj => {
      return inviteForm.some(obj2 => {
        if (obj2) {
          return obj.email === obj2.email;
        } else return false;
      });
    });
    if (alreadyInvitedActive) setAlreadyInvited(alreadyInvitedActive);

    if (inviteForm[count] && !!inviteForm[count].email.match(emailRegex)) {
      count++;
      setInviteForm([
        ...inviteForm,
        { id: count, email: "", role: "CONTRIBUTOR" }
      ]);
    }
    if (inviteForm[count - 1] && !inviteForm[count - 1].email && count !== 0) {
      setInviteForm(inviteForm.filter(item => item.id !== count));
      count--;
    }
  }, [inviteForm]);

  const handleInviteForm = async () => {
    try {
      setLoading(true);
      const filterInvites = inviteForm.filter(object => object.email);
      const emailArray = filterInvites.map(item => item.email);
      const results = await API.graphql(
        graphqlOperation(queries.preInviteCheck, {
          emails: emailArray,
          dataRoomId: roomData.dataRoomId
        })
      );
      if (results.data) {
        onTxSpinner(true);
        await sendTransaction(results.data.preInviteCheck);
        onTxSpinner(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const sendTransaction = React.useCallback(
    async preInviteCheck => {
      const filteredInviteForm = inviteForm.filter(item => item.email !== "");

      // check if a user already a member but not Revoked
      const alreadyInvited = filteredInviteForm.filter(obj => {
        return roomData.dataRoom.members.items.some(obj2 => {
          if (obj2) {
            return (
              obj.email === obj2.state.memberDetails.email &&
              obj2.state.status !== "REVOKED" &&
              obj2.state.status !== "REJECTED"
            );
          } else return false;
        });
      });

      if (alreadyInvited.length > 0) {
        setAlreadyInvited(alreadyInvited);
        setLoading(false);
        return;
      }

      //check users which are not on Akord
      const newUsers = filteredInviteForm.filter(obj => {
        return preInviteCheck.some(obj2 => {
          if (obj2) {
            return (
              obj.email === obj2.email &&
              !obj2.publicSigningKey &&
              !obj2.membership
            );
          } else return false;
        });
      });

      if (newUsers.length > 0) {
        newUsers.forEach(user => {
          user.status = "NEW_USER_INVITED";
          user.dataRoomId = roomData.dataRoomId;
        });
      }

      //check users which are not on Akord and were Revoked
      const newUsersRevoked = filteredInviteForm.reduce((acc, curr) => {
        const matchUser = preInviteCheck.find(
          user =>
            user.email === curr.email &&
            !user.publicSigningKey &&
            user.membership
        );
        if (matchUser) {
          curr.membership = matchUser.membership;
          curr.status = "NEW_USER_REINVITED";
          acc.push(curr);
        }
        return acc;
      }, []);

      // check if a user already a member but Revoked
      const existingRevokedUsers = preInviteCheck.filter(obj => {
        return filteredInviteForm.some(obj2 => {
          if (obj2) {
            return (
              obj2.email === obj.email && obj.membership && obj.publicSigningKey
            );
          } else return false;
        });
      });

      //users with Akord account
      const akordUsers = filteredInviteForm.reduce((acc, curr) => {
        const matchUser = preInviteCheck.find(
          user =>
            user.email === curr.email &&
            user.publicKey &&
            user.publicSigningKey &&
            !user.membership
        );
        if (matchUser) {
          curr.publicKey = matchUser.publicKey;
          curr.publicSigningKey = matchUser.publicSigningKey;
          acc.push(curr);
        }
        return acc;
      }, []);

      //combine all users together
      const updatedUsersByEmail = newUsers
        .concat(akordUsers)
        .concat(existingRevokedUsers)
        .concat(newUsersRevoked);

      const groupRef = uuidv4();
      let peopleInvited = 0;

      const inviteResult = await updatedUsersByEmail.map(async user => {
        if (user) {
          const inviteRecord = inviteForm.filter(
            item => item.email === user.email
          )[0];
          try {
            let actionRef;
            let header = {
              groupRef: groupRef
            };
            let body = {
              role: inviteRecord.role,
              emailMessage: inviteMessage.message,
              termsOfAccess: roomData.dataRoom.state.termsOfAccess
            };
            const encryptionKeys = fromMembership(currentMembership);
            const memberLedgerWrapper = new LedgerWrapper(
              ledgerWrapper.wallet,
              encryptionKeys
            );
            //to invite a new user
            if (user.status === "NEW_USER_INVITED") {
              actionRef = "MEMBERSHIP_INVITE_NEW_USER";
              header.dataRoomId = roomData.dataRoomId;
              body.memberDetails = {
                email: user.email
              };
            }
            //to reinvite a new user
            else if (user.status === "NEW_USER_REINVITED") {
              actionRef = "MEMBERSHIP_INVITE_NEW_USER";
              header.prevHash = user.membership.hash;
            }
            //to reinvite
            else if (user.membership) {
              actionRef = "MEMBERSHIP_INVITE";
              header.prevHash = user.membership.hash;
              body.memberDetails = {
                publicSigningKey: user.publicSigningKey
              };
              body.memberKeys = user.membership.state.keys;
              memberLedgerWrapper.setKeysEncryptionPublicKey(user.publicKey);
            }
            //for new invites
            else {
              actionRef = "MEMBERSHIP_INVITE";
              header.dataRoomId = roomData.dataRoomId;
              body.memberDetails = {
                publicSigningKey: user.publicSigningKey
              };
              body.memberKeys = [];
              memberLedgerWrapper.setKeysEncryptionPublicKey(user.publicKey);
            }
            const { encodedTransaction } = await memberLedgerWrapper.dispatch(
              actionRef,
              header,
              body,
              null,
              isVaultPublic
            );

            peopleInvited++;
            return await API.graphql(
              graphqlOperation(mutations.postLedgerTransaction, {
                transactions: [encodedTransaction]
              })
            );
          } catch (err) {
            console.log(err);
            throw new Error(err);
          }
        } else {
          console.error("User doesn't exist!");
          throw new Error("User doesn't exist!");
        }
      });

      Promise.all(inviteResult)
        .then(() => {
          setLoading(false);
          onSnackbarToShow(
            "dataRoomInvite",
            peopleInvited > 1 ? peopleInvited : null
          );
          count = 0;
          history.back();
        })
        .catch(() => {
          setLoading(false);
          onSnackbarToShow("actionFailure", null, "error");
          count = 0;
        });
    },
    [
      inviteForm,
      wallet,
      inviteMessage.message,
      roomData.txContextUri,
      roomData.members,
      roomData.encPrivateKey
    ]
  );

  // React.useEffect(() => {
  //   if (data) sendTransaction(data.usersByEmail)
  // }, [data])

  const handleEmailClear = (elIndex, item) => {
    if (
      elIndex === 0 &&
      inviteForm.filter(user => user.email !== "").length === 1
    ) {
      setInviteForm([
        {
          id: 0,
          email: "",
          role: "CONTRIBUTOR"
        }
      ]);
      count = 0;
    } else {
      setInviteForm(inviteForm.filter(el => el.id !== item.id));
      count--;
    }
    setInvalidUsers(invalidUsers.filter(user => user.id !== item.id));
    setAlreadyInvited(alreadyInvited.filter(user => user.id !== item.id));
  };

  if (!roomData) return null;

  return (
    <SupportScreenWrapper
      title="Invite to vault"
      subtitle={roomData.dataRoom.state.title}
      referral
    >
      <Box width={!isMobile ? "100%" : "inherit"}>
        {inviteForm.map((item, index) => (
          <InviteToRoomForm
            key={index}
            index={index}
            item={item}
            darkMode={darkMode}
            isMobile={isMobile}
            inviteForm={inviteForm}
            handleFormChange={handleFormChange}
            handleEmailClear={handleEmailClear}
            invalidUsers={invalidUsers}
            alreadyInvited={alreadyInvited}
          />
        ))}
        {isMobile && <Divider style={{ marginTop: "24px" }} />}
        <Box mt={6} mb={8}>
          <FormControl fullWidth>
            <TextareaAutosize
              id="enter-message"
              name="message"
              type="text"
              placeholder="Add an optional message... &#13;&#10;Please note, it will be sent unencrypted in an email notification."
              onChange={handleMessageChange}
              className={classes.textArea}
            />
          </FormControl>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="primary"
        type="button"
        disableElevation
        classes={{
          containedPrimary: classes.containedPrimary
        }}
        // style={error && { background: '#DB443C' }}
        disabled={isButtonDisabled()}
        fullWidth={isMobile}
        onClick={handleInviteForm}
      >
        Invite to vault
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </Button>
    </SupportScreenWrapper>
  );
}

export default withRouter(InviteToRoom);
