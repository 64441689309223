import React, { useState } from "react";
import API, { graphqlOperation } from "@aws-amplify/api";
import { Box, Typography } from "@mui/material";
import InviteToRoomForm from "./InviteToRoomForm";
import * as mutations from "../../graphql/mutations";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useHistory, withRouter } from "react-router";
import { StandAloneScreenWrapper, ButtonGroup } from "../../components/common";
import { TicketIcon } from "@akord/addon-icons";
import { copyToClipboard } from "../../helpers/helpers";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
let count = 0;

function Referral() {
  const {
    isMobile,
    darkMode,
    onUserAttributes,
    userAttributes,
    onError,
    error
  } = useGlobalContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const history = useHistory();
  const [inviteForm, setInviteForm] = useState([
    {
      id: 0,
      email: ""
    }
  ]);
  const [loading, setLoading] = useState(false);

  const userReferralId = userAttributes.userReferralId;
  const referralsCount = userAttributes.referrals;
  const referralUrl =
    window.location.host + "/signup/?referrerId=" + userReferralId;

  const [copyClick, setCopyClick] = useState(false);
  const handleCopyClick = value => setCopyClick(value);

  const handleFormChange = (e, elIndex) => {
    setInviteForm(
      inviteForm.map(el =>
        el.id === elIndex ? { ...el, [e.target.name]: e.target.value } : el
      )
    );
  };

  const isButtonDisabled = () => {
    const allInvitesRegex = inviteForm.every(
      (invite, index) =>
        (invite && invite.email.match(emailRegex)) ||
        (index !== 0 && invite.email === "")
    );
    if (allInvitesRegex && !loading) return false;
    else return true;
  };

  React.useEffect(() => {
    if (count === 10 - referralsCount - 1) return;
    if (inviteForm[count] && !!inviteForm[count].email.match(emailRegex)) {
      count++;
      setInviteForm([...inviteForm, { id: count, email: "" }]);
    }
    if (inviteForm[count - 1] && !inviteForm[count - 1].email && count !== 0) {
      setInviteForm(inviteForm.filter(item => item.id !== count));
      count--;
    }
  }, [inviteForm]);

  const handleInviteForm = async () => {
    try {
      setLoading(true);
      const filterInvites = inviteForm.filter(object => object.email);
      const emailArray = filterInvites.map(item => item.email);
      await API.graphql(
        graphqlOperation(mutations.sendReferralEmail, {
          emails: emailArray
        })
      );
      setLoading(false);
      history.goBack();
      onSnackbarToShow(
        emailArray.length > 1 ? "referralInvites" : "referralInvite"
      );
      const newReferralCount = referralsCount + 1;
      onUserAttributes({ referrals: newReferralCount });
    } catch (err) {
      setLoading(false);
      onError(err);
      console.log("Error handling referral invite: ", err);
    }
  };

  const handleEmailClear = (elIndex, item) => {
    if (
      elIndex === 0 &&
      inviteForm.filter(user => user.email !== "").length === 1
    ) {
      setInviteForm([
        {
          id: 0,
          email: ""
        }
      ]);
      count = 0;
    } else {
      let inviteFormCopy = inviteForm.filter(el => el.id !== item.id);
      inviteFormCopy[inviteFormCopy.length - 1].id = inviteFormCopy.length - 1;
      setInviteForm(inviteFormCopy);
      count--;
    }
  };

  return (
    <StandAloneScreenWrapper title="Referral Reward">
      <Box mb={7}>
        <Typography variant="body2" className="strong" color="text.primary">
          100 MB per referral
        </Typography>
        <Typography variant="body2">
          Copy the referral link below and send it to friends, or enter their
          email addresses below and we’ll take care of the rest. You have a max
          of 10 referral rewards.
        </Typography>
        <Box mt={6}>
          <Typography variant="body2" className="strong">
            {10 - referralsCount} referral rewards available.
          </Typography>
        </Box>
        <Box mt={6}>
          <TicketIcon color="disabled" style={{ marginRight: "12px" }} />
          <Typography
            component="a"
            variant="body2"
            onClick={() => copyToClipboard(referralUrl, handleCopyClick)}
          >
            {copyClick ? "Copied!" : "Copy referral link"}
          </Typography>
        </Box>
      </Box>
      <Box mb={7} width="100%" maxWidth="400px">
        {inviteForm.map((item, index) => (
          <InviteToRoomForm
            key={index}
            index={index}
            item={item}
            darkMode={darkMode}
            isMobile={isMobile}
            inviteForm={inviteForm}
            handleFormChange={handleFormChange}
            handleEmailClear={handleEmailClear}
            noSelect
          />
        ))}
      </Box>
      <ButtonGroup
        loading={loading}
        type="submit"
        noMinWidth
        nextText="Invite now"
        nextDiasabled={isButtonDisabled()}
        hideBackButton={true}
        fullWidthNextButton={isMobile ? true : false}
        hideIcon
        errorText={error}
        handleEnd={() => handleInviteForm()}
        disabledRed={false}
      />
    </StandAloneScreenWrapper>
  );
}

export default withRouter(Referral);
