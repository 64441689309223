import { Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
// Storage.configure({
//   level: "public",
//   customPrefix: {
//     public: "publicFiles/"
//   }
// });
export default class PublicChunkProcessor {
  constructor(progressHook, cancelHook, snackbarHook) {
    this.progressHook = progressHook;
    this.cancelHook = cancelHook;
    this.snackbarHook = snackbarHook;
  }

  encodingFactor = 2.4;
  encryptionFactor = 100; // in bytes - safety buffer; encryption should take 16 bytes
  chunkSize = 10485760;
  uploadInProgress = false;
  downloadInProgress = false;
  downloadCancelled = false;
  uploadedChunks = 0;
  resourceUrl = null;

  async chunkedUpload(file) {
    this.uploadInProgress = true;
    let offset = 0;
    let loadedProgress = 0;
    let totalProgress = 0;

    while (offset < file.size && !this.uploadCancelled) {
      const chunk = file.slice(offset, this.chunkSize + offset);
      const { publicData, chunkNumber } = await this._proccessChunk(
        chunk,
        offset
      );
      totalProgress = file.size;
      if (offset == 0) {
        this.resourceUrl = publicData.resourceKey;
      }
      loadedProgress = await this._uploadChunk(
        publicData,
        chunkNumber,
        this.resourceUrl,
        loadedProgress,
        totalProgress
      );
      offset += this.chunkSize;
      this.uploadedChunks += 1;
    }

    this.uploadInProgress = false;
    return {
      uploaded: !this.uploadCancelled,
      resourceUrl: this.resourceUrl,
      numberOfChunks: this.uploadedChunks,
      chunkSize: this.chunkSize
    };
  }

  cancelUpload() {
    this.uploadCancelled = true;
    this.progressHook(0);
    let removedChunks = 0;
    while (removedChunks <= this.uploadedChunks) {
      Storage.remove(`${this.resourceUrl}_${removedChunks}`);
      removedChunks += 1;
    }
  }

  async publicChunkedDownload(
    originalFileSize,
    resourceUrl,
    numberOfChunks,
    fileType
  ) {
    this.downloadInProgress = true;
    let fileData = new Uint8Array();
    let uploadedFileSize = originalFileSize;
    let currentChunk = 0;
    let decryptedSize = 0;
    const chunksCount = numberOfChunks || 1;
    const progressHook = this.progressHook;
    if (chunksCount > 1 && this.snackbarHook) {
      this.snackbarHook("fileDownload");
    }
    try {
      while (currentChunk < chunksCount && !this.downloadCancelled) {
        const chunkUrl = this._getChunkUrl(
          resourceUrl,
          numberOfChunks,
          currentChunk
        );

        if (!chunkUrl) {
          throw new Error(
            "Failed to download. The file might be corrupted. Please upload the file again and/or contact Akord support."
          );
        }
        const bucketObject = await Storage.get(chunkUrl, {
          download: true,
          progressCallback(progress) {
            let percentCompleted = Math.round(
              ((decryptedSize + progress.loaded) * 100) / uploadedFileSize
            );
            if (progressHook) {
              progressHook(percentCompleted);
            }
          }
        });
        if (Object.keys(bucketObject).length > 0) {
          const { publicFile } = (await this._getFile(bucketObject)) || {};
          fileData = this._appendBuffer(fileData, publicFile);
          currentChunk += 1;
          decryptedSize = fileData?.byteLength || 0;
        }
      }
    } catch (e) {
      console.log(e);
      throw new Error(
        "Failed to download. Please check your network connection." +
          " Please upload the file again if problem persists and/or contact Akord support."
      );
    } finally {
      this.downloadInProgress = false;
    }
    if (this.downloadCancelled) {
      throw new Error("Download cancelled");
    }
    return new Blob([fileData], { type: fileType });
  }

  async noAuthPublicChunkedDownload(chunks, numberOfChunks, fileType) {
    this.downloadInProgress = true;
    let fileData = new Uint8Array();
    let currentChunk = 0;
    const chunksCount = numberOfChunks || 1;
    if (chunksCount > 1 && this.snackbarHook) {
      this.snackbarHook("fileDownload");
    }
    try {
      while (currentChunk < chunksCount && !this.downloadCancelled) {
        const chunkUrl = chunks[currentChunk];

        if (!chunkUrl) {
          throw new Error(
            "Failed to download. The file might be corrupted. Please upload the file again and/or contact Akord support."
          );
        }
        const response = await axios.get(chunkUrl, {
          decompress: false,
          // Ref: https://stackoverflow.com/a/61621094/4050261
          responseType: "arraybuffer"
        });
        const dataArrayBuffer = response.data;

        if (Object.keys(response).length > 0) {
          fileData = this._appendBuffer(fileData, dataArrayBuffer);
          currentChunk += 1;
        }
      }
    } catch (e) {
      console.log(e);
      throw new Error(
        "Failed to download. Please check your network connection." +
          " Please upload the file again if problem persists and/or contact Akord support."
      );
    } finally {
      this.downloadInProgress = false;
    }
    if (this.downloadCancelled) {
      throw new Error("Download cancelled");
    }
    return new Blob([fileData], { type: fileType });
  }

  cancelDownload() {
    this.downloadCancelled = true;
  }

  cancel() {
    if (this.downloadInProgress) {
      this.downloadInProgress = false;
      this.cancelDownload();
    } else if (this.uploadInProgress) {
      this.uploadInProgress = false;
      this.cancelUpload();
    }
  }

  async _getFile(bucketObject) {
    return {
      publicFile: await bucketObject.Body.arrayBuffer()
    };
  }

  _getChunkUrl(resourceUrl, numberOfChunks, chunkNumber) {
    if (numberOfChunks) {
      if (chunkNumber >= numberOfChunks) {
        return null;
      }
      return `${resourceUrl}_${chunkNumber}`;
    }
    return resourceUrl;
  }

  async _proccessChunk(chunk, offset) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = async evt => {
        if (evt.target.error == null) {
          const chunkNumber = offset / this.chunkSize;
          const byteArray = new Uint8Array(evt.target.result);
          const publicData = this._proccessByteArray(byteArray);
          resolve({ publicData, chunkNumber });
        }
      };
      reader.readAsArrayBuffer(chunk);
    });
  }

  _proccessByteArray(byteArray) {
    const resourceKey = uuidv4();
    const uploadPayload = {
      publicFile: byteArray,
      resourceKey: resourceKey
    };
    return uploadPayload;
  }

  async _uploadChunk(
    chunk,
    chunkNumber,
    resourceUrl,
    loadedProgress,
    totalProgress
  ) {
    const processor = this;
    await Storage.put(`${resourceUrl}_${chunkNumber}`, chunk.publicFile, {
      metadata: {
        publicfile: "true"
      },
      progressCallback(progress) {
        if (processor.uploadCancelled) {
          processor.progressHook(0);
        } else {
          let percentCompleted = Math.round(
            (loadedProgress + progress.loaded * 100) / totalProgress
          );
          if (processor.progressHook) {
            processor.progressHook(percentCompleted);
          }
          if (progress.loaded == progress.total) {
            loadedProgress += progress.loaded * 100;
          }
        }
      }
    });
    return loadedProgress;
  }

  _appendBuffer(buffer1, buffer2) {
    if (!buffer1 || !buffer2) return;
    var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
    tmp.set(new Uint8Array(buffer1), 0);
    tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
    return tmp.buffer;
  }
}
