import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Gallery, darkTheme, lightTheme } from "@akord/ui";
import { Helmet } from "react-helmet";
import { ThemeProvider, Dialog } from "@mui/material";
import { publicFileView } from "../../helpers/akord-actions";
import "@akord/ui/dist/esm/index.css";
import { usePublicFileViewerContext } from "../../contexts/PublicFileViewerContextProvider";

function PublicGallery() {
  const { activeAsset, assetHash } = usePublicFileViewerContext();

  const history = useHistory();

  const [files, setFiles] = useState([]);
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const [theme, setTheme] = useState(darkTheme);
  const [key, setKey] = useState(0);

  useEffect(async () => {
    if (activeAsset)
      setFiles(
        mapToFiles([
          {
            ...activeAsset?.state,
            type: activeAsset?.type,
            id: activeAsset?.id,
            refId: activeAsset?.storageTransactions[0]?.refId
          }
        ])
      );
  }, [activeAsset]);

  useEffect(async () => {
    setTheme(isDarkTheme ? darkTheme : lightTheme);
  }, [isDarkTheme]);

  useEffect(async () => {
    setKey(key + 1);
  }, [files]);

  const getFile = async file => {
    try {
      const blob = (await publicFileView(file)) || new Blob();
      const src = blob ? URL.createObjectURL(blob) : null;
      const type = (file.title.split(".").pop() || "").toLowerCase();
      return { src: src, type: type, fileName: file.title };
    } catch (e) {
      console.log(e);
    }
  };

  const getNote = async note => {
    return Promise.resolve({
      src: JSON.parse(note.content),
      type: "note",
      fileName: note.title
    });
  };

  const mapToFiles = assets => {
    const viewawbleFiles = [];
    assets.map((asset, idx) => {
      const revisions = asset?.files || asset?.revisions;
      return revisions?.forEach((revision, version) => {
        if (assetHash === revision.hash)
          viewawbleFiles.push({
            hash: revision.hash,
            id: asset.id,
            txId: asset.refId,
            group: idx.toString(),
            version: version,
            toLoad: revision,
            onLoad: asset.type === "NOTE" ? getNote : getFile
          });
      });
    });
    return viewawbleFiles;
  };

  if (!files || !files.length) {
    return null;
  }
  const { host, protocol } = window.location;
  const baseUrl = `${protocol}//${host}`;
  const pathname = history.location.pathname;

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Akord | Public</title>
        <link rel="canonical" href={`${baseUrl}${pathname}`} />
      </Helmet>
      <Dialog
        fullScreen
        open
        PaperProps={{
          style: {
            overflowY: "hidden",
            backgroundColor: theme.palette.background.default
          }
        }}
      >
        <Gallery
          key={key}
          navigation={activeAsset.state.title}
          width="auto"
          height="auto"
          mode="single"
          darkMode={isDarkTheme}
          onThemeSwitch={() => setIsDarkTheme(!isDarkTheme)}
          onClose={() => history.push("/sign-in")}
          // groupFilesEnabled
          printEnabled
          loaderEnabled
          loaderText="Loading"
          downloadEnabled
          themeSwitchEnabled
          viewTransactionEnabled
          transactionBaseUrl="https://viewblock.io/arweave/tx"
          publicShareEnabled
          publicShareBaseUrl={`${baseUrl}/public`}
          files={files}
        />
      </Dialog>
    </ThemeProvider>
  );
}

export default PublicGallery;
