import React, { useState, createContext, useContext, useEffect } from "react";
import { withRouter, matchPath } from "react-router";
import { getPublicAssetByIdQuery } from "../graphql/queries/utils";

const Context = createContext();

function PublicFileViewerContextProvider({ children, location, history }) {
  const [activeAsset, setActiveAsset] = useState();
  const handleActiveAsset = stack => setActiveAsset(stack);

  const getAssetId = pathname => {
    const matchProfile = matchPath(pathname, {
      path: `/public/:assetId/:assetHash`
    });
    return {
      assetId: matchProfile?.params?.assetId || null,
      assetHash: matchProfile?.params?.assetHash || null
    };
  };

  const { assetId, assetHash } = getAssetId(location.pathname);

  useEffect(() => {
    const loadAsset = async () => {
      try {
        const asset = await getPublicAssetByIdQuery(assetId);
        handleActiveAsset(asset);
      } catch (err) {
        console.log("loadStack error: ", err);
        history.push("/404");
      }
    };
    if (assetId) loadAsset();
  }, [assetId]);

  return (
    <Context.Provider
      value={{
        activeAsset: activeAsset,
        onActiveAsset: handleActiveAsset,
        assetHash: assetHash
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default withRouter(PublicFileViewerContextProvider);

export const withPublicFileViewerContext = Component => props =>
  (
    <Context.Consumer>
      {fileViewerContext => <Component {...props} {...fileViewerContext} />}
    </Context.Consumer>
  );

export const usePublicFileViewerContext = () => useContext(Context);
