import API, { graphqlOperation } from "@aws-amplify/api";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import React, { useEffect, useState } from "react";
import { SupportScreenWrapper } from "../../components/common/";
import { getPaymentConfig } from "../../graphql/queries/topup";
import CardForm from "./CardForm";

function TopUp() {
  const [stripePromise, setStripePromise] = useState(null);
  const [products, setProducts] = useState();

  useEffect(() => {
    async function initializeTopUp() {
      const paymentIntentResponse = await API.graphql(
        graphqlOperation(getPaymentConfig, {
          topUp: {
            operation: "PAYMENT_CONFIG",
            currency: "usd"
          }
        })
      );

      const publishableKey =
        paymentIntentResponse.data?.paymentConfig?.publishableKey;
      const products = paymentIntentResponse.data?.paymentConfig?.products;
      setProducts(products);

      const stripePromise = loadStripe(publishableKey);
      setStripePromise(stripePromise);
    }
    initializeTopUp();
  }, []);

  // if (!stripePromise || !products) {
  //   return null
  // }

  return (
    <SupportScreenWrapper title="Top up" route="/storage">
      <Elements stripe={stripePromise}>
        <CardForm products={products} />
      </Elements>
    </SupportScreenWrapper>
  );
}

export default TopUp;
