import React, { useState, createContext, useContext } from "react";
import { useGlobalContext } from "./GlobalDataProvider";
import { useVaultContext } from "./VaultContextProvider";
import { decryptTimeline, mapMember } from "../helpers/decrypt-helper";
import { paginatedTimelineQuery } from "../graphql/queries/utils";
import { memoActionRef, memoActionRefs } from "../helpers/akord-enums.js";
import { getDataRoomId, isActionFiltered } from "../helpers/helpers";
const Context = createContext();

function TimelineContextProvider({ children }) {
  const {
    onDecrptSpinner,
    onEncrypterFromMembership,
    decryptedMemberships,
    assetsUpdatedHeight,
    onAssetsUpdatedHeight
  } = useGlobalContext();

  const { newTimelineRef } = useVaultContext();
  const [decryptedTimeline, setDecryptedTimeline] = useState([]);
  const handleDecryptedTimeline = membership =>
    setDecryptedTimeline(membership);

  const dataRoomId = getDataRoomId(location.pathname);

  const currentMembership = decryptedMemberships?.filter(
    membership => membership?.dataRoomId === dataRoomId
  )[0];

  React.useEffect(() => {
    if (decryptedMemberships && Object.keys(decryptedMemberships).length > 0) {
      fetchAndDecryptTimeline();
    }
  }, [decryptedMemberships, dataRoomId]);

  // Clear timeline on page change
  React.useEffect(() => {
    return () => {
      if (!newTimelineRef.current) {
        handleDecryptedTimeline([]);
        newTimelineRef.current = true;
      }
    };
  }, [dataRoomId]);

  React.useEffect(() => {
    if (currentMembership && Object.keys(currentMembership).length === 0)
      return;

    if (assetsUpdatedHeight) {
      onAssetsUpdatedHeight(null);
      fetchAndDecryptTimeline(true);
    }
  }, [assetsUpdatedHeight]);

  const fetchAndDecryptTimeline = async (isSub = false) => {
    if (
      (decryptedMemberships &&
        Object.keys(decryptedMemberships).length === 0) ||
      (!isSub && !newTimelineRef.current)
    )
      return;

    onDecrptSpinner(true);

    const encrypter = onEncrypterFromMembership(currentMembership);

    const decryptedAvatarsMap = new Map();
    const decryptedMembers = await Promise.all(
      currentMembership.dataRoom.members.items.map(
        async member => await mapMember(member, encrypter, decryptedAvatarsMap)
      )
    );

    const membersMap =
      currentMembership?.dataRoom?.members &&
      new Map(
        decryptedMembers.map(member => {
          return [member.memberPublicSigningKey, member.state.memberDetails];
        })
      );

    const timelineItems = await paginatedTimelineQuery(dataRoomId);
    // Filter items which are not being shown before decryption
    const filteredTimelineItems = timelineItems.filter(
      item => !isActionFiltered(item)
    );
    const items = processTimelineItems(timelineItems, filteredTimelineItems);

    const decryptedTimelineArray = await decryptTimeline(
      encrypter,
      items,
      membersMap,
      {
        dataRoomId: currentMembership.dataRoom.id,
        dataRoomName: currentMembership.dataRoom.state.title,
        dataRoomStatus: currentMembership.dataRoom.state.status
      }
    );

    newTimelineRef.current = false;
    handleDecryptedTimeline(decryptedTimelineArray);
    onDecrptSpinner(false); //hide tx spinner
  };

  const processTimelineItems = (timelineItems, filteredItems) => {
    let items = filteredItems;
    items = processMemoReactions(timelineItems, filteredItems);
    return items;
  };

  const processMemoReactions = (timelineItems, filteredItems) => {
    const filteredItemsMap = getMemoTransactionsMap(filteredItems);
    const reactions = getMemoReactions(timelineItems);
    if (reactions && reactions.length) {
      reactions.forEach(reactionTransaction => {
        const memo = filteredItemsMap.get(reactionTransaction.modelId);
        if (memo) {
          if (!memo.reactions) {
            memo.reactions = [];
          }
          reactionTransaction.memo.reactions.forEach(reaction => {
            if (
              !memo.reactions.some(memoReaction => memoReaction === reaction)
            ) {
              memo.reactions.push(reaction);
              memo.hash = reactionTransaction.hash;
            }
          });
        }
      });
    }
    return Array.from(filteredItemsMap.values());
  };

  const getMemoReactions = timelineItems => {
    timelineItems.sort(
      (transationA, transationB) =>
        new Date(transationA.postedAt) - new Date(transationB.postedAt)
    );
    const activeReactions = new Map();
    timelineItems.forEach(item => {
      if (
        item.actionRef === memoActionRef.MEMO_ADD_REACTION ||
        item.actionRef === memoActionRef.MEMO_REMOVE_REACTION
      ) {
        activeReactions.set(item.modelId, item);
      }
    });
    return Array.from(activeReactions.values());
  };

  const getMemoTransactionsMap = timelineItems => {
    const items = new Map();
    timelineItems.forEach(item => {
      if (memoActionRefs.includes(item.actionRef)) {
        items.set(item.modelId, item);
      } else {
        items.set(item.hash, item);
      }
    });
    return items;
  };

  return (
    <Context.Provider
      value={{
        decryptedTimeline: decryptedTimeline,
        onDecryptedTimeline: handleDecryptedTimeline
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default TimelineContextProvider;

export const withTimelineContext = Component => props =>
  (
    <Context.Consumer>
      {timelineContext => <Component {...props} {...timelineContext} />}
    </Context.Consumer>
  );

export const useTimelineContext = () => useContext(Context);
