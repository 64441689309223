import React from "react";
import { Typography } from "@mui/material";
import OperationTitleWithIcon from "../../../components/common/OperationTitle/OperationTitleWithIcon";

function FolderRestore(props) {
  const { positionedBeforeDate, operation, color } = props;

  return (
    <>
      <OperationTitleWithIcon
        actionRef={operation.actionRef}
        color={color}
        title="Folder restored"
      />
      <div
        style={{
          marginBottom: positionedBeforeDate ? "74px" : "16px",
          display: "flex"
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          className="small"
          noWrap
          style={{ whiteSpace: "pre" }}
        >
          {operation?.folder?.title}
        </Typography>
      </div>
    </>
  );
}

export default FolderRestore;
