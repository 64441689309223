import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { convertToRaw } from "draft-js";
import MUIRichTextEditor from "mui-rte";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import {
  ButtonGroup,
  ErrorAlert,
  SupportScreenWrapper
} from "../../components/common";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { dataRoomCreate } from "../../helpers/akord-actions";
import { useSnackbarContext } from "../../contexts/SnackbarContextProvider";
import { rteAkordTheme, rteStyleMap } from "./mui-rte-styles";

function CreateRoom({ isPublic = false }) {
  const [error, setError] = useState();
  const [formData, setFormData] = useState({
    roomTitle: "",
    roomTerms: "",
    hasTerms: false,
    isPublic: isPublic
  });
  // button loading state
  const [loading, setLoading] = useState(false);

  const { wallet, isMobile, decryptedProfileDetails, darkMode } =
    useGlobalContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const history = useHistory();
  const handleFormChange = prop => event => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setFormData({
      ...formData,
      [prop]: value
    });
  };

  const handleRTE = state => {
    setFormData({
      ...formData,
      roomTerms: JSON.stringify(convertToRaw(state.getCurrentContent())),
      hasTerms: state.getCurrentContent().hasText()
    });
  };

  const isButtonDisabled = () => {
    return formData.roomTitle.length > 0 && !loading ? false : true;
  };

  const handleCreateRoom = async () => {
    try {
      setLoading(true);
      await dataRoomCreate(wallet, formData, decryptedProfileDetails);
      // const dataRoomId = data?.postLedgerTransaction[0]?.dataRoomId;
      setLoading(false);
      onSnackbarToShow("dataRoomCreate");
      history.push(`/`);
    } catch (err) {
      setError(err);
      console.log(err);
    }
  };

  return (
    <SupportScreenWrapper title="Create new vault" hideChevron spaceBetween>
      <Box
        height={isMobile ? "calc(100% - 96px)" : "auto"}
        display="flex"
        flexDirection="column"
        justifyContent={isMobile ? "space-between" : "flex-start"}
      >
        <Box>
          <Box mb={7}>
            <FormControl error={!!error} variant="outlined" fullWidth>
              <InputLabel htmlFor="enter-title">Title</InputLabel>
              <OutlinedInput
                id="enter-title"
                name="roomTitle"
                type="text"
                label="Title"
                value={formData.roomTitle}
                onChange={handleFormChange("roomTitle")}
              />
              {error && <ErrorAlert getErrorText={error.message} />}
            </FormControl>
          </Box>
          {!isPublic && (
            <>
              <Box mb={2}>
                <Typography
                  variant="body2"
                  className="strong"
                  color="text.primary"
                >
                  Terms of access (optional)
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  If the vault is intended for professional or legal use, you
                  can add terms of access and they must be digitally signed
                  before accessing the vault.
                </Typography>
              </Box>
              <Box>
                <ThemeProvider theme={rteAkordTheme(darkMode)}>
                  <MUIRichTextEditor
                    draftEditorProps={{ customStyleMap: rteStyleMap }}
                    toolbarButtonSize="small"
                    customStyleMap={rteStyleMap}
                    controls={[
                      "title",
                      "bold",
                      "italic",
                      "underline",
                      "link",
                      "numberList",
                      "bulletList",
                      "clear"
                    ]}
                    onChange={handleRTE}
                    label="Add your terms of access here...."
                  />
                </ThemeProvider>
              </Box>
            </>
          )}
        </Box>
        <ButtonGroup
          nextDiasabled={isButtonDisabled()}
          handleEnd={() => handleCreateRoom()}
          handleBack={() => history.goBack()}
          loading={loading}
          hideIcon
          nextText="Create vault"
        />
      </Box>
    </SupportScreenWrapper>
  );
}

export default withRouter(CreateRoom);
