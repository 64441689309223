import { AkordWallet } from "@akord/crypto";
import { Auth } from "aws-amplify";
import { akordErrors } from "../../helpers/akordTexts";

const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
// no spaces first/last char
const regexPassword = /^[\S]+.*[\S]+$/;

export const stepsData = {
  signup: {
    isButtonDisabled: (formData, passEvaluation) => {
      return !(
        formData.userEmail.length > 0 &&
        regexEmail.test(formData.userEmail) &&
        formData.userPassword.length >= 12 &&
        regexPassword.test(formData.userPassword) &&
        passEvaluation.score >= 4 &&
        formData.acceptTerms &&
        formData.withdrawalRight
      );
    },
    handleNext: async ({
      formData,
      handleError,
      handleWallet,
      steps,
      currentStep,
      path,
      props
    }) => {
      try {
        const res = await AkordWallet.create(formData.userPassword.trim());
        handleWallet(res);
        nextPage(steps, currentStep, path, props);
      } catch (err) {
        handleError(err);
      }
    },
    handleBack: props => props.history.goBack(),
    nextText: "Next",
    inverted: false,
    hideBackButton: false,
    fullWidthNextButton: false,
    hideIcon: false,
    showBottomNav: true,
    darkMode: true
  },
  "account-backup": {
    isButtonDisabled: formData => {
      return !(
        formData.userEmail.length > 0 &&
        // formData.userPassword === formData.confirmUserPassword &&
        formData.userPassword.length >= 12 &&
        formData.acceptTerms &&
        formData.acceptPhrase
      );
    },
    handleNext: ({ steps, currentStep, path, props }) =>
      nextPage(steps, currentStep, path, props),
    handleBack: props => props.history.goBack(),
    nextText: "Next",
    inverted: false,
    hideBackButton: false,
    fullWidthNextButton: false,
    hideIcon: false,
    showBottomNav: true,
    darkMode: true
  },
  "backup-phrase": {
    isButtonDisabled: () => false,
    handleNext: ({ steps, currentStep, path, props }) =>
      nextPage(steps, currentStep, path, props),
    handleBack: props => props.history.goBack(),
    spaceBetween: null,
    darkMode: true,
    nextText: "Next",
    inverted: false,
    hideBackButton: true,
    fullWidthNextButton: true,
    hideIcon: false,
    showBottomNav: true
  },
  verification: {
    isButtonDisabled: () => true,
    handleNext: async ({
      formData,
      handleError,
      steps,
      currentStep,
      path,
      wallet,
      props,
      missingWords
    }) => {
      try {
        const username = formData.userEmail.toLowerCase().trim();
        const password = formData.userPassword.trim();
        const email = formData.userEmail.toLowerCase().trim();
        const { host, protocol } = window.location;
        const verifyUrl = `${protocol}//${host}/sign-in`;

        const backupPhraseArray = wallet.backupPhrase.split(" ");
        let wordsMatch = true;

        Object.keys(missingWords).forEach(missingWordsIndex => {
          if (
            backupPhraseArray[missingWordsIndex] !==
            missingWords[missingWordsIndex]
          )
            wordsMatch = false;
        });

        if (wordsMatch) {
          await Auth.signUp({
            username,
            password,
            clientMetadata: { verifyUrl },
            attributes: {
              email,
              "custom:encBackupPhrase": wallet.encBackupPhrase,
              "custom:publicKey": await wallet.publicKey(),
              "custom:publicSigningKey": await wallet.signingPublicKey(),
              "custom:mode": "dark",
              "custom:notifications": "true",
              "custom:referrerId": formData.referrerId
            }
          });
          nextPage(steps, currentStep, path, props);
        } else
          handleError({
            message: akordErrors.backupPhrase
          });
      } catch (err) {
        handleError(err);
      }
    },
    handleBack: props => props.history.goBack(),
    nextText: "Finish backup",
    inverted: false,
    hideBackButton: false,
    fullWidthNextButton: false,
    hideIcon: false,
    showBottomNav: true,
    darkMode: true
  },
  "account-created": {
    isButtonDisabled: () => null,
    handleNext: () => null,
    handleBack: props => props.history.goBack(),
    handleEnd: props => props.history.push("/"),
    inverted: true,
    hideBackButton: true,
    fullWidthNextButton: true,
    hideIcon: true,
    showBottomNav: false,
    darkMode: true
  },
  "backup-info": {
    isButtonDisabled: () => null,
    inverted: false,
    hideIcon: false,
    showBottomNav: false,
    darkMode: true
  }
};

const nextPage = (steps, currentStep, path, props) => {
  const nextStepIndex = steps.indexOf(currentStep) + 1;
  const routeName = steps[nextStepIndex];
  props.history.push(`${path}/${routeName}`);
};
