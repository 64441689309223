import React from "react";
import {
  getFolderId,
  // getUserRole,
  isArchived,
  getDataRoomId,
  getDataRoomStatus
} from "../../../helpers/helpers";

// import { roomMenuOptions } from '../MoreMenu/room-menu-options'
// import { useGlobalContext } from '../../../contexts/GlobalDataProvider'
import { useVaultContext } from "../../../contexts/VaultContextProvider";

import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import DrawerRoot from "./MoreDrawerRoot";
import MoreDrawerItem from "./MoreDrawerItem";
import { useLocation, useHistory } from "react-router";
import {
  noteMenuActiveOptions,
  noteMenuRevokedOptions
} from "../MoreMenu/note-menu-options";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";

function NoteDrawer({ openDrawer, handleMenuClose, revoked, note }) {
  // const { decryptedProfileDetails } = useGlobalContext()
  const { modal, onNotificationData } = useNotificationsContext();
  const { decryptedFolders } = useAssetsContext();
  const { userRole, currentMembership, isVaultPublic } = useVaultContext();
  const history = useHistory();
  const location = useLocation();
  const folderId = getFolderId(location.pathname);
  const roomId = getDataRoomId(location.pathname);
  const roomStatus = getDataRoomStatus(location.pathname);

  // const userRole = getUserRole(
  //   currentMembership ? currentMembership : [],
  //   decryptedProfileDetails
  // )

  const isRoomArchived = isArchived(currentMembership);

  if (!currentMembership) return null;
  const noteMenuOptions = revoked
    ? noteMenuRevokedOptions
    : noteMenuActiveOptions;

  return (
    <DrawerRoot
      header={currentMembership?.dataRoom?.state?.title}
      openDrawer={openDrawer}
      handleMenuClose={handleMenuClose}
      isRoomArchived={isRoomArchived}
      currentMembership={currentMembership}
    >
      {noteMenuOptions(
        history,
        handleMenuClose,
        folderId,
        roomId,
        roomStatus,
        note,
        modal.onModalVisibility,
        modal.onConfirmModalType,
        onNotificationData,
        decryptedFolders,
        userRole,
        isRoomArchived,
        isVaultPublic
      ).map(
        (menuItem, index) =>
          menuItem.show && (
            <MoreDrawerItem
              key={index}
              menuItem={menuItem}
              handleMenuClose={handleMenuClose}
            />
          )
      )}
    </DrawerRoot>
  );
}

export default NoteDrawer;
