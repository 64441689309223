import { TableCell, TableRow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FolderDrawer, RoomDrawer, StackDrawer } from "../../components/common";
import MoreActionButton from "../../components/common/MoreActionButton";
import MenuFolder from "../../components/common/MoreMenu/MenuFolder";
import MenuRoom from "../../components/common/MoreMenu/MenuRoom";
import MenuStack from "../../components/common/MoreMenu/MenuStack";
import {
  ImageIcon,
  DocumentIcon,
  VaultIcon,
  FolderIcon,
  StackIcon,
  MessageIcon
} from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { searchResultType } from "./search-result-type";

const useStyles = makeStyles(theme => ({
  stackIconBackGround: {
    background: ({ isActive }) =>
      isActive
        ? theme.palette.background.stack
        : theme.palette.background.archived,
    boxSizing: "content-box",
    padding: "3px",
    color: ({ isActive }) =>
      isActive
        ? theme.palette.info.secondary
        : theme.palette.secondary.disabled,
    marginRight: "15px"
  },
  dataRoomIconBackGround: {
    background: ({ isActive }) =>
      isActive
        ? theme.palette.background.active
        : theme.palette.background.archived,
    boxSizing: "content-box",
    padding: "3px",
    marginRight: theme.spacing(4)
  },
  folderIconBackGround: {
    boxSizing: "content-box",
    color: ({ isActive }) =>
      isActive ? "#8B7872" : theme.palette.secondary.disabled,
    marginRight: "15px"
  },
  messageIconBackGround: {
    boxSizing: "content-box",
    color: "#A167FF",
    background: ({ isDarkModeEnabled }) =>
      isDarkModeEnabled ? "#39078A" : "#F0E7FE",
    padding: "3px",
    marginRight: "15px"
  }
}));

function SearchResult(props) {
  const { document } = props;
  const history = useHistory();
  const { width, darkMode, onMembershipByDataRoomId } = useGlobalContext();
  const { onCurrentMembership, onCurrentStack } = useVaultContext();

  const [membership, setMembership] = React.useState(null);
  const [stack, setStack] = React.useState(null);
  const [menuActive, setMenuActive] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const classes = useStyles({
    isActive: document.isActive,
    isDarkModeEnabled: darkMode,
    menuActive: menuActive
  });

  useEffect(() => {
    const membershipFromSourceId = onMembershipByDataRoomId(document.sourceId);
    if (membershipFromSourceId) {
      setMembership(membershipFromSourceId);
      if (searchResultType.isStackType(document.type)) {
        const stack = membershipFromSourceId.dataRoom.stacks?.items?.find(
          stack => stack.id === document.id
        );
        if (stack) {
          setStack(stack);
        }
      } else if (document.type === "FOLDER") {
        const stack = membershipFromSourceId.dataRoom.folders?.items?.find(
          folder => folder.id === document.id
        );
        if (stack) {
          setStack(stack);
        }
      }
    }
  }, []);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
    setMenuActive(true);
    onCurrentMembership(membership);
    if (stack) {
      onCurrentStack(stack);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuActive(false);
  };

  const icon = (type, isActive) => {
    switch (type) {
      case "DATA_ROOM":
        return (
          <VaultIcon
            fontSize="default"
            color={isActive ? "action" : "disabled"}
            className={classes.dataRoomIconBackGround}
          />
        );
      case "FOLDER":
        return (
          <FolderIcon
            fontSize="default"
            color={isActive ? "action" : "disabled"}
            className={classes.folderIconBackGround}
          />
        );
      case "STACK":
        return (
          <StackIcon
            fontSize="medium"
            className={classes.stackIconBackGround}
          />
        );
      case "STACK_DOCUMENT":
        return (
          <DocumentIcon
            fontSize="medium"
            className={classes.stackIconBackGround}
          />
        );
      case "STACK_IMAGE":
        return (
          <ImageIcon
            fontSize="medium"
            className={classes.stackIconBackGround}
          />
        );
      case "MEMO":
        return (
          <MessageIcon
            fontSize="medium"
            className={classes.messageIconBackGround}
          />
        );
    }
  };

  const menu = (type, isActive) => {
    switch (type) {
      case "DATA_ROOM":
        if (width !== "xs") {
          return (
            <MenuRoom
              anchorEl={anchorEl}
              openMenu={openMenu}
              handleMenuClose={handleMenuClose}
              currentMembership={membership}
              history={history}
            />
          );
        } else {
          return (
            <RoomDrawer
              currentMembership={membership}
              openDrawer={openMenu}
              handleMenuClose={handleMenuClose}
              history={history}
            />
          );
        }
      case "STACK":
      case "STACK_DOCUMENT":
      case "STACK_IMAGE":
        if (width !== "xs") {
          return (
            <MenuStack
              anchorEl={anchorEl}
              openMenu={openMenu}
              handleMenuClose={handleMenuClose}
              revokedStack={!isActive}
            />
          );
        } else {
          return (
            <StackDrawer
              openDrawer={openMenu}
              handleMenuClose={handleMenuClose}
              revokedStack={!isActive}
            />
          );
        }
      case "FOLDER":
        if (width !== "xs") {
          return (
            <MenuFolder
              anchorEl={anchorEl}
              openMenu={openMenu}
              handleMenuClose={handleMenuClose}
              revokedFolder={!isActive}
            />
          );
        } else {
          return (
            <FolderDrawer
              openDrawer={openMenu}
              handleMenuClose={handleMenuClose}
              revokedFolder={!isActive}
            />
          );
        }
    }
  };

  const decorate = searchResult => {
    searchResult.terms.forEach(matchedTerm => {
      searchResult.name = searchResult.name
        .split(matchedTerm)
        .join(`<b>${matchedTerm}</b>`);
    });
    return searchResult.name;
  };

  const isMoreActionsShown = type => {
    switch (type) {
      case "MEMO":
        return false;
      default:
        return true;
    }
  };

  return (
    <>
      <TableRow hover>
        <TableCell
          size="medium"
          style={{
            cursor: "pointer",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
          }}
          scope="row"
          onClick={() =>
            history.push({
              pathname: document.sourceUrl,
              state: { itemId: document.id }
            })
          }
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {icon(document.type, document.isActive)}
            <div>
              <div style={{ display: "grid" }}>
                <Typography
                  variant="body2"
                  color="text.primary"
                  noWrap
                  className={classes.item}
                  dangerouslySetInnerHTML={{ __html: decorate(document) }}
                ></Typography>
              </div>
              {document.sourceName && (
                <div>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    component="span"
                    className={classes.source}
                  >
                    {document.sourceName}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </TableCell>
        <TableCell align="right" size="medium">
          <MoreActionButton
            handleClick={handleMenu}
            menuActive={menuActive}
            hidden={!isMoreActionsShown(document.type)}
          />
        </TableCell>
      </TableRow>
      {menu(document.type, document.isActive)}
    </>
  );
}

export default SearchResult;
