import { fileDownload } from '../../../helpers/akord-actions'

export const handlePrint = fileViewerData => {
  let windowToPrint
  if (fileViewerData.fileType === 'docx') {
    const docxHTML = document.getElementById('docx').innerHTML
    windowToPrint = window.open('', fileViewerData.title)
    windowToPrint.document.write(
      `<html>
            <head>
              <style>
                @page {
                  margin: 1in;
                }

                @media print {
                  body {
                    margin: 0;
                  }
                }
              </style>
          </head>
          <body>
            <div style="margin: 50px">${docxHTML}</div>
          </body>
        </html>`
    )
    windowToPrint.document.close()
  } else {
    windowToPrint = window.open(fileViewerData.filePath, fileViewerData.title)
  }

  windowToPrint.focus()
  windowToPrint.print()
  windowToPrint.onafterprint = () => windowToPrint.close()
}

export const downloadSelectedFile = (
  activeStack,
  encrypter,
  fileViewerData,
  onUploadStart,
  onDrawerActionType,
  onUploadHook
) => {
  const download = fileDownload(
    activeStack,
    encrypter,
    null,
    null,
    null,
    fileViewerData.originalIndex,
    null,
    onUploadHook
  )
  onUploadStart(download)
  onDrawerActionType('download')
}

export const getCorrectExtForVersion = (index, activeStack) => {
  if (!activeStack.title) return ''
  const originalName = activeStack.title.split('.')
  originalName.pop()
  const originalTitleArray = activeStack.items[index].title.split('.')
  const originalExtension = originalTitleArray[originalTitleArray.length - 1]
  return originalName.join('.') + '.' + originalExtension
}
