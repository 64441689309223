import React, { useState, useEffect } from "react";
import { Box, Typography, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { EmptySpace, SupportScreenWrapper } from "../../components/common/";
import { useInAppNotificationsContext } from "../../contexts/InAppNotificationsContext";
import LoadingNotification from "./LoadingNotification";
import LogNotification from "./LogNotification";
import API, { graphqlOperation } from "@aws-amplify/api";
import { Auth } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import { grey } from "../../theme/colors/index";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";

const notificationsOnPage = 10;

const useStyles = makeStyles({
  mainBox: {
    border: "1px solid #312F37",
    borderColor: ({ darkMode }) => (darkMode ? grey[800] : grey[200]),
    borderRadius: 4
  }
});

function Notifications() {
  const { notificationsLog } = useInAppNotificationsContext();
  const { darkMode, isMobile } = useGlobalContext();

  const classes = useStyles({ darkMode: darkMode });

  const [logsToShow, setLogsToShow] = useState(notificationsOnPage);
  const handleLogsToShow = () =>
    setLogsToShow(prevState => prevState + notificationsOnPage);

  //set all notifications to "READ" on  unmount
  useEffect(() => {
    return async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          await API.graphql(
            graphqlOperation(mutations.updateNotificationStatus, {
              operation: "MARK_ALL_AS_READ",
              ids: []
            })
          );
        }
      } catch (err) {
        console.log(err);
      }
    };
  }, []);

  return (
    <SupportScreenWrapper title="Notifications" hideChevron showMenu>
      {!notificationsLog ? (
        Array.from(Array(10).keys()).map((item, index) => (
          <LoadingNotification key={index} />
        ))
      ) : notificationsLog.length ? (
        <Box className={classes.mainBox}>
          {notificationsLog.slice(0, logsToShow).map((log, index) => (
            <LogNotification
              transaction={log}
              key={index}
              lastToShow={
                notificationsLog.slice(0, logsToShow).length === index + 1
              }
            />
          ))}
        </Box>
      ) : (
        <EmptySpace dataText="notifications" />
      )}
      <Box pt={5} pb={isMobile ? 10 : 5} display="flex" justifyContent="center">
        {logsToShow < notificationsLog?.length && (
          <Typography variant="body2" className="small">
            <Link
              onClick={() => handleLogsToShow()}
              underline="always"
              color="text.secondary"
              // color="initial"
            >
              Load more...
            </Link>
          </Typography>
        )}
      </Box>
    </SupportScreenWrapper>
  );
}

export default Notifications;
