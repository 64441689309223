import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Typography,
  Grid,
  TextField,
  InputAdornment
} from "@mui/material";
import { AccountBackupIcon } from "@akord/addon-icons";
import { useSignupContext } from "../../contexts/SignupContext";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    color: "white"
  },
  rootWithIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  gridRoot: {
    marginTop: theme.spacing(4)
  },
  xsGrid: {
    flexBasis: "33%",
    maxWidth: "33%"
  },
  buttonGrid: {
    margin: 0
  },
  inputAdornment: {
    fontSize: "10px",
    alignItems: "flex-end",
    position: "absolute",
    paddingLeft: "8px"
  },
  inputLabel: {
    fontFamily: [
      "Larsseit-Bold",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen-Sans",
      "Ubuntu",
      "Cantarell",
      "Helvetica Neue",
      "sans-serif"
    ].join(","),
    textAlign: "center",
    padding: "12px 14px",
    WebkitTextFillColor: "inherit!important"
  },
  inputAdornedStart: {
    paddingLeft: 0
  },
  disabled: {
    background: "transparent!important",
    color: `${theme.palette.text.primary}!important`
  },
  notchedOutline: {
    borderColor: `${theme.palette.primary.darkest}!important`
  },
  notchedOutlineActive: {
    borderColor: `${theme.palette.primary.dark}!important`
  },
  notchedOutlineError: {
    borderColor: `${theme.palette.error.main}!important`
  },
  rootInput: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.primary.main}!important`
    },
    "&.Mui-focused fieldset": {
      borderColor: `${theme.palette.primary.main}!important`
    }
  }
}));

function Verify({ setActivateButton }) {
  const classes = useStyles();
  const history = useHistory();
  const { wallet, onMissingWords, missingWords, error } = useSignupContext();

  const [confirmPhrase, setConfirmPhrase] = useState([]);

  if (!wallet?.backupPhrase) {
    history.push("/signup");
    return;
  }

  const isNextDisable = useMemo(() => {
    if (Object.keys(missingWords).length === 2) return false;
    else return true;
  }, [missingWords, wallet.backupPhrase]);

  useEffect(() => {
    setActivateButton(isNextDisable);
  }, [isNextDisable, setActivateButton]);

  let randomNumbers = [];
  useEffect(() => {
    const hideRandomWordsArray = () => {
      let backupPhraseToShow = wallet?.backupPhrase.split(" ");
      while (randomNumbers.length < 2) {
        const randomNumber = Math.floor(Math.random() * 12);
        if (randomNumbers.indexOf(randomNumber) === -1) {
          randomNumbers.push(randomNumber);
          backupPhraseToShow[randomNumber] = "";
        }
      }
      setConfirmPhrase(backupPhraseToShow);
    };
    hideRandomWordsArray();
  }, [wallet.backupPhrase]);

  if (!wallet.backupPhrase) history.push("/signup");

  const handleMissingWords = (event, index) => {
    onMissingWords({
      ...missingWords,
      [index]: event.target.value
    });
  };

  return (
    <>
      <Box className={classes.root}>
        <Box mb={3} className={classes.rootWithIcon}>
          <Typography variant="h1">Verification</Typography>
          <AccountBackupIcon fontSize="large" color="primary" />
        </Box>
        <Box mb={6}>
          <Typography variant="body2">
            Fill in the missing words from your backup phrase.
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            className={classes.gridRoot}
          >
            {confirmPhrase.map((word, index) => {
              return (
                <Grid item xs={3} key={index} className={classes.xsGrid}>
                  <TextField
                    disabled={word !== ""}
                    id={"outlined-basic-" + index}
                    variant="outlined"
                    className={classes.buttonGrid}
                    defaultValue={word}
                    onChange={e => handleMissingWords(e, index)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.inputAdornment}
                        >
                          {index + 1}
                        </InputAdornment>
                      ),
                      classes: {
                        root: word === "" ? classes.rootInput : "",
                        input: classes.inputLabel,
                        adornedStart: classes.inputAdornedStart,
                        disabled: classes.disabled,
                        notchedOutline:
                          word !== ""
                            ? classes.notchedOutline
                            : error
                            ? classes.notchedOutlineError
                            : classes.notchedOutlineActive
                      }
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default Verify;
