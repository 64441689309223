import React from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Box, Radio, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ButtonGroup, SupportScreenWrapper } from "../../components/common";
import {
  GlobeIcon,
  PadlockIcon,
  RadioEmptyIcon,
  RadioCheckedIcon
} from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";

const useStyles = makeStyles(theme => ({
  box: {
    border: "1px solid",
    borderColor: theme.palette.background.cardBorder,
    borderRadius: "8px",
    padding: "16px",
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "16px",
    cursor: "pointer"
  },
  badgeBox: {
    border: "1px solid #FB814B",
    borderRadius: "4px",
    padding: "0 6px"
  },
  radio: {
    marginTop: "4px",
    padding: "0 16px 0 0",
    "&:hover": {
      backgroundColor: "transparent"
    },
    "&.Mui-checked:hover": {
      backgroundColor: ({ outlined }) => (outlined ? "inherit" : "transparent")
    }
  }
}));

function CreateVault() {
  const [selectedValue, setSelectedValue] = React.useState();
  const classes = useStyles();
  const history = useHistory();
  const { isMobile } = useGlobalContext();

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  const createVaultRedirect = () => {
    selectedValue === "Encrypted"
      ? history.push("/create-vault/encrypted")
      : history.push("/create-vault/public");
  };

  return (
    <SupportScreenWrapper title="Create new vault" hideChevron spaceBetween>
      <Box
        height={isMobile ? "calc(100% - 96px)" : "auto"}
        display="flex"
        flexDirection="column"
        justifyContent={isMobile ? "space-between" : "flex-start"}
      >
        <Box>
          <Box
            className={classes.box}
            backgroundColor={
              selectedValue === "Encrypted" ? "background.card" : "transparent"
            }
            onClick={() => handleChange({ target: { value: "Encrypted" } })}
          >
            <Radio
              checked={selectedValue === "Encrypted"}
              onChange={handleChange}
              value="Encrypted"
              size="small"
              icon={<RadioEmptyIcon />}
              checkedIcon={<RadioCheckedIcon />}
              className={classes.radio}
              disableRipple
              disableFocusRipple
              name="radio-buttons"
              inputProps={{ "aria-label": "Encrypted" }}
            />
            <Box>
              <Box display="flex" marginBottom="8px">
                <Typography
                  variant="body2"
                  className="strong"
                  color="text.primary"
                >
                  Encrypted
                </Typography>
                <PadlockIcon fontSize="small" style={{ marginLeft: "4px" }} />
              </Box>
              <Typography
                variant="body2"
                className="small"
                color="text.secondary"
              >
                All data is end-to-end encrypted. Keep it personal and private,
                or invite others to share digital valuables in a secure space.{" "}
              </Typography>
            </Box>
          </Box>
          <Box
            backgroundColor={
              selectedValue === "Public" ? "background.card" : "transparent"
            }
            className={classes.box}
            onClick={() => handleChange({ target: { value: "Public" } })}
          >
            <Radio
              checked={selectedValue === "Public"}
              onChange={handleChange}
              value="Public"
              size="small"
              icon={<RadioEmptyIcon />}
              checkedIcon={<RadioCheckedIcon />}
              className={classes.radio}
              disableRipple
              disableFocusRipple
              name="radio-buttons"
              inputProps={{ "aria-label": "Public" }}
            />
            <Box>
              <Box display="flex" alignItems="center" marginBottom="8px">
                <Typography
                  variant="body2"
                  className="strong"
                  color="text.primary"
                >
                  Public
                </Typography>
                <GlobeIcon fontSize="small" style={{ margin: "0 8px 0 4px" }} />
                <Box className={classes.badgeBox}>
                  <Typography
                    variant="caption"
                    color="primary.light"
                    className={["strong", "small"].join(" ")}
                  >
                    Data is not encrypted
                  </Typography>
                </Box>
              </Box>
              <Typography
                variant="body2"
                className="small"
                color="text.secondary"
              >
                Data is sent to Arweave without encryption, and could be
                accessed by anyone. Do not choose if you have any concerns on
                sharing data publicly.
              </Typography>
            </Box>
          </Box>
        </Box>
        <ButtonGroup
          nextDiasabled={!selectedValue}
          handleBack={() => history.push("/vaults/active")}
          handleEnd={() => createVaultRedirect()}
          nextText="Next"
        />
      </Box>
    </SupportScreenWrapper>
  );
}

export default withRouter(CreateVault);
