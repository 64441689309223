import React from "react";
import { MenuItem, ListItemIcon, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { fileRevision } from "../../../helpers/akord-actions";
import { UploadForm } from "..";

const useStyles = makeStyles(theme => ({
  menuItem: {
    "&:hover": {
      "& $icon": {
        color: theme.palette.text.primary
      }
    }
  },
  icon: {
    transition: ".3s ease",
    marginBottom: "2px"
  }
}));

const ItemMenu = ({
  menuItem,
  handleMenuClose,
  onDrawerActionType,
  currentStack
}) => {
  const classes = useStyles();

  const MenuIcon = menuItem.icon;

  return (
    <MenuItem
      disableGutters
      disabled={menuItem.disabled}
      className={classes.menuItem}
      // component={menuItem.upload ? "label" : "li"}
      // htmlFor={menuItem.upload ? "upload-file" : null}
      onClick={menuItem.action}
    >
      {menuItem.upload ? (
        <label
          htmlFor="upload-file"
          style={{ display: "flex", cursor: "pointer" }}
        >
          <UploadForm
            actionFunc={fileRevision}
            currentStack={currentStack}
            handleMenuClose={handleMenuClose}
            onDrawerActionType={onDrawerActionType}
            multiple={false}
          />
          <ListItemIcon>
            <MenuIcon
              fontSize="small"
              color="disabled"
              className={classes.icon}
            />
          </ListItemIcon>
          <Typography variant="body2" color="text.primary">
            {menuItem.text}
          </Typography>
        </label>
      ) : (
        <>
          <ListItemIcon>
            <MenuIcon
              fontSize="small"
              color="disabled"
              className={classes.icon}
            />
          </ListItemIcon>
          <Typography variant="body2" color="text.primary">
            {menuItem.text}
          </Typography>
        </>
      )}

      {/* <ListItemIcon>
        <MenuIcon fontSize="small" color="disabled" className={classes.icon} />
      </ListItemIcon>
      <Typography variant="body2" color="text.primary">
        {menuItem.text}
      </Typography> */}
    </MenuItem>
  );
};

export default ItemMenu;
