import {
  RevokedFileIcon,
  AddNewVersionIcon,
  DownloadIcon,
  PenIcon,
  TrashIcon,
  FolderRenameOutlineIcon,
  MoveIcon,
  FolderRestoreIcon,
  FileRestoreIcon,
  FolderRevokeIcon,
  MinusInCircleIcon,
  ViewTransactionIcon,
  LinkIcon
} from "@akord/addon-icons";
import { fileDownload } from "../../../helpers/akord-actions";
import { akordLinks } from "../../../helpers/akordTexts";

const { host, protocol } = window.location;
const baseUrl = `${protocol}//${host}`;

export const stackActiveMenuOptions = (
  currentStack,
  encrypter,
  userRole,
  isRoomArchived,
  onShowLoader,
  onProgress,
  onSnackbarToShow,
  onAxiosError,
  onUploadStart,
  onDrawerActionType,
  onShowUpload,
  handleStackData,
  setVisibility,
  setConfirmType,
  handleMenuClose,
  setConfirmFormType,
  decryptedFolders,
  onUploadHook,
  isVaultPublic
) => [
  {
    text: "Download",
    altText: "Download latest version",
    icon: DownloadIcon,
    action: () => {
      const download = fileDownload(
        currentStack,
        encrypter,
        onShowLoader,
        onProgress,
        onAxiosError,
        //always download latest version
        currentStack.files.length - 1,
        onSnackbarToShow,
        onUploadHook,
        isVaultPublic
      );
      onUploadStart(download);
      onShowLoader(true);
      // onDrawerActionType('download')
      handleMenuClose();
    },
    disabled: false,
    show: true,
    upload: false
  },
  {
    text: "Add new version",
    icon: AddNewVersionIcon,
    action: () => {
      // All logic lives in UploadForm
      // onShowUpload(true)
      // onDrawerActionType('upload')
      // handleMenuClose()
    },
    disabled: userRole === "VIEWER" || isRoomArchived,
    show: true,
    upload: true
  },
  {
    text: "Rename",
    icon: PenIcon,
    action: () => {
      setConfirmFormType("fileRename");
      setVisibility(null, "fileRename");
      handleStackData({
        title: currentStack.title.split(".").slice(0, -1).join("."),
        extension: currentStack.title.split(".").slice(-1)[0],
        fileType: currentStack.files[0].fileType,
        hash: currentStack.hash
      });
      handleMenuClose();
    },
    disabled: userRole === "VIEWER" || isRoomArchived,
    show: true,
    upload: false
  },
  {
    text: "Move",
    icon: MoveIcon,
    action: () => {
      setConfirmType("fileMove");
      setVisibility(null, "fileMove");
      handleStackData({
        hash: currentStack.hash
      });
      handleMenuClose();
    },
    disabled: userRole === "VIEWER" || isRoomArchived,
    show: decryptedFolders?.length > 0,
    upload: false
  },
  {
    text: "Revoke",
    icon: RevokedFileIcon,
    action: () => {
      setConfirmType(
        currentStack.files.length > 1 ? "revokeStack" : "revokeFile"
      );
      setVisibility(
        null,
        currentStack.files.length > 1 ? "revokeStack" : "revokeFile"
      );
      handleStackData({
        title: currentStack.title.split(".").slice(0, -1).join("."),
        extension: currentStack.title.split(".").slice(-1)[0],
        fileType: currentStack.files[0].fileType,
        hash: currentStack.hash
      });
      handleMenuClose();
    },
    disabled: userRole === "VIEWER" || isRoomArchived,
    show: true,
    upload: false
  },
  {
    text: "View transaction",
    icon: ViewTransactionIcon,
    action: () => {
      const refId = currentStack.storageTransactions?.items?.find(
        tr => tr.refHash === currentStack.hash
      )?.refId;
      window.open(`${akordLinks.transactionBaseUrl}/${refId}`, "_blank");
      handleMenuClose();
    },
    disabled: userRole === "VIEWER" || isRoomArchived,
    show: true,
    upload: false
  },
  {
    text: "Share",
    icon: LinkIcon,
    action: () => {
      setConfirmFormType("shareFile");
      setVisibility(null, "shareFile");
      handleStackData({
        title: `${baseUrl}/public/${currentStack?.id}/${currentStack?.hash}`
      });
      handleMenuClose();
    },
    disabled: isRoomArchived,
    show: isVaultPublic,
    upload: false
  }
];

export const stackRevokedMenuOptions = (
  currentStack,
  encrypter,
  userRole,
  isRoomArchived,
  onShowLoader,
  onProgress,
  onSnackbarToShow,
  onAxiosError,
  onUploadStart,
  onDrawerActionType,
  onShowUpload,
  handleStackData,
  setVisibility,
  setConfirmType,
  handleMenuClose
) => [
  {
    text: "Restore",
    icon: FileRestoreIcon,
    action: () => {
      setConfirmType(
        currentStack.folderId
          ? "restoreFileWithMove"
          : currentStack.files.length > 1
          ? "restoreStack"
          : "restoreFile"
      );
      setVisibility(
        null,
        currentStack.folderId
          ? "restoreFileWithMove"
          : currentStack.files.length > 1
          ? "restoreStack"
          : "restoreFile"
      );
      handleStackData({
        title: currentStack.title.split(".").slice(0, -1).join("."),
        extension: currentStack.title.split(".").slice(-1)[0],
        fileType: currentStack.files[0].fileType,
        hash: currentStack.hash,
        folderId: currentStack.folderId ? currentStack.folderId : null
      });
      handleMenuClose();
    },
    disabled: userRole === "VIEWER" || isRoomArchived,
    show: true,
    upload: false
  },
  {
    text: "Remove",
    icon: MinusInCircleIcon,
    action: () => {
      setConfirmType("removeFile");
      setVisibility(null, "removeFile");
      handleStackData({
        title: currentStack.title.split(".").slice(0, -1).join("."),
        extension: currentStack.title.split(".").slice(-1)[0],
        fileType: currentStack.files[0].fileType,
        hash: currentStack.hash
      });
      handleMenuClose();
    },
    disabled: userRole === "VIEWER" || isRoomArchived,
    show: true,
    upload: false
  }
  // {
  //   text: 'Delete',
  //   icon: TrashIcon,
  //   action: () => {
  //     setConfirmType('deleteFile')
  //     setVisibility(null, 'deleteFile')
  //     handleStackData({
  //       title: currentStack.title.split('.').slice(0, -1).join('.'),
  //       extension: currentStack.title.split('.').slice(-1)[0],
  //       fileType: currentStack.files[0].fileType,
  //       hash: currentStack.hash
  //     })
  //     handleMenuClose()
  //   },
  //   disabled: userRole === 'VIEWER' || isRoomArchived,
  //   show: true,
  //   upload: false
  // }
];

export const folderActiveMenuOptions = (
  currentFolder,
  encrypter,
  userRole,
  isRoomArchived,
  onShowLoader,
  onProgress,
  onAxiosError,
  onUploadStart,
  onDrawerActionType,
  onShowUpload,
  handleFolderData,
  setVisibility,
  setConfirmType,
  handleMenuClose,
  setConfirmFormType
) => [
  {
    text: "Rename",
    icon: FolderRenameOutlineIcon,
    action: () => {
      setConfirmFormType("folderRename");
      setVisibility(null, "folderRename");
      handleFolderData({
        title: currentFolder.title,
        hash: currentFolder.hash
      });
      handleMenuClose();
    },
    disabled: userRole === "VIEWER" || isRoomArchived,
    show: true,
    upload: false
  },
  {
    text: "Move",
    icon: MoveIcon,
    action: () => {
      setConfirmType("folderMove");
      setVisibility(null, "folderMove");
      handleFolderData({
        id: currentFolder.id,
        title: currentFolder.title,
        hash: currentFolder.hash
      });
      handleMenuClose();
    },
    disabled: userRole === "VIEWER" || isRoomArchived,
    show: true,
    upload: false
  },
  {
    text: "Revoke",
    icon: FolderRevokeIcon,
    action: () => {
      setConfirmType("folderRevoke");
      setVisibility(null, "folderRevoke");
      handleFolderData({
        title: currentFolder.title,
        hash: currentFolder.hash
      });
      handleMenuClose();
    },
    disabled: userRole === "VIEWER" || isRoomArchived,
    show: true,
    upload: false
  }
];

export const folderRevokeMenuOptions = (
  currentStack,
  encrypter,
  userRole,
  isRoomArchived,
  onShowLoader,
  onProgress,
  onAxiosError,
  onUploadStart,
  onDrawerActionType,
  onShowUpload,
  handleStackData,
  setVisibility,
  setConfirmType,
  handleMenuClose
) => [
  {
    text: "Restore folder",
    icon: FolderRestoreIcon,
    action: () => {
      setConfirmType(
        currentStack.folderId ? "restoreFolderWithMove" : "folderRestore"
      );
      setVisibility(
        null,
        currentStack.folderId ? "restoreFolderWithMove" : "folderRestore"
      );
      handleStackData({
        title: currentStack.title,
        hash: currentStack.hash,
        folderId: currentStack.folderId
      });
      handleMenuClose();
    },
    disabled: userRole === "viewer" || isRoomArchived,
    show: true,
    upload: false
  },
  {
    text: "Delete folder",
    icon: TrashIcon,
    action: () => {
      setConfirmType("folderDelete");
      setVisibility(null, "folderDelete");
      handleStackData({
        title: currentStack.title,
        hash: currentStack.hash
      });
      handleMenuClose();
    },
    disabled: userRole === "VIEWER" || isRoomArchived,
    show: true,
    upload: false
  }
];
