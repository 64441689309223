import API, { graphqlOperation } from '@aws-amplify/api'
import { Storage } from 'aws-amplify'
import { KeysStructureEncrypter, fromMembership, fromProfileState } from '@akord/crypto'
import LedgerWrapper from '../crypto/ledger-wrapper'
import * as mutations from '../graphql/mutations'
import * as queries from '../graphql/queries'
import { memberStatusEnum } from './akord-enums'

export const resendInvite = async (ledgerWrapper, memberHash) => {
  try {
    const { encodedTransaction } = await ledgerWrapper.dispatch(
      'MEMBERSHIP_INVITE_RESEND',
      { prevHash: memberHash }
    )

    await API.graphql(
      graphqlOperation(mutations.postLedgerTransaction, {
        transactions: [encodedTransaction]
      })
    )
  } catch (error) {
    console.log('Resend Error: ', error)
  }
}

export const resendInviteNewUser = async (ledgerWrapper, memberHash) => {
  try {
    const { encodedTransaction } = await ledgerWrapper.dispatch(
      'MEMBERSHIP_INVITE_NEW_USER_RESEND',
      { prevHash: memberHash }
    )
    await API.graphql(
      graphqlOperation(mutations.postLedgerTransaction, {
        transactions: [encodedTransaction]
      })
    )
  } catch (error) {
    console.log('Resend Error: ', error)
  }
}

export const restoreAccess = async (
  ledgerWrapper,
  memberHash,
  termsOfAccess
) => {
  try {
    const { encodedTransaction } = await ledgerWrapper.dispatch(
      'MEMBERSHIP_RESTORE_ACCESS',
      { prevHash: memberHash },
      { termsOfAccess: termsOfAccess }
    )

    await API.graphql(
      graphqlOperation(mutations.postLedgerTransaction, {
        transactions: [encodedTransaction]
      })
    )
  } catch (error) {
    console.log('Resend Error: ', error)
  }
}

export const revokeInvite = async (
  ledgerWrapper,
  memberHash,
  currentMembership
) => {
  const memberToRevoke = currentMembership?.dataRoom.members.items.filter(
    member => member.hash === memberHash
  )[0]

  try {
    let transactions = []
    const revokeResult = await ledgerWrapper.dispatch('MEMBERSHIP_REVOKE', {
      prevHash: memberToRevoke.hash
    })
    transactions.push(revokeResult.encodedTransaction)

    if (ledgerWrapper.dataEncrypter instanceof KeysStructureEncrypter) {
      const { encodedTransaction, additionalData } =
        await ledgerWrapper.dispatch('DATAROOM_KEY_ROTATE', {
          prevHash: currentMembership.dataRoom.hash
        })
      transactions.push(encodedTransaction)

      let emails = []
      currentMembership?.dataRoom.members.items.map(async member => {
        if (
          member.memberPublicSigningKey &&
          memberToRevoke.state.memberDetails.publicSigningKey !==
            member.memberPublicSigningKey &&
          (member.status === memberStatusEnum.ACCEPTED ||
            member.status === memberStatusEnum.PENDING)
        ) {
          emails.push(member.state.memberDetails.email)
        }
      })

      if (emails.length > 0) {
        const results = await API.graphql(
          graphqlOperation(queries.preInviteCheck, {
            emails: emails,
            dataRoomId: currentMembership.dataRoomId
          })
        )
        const memberKeysPromises = results.data.preInviteCheck.map(
          async member => {
            const encryptionKeys = fromMembership(currentMembership)
            const memberLedgerWrapper = new LedgerWrapper(
              ledgerWrapper.wallet,
              encryptionKeys
            )
            memberLedgerWrapper.setKeysEncryptionPublicKey(member.publicKey)
            const { encodedTransaction } = await memberLedgerWrapper.dispatch(
              'MEMBERSHIP_KEY_ROTATE',
              { prevHash: member.membership.hash },
              {
                keyRotate: additionalData.newKeyPair
              }
            )
            transactions.push(encodedTransaction)
          }
        )
        await Promise.all(memberKeysPromises)
      }
    }

    await API.graphql(
      graphqlOperation(mutations.postLedgerTransaction, {
        transactions: transactions
      })
    )
  } catch (error) {
    console.log('Revoke Error: ', error)
  }
}

export const changeAccess = async (ledgerWrapper, member, groupRef) => {
  try {
    const { encodedTransaction } = await ledgerWrapper.dispatch(
      'MEMBERSHIP_CHANGE_ACCESS',
      { prevHash: member.hash, groupRef },
      { role: member.state.role }
    )

    await API.graphql(
      graphqlOperation(mutations.postLedgerTransaction, {
        transactions: [encodedTransaction]
      })
    )
  } catch (error) {
    console.log('Change Access Error: ', error)
  }
}

export const membershipAccept = async (
  wallet,
  membership,
  decryptedProfileDetails
) => {
  try {
    const encryptionKeys = fromMembership(membership)
    const ledgerWrapper = new LedgerWrapper(wallet, encryptionKeys)
    const { encodedTransaction, uploadPayload } = await ledgerWrapper.dispatch(
      'MEMBERSHIP_ACCEPT',
      { prevHash: membership.hash },
      {
        memberDetails: {
          fullName: decryptedProfileDetails.fullName,
          phone: decryptedProfileDetails.phone,
          avatarUrl: decryptedProfileDetails.avatarUrl
        }
      }
    )

    if (uploadPayload) {
      await Storage.put(uploadPayload.resourceKey, uploadPayload.encryptedFile)
    }

    await API.graphql(
      graphqlOperation(mutations.postLedgerTransaction, {
        transactions: [encodedTransaction]
      })
    )
  } catch (error) {
    console.log('Membership Accept: ', error)
  }
}

export const membershipReject = async (wallet, membership) => {
  try {
    const ledgerWrapper = new LedgerWrapper(wallet)
    const { encodedTransaction } = await ledgerWrapper.dispatch(
      'MEMBERSHIP_REJECT',
      { prevHash: membership.hash }
    )
    await API.graphql(
      graphqlOperation(mutations.postLedgerTransaction, {
        transactions: [encodedTransaction]
      })
    )
  } catch (error) {
    console.log('Membership Reject: ', error)
  }
}

export const membershipLeave = async (ledgerWrapper, membership) => {
  try {
    const { encodedTransaction } = await ledgerWrapper.dispatch(
      'MEMBERSHIP_LEAVE',
      { prevHash: membership.hash }
    )
    await API.graphql(
      graphqlOperation(mutations.postLedgerTransaction, {
        transactions: [encodedTransaction]
      })
    )
  } catch (error) {
    console.log('Membership Leave: ', error)
  }
}

export const membershipConfirm = async (
  ledgerWrapper,
  membership,
  currentMembership
) => {
  try {
    // making a call to get a publicKey
    const inviteResult = await API.graphql(
      graphqlOperation(queries.preInviteCheck, {
        emails: [membership.email],
        dataRoomId: membership.dataRoomId
      })
    )
    ledgerWrapper.setKeysEncryptionPublicKey(
      inviteResult.data.preInviteCheck[0].publicKey
    )

    const { encodedTransaction } = await ledgerWrapper.dispatch(
      'MEMBERSHIP_CONFIRM',
      { prevHash: membership.hash },
      {
        memberDetails: {
          publicSigningKey: inviteResult.data.preInviteCheck[0].publicSigningKey
        },
        termsOfAccess: currentMembership.dataRoom.state.termsOfAccess,
        memberKeys: []
      }
    )

    await API.graphql(
      graphqlOperation(mutations.postLedgerTransaction, {
        transactions: [encodedTransaction]
      })
    )
  } catch (error) {
    console.log('Membership Accept: ', error)
  }
}

export const updateProfile = async (
  wallet,
  fullProfile,
  profileForm,
  avatar,
  decryptedMemberships
) => {
  const encryptionKeys = fromProfileState(fullProfile.state)
  const ledgerWrapper = new LedgerWrapper(wallet, encryptionKeys)

  const { uploadPayload, encodedTransaction } = await ledgerWrapper.dispatch(
    'PROFILE_UPDATE',
    { prevHash: fullProfile.hash },
    {
      profileDetails: {
        fullName: profileForm.name,
        avatarUrl: avatar.croppedFile
      }
    }
  )

  if (uploadPayload) {
    await Storage.put(uploadPayload.resourceKey, uploadPayload.encryptedFile)
  }

  await API.graphql(
    graphqlOperation(mutations.postLedgerTransaction, {
      transactions: [encodedTransaction]
    })
  )

  const updatedMemberships = decryptedMemberships.map(async membership => {
    const memberEncryptionKeys = fromMembership(membership)
    const memberLedgerWrapper = new LedgerWrapper(wallet, memberEncryptionKeys)
    const { uploadPayload, encodedTransaction } =
      await memberLedgerWrapper.dispatch(
        'MEMBERSHIP_PROFILE_UPDATE',
        { prevHash: membership.hash },
        {
          memberDetails: {
            fullName: profileForm.name,
            avatarUrl: avatar.croppedFile
          }
        }
      )
    if (uploadPayload) {
      await Storage.put(uploadPayload.resourceKey, uploadPayload.encryptedFile)
    }

    await API.graphql(
      graphqlOperation(mutations.postLedgerTransaction, {
        transactions: [encodedTransaction]
      })
    )
  })
  await Promise.all(updatedMemberships)
}
