import React from 'react'
import { Skeleton } from '@mui/material';
import { Box } from '@mui/material'

function LoadingNotification() {
  return (
    <Box display="flex" mb={6}>
      <Box mr={4}>
        <Skeleton variant="circular" width={40} height={40} />
      </Box>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={40}
        style={{ borderRadius: 4 }}
      />
    </Box>
  );
}

export default LoadingNotification
