import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Container, Button, Box } from "@mui/material";
import { ArrowRightIcon } from "@akord/addon-icons";
import ErrorAlert from "../../components/common/ErrorAlert";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: ({ showButtonInColumn }) =>
      showButtonInColumn ? "column-reverse" : "row",
    justifyContent: "space-between",
    marginTop: ({ noMarginTop }) => (noMarginTop ? 0 : theme.spacing(7))
  },
  disabledRed: {
    backgroundColor: "#F5A896!important"
  },
  rootButton: {
    display: ({ hideIcon }) => (hideIcon ? "inherit" : "inline"),
    marginBottom: ({ showButtonInColumn }) =>
      showButtonInColumn ? "16px" : "0",
    minWidth: ({ noMinWidth }) => (noMinWidth ? "auto" : "228px")
  },
  endIcon: {
    float: "right",
    display: "inline",
    margin: "0"
  },
  buttonRoot: {
    background: "white",
    color: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: "white",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "white"
      }
    }
  },
  label: {
    display: ({ hideIcon }) => (hideIcon ? "inherit" : "inline")
  },
  buttonProgress: {
    position: "absolute"
  }
}));

const ButtonGroup = props => {
  const {
    nextText,
    backText = "Back",
    inverted,
    nextDiasabled,
    backButtonOutlined,
    handleNext,
    handleBack,
    handleEnd,
    hideBackButton,
    fullWidthNextButton,
    hideIcon,
    disableBackButton,
    error,
    errorText,
    disabledRed,
    loading,
    backLoading,
    noMinWidth = false,
    type,
    size = "medium",
    noMarginTop = false,
    showButtonInColumn = false
  } = props;
  const classes = useStyles({
    noMinWidth: noMinWidth,
    hideIcon: hideIcon,
    noMarginTop: noMarginTop,
    showButtonInColumn: showButtonInColumn
  });

  return (
    <Box>
      <Container classes={{ root: classes.root }} disableGutters={true}>
        <Button
          size={size}
          color="primary"
          variant={backButtonOutlined && "outlined"}
          disabled={disableBackButton}
          disableElevation
          type="button"
          onClick={handleBack}
          style={{
            display: hideBackButton ? "none" : "inherit"
            // color: disableBackButton ? 'transparent' : null
          }}
        >
          {backText}
          {backLoading && (
            <CircularProgress
              component="span"
              size={24}
              className={classes.buttonProgress}
            />
          )}
        </Button>
        <>
          <Button
            size={size}
            variant="contained"
            color="primary"
            type={type ? type : "button"}
            disableElevation
            className={inverted ? classes.buttonRoot : null}
            classes={{
              root: classes.rootButton,
              endIcon: classes.endIcon,
              disabled: disabledRed ? classes.disabledRed : null,
              label: classes.label
            }}
            style={error && { background: "#DB443C", borderColor: "#DB443C" }}
            disabled={nextDiasabled}
            fullWidth={fullWidthNextButton}
            onClick={handleEnd ? handleEnd : handleNext}
            endIcon={hideIcon ? null : <ArrowRightIcon />}
          >
            {nextText}
            {loading && (
              <CircularProgress
                component="span"
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </Button>
        </>
      </Container>
      {errorText && <ErrorAlert getErrorText={errorText} />}
    </Box>
  );
};

export default ButtonGroup;
