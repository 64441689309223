import React from "react";
import { isArchived } from "../../../helpers/helpers";
import { ImageIcon, DocumentIcon, StackIcon } from "@akord/addon-icons";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { useProgressContext } from "../../../contexts/ProgressContext";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";
import {
  stackActiveMenuOptions,
  stackRevokedMenuOptions
} from "../MoreMenu/stack-menu-options";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import MoreDrawerRoot from "./MoreDrawerRoot";
import MoreDrawerItem from "./MoreDrawerItem";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";

function StackDrawer({ openDrawer, handleMenuClose, revokedStack }) {
  const { onProgress } = useProgressContext();
  const { onSnackbarToShow } = useSnackbarContext();
  const { modal, onNotificationData } = useNotificationsContext();
  const {
    currentMembership,
    userRole,
    encrypter,
    currentStack,
    isVaultPublic
  } = useVaultContext();

  const {
    onShowLoader,
    onAxiosError,
    onShowUpload,
    onUploadStart,
    onDrawerActionType,
    decryptedFolders,
    onUploadHook
  } = useAssetsContext();

  const isRoomArchived = isArchived(currentMembership);

  // select icon for a file
  const fileType =
    currentStack?.files && currentStack.files[0]
      ? currentStack.files[0].fileType
      : "";
  const FileIcon =
    currentStack?.files?.length > 1
      ? StackIcon
      : fileType && fileType.match("image")
      ? ImageIcon
      : DocumentIcon;

  // if its a currentStack we need to show ext from the latest vers.
  const createStackTitle = () => {
    const splitOriginalTitle = currentStack.title.split(".");
    splitOriginalTitle.pop();
    const latestTitleArray =
      currentStack.files[currentStack.files.length - 1].title.split(".");
    const originalExtension = latestTitleArray[latestTitleArray.length - 1];
    return splitOriginalTitle.join(".") + "." + originalExtension;
  };

  if (Object.keys(currentStack).length === 0) return null;

  const stackMenuOptions = revokedStack
    ? stackRevokedMenuOptions
    : stackActiveMenuOptions;

  return (
    <MoreDrawerRoot
      openDrawer={openDrawer}
      header={
        currentStack.files
          ? currentStack.files.length === 1
            ? currentStack.title
            : createStackTitle()
          : null
      }
      icon={FileIcon}
      handleMenuClose={handleMenuClose}
      isRoomArchived={isRoomArchived}
      isStack
    >
      {stackMenuOptions(
        currentStack,
        encrypter,
        userRole,
        isRoomArchived,
        onShowLoader,
        onProgress,
        onSnackbarToShow,
        onAxiosError,
        onUploadStart,
        onDrawerActionType,
        onShowUpload,
        onNotificationData,
        modal.onModalVisibility,
        modal.onConfirmModalType,
        handleMenuClose,
        modal.onConfirmModalFormType,
        decryptedFolders,
        onUploadHook,
        isVaultPublic
      ).map((menuItem, index) => {
        return (
          menuItem.show && (
            <MoreDrawerItem
              key={index}
              menuItem={menuItem}
              currentStack={currentStack}
              handleMenuClose={handleMenuClose}
              onDrawerActionType={onDrawerActionType}
            />
          )
        );
      })}
    </MoreDrawerRoot>
  );
}

export default StackDrawer;
