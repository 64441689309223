import React from "react";
import { FolderIcon } from "@akord/addon-icons";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { useProgressContext } from "../../../contexts/ProgressContext";
import {
  folderActiveMenuOptions,
  folderRevokeMenuOptions
} from "../MoreMenu/stack-menu-options";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import MoreDrawerRoot from "./MoreDrawerRoot";
import MoreDrawerItem from "./MoreDrawerItem";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";

function FolderDrawer({ openDrawer, handleMenuClose, revokedFolder }) {
  const { onProgress } = useProgressContext();
  const { modal, onNotificationData } = useNotificationsContext();
  const { encrypter, userRole, isRoomArchived, currentStack } =
    useVaultContext();
  const {
    onShowLoader,
    onAxiosError,
    onShowUpload,
    onUploadStart,
    onDrawerActionType
  } = useAssetsContext();

  const folderMenuOptions = revokedFolder
    ? folderRevokeMenuOptions
    : folderActiveMenuOptions;

  if (Object.keys(currentStack).length === 0) return null;

  return (
    <MoreDrawerRoot
      header={currentStack.title}
      icon={FolderIcon}
      openDrawer={openDrawer}
      handleMenuClose={handleMenuClose}
      isRoomArchived={isRoomArchived}
      isFolder
    >
      {folderMenuOptions(
        currentStack,
        encrypter,
        userRole,
        isRoomArchived,
        onShowLoader,
        onProgress,
        onAxiosError,
        onUploadStart,
        onDrawerActionType,
        onShowUpload,
        onNotificationData,
        modal.onModalVisibility,
        modal.onConfirmModalType,
        handleMenuClose,
        modal.onConfirmModalFormType
      ).map((menuItem, index) => {
        return (
          menuItem.show && (
            <MoreDrawerItem
              key={index}
              menuItem={menuItem}
              currentStack={currentStack}
              handleMenuClose={handleMenuClose}
              onDrawerActionType={onDrawerActionType}
            />
          )
        );
      })}
    </MoreDrawerRoot>
  );
}

export default FolderDrawer;
