import { Box, Button, Fab, Tab, Tabs, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Redirect, withRouter } from "react-router";
import { Link, Route, Switch } from "react-router-dom";
import { HideOnScroll } from "../../components/common/";
import { PlusIcon } from "@akord/addon-icons";
import SearchBox from "../../components/search/SearchBox";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import TabActiveRooms from "./TabActiveRooms";
import TabArchivedRooms from "./TabArchivedRooms";
import { useInAppNotificationsContext } from "../../contexts/InAppNotificationsContext";
import PriorityNotification from "../../components/common/PriorityNotification";
import { priorityNotificationsEnum } from "../../helpers/akord-enums";

const useStyles = makeStyles({
  fab: {
    position: "fixed",
    right: "16px",
    bottom: "76px"
  },
  headerBox: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between"
  }
});

function a11yProps(index) {
  return {
    id: `room-tab-${index}`,
    "aria-controls": `room-tabpanel-${index}`
  };
}

function ShowRooms({ history, location }) {
  const roomRef = React.useRef();
  const archiveRef = React.useRef();
  const { decryptedMemberships, isMobile, userAttributes } = useGlobalContext();
  const { notificationsLog } = useInAppNotificationsContext();
  const classes = useStyles();

  const activeRooms = decryptedMemberships?.filter(
    room => room.dataRoom.status === "ACTIVE"
  );

  // Reading notifications to show priority notifications
  const membershipNotifications = notificationsLog?.filter(
    log => log.modelType === "membership"
  );

  const priorityNotification = membershipNotifications?.map(notification => {
    return notification.transactions.items
      .filter(
        transaction =>
          priorityNotificationsEnum.includes(transaction.actionRef) &&
          notification.status !== "READ"
      )
      .map(transaction => {
        return { ...transaction, logId: notification.id };
      });
  })[0];

  const showPrioratyNotification = priorityNotification?.length > 0;

  return (
    <>
      {priorityNotification?.length > 0 &&
        priorityNotification?.map((transaction, index) => (
          <PriorityNotification
            key={index}
            transaction={transaction}
            isMobile={isMobile}
            prioratyType={transaction.actionRef}
          />
        ))}
      <Box
        position="fixed"
        width={isMobile ? "100%" : "calc(100% - 250px)"}
        zIndex="1"
        style={{
          top:
            isMobile && showPrioratyNotification
              ? "110px"
              : isMobile
              ? userAttributes.showV2Announcment
                ? "88px"
                : "44px"
              : showPrioratyNotification
              ? "50px"
              : "inherit"
        }} // on mobile we need the top menu stay fixed and move this header down
      >
        <Box mx={5} mt={4} mb={!isMobile ? 1 : 3} className={classes.headerBox}>
          <Box width="20%">
            <Typography variant="h2">Vaults</Typography>
          </Box>
          {!isMobile && (
            <Box flexGrow="1" display="flex" justifyContent="flex-end">
              <SearchBox></SearchBox>
            </Box>
          )}
          {!isMobile && (
            <Box marginLeft="15px">
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => history.push("/create-vault")}
              >
                New vault
              </Button>
            </Box>
          )}
        </Box>
        <Box>
          <Tabs
            textColor="secondary"
            value={location.pathname}
            aria-label="rooms tabs"
          >
            <Tab
              label="Active"
              component={Link}
              to="/vaults/active"
              value="/vaults/active"
              disableRipple
              {...a11yProps(1)}
            />
            <Tab
              component={Link}
              label="Archived"
              value="/vaults/archived"
              to="/vaults/archived"
              disableRipple
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
      </Box>
      <Box
        flex="1"
        height={
          isMobile
            ? "calc(100% - 133px)"
            : showPrioratyNotification
            ? "calc(100% - 136px)"
            : "calc(100% - 86px)"
        }
        position="absolute"
        top={
          isMobile && showPrioratyNotification
            ? "177px"
            : isMobile
            ? "120px"
            : showPrioratyNotification
            ? "136px"
            : "86px"
        }
        width={isMobile ? "100%" : "calc(100% - 250px)"}
      >
        <Switch>
          <Route
            path="/vaults/active"
            exact
            render={() => <TabActiveRooms roomRef={roomRef} />}
          />
          <Route
            path="/vaults/archived"
            exact
            render={() => <TabArchivedRooms archiveRef={archiveRef} />}
          />
          <Redirect to="/404" />
        </Switch>
        {decryptedMemberships?.length > 0 &&
          activeRooms?.length > 0 &&
          isMobile &&
          location.pathname.match("/active") && (
            <HideOnScroll window={roomRef.current} fab={true}>
              <Fab
                size="medium"
                color="primary"
                aria-label="create-vault"
                className={classes.fab}
                onClick={() => history.push("/create-vault")}
              >
                <PlusIcon fontSize="large" />
              </Fab>
            </HideOnScroll>
          )}
      </Box>
    </>
  );
}

export default withRouter(ShowRooms);
