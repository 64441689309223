import { mapMemberDetails, decryptMembership } from "../helpers/decrypt-helper";

const cacheDecryptMembership = new Map();
export const memoizedDecryptMembership = (currentMembership, wallet) => {
  if (currentMembership.dataRoom?.state?.isPublic) return currentMembership;
  if (!currentMembership.dataRoom?.state?.publicKeys) return;
  if (
    !cacheDecryptMembership.has(currentMembership.dataRoom.state.publicKeys)
  ) {
    const decrypted = decryptMembership(currentMembership, wallet);
    cacheDecryptMembership.set(
      currentMembership.dataRoom?.state?.publicKeys,
      decrypted
    );
  }
  return cacheDecryptMembership.get(
    currentMembership.dataRoom.state.publicKeys
  );
};

const cacheMapMemberDetails = new Map();
export const memoizedMapMemberDetails = (
  transactionMembership,
  encrypter,
  decryptedAvatarsMap
) => {
  if (transactionMembership?.dataRoom?.state?.isPublic) return transactionMembership.state.memberDetails;
  if (!transactionMembership?.dataRoom?.state?.publicKeys) return;
  if (
    !cacheMapMemberDetails.has(
      transactionMembership?.dataRoom?.state.publicKeys
    )
  ) {
    const decrypted = mapMemberDetails(
      transactionMembership,
      encrypter,
      decryptedAvatarsMap
    );
    cacheMapMemberDetails.set(
      transactionMembership.dataRoom.state.publicKeys,
      decrypted
    );
  }
  return cacheMapMemberDetails.get(
    transactionMembership.dataRoom.state.publicKeys
  );
};
