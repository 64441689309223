import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import InviteToRoom from "../../pages/room-options/InviteToRoom";
import ManageAccess from "../../pages/room-options/manage-access/ManageAccess";
import RevokedFiles from "../../pages/room-options/RevokedFiles";
import Timeline from "../../pages/timeline";
import Rooms from "../../pages/room";
import Stacks from "../../pages/vault-pages/";
import FileViewerContextProvider from "../../contexts/FileViewerContextProvider";
import DialogNoteViewer from "../common/FileViewer/DialogNoteViewer";
import TimelineContextProvider from "../../contexts/TimelineContextProvider";

function RoomDetailsRouter(props) {
  return (
    <Switch>
      <Route exact path="/vaults/:dataRoomStatus" render={() => <Rooms />} />
      <Route
        path="/vaults/:dataRoomStatus/:dataRoomId/assets/create-note"
        exact
        render={() => (
          <FileViewerContextProvider>
            <DialogNoteViewer />
          </FileViewerContextProvider>
        )}
      />
      <Route
        path="/vaults/:dataRoomStatus/:dataRoomId/invite-to-vault"
        exact
        render={() => <InviteToRoom {...props} />}
      />
      <Route
        path="/vaults/:dataRoomStatus/:dataRoomId/manage-access"
        exact
        render={() => <ManageAccess {...props} />}
      />
      <Route
        path="/vaults/:dataRoomStatus/:dataRoomId/revoked-files"
        render={() => <RevokedFiles {...props} />}
      />
      <Route
        path="/vaults/:dataRoomStatus/:dataRoomId/timeline"
        render={() => (
          <TimelineContextProvider>
            <Timeline {...props} />
          </TimelineContextProvider>
        )}
      />
      <Route
        path="/vaults/:dataRoomStatus/:dataRoomId"
        render={() => <Stacks {...props} />}
      />
    </Switch>
  );
}

export default withRouter(RoomDetailsRouter);
