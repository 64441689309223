import { base64ToJson } from "@akord/crypto";

export const memberRoleEnum = {
  CONTRIBUTOR: "CONTRIBUTOR",
  OWNER: "OWNER",
  VIEWER: "VIEWER"
};

export const itemStatusEnum = {
  REVOKED: "REVOKED",
  DELETED: "DELETED"
};

export const memberStatusEnum = {
  ACCEPTED: "ACCEPTED",
  INVITED: "INVITED",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  REVOKED: "REVOKED"
};

export const notificationStatus = {
  DELETED: "DELETED",
  READ: "READ",
  UNREAD: "UNREAD"
};

export const storageTransactionStatus = {
  PENDING: "PENDING",
  SCHEDULED: "SCHEDULED"
};

export const memoActionRef = {
  MEMO_WRITE: "MEMO_WRITE",
  MEMO_ADD_REACTION: "MEMO_ADD_REACTION",
  MEMO_REMOVE_REACTION: "MEMO_REMOVE_REACTION"
};

export const memoActionRefs = [
  "MEMO_WRITE",
  "MEMO_ADD_REACTION",
  "MEMO_REMOVE_REACTION"
];

export const filterActionRefs = [
  "DATAROOM_KEY_ROTATE",
  "MEMBERSHIP_KEY_ROTATE",
  "MEMBERSHIP_OWNER",
  "MEMBERSHIP_PROFILE_UPDATE",
  "REFERRAL_REWARD",
  "STACK_UPDATE_STORAGE_TRANSACTION_STATUS",
  "MEMO_ADD_REACTION",
  "MEMO_REMOVE_REACTION"
];

export const filterActions = [
  {
    actionRef: "MEMBERSHIP_ACCEPT",
    filter: action => {
      if (!action.membership.termsOfAccess) return false;
      const termsOfAccess = base64ToJson(action.membership.termsOfAccess);
      return !termsOfAccess.hasTerms;
    }
  }
];

// export const filterActionRefsForNotifications = [
//   'DATAROOM_KEY_ROTATE',
//   'MEMBERSHIP_KEY_ROTATE',
//   'MEMBERSHIP_OWNER',
//   'MEMBERSHIP_PROFILE_UPDATE',
//   'STACK_UPDATE_STORAGE_TRANSACTION_STATUS'
// ]

export const priorityNotificationsEnum = ["USER_VERIFIED"];

export const FILE_SIZE_LIMIT_UPLOAD = 3000000000;
