export const notificationsByPublicSigningKey = /* GraphQL */ `
  query NotificationsByPublicSigningKey(
    $publicSigningKey: String
    $limit: Int
    $nextToken: String
  ) {
    notificationsByPublicSigningKey(
      publicSigningKey: $publicSigningKey
      status: { gt: "DELETED" }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        admin
        content
        hash
        height
        id
        dataRoomId
        modelType
        modelId
        status
        createdAt
        dataRoom {
          memberships: members {
            items {
              dataRoom {
                state {
                  publicKeys
                  title
                  termsOfAccess
                  isPublic
                }
              }
              memberPublicSigningKey
              state {
                memberDetails {
                  publicSigningKey
                  email
                  fullName
                  avatarUrl
                }
                encryptionType
                keys {
                  encPrivateKey
                  publicKey
                }
              }
            }
          }
        }
        transactions(
          filter: {
            actionRef: { ne: "DATAROOM_KEY_ROTATE" }
            and: {
              actionRef: { ne: "MEMBERSHIP_KEY_ROTATE" }
              and: {
                actionRef: { ne: "MEMBERSHIP_OWNER" }
                and: {
                  actionRef: { ne: "MEMBERSHIP_PROFILE_UPDATE" }
                  and: {
                    actionRef: { ne: "STACK_UPDATE_STORAGE_TRANSACTION_STATUS" }
                  }
                }
              }
            }
          }
        ) {
          items {
            publicSigningKey
            actionRef
            createdAt
            dataRoomId
            modelId
            groupRef
            hash
            encodedPrevState
            stack {
              title
            }
            folder {
              title
            }
            note {
              title
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getTransactionLog = /* GraphQL */ `
  query getTransactionLog($id: ID!) {
    getTransactionLog(id: $id) {
      admin
      content
      hash
      id
      dataRoomId
      modelId
      status
      createdAt
      dataRoom {
        memberships: members {
          items {
            dataRoom {
              state {
                publicKeys
                title
                termsOfAccess
              }
            }
            memberPublicSigningKey
            state {
              memberDetails {
                publicSigningKey
                email
                fullName
                avatarUrl
              }
              encryptionType
              keys {
                encPrivateKey
                publicKey
              }
            }
          }
        }
      }
      transactions(
        filter: {
          actionRef: { ne: "DATAROOM_KEY_ROTATE" }
          and: {
            actionRef: { ne: "MEMBERSHIP_KEY_ROTATE" }
            and: {
              actionRef: { ne: "MEMBERSHIP_OWNER" }
              and: {
                actionRef: { ne: "MEMBERSHIP_PROFILE_UPDATE" }
                and: {
                  actionRef: { ne: "STACK_UPDATE_STORAGE_TRANSACTION_STATUS" }
                }
              }
            }
          }
        }
      ) {
        items {
          publicSigningKey
          actionRef
          createdAt
          dataRoomId
          modelId
          groupRef
          hash
          encodedPrevState
          stack {
            title
          }
          folder {
            title
          }
          note {
            title
          }
        }
      }
    }
  }
`;
