import React from "react";
import { ConfirmationModal, FormModal } from "..";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { useProgressContext } from "../../../contexts/ProgressContext";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";
import { useLocation } from "react-router";
import { getFolderId } from "../../../helpers/helpers";
import {
  handleUpload,
  filesToUpdate
} from "../../../helpers/duplicate-file-upload";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";

function NotificationsWrapper({ children }) {
  const { onSnackbarToShow } = useSnackbarContext();
  const { onProgress } = useProgressContext();
  const { currentMembership, ledgerWrapper } = useVaultContext();
  const {
    onShowLoader,
    decryptedStacks,
    onDrawerActionType,
    onAxiosError,
    onUploadStart,
    onFilesNumber,
    onUploadHook
  } = useAssetsContext();
  const {
    modal,
    uploadedFiles,
    onDuplicateFiles,
    onUploadFiles,
    uploadEventRef,
    clearUploadEventRef
  } = useNotificationsContext();

  const location = useLocation();
  const folderId = getFolderId(location.pathname);

  React.useEffect(() => {
    const checkFilesForDuplicates = files => {
      const filesToUpdateArray = filesToUpdate(files, decryptedStacks);
      if (filesToUpdateArray.length > 0) {
        onDuplicateFiles(filesToUpdateArray);
        return true;
      } else return false;
    };

    if (!uploadedFiles.length) return;

    if (checkFilesForDuplicates(uploadedFiles)) {
      modal.onConfirmModalType("duplicateFile");
      modal.onModalVisibility(null, "duplicateFile");
    } else {
      if (!currentMembership?.dataRoom?.state?.permanentStorage) {
        handleUpload(
          [],
          uploadedFiles,
          decryptedStacks,
          onDrawerActionType,
          onShowLoader,
          currentMembership,
          ledgerWrapper,
          onProgress,
          onAxiosError,
          onSnackbarToShow,
          onUploadStart,
          onFilesNumber,
          onUploadFiles,
          uploadEventRef,
          clearUploadEventRef,
          folderId,
          null,
          onUploadHook
        );
      } else {
        modal.onConfirmModalType("confirmUpload");
        modal.onModalVisibility(null, "confirmUpload");
      }
    }
  }, [uploadedFiles]);

  return (
    <>
      {modal.confirmModalFormType && <FormModal />}
      {modal.confirmModalType && <ConfirmationModal />}
      {children}
    </>
  );
}

export default NotificationsWrapper;
