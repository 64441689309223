import React from "react";
import { Typography } from "@mui/material";
import OperationTitleWithIcon from "../../../components/common/OperationTitle/OperationTitleWithIcon";

function FolderRename(props) {
  const { operation, positionedBeforeDate, color } = props;

  return (
    <div style={{ marginBottom: positionedBeforeDate ? "74px" : "16px" }}>
      <OperationTitleWithIcon actionRef={operation.actionRef} color={color} />
      <div style={{ display: "flex" }}>
        <Typography variant="caption" color="text.secondary">
          {"From:"}
        </Typography>
        <Typography variant="caption" noWrap style={{ whiteSpace: "pre" }}>
          {" "}
          {operation?.prevTitle}
        </Typography>
      </div>
      <div
        style={{
          marginBottom: positionedBeforeDate ? "74px" : "16px",
          display: "flex"
        }}
      >
        <Typography variant="body2" color="text.secondary" className="small">
          {"To:"}
        </Typography>
        <Typography
          variant="body2"
          className="small"
          noWrap
          style={{ whiteSpace: "pre" }}
        >
          {" "}
          {operation.title}
        </Typography>
      </div>
    </div>
  );
}

export default FolderRename;
