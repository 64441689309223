import React from "react";
import { AppBar, Typography, Toolbar, IconButton, Badge } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useLocation } from "react-router-dom";
import {
  SettingsIcon,
  VaultIcon,
  BellIcon,
  StorageIcon
} from "@akord/addon-icons";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { getDataRoomStatus } from "../../../helpers/helpers";
import { useInAppNotificationsContext } from "../../../contexts/InAppNotificationsContext";

const useStyles = makeStyles({
  appBottomBar: {
    top: "auto",
    bottom: 0,
    borderTop: "1px solid",
    borderColor: ({ darkMode }) => (darkMode ? "#434140" : "#D5D5D5"),
    height: "60px"
  },
  appBarTop: {
    height: "54px"
  },
  iconWithText: {
    flexDirection: "column"
  },
  grow: {
    flexGrow: 1
  },
  container: {
    top: "54px",
    position: "relative"
  }
});

function BottomBarSm({ history }) {
  const { darkMode } = useGlobalContext();
  const classes = useStyles({ darkMode: darkMode });
  const location = useLocation();
  const { transactionLogs } = useInAppNotificationsContext();
  return (
    <AppBar
      elevation={0}
      position="fixed"
      color="secondary"
      className={classes.appBottomBar}
    >
      <Toolbar style={{ paddingTop: "2px" }}>
        <IconButton
          onClick={() => {
            history.push("/vaults/active");
          }}
          classes={{ root: classes.iconWithText }}
          size="large"
        >
          <VaultIcon
            fontSize="medium"
            color={
              location.pathname ===
              `/vaults/${getDataRoomStatus(location.pathname)}`
                ? "action"
                : "disabled"
            }
            style={{ marginBottom: "4px" }}
          />
          <Typography
            variant="caption"
            className="small"
            color={
              location.pathname.match(
                `/vaults/${getDataRoomStatus(location.pathname)}`
              )
                ? "text.primary"
                : "text.secondary"
            }
          >
            Vaults
          </Typography>
        </IconButton>
        <IconButton
          onClick={() => {
            history.push("/storage");
          }}
          classes={{ root: classes.iconWithText }}
          size="large"
        >
          <StorageIcon
            fontSize="default"
            color={location.pathname === "/storage" ? "action" : "disabled"}
            style={{ marginBottom: "4px" }}
          />
          <Typography
            variant="caption"
            className="small"
            color={
              location.pathname === "/storage"
                ? "text.primary"
                : "text.secondary"
            }
          >
            Storage
          </Typography>
        </IconButton>
        <IconButton
          onClick={() => {
            history.push("/notifications");
          }}
          classes={{ root: classes.iconWithText }}
          size="large"
        >
          <Badge
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            variant="dot"
            overlap="circular"
            invisible={
              !transactionLogs ||
              transactionLogs?.filter(log => log.status === "UNREAD").length ===
                0
            }
          >
            <BellIcon
              fontSize="default"
              style={{ marginBottom: "4px" }}
              color={
                location.pathname === "/notifications" ? "action" : "disabled"
              }
            />
          </Badge>
          <Typography
            variant="caption"
            className="small"
            color={
              location.pathname === "/notifications"
                ? "text.primary"
                : "text.secondary"
            }
          >
            Notifications
          </Typography>
        </IconButton>
        <IconButton
          onClick={() => {
            history.push("/settings");
          }}
          classes={{ root: classes.iconWithText }}
          size="large"
        >
          <SettingsIcon
            fontSize="default"
            color={location.pathname.match("/settings") ? "action" : "disabled"}
            style={{ marginBottom: "4px" }}
          />
          <Typography
            variant="caption"
            className="small"
            color={
              location.pathname.match("/settings")
                ? "text.primary"
                : "text.secondary"
            }
          >
            Settings
          </Typography>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default BottomBarSm;
