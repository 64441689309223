import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";

const useStyles = makeStyles(theme =>
  createStyles({
    listItems: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "50px",
      "&:hover": {
        "& $icon": {
          color: theme.palette.text.primary
        }
      }
    },
    icon: {
      transition: ".3s ease"
    },
    listIcon: {
      marginRight: "10px"
    }
  })
);

function DrawerItem(props) {
  const {
    disabled = false,
    drawerItemData,
    Icon,
    text,
    actionFunc,
    altText,
    drawerAction,
    onShowLoader,
    onDrawerActionType,
    onShowUpload,
    handleDrawerItem,
    darkMode
  } = props;

  const classes = useStyles({ darkMode: darkMode, disabled: disabled });

  return (
    <div style={{ cursor: disabled ? "not-allowed" : "inherit" }}>
      <ListItem
        disabled={disabled}
        disableGutters
        button
        className={classes.listItems}
        onClick={() => {
          actionFunc();
          onShowLoader && onShowLoader(true);
          handleDrawerItem && handleDrawerItem(false);
          onShowUpload && onShowUpload(true);
          onDrawerActionType && onDrawerActionType(drawerAction);
        }}
      >
        <ListItemIcon className={classes.listIcon}>
          <Icon fontSize="medium" color="disabled" className={classes.icon} />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body2">
            {drawerItemData?.files?.length > 1 && altText ? altText : text}
          </Typography>
        </ListItemText>
      </ListItem>
    </div>
  );
}

export default DrawerItem;
