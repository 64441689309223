import {
  arrayToBase64,
  blobToArray,
  dataUrlToArray,
  EncrypterFactory,
  generateKeyPair,
  jsonToBase64,
  signString
} from "@akord/crypto";
import { v4 as uuidv4 } from "uuid";
import EncryptedChunkProcessor from "../helpers/chunked-file-io";
import PublicChunkProcessor from "../helpers/chunked-file-io-public";
import * as constants from "./constants";

export default class LedgerWrapper {
  constructor(wallet, encryptionKeys) {
    this.wallet = wallet;
    // for the data encryption
    this.dataEncrypter = new EncrypterFactory(
      wallet,
      encryptionKeys
    ).encrypterInstance();
    // for the member keys encryption
    this.keysEncrypter = new EncrypterFactory(
      wallet,
      encryptionKeys
    ).encrypterInstance();
  }

  setRawDataEncryptionPublicKey(publicKey) {
    this.dataEncrypter.setRawPublicKey(publicKey);
  }

  setDataEncryptionPublicKey(publicKey) {
    this.dataEncrypter.setPublicKey(publicKey);
  }

  setKeysEncryptionPublicKey(publicKey) {
    this.keysEncrypter.setPublicKey(publicKey);
  }

  setRawKeysEncryptionPublicKey(publicKey) {
    this.keysEncrypter.setRawPublicKey(publicKey);
  }

  async dispatch(actionRef, header, body, hooks, isVaultPublic = false) {
    // build the transaction header
    let headerPayload = {
      ...header,
      actionRef: actionRef,
      publicSigningKey: await this.wallet.signingPublicKey(),
      postedAt: new Date()
    };
    let bodyPayload = body ? body : {};
    let additionalData = {};

    switch (actionRef) {
      case "DATAROOM_CREATE":
      case "DATAROOM_KEY_ROTATE": {
        headerPayload.schemaUri = constants.LEDGER_DATAROOM_WRITE;
        bodyPayload.status = "ACTIVE";
        // Create keys only for Encrypted Vaults
        if (!bodyPayload.isPublic) {
          // generate a new vault key pair
          const { privateKey, publicKey } = await generateKeyPair();
          bodyPayload.publicKeys = [arrayToBase64(publicKey)];
          additionalData = {
            newKeyPair: {
              publicKey: publicKey,
              privateKey: privateKey
            }
          };
          this.setRawDataEncryptionPublicKey(publicKey);
        } else {
          if (actionRef === "DATAROOM_CREATE") bodyPayload.termsOfAccess = null;
        }
        break;
      }
      case "DATAROOM_ARCHIVE":
        headerPayload.schemaUri = constants.LEDGER_DATAROOM_ARCHIVE;
        bodyPayload.status = "ARCHIVED";
        break;
      case "DATAROOM_RESTORE":
        headerPayload.schemaUri = constants.LEDGER_DATAROOM_RESTORE;
        bodyPayload.status = "ACTIVE";
        break;
      case "DATAROOM_RENAME":
        headerPayload.schemaUri = constants.LEDGER_DATAROOM_WRITE;
        break;
      case "DATAROOM_DELETE":
        headerPayload.schemaUri = constants.LEDGER_DATAROOM_DELETE;
        bodyPayload.status = "DELETED";
        break;
      case "STACK_REVOKE":
        headerPayload.schemaUri = constants.LEDGER_STACK_REVOKE;
        bodyPayload.status = "REVOKED";
        break;
      case "STACK_RESTORE":
        headerPayload.schemaUri = constants.LEDGER_STACK_RESTORE;
        bodyPayload.status = "ACTIVE";
        break;
      case "STACK_REMOVE":
      case "STACK_DELETE":
        headerPayload.schemaUri = constants.LEDGER_STACK_DELETE;
        bodyPayload.status = "DELETED";
        break;
      case "FOLDER_REVOKE":
        headerPayload.schemaUri = constants.LEDGER_FOLDER_REVOKE;
        bodyPayload.status = "REVOKED";
        break;
      case "FOLDER_RESTORE":
        headerPayload.schemaUri = constants.LEDGER_FOLDER_RESTORE;
        bodyPayload.status = "ACTIVE";
        break;
      case "FOLDER_REMOVE":
      case "FOLDER_DELETE":
        headerPayload.schemaUri = constants.LEDGER_FOLDER_DELETE;
        bodyPayload.status = "DELETED";
        break;
      case "STACK_CREATE":
        headerPayload.schemaUri = constants.LEDGER_STACK_WRITE;
        bodyPayload.status = "ACTIVE";
        break;
      case "STACK_RENAME":
      case "STACK_UPLOAD_REVISION":
      case "STACK_MOVE":
        headerPayload.schemaUri = constants.LEDGER_STACK_WRITE;
        break;
      case "FOLDER_CREATE":
      case "FOLDER_RENAME":
      case "FOLDER_MOVE":
        headerPayload.schemaUri = constants.LEDGER_FOLDER_WRITE;
        break;
      case "MEMBERSHIP_OWNER":
        // Only Encrypt for Encrypted Vaults
        if (bodyPayload.keyRotate) {
          this.setRawKeysEncryptionPublicKey(await this.wallet.publicKeyRaw());
          this.setRawDataEncryptionPublicKey(bodyPayload.keyRotate.publicKey);
        } else {
          delete bodyPayload.keyRotate;
        }
        headerPayload.schemaUri = constants.LEDGER_MEMBERSHIP_OWNER;
        bodyPayload.role = "OWNER";
        bodyPayload.status = "ACCEPTED";
        break;
      case "MEMBERSHIP_INVITE":
        headerPayload.schemaUri = constants.LEDGER_MEMBERSHIP_INVITE;
        bodyPayload.status = "PENDING";
        break;
      case "MEMBERSHIP_INVITE_NEW_USER":
        headerPayload.schemaUri = constants.LEDGER_MEMBERSHIP_INVITE_NEW_USER;
        bodyPayload.status = "INVITED";
        break;
      case "MEMBERSHIP_INVITE_RESEND":
        headerPayload.schemaUri = constants.LEDGER_MEMBERSHIP_INVITE;
        break;
      case "MEMBERSHIP_INVITE_NEW_USER_RESEND":
        headerPayload.schemaUri = constants.LEDGER_MEMBERSHIP_INVITE_NEW_USER;
        break;
      case "MEMBERSHIP_ACCEPT":
        headerPayload.schemaUri = constants.LEDGER_MEMBERSHIP_ACCEPT;
        bodyPayload.status = "ACCEPTED";
        break;
      case "MEMBERSHIP_REJECT":
      case "MEMBERSHIP_LEAVE":
        headerPayload.schemaUri = constants.LEDGER_MEMBERSHIP_REJECT;
        bodyPayload.status = "REJECTED";
        break;
      case "MEMBERSHIP_REVOKE":
        headerPayload.schemaUri = constants.LEDGER_MEMBERSHIP_REVOKE;
        bodyPayload.status = "REVOKED";
        break;
      case "MEMBERSHIP_CONFIRM":
        headerPayload.schemaUri = constants.LEDGER_MEMBERSHIP_CONFIRM;
        bodyPayload.status = "PENDING";
        break;
      case "MEMBERSHIP_CHANGE_ACCESS":
        headerPayload.schemaUri = constants.LEDGER_MEMBERSHIP_WRITE;
        break;
      case "MEMBERSHIP_RESTORE_ACCESS":
        headerPayload.schemaUri = constants.LEDGER_MEMBERSHIP_INVITE;
        bodyPayload.status = "PENDING";
        break;
      case "MEMBERSHIP_PROFILE_UPDATE":
        headerPayload.schemaUri = constants.LEDGER_MEMBERSHIP_WRITE;
        break;
      case "MEMBERSHIP_KEY_ROTATE":
        headerPayload.schemaUri = constants.LEDGER_MEMBERSHIP_WRITE;
        break;
      case "PROFILE_CREATE":
        headerPayload.schemaUri = constants.LEDGER_PROFILE_WRITE;
        bodyPayload.status = "PENDING";
        bodyPayload.wallets = [
          {
            publicSigningKey: await this.wallet.signingPublicKey(),
            encBackupPhrase: this.wallet.encBackupPhrase,
            publicKey: await this.wallet.publicKey()
          }
        ];
        bodyPayload.profileDetails = {
          publicSigningKey: await this.wallet.signingPublicKey()
        };
        break;
      case "PROFILE_UPDATE":
        this.setRawDataEncryptionPublicKey(await this.wallet.publicKeyRaw());
        headerPayload.schemaUri = constants.LEDGER_PROFILE_WRITE;
        break;
      case "PROFILE_UPDATE_PASSWORD":
        headerPayload.schemaUri = constants.LEDGER_PROFILE_WRITE;
        bodyPayload.wallets = [
          {
            publicSigningKey: await this.wallet.signingPublicKey(),
            encBackupPhrase: this.wallet.encBackupPhrase,
            publicKey: await this.wallet.publicKey()
          }
        ];
        break;
      case "MEMO_WRITE":
        headerPayload.schemaUri = constants.LEDGER_MEMO_WRITE;
        break;
      case "NOTE_CREATE":
      case "NOTE_CREATE_REVISION":
      case "NOTE_MOVE":
        headerPayload.schemaUri = constants.LEDGER_NOTE_WRITE;
        bodyPayload.status = "ACTIVE";
        break;
      case "NOTE_REVOKE":
        headerPayload.schemaUri = constants.LEDGER_NOTE_REVOKE;
        bodyPayload.status = "REVOKED";
        break;
      case "NOTE_RESTORE":
        headerPayload.schemaUri = constants.LEDGER_NOTE_RESTORE;
        bodyPayload.status = "ACTIVE";
        break;
      case "NOTE_REMOVE":
      case "NOTE_DELETE":
        headerPayload.schemaUri = constants.LEDGER_NOTE_DELETE;
        bodyPayload.status = "DELETED";
        break;
      case "MEMO_ADD_REACTION":
        headerPayload.schemaUri = constants.LEDGER_MEMO_ADD_REACTION;
        break;
      case "MEMO_REMOVE_REACTION":
        headerPayload.schemaUri = constants.LEDGER_MEMO_REMOVE_REACTION;
        break;
      default:
        throw new Error("Unknown action ref: " + actionRef);
    }
    // Public vs Encrypted Vaults
    if (
      (!bodyPayload.isPublic || !isVaultPublic) &&
      (this.dataEncrypter.publicKey || this.dataEncrypter.encAccessKey)
    ) {
      // build & encrypt the transaction body
      const { encryptedBody, uploadPayload } = await this.constructBody(
        bodyPayload,
        hooks
      );

      const encodedTransaction = await this.encodeTransaction(
        headerPayload,
        encryptedBody
      );
      return { encodedTransaction, uploadPayload, additionalData };
    } else {
      const { transformedBody, uploadPayload } = await this.transformBody(
        bodyPayload,
        hooks
      );

      const encodedTransaction = await this.encodeTransaction(
        headerPayload,
        transformedBody
      );

      return { encodedTransaction, uploadPayload, additionalData };
    }
  }

  async transformBody(payload, hooks) {
    let transformedBody = {};
    let uploadPayload = null;
    for (let fieldName in payload) {
      if (payload[fieldName]) {
        switch (fieldName) {
          case "revision":
            transformedBody.revisions = [
              {
                title: payload[fieldName].title,
                content: payload[fieldName].content,
                size: payload[fieldName].size,
                postedAt: new Date()
              }
            ];
            break;
          case "file": {
            uploadPayload = {};
            if (payload[fieldName].thumbnail) {
              const thumbnailArray = await blobToArray(
                payload[fieldName].thumbnail
              );
              uploadPayload.thumbnail = await this.proccessByteArray(
                thumbnailArray
              );
            }

            const chunkedFileProcessor = new PublicChunkProcessor(
              hooks.onProgress,
              hooks.onCancel
            );
            hooks.onUploader(chunkedFileProcessor);
            const { uploaded, resourceUrl, numberOfChunks, chunkSize } =
              await chunkedFileProcessor.chunkedUpload(
                payload[fieldName].file,
                hooks.onProgress
              );

            if (!uploaded) {
              return null;
            }

            transformedBody.files = [
              {
                postedAt: new Date(),
                title: payload[fieldName].title,
                resourceUrl: resourceUrl,
                thumbnailUrl: uploadPayload.thumbnail
                  ? uploadPayload.thumbnail.resourceKey
                  : null,
                fileType: payload[fieldName].fileType,
                size: payload[fieldName].size,
                modifiedAt: new Date(
                  payload[fieldName].file.lastModified * 1000
                ),
                numberOfChunks: numberOfChunks,
                chunkSize: chunkSize
              }
            ];
            break;
          }
          case "emailMessage":
            transformedBody.message = payload[fieldName];
            break;
          case "memberKeys": {
            const name = this.keysEncrypter.encAccessKey
              ? "encAccessKey"
              : "keys";
            transformedBody[name] = payload[fieldName];
            break;
          }
          default:
            transformedBody[fieldName] = payload[fieldName];
            break;
        }
      }
    }

    return { transformedBody, uploadPayload };
  }

  async constructBody(payload, hooks) {
    let encryptedBody = {};
    let uploadPayload = null;
    for (let fieldName in payload) {
      if (payload[fieldName]) {
        switch (fieldName) {
          case "title":
          case "message":
          case "content":
          case "description": {
            encryptedBody[fieldName] = await this.dataEncrypter.encryptString(
              payload[fieldName]
            );
            break;
          }
          case "reaction": {
            const reactionData = payload.reaction;
            reactionData.reaction = await this.dataEncrypter.encryptString(
              reactionData.reaction
            );
            reactionData.name = await this.dataEncrypter.encryptString(
              reactionData.name
            );
            encryptedBody.reactions = [reactionData];
            break;
          }
          case "emailMessage":
            // leave the invite message unencrypted for sending an email
            encryptedBody.message = payload[fieldName];
            break;
          case "termsOfAccess": {
            encryptedBody[fieldName] = jsonToBase64(payload[fieldName]);
            break;
          }
          case "memberDetails":
          case "profileDetails": {
            const encryptedMemberDetailsResult =
              await this.encryptMemberDetails(
                payload[fieldName].fullName,
                payload[fieldName].phone,
                payload[fieldName].avatarUrl
              );
            encryptedBody[fieldName] = {
              ...payload[fieldName],
              ...encryptedMemberDetailsResult.encryptedMemberDetails
            };
            uploadPayload = encryptedMemberDetailsResult.uploadPayload;
            break;
          }
          case "file": {
            uploadPayload = {};
            if (payload[fieldName].thumbnail) {
              const thumbnailArray = await blobToArray(
                payload[fieldName].thumbnail
              );
              uploadPayload.thumbnail = await this.encryptByteArray(
                thumbnailArray
              );
            }
            const encryptedTitle = await this.dataEncrypter.encryptString(
              payload[fieldName].title
            );

            const chunkedFileProcessor = new EncryptedChunkProcessor(
              this.dataEncrypter,
              hooks.onProgress,
              hooks.onCancel
            );
            hooks.onUploader(chunkedFileProcessor);
            const { uploaded, resourceUrl, numberOfChunks, chunkSize } =
              await chunkedFileProcessor.encryptedChunkedUpload(
                payload[fieldName].file,
                hooks.onProgress
              );

            if (!uploaded) {
              return null;
            }

            encryptedBody.files = [
              {
                postedAt: new Date(),
                title: encryptedTitle,
                resourceUrl: resourceUrl,
                thumbnailUrl: uploadPayload.thumbnail
                  ? uploadPayload.thumbnail.resourceKey
                  : null,
                fileType: payload[fieldName].fileType,
                size: payload[fieldName].size,
                numberOfChunks: numberOfChunks,
                chunkSize: chunkSize
              }
            ];
            break;
          }
          case "revision": {
            const encryptedTitle = await this.dataEncrypter.encryptString(
              payload[fieldName].title
            );
            const encryptedContent = await this.dataEncrypter.encryptString(
              payload[fieldName].content
            );

            encryptedBody.revisions = [
              {
                title: encryptedTitle,
                content: encryptedContent,
                size: payload[fieldName].size,
                postedAt: new Date()
              }
            ];
            break;
          }
          case "memberKeys": {
            const name = this.keysEncrypter.encAccessKey
              ? "encAccessKey"
              : "keys";
            encryptedBody[name] = await this.keysEncrypter.encryptMemberKeys(
              payload[fieldName]
            );
            break;
          }
          case "keyRotate": {
            const encPrivateKey = await this.keysEncrypter.encryptMemberKey(
              payload[fieldName].privateKey
            );
            encryptedBody.keys = [
              {
                publicKey: arrayToBase64(payload[fieldName].publicKey),
                encPrivateKey: encPrivateKey
              }
            ];
            break;
          }
          default:
            encryptedBody[fieldName] = payload[fieldName];
            break;
        }
      }
    }
    return { encryptedBody, uploadPayload };
  }

  async encryptMemberDetails(fullName, phone, avatar) {
    let encryptedMemberDetails = {};

    if (fullName) {
      const encryptedFullName = await this.dataEncrypter.encryptString(
        fullName
      );
      encryptedMemberDetails.fullName = encryptedFullName;
    }
    if (phone) {
      const encryptedPhone = await this.dataEncrypter.encryptString(phone);
      encryptedMemberDetails.phone = encryptedPhone;
    }
    let uploadPayload = null;
    if (avatar) {
      const avatarArray = await dataUrlToArray(avatar);
      uploadPayload = await this.encryptByteArray(avatarArray);
      encryptedMemberDetails.avatarUrl = uploadPayload.resourceKey;
    }
    return { encryptedMemberDetails, uploadPayload };
  }

  async encryptByteArray(byteArray) {
    const encryptedByteArray = await this.dataEncrypter.encryptRaw(byteArray);
    const resourceKey = uuidv4();
    const uploadPayload = {
      encryptedFile: encryptedByteArray,
      resourceKey: resourceKey
    };
    return uploadPayload;
  }

  async proccessByteArray(byteArray) {
    const resourceKey = uuidv4();
    const uploadPayload = {
      publicFile: byteArray,
      resourceKey: resourceKey
    };

    return uploadPayload;
  }

  /**
   * Post ledger transaction preparation
   * - encode & sign the transaction payload
   * @param {Object} headerPayload
   * @param {Object} bodyPayload
   */
  async encodeTransaction(header, body) {
    const privateKeyRaw = await this.wallet.signingPrivateKeyRaw();
    const publicKey = await this.wallet.signingPublicKey();

    // encode the header and body as BASE64 and sign it
    const encodedHeader = jsonToBase64(header);
    const encodedBody = jsonToBase64(body);
    const signature = await signString(
      `${encodedHeader}${encodedBody}`,
      privateKeyRaw
    );
    return { encodedHeader, encodedBody, publicKey, signature };
  }
}
