import { Box, Typography } from "@mui/material";
import React from "react";
import ButtonLink from "../../components/common/ButtonLink";
import { CssBaseline } from "@mui/material";
import NFDM from "../../components/common/404/nf-dm";
import NFLM from "../../components/common/404/nf-lm";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useHistory } from "react-router-dom";
import ThemeWrapper from "../../components/common/Wrappers/ThemeWrapper";

const NotFoundPage = () => {
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width:668px)");
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const loader = document.querySelector(".preloader");
  const showPreLoader = () => loader?.classList.remove("preloader");
  const addClass = () => loader?.classList.add("loader-hide");

  React.useEffect(() => {
    showPreLoader();
    addClass();
  }, []);
  return (
    <ThemeWrapper darkMode={prefersDarkMode}>
      <CssBaseline />
      <Box display="flex" height="100%" width="100%" justifyContent="center">
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          height="100%"
          style={{
            margin: "0 20px"
          }}
          maxWidth="352px"
          width="100%"
        >
          {prefersDarkMode ? (
            <NFDM style={{ marginBottom: 12 }} />
          ) : (
            <NFLM style={{ marginBottom: 12 }} />
          )}
          <Box mb={2}>
            <Typography variant="h1">
              Oh dear, something&apos;s wrong!
            </Typography>
          </Box>

          <Box mb={5}>
            <Typography variant="body2">
              Nevermind, these things happen.
            </Typography>
          </Box>

          <Box display="flex" justifyContent="center" width="100%">
            <ButtonLink
              onClick={() => history.push("/vaults/active")}
              width={isMobile ? "xs" : "md"}
            >
              Return to a secure context
            </ButtonLink>
          </Box>
        </Box>
      </Box>
    </ThemeWrapper>
  );
};

export default NotFoundPage;
