import React, { forwardRef, useRef, useImperativeHandle } from 'react'


const StripeInput = forwardRef((props, ref) => {
     const { component: Component } = props;
     const elementRef = useRef();
     useImperativeHandle(ref, () => ({
       focus: () => elementRef.current.focus
     }));
   
     return (
          <Component
               onReady={element => (elementRef.current = element)}
               {...props}
          />
     )
   });

export default StripeInput
