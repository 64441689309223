import { fileUpload, fileRevision } from "./akord-actions";

export const activeDecryptedStacksFilter = decryptedStacks =>
  decryptedStacks.filter(
    stack => stack.status !== "REVOKED" && stack.status !== "DELETED"
  );

export const filesToUpdate = (files, decryptedStacks) => {
  let fileList = [];
  for (let i = 0; i < files.length; i++) {
    fileList.push(files[i]);
  }

  let namesToUpdateArray = [];
  fileList.map((file, index) => {
    return activeDecryptedStacksFilter(decryptedStacks).map(decrypted => {
      if (file.name === decrypted.title)
        return namesToUpdateArray.push({ name: file.name, index: index });
    });
  });
  return namesToUpdateArray;
};

export const handleUpload = (
  filesWithAction,
  uploadedFiles,
  decryptedStacks,
  onDrawerActionType,
  onShowLoader,
  currentMembership,
  ledgerWrapper,
  onProgress,
  onAxiosError,
  onSnackbarToShow,
  onUploadStart,
  onFilesNumber,
  onUploadFiles,
  uploadEventRef,
  clearUploadEventRef,
  folderId = null,
  isLegacyVault,
  onUploadHook,
  onTxSpinner
) => {
  const activeDecryptedStacks = activeDecryptedStacksFilter(decryptedStacks);
  const newVersionFilesForIndexes = filesWithAction.filter(
    file => file.action === "New version"
  );
  const filesAsNewVersion = newVersionFilesForIndexes.map(versionFile => {
    return uploadedFiles[versionFile.index];
  });

  if (filesAsNewVersion.length) {
    filesAsNewVersion.map(async version => {
      const stackData = activeDecryptedStacks.filter(
        stack => stack.title === version.name
      )[0];
      onDrawerActionType("upload");
      onShowLoader(true);
      try {
        const uploader = fileRevision(
          ledgerWrapper,
          stackData,
          filesAsNewVersion,
          onShowLoader,
          onProgress,
          onAxiosError,
          onSnackbarToShow,
          isLegacyVault,
          onUploadHook
        );
        onUploadStart(uploader);
        if (uploadEventRef.current) clearUploadEventRef();
      } catch (err) {
        console.log(err);
      }
    });
  }

  let uploadedFilesCopy = [...uploadedFiles];
  const filesWithNewNames = uploadedFilesCopy.filter(
    (_, index) =>
      !newVersionFilesForIndexes.some(filter => filter.index === index)
  );
  if (filesWithNewNames.length > 0) {
    const allExistingNames = activeDecryptedStacks.map(stack => stack.title);
    const uploader = fileUpload(
      currentMembership,
      ledgerWrapper,
      filesWithNewNames,
      allExistingNames,
      filesWithAction,
      onShowLoader,
      onProgress,
      onAxiosError,
      onFilesNumber,
      onSnackbarToShow,
      folderId,
      onUploadHook,
      onTxSpinner
    );
    onUploadStart(uploader);
    onUploadFiles([]);
    if (uploadEventRef.current) clearUploadEventRef();
    onDrawerActionType("upload");
    onShowLoader(true);
  }
};
