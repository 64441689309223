import React from "react";
import { Typography, Link } from "@mui/material";
import OperationTitleWithIcon from "../../../components/common/OperationTitle/OperationTitleWithIcon";

function AssetRestore(props) {
  const { positionedBeforeDate, operation, color } = props;
  const [showFiles, setShowFiles] = React.useState(false);
  const handleShowFiles = show => setShowFiles(show);
  const handleClick = () => handleShowFiles(!showFiles);

  const restoredTitle = operation => {
    if (operation.group) {
      if (operation.group.length > 1)
        return `${operation?.group?.length} files restored`;
      else return "File restored";
    } else {
      if (operation.stack?.files?.length > 1) return "Stack restored";
      else return "File restored";
    }
  };

  return (
    <>
      <OperationTitleWithIcon
        actionRef={operation.actionRef}
        color={color}
        title={restoredTitle(operation)}
      />
      {operation.group?.length > 1 ? (
        <div
          style={{
            marginBottom: positionedBeforeDate ? "74px" : "16px"
          }}
        >
          {showFiles ? (
            <>
              {operation.group.map((operation, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  className="small"
                  color="text.secondary"
                  noWrap
                  style={{ whiteSpace: "pre" }}
                >
                  {operation?.stack
                    ? operation?.stack?.title
                    : operation?.note?.title}
                </Typography>
              ))}
              <Link
                variant="body2"
                className="small"
                underline="none"
                color="text.secondary"
                onClick={handleClick}
              >
                Hide files
              </Link>
            </>
          ) : (
            <Link
              variant="body2"
              className="small"
              underline="none"
              color="text.secondary"
              onClick={handleClick}
            >
              Show files
            </Link>
          )}
        </div>
      ) : (
        <div
          style={{
            marginBottom: positionedBeforeDate ? "74px" : "16px",
            display: "flex"
          }}
        >
          <Typography
            variant="body2"
            className="small"
            color="text.secondary"
            noWrap
            style={{ whiteSpace: "pre" }}
          >
            {operation.group
              ? operation?.group[0].stack
                ? operation?.group[0].stack.title
                : operation?.group[0].note.title
              : operation?.stack
              ? operation?.stack?.title
              : operation?.note?.title}
          </Typography>
        </div>
      )}
    </>
  );
}

export default AssetRestore;
