import React from "react";
import API, { graphqlOperation } from "@aws-amplify/api";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import { Typography } from "@mui/material";
import {
  ButtonGroup,
  OperationTitleWithIcon
} from "../../../components/common/";
import { revokeInvite } from "../../../helpers/manage-access-actions";
import { useVaultContext } from "../../../contexts/VaultContextProvider";

function UserVerified({ operation, positionedBeforeDate, color }) {
  const { currentMembership, ledgerWrapper } = useVaultContext();

  const [loading, setLoading] = React.useState(false);
  const [backLoading, setBackLoading] = React.useState(false);

  const confirmMembership = async () => {
    try {
      setLoading(true);
      const resultMembership = await API.graphql(
        graphqlOperation(queries.getMembership, {
          id: operation.modelId
        })
      );
      const membership = resultMembership.data.getMembership;

      ledgerWrapper.setKeysEncryptionPublicKey(operation.publicKey);
      const { encodedTransaction } = await ledgerWrapper.dispatch(
        "MEMBERSHIP_CONFIRM",
        { prevHash: membership.hash },
        {
          memberDetails: {
            publicSigningKey: operation.publicSigningKey
          },
          termsOfAccess: currentMembership.dataRoom.state.termsOfAccess,
          memberKeys: []
        }
      );

      await API.graphql(
        graphqlOperation(mutations.postLedgerTransaction, {
          transactions: [encodedTransaction]
        })
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const cancelMembership = async () => {
    try {
      setBackLoading(true);
      const resultMembership = await API.graphql(
        graphqlOperation(queries.getMembership, {
          id: operation.modelId
        })
      );

      const membership = resultMembership.data.getMembership;

      revokeInvite(ledgerWrapper, membership.hash, currentMembership);
      setBackLoading(false);
    } catch (err) {
      setBackLoading(false);
      console.log(err);
    }
  };

  return (
    <div
      style={{
        marginBottom: positionedBeforeDate ? "74px" : "16px"
      }}
    >
      <OperationTitleWithIcon
        actionRef={operation.actionRef}
        color={color}
        title={`${operation.email} was verified`}
      />
      <Typography noWrap variant="body2" className="small" paragraph>
        Please confirm access to the vault.
      </Typography>
      <div
        style={{
          maxWidth: "300px"
        }}
      >
        <ButtonGroup
          size="small"
          noMinWidth={true}
          handleNext={() => confirmMembership()}
          handleBack={() => cancelMembership()}
          disableBackButton={false}
          nextDiasabled={loading}
          nextText="Confirm access"
          backText="Cancel"
          inverted={false}
          hideBackButton={false}
          fullWidthNextButton={false}
          hideIcon={true}
          disabledRed={false}
          showButtonInColumn={false}
          loading={loading}
          backLoading={backLoading}
          noMarginTop={true}
        />
      </div>
    </div>
  );
}

export default UserVerified;
