const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
export const sortedItemsByDate = memos => {
  const sorted = new Map();
  const todayFullDate = new Date();
  const todayMonth = todayFullDate.getMonth();
  const todayDate = todayFullDate.getDate();
  // // Commented out cause we sort it before grouping in StackTimeline.jsx
  memos.sort((a, b) => {
    return new Date(a.createdAt) - new Date(b.createdAt);
  });

  memos.forEach(item => {
    const fullDate = new Date(item.createdAt);
    const month = fullDate.getMonth();
    const date = fullDate.getDate();
    const day = fullDate.getDay();

    const dateToShow =
      todayMonth === month && todayDate === date
        ? "Today"
        : todayMonth === month && todayDate - date === 1
        ? "Yesterday"
        : `${days[day]}, ${months[month]} ${date}`;

    if (sorted.has(dateToShow)) {
      const data = sorted.get(dateToShow);
      data.push(item);
      sorted.set(dateToShow, data);
    } else sorted.set(dateToShow, [item]);
  });

  return sorted;
};

// We paginate already groupped items
// so we will not break apart groupRef
export const paginatePreparedMemos = (memos, chatItemsNumber, countRef) => {
  let mapped = new Map();
  // Keep track of amount of items we have
  let count = 1;

  // Need to reverse to start counting from the end
  const reversedKeys = new Map(Array.from(memos).reverse());
  for (const [key, value] of reversedKeys) {
    // We update count after adding the items
    // and update the state with `timelineItemsNumber`
    // later, so pagination can move in a faster pace
    // depending on the timline structure
    // (again, we don't want to break groupRef items)

    let limitedItemsForDate = [];
    // Reversing the content for the same reason - counting from the bottom
    for (const item of value.reverse()) {
      if (count <= chatItemsNumber) {
        limitedItemsForDate.push(item);
        count++;
      }
    }
    if (limitedItemsForDate.length > 0)
      mapped.set(key, limitedItemsForDate.reverse());
  }
  // To avoid adding same count because of rerenders
  if (!countRef.current.includes(count) && count > 0)
    countRef.current.push(count);
  const reversedOutput = new Map(Array.from(mapped).reverse());
  return reversedOutput;
};

export const reactionEmojiCodePoints = [
  "128514", // 'joy'
  "128562", // 'astonished'
  "128557", // 'cry'
  "10084,65039", // 'heart'
  "128293", // 'fire'
  "128077", // '+1'
  "128078", // '-1'
  "128591" // 'pray'
];
