import React from 'react'
import { useNotificationsContext } from '../../contexts/NotificationsContextProvider'

function DropZone({ children, userRole }) {
  const { onUploadFiles, uploadEventRef } = useNotificationsContext()
  const [dragedOver, setDragedOver] = React.useState(false)

  const handleOnDrop = event => {
    stopEvent(event)
    const { files } = event.dataTransfer
    onUploadFiles(files)
    uploadEventRef.current = event
    setDragedOver(false)
  }

  const onDragLeave = event => {
    stopEvent(event)
    setDragedOver(false)
  }
  const onDragOver = event => {
    stopEvent(event)
    setDragedOver(true)
  }

  const stopEvent = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  return userRole !== 'VIEWER' ? (
    <div
      onDrop={handleOnDrop}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      style={{
        // outline: dragedOver ? '5px solid #3592FA' : 'inherit',
        // outlineOffset: '-5px',
        background: dragedOver ? 'rgb(53, 146, 250, 0.2)' : 'inherit',
        height: '100%',
        overflowY: 'auto'
      }}
      className="dropzone"
    >
      <input
        name="files-upload"
        id="files-upload"
        accept="image/*"
        type="file"
        multiple
        // onChange={onFilesAdded}
        style={{ display: 'none' }}
      />
      {children}
    </div>
  ) : (
    <div>{children}</div>
  )
}

export default DropZone
