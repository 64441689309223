import React from "react";
import {
  ImageIcon,
  DocumentIcon,
  PlusIcon,
  VideoIcon,
  AudioIcon,
  NoteIcon
} from "@akord/addon-icons";

export const rowNames = [
  { label: "ID", key: "refId" },
  { label: "Type", key: "type" },
  { label: "Size", key: "amount" },
  { label: "Time", key: "timestamp" },
  { label: "Status", key: "status" },
  { label: "", key: "" }
];
export const arweaveURL = "https://viewblock.io/arweave/tx/";

export const bytesToGb = (bytes, decimals = 2) => {
  if (bytes === 0 || !bytes) return { size: 0, sizeType: "b" };
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["b", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return {
    size: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
    sizeType: sizes[i]
  };
};

const storageColors = {
  documents: "#254DF8",
  photos: "#3F9FB4",
  video: "#A167FF",
  audio: "#BA3333",
  other: "#CCB400"
};

export const prepareDataForBar = storageData => {
  if (!storageData) return;
  const data = [];
  for (let key in storageData) {
    switch (key) {
      case "video":
      case "photos":
      case "audio":
      case "other":
        data.push({
          description: key?.charAt(0)?.toUpperCase() + key?.slice(1), //to uppercase first letter
          value: storageData[key] < 0 ? 0 : storageData[key], // Bug when storage is negative number
          color: storageColors[key]
        });
        break;
      // Combining notes and documents
      case "documents":
      case "notes":
        data.forEach(item => {
          if (item.description === "Documents") {
            item.value += storageData[key];
          } else {
            data.push({
              description: "Documents",
              value: storageData[key] < 0 ? 0 : storageData[key],
              color: storageColors["documents"]
            });
          }
        });
        break;
      default:
        break;
    }
  }
  const freeSpace =
    storageData["total_storage"] -
    (storageData["storage_used"] < 0 ? 0 : storageData["storage_used"]);

  data.push({
    description: "Free",
    value: freeSpace,
    color: "transparent"
  });
  return data;
};

export const createDataForTable = ({
  refId,
  refHash,
  type,
  amount,
  timestamp,
  status,
  stackId
}) => {
  return { refId, refHash, type, amount, timestamp, status, stackId };
};

export const formatRefId = refId => {
  if (!refId) return "–";
  const formated = refId.slice(0, 4) + "..." + refId.slice(-4);
  return formated;
};

export const getTypeIconAndTitle = type => {
  switch (type) {
    case "TOPUP":
      return (
        <>
          <PlusIcon
            fontSize="small"
            style={{ marginRight: 8 }}
            color="secondary"
          />
          Top up
        </>
      );
    case "PHOTOS":
      return (
        <>
          <ImageIcon
            fontSize="small"
            style={{ marginRight: 8 }}
            color="secondary"
          />
          Photo
        </>
      );
    case "VIDEO":
      return (
        <>
          <VideoIcon
            fontSize="small"
            style={{ marginRight: 8 }}
            color="secondary"
          />
          Video
        </>
      );
    case "AUDIO":
      return (
        <>
          <AudioIcon
            fontSize="small"
            style={{ marginRight: 8 }}
            color="secondary"
          />
          Audio
        </>
      );
    case "DOCUMENTS":
      return (
        <>
          <DocumentIcon
            fontSize="small"
            style={{ marginRight: 8 }}
            color="secondary"
          />
          Document
        </>
      );
    case "NOTES":
      return (
        <>
          <NoteIcon
            fontSize="small"
            style={{ marginRight: 8 }}
            color="secondary"
          />
          Note
        </>
      );

    default:
      return (
        <>
          <DocumentIcon
            fontSize="small"
            style={{ marginRight: 4 }}
            color="secondary"
          />
          Other
        </>
      );
  }
};
