import { akordLinks } from "../../../helpers/akordTexts";
import {
  getDataRoomStatus,
  getDataRoomId,
  getFolderId
} from "../../../helpers/helpers";
import {
  AvatarIcon,
  FeedbackIcon,
  LogoutIcon,
  SupportIcon,
  DiscordIcon,
  TicketIcon,
  UploadIcon,
  NoteIcon,
  FolderOutlineIcon
} from "@akord/addon-icons";

import { Auth } from "aws-amplify";

export const dropdownMenuData = {
  avatarMenu: (
    history,
    onIsAuthenticated,
    onShowUpload,
    handleMenuClose,
    folderId,
    setConfirmFormType,
    setVisibility,
    setData,
    currentMembership,
    referralsCount
  ) => [
    {
      icon: AvatarIcon,
      text: "Profile",
      action: () => history.push("/profile")
    },
    {
      icon: SupportIcon,
      text: "Support",
      action: () => window.open(akordLinks["support"])
    },
    {
      icon: FeedbackIcon,
      text: "Send feedback",
      action: () => window.open(akordLinks["feedback"])
    },
    {
      icon: TicketIcon,
      text: "Referral reward",
      action: () => history.push("/referral"),
      disabled: referralsCount >= 10,
      showTooltip: referralsCount >= 10,
      tooltipText: "No referral rewards available"
    },
    {
      icon: DiscordIcon,
      text: "Join our Discord",
      action: () => window.open(akordLinks["discord"]),
      divider: true
    },
    {
      icon: LogoutIcon,
      text: "Log out",
      action: async () => {
        try {
          await Auth.signOut();
          onIsAuthenticated(false);
          history.push("/sign-in");
        } catch (error) {
          console.log("SIGN OUT ERROR: ", error);
        }
      }
    }
  ],
  plusMenu: (
    history,
    onIsAuthenticated,
    onShowUpload,
    handleMenuClose,
    folderId,
    setConfirmFormType,
    setVisibility,
    setData,
    currentMembership
  ) => [
    {
      icon: UploadIcon,
      text: "Upload a file",
      action: () => {
        onShowUpload(true);
        handleMenuClose();
      },
      upload: true
    },
    {
      icon: NoteIcon,
      text: "Create a note",
      action: () => {
        history.push({
          pathname: `/vaults/${getDataRoomStatus(
            history.location.pathname
          )}/${getDataRoomId(history.location.pathname)}/assets/create-note`,
          state: { folderId: getFolderId(history.location.pathname) }
        });
      }
    },
    {
      text: folderId ? "Create a sub folder" : "Create a folder",
      icon: FolderOutlineIcon,
      action: () => {
        setConfirmFormType("folderCreate");
        setVisibility(null, "folderCreate");
        setData({
          title: "",
          dataRoomId: currentMembership.dataRoomId,
          folderId: folderId
        });
        handleMenuClose();
      }
      // disabled: userRole === "VIEWER",
      // show: !isRoomArchived
    }
  ]
};
