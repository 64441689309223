import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, Box } from "@mui/material";
import { LetterIcon } from "@akord/addon-icons";
import { useSignupContext } from "../../contexts/SignupContext";
import { handleResendEmail } from "./signup-helpers";

const useStyles = makeStyles({
  container: {
    paddingTop: "128px",
    height: "60%",
    textAlign: "center"
  }
});

function DoneSingUp() {
  const { formData } = useSignupContext();
  const classes = useStyles();

  const [resendClick, setResendClick] = useState(false);
  const handleResendClick = value => setResendClick(value);

  return (
    <Box className={classes.container}>
      <Box mb={4}>
        <div
          style={{
            margin: "auto",
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            border: "1px solid #F85B15",
            display: "flex"
          }}
        >
          <LetterIcon color="action" style={{ margin: "auto" }} />
        </div>
      </Box>
      <Box mb={2}>
        <Typography variant="h1" align="center">
          Final step – verify your email
        </Typography>
      </Box>
      <Typography variant="body2" paragraph align="center">
        To complete your account creation, please click the link we’ve sent to
        your email address. If you don’t see the email, check your spam.
      </Typography>
      <Typography
        variant="body2"
        align="center"
        component="a"
        color="text.primary"
        onClick={() => handleResendEmail(formData, handleResendClick)}
      >
        {resendClick ? "Resent!" : "Resend email"}
      </Typography>
    </Box>
  );
}

export default DoneSingUp;
