import React from "react";
import { Box, Typography } from "@mui/material";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";

function SelectAll({ revoked, allItemsInVault }) {
  const { onSelectedItems, selectedItemsMap } = useAssetsContext();

  return (
    <Box display="flex">
      <Typography
        variant="body2"
        color="text.secondary"
        style={{ whiteSpace: "pre" }}
      >
        {selectedItemsMap.size} items selected.{" "}
      </Typography>
      <Typography
        component="a"
        variant="body2"
        onClick={() => onSelectedItems("allItems", null, revoked)}
      >
        {selectedItemsMap.size < allItemsInVault.length ? (
          <>
            Select all
            <Typography
              component="span"
              variant="body2"
              className="strong"
              style={{ color: "inherit" }}
            >
              {" "}
              {allItemsInVault.length}{" "}
            </Typography>
            items in the vault.
          </>
        ) : (
          "Clear selection."
        )}
      </Typography>
    </Box>
  );
}

export default SelectAll;
