import API, { graphqlOperation } from "@aws-amplify/api";
import {
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import createDOMPurify from "dompurify";
import linkifyHtml from "linkifyjs/html";
import React, { useEffect, useState } from "react";
import { CloseInCircleIcon, AddReactionOutlinedIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import * as mutations from "../../graphql/mutations";
import {
  addMemoReaction,
  removeMemoReaction
} from "../../helpers/akord-actions";
import {
  formatDate,
  formatDateAtTime,
  getDataRoomId
} from "../../helpers/helpers";
import { grey } from "../../theme/colors";
import { useLocation } from "react-router-dom";
import { reactionEmojiCodePoints } from "../../helpers/chat-helpers";

const DOMPurify = createDOMPurify(window);

const MEMO_DELAY_MILISECONDS = 5000;

//https://github.com/cure53/DOMPurify/issues/317
DOMPurify.addHook("afterSanitizeAttributes", function (node) {
  // set all elements owning target to target=_blank
  if ("target" in node) {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener");
  }
});

const useStyles = makeStyles(theme =>
  createStyles({
    memoBubble: {
      display: "inline-block",
      lineHeight: 1.4,
      padding: "8px 12px",
      borderRadius: "4px"
    },
    link: {
      borderBottom: "none",
      borderBottomColor: theme.palette.text.primary,
      color: theme.palette.text.tertiary
    },
    reactionPicker: {
      display: "flex",
      gap: 1,
      padding: 7,
      position: "absolute",
      borderRadius: 8,
      background: ({ darkMode }) => (darkMode ? grey[800] : "#FFF"),
      filter: ({ darkMode }) =>
        darkMode ? "" : `drop-shadow(0 4px 4px ${grey[500]})`,
      "@media (max-width: 410px)": {
        marginRight: "70%",
        left: 0
      },
      "@media (max-width: 998px)": {
        marginRight: "10%"
      }
    },
    buttonRoot: {
      padding: "8px",
      "&:hover": {
        backgroundColor: ({ darkMode }) => (darkMode ? grey[900] : grey[200])
      }
    },
    reactionCount: {
      marginLeft: 5,
      marginBottom: 14,
      marginRight: 8,
      fontSize: 10,
      color: ({ darkMode }) => (darkMode ? grey[400] : grey[500])
    },
    spaceBetween: {
      display: "flex",
      justifyContent: "space-between"
    },
    flexEnd: {
      display: "flex",
      alignItems: "flex-end"
    }
  })
);

function ChatMemoItem({ positionedBeforeDate, operation, color }) {
  const { darkMode, isMobile, decryptedProfileDetails } = useGlobalContext();
  const {
    onCancelledTimelineItemsCount,
    cancelledTimelineItemsCount,
    ledgerWrapper,
    userRole,
    isRoomArchived
  } = useVaultContext();

  const [isMemoHovered, setIsMemoHovered] = useState(false);
  const [isDelayed, setIsDelayed] = useState(false);
  const [isComitted, setIsComitted] = useState(false);
  const [showReactions, setShowReactions] = useState(false);
  const [showAddReaction, setShowAddReaction] = useState(false);

  const classes = useStyles({
    color: color,
    darkMode: darkMode,
    isMobile: isMobile
  });

  const commitMemoTimestamp = new Date(
    new Date(operation.postedAt).getTime() + MEMO_DELAY_MILISECONDS
  );

  const location = useLocation();
  const dataRoomId = getDataRoomId(location.pathname);

  const memoContainer = React.useRef();
  const emojiNode = React.useRef();
  const emojiButton = React.useRef();

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    const now = new Date();
    if (now < commitMemoTimestamp) {
      setIsDelayed(true);
      setTimeout(() => {
        setIsDelayed(false);
      }, commitMemoTimestamp - now);
    }
  }, []);

  useEffect(() => {
    if (operation.hash) {
      setIsComitted(true);
    }
  }, [operation]);

  useEffect(() => {
    if (isMemoHovered && isComitted) {
      setShowAddReaction(true);
    } else {
      setShowAddReaction(false);
    }
  }, [isComitted, isMemoHovered]);

  const proccessReactions = memoReactions => {
    const reactions = new Map();
    memoReactions
      .filter(reactionData => reactionData.status === "ACTIVE")
      .map(reactionData => {
        const reactionsData = reactions.get(reactionData.reaction) || [];
        reactionsData.push(reactionData);
        reactions.set(reactionData.reaction, reactionsData);
      });
    return reactions;
  };

  const getReaction = reaction => {
    return (
      operation.reactions &&
      operation.reactions.find(
        item =>
          item.publicSigningKey === decryptedProfileDetails.publicSigningKey &&
          item.reaction == reaction &&
          item.status === "ACTIVE"
      )
    );
  };

  const isReactionAdded = reaction => {
    return (
      operation.reactions &&
      operation.reactions.some(
        item =>
          item.status === "ACTIVE" &&
          item.publicSigningKey === decryptedProfileDetails.publicSigningKey &&
          item.reaction == reaction
      )
    );
  };

  const removeReaction = reaction => {
    if (isReactionAdded(reaction) && isComitted) {
      const reactionData = getReaction(reaction);
      removeMemoReaction(
        ledgerWrapper,
        operation.hash,
        dataRoomId,
        reactionData
      );
      operation.reactions = operation.reactions.filter(
        item =>
          !(
            item.publicSigningKey ===
              decryptedProfileDetails.publicSigningKey &&
            item.reaction === reaction
          )
      );
      setIsComitted(false);
    }
  };

  const emojiFromDecimalString = decimalString => {
    const codePoints = decimalString.split(",").map(string => parseInt(string));
    try {
      return String.fromCodePoint(...codePoints);
    } catch (e) {
      return null;
    }
  };

  const addreaction = reactionEmoji => {
    if (!isReactionAdded(reactionEmoji) && isComitted) {
      addMemoReaction(
        ledgerWrapper,
        operation.hash,
        decryptedProfileDetails,
        dataRoomId,
        reactionEmoji
      );
      operation.reactions = operation.reactions || [];
      operation.reactions.push({
        publicSigningKey: decryptedProfileDetails.publicSigningKey,
        reaction: reactionEmoji,
        name: "You",
        postedAt: new Date(),
        status: "ACTIVE"
      });
      setIsComitted(false);
    }
    setShowReactions(false);
  };

  const cancelTransaction = async () => {
    operation.isCancelled = true;
    onCancelledTimelineItemsCount(cancelledTimelineItemsCount + 1);
    await API.graphql(
      graphqlOperation(mutations.cancelLazyTransaction, {
        id: operation.clientHash
      })
    );
  };

  const makeEmojiBigger = text => {
    const emojiRegex = /([\ud800-\udbff])([\udc00-\udfff])/g;
    const updateText = text?.replace(
      emojiRegex,
      match =>
        `<span style=font-size:${
          text.length === 2 ? "32px" : "20px"
        };line-height:${text.length === 2 ? "32px" : "20px"}>${match}</span>`
    );
    return updateText;
  };

  const handleClick = e => {
    if (
      (emojiButton.current && emojiButton.current.contains(e.target)) ||
      (emojiNode.current && emojiNode.current.contains(e.target))
    )
      return;

    setShowReactions(false);
  };

  const hasContainerRightSpace = () => {
    const memoContainerWidth =
      memoContainer?.current?.getBoundingClientRect()?.width;
    return screen.width > 920 || memoContainerWidth < 250;
  };

  const formatTooltipText = reactions => {
    if (!reactions) {
      return null;
    }
    const tooltipText = new Map();
    reactions.forEach(reaction => {
      const date = reaction.postedAt ? formatDateAtTime(reaction.postedAt) : "";
      if (
        decryptedProfileDetails &&
        decryptedProfileDetails.publicSigningKey === reaction.publicSigningKey
      ) {
        tooltipText.set("You", date);
      } else {
        tooltipText.set(reaction.name, date);
      }
    });
    return Array.from(tooltipText.entries()).map((text, idx) => {
      return (
        <div key={idx}>
          <b>{text[0]}</b> {text[1]}
        </div>
      );
    });
  };

  const linkOptions = {
    defaultProtocol: "https",
    className: classes.link,
    target: { url: "_blank", email: "_blank" },
    validate: true
  };

  // If reaction is added increase margin bottom
  const isActiveReactionsPresent = operation => {
    if (operation.reactions) {
      if (operation.reactions?.some(reaction => reaction.status === "ACTIVE"))
        return true;
    } else return false;
  };

  return (
    <Box
      style={{
        marginBottom: positionedBeforeDate
          ? "74px"
          : isActiveReactionsPresent(operation)
          ? "0px"
          : "16px"
      }}
      onMouseEnter={() => setIsMemoHovered(true)}
      onMouseLeave={() => setIsMemoHovered(false)}
    >
      <Box className={classes.flexEnd}>
        <div
          style={{
            maxWidth: "440px"
          }}
        >
          <div
            className={classes.memoBubble}
            style={{ border: `1px solid ${color}` }}
            data-color={color}
            id="memo-container"
            ref={memoContainer}
          >
            <Box className={classes.spaceBetween}>
              <Box>
                <Tooltip title={operation.ownerInfo.email} arrow>
                  <Typography
                    variant="body2"
                    component="span"
                    className="small strong"
                    color="text.primary"
                    style={{ marginRight: 8 }}
                  >
                    {operation.ownerInfo.fullName || operation.ownerInfo.email}
                  </Typography>
                </Tooltip>
                <Typography
                  variant="caption"
                  className="small"
                  color="text.secondary"
                >
                  {formatDate(operation.createdAt, true)}
                </Typography>
              </Box>

              <Box style={{ display: "flex", alignItems: "center" }}>
                {!isComitted && (
                  <CircularProgress
                    component="span"
                    size={16}
                    style={{
                      marginRight: 5,
                      marginLeft: 15,
                      position: "inherit"
                    }}
                  />
                )}
                {isDelayed && (
                  <Tooltip title="Cancel" arrow>
                    <span>
                      <IconButton
                        onClick={() => cancelTransaction()}
                        size="large"
                      >
                        <CloseInCircleIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </Box>
            </Box>
            <Box className={classes.spaceBetween}>
              <Typography
                paragraph
                variant="body2"
                className="small"
                style={{ wordBreak: "break-word", whiteSpace: "break-spaces" }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    linkifyHtml(makeEmojiBigger(operation.message), linkOptions)
                  )
                }}
              ></Typography>
            </Box>
          </div>
        </div>

        <Box
          style={{
            display: "flex",
            justifyContent: hasContainerRightSpace() ? "center" : "flex-end",
            minWidth: "20px"
          }}
        >
          {showAddReaction && (
            <Tooltip title="React" arrow style={{ marginLeft: "5px" }}>
              <span>
                <IconButton
                  ref={emojiButton}
                  disabled={userRole === "VIEWER" || isRoomArchived}
                  disableRipple
                  edge="end"
                  aria-label="open emojis"
                  onClick={() => setShowReactions(!showReactions)}
                  style={{ verticalAlign: "bottom" }}
                  size="large"
                >
                  <AddReactionOutlinedIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {showReactions && (
            <Box
              ref={emojiNode}
              className={classes.reactionPicker}
              style={{
                bottom:
                  positionedBeforeDate && operation.reactions?.length > 0
                    ? "135px"
                    : operation.reactions?.length > 0
                    ? "60px"
                    : positionedBeforeDate
                    ? "100px"
                    : "40px",
                marginRight: !hasContainerRightSpace() && "-30px"
              }}
            >
              {reactionEmojiCodePoints.map((emojiId, index) => {
                return (
                  <IconButton
                    variant="contained"
                    classes={{
                      root: classes.buttonRoot
                    }}
                    onClick={() => addreaction(emojiId)}
                    key={index}
                    size="large"
                  >
                    <Box fontSize={22} lineHeight={"22px"}>
                      {emojiFromDecimalString(emojiId)}
                    </Box>
                  </IconButton>
                );
              })}
            </Box>
          )}
        </Box>
      </Box>
      <Box style={{ display: "flex", marginTop: "3px" }}>
        {operation.reactions &&
          Array.from(proccessReactions(operation.reactions).entries()).map(
            (entry, index) => {
              const [emojiId, reactions] = entry;
              return (
                <Box className={classes.flexEnd} key={index}>
                  <Tooltip
                    title={
                      <React.Fragment>
                        {formatTooltipText(reactions)}
                      </React.Fragment>
                    }
                    arrow
                    style={{ paddingLeft: 15 }}
                    key={index}
                  >
                    <Typography
                      paragraph
                      variant="body2"
                      className="small"
                      style={{
                        wordBreak: "break-word",
                        whiteSpace: "break-spaces",
                        marginTop: 2
                      }}
                    >
                      <IconButton
                        onClick={() => removeReaction(emojiId)}
                        size="large"
                      >
                        <Box fontSize={16} lineHeight={"16px"}>
                          {emojiFromDecimalString(emojiId)}
                        </Box>
                      </IconButton>
                    </Typography>
                  </Tooltip>
                  <Typography
                    variant="caption"
                    className={classes.reactionCount}
                  >
                    {reactions.length}
                  </Typography>
                </Box>
              );
            }
          )}
      </Box>
    </Box>
  );
}

export default ChatMemoItem;
