import React from "react";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { Box, Typography, Tooltip } from "@mui/material";
import { BannerIcon, InfoIcon } from "@akord/addon-icons";

const ToolTipText = () => (
  <Box m={2}>
    <Typography
      variant="body2"
      className="xs"
      style={{ marginBottom: 8, color: "#FFF" }}
    >
      When someone you invite to a vault signs up to Akord for the first time,
      we’ll top up your account automatically with 100 MB of free storage.
    </Typography>
  </Box>
);

const ReferralBanner = () => {
  const { darkMode } = useGlobalContext();
  return (
    <Box display="flex">
      <Box display="flex" justifyContent="center" alignItems="center">
        <BannerIcon
          viewBox="0 0 174 23"
          style={{
            width: "174px",
            color: darkMode ? "#0576F5" : "#3592FA"
          }}
        />
        <Box style={{ position: "absolute" }}>
          <Typography
            variant="caption"
            className="strong"
            style={{ color: "#FFF" }}
          >
            100 MB{" "}
          </Typography>
          <Typography variant="caption" style={{ color: "#FFF" }}>
            Referral Reward
          </Typography>
        </Box>
      </Box>
      <Tooltip placement="bottom-end" title={<ToolTipText />}>
        <span>
          <InfoIcon
            fontSize="small"
            color="secondary"
            style={{ marginLeft: 4 }}
          />
        </span>
      </Tooltip>
    </Box>
  );
};

export default ReferralBanner;
