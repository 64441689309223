export const HASH_ALGORITHM = "SHA-256";

export const SYMMETRIC_KEY_ALGORITHM = "AES-GCM";
export const SYMMETRIC_KEY_LENGTH = 256;
export const IV_LENGTH = 12;

export const KEY_DERIVATION_FUNCTION = "PBKDF2";
export const KEY_DERIVATION_ITERATION_COUNT = 150000;
export const SALT_LENGTH = 16;

export const HD_SIGNING_PATH = "m/0'/0'/0";
export const HD_ENCRYPTION_PATH = "m/1'/0'/0";

export const DB_VERSION = 1;
export const DB_NAME = "Akord";
export const KEYSTORE_NAME = "Keystore";

export const LEDGER_PROFILE_WRITE = "akord:profile:write";
export const LEDGER_DATAROOM_WRITE = "akord:dataroom:write";
export const LEDGER_DATAROOM_ARCHIVE = "akord:dataroom:archive";
export const LEDGER_DATAROOM_RESTORE = "akord:dataroom:restore";
export const LEDGER_DATAROOM_DELETE = "akord:dataroom:delete";
export const LEDGER_MEMBERSHIP_WRITE = "akord:membership:write";
export const LEDGER_MEMBERSHIP_OWNER = "akord:membership:owner";
export const LEDGER_MEMBERSHIP_INVITE = "akord:membership:invite";
export const LEDGER_MEMBERSHIP_INVITE_NEW_USER =
  "akord:membership:invite-new-user";
export const LEDGER_MEMBERSHIP_CONFIRM = "akord:membership:confirm";
export const LEDGER_MEMBERSHIP_ACCEPT = "akord:membership:accept";
export const LEDGER_MEMBERSHIP_REJECT = "akord:membership:reject";
export const LEDGER_MEMBERSHIP_REVOKE = "akord:membership:revoke";
export const LEDGER_STACK_WRITE = "akord:stack:write";
export const LEDGER_STACK_REVOKE = "akord:stack:revoke";
export const LEDGER_STACK_RESTORE = "akord:stack:restore";
export const LEDGER_STACK_DELETE = "akord:stack:delete";
export const LEDGER_MEMO_WRITE = "akord:memo:write";
export const LEDGER_MEMO_ADD_REACTION = "akord:memo:add-reaction";
export const LEDGER_MEMO_REMOVE_REACTION = "akord:memo:remove-reaction";
export const LEDGER_FOLDER_WRITE = "akord:folder:write";
export const LEDGER_FOLDER_REVOKE = "akord:folder:revoke";
export const LEDGER_FOLDER_RESTORE = "akord:folder:restore";
export const LEDGER_FOLDER_DELETE = "akord:folder:delete";
export const LEDGER_NOTE_WRITE = "akord:note:write";
export const LEDGER_NOTE_REVOKE = "akord:note:revoke";
export const LEDGER_NOTE_RESTORE = "akord:note:restore";
export const LEDGER_NOTE_DELETE = "akord:note:delete";
