import React from "react";
import { Route, Switch } from "react-router-dom";
import DialogNoteViewer from "../../components/common/FileViewer/DialogNoteViewer";
import AssetsGallery from "../../components/gallery/AssetsGallery";
import FileViewerContextProvider from "../../contexts/FileViewerContextProvider";
import ShowStack from "./ShowStack";

function Stacks(props) {
  return (
    <Switch>
      <Route
        path="/vaults/:dataRoomStatus/:dataRoomId/gallery/folders/:folderId"
        render={() => <AssetsGallery />}
      />
      <Route
        path="/vaults/:dataRoomStatus/:dataRoomId/gallery"
        render={() => <AssetsGallery />}
      />
      <Route
        path="/vaults/:dataRoomStatus/:dataRoomId/:roomView/note/:noteId"
        exact
        render={() => (
          <FileViewerContextProvider>
            <DialogNoteViewer />
          </FileViewerContextProvider>
        )}
      />
      <Route
        path="/vaults/:dataRoomStatus/:dataRoomId/:roomView"
        render={() => <ShowStack {...props} />}
      />
    </Switch>
  );
}

export default Stacks;
