import Dexie from 'dexie'

export const db = new Dexie('AkordDB')
db.version(1).stores({
  profile: 'id',
  memberships: 'dataRoomId',
  status: '++id'
})

export const updateDB = async data => {
  if (data.profile) {
    db.table('profile')
      .update(data.profile.id, data.profile)
      .then(updated => {
        if (!updated) db.table('profile').add(data.profile)
      })
  }

  let bulkMemberships = []
  const filteredData = data.memberships.filter(
    membership => membership
    // Filtering out [null] which we get on Profile update
    // TODO: set everything to DB filter later for UI
  )
  const fullUpdate = filteredData.map(async membership => {
    if (membership.status === 'REVOKED' || membership.status === 'REJECTED') {
      await db.table('memberships').delete(membership.dataRoomId)
      return membership
    } else {
      bulkMemberships.push(membership)
      return membership
    }
  })

  Promise.all(fullUpdate).then(() => {
    if (bulkMemberships.length) {
      db.memberships
        .bulkPut(bulkMemberships)
        .catch(Dexie.BulkError, function (e) {
          // Explicitely catching the bulkAdd() operation makes those successful
          // additions commit despite that there were errors.
          console.error(
            'Some memberships did not succeed. However, ' +
              bulkMemberships.length -
              e.failures.length +
              ' memberships was added successfully'
          )
        })
    }
    // Table status is to know when the data is saved to DB in order to show skeleton/empty/vaults
    // After singup on first login we don't have profile, need to wait for the subscription
    // to get the data. Until then showing the skeleton.
    if (data.profile) db.status.put({ isLoaded: true })
  })
}

export const clearDB = async () => {
  if (checkDB()) {
    await db.memberships.clear()
    await db.profile.clear()
    await db.status.clear()
  }
}

export const checkDB = async () => {
  try {
    await db.open()
    return true
  } catch (err) {
    return false
  }
}
