import React from 'react'
import { IconButton, Typography } from '@mui/material'
import { useGlobalContext } from '../../../contexts/GlobalDataProvider'

function PaginationRow({ pages, currentPage, onChangePage }) {
  const { isMobile } = useGlobalContext()
  // Create an array of pages to show as limited (only three pages) pagination
  const pagesToArray = Array.from({ length: pages }, (_, i) => i + 1)
  const pagesToShow = pagesToArray.slice(
    currentPage === 1
      ? 0
      : currentPage === pages
      ? currentPage - 3
      : currentPage - 2,
    currentPage === 1
      ? currentPage + 2
      : currentPage === pages
      ? currentPage
      : currentPage + 1
  )

  return pagesToShow.map((actualNum, index) => (
    <IconButton key={index} onClick={() => onChangePage(actualNum)} size="large">
      <Typography
        variant="body2"
        className={actualNum === currentPage ? 'strong' : ''}
        style={{
          marginRight:
            index + 1 < pagesToShow.length ? (isMobile ? '20px' : '12px') : 0
        }}
      >
        {actualNum}
      </Typography>
    </IconButton>
  ));
}

export default PaginationRow
