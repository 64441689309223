const grey = {
  900: "#222126",
  800: "#312F37",
  700: "#403F46",
  600: "#53515C",
  500: "#7C7986",
  400: "#9D9BA6",
  300: "#D1CFD8",
  200: "#E8E6EF",
  100: "#F7F6FA"
};
const orange = {
  // 900: '#4D1800',
  900: "#3A241F",
  800: "#652001",
  700: "#9B3203",
  600: "#D04406",
  500: "#F85B15",
  400: "#FB814B",
  300: "#FD9668",
  200: "#FEB28F",
  100: "#FFEAE0"
};
const blue = {
  900: "#19355A",
  800: "#0152AD",
  700: "#0365D3",
  600: "#0576F5",
  500: "#3592FA",
  400: "#69AEFC",
  300: "#90C4FD",
  200: "#C2DFFF",
  100: "#EBF4FF"
};

export { grey, orange, blue };
