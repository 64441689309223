import { Hub } from 'aws-amplify';
import { clearDB } from '../../helpers/db'
import { AkordWallet } from '@akord/crypto'
import { cookieStorage } from '../../helpers/cookie-storage';

export const registerAuthHandler = () => {

    const listener = async (data) => {
        switch (data.payload.event) {
            case 'signIn':
                cookieStorage.storeCognitoUser(data.payload.data.username, data.payload.data.attributes["email"])
                break;
            case 'signUp':
                cookieStorage.storeCognitoUser(data.payload.data.userSub, data.payload.data.user.username)
                localStorage.removeItem('height')
                await AkordWallet.clear()
                await clearDB()
                break;
            case 'signOut':
            case 'tokenRefresh_failure':
                await AkordWallet.clear()
                await clearDB()
                localStorage.clear()
                sessionStorage.clear()
                break;
        }
    }

    Hub.listen('auth', listener)

}
