import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  FormLabel,
  FormControl,
  Checkbox,
  FormControlLabel,
  Divider,
  Collapse,
  Typography,
  Box,
  FormGroup
} from "@mui/material";
import { periodListMap } from "./filterMap";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  CheckboxTickIcon,
  CheckboxEmptyIcon
} from "@akord/addon-icons";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";

const useStyles = makeStyles(theme => ({
  formControl: {
    width: "100%"
  },
  formControlLabelRoot: {
    justifyContent: "space-between",
    marginBottom: theme.spacing(5)
  },
  labelPlacementStart: {
    marginLeft: 0,
    marginRight: "-11px"
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
  resetText: {
    color: ({ hasTrueKeys }) => hasTrueKeys && theme.palette.primary.main
  },
  divider: {
    marginLeft: -20,
    marginRight: -20
  }
}));

function FilterPeriodMobile({ hasTrueKeys, keepOpen }) {
  const { darkMode } = useGlobalContext();
  const { periodFilter, onPeriodFilter } = useAssetsContext();
  const classes = useStyles({
    hasTrueKeys: hasTrueKeys,
    darkMode: darkMode
  });
  const [openActions, setOpenActions] = React.useState(false);

  const handleChange = event => {
    if (event.target.name === periodFilter) onPeriodFilter(null);
    else onPeriodFilter(event.target.name);
  };

  const handleClick = () => {
    setOpenActions(!openActions);
  };

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel
        component="div"
        className={classes.label}
        onClick={handleClick}
      >
        <Box mb={5} display="flex" justifyContent="space-between">
          <Typography variant="body2" className="strong" color="text.primary">
            Period
          </Typography>
          {openActions ? (
            <ChevronUpIcon color="disabled" />
          ) : (
            <ChevronDownIcon color="disabled" />
          )}
        </Box>
      </FormLabel>
      <Collapse in={openActions || keepOpen} timeout="auto" unmountOnExit>
        <FormGroup>
          {Object.keys(periodListMap).map((period, index) => {
            return (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={period === periodFilter}
                    className={classes.checkbox}
                    icon={<CheckboxEmptyIcon />}
                    checkedIcon={<CheckboxTickIcon />}
                    onChange={handleChange}
                    name={period}
                  />
                }
                labelPlacement="start"
                label={
                  <Typography variant="body2">
                    {periodListMap[period]}
                  </Typography>
                }
                name={period}
                classes={{
                  labelPlacementStart: classes.labelPlacementStart
                }}
                className={classes.formControlLabelRoot}
              />
            );
          })}
        </FormGroup>
      </Collapse>
      <Divider className={classes.divider} />
    </FormControl>
  );
}

export default FilterPeriodMobile;
