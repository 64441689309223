import React, { useState, useContext, useEffect } from "react";
import zxcvbn from "zxcvbn";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { withRouter } from "react-router";
const SignupContext = React.createContext();

const regexPassword = /^[\S]+.*[\S]+$/;

function SignupContextProvider({ children }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true
  });

  const [wallet, setWallet] = useState({});
  const handleWallet = newWallet => setWallet(newWallet);

  const [error, setError] = useState();
  const handleError = error => setError(error);

  const [passEvaluation, setPassEvaluation] = useState({});
  const handlePassEvaluation = evaluation => setPassEvaluation(evaluation);

  const [formData, setFormData] = useState({
    userEmail: "",
    userPassword: "",
    confirmUserPassword: "",
    getNewsletter: false,
    acceptTerms: false,
    acceptPhrase: false,
    referrerId: null,
    withdrawalRight: false
  });
  const handleFormChange = prop => event => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (prop === "userPassword") {
      const evaluation = zxcvbn(value);
      // AWS restrictions
      if (!regexPassword.test(value) && value.length >= 2)
        evaluation.score = -1;

      setPassEvaluation(evaluation);
    }
    setFormData({
      ...formData,
      [prop]: value
    });
  };

  const [missingWords, setMissingWords] = useState({});
  const handleMissingWords = words => setMissingWords(words);

  // verify referralId
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const referrerId = urlParams.get("referrerId");
    if (referrerId) {
      setFormData({
        ...formData,
        referrerId: referrerId
      });
    }
  }, [location.search]);

  // Clear errors on changing the input
  useEffect(() => {
    handleError();
  }, [formData.userEmail, missingWords]);

  // Clear filled in words on page change
  useEffect(() => {
    handleMissingWords({});
  }, [location.pathname]);

  return (
    <SignupContext.Provider
      value={{
        missingWords: missingWords,
        onMissingWords: handleMissingWords,
        formData: formData,
        onFormData: setFormData,
        handleFormChange: handleFormChange,
        passEvaluation: passEvaluation,
        handlePassEvaluation: handlePassEvaluation,
        error: error,
        handleError: handleError,
        isDesktop: isDesktop,
        handleWallet: handleWallet,
        wallet: wallet
      }}
    >
      {children}
    </SignupContext.Provider>
  );
}
export default withRouter(SignupContextProvider);

export const useSignupContext = () => useContext(SignupContext);
