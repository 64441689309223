export const memosByDataRoomId =
  /* GraphQL */
  `
    query MemosByDataRoomId(
      $dataRoomId: ID!
      $filter: ModelMemoFilterInput
      $limit: Int
      $nextToken: String
    ) {
      memosByDataRoomId(
        dataRoomId: $dataRoomId
        filter: $filter
        nextToken: $nextToken
        limit: $limit
        sortDirection: DESC
      ) {
        items {
          id
          hash
          prevHash
          refHash
          publicSigningKey
          postedAt
          contextVersion
          dataRoomId
          state {
            message
            reactions {
              publicSigningKey
              name
              reaction
              status
              postedAt
              refHash
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;
