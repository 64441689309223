import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, CssBaseline, Button, Container } from "@mui/material";
import { CloseIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import ThemeWrapper from "../../components/common/Wrappers/ThemeWrapper";

const useStyles = makeStyles({
  container: {
    paddingTop: 128,
    textAlign: "center"
  }
});

function RejectInvite({ location, history }) {
  const { isMobile, darkMode } = useGlobalContext();

  const revokedFrom = location?.state?.from ? location.state.from : null;

  const classes = useStyles({
    darkMode: darkMode
  });

  const getTitle = () => {
    switch (revokedFrom) {
      case "REVOKED":
        return "Your invite was revoked";
      case "REJECED":
        return "Terms of access rejected";

      default:
        return "Terms of access rejected";
    }
  };

  const getText = () => {
    switch (revokedFrom) {
      case "REVOKED":
        return "The invite to this vault was revoked. Contact the person who originally invited you for more information.";
      case "REJECTED":
        return "You have already rejected this invite. You will have to contact the person who invited you to ask for another invite";

      default:
        return "We’ve let the person who invited you to the vault know you rejected the terms of access.";
    }
  };

  return (
    <ThemeWrapper darkMode={true}>
      <CssBaseline />

      <Container maxWidth="xs" className={classes.container}>
        <Box mb={4}>
          <div
            style={{
              margin: "auto",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              border: "1px solid #F85B15",
              display: "flex"
            }}
          >
            <CloseIcon color="action" style={{ margin: "auto" }} />
          </div>
        </Box>
        <Box mb={2}>
          <Typography variant="h1" color="text.primary">
            {getTitle()}
          </Typography>
        </Box>
        <Box mb={5}>
          <Typography variant="body2" align="center">
            {getText()}
          </Typography>
        </Box>
        <Button
          fullWidth={isMobile}
          style={{ minWidth: "auto" }}
          color="primary"
          variant="contained"
          disableElevation
          type="button"
          className={classes.button}
          onClick={() => history.push("/")}
        >
          View your vaults
        </Button>
      </Container>
    </ThemeWrapper>
  );
}

export default RejectInvite;
