import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  AppBar,
  IconButton,
  Toolbar,
  Fab,
  TextField,
  Box
} from "@mui/material";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import { isArchived } from "../../helpers/helpers";
import { EmojiFaceIcon, PaperPlaneIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { grey } from "../../theme/colors";
import { v4 as uuidv4 } from "uuid";
import { createMemo } from "../../helpers/akord-actions";
import { useChatContext } from "../../contexts/ChatContextProvider";

const useStyles = makeStyles(theme => ({
  appBottomBar: {
    width: ({ isMobile }) => !isMobile && "calc(100% - 250px)",
    top: "auto",
    bottom: 0,
    borderTop: "1px solid",
    borderColor: ({ darkMode }) => (darkMode ? grey[700] : grey[300]),
    borderRadius: 0,
    minHeight: "55px",
    height: "auto"
  },
  grow: {
    flexGrow: 1
  },
  textField: {
    marginBottom: 0
  },
  outlinedInput: {
    cursor: ({ isMemberViewer }) =>
      isMemberViewer ? "not-allowed" : "inherit",
    border: "none!important",
    fontSize: "15px",
    padding: "6px 12px",
    "&::placeholder": {
      color: theme.palette.text.disabled
    },
    "&.Mui-disabled": {
      background: ({ darkMode }) =>
        darkMode ? `${grey[700]}!important` : `${grey[200]}!important`
    }
  },
  inputRoot: {
    fontSize: "15px",
    borderRadius: "20px",
    minWidth: "100px"
  },
  notchedOuline: {
    border: "none!important"
  },
  multiline: {
    padding: "0px",
    overflow: "hidden"
  },
  button: {
    height: "33px",
    minWidth: "auto",
    border: "none",
    borderRadius: "100px",
    padding: "0 16px",
    marginLeft: theme.spacing(3),
    marginBottom: 0
  },
  fab: {
    "&.Mui-disabled": {
      color: ({ darkMode }) =>
        darkMode ? `${grey[500]}!important` : `${grey[400]}!important`,
      backgroundColor: ({ darkMode }) =>
        darkMode ? `${grey[700]}!important` : `${grey[200]}!important`
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(2)
  },
  emojiBox: {
    position: "absolute",
    boxShadow: "none",
    bottom: "70px",
    left: "260px",
    marginBottom: "2px"
  },
  emojiButton: {
    padding: "4px 12px"
  }
}));

function MemoBar() {
  const { decryptedProfileDetails, darkMode, isMobile } = useGlobalContext();
  const { onDecryptedMemos, chatCurrentMembership } = useChatContext();

  const { ledgerWrapper, userRole } = useVaultContext();

  const isRoomArchived = isArchived(chatCurrentMembership);
  const classes = useStyles({
    darkMode: darkMode,
    isMobile: isMobile,
    isDisabled: userRole === "VIEWER" || isRoomArchived,
    isMemberViewer: userRole === "VIEWER",
    isRoomArchived: isRoomArchived
  });
  const [message, setMessage] = useState("");

  const [showEmoji, setShowEmoji] = useState(false);

  const emojiNode = React.useRef();
  const emojiButton = React.useRef();
  React.useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleMessageChange = e => {
    setMessage(e.target.value);
  };

  const handleMessageSubmit = async e => {
    e.preventDefault();
    if (message === "\n" || message === "") {
      setMessage("");
      return;
    }
    try {
      const preparedMessage = message.trim();
      // Optimistic memo action update
      const clientHash = uuidv4();
      // const newTimelineAction = {
      //   actionRef: "MEMO_WRITE",
      //   message: preparedMessage,
      //   ownerInfo: {
      //     email: decryptedProfileDetails.email,
      //     fullName: decryptedProfileDetails.fullName,
      //     avatarUrl: decryptedProfileDetails.avatarUrl
      //   },
      //   postedAt: new Date(),
      //   groupRef: null,
      //   clientHash: clientHash
      // };
      const newMemo = {
        message: preparedMessage,
        ownerInfo: {
          email: decryptedProfileDetails.email,
          fullName: decryptedProfileDetails.fullName,
          avatarUrl: decryptedProfileDetails.avatarUrl
        },
        postedAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        groupRef: null,
        clientHash: clientHash
      };
      onDecryptedMemos(prevState => [...prevState, newMemo]);
      setMessage("");
      await createMemo(
        ledgerWrapper,
        chatCurrentMembership.dataRoomId,
        clientHash,
        preparedMessage
      );
    } catch (err) {
      console.log("Error posting memo: ", err);
    }
  };

  const onEmojiClick = emoji => {
    setMessage(prevState => prevState + emoji.native);
  };

  const handleClick = e => {
    if (
      (emojiButton.current && emojiButton.current.contains(e.target)) ||
      (emojiNode.current && emojiNode.current.contains(e.target))
    )
      return;

    // outside click
    setShowEmoji(false);
  };

  const handleKeyDown = e => {
    if (e.nativeEvent.keyCode === 13) {
      if (e.shiftKey) return;
      else {
        if (e.target.value === "" || !e.target.value.replace(/\s/g, "").length)
          return;
        handleMessageSubmit(e);
      }
    }
  };

  return (
    <>
      {showEmoji && (
        <div ref={emojiNode}>
          <Picker
            theme="auto"
            showPreview={false}
            style={{
              position: "fixed",
              bottom: "70px",
              left: "260px",
              zIndex: 1
            }}
            onClick={onEmojiClick}
          />
        </div>
      )}
      <AppBar
        position="fixed"
        color="secondary"
        className={classes.appBottomBar}
      >
        <Toolbar
          component="form"
          onSubmit={handleMessageSubmit}
          onKeyDown={handleKeyDown}
        >
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            alignItems="center"
            paddingTop="9px"
            paddingBottom="10px"
          >
            {!isMobile && (
              <div
                style={{
                  cursor:
                    userRole === "VIEWER" || isRoomArchived
                      ? "not-allowed"
                      : "inherit"
                }}
              >
                <IconButton
                  disabled={userRole === "VIEWER" || isRoomArchived}
                  ref={emojiButton}
                  disableRipple
                  edge="start"
                  aria-label="open emojis"
                  onClick={() => setShowEmoji(!showEmoji)}
                  className={classes.emojiButton}
                  size="large"
                >
                  <EmojiFaceIcon />
                </IconButton>
              </div>
            )}
            <TextField
              disabled={userRole === "VIEWER" || isRoomArchived}
              fullWidth
              variant="outlined"
              color="secondary"
              multiline
              maxRows={5}
              value={message}
              onChange={handleMessageChange}
              placeholder="Write an encrypted message…"
              className={classes.textField}
              InputProps={{
                "aria-label": "message",
                classes: {
                  root: classes.inputRoot,
                  input: classes.outlinedInput,
                  multiline: classes.multiline,
                  notchedOutline:
                    (userRole === "VIEWER" || isRoomArchived) &&
                    classes.notchedOuline
                }
              }}
            />
            <div
              style={{
                cursor:
                  userRole === "VIEWER" || isRoomArchived
                    ? "not-allowed"
                    : "inherit"
              }}
            >
              <Box ml={4}>
                {isMobile ? (
                  <Fab
                    disabled={userRole === "VIEWER" || isRoomArchived}
                    component="button"
                    type="submit"
                    size="small"
                    color="primary"
                    className={classes.fab}
                  >
                    <PaperPlaneIcon
                      fontSize="small"
                      style={{ marginRight: 4 }}
                    />
                  </Fab>
                ) : (
                  <>
                    <Fab
                      disabled={userRole === "VIEWER" || isRoomArchived}
                      component="button"
                      variant="extended"
                      type="submit"
                      size="small"
                      color="primary"
                      className={classes.fab}
                    >
                      <PaperPlaneIcon
                        fontSize="small"
                        className={classes.extendedIcon}
                      />
                      Post
                    </Fab>
                  </>
                )}
              </Box>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default MemoBar;
