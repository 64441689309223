import React, { useState, createContext, useContext } from "react";
import useModal from "../helpers/useModal";
const Context = createContext();

const inititalState = {
  id: "",
  title: "",
  extension: "",
  fileType: "",
  hash: "",
  membersArray: [],
  memberToRevoke: {},
  dataRoomId: "",
  folderId: null,
  batchItems: new Map(),
  noteText: ""
};

function NotificationsContextProvider({ children }) {
  //hack to let upload same file after cancel https://stackoverflow.com/questions/4109276/how-to-detect-input-type-file-change-for-the-same-file
  let uploadEventRef = React.useRef();
  const clearUploadEventRef = () => {
    uploadEventRef.current = null;
  };

  const [duplicateFiles, setDuplicateFiles] = React.useState([]);
  const handleDuplicateFiles = files => setDuplicateFiles(files);

  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const handleUploadFiles = files => setUploadedFiles(files);

  const [notificationData, setNotificationData] = useState(inititalState);
  const handleNotificationData = notificationData =>
    notificationData
      ? setNotificationData(notificationData)
      : setNotificationData(inititalState);

  const [
    isModalVisible,
    handleModalVisibility,
    confirmModalType,
    handleConfirmModalType,
    confirmModalFormType,
    onConfirmModalFormType
  ] = useModal();

  return (
    <Context.Provider
      value={{
        onNotificationData: handleNotificationData,
        notificationData: notificationData,
        modal: {
          isModalVisible: isModalVisible,
          onModalVisibility: handleModalVisibility,
          confirmModalType: confirmModalType,
          onConfirmModalType: handleConfirmModalType,
          confirmModalFormType: confirmModalFormType,
          onConfirmModalFormType: onConfirmModalFormType
        },
        uploadedFiles: uploadedFiles,
        onUploadFiles: handleUploadFiles,
        onDuplicateFiles: handleDuplicateFiles,
        duplicateFiles: duplicateFiles,
        uploadEventRef: uploadEventRef,
        clearUploadEventRef: clearUploadEventRef
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default NotificationsContextProvider;

export const withNotificationsContext = Component => props =>
  (
    <Context.Consumer>
      {notificationsContext => (
        <Component {...props} {...notificationsContext} />
      )}
    </Context.Consumer>
  );

export const useNotificationsContext = () => useContext(Context);
