import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { IconButton, Tooltip } from "@mui/material";
import { MenuDotsIcon, MenuDotsHorizontalIcon } from "@akord/addon-icons";

const useStyles = makeStyles(theme => ({
  menuIcon: {
    color: ({ menuActive }) => menuActive && theme.palette.primary.main,
    "&:hover": {
      color: ({ menuActive }) => menuActive && theme.palette.primary.main
    }
  }
}));

const MoreActionButton = ({
  handleClick,
  menuActive,
  hidden = false,
  horizontalButton,
  onboarding,
  disabled = false
}) => {
  const classes = useStyles({
    menuActive: menuActive
  });

  return (
    <Tooltip title="Menu" arrow>
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end"
        }}
      >
        <IconButton
          disabled={disabled}
          aria-label="display more actions"
          onClick={e => handleClick(e)}
          className={
            onboarding
              ? [classes.menuIcon, "step5"].join(" ")
              : classes.menuIcon
          }
          style={{ display: hidden ? "none" : "initial" }}
        >
          {horizontalButton ? <MenuDotsHorizontalIcon /> : <MenuDotsIcon />}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default MoreActionButton;
