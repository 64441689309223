import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { useGlobalContext } from "../../../contexts/GlobalDataProvider";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import { useSearchContext } from "../../../contexts/SearchContextProvider";
import { useSnackbarContext } from "../../../contexts/SnackbarContextProvider";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import muiTheme from "../../../theme/mui";
import FormSubmit from "../FormSubmit";
import { formModalOptions, handleModalFormSubmit } from "./form-modal-options";

const useStyles = makeStyles({
  dialog: {
    // height: '60%'
  },
  dialogTitle: {
    display: "flex"
    // padding: '16px 16px 0'
  },
  textField: {
    marginBottom: 0
  },
  root: {
    height: "48px!important"
  },
  icon: {
    marginRight: "8px"
  },
  button: {
    minWidth: "auto",
    marginLeft: ({ isDesktop }) =>
      isDesktop ? "24px!important" : "12px!important"
  },
  paperFullWidth: {
    // width: 'calc(100% - 32px)'
  },
  paper: {
    margin: "128px 16px"
    // minWidth: '300px'
  },
  buttonProgress: {
    position: "absolute"
  }
});

function RenamePopUp() {
  const { onSnackbarToShow } = useSnackbarContext();
  const { ledgerWrapper, modelIdByHash } = useVaultContext();
  const { modal, notificationData, onNotificationData } =
    useNotificationsContext();
  const { removeFromIndex } = useSearchContext();
  const { onTxSpinner } = useGlobalContext();

  const theme = muiTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true
  });
  const classes = useStyles({ isDesktop: isDesktop });
  const [disabledButton, setDisabledButton] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true)
  // }

  const handleClose = () => {
    modal.onModalVisibility(false, modal.confirmModalFormType);
    setDisabledButton(true);
  };

  const handleChange = event => {
    onNotificationData({ ...notificationData, title: event.target.value });
    setDisabledButton(false);
  };

  const handleSubmit = async () => {
    try {
      if (modal.confirmModalFormType !== "shareFile") {
        setDisabledButton(true);
        setLoading(true);
        handleSearchIndex();
        await handleModalFormSubmit(
          modal.confirmModalFormType,
          notificationData,
          ledgerWrapper
        );

        modal.onModalVisibility(false, modal.confirmModalFormType);
        onSnackbarToShow(modal.confirmModalFormType);
        setLoading(false);
        onTxSpinner(true);
      } else {
        await handleModalFormSubmit(
          modal.confirmModalFormType,
          notificationData,
          ledgerWrapper
        );
        modal.onModalVisibility(false, modal.confirmModalFormType);
        onSnackbarToShow(modal.confirmModalFormType);
      }
    } catch (err) {
      console.log("Error: ", err);
      setLoading(false);
    }
  };

  const handleSearchIndex = () => {
    const id = modelIdByHash(notificationData.hash);
    if (id) {
      removeFromIndex(id);
    }
  };

  const MenuIcon = formModalOptions[modal.confirmModalFormType].icon;

  return (
    <Dialog
      open={modal.isModalVisible[modal.confirmModalFormType]}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      classes={{
        container: classes.dialog,
        paperFullWidth: classes.paperFullWidth,
        paper: classes.paper
      }}
      TransitionProps={{
        onEntered: () => document.getElementById("name").select()
      }}
    >
      <FormSubmit
        onSubmit={async () => {
          await handleSubmit();
        }}
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
          <MenuIcon
            fontSize="medium"
            color="disabled"
            className={classes.icon}
          />
          <Typography className="strong" color="text.primary">
            {formModalOptions[modal.confirmModalFormType].title}
          </Typography>
        </DialogTitle>
        {formModalOptions[modal.confirmModalFormType].secondaryText && (
          <DialogContent>
            <Typography
              variant="body2"
              color="text.tertiary"
              style={{ whiteSpace: "pre-line" }}
              noWrap
            >
              {formModalOptions[modal.confirmModalFormType].secondaryText}
            </Typography>
          </DialogContent>
        )}
        <DialogContent style={{ overflowY: "initial" }}>
          <TextField
            autoFocus
            fullWidth
            variant="outlined"
            id="name"
            value={notificationData.title}
            placeholder={
              formModalOptions[modal.confirmModalFormType].placeholder
            }
            type="text"
            className={classes.textField}
            InputProps={{
              readOnly: modal.confirmModalFormType === "shareFile",
              classes: {
                root: classes.root
              }
            }}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            label="cancel"
            className={classes.button}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            fullWidth={false}
            disabled={
              disabledButton && modal.confirmModalFormType !== "shareFile"
            }
            type="submit"
            label="submit"
            disableElevation
            className={classes.button}
          >
            {formModalOptions[modal.confirmModalFormType].nextButton}
            {loading && (
              <CircularProgress
                component="span"
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </Button>
        </DialogActions>
      </FormSubmit>
    </Dialog>
  );
}

export default RenamePopUp;
