import React from "react";
import { isArchived } from "../../../helpers/helpers";
import {
  folderActiveMenuOptions,
  folderRevokeMenuOptions
} from "./stack-menu-options";
import { useVaultContext } from "../../../contexts/VaultContextProvider";
import { useProgressContext } from "../../../contexts/ProgressContext";
import { useNotificationsContext } from "../../../contexts/NotificationsContextProvider";
import MoreMenuRoot from "./MoreMenuRoot";
import MoreMenuItem from "./MoreMenuItem";
import { useAssetsContext } from "../../../contexts/AssetsContextProvider";

function MenuFolder({ anchorEl, handleMenuClose, openMenu, revokedFolder }) {
  const { modal, onNotificationData } = useNotificationsContext();
  const { onProgress } = useProgressContext();
  const { encrypter, currentMembership, userRole, currentStack } =
    useVaultContext();

  const {
    onShowLoader,
    onAxiosError,
    onShowUpload,
    onUploadStart,
    onDrawerActionType
  } = useAssetsContext();

  const isRoomArchived = isArchived(currentMembership);

  const folderMenuOptions = revokedFolder
    ? folderRevokeMenuOptions
    : folderActiveMenuOptions;

  if (!openMenu) return null;

  return (
    <MoreMenuRoot
      anchorEl={anchorEl}
      handleMenuClose={handleMenuClose}
      openMenu={openMenu}
    >
      <div>
        {folderMenuOptions(
          currentStack,
          encrypter,
          userRole,
          isRoomArchived,
          onShowLoader,
          onProgress,
          onAxiosError,
          onUploadStart,
          onDrawerActionType,
          onShowUpload,
          onNotificationData,
          modal.onModalVisibility,
          modal.onConfirmModalType,
          handleMenuClose,
          modal.onConfirmModalFormType
        ).map((menuItem, index) => {
          return (
            menuItem.show && (
              <MoreMenuItem
                key={index}
                menuItem={menuItem}
                handleMenuClose={handleMenuClose}
                currentStack={currentStack}
                onDrawerActionType={onDrawerActionType}
              />
            )
          );
        })}
      </div>
    </MoreMenuRoot>
  );
}

export default MenuFolder;
