import React, { useEffect } from "react";
import API, { graphqlOperation } from "@aws-amplify/api";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import queryString from "query-string";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Typography,
  Card,
  CardContent,
  FormControl,
  FormGroup,
  FormControlLabel
} from "@mui/material";
import { base64ToJson } from "@akord/crypto";
import * as queries from "../../graphql/queries";
import {
  ButtonGroup,
  StandAloneScreenWrapper,
  AkordCheckbox
} from "../../components/common";
import createLinkDecorator from "../timeline/timeline-actions/LinkDecorator";
import {
  membershipAccept,
  membershipReject
} from "../../helpers/manage-access-actions";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { grey } from "../../theme/colors";

const useStyles = makeStyles({
  card: {
    background: ({ darkMode }) => (darkMode ? grey[800] : grey[100]),
    border: "none"
  },
  cardContent: {
    padding: 16,
    "&:last-child": {
      paddingBottom: 16
    }
  },
  termsLabel: {
    color: ({ darkMode }) => (darkMode ? grey[300] : grey[600])
  }
});

function AcceptInvite(props) {
  const {
    decryptedProfileDetails,
    wallet,
    isMobile,
    darkMode,
    isProfileLoaded,
    decryptedMemberships
  } = useGlobalContext();

  const classes = useStyles({ darkMode: darkMode });

  const [acceptTermsCheck, setAcceptTermsCheck] = React.useState(false);
  const [membership, setMemebership] = React.useState();
  const [termsData, setTermsData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [rejectLoading, setRejectLoading] = React.useState(false);
  const [error, setError] = React.useState();

  useEffect(() => {
    if (!props.location.search) {
      props.history.push("/");
    }
    const getMembership = async () => {
      try {
        const queryMembershipId = queryString.parse(props.location.search);
        let membership;

        // If accessed through an email link - fetch first
        // else - lookup in memberships
        if (!decryptedMemberships) {
          const result = await API.graphql(
            graphqlOperation(queries.getMembership, {
              id: queryMembershipId.membershipId
            })
          );
          membership = result.data.getMembership;
        } else {
          membership = decryptedMemberships?.filter(
            membership => membership.id === queryMembershipId.membershipId
          )[0];
        }

        //if a user clicks on invitation link after was accepted
        if (membership && membership.state.status === "ACCEPTED")
          props.history.push(`/vaults/active/${membership.dataRoom.id}/assets`);

        //if a user clicks on invitation link after was revoked
        if (membership && membership.state.status === "REVOKED")
          props.history.push({
            pathname: "/reject-invite",
            state: { from: "REVOKED" }
          });

        //if a user clicks on invitation link after they rejected
        if (membership && membership.state.status === "REJECTED")
          props.history.push({
            pathname: "/reject-invite",
            state: { from: "REJECTED" }
          });

        setMemebership(membership);
      } catch (err) {
        console.log("Error fetching Membership: ", err);
      }
    };
    getMembership();
  }, [props.location.search]);

  useEffect(() => {
    if (membership && isProfileLoaded) {
      const termsOfAccess = membership.state.termsOfAccess
        ? base64ToJson(membership.state.termsOfAccess)
        : { termsOfAccess: null, hasTerms: false };
      //auto-accept invite if no terms of access
      if (!termsOfAccess.hasTerms) {
        acceptInvite(membership);
        return;
      }

      const decorator = createLinkDecorator();
      const contentState = convertFromRaw(
        JSON.parse(termsOfAccess.termsOfAccess)
      );
      // const contentState = convertFromRaw(
      //   JSON.parse(data.membershipReview.termsOfAccess)
      // )
      const editorState = EditorState.createWithContent(
        contentState,
        decorator
      );
      const hasText = editorState.getCurrentContent().hasText();
      setTermsData({ hasText: hasText, editorState: editorState });
    }
  }, [membership, isProfileLoaded]);

  const getRoleText = role => {
    switch (role) {
      case "CONTRIBUTOR":
        return (
          <>
            <Typography variant="body2" color="text.primary" className="strong">
              Contribution permissons
            </Typography>
            <Typography variant="body2">
              You can upload files, request data and delete files.
            </Typography>
          </>
        );
      case "VIEWER":
        return (
          <>
            <Typography variant="body2" color="text.primary" className="strong">
              View only permissons
            </Typography>
            <Typography variant="body2">
              You can view and download content in the vault.
            </Typography>
          </>
        );

      default:
        return (
          <Typography variant="body2" color="text.primary" className="strong">
            No role has been set.
          </Typography>
        );
    }
  };

  const acceptInvite = async () => {
    try {
      setLoading(true);
      await membershipAccept(wallet, membership, decryptedProfileDetails);
      setLoading(false);
      props.history.push(`/vaults/active/${membership.dataRoom.id}/assets`);
    } catch (err) {
      console.log("Failed to accept terms: ", err);
      setLoading(false);
      setError("Something went wrong!");
    }
  };

  const rejectTerms = async () => {
    try {
      setRejectLoading(true);
      await membershipReject(wallet, membership);
      setRejectLoading(false);
      props.history.push("/reject-invite");
    } catch (err) {
      console.log("Failed to reject terms: ", err);
    }
  };

  if (!membership || !isProfileLoaded) return null;

  const membershipOwnerDetails = membership.dataRoom.members.items.filter(
    member => {
      return member.state.role === "OWNER";
    }
  )[0].state.memberDetails;

  // auto-accept the invite
  if (!termsData.hasText) {
    return null;
  }

  return (
    <StandAloneScreenWrapper
      title={termsData.hasText ? "Terms of access" : "Invitation"}
      route="/"
    >
      <Box
        className={classes.box}
        height={termsData.hasText ? "90%" : "auto"}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box height="88%">
          <Box mb={6}>
            <Card square className={classes.card}>
              <CardContent className={classes.cardContent}>
                {getRoleText(membership.state.role)}
              </CardContent>
            </Card>
          </Box>
          {termsData.hasText ? (
            <Box mb={8} maxHeight={isMobile ? "65%" : "75%"} overflow="auto">
              <Editor editorState={termsData.editorState} readOnly={true} />
            </Box>
          ) : (
            <Box>
              <Typography variant="body2">
                You have been invited by {membershipOwnerDetails.email} to their
                vault.
              </Typography>
            </Box>
          )}
        </Box>
        {termsData.hasText && (
          <Box>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  style={{ alignItems: "normal" }}
                  classes={{ label: classes.termsLabel }}
                  control={
                    <AkordCheckbox
                      inputProps={{ "aria-label": "acceptTerms" }}
                      checked={acceptTermsCheck}
                      onChange={() => setAcceptTermsCheck(!acceptTermsCheck)}
                      name="acceptTerms"
                    />
                  }
                  label="By checking this box you are agreeing to digitally sign and accept the terms."
                />
              </FormGroup>
            </FormControl>
          </Box>
        )}
      </Box>
      <ButtonGroup
        noMinWidth={true}
        errorText={error}
        nextDiasabled={(!acceptTermsCheck && termsData.hasText) || loading}
        handleNext={() => acceptInvite()}
        handleBack={() => rejectTerms()}
        disableBackButton={rejectLoading}
        nextText={termsData.hasText ? "Accept terms" : "Accept invitation"}
        backText="Reject terms"
        backButtonOutlined={true}
        hideBackButton={false}
        fullWidthNextButton={false}
        hideIcon={true}
        showButtonInColumn={!termsData.hasText}
        loading={loading}
        backLoading={rejectLoading}
      />
    </StandAloneScreenWrapper>
  );
}

export default AcceptInvite;
