import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowRightIcon, BellIcon, CloseIcon } from "@akord/addon-icons";
import { useHistory } from "react-router-dom";
import API, { graphqlOperation } from "@aws-amplify/api";
import * as mutations from "../../graphql/mutations";

function PriorityNotification({ prioratyType, isMobile, transaction }) {
  const history = useHistory();

  const prioratyNotificationsData = {
    USER_VERIFIED: {
      actionText: "Action required! ",
      text: "Confirm access to someone you invited",
      action: () => {
        handleMarkAsRead();
        history.push(`/confirm/?membershipId=${transaction?.modelId}`);
      }
    }
  };

  const handleClosePrioratyNotification = e => {
    e.stopPropagation();
    handleMarkAsRead();
  };

  const handleMarkAsRead = async () => {
    await API.graphql(
      graphqlOperation(mutations.updateNotificationStatus, {
        operation: "MARK_AS_READ",
        ids: [transaction?.logId]
      })
    );
  };

  return (
    <Box
      position="fixed"
      key={transaction?.hash}
      width={isMobile ? "100%" : "calc(100% - 250px)"}
      style={{
        top: isMobile ? "44px" : 0
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        mx={5}
        mt={4}
        onClick={prioratyNotificationsData[prioratyType].action}
        sx={{
          backgroundColor: " #312F37",
          padding: "4px",
          borderRadius: "6px",
          border: "1px solid #403F46",
          transition: "0.3s ease",
          cursor: "pointer",
          "&:hover": {
            borderColor: "#F85B15"
          }
        }}
      >
        <Box display="flex">
          <BellIcon
            fontSize="small"
            color="warning"
            sx={{ marginRight: "4px" }}
          />
          <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
            <Typography
              variant="body2"
              className={["small", "strong"].join(" ")}
              color="primary.light"
              sx={{ whiteSpace: "pre" }}
            >
              {prioratyNotificationsData[prioratyType].actionText}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="body2" className="small">
                {prioratyNotificationsData[prioratyType].text}
              </Typography>
              <ArrowRightIcon
                color="info"
                sx={{ fontSize: "14px", marginLeft: "4px" }}
              />
            </Box>
          </Box>
        </Box>
        <IconButton
          onClick={e => handleClosePrioratyNotification(e)}
          sx={{ zIndex: 100 }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
}

export default PriorityNotification;
