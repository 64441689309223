import { Badge, Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator
} from "@mui/lab";
import { memoize } from "lodash";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { AvatarIcon, PadlockIcon } from "@akord/addon-icons";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { sortedItemsByDate } from "../../helpers/chat-helpers";
import { colorMap } from "../timeline/timeline-actions/colorMap";
import ChatMemoItem from "./ChatMemoItem";
import { useChatContext } from "../../contexts/ChatContextProvider";

const useStyles = makeStyles({
  missingOppositeContent: {
    "&:before": {
      flex: "none",
      padding: 0
    }
  },
  dot: {
    padding: 0,
    margin: 0,
    borderWidth: "1px",
    boxShadow: "none",
    border: "none"
  },
  content: {
    padding: "2px 8px 2px 4px",
    maxWidth: "calc(100% - 50px)"
  },
  iconExtraLarge: {
    fontSize: "38px",
    margin: 3
  },
  iconExtraSmall: {
    fontSize: "13px"
  },
  lockBoxLength: {
    minHeight: "28px"
  },
  lockPosition: {
    marginLeft: "16px"
  },
  dateBadge: {
    padding: "16px 14px 14px",
    border: "none",
    fontSize: "14px",
    borderRadius: "30px",
    fontFamily: [
      "Larsseit-Regular",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen-Sans",
      "Ubuntu",
      "Cantarell",
      "Helvetica Neue",
      "sans-serif"
    ].join(",")
  },
  badgePosition: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  lastItemBeforeDateLength: {
    minHeight: "84px"
  },
  avatar: {
    width: "38px",
    height: "38px",
    borderRadius: "50%",
    margin: "3px"
  }
});

const memoSortedItemsByDate = memoize(sortedItemsByDate);

function VaultChat({
  chatItems,
  chatEndRef,
  scrollToBottom,
  topMemoRef,
  loadMoreChatItems,
  isMoreMemosLoadedRef
}) {
  const { members, cancelledTimelineItemsCount } = useVaultContext();
  const {
    isMemoLoaded,
    nextMemoToken,
    countRef,
    chatItemsNumber,
    chatItemsLimit
  } = useChatContext();

  const location = useLocation();
  const itemId = location.state?.itemId;

  const classes = useStyles();
  const itemRef = useRef(null);

  useEffect(() => {
    if (!itemId && !isMoreMemosLoadedRef.current) scrollToBottom();
  }, [chatItems, cancelledTimelineItemsCount]);

  useEffect(() => {
    if (itemRef && itemRef.current) {
      itemRef.current.scrollIntoView({ behavior: "smooth" });
      highlightItem();
    }
  }, [chatItems, itemRef]);

  const highlightItem = () => {
    const container = itemRef.current.querySelector("#memo-container");
    if (container) {
      const color = container.getAttribute("data-color");
      const initialBackground = "inherit";
      container.style.transition = "background-color 1s ease-in";
      container.style.background = color;
      setTimeout(() => {
        container.style.background = initialBackground;
      }, 1000);
    }
  };

  const preparedMemosMap = memoSortedItemsByDate(chatItems);
  const flattenedMemoArray = Array.from(preparedMemosMap);
  const memosEntriesLength = preparedMemosMap.size;
  const preparedMemosItems = Array.from(preparedMemosMap?.values()).flat();

  useEffect(() => {
    // To avoid adding same count because of rerenders
    if (
      isMemoLoaded &&
      !countRef.current.includes(chatItemsNumber) &&
      chatItemsNumber > 0
    )
      countRef.current.push(chatItemsNumber);
  }, [chatItemsNumber]);

  // To find which item should be added to the REF (top item in the previous view)
  // we keep track of the indexes of these items and calculate here based on
  // last too indexes
  const calcItemIndex = () => {
    const currIndex = countRef.current.length - 1;
    const prevIndex = countRef.current.length - 2;
    return countRef.current[currIndex] - countRef.current[prevIndex];
  };

  // We need to match items when we render either by groupRef or id
  // for that we keep `paginatedTimelineItems` array of all items
  // and use `calcItemIndex` to get an index and match to the rendered item
  const matchItem = item => {
    if (!item.groupRef && item.id)
      return preparedMemosItems[calcItemIndex()]?.id === item.id;
    else return null;
  };

  return (
    <>
      {nextMemoToken && isMemoLoaded && (
        <Box mb={6} display="flex" justifyContent="center">
          <Button
            size="small"
            onClick={() => loadMoreChatItems()}
            color="primary"
            variant="outlined"
            type="submit"
            label="submit"
            disableElevation
            className={classes.buttonGroup}
          >
            Load more activity...
          </Button>
        </Box>
      )}
      <Box>
        <Timeline align="left">
          <TimelineItem
            className={classes.lockBoxLength}
            classes={{ missingOppositeContent: classes.missingOppositeContent }}
          >
            <TimelineSeparator className={classes.lockPosition}>
              <TimelineDot className={classes.dot}>
                <PadlockIcon classes={{ root: classes.iconExtraSmall }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
          </TimelineItem>
          {isMemoLoaded
            ? flattenedMemoArray.map(([date, memoArray], key) => {
                return (
                  <span key={key}>
                    <Badge
                      variant="standard"
                      color="secondary"
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      badgeContent={date}
                      classes={{ badge: classes.dateBadge }}
                      className={classes.badgePosition}
                      style={{ top: key === 0 ? "0px" : "-10px", zIndex: 0 }}
                    />
                    {memoArray.map((item, index, { length }) => {
                      if (item.isCancelled) return null;
                      return (
                        <TimelineItem
                          key={index}
                          className={
                            index === memoArray.length - 1 &&
                            key !== memosEntriesLength - 1
                              ? classes.lastItemBeforeDateLength
                              : key === memosEntriesLength - 1 &&
                                index === memoArray.length - 1
                              ? classes.lastItemLength
                              : null
                          }
                          classes={{
                            missingOppositeContent:
                              classes.missingOppositeContent
                          }}
                          id={item.groupRef || item.id}
                          ref={el => {
                            if (
                              location.state?.itemId &&
                              item.id == location.state?.itemId
                            )
                              itemRef.current = el;
                            else if (
                              chatItemsNumber - chatItemsLimit >= 0 &&
                              matchItem(item)
                            )
                              topMemoRef.current = el;
                          }}
                        >
                          <TimelineSeparator>
                            <TimelineDot
                              className={classes.dot}
                              variant="outlined"
                            >
                              {item.ownerInfo && item.ownerInfo.avatarUrl ? (
                                <img
                                  src={item.ownerInfo.avatarUrl}
                                  alt="Avatar"
                                  className={classes.avatar}
                                />
                              ) : (
                                <AvatarIcon
                                  fontSize="large"
                                  style={{
                                    color: item.ownerInfo
                                      ? colorMap[members[item.ownerInfo.email]]
                                      : "#A2A2A2"
                                  }}
                                  classes={{
                                    fontSizeLarge: classes.iconExtraLarge
                                  }}
                                />
                              )}
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent className={classes.content}>
                            <ChatMemoItem
                              operation={item}
                              positionedBeforeDate={
                                index === length - 1 &&
                                key !== memosEntriesLength - 1
                              }
                              color={colorMap[members[item.ownerInfo.email]]}
                            />
                          </TimelineContent>
                        </TimelineItem>
                      );
                    })}
                  </span>
                );
              })
            : ""}

          <TimelineItem
            classes={{ missingOppositeContent: classes.missingOppositeContent }}
            style={{ minHeight: "auto" }}
          >
            <TimelineSeparator className={classes.lockPosition}>
              <TimelineDot className={classes.dot}>
                <PadlockIcon classes={{ root: classes.iconExtraSmall }} />
              </TimelineDot>
            </TimelineSeparator>
          </TimelineItem>
        </Timeline>
        <div id="timeline-end" ref={chatEndRef} />
      </Box>
    </>
  );
}

export default VaultChat;
