import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import Routes from './components/route-wrappers/Routes'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(<App component={Routes} />, document.getElementById('main'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
