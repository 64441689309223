import { useState, useEffect } from 'react'

export default function useModal(initialValue = false) {
  const [confirmModalFormType, setConfirmModalFormType] = useState(null)
  const onConfirmModalFormType = type => setConfirmModalFormType(type)

  const [confirmModalType, setConfirmModalType] = useState(null)
  const onConfirmModalType = type => setConfirmModalType(type)

  const [isModalVisible, setModalVisible] = useState({
    revoke: initialValue,
    archive: initialValue,
    restore: initialValue,
    dataRoomRename: initialValue,
    fileRename: initialValue,
    folderCreate: initialValue,
    folderRename: initialValue,
    folderRevoke: initialValue,
    folderRestore: initialValue,
    folderDelete: initialValue,
    revokeFile: initialValue,
    revokeStack: initialValue,
    restoreFile: initialValue,
    restoreStack: initialValue,
    deleteFile: initialValue,
    restoreFileFromTimeline: initialValue,
    deletedFileFromTimeline: initialValue,
    duplicateFile: initialValue,
    confirmUpload: initialValue,
    saveNote: initialValue
  })

  useEffect(() => {
    return () => {
      onConfirmModalType(null)
      setModalVisible({
        revoke: initialValue,
        archive: initialValue,
        restore: initialValue,
        dataRoomRename: initialValue,
        fileRename: initialValue,
        folderCreate: initialValue,
        folderRename: initialValue,
        folderRevoke: initialValue,
        folderRestore: initialValue,
        folderDelete: initialValue,
        revokeFile: initialValue,
        revokeStack: initialValue,
        restoreStack: initialValue,
        restoreFile: initialValue,
        deleteFile: initialValue,
        restoreFileFromTimeline: initialValue,
        deletedFileFromTimeline: initialValue,
        duplicateFile: initialValue,
        confirmUpload: initialValue,
        saveNote: initialValue
      })
    }
  }, [])

  const onModalVisibility = (valueForType = null, type) =>
    setModalVisible({
      ...isModalVisible,
      [type]: valueForType ? valueForType : !isModalVisible[type]
    })

  return [
    isModalVisible,
    onModalVisibility,
    confirmModalType,
    onConfirmModalType,
    confirmModalFormType,
    onConfirmModalFormType
  ]
}
