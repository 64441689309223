import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Divider,
  Typography
} from "@mui/material";
import React from "react";
import ButtonGroup from "../../components/common/ButtonGroup";
import { CloseIcon, StripeClimateIcon } from "@akord/addon-icons";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";

function TopupSummaryModal({
  formData,
  selectedProduct,
  quantity,
  handleCardPayment,
  openConfirmStripeModal,
  onConfirmStripeModalClose,
  loading
}) {
  const { isMobile } = useGlobalContext();
  const renderStorage = item => {
    if (!item) return "-";
    const storage = item?.promoText ? item.storage : item.storage * quantity;
    return storage;
  };

  // TODO: add currency symbol to product @stripe
  const renderPrice = item => {
    if (!item) return "-";
    const amount = item?.promoText ? item.amount : item.amount * quantity;
    return `$${amount / 100}`;
  };

  const renderPayButtonLabel = () => {
    return `Pay ${renderPrice(selectedProduct)} for ${renderStorage(
      selectedProduct
    )} GB`;
  };

  return (
    <Dialog
      fullScreen={isMobile}
      maxWidth="md"
      onClose={onConfirmStripeModalClose}
      aria-labelledby="stripe-confirm-dialog"
      open={openConfirmStripeModal}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h2">Review and pay</Typography>
          <IconButton
            aria-label="close"
            sx={{ padding: 0 }}
            onClick={onConfirmStripeModalClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ marginBottom: 0, maxHeight: "none" }}>
        <Box>
          <Box>
            <Typography variant="body2">
              Please check all the details carefully before confirming your
              purchase.
            </Typography>
            <Box mt={5}>
              <Typography variant="h3">Storage amount</Typography>
              <Typography variant="body2">
                {selectedProduct.storage} GB
              </Typography>
            </Box>
            <Box mt={5}>
              <Typography variant="h3">Price</Typography>
              <Typography variant="body2">
                {renderPrice(selectedProduct)}
              </Typography>
            </Box>
            <Box mt={5}>
              <Typography variant="h3">Payment details</Typography>
              <Typography variant="body2">{formData.name}</Typography>
              <Typography variant="body2">{formData.companyName}</Typography>
              <Typography variant="body2">{formData.companyVat}</Typography>
              <Typography variant="body2">{formData.address}</Typography>
              <Typography variant="body2">{formData.city}</Typography>
              <Typography variant="body2">{formData.state}</Typography>
              <Typography variant="body2">{formData.zip}</Typography>
              <Typography variant="body2">{formData.country}</Typography>
            </Box>
          </Box>
          <Box mt={6} pb={4}>
            <ButtonGroup
              nextText={renderPayButtonLabel()}
              handleEnd={() => handleCardPayment()}
              nextDiasabled={loading}
              hideBackButton={true}
              fullWidthNextButton={true}
              hideIcon={true}
              disabledRed={false}
              loading={loading}
              noMarginTop={true}
            />
          </Box>
          <Box display="flex" alignItems="center" pb={4}>
            <StripeClimateIcon
              fontSize="small"
              style={{ marginRight: "5px" }}
            />
            <Typography
              variant="caption"
              color="text.tertiary"
              className="small"
            >
              Akord will contribute 2% of your purchase to remove CO2 from the
              atmosphere.
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default TopupSummaryModal;
