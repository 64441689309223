import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import {
  Badge,
  Box,
  Checkbox,
  TableCell,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import {
  formatDate,
  getDataRoomId,
  getDataRoomStatus,
  getFolderId
} from "../../helpers/helpers";
import { storageTransactionStatus } from "../../helpers/akord-enums";
import { useGlobalContext } from "../../contexts/GlobalDataProvider";
import { useVaultContext } from "../../contexts/VaultContextProvider";
import { MoreActionButton } from "../../components/common";
import { bytesToGb } from "../storage/storage-helper";
import { NoteIcon, TickIcon } from "@akord/addon-icons";
import { grey } from "../../theme/colors";
import NoteDrawer from "../../components/common/MoreMenuDrawer/NoteDrawer";
import MenuNote from "../../components/common/MoreMenu/MenuNote";
import { useAssetsContext } from "../../contexts/AssetsContextProvider";

const useStyles = makeStyles(theme => ({
  iconBackGround: {
    background: ({ archived, itemChecked, isVaultPublic }) =>
      archived
        ? itemChecked
          ? grey[500]
          : theme.palette.background.archived
        : isVaultPublic
        ? theme.palette.background.stack
        : itemChecked
        ? theme.palette.info.secondary
        : theme.palette.background.active,
    boxSizing: "content-box",
    color: ({ archived, itemChecked, isVaultPublic }) =>
      archived
        ? itemChecked
          ? "#FFF"
          : theme.palette.secondary.disabled
        : isVaultPublic
        ? theme.palette.info.secondary
        : itemChecked
        ? "#FFF"
        : theme.palette.primary.main,
    padding: "3px",
    marginRight: "15px",
    borderRadius: "2px"
  },
  toolLine: {
    display: "flex",
    justifyContent: "space-between",
    height: "56px",
    alignItems: "center"
  },
  orangeBadge: {
    backgroundColor: theme.palette.warning.main
  },
  checkBox: {
    padding: 0
  }
}));

const columnsToShow = (stack, stackSize, width) => {
  const stackSizeToShow = bytesToGb(stack?.size ? stack.size : stackSize);

  switch (true) {
    case width === "xs":
      return null;
    case !!stack:
      switch (true) {
        case width === "sm":
          return (
            <TableCell align="left">
              <Typography variant="body2" color="text.primary" noWrap>
                {formatDate(stack.updatedAt || new Date())}
              </Typography>
            </TableCell>
          );
        default:
          return (
            <>
              <TableCell align="left">
                <Typography variant="body2" color="text.primary" noWrap>
                  {formatDate(stack.updatedAt || new Date())}
                </Typography>
              </TableCell>
              <TableCell align="left">
                {stackSizeToShow.size
                  ? stackSizeToShow.size +
                    stackSizeToShow.sizeType.toUpperCase()
                  : "-"}
              </TableCell>
              <TableCell align="left">{stack.revisions.length}</TableCell>
            </>
          );
      }
    default:
      return (
        <>
          <TableCell>
            <Skeleton style={{ height: "30px" }} />
          </TableCell>
          {width === "sm" ? (
            <TableCell />
          ) : (
            <>
              <TableCell>
                <Skeleton style={{ height: "30px" }} />
              </TableCell>
              <TableCell>
                <Skeleton style={{ height: "30px" }} />
              </TableCell>
            </>
          )}
        </>
      );
  }
};

function NoteItem({ note, revoked }) {
  const { width, darkMode } = useGlobalContext();

  const { userRole, onCurrentStack, isVaultPublic } = useVaultContext();
  const {
    decryptedNotes,
    onSelectedItems,
    selectedItemsMap,
    onLastSelectedItem,
    isRoomArchived
  } = useAssetsContext();

  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();

  const roomId = getDataRoomId(history.location.pathname);
  const folderId = getFolderId(history.location.pathname);
  const roomStatus = getDataRoomStatus(history.location.pathname);
  const itemId = location.state?.itemId;
  const itemRef = useRef(null);
  const itemChecked = selectedItemsMap?.has(note?.hash);

  const [menuActive, setMenuActive] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const [iconHovered, setIconHovered] = React.useState(false);
  const handleIconHovered = action => setIconHovered(action);

  const classes = useStyles({
    archived: isRoomArchived || revoked || userRole === "VIEWER",
    darkMode: darkMode,
    itemChecked: itemChecked,
    menuActive: menuActive,
    isVaultPublic: isVaultPublic
  });

  useEffect(() => {
    if (itemId && note && itemId == note.id && itemRef?.current) {
      itemRef.current.scrollIntoView({ behavior: "smooth" });
      highlightItem();
    }
  }, [note]);

  const highlightItem = () => {
    const initialBackground = "inherit";
    itemRef.current.style.transition = "background-color 1s ease-in";
    itemRef.current.style.background = theme.palette.background.stack;
    setTimeout(() => {
      itemRef.current.style.background = initialBackground;
    }, 1000);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
    setMenuActive(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuActive(false);
  };

  // Calculate size of the stack by files
  const stackSize = note?.files?.reduce((acc, file) => acc + file.size, 0);

  const fullNote =
    decryptedNotes.filter(item => item.hash === note?.hash) || [];

  // Status on Arweave
  const stackStatus =
    fullNote && fullNote[0]?.storageTransactions.items[0]?.status;

  const toggleIcon = () => handleIconHovered(!iconHovered);

  const handleSelectItem = event => {
    const { name, checked } = event.target;
    onSelectedItems(name, checked, revoked);
    onLastSelectedItem(name);
  };

  return (
    <>
      <TableRow hover={!!note} ref={itemRef}>
        {note ? (
          <TableCell
            size="medium"
            style={{ cursor: revoked ? "inherit" : "pointer" }}
            scope="row"
          >
            <Tooltip
              title={
                stackStatus === storageTransactionStatus.PENDING
                  ? note.title + " (Pending...)"
                  : note.title
              }
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Badge
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  overlap="rectangular"
                  variant="dot"
                  classes={{ badge: classes.orangeBadge }}
                  invisible={
                    stackStatus !== storageTransactionStatus.PENDING &&
                    stackStatus !== storageTransactionStatus.SCHEDULED
                  }
                >
                  {iconHovered || itemChecked ? (
                    <Checkbox
                      onMouseLeave={toggleIcon}
                      onMouseEnter={toggleIcon}
                      onChange={handleSelectItem}
                      checked={itemChecked}
                      name={note.hash}
                      className={classes.checkBox}
                      icon={
                        <TickIcon
                          fontSize="medium"
                          className={classes.iconBackGround}
                        />
                      }
                      checkedIcon={
                        <TickIcon
                          fontSize="medium"
                          className={classes.iconBackGround}
                        />
                      }
                    />
                  ) : (
                    <NoteIcon
                      fontSize="medium"
                      className={classes.iconBackGround}
                      onMouseEnter={toggleIcon}
                    />
                  )}
                </Badge>
                <div
                  style={{ display: "grid" }}
                  onClick={() => {
                    if (!revoked) {
                      let galleryUrl = `/vaults/${roomStatus}/${roomId}/gallery`;
                      if (folderId) {
                        galleryUrl += `/folders/${folderId}`;
                      }
                      galleryUrl += `#${note.hash}`;
                      history.push(galleryUrl);
                    }
                  }}
                >
                  <Typography variant="body2" color="text.primary" noWrap>
                    {note.title}
                  </Typography>
                </div>
              </div>
            </Tooltip>
          </TableCell>
        ) : (
          <TableCell scope="row" size="medium">
            <Box display="flex">
              <Skeleton
                variant="rectangular"
                width={30}
                height={30}
                style={{ marginRight: "15px" }}
              />
              <Skeleton style={{ flex: "1" }} />
            </Box>
          </TableCell>
        )}

        {columnsToShow(note, stackSize, width)}

        {note ? (
          <TableCell align="right" size="medium">
            <MoreActionButton
              handleClick={e => {
                handleMenu(e);
                onCurrentStack(note);
              }}
              menuActive={menuActive}
            />
          </TableCell>
        ) : width === "xs" || width === "sm" ? null : (
          <TableCell />
        )}
      </TableRow>
      {width === "xs" ? (
        <NoteDrawer
          openDrawer={openMenu}
          handleMenuClose={handleMenuClose}
          revoked={revoked}
          note={note}
        />
      ) : (
        <MenuNote
          anchorEl={anchorEl}
          handleMenuClose={handleMenuClose}
          openMenu={openMenu}
          revoked={revoked}
          note={note}
        />
      )}
    </>
  );
}

export default NoteItem;
