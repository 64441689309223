import {
  stackRename,
  dataRoomRename,
  folderCreate,
  folderRename
} from "../../../helpers/akord-actions";
import { PenIcon, FolderOutlineIcon, LinkIcon } from "@akord/addon-icons";

export const formModalOptions = {
  folderCreate: {
    title: "Create a folder",
    icon: FolderOutlineIcon,
    nextButton: "Create",
    placeholder: "Folder name"
  },
  dataRoomRename: {
    title: "Rename vault",
    icon: PenIcon,
    nextButton: "Rename",
    placeholder: null
  },
  fileRename: {
    title: "Rename file",
    icon: PenIcon,
    nextButton: "Rename",
    placeholder: null
  },
  folderRename: {
    title: "Rename folder",
    icon: PenIcon,
    nextButton: "Rename",
    placeholder: null
  },
  shareFile: {
    icon: LinkIcon,
    title: "Share public file",
    placeholder: null,
    secondaryText:
      "Anyone you share this link with will able to view the image.",
    nextButton: "Copy link"
  }
};

export const handleModalFormSubmit = async (
  confirmFormType,
  notificationData,
  ledgerWrapper
) => {
  switch (confirmFormType) {
    case "dataRoomRename":
      await dataRoomRename(ledgerWrapper, notificationData);
      break;
    case "fileRename":
      await stackRename(ledgerWrapper, notificationData);
      break;
    case "folderCreate":
      await folderCreate(ledgerWrapper, notificationData);
      break;
    case "folderRename":
      await folderRename(ledgerWrapper, notificationData);
      break;
    case "shareFile":
      navigator.clipboard.writeText(notificationData.title);
      break;

    default:
      break;
  }
};
