import React from "react";
import { Box, Container, CssBaseline } from "@mui/material";
import { Helmet } from "react-helmet";
import makeStyles from '@mui/styles/makeStyles';
import ButtonGroup from "../ButtonGroup";
import muiTheme from "../../../theme/mui";
import { withRouter } from "react-router";
import useMediaQuery from "@mui/material/useMediaQuery";
import ThemeWrapper from "./ThemeWrapper";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: ({ isDesktop, showButtonInColumn }) =>
      isDesktop || showButtonInColumn ? "flex-start" : "space-between",
    // borderLeft: ({ isDesktop }) => (isDesktop ? '1px solid' : 'none'),
    // borderRight: ({ isDesktop }) => (isDesktop ? '1px solid' : 'none'),
    // backgroundColor: ({ darkMode }) =>
    //   darkMode ? '#1D1D1D' : theme.palette.background.default,
    paddingTop: "20px",
    paddingBottom: "25px"
  }
});

const PageWrapper = ({
  darkMode,
  showBottomNav = false,
  nextDiasabled,
  handleNext,
  handleBack,
  handleEnd,
  nextText,
  inverted,
  hideBackButton,
  fullWidthNextButton,
  hideIcon,
  disableBackButton,
  children,
  className = "",
  mainClassName = "",
  maxWidth = "xs",
  error,
  errorText,
  disabledRed,
  showButtonInColumn,
  noMinWidth = false,
  loading,
  backLoading,
  pageTitle = "Akord",
  ...rest
}) => {
  const theme = muiTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true
  });
  const classes = useStyles({
    isDesktop: isDesktop,
    darkMode: darkMode,
    showButtonInColumn: showButtonInColumn
  });
  const fullWidth = rest.width === "xs";
  // const { vh } = useViewport()

  const { host, protocol } = window.location;
  const baseUrl = `${protocol}//${host}`;
  const pathname = rest.location.pathname;

  return (
    <ThemeWrapper darkMode={darkMode}>
      <CssBaseline />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${pageTitle} | Akord`}</title>
        <link rel="canonical" href={`${baseUrl}${pathname}`} />
      </Helmet>
      <Box className={`${className}`} height="100%">
        <Container
          className={`${classes.main} ${mainClassName}`}
          maxWidth={fullWidth ? false : maxWidth}
          style={{ minHeight: "100%" }}
        >
          {children}
          {showBottomNav ? (
            <ButtonGroup
              noMinWidth={noMinWidth}
              error={error}
              errorText={errorText}
              nextDiasabled={nextDiasabled}
              handleNext={handleNext}
              handleBack={handleBack}
              handleEnd={handleEnd}
              disableBackButton={disableBackButton}
              nextText={nextText}
              inverted={inverted}
              hideBackButton={hideBackButton}
              fullWidthNextButton={fullWidthNextButton}
              hideIcon={hideIcon}
              disabledRed={disabledRed}
              showButtonInColumn={showButtonInColumn}
              loading={loading}
              backLoading={backLoading}
              {...rest}
            />
          ) : null}
        </Container>
      </Box>
    </ThemeWrapper>
  );
};

export default withRouter(PageWrapper);
